import React, { Component } from "react";
import Resources from "../libraries/resources";
import LanguageSelect from "../components/language-select";
import MenuSignInButton from "../components/menu-signIn-button";

// images
import foramindLogo from "../styles/img/foramind_logo.png";
import closeMenuIcon from "../styles/img/close-menu.svg";

class NavMenu extends Component {

  constructor() {
    super();
  }

  componentDidMount() {
    this.navbarMenu();
    this.scrollFunction();
  };

  navbarMenu() {
    var menu = document.querySelector('.navbar-collapse');
    var barsIcon = document.querySelector(".bars-icon");
    barsIcon.addEventListener("click", function () {
      menu.classList.toggle("show");
      menu.classList.add("activate");
      barsIcon.classList.toggle("none");
      closeIcon.classList.toggle("none");

    });

    var closeIcon = document.querySelector(".close-icon");
    var submenu = document.querySelector('.bars-icon');
    closeIcon.addEventListener("click", function () {
      menu.classList.toggle("show");
      menu.classList.add("activate");
      submenu.classList.toggle("none");
      closeIcon.classList.toggle("none");
    });

    const navLinks = document.querySelectorAll('.nav-link');
    for (let n in navLinks) {
      if (navLinks.hasOwnProperty(n)) {
        navLinks[n].addEventListener('click', e => {
          e.preventDefault();
          menu.classList.toggle("show");
          closeIcon.classList.toggle("none");
          barsIcon.classList.toggle("none");
        });
      }
    }
  };

  scrollFunction() {
    const makeNavLinksSmooth = () => {
      const navLinks = document.querySelectorAll('.nav-link');
      for (let n in navLinks) {
        if (navLinks.hasOwnProperty(n)) {
          navLinks[n].addEventListener('click', e => {
            if (!navLinks[n].classList.contains('external-link')) {
              e.preventDefault();
              if (navLinks[n].getAttribute("href") !== '') {
                document.querySelector(navLinks[n].hash)
                  .scrollIntoView({
                    top: 150,
                    behavior: "smooth"
                  });
              }
            } else {
              window.location.href = Resources.getValue("appBaseEnvURL") + navLinks[n].getAttribute("href");
            }
          });
        }
      }
    }


    const spyScrolling = () => {
      const sections = document.querySelectorAll('.hero-bg');
      const navbar = document.querySelector(".navbar");

      window.onscroll = () => {
        const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

        for (let s in sections)
          if (sections.hasOwnProperty(s) && sections[s].offsetTop <= scrollPos + 400) {
            const id = sections[s].id;
            document.querySelector('.active').classList.remove('active');
            if (id) {
              document.querySelector(`a[href*=${id}]`).parentNode.classList.add('active');
            }

            const navLinks = document.querySelectorAll('.nav-link');
            if (scrollPos > 50) {
              navbar.classList.add("navbar-activate")
              navLinks.forEach(function (item) {
                item.classList.add("text-color-activate");
              });
              if (id) {
                document.querySelector(`a[href*=${id}]`).parentNode.classList.remove('home-color-set');
              }
            }
            else {
              navbar.classList.remove("navbar-activate");

              navLinks.forEach(function (item) {
                item.classList.remove("text-color-activate");
              });
              if (id) {
                document.querySelector(`a[href*=${id}]`).parentNode.classList.remove('home-color-set');
              }
            }
          }
      }
    }
    makeNavLinksSmooth();
    spyScrolling();
  }
  // toggle_visibility= (id) => {
  //   const e = document.getElementById(id);
  //   if(e != null){
  //     if(e.style.display === 'block' || e.style.display === ''){
  //       e.style.display = 'none';
  //     }
  //     else{
  //       e.style.display = 'block';
  //     }
  //   }
    
  // };
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
        <div className="header-wraps">
        <button className="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="bars-icon"><i className="fa fa-bars" aria-hidden="true"></i></span>
            {/* <span className="close-icon none"><i className="fa fa-times" aria-hidden="true"></i></span> */}
            <span className="close-icon none"><img src={closeMenuIcon}/></span>
          </button>
          <a className="navbar-brand" href={Resources.getValue('appBaseEnvURL') === '/foramind' ? Resources.getValue('appBaseEnvURL') + '/' : '/'}>
            <img className="landing-page-logo d-inline-block align-top" src={foramindLogo} width="120" alt="Foramind" />
          </a>
          <div className="language-page">
            <LanguageSelect />
          </div>
        </div>
        <div className="collapse navbar-collapse home-collapse">
          <ul className="navbar-nav ml-auto">
            {this.props.menuItems.map((item, ind) => (
              <li className={`nav-item ${item.subLinks ? 'corporate-menu' : ''} ${item.activeClass}`} key={ind}>
                <a
                  // onclick={item.subLinks ? this.toggle_visibility(item.title + '-sub-menu'):""}
                  className={`${item.subLinks ? 'dropdown-toggle' : 'nav-link'} ${item.externalLink === true ? 'external-link' : ''}`}
                  href={item.subLinks ? "#/" : item.href}
                  data-toggle={item.subLinks && "dropdown"}
                  title={item.title}
                >
                  {item.title}
                </a>
                {item.subLinks && (
                  <ul id={`${item.title + '-sub-menu'}`}>
                    {
                      item.subLinks.map((itm, ind) => (
                        <li className={`nav-item ${itm.activeClass}`} key={ind}>
                          <a
                            className={`nav-link ${itm.externalLink === true ? 'external-link' : ''}`}
                            href={itm.href}
                            title={itm.title}
                          >
                            {itm.title}
                          </a>
                        </li>
                      ))
                    }
                  </ul>
                )}
              </li>
            ))}
            <MenuSignInButton />
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavMenu;
