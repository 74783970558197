import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";

var MindMapService = {
  getMindMap: function (recordSize, callback, _this, id) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url:
        Resources.getValue("mindMapListApiURL") + "?recordSize=" + recordSize,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MindMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MindMapService.openPaymentStatusCode();
          } else {
            MindMapService.openWarningModal();
          }
        } else {
          MindMapService.openErrorModal();
        }
      }
    };
  },

  delete: function (mapId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "DELETE",
      token: "Bearer",
      url: Resources.getValue("mapDeleteByIdApiURL") + "?mindMapId=" + mapId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MindMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(_this);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MindMapService.openPaymentStatusCode();
          } else {
            MindMapService.openWarningModal();
          }
        } else {
          MindMapService.openErrorModal();
        }
      }
    };
  },

  updateMapName: function (mapId, mapName, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "PUT",
      token: "Bearer",
      url:
        Resources.getValue("mapUpdateNameApiURL") +
        "?mindMapId=" +
        mapId +
        "&name=" +
        mapName,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MindMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MindMapService.openPaymentStatusCode();
          } else {
            MindMapService.openWarningModal();
          }
        } else if (xhr.status == 409) {
          MindMapService.openExistNameErrorModal();
        } else {
          MindMapService.openErrorModal();
        }
      }
    };
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openExistNameErrorModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("existNameErrorModalMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/mind-map-list",
        },
      ],
      redirectAfterClose:
        Resources.getValue("appBaseEnvURL") + "/mind-map-list",
    });
  },

  openPaymentStatusCode: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("paymentStatusModalMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/payment",
        },
      ],
      redirectAfterClose: Resources.getValue("appBaseEnvURL") + "/payment",
    });
  },
};

export default MindMapService;
