import React, { Component } from "react";

class MailingPage extends Component {
  render() {
    return (
      <div style={{background: '#fff'}}>
        <table style={{background: '#fff'}} id="Table_01" width="840" height="1644" border="0" cellpadding="0" cellspacing="0">
          <tr>
            <td colspan="4">
              <img src="https://i.ibb.co/QcJ5ykx/foramind-mailing-xd-1-01.jpg" width="375" height="260" alt="" /></td>
            <td colspan="3">
              <img src="https://i.ibb.co/fM56xTL/foramind-mailing-xd-1-02.jpg" width="465" height="260" alt="" /></td>
          </tr>
          <tr>
            <td style={{textAlign: 'left', paddingLeft: 60}} colspan="7" width="840" height="260">
              Sevgili Kafein, <br /><br />

            Yepyeni bir ürün ile karşınızdayız! FORAMIND…<br /><br />

            İlk defa ön lansmanını sizlerle paylaşıyor olmaktan dolayı gurur duyuyoruz.<br /><br />

            Hazırsanız başlıyoruz…
          </td>
          </tr>
          <tr>
            <td colspan="5">
              <img src="https://i.ibb.co/3MKJCsC/foramind-mailing-xd-1-04.jpg" width="397" height="648" alt="" /></td>
            <td style={{background: '#f5f5f5', textAlign: 'left'}} colspan="2" width="443" height="648">
              <h1 style={{fontSize: 35, fontWeight: 'bold', color: '#33bdd8'}}>FORAMIND Nedir?</h1>
              <br /><br />
              <p style={{lineHeight: 1}}>
                Dünyada yükselen ve değer kazanan <br />
					geliştirme araçlarından birisi olan Zihin Haritaları konusunda ilk
					yerli ürünümüzdür.<br /><br />

					Teknolojiyi kullanarak aklınızdaki her planı not edin, programlayın. <br />

					Şimdi sizlerin kullanımına açıyoruz.<br />

					FORAMIND ile Neler Yapabilirsiniz?<br /><br />
					- Aklınızdaki planları organize etmede,<br /><br />
					- Problem çözmek için,<br /><br />
					- Konu tekrarı yapmak için,<br /><br />
					- Sunumlarınızda,<br /><br />
					- Çocuklarınızın ders notları ve çalışmalarında,<br /><br />
					- Not almak için kullanabilirsiniz.<br /><br />


              </p>

            </td>
          </tr>
          <tr>
            <td colspan="7">
              <img src="https://i.ibb.co/XXrcSZZ/foramind-mailing-xd-1-06.jpg" width="840" height="57" alt="" /></td>
          </tr>
          <tr>
            <td colspan="3">
              <img src="https://i.ibb.co/vBW2FYy/foramind-mailing-xd-1-07.jpg" width="134" height="50" alt="" /></td>
            <td colspan="3">
              <img src="https://i.ibb.co/7SD5Mjb/foramind-mailing-xd-1-08.jpg" width="577" height="50" alt="" /></td>
            <td rowspan="7">
              <img src="https://i.ibb.co/pPr42Xs/foramind-mailing-xd-1-09.jpg" width="129" height="418" alt="" /></td>
          </tr>
          <tr>
            <td colspan="2" rowspan="2">
              <img src="https://i.ibb.co/vBgmJdt/foramind-mailing-xd-1-10.jpg" width="129" height="120" alt="" /></td>
            <td style={{background: '#f5f5f5', textAlign: 'center', fontWeight: 'bold'}} colspan="4" width="582" height="48">
              <a style={{color: 'black'}} href="https://forms.gle/zW9yrxCxqDgqzzko9
				">https://forms.gle/zW9yrxCxqDgqzzko9
				</a>
            </td>

          </tr>
          <tr>
            <td>
              <img src="https://i.ibb.co/JHT4cX1/foramind-mailing-xd-1-12.jpg" width="5" height="72" alt="" /></td>
            <td colspan="3">
              <img src="https://i.ibb.co/3zdZGRd/foramind-mailing-xd-1-13.jpg" width="577" height="72" alt="" /></td>
          </tr>
          <tr>
            <td rowspan="4">
              <img src="https://i.ibb.co/KjzymkX/foramind-mailing-xd-1-14.jpg" width="128" height="248" alt="" /></td>
            <td style={{background: '#f5f5f5', textAlign: 'center', fontWeight: 'bold'}} colspan="5" width="583" height="48">
              <a style={{color: 'black'}} href="https://foramind.com/landing-page
				">https://foramind.com/landing-page
				</a>
            </td>
          </tr>
          <tr>
            <td colspan="2" rowspan="3">
              <img src="https://i.ibb.co/gjZCG6S/foramind-mailing-xd-1-16.jpg" width="6" height="200" alt="" /></td>
            <td colspan="3">
              <img src="https://i.ibb.co/dQN56j1/foramind-mailing-xd-1-17.jpg" width="577" height="72" alt="" /></td>
          </tr>
          <tr>
            <td style={{background: '#f5f5f5', textAlign: 'center', fontWeight: 'bold'}} colspan="3" width="577" height="48">
              Ekten Ulaşabilirsiniz.</td>
          </tr>
          <tr>
            <td colspan="3">
              <img src="https://i.ibb.co/6gYhkz4/foramind-mailing-xd-1-19.jpg" width="577" height="80" alt="" /></td>
          </tr>
          <tr>
            <td>
              <img src="https://cdn1.bbcode0.com/uploads/2020/10/16/deaf5d2c9e01f9af5f15238f85a8d33c-full.gif"
                width="128" height="1" alt="" /></td>
            <td>
              <img src="https://cdn1.bbcode0.com/uploads/2020/10/16/deaf5d2c9e01f9af5f15238f85a8d33c-full.gif"
                width="1" height="1" alt="" /></td>
            <td>
              <img src="https://cdn1.bbcode0.com/uploads/2020/10/16/deaf5d2c9e01f9af5f15238f85a8d33c-full.gif"
                width="5" height="1" alt="" /></td>
            <td>
              <img src="https://cdn1.bbcode0.com/uploads/2020/10/16/deaf5d2c9e01f9af5f15238f85a8d33c-full.gif"
                width="241" height="1" alt="" /></td>
            <td>
              <img src="https://cdn1.bbcode0.com/uploads/2020/10/16/deaf5d2c9e01f9af5f15238f85a8d33c-full.gif"
                width="22" height="1" alt="" /></td>
            <td>
              <img src="https://cdn1.bbcode0.com/uploads/2020/10/16/deaf5d2c9e01f9af5f15238f85a8d33c-full.gif"
                width="314" height="1" alt="" /></td>
            <td>
              <img src="https://cdn1.bbcode0.com/uploads/2020/10/16/deaf5d2c9e01f9af5f15238f85a8d33c-full.gif"
                width="129" height="1" alt="" /></td>
          </tr>
        </table>
      </div>
    );
  };
}

export default MailingPage;
