import React, { Component } from "react";
import LoginLimService from "../services/api/login-lim";
import Utils from "../libraries/utils";

class LoginLim extends Component {

  componentDidMount(){
    this.listener();
  }

  listener(){
    // -- 09.09.2021 tarihinde methodbox durdurulmasi istendigi icin yoruma alindi --
    // var token = Utils.getParameterByName('token');
    // var campusName = Utils.getParameterByName('campusName');
    // var data = {
    //   token: token,
    //   campusName: campusName
    // }
    // if(data.token){
    //   LoginLimService(data);
    // }
  }

  render() {
    return (
      <div className="">
      </div>
    );
  };
}

export default LoginLim;
