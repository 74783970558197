import React, { Component } from "react";

class ContextMenu extends Component {
  render() {
    return (
      <div className="context-menu">
        <div id="menu">
          {/* <button data-command="InsertChild"></button> */}
          <button data-command="AddRemoveNodeLine"></button>
          {/* <button data-command="AddRemoveNodeBackground"></button>
          <button data-command="AddImage"></button> */}
          <span></span>
          {/* <button data-command="GetItemFormat"></button> */}
          {/* <button data-command="SetItemFormat"></button> */}
          {/* <button data-command="FinishItemFormat"></button> */}
          <button data-command="Delete"></button>
          {/* <span></span> */}
          <button data-command="Edit"></button>
          {/* <button data-command="Value"></button> */}
          {/* <span></span> */}
          <button data-command="Undo"></button>
          <button data-command="Redo"></button>
          <button data-command="Center"></button>
        </div>
      </div>
    );
  }
}

export default ContextMenu;
