import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import DataService from "./data-service";

var AiPackages = {
  getMapNumbers: function (userId) {
    return new Promise((resolve) => {
      const xhr = new DataService.createXHR({
        type: "GET",
        token: "Bearer",
        url: Resources.getValue("remainingMaps") + "?userId=" + userId,
      });

      xhr.onerror = function () {
        Utils.loadingScreen("hide");
      };

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          // close loading when xhr done
          Utils.loadingScreen("hide");
          if (xhr.status == 200 && xhr.response) {
            const responseData = JSON.parse(xhr.response);
            if (responseData) {
              resolve(responseData.data);
            }
          } else if (xhr.status == 401) {
            LoginService.login();
          }
        }
      };

    });
  }
};

export default AiPackages;
