import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";
import SuccessModal from "../../components/modals/success-modal";
import CompanyUserInfo from "./getCompanyUserInfo";

var LoginService = {
  login: function () {
    if (LoginService.loginDataCheck()) {
      LoginService.resfreshToken();
    } else {
      LoginService.getToken();
    }
  },

  accountAutoLogin: function () {
    const responseData = JSON.parse(this.response);
    localStorage.setItem("retrieveUrl", window.location.pathname);
    localStorage.setItem("token", responseData.token);
    localStorage.setItem("refreshToken", responseData.refreshToken);
    localStorage.setItem("a1Num", responseData.statusCode);
    localStorage.setItem("ab0c5", responseData.freeDays);
    localStorage.setItem("hw8w0", responseData.productId);
    localStorage.setItem("bhr05", responseData.productDay);
    localStorage.setItem("zt931", responseData.trialDay);
    localStorage.setItem("x8s88", responseData.remainingProductDays);
    //localStorage.setItem("77we1", responseData.isFreeUser);
    localStorage.setItem("c65s1", JSON.stringify(responseData.companySubscriptionInfo));
    if (localStorage.getItem("userInformation")) {
      window.location.href =
        Resources.getValue("appBaseEnvURL") +
        "/mind-map-list?params=accountActivationInfo";
    } else {
      LoginService.getUserInformation("accountActivationInfo");
    }
  },

  resfreshToken: function (callback) {
    if (LoginService.loginDataCheck()) {
      const data = {
        token: localStorage.getItem("token"),
        refreshToken: localStorage.getItem("refreshToken"),
      };

      const xhr = new DataService.createXHR({
        type: "POST",
        token: "Bearer",
        url: Resources.getValue("accountTokenRefreshApiURL"),
        data: JSON.stringify(data),
      });

      xhr.onerror = function () {
        Utils.loadingScreen("hide");
        LoginService.openErrorModal();
      };

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          // close loading when xhr done
          Utils.loadingScreen("hide");
          if (xhr.status == 200 && xhr.response) {
            const responseData = JSON.parse(xhr.response);
            if (responseData.result) {
              localStorage.setItem("token", responseData.token);
              localStorage.setItem("refreshToken", responseData.refreshToken);
              if (callback) {
                callback.apply(xhr);
              }
            }
          } else if (xhr.status == 401) {
            localStorage.removeItem("userInformation");
            localStorage.removeItem("userCompnayInformation");
            localStorage.getItem("googleEmail") !== null &&
              localStorage.removeItem("googleEmail");
            if (window.location.href.indexOf("/login") < 0) {
              if (window.location.pathname === "/foramind/map") {
                window.location.href =
                  Resources.getValue("appBaseEnvURL") +
                  "/login" +
                  "?redirectTo=" +
                  window.location.pathname +
                  "?mapId=" +
                  localStorage.getItem("openedMapId");
              } else if (
                window.location.pathname === "/foramind/sub-template-list"
              ) {
                window.location.href =
                  Resources.getValue("appBaseEnvURL") +
                  "/login" +
                  "?redirectTo=" +
                  "/foramind/template-list";
              } else if (
                window.location.pathname === "/foramind/subscription-detail"
              ) {
                localStorage.setItem('retrieveControlAfterLogin', 'retrieve');
                window.location.href =
                  Resources.getValue("appBaseEnvURL") +
                  "/login" +
                  "?redirectTo=" +
                  "/foramind/subscription-detail";
              } else if (
                window.location.pathname === "/subscription-detail" 
              ) {
                localStorage.setItem('retrieveControlAfterLogin', 'retrieve');
                window.location.href =
                  Resources.getValue("appBaseEnvURL") +
                  "/login" +
                  "?redirectTo=" +
                  "/subscription-detail";
              } else if (
                window.location.pathname === "/foramind/payment"
              ) {
                window.location.href =
                  Resources.getValue("appBaseEnvURL") +
                  "/login" +
                  "?redirectTo=" +
                  "/foramind/payment";
              } else if (
                window.location.pathname === "/payment" 
              ) {
                window.location.href =
                  Resources.getValue("appBaseEnvURL") +
                  "/login" +
                  "?redirectTo=" +
                  "/payment";
              } else if (
                window.location.pathname.split("/")[
                  window.location.pathname.split("/").length - 1
                ] === "public-share-link"
              ) {
                window.location.href =
                  Resources.getValue("appBaseEnvURL") +
                  "/login" +
                  "?redirectTo=" +
                  window.location.pathname +
                  "?mindMapId=" +
                  localStorage.getItem("openedMapId");
              } else {
                window.location.href =
                  Resources.getValue("appBaseEnvURL") +
                  "/login" +
                  "?redirectTo=" +
                  window.location.pathname;
              }
            }
          } else if (xhr.status == 400) {
            window.location.href =
              Resources.getValue("appBaseEnvURL") + "/mind-map-list";
          } else {
            LoginService.openErrorModal();
          }
        }
      };
    }
  },

  getToken: function (email, password, params) {
    let data = {};
    if (document.querySelector("#email")) {
      data = {
        email: document.querySelector("#email").value.toLowerCase(),
        password: document.querySelector("#password").value,
      };
    } else if (email) {
      data = {
        email: email.toLowerCase(),
        password: password,
      };
    } else {
      LoginService.redirectToLogin();
      return;
    }

    const xhr = new DataService.createXHR({
      type: "POST",
      url: Resources.getValue("accountLoginApiURL"),
      data: JSON.stringify(data),
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LoginService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.setItem("retrieveUrl", window.location.pathname);
            localStorage.setItem("isShowPopupForTrialDay", responseData.isShowTrialDayPopup);
            localStorage.setItem("a1Num", responseData.statusCode);
            localStorage.setItem("ab0c5", responseData.freeDays);
            localStorage.setItem("hw8w0", responseData.productId);
            localStorage.setItem("bhr05", responseData.productDay);
            localStorage.setItem("zt931", responseData.trialDay);
            localStorage.setItem("x8s88", responseData.remainingProductDays);
            //localStorage.setItem("77we1", responseData.isFreeUser);
            localStorage.setItem("c65s1", JSON.stringify(responseData.companySubscriptionInfo));
            localStorage.setItem("token", responseData.token);
            localStorage.setItem("refreshToken", responseData.refreshToken);
            localStorage.setItem(
              "userRoleIdList",
              JSON.stringify(responseData.roleIdList)
            );
            if (document.querySelector("#email")) {
              localStorage.setItem(
                "userMail",
                document.querySelector("#email").value.toLowerCase()
              );
            }
            if (localStorage.getItem("sharedAnonymusData")) {
              LoginService.getUserInformation(
                "?privateKey=" +
                  JSON.parse(localStorage.getItem("sharedAnonymusData"))
                    .privateKey +
                  "&email=" +
                  JSON.parse(localStorage.getItem("sharedAnonymusData")).email +
                  "&mindMapId=" +
                  JSON.parse(localStorage.getItem("sharedAnonymusData"))
                    .mindMapId
              );
            } else {
              LoginService.getUserInformation(params);
            }

          }
        } else if (
          xhr.status == 400 &&
          JSON.parse(xhr.response).statusCode == 404
        ) {
          LoginService.openUserNotFoundWarningModal();
        } else if (
          xhr.status == 400 &&
          JSON.parse(xhr.response).statusCode != 3
        ) {
          LoginService.openWarningModal();
        } else if (
          xhr.status == 400 &&
          JSON.parse(xhr.response).statusCode == 3
        ) {
          LoginService.openAccountActivationErrorModal();
        } else {
          LoginService.openErrorModal();
        }
      }
    };
  },

  getUserInformation: function (params) {
    const _params = params ? "?params=" + params : "";

    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("accountDetailApiURL"),
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LoginService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.setItem("loginType", responseData.user.loginType);
            localStorage.setItem(
              "userInformation",
              JSON.stringify(responseData.user)
            );
            localStorage.setItem(
              "userCompanyInformation",
              JSON.stringify(responseData.company)
            );
            localStorage.setItem(
                "c65s1",
                JSON.stringify(responseData.companySubscription)
            );
            let userRoleIdList = JSON.parse(localStorage.getItem("userRoleIdList")) || [];

            // Yeni userTypeId değerini diziye ekle
            userRoleIdList.push(responseData.user.userTypeId);

            // Güncellenmiş diziyi localStorage'a kaydet
            localStorage.setItem("userRoleIdList", JSON.stringify(userRoleIdList));
            LoginService.baseRedirect(_params);
          }
        } else {
          LoginService.openErrorModal();
        }
      }
    };
  },

  getUserInformationPromise: function (params) {
    const _params = params ? "?params=" + params : "";
    
    return new Promise((resolve, reject) => {
      const xhr = new DataService.createXHR({
        type: "GET",
        token: "Bearer",
        url: Resources.getValue("accountDetailApiURL"),
      });

      xhr.onerror = function () {
        Utils.loadingScreen("hide");
        LoginService.openErrorModal();
        reject(new Error("XHR error"));
      };

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          // close loading when xhr done
          Utils.loadingScreen("hide");
          if (xhr.status == 200 && xhr.response) {
            const responseData = JSON.parse(xhr.response);
            if (responseData.result) {
              localStorage.setItem("loginType", responseData.user.loginType);
              localStorage.setItem("userId", responseData.user.id);
              localStorage.setItem(
                  "userInformation",
                  JSON.stringify(responseData.user)
              );
              localStorage.setItem(
                  "userCompanyInformation",
                  JSON.stringify(responseData.company)
              );
              LoginService.baseRedirect(_params);
              resolve(responseData.user.id);
            } else {
              LoginService.openErrorModal();
              reject(new Error("Response error"));
            }
          } else {
            LoginService.openErrorModal();
            reject(new Error("XHR status error"));
          }
        }
      };
    });
  },



  // get user information for google login
  getUserInformationForGoogleLogin: function () {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("accountDetailApiURL"),
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LoginService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.setItem("loginType", responseData.user.loginType);
            localStorage.setItem(
              "userInformation",
              JSON.stringify(responseData.user)
            );
            localStorage.setItem(
              "userCompanyInformation",
              JSON.stringify(responseData.company)
            );
          }
        } else {
          LoginService.openErrorModal();
        }
      }
    };
  },

  baseRedirect(params) {
    var _params = params ? "?params=" + params : "";
    // url has redirectTo qs?
    var redirectToQs = Utils.getParameterByName("redirectTo");
    if (redirectToQs) {
      if (localStorage.getItem("sharedAnonymusData")) {
        var baseURL = _params.split("?params=")[2];
        redirectToQs = redirectToQs + baseURL + "&isSave=" + true;
      }
      if (redirectToQs.indexOf("?") && _params) {
        redirectToQs = redirectToQs + _params.replace("?", "&");
      }
      window.location.href = redirectToQs;
    } else {
      if(Utils.getParameterByName("continueWith") === 'payment') {
        window.location.href = Resources.getValue('appBaseEnvURL') + '/payment';
      } else {
        window.location.href = Resources.getValue("appBaseEnvURL") + "/mind-map-list" + _params;
      }
    }
  },

  loginDataCheck: function () {
    let result = false;

    if (
      localStorage.getItem("token") &&
      localStorage.getItem("refreshToken") &&
      localStorage.getItem("userInformation")
    ) {
      result = true;
    }

    return result;
  },

  accountActivationErrorService: function () {
    const accountActivation = {
      email: document.querySelector("#email").value.toLowerCase(),
    };

    const xhr = new DataService.createXHR({
      type: "POST",
      url: Resources.getValue("accountActivateError"),
      data: JSON.stringify(accountActivation),
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LoginService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            LoginService.openAccountActivateSuccessModal();
          }
        } else if (xhr.status == 400) {
          LoginService.openWarningModal();
        } else {
          LoginService.openErrorModal();
        }
      }
    };
    Utils.modalm().close();
  },

  googleLogin: function (data, params, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("googleLoginApiURL") + "?token=" + data,
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LoginService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.setItem("isShowPopupForTrialDay", responseData.isShowTrialDayPopup);
            localStorage.setItem("a1Num", responseData.statusCode);
            localStorage.setItem("ab0c5", responseData.freeDays);
            localStorage.setItem("hw8w0", responseData.productId);
            localStorage.setItem("bhr05", responseData.productDay);
            localStorage.setItem("zt931", responseData.trialDay);
            localStorage.setItem("x8s88", responseData.remainingProductDays);
            //localStorage.setItem("77we1", responseData.isFreeUser);
            localStorage.setItem("c65s1", JSON.stringify(responseData.companySubscriptionInfo));
            localStorage.setItem("token", responseData.token);
            localStorage.setItem("refreshToken", responseData.refreshToken);
            localStorage.setItem(
              "userRoleIdList",
              JSON.stringify(responseData.roleIdList)
            );
            localStorage.setItem("googleEmail", responseData.email);
            localStorage.setItem("userMail", responseData.email);
            // LoginService.getUserInformationForGoogleLogin();
            setTimeout(() => {
              LoginService.getUserInformationForGoogleLogin();
              LoginService.googleLoginUserTypeControl();
            }, 200);
          }
        } else if (xhr.status == 400) {
          LoginService.openWarningModal();
        } else {
          LoginService.openErrorModal();
        }
      }
    };
  },

  azureLogin: function (data, params, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("azureLoginApiURL") + "?token=" + data,
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LoginService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.setItem("isShowPopupForTrialDay", responseData.isShowTrialDayPopup);
            localStorage.setItem("a1Num", responseData.statusCode);
            localStorage.setItem("ab0c5", responseData.freeDays);
            localStorage.setItem("hw8w0", responseData.productId);
            localStorage.setItem("bhr05", responseData.productDay);
            localStorage.setItem("zt931", responseData.trialDay);
            localStorage.setItem("x8s88", responseData.remainingProductDays);
            //localStorage.setItem("77we1", responseData.isFreeUser);
            localStorage.setItem("c65s1", JSON.stringify(responseData.companySubscriptionInfo));
            localStorage.setItem("token", responseData.token);
            localStorage.setItem("refreshToken", responseData.refreshToken);
            localStorage.setItem(
                "userRoleIdList",
                JSON.stringify(responseData.roleIdList)
            );
            localStorage.setItem("googleEmail", responseData.email);
            localStorage.setItem("userMail", responseData.email);
            // LoginService.getUserInformationForGoogleLogin();
            setTimeout(() => {
              LoginService.getUserInformationForGoogleLogin();
              LoginService.googleLoginUserTypeControl();
            }, 200);
          }
        } else if (xhr.status == 400) {
          LoginService.openWarningModal();
        } else {
          LoginService.openErrorModal();
        }
      }
    };
  },


  // google login user type control
  googleLoginUserTypeControl: function (params) {
    const _params = params ? "?params=" + params : "";
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("googleLoginUserTypeControlApiURL"),
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LoginService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          LoginService.getUserInformationForGoogleLogin();
          const responseData = JSON.parse(xhr.response);
          if (responseData.data) {
            if (
                responseData.data.hasSetUserType === false
              ) {
                window.location.reload();
            } else {
              if (localStorage.getItem("sharedAnonymusData")) {
                LoginService.getUserInformation(
                  "?privateKey=" +
                    JSON.parse(localStorage.getItem("sharedAnonymusData"))
                      .privateKey +
                    "&email=" +
                    JSON.parse(localStorage.getItem("sharedAnonymusData")).email +
                    "&mindMapId=" +
                    JSON.parse(localStorage.getItem("sharedAnonymusData"))
                      .mindMapId
                );
              } else {
                LoginService.baseRedirect(_params);
              }
            }
          }
        } else if (xhr.status == 400) {
          LoginService.openWarningModal();
        } else {
          LoginService.openErrorModal();
        }
      }
    };
  },

  // google login update user type
  googleLoginUpdateUserType: function (data, params, _this) {
    const _params = params ? "?params=" + params : "";
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url:
        Resources.getValue("googleLoginUpdateUserTypeApiURL") +
        "?userTypeId=" +
        data.userTypeId +
        "&branchName=" +
        data.branchName +
        "&className=" +
        data.className,
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LoginService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          LoginService.baseRedirect(_params);
        } else if (xhr.status == 400) {
          LoginService.openWarningModal();
        } else {
          LoginService.openErrorModal();
        }
      }
    };
  },

  redirectToLogin: function () {
    if (localStorage.getItem("sharedAnonymusData")) {
      window.location.href =
        Resources.getValue("appBaseEnvURL") +
        "/login" +
        "?redirectTo=" +
        window.location.pathname +
        "&privateKey=" +
        JSON.parse(localStorage.getItem("sharedAnonymusData")).privateKey +
        "&email=" +
        JSON.parse(localStorage.getItem("sharedAnonymusData")).email +
        "&mindMapId=" +
        JSON.parse(localStorage.getItem("sharedAnonymusData")).mindMapId;
      localStorage.setItem("isNewMap", "");
    } else {
      if (
        window.location.pathname.split("/")[
          window.location.pathname.split("/").length - 1
        ] === "public-share-link"
      ) {
        window.location.href =
          Resources.getValue("appBaseEnvURL") +
          "/login" +
          "?redirectTo=" +
          window.location.pathname +
          "?mindMapId=" +
          localStorage.getItem("openedMapId");
      } else {
        window.location.href =
          Resources.getValue("appBaseEnvURL") +
          "/login" +
          "?redirectTo=" +
          window.location.pathname;
      }
    }
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("loginErrormodalMsgTxt") + "</p>",
    });
  },

  openAccountActivationErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message:
        "<p>" +
        Resources.getValue("accountActivationErrorModalMsgTxt") +
        "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button fresh-button reject-button",
          href: "",
        },
        {
          text: Resources.getValue("sendAgainMsgTxt"),
          class: "button yellow-button confirm-button ",
          href: "",
        },
      ],
      confirmCallback: LoginService.accountActivationErrorService,
      rejectCallback: null,
      scope: null,
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openUserNotFoundWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message:
        "<p>" + Resources.getValue("userNotFoundWarningmodalMsgTxt") + "</p>",
    });
  },

  openAccountActivateSuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + Resources.getValue("successAccountModal") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/login",
        },
      ],
    });
  },
};

export default LoginService;
