import MM from "../../libraries/map";
import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import { toast } from "react-toastify";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";
import SuccessModal from "../../components/modals/success-modal";

var MapService = {
  create: function (data, mapName) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("mapCreateApiURL"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.setItem("openedMapName", responseData.mindMap.name);
            localStorage.setItem("openedMapId", responseData.mindMap.id);
            localStorage.setItem("userIsOwner", true);
            localStorage.removeItem("newMapName");
            localStorage.setItem("isNewMap", true);
            localStorage.setItem(
              "mapJsonObj",
              JSON.stringify(responseData.mindMap)
            );
            let createdMapId = JSON.parse(
              JSON.stringify(responseData.mindMap.content)
            )
              .split('"id":"')[1]
              .split('"')[0];
            localStorage.setItem("createdNewMapContentId", createdMapId);
            localStorage.setItem(
              "blankMapContent",
              '{backgroundName: "", "pageBgImage": "", "root":{"id":"' +
                localStorage.getItem("createdNewMapContentId") +
                '","text":"yeni","layout":"map","collapsed":false,"nodeBackground":true,"imageItem":false,"nodeLine":true}}'
            );
            if (mapName !== "yeni") {
              let currentContent = JSON.parse(responseData.mindMap.content);
              currentContent["backgroundName"] =
                responseData.mindMap.backgroundName;
              currentContent["pageBgImage"] =
                responseData.mindMap.backgroundName;
              localStorage.setItem("content", JSON.stringify(currentContent));
            }
            window.location.href =
              Resources.getValue("appBaseEnvURL") +
              "/map?mapId=" +
              responseData.mindMap.id;
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else if (xhr.status == 409) {
          MapService.openExistNameErrorModal(mapName);
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  getMapList: function (userId, recordSize, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url:
        Resources.getValue("mapGetAllListApiURL") +
        "?id=" +
        userId +
        "&recordSize=" +
        recordSize,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  // duplicate my map with name change
  saveACopyOfMyMap: function (data, mapName) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("saveACopyOfMyMap"),
      data: JSON.stringify(data),
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.isSuccess) {
            window.location.href =
              Resources.getValue("appBaseEnvURL") + "/mind-map-list";
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.paymentStatusCode();
          } else {
            MapService.openWarningAnonymousModal();
          }
        } else if (xhr.status == 409) {
          MapService.openExistNameErrorModal(mapName);
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  getMap: function (mapId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("mapGetByIdApiURL") + "?mindMapId=" + mapId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            if (responseData.sharedMindMapMail === null) {
              localStorage.setItem("userIsOwner", true);
            } else {
              localStorage.setItem("userIsOwner", false);
            }
            localStorage.setItem("openedMapName", responseData.mindMap.name);
            document.title = localStorage.getItem("openedMapName");
            localStorage.setItem(
              "blankMapContent",
              '{backgroundName: "", "pageBgImage": "", "root":{"id":"' +
                localStorage.getItem("createdNewMapContentId") +
                '","text":"yeni","layout":"map","collapsed":false,"nodeBackground":true,"imageItem":false,"nodeLine":true}}'
            );
            localStorage.setItem(
              "isMapPublicControl",
              responseData.mindMap.isPublicMap
            );
            localStorage.setItem(
              "isMapPublic",
              responseData.mindMap.isPublicMap
            );
            const currentContent = JSON.parse(responseData.mindMap.content);
            currentContent["backgroundName"] =
              responseData.mindMap.backgroundName;
            currentContent["pageBgImage"] = responseData.mindMap.backgroundName;
            localStorage.setItem("content", JSON.stringify(currentContent));
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          }
          if (
            responseData.errors.length &&
            responseData.errors[0] ===
              "You do not have permission to edit the map"
          ) {
            // duzenleme yetkisi yoksa
            MapService.openNotEditingAuthWarningModal();
          } else if (
            responseData.errors.length &&
            responseData.errors[0] === "You are not authorized to view the map."
          ) {
            // --- farkli kullanici ile giris yapildiysa
            MapService.openNotAuthorizedWarningModal();
          } else if (
            responseData.errors.length &&
            responseData.errors[0].includes("Cannot find Mind Map Id =")
          ) {
            // -- harita bulunamadi (silinmis / yetki kaldirilmis)
            MapService.openNotFindMapWarningModal();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  getMapForContent: function (mapId, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("mapGetByIdApiURL") + "?mindMapId=" + mapId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          const contentOfMap = responseData.mindMap.content;
          localStorage.setItem("contentOfMap", contentOfMap);
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  getMapPresentItemList: function (mapId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url:
        Resources.getValue("mapGetAllPresentItemListApiURL") +
        "?mindMapId=" +
        mapId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.isSuccess) {
            callback.apply(xhr);
            // localStorage.setItem("mapPresentDatasStore", JSON.stringify(responseData.data.presentationNodes));
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },
  CreateMindMapPresentation: function (data, message, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("mapCreatePresentItemListApiURL"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.isSuccess) {
            MapService.openUpdatePresentSuccessModal(message);
          }
        } else if (xhr.status == 400) {
          MapService.openWarningModal();
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },
  updateMindMapByPermission: function (mapModel, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "PUT",
      token: "Bearer",
      url: Resources.getValue("updateMindMapByPermission"),
      keepScope: _this,
      data: JSON.stringify(mapModel),
      loadingAnimation: false,
    });

    xhr.onerror = function () {
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        if (xhr.status == 200 && xhr.response) {
          var mapObj = JSON.parse(localStorage.getItem("mapJsonObj"));
          if (mapObj.isMapShared === true) {
            (function () {
              if (
                MM.UI.Backend.File.sync.getHubConnectionStatus() !== "syncSave"
              ) {
                MM.UI.Backend.File.sync.init();
              } else {
                MM.UI.Backend.File.sync.setHubConnectionStatus("deActive");
              }
            })();
          }
          const responseData = JSON.parse(xhr.response);
          localStorage.setItem("openedMapName", responseData.mindMap.name);
          localStorage.setItem(
            "createdNewMapContentId",
            JSON.parse(responseData.mindMap.content).root.id
          );
          localStorage.setItem(
            "isMapPublicControl",
            responseData.mindMap.isPublicMap
          );
          localStorage.setItem(
            "blankMapContent",
            '{backgroundName: "", "pageBgImage": "", "root":{"id":"' +
              JSON.parse(responseData.mindMap.content).root.id +
              '","text":"yeni","layout":"map","collapsed":false,"nodeBackground":true,"imageItem":false,"nodeLine":true}}'
          );
          let currentContent = JSON.parse(responseData.mindMap.content);
          currentContent["backgroundName"] =
            responseData.mindMap.backgroundName;
          currentContent["pageBgImage"] = responseData.mindMap.backgroundName;
          localStorage.setItem("content", JSON.stringify(currentContent));
          if (responseData.result) {
            if (callback) {
              callback.apply(xhr);
            }
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else if (
            responseData.result === false &&
            responseData.errors[0] === "You are not authorized to view the map."
          ) {
            // yetki kaldirildiysa / harita silindiyse
            MapService.openNotAuthorizedWarningModal();
          } else if (
            responseData.result === false &&
            responseData.errors[0] === "Cannot find Mind Map Id ="
          ) {
            // yetki kaldirildiysa / harita silindiyse
            MapService.openNotFindMapWarningModal();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  updateMapName: function (mapId, mapName, callback, _this, updateName) {
    const xhr = new DataService.createXHR({
      type: "PUT",
      token: "Bearer",
      url:
        Resources.getValue("mapUpdateNameApiURL") +
        "?mindMapId=" +
        mapId +
        "&name=" +
        mapName,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
            localStorage.setItem("openedMapName", mapName);
            document.title = localStorage.getItem("openedMapName");
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else if (xhr.status == 409) {
          MapService.openExistNameErrorModal(updateName);
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  mapPageUpdateName: function (mapId, mapName, callback, _this, updateName) {
    const xhr = new DataService.createXHR({
      type: "PUT",
      token: "Bearer",
      url:
        Resources.getValue("mapUpdateNameApiURL") +
        "?mindMapId=" +
        mapId +
        "&name=" +
        mapName,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.setItem("openedMapName", responseData.mindMap.name);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else if (xhr.status == 409) {
          MapService.openExistNameErrorModal(updateName);
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  delete: function (mapId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "DELETE",
      token: "Bearer",
      url: Resources.getValue("mapDeleteByIdApiURL") + "?mindMapId=" + mapId,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(_this);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  imageFileUpload: function (fileModel) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("mapImageUploadApiURL"),
      data: fileModel,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          var responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            const imageUploadElement =
              document.querySelector(".map-image-upload");
            if (imageUploadElement.getAttribute("data-image-item")) {
              const side = imageUploadElement.getAttribute(
                "data-image-item-side"
              );
              const newItem = MM.Command.InsertChild.execute(side, false);
              newItem._dom.node.classList.add("none");
              setTimeout(function () {
                const imageAction = new MM.Action.SetImage(
                  MM.App.current,
                  responseData.path || null
                );
                newItem._dom.node.classList.remove("none");
                MM.App.action(imageAction);
                MM.App.current.imageItem();
                MM.App.current._parent.update();
                MM.App.current._parent.deselect();
              }, 50);
              imageUploadElement.removeAttribute("data-image-item");
              imageUploadElement.removeAttribute("data-image-item-side");
            } else {
              var action = new MM.Action.SetImage(
                MM.App.current,
                responseData.path || null
              );
              MM.App.action(action);
            }

            setTimeout(function () {
              // check space for item
              MM.Map.checkNodeSpace(MM.App.current, "child-added");
              // detect intersect for active node's item object
              MM.Map.detectIntersection(MM.App.current);
            }, 500);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          MapService.openWarningModal();
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  imageFileDelete: function (fileId) {
    const xhr = new DataService.createXHR({
      type: "DELETE",
      token: "Bearer",
      url: Resources.getValue("mapImageDeleteApiURL") + "?fileName=" + fileId,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            // file deleted
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          MapService.openWarningModal();
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  AddRemoveFavorite: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("addRemoveFavoriteUrl"),
      keepScope: _this,
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200) {
          toast.dismiss();
          const toastSettings = {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          };
          if (JSON.parse(data).isFavorite === true) {
            toast.success(
              Resources.getValue("mapAddedToFavoritesInfoMsgTxt"),
              toastSettings
            );
          } else {
            toast.success(
              Resources.getValue("mapRemovedFromFavoritesInfoMsgTxt"),
              toastSettings
            );
          }
          callback.apply(xhr);
        } else if (xhr.status == 400) {
          MapService.openWarningModal();
        }
      }
    };
  },

  SetPublicOrPrivateMap: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url:
        Resources.getValue("setPublicOrPrivateMapUrl") +
        "?mindMapId=" +
        data.mindMapId +
        "&isPublicMap=" +
        data.isPublicMap,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200) {
          toast.dismiss();
          const toastSettings = {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          };
          if (data.isPublicMap === true) {
            toast.success(
              Resources.getValue("mapMadePublicInfoMsgTxt"),
              toastSettings
            );
          } else {
            toast.success(
              Resources.getValue("mapMadePrivateInfoMsgTxt"),
              toastSettings
            );
          }
          callback.apply(xhr);
        } else if (xhr.status == 400) {
          MapService.openWarningModal();
        }
      }
    };
  },

  // yeni paylas
  shareMapToUsers: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("shareMapToUsers"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            if (responseData.errors != null) {
              MapService.openSharedWarningModal(responseData.errors[0]);
            } else {
              MapService.openSharedSuccessModal();
            }
          }
        } else if (xhr.status == 400) {
          MapService.openWarningModal();
        } else if (xhr.status == 409) {
          MapService.openExistEmailErrorModal();
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },
  getIsDownloadableMap: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("getIsDownloadableMap") + "?mindMapId=" + data,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },
  getSharedUserList: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("getSharedUserList") + "?mindMapId=" + data,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  updateUserMapPermission: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("updateUserMapPermission"),
      keepScope: _this,
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            // done
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  removeUserFromMap: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("removeUserFromMap"),
      keepScope: _this,
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            // done
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            MapService.openWarningPaymentStatusCode();
          } else {
            MapService.openWarningModal();
          }
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  publicSharedMindMapById: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("sharePublicMindMapByEmail"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            MapService.openSharedSuccessModal();
          }
        } else if (xhr.status == 400) {
          MapService.openWarningModal();
        } else if (xhr.status == 409) {
          MapService.openExistEmailErrorModal();
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  getPublicShareLink: function (url, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("createPublicShareLink") + "?url=" + url,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
            document.querySelector(".editor-header-tools").innerText =
              localStorage.getItem("openedMapName");
          }
        } else if (xhr.status == 400) {
          MapService.openWarningModal();
        } else if (xhr.status == 409) {
          MapService.openExistEmailErrorModal();
        } else {
          MapService.openErrorModal();
        }
      }
    };
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openSharedSuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + Resources.getValue("sharedsuccessmapMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
      confirmCallback: window.location.reload(),
    });
  },
  openSharedWarningModal: function (data) {
    WarningModal({
      title: Resources.getValue("warningSendMailMsgTxt"),
      message:
        "<p>" + data + Resources.getValue("sharedwarningmapMsgTxt") + "</p>",
    });
  },
  openUpdateSuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + Resources.getValue("updatesuccessmapMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
    });
  },
  openUpdatePresentSuccessModal: function (message) {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + message + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
    });
  },
  openExistNameErrorModal: function (mapName) {
    let changeUrl;
    if (mapName === "createMap") {
      changeUrl = "/template-list";
    } else {
      changeUrl = "dashboard";
    }

    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("existNameErrorModalMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + changeUrl,
        },
      ],
    });
  },

  openExistEmailErrorModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message:
        "<p>" + Resources.getValue("existEMailErrorModalMsgTxt") + "</p>",
    });
  },

  openNotAuthorizedWarningModal: function () {
    WarningModal({
      title: Resources.getValue("modalTitleInfoMsgTxt"),
      message: "<p>" + Resources.getValue("youCanNotSeeThisMapMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("goToMyMapsMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/mind-map-list",
        },
      ],
    });
  },

  openNotFindMapWarningModal: function () {
    WarningModal({
      title: Resources.getValue("modalTitleInfoMsgTxt"),
      message: "<p>" + Resources.getValue("youNotFindMapMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("goToMyMapsMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/mind-map-list",
        },
      ],
    });
  },

  openNotEditingAuthWarningModal: function () {
    WarningModal({
      title: Resources.getValue("warningMsgTxt"),
      message: "<p>" + Resources.getValue("youCanNotEditMapMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href:
            Resources.getValue("appBaseEnvURL") +
            "/shared-map" +
            "?id=" +
            JSON.parse(localStorage.getItem("userInformation")).id +
            "&mindMapId=" +
            localStorage.getItem("openedMapId") +
            "&sharedMap=" +
            true,
        },
      ],
    });
  },

  openWarningPaymentStatusCode: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("paymentStatusModalMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/payment",
        },
      ],
      redirectAfterClose: Resources.getValue("appBaseEnvURL") + "/payment",
    });
  },
};

export default MapService;
