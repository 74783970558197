import React, { Component } from "react";
import Resources from "../libraries/resources";

class Footer extends Component {

  render() {
    return (
      <div className="landing-page-footer">
        <div className="container">
          <div className="text-center text-white">
            Copyright © 2021, {Resources.getValue("kafeinTechMsgTxt")}
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;
