import React from "react";
// import MM from "../libraries/shared-map";
import MM from "../libraries/map";
import Header from "../components/header";
import AnonymosSharedTip from "../components/anonymous-shared-tip";
import AnonymousSharedToolbox from "../components/anonymous-shared-toolbox";
import Utils from "../libraries/utils";
import SharedMapService from "../services/api/shared-map";
import LoginService from "../services/api/login";
import PublicShareLinkToolbox from "../components/publicShareLinkToolbox";
import PublicShareLinkTip from "../components/publick-share-link-tip";
import Resources from "../libraries/resources";

class PublicShareLink extends React.Component {

  constructor() {
    super();

    localStorage.setItem("openedMapId", Utils.getParameterByName("mindMapId"));
  }

  componentDidMount() {
    MM.App.init();
    MM.App.io.restore();
    if(Utils.getParameterByName("mindMapId") === null) {
      document.querySelector('.toolbox').classList.add('none');
      Utils.modalm().open({
				title: Resources.getValue("errorMsgTxt"),
				bodyContent: "<p>" + Resources.getValue("mapNotFoundMsgTxt") + "</p>",
				buttons: [
					{
						text: Resources.getValue("okMsgTxt"),
						class: "button  yellow-button confirm-button",
						href: Resources.getValue("appBaseEnvURL") + "/mind-map-list",
					},
				],
				confirmCallback: null,
				rejectCallback: null
			});
    } else {
      this.checkOpenedMap();
    }
  };


  mapPageStyle() {
    document.querySelector('body').style.overflow = 'hidden';
  };

  checkOpenedMap() {
    var mindMapId   = Utils.getParameterByName('mindMapId');

    if(mindMapId ){
      SharedMapService.getPublicMindMapById(mindMapId,this.drawOpenedMap);
    }

    // var sharedAnonymusData = {
    //   mindMapId,
    // }
    // sessionStorage.setItem("sharedAnonymusData",JSON.stringify(sharedAnonymusData))
    // var userId      = sessionStorage.getItem('userInformation');
    // if(userId){
    //   if(JSON.parse(userId).id){
    //     if(mindMapId ){
    //       SharedMapService.getPublicMindMapById( mindMapId);
    //     }
    //   }
    // }else{
    //   LoginService.login()
    // }
  };

  drawOpenedMap() {
    var mapData = JSON.parse(this.response).mindMap.content;
    var mapBackgroundImage = JSON.parse(this.response).mindMap.backgroundName;
    localStorage.setItem("openedMapName", JSON.parse(this.response).mindMap.name);
    document.title = localStorage.getItem("openedMapName");


    if(mapData){
      MM.UI.Backend._loadDone(JSON.parse(mapData));
    }

    if(mapBackgroundImage){
      document.querySelector("#port").style.background = mapBackgroundImage
    }
  };

  render() {
    this.mapPageStyle();
    return (
      <React.Fragment>
        <PublicShareLinkTip/>
        <PublicShareLinkToolbox />
      </React.Fragment>
    );
  };
}

export default PublicShareLink;
