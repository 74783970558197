import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";

export default function WarningModal(modalObj) {
  Utils.modalm().open({
    exitButtonText: Resources.getValue("exitMsgTxt"),
    title: modalObj.title || Resources.getValue("errorMsgTxt"),
    bodyContent:
      modalObj.message ||
      "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    buttons: modalObj.buttons || [
      {
        text: Resources.getValue("okMsgTxt"),
        class: "button yellow-button confirm-button",
        href: "",
      },
    ],
    confirmCallback: modalObj.confirmCallback,
    rejectCallback: modalObj.rejectCallback,
    scope: modalObj.scope,
    redirectAfterClose: modalObj.redirectAfterClose,
  });
}
