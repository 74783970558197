import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./guard/auth";
import Utils from "./libraries/utils";

// page components
import Login from "./pages/login";
import LoginLim from "./pages/login-lim";
import Register from "./pages/register";
// import Dashboard from "./pages/dashboard";
import Map from "./pages/map";
import SharedMap from "./pages/shared-map";
import AnonymousShared from "./pages/anonymous-shared";
import PublicShareLink from "./pages/public-share-link";
import ForgotPassword from "./pages/forgot-password";
import ChangePassword from "./pages/change-password";
import Profile from "./pages/profile";
import TemplateList from "./pages/template-list";
import SubTemplateList from "./pages/sub-template-list";
import MyPlanner from "./pages/my-planner-list";
import TaskBoards from "./pages/task-board-list";
import MindMap from "./pages/mind-map-list";
import MindMapShare from "./pages/mind-map-share-list";
import MyPlannerDashBoard from "./pages/my-planner-dashboard";
import NewPassword from "./pages/new-password";
import Resources from "./libraries/resources";
import Contact from "./pages/contact";
import LandingPage from "./pages/landing-page";
import EducationPage from "./pages/education-page";
import AboutPage from "./pages/about-page";
import BlogPage from "./pages/blog-page";
import FeaturesPage from "./pages/features-page";
import KvkkPage from "./pages/kvkk-page";
import MailingPage from "./pages/mailing";
import Payment from "./pages/payment";
import ChatGptPayment from "./pages/chat-gpt-payment"
import ChatGptSubscriptionDetail from "./pages/chat-gpt-subscription-detail";
import Retrieve from "./pages/retrieve";
import PaymentResult from "./pages/success-payment";
import SubscriptionDetail from "./pages/subscription-detail";
import SubscriptionHistory from "./pages/subscription-history";
import Help from "./pages/help-page";
// Hotjar
import HotjarClarifyingPage from "./pages/hotjar-clarifying-agreement"; // hotjar geribildirim aydinlatma metni
import HotjarExplicitConsentPage from "./pages/hotjar-explicit-consent-agreement"; // hotjar acik riza metni
import CookiePolicyPage from "./pages/cookie-policy-page"; // hotjar geribildirim aydinlatma metni
import DataOwnerFormPage from "./pages/data-owner-form-page"; // hotjar acik riza metni
// Cookie Consent
import CookieConsent from "react-cookie-consent";
import CookiePolicyText from "./components/cookie-policy-text";
import BlogDetailPage from "./pages/blog-detail-page";
import SampasEnerjiPage from "./pages/sampas-enerji";

class App extends React.Component {

  cookiePolicyTextModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("cookiePolicyMsgTxt"),
      bodyContent:
        '<div class="membership-agreement">' + CookiePolicyText() + "</div>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
    });
  }

  componentDidMount() {
    // tried to get current tab parameter
    window.addEventListener('DOMContentLoaded', () => {
      // console.log('tab acildi')
      // console.log('location href split ? :' + window.location.href.split('?')[1])
      // console.log('mapId: ' + Utils.getParameterByName("mapId"))
    });
    document.addEventListener('visibilitychange', () => {
      if(document.hidden){
          //
          return;
      }
      // console.log('baska tab a gecis yapildi')
      // console.log('location href split ? :' + window.location.href.split('?')[1])
      // console.log('mapId: ' + Utils.getParameterByName("mapId"))
    });
  }

  render() {
    return (
      <>
        <Router basename={Resources.getValue("appBaseEnvURL")}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/lim" component={LoginLim} />
            <Route path="/register" component={Register} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/new-password" component={NewPassword} />
            <Route path="/" exact component={LandingPage} />
            <Route path="/education" component={EducationPage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/blog" component={BlogPage} />
            <Route path="/features" component={FeaturesPage} />
            <Route path="/kvkk" component={KvkkPage} />
            <Route path="/anonymous-shared" component={AnonymousShared} />
            <Route path="/public-share-link" component={PublicShareLink} />
            <Route path="/mailing" component={MailingPage} />
            <Route path="/hotjar-acik-riza" component={HotjarExplicitConsentPage} />
            <Route path="/hotjar-geribildirim-aydinlatma" component={HotjarClarifyingPage} />
            <Route path="/cerez-politikasi" component={CookiePolicyPage} />
            <Route path="/veri-sahibi-basvuru-formu" component={DataOwnerFormPage} />
            <Route path="/subscription-detail" component={SubscriptionDetail} />
            <Route path="/subscription-history" component={SubscriptionHistory} />
            <Route path="/ai-subscription-detail" component={ChatGptSubscriptionDetail} />

            {/* sampas demo page */}
            <Route path="/enerji-tuketimi" component={SampasEnerjiPage} />

            <PrivateRoute
              path="/change-password"
              component={ChangePassword}
              customParams={{ paymentRequired: false }}
            />
            <PrivateRoute
              path="/help"
              component={Help}
              customParams={{ paymentRequired: false }}
            />
            {/* <PrivateRoute
              path="/dashboard"
              component={Dashboard}
              customParams={{ paymentRequired: false }}
            /> */}
            <PrivateRoute
              path="/profile"
              component={Profile}
              customParams={{ paymentRequired: false }}
            />
            <PrivateRoute
              path="/map"
              component={Map}
            
              customParams={{ paymentRequired: true }}
            />
            <PrivateRoute
              path="/shared-map"
            
              component={SharedMap}
              customParams={{ paymentRequired: true }}
            />
            <PrivateRoute
              path="/template-list"
            
              component={TemplateList}
              customParams={{ paymentRequired: true }}
            />
            <PrivateRoute
              path="/sub-template-list"
            
              component={SubTemplateList}
              customParams={{ paymentRequired: true }}
            />
            <PrivateRoute
              path="/my-planner-list"
              component={MyPlanner}
              customParams={{ paymentRequired: false }}
            />
            <PrivateRoute
              path="/task-board-list"
              component={TaskBoards}
              customParams={{ paymentRequired: false }}
            />
            <PrivateRoute
              path="/mind-map-list"
              component={MindMap}
            
              customParams={{ paymentRequired: true }}
            />
            <PrivateRoute
              path="/mind-map-share-list"
            
              component={MindMapShare}
              customParams={{ paymentRequired: true }}
            />
            <PrivateRoute
              path="/my-planner-dashboard"
              component={MyPlannerDashBoard}
              customParams={{ paymentRequired: false }}
            />
            <PrivateRoute
              path="/contact"
            
              component={Contact}
              customParams={{ paymentRequired: false }}
            />
            <PrivateRoute
              path="/payment"
            
              component={Payment}
              customParams={{ paymentRequired: false }}
            />

            <PrivateRoute
            path="/ai-payment"
          
            component={ChatGptPayment}
            customParams={{ paymentRequired: true }}
          />

            <PrivateRoute
              path="/retrieve"
            
              component={Retrieve}
              customParams={{ paymentRequired: false }}
            />
            <PrivateRoute
              path="/successpayment"
            
              component={PaymentResult}
              customParams={{ paymentRequired: true }}
            />
            <Redirect from="/landing-page" to="/" />
            <Route
              path="/:slug"
              component={BlogDetailPage}
            />
            <Redirect from="/" to="/" />
            
          </Switch>
        </Router>

        <CookieConsent
          // debug={true}
          location="bottom"
          buttonText={Resources.getValue("closeMsgTxt")}
          cookieName="CookieConsent"
          style={{ background: "rgba(43, 55, 59, 0.75)", zIndex: '1000' }}
          buttonStyle={{ color: "#fff", fontSize: "12px", background: '#f5701f' }}
          expires={60}
        >
          <span style={{fontSize: '13px'}}>
            {Resources.getValue("cookieConsentInfoTextMsgTxt")} {' '}
            <a onClick={() => {this.cookiePolicyTextModal()}} style={{color: '#33BDD8', textDecoration: 'underline', fontWeight: 'bold'}}>
              {Resources.getValue("cookiePolicyMsgTxt")}
            </a>
          </span>
        </CookieConsent>
      </>
    );
  }
}

export default App;
