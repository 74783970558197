import React, { Component } from "react";
import Resources from "../libraries/resources";
import Utils from "../libraries/utils";
import MapService from "../services/api/map";

class ChangeMapNameModal extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.openModal();
    this.inputTextHandle();
  }

  warningModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("warningSendMailMsgTxt"),
      bodyContent: Resources.getValue("warningmodalMsgTxt"),
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: 'button yellow-button confirm-button',
          href: ''
        },
      ],
    });
  }

  openModal() {
    var _this = this;
    document.querySelector(".close-modal").addEventListener("click", function () {
      _this.props.sharedClick(false);
      localStorage.setItem('isCustomModalOpen', false);
    })
  }

  changeThisMapName() {
    if(document.querySelector(".shared-map-name").value !== "yeni") {
      document.querySelector('.update-btn').removeAttribute('disabled');
      var mapId =
        Utils.getParameterByName("mapId") || localStorage.getItem("openedMapId");
      var newMapName = document.querySelector(".shared-map-name").value;
      var updateName = "updateName";
      localStorage.setItem('openedMapName', newMapName);
      MapService.mapPageUpdateName(mapId, newMapName, this, updateName);
      document.querySelector(".editor-header-tools").value = localStorage.getItem(
        "openedMapName"
      );
      setTimeout(() => {
        var mapOwner = JSON.parse(localStorage.getItem("userIsOwner"));
        if(JSON.parse(localStorage.getItem("willGoToNewMapPage")) === true) {
          window.location.href = Resources.getValue("appBaseEnvURL") + "/template-list";
        } else {
          mapOwner 
          ? window.location.href = Resources.getValue("appBaseEnvURL") + "/mind-map-list"
          : window.location.href = Resources.getValue("appBaseEnvURL") + "/mind-map-share-list"
        }
      }, 500);
    } else {
      document.querySelector('.update-btn').setAttribute('disabled', '');
      return;
    }
  }

  closeMapNameModal() {
    var mapOwner = JSON.parse(localStorage.getItem("userIsOwner"));
    if(JSON.parse(localStorage.getItem("willGoToNewMapPage")) === true) {
      window.location.href = Resources.getValue("appBaseEnvURL") + "/template-list";
    } else {
      mapOwner 
      ? window.location.href = Resources.getValue("appBaseEnvURL") + "/mind-map-list"
      : window.location.href = Resources.getValue("appBaseEnvURL") + "/mind-map-share-list"
    }
  }

  inputTextHandle() {
    if (document.querySelector(".shared-map-name").value === "yeni" || document.querySelector(".shared-map-name").value === "") {
      document.querySelector('.mail-save-btn.update-btn').setAttribute('disabled', '');
    } else {
      document.querySelector('.mail-save-btn.update-btn').removeAttribute('disabled');
    }
  }


  render() {
    return (
      <div className="overlay">
        <div className="popup change-map-name-modal">
          <div className="title">
            <span className="fa-stack fa-1x icon-wrap">
              <i className="fa fa-circle fa-stack-2x circle-icon"></i>
              <i className="fa fa-external-link fa-stack-1x sitemap-icon"></i>
            </span>
            <div className="text">
              {Resources.getValue("changeMapNameMsgText")} 
						</div>
          </div>
          <a className="close close-modal" onClick={this.props.handler}>&times;</a>
          <div className="select-shared">
            <ul>
              <li className="buttons shared-select">
                <div className="email-tab-contents">
                  <div className="email-box active">
                    <div className="popup-input mail-input">
                      <p>
                        {Resources.getValue("changeMapNameModalInfoMsgTxt")} 
                        <br /> 
                        {Resources.getValue("wantToChangeMapNameMsgTxt")}
                      </p>
                    </div>
                    <div className="popup-input mail-input">
                      <input type="text" className="shared-map-name" 
                        onChange={() => {this.inputTextHandle()}}
                        placeholder={Resources.getValue("blankMsgText")} 
                        defaultValue={localStorage.getItem("openedMapName")}
                      />
                    </div>
                    <div className="popup-input button-wrap">
                      <button className="mail-save-btn update-btn" onClick={this.changeThisMapName}>
                        {Resources.getValue("updateMsgTxt")}
                      </button>
                      <button className="mail-save-btn cancel-btn" onClick={this.closeMapNameModal}>
                        {Resources.getValue("goOnWithoutUpdateMsgTxt")}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangeMapNameModal;
