import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";
import SuccessModal from "../../components/modals/success-modal";

var HelpPageService = {
  getHelpPageList: function (recordSize, callback,filterData, _this,title) { 
    const parameterizeArray = (key, arr) => {
      arr = arr.map(encodeURIComponent)
      return '&'+key+'=' + arr.join('&'+key+'=')
    }

    var url="";
    if(filterData.length > 0){
      url =Resources.getValue("getVideosListApiUrl") +
      "?" +
      parameterizeArray('title', filterData)
    }
    else{
      url =Resources.getValue("getVideosListApiUrl")
    }
    let responseData;
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: url,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status === 200 && xhr.response) {
          responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 400) {
          HelpPageService.openWarningModal();
        } else {
          HelpPageService.openErrorModal();
        }
      }
    }
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  },
}

export default HelpPageService;
