import React, { Component } from "react";
import Utils from "../libraries/utils";
import "../libraries/jscolor";
import Resources from "../libraries/resources";
import MM from "../libraries/map"; // shared-map (eski)
import * as htmlToImage from "html-to-image";
import { FixedSizeList as List } from "react-window";
import SharedMapService from "../services/api/shared-map";
import MapService from "../services/api/map";
import LoginService from "../services/api/login";

import saveIcon from "../styles/img/save_alt_icon.png";
import copyIcon from "../styles/img/copy_icon.png";

class AnonymousSharedToolbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sharedMapData: null,
    };

    // localStorage.setItem('IsAnonymous', true);
  }

  componentDidMount() {
    var mindMapId =
      Utils.getParameterByName("mindMapId") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).mindMapId;
    var email =
      Utils.getParameterByName("email") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).email;
    var privateKey =
      Utils.getParameterByName("privateKey") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).privateKey;

    var sharedAnonymusData = {
      mindMapId,
      email,
      privateKey,
    };
    localStorage.setItem(
      "sharedAnonymusData",
      JSON.stringify(sharedAnonymusData)
    );
    
    this.getAnonymSharedMapInfo();
    var isSave = Utils.getParameterByName("isSave");
    if (isSave) {
      // this.saveMap();
    }
  }

  htmlToImage() {
    var leftPadding = 30;
    var topPadding = 30;

    var mapHeight = parseInt(
      document.querySelectorAll("#port > li > canvas")[0].getAttribute("height")
    );
    var mapWidth = parseInt(
      document.querySelectorAll("#port > li > canvas")[0].getAttribute("width")
    );
    var projectName = localStorage.getItem("openedMapName") + ".jpg";

    document.querySelector(".tip").style.display = "none";
    var options = document.querySelectorAll(".options");
    for (var i = 0; i < options.length; i++) {
      options[i].style.display = "none";
    }
    MM.App.map.move(leftPadding, topPadding);
    Utils.loadingScreen("show");

    htmlToImage
      .toJpeg(document.getElementById("port"), {
        quality: 1,
        width: mapWidth + leftPadding * 2,
        height: mapHeight + topPadding * 2,
      })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = projectName;
        link.href = dataUrl;
        link.click();
        document.querySelector(".tip").style.display = "block";
        for (var i = 0; i < options.length; i++) {
          options[i].style.display = "flex";
        }
        Utils.loadingScreen("hide");
        MM.App.map.center();
      });
  }

  saveMap() {
    var mindMapId =
      Utils.getParameterByName("mindMapId") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).mindMapId;
    var email =
      Utils.getParameterByName("email") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).email;
    var privateKey =
      Utils.getParameterByName("privateKey") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).privateKey;
    var userInformation = localStorage.getItem("userInformation");
    var sharedAnonymusData = {
      mindMapId,
      email,
      privateKey,
    };
    localStorage.setItem(
      "sharedAnonymusData",
      JSON.stringify(sharedAnonymusData)
    );
    if (userInformation) {
      if (JSON.parse(userInformation).id) {
        var data = {
          mindMapId: mindMapId,
          userId: JSON.parse(userInformation).id,
          privateKey: privateKey,
        };
        if (mindMapId && privateKey && email) {
          SharedMapService.saveMap(JSON.stringify(data));
        }
      }
    } else {
      LoginService.login();
    }
    // var userId      = JSON.parse(sessionStorage.getItem('userInformation')).id;
    // var data = {
    //   mindMapId : mindMapId,
    //   userId    : userId
    // }
    // SharedMapService.saveMap(JSON.stringify(data))
  }

  getAnonymSharedMapInfo() {
    var mindMapId =
      Utils.getParameterByName("mindMapId") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).mindMapId;
    var email =
      Utils.getParameterByName("email") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).email;
    var privateKey =
      Utils.getParameterByName("privateKey") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).privateKey;

    var sharedAnonymusData = {
      mindMapId,
      email,
      privateKey,
    };
    localStorage.setItem(
      "sharedAnonymusData",
      JSON.stringify(sharedAnonymusData)
    );

    SharedMapService.getSharedAnonymousMindMapAsync(
      privateKey,
      email,
      mindMapId,
      this.drawOpenedMap,
      this
    );
  }

  drawOpenedMap() {
    var _this = this;
    const data = JSON.parse(this.response).sharedMindMapMail;
    localStorage.setItem("mapJsonObj", JSON.stringify(data));
    _this.scope.setState({
      sharedMapData: data,
    });
    var mapData = JSON.parse(this.response).sharedMindMapMail.content;
    // var mapBackgroundImage = JSON.parse(this.response).sharedMindMapMail.backgroundName;

    document.querySelector(".editor-header-tools") && (document.querySelector(".editor-header-tools").innerText =
      localStorage.getItem("openedMapName"));

    if (mapData) {
      // MM.UI.Backend._loadDone(JSON.parse(mapData));
    }

    // if (mapBackgroundImage) {
    //   document.querySelector(".tip").style.background = mapBackgroundImage;
    // }
  }

  addRemoveFavorite(mapId, isFavStatus) {
    if (
      localStorage.getItem("userInformation") != undefined ||
      localStorage.getItem("userInformation") != null
    ) {
      const userId = JSON.parse(localStorage.getItem("userInformation")).id;
      const favData = {
        mindMapId: mapId,
        userId: userId,
        isFavorite: isFavStatus,
      };
      MapService.AddRemoveFavorite(
        JSON.stringify(favData),
        this.favCallback,
        this
      );
    } else {
      LoginService.login();
    }
  }

  favCallback() {
    var _this = this;
    _this.scope.getAnonymSharedMapInfo();
  }

  render() {
    const anonymousData = {
      sharedMindMapMail: {
        mindMapId: Utils.getParameterByName("mindMapId"),
        isFavorite: false,
      },
    };
    if (
      localStorage.getItem("mapJsonObj") === undefined ||
      localStorage.getItem("mapJsonObj") === null
    ) {
      localStorage.setItem("mapJsonObj", JSON.stringify(anonymousData));
    }

    var mapOwner = JSON.parse(localStorage.getItem("userIsOwner"));

    var backBtn = "";

    if (mapOwner) {
      backBtn = (
        <a
          href={Resources.getValue("appBaseEnvURL") + "/mind-map-list"}
          data-v-afb4cbea=""
          title="Back"
          className="back-btn"
        >
          <i className="fa fa-chevron-left back-icon"></i>
        </a>
      );
    } else {
      backBtn = (
        <a
          href={Resources.getValue("appBaseEnvURL") + "/mind-map-share-list"}
          data-v-afb4cbea=""
          title="Back"
          className="back-btn"
        >
          <i className="fa fa-chevron-left back-icon"></i>
        </a>
      );
    }

    return (
      <div className="toolbox">
        <div className="ui-wrapper">
          <div className="editor-header-left">
            {/* Change Map Name Input */}
            <div className="editor-wrapper">
              {backBtn}
              <div className="doc-name ellipsis">
                <h4
                  type="text"
                  maxLength="50"
                  className="map-name editor-header-tools"
                ></h4>
              </div>
            </div>
          </div>

          <div className="toolbox-option none">
            <input
              type="file"
              className="map-image-upload"
              accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            />
          </div>

          <div className="toolbox-option none">
            <label>{Resources.getValue("valueMsgTxtValue")}</label>
            <select id="value">
              <option value="">({Resources.getValue("noneMsgTxt")})</option>
              <option value="num">{Resources.getValue("numberMsgTxt")}</option>
              <optgroup label="Formula">
                <option value="sum">{Resources.getValue("sumMsgTxt")}</option>
                <option value="avg">
                  {Resources.getValue("avarageMsgTxt")}
                </option>
                <option value="min">{Resources.getValue("minMsgTxt")}</option>
                <option value="max">{Resources.getValue("maxMsgTxt")}</option>
              </optgroup>
            </select>
          </div>

          <div className="toolbox-option none">
            <label>{Resources.getValue("statusMsgTxt")}</label>
            <select id="status">
              <option value="">{Resources.getValue("noneMsgTxt")}</option>
              <option value="yes">{Resources.getValue("yesMsgTxt")}</option>
              <option value="no">{Resources.getValue("noMsgTxt")}</option>
              <option value="computed">
                {Resources.getValue("autocomputeMsgTxt")}
              </option>
            </select>
          </div>
        </div>

        {/* <button
          className="toogle-button fa fa-chevron-circle-left"
          title={Resources.getValue("toggleMsgTxt")}
        ></button> */}

        {/* <button
          data-command="Help"
          className="help-button toolbox-closed"
          title={Resources.getValue("showHideHelpPanelMsgTxt")}
        >
          <i className="fa fa-question" aria-hidden="true"></i>
        </button> */}

        {/* Share Button */}
        <div className="editor-header-right">
          <div className="ui">
            <div className="share-button-wrapper">
              <div
                className={`show-users fav-button${
                  localStorage.getItem("IsAnonymous") === "true" ? " none" : ""
                }`}
              >
                <a
                  onClick={() =>
                    this.addRemoveFavorite(
                      Utils.getParameterByName("mindMapId"),
                      !JSON.parse(localStorage.getItem("mapJsonObj")).isFavorite
                    )
                  }
                  className={`fav-action${
                    JSON.parse(localStorage.getItem("mapJsonObj")).isFavorite
                      ? " active"
                      : ""
                  }`}
                >
                  <i className="icon-favorite-icon"></i>
                  {JSON.parse(localStorage.getItem("mapJsonObj")).isFavorite
                    ? Resources.getValue("removeFromFavMsgTxt")
                    : Resources.getValue("addToFavMsgTxt")}
                </a>
              </div>
              <div className="shared-link">
                <a
                  onClick={this.saveMap}
                  className="save-button right-bottom-button"
                  id="saved"
                  title={Resources.getValue("saveMapMsgTxt")}
                >
                  <img
                    src={copyIcon}
                    alt={Resources.getValue("saveACopyMsgText")}
                  />
                  {Resources.getValue("saveMapMsgTxt")}
                </a>
              </div>

              <div className="download-link">
                <a
                  onClick={this.htmlToImage}
                  id="image-to-pdf"
                  className="right-bottom-button"
                  title={Resources.getValue("imageToPdf")}
                >
                  <img src={saveIcon} alt={Resources.getValue("imageToPdf")} />
                  {Resources.getValue("imageToPdf")}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="io" className="ui">
          <h3></h3>
          <p>
            <span>{Resources.getValue("storageMsgTxt")}</span>
            <select id="backend"></select>
          </p>

          <div id="file">
            <p className="desc">
              Local files are suitable for loading/saving files from other
              mindmapping applications.
            </p>
            <p data-for="save">
              <span>Format</span>
              <select className="format"></select>
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
          </div>

          <div id="image">
            <p className="desc">Export your design as a PNG image.</p>
            <p data-for="save">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
          </div>

          <div id="local">
            <p className="desc">
              Your browser's localStorage can handle many mind maps and creates
              a permalink, but this URL cannot be shared.
            </p>
            <p data-for="load">
              <span>Saved maps</span>
              <select className="list"></select>
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
            <p data-for="load">
              <button className="remove">Delete</button>
            </p>
          </div>

          <div id="firebase">
            <p className="desc">
              Firebase offers real-time synchronization for true multi-user
              collaboration.
            </p>
            <p data-for="save load">
              <span>Server</span>
              <input type="text" className="server" />
            </p>
            <p data-for="save load">
              <span>Auth</span>
              <select className="auth">
                <option value="">(None)</option>
                <option value="facebook">Facebook</option>
                <option value="twitter">Twitter</option>
                <option value="github">GitHub</option>
                <option value="google">Google</option>
              </select>
            </p>
            <p data-for="load">
              <span>Saved maps</span>
              <select className="list"></select>
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
            <p data-for="load">
              <button className="remove">Delete</button>
            </p>
          </div>

          <div id="webdav">
            <p className="desc">
              Use this to access a generic DAV-like REST API.
            </p>
            <p data-for="save load">
              <span>URL</span>
              <input type="text" className="url" />
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
          </div>

          <div id="gdrive">
            <p className="desc">
              Maps stored in Google Drive have a permalink URL and can be shared
              with other users, if you allow this by setting proper permissions
              (inside Google Drive itself).
            </p>
            <p data-for="save">
              <span>Format</span>
              <select className="format"></select>
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

class MenuList extends Component {
  render() {
    const iconSelectOptionheight = 30;
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * iconSelectOptionheight;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={iconSelectOptionheight}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

export default AnonymousSharedToolbox;
