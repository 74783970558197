import React, { Component } from "react";
import Utils from "../libraries/utils";
import Resources from "../libraries/resources";

class retrieve extends Component {

  componentDidMount(){
    localStorage.setItem("wq0wdbr9aq01xxky05h1l8",Utils.getParameterByName('token'))
    localStorage.setItem("retrieveUrl", window.location.pathname);
    // window.location.href = Resources.getValue('appBaseEnvURL') + '/successpayment';
    window.location.href = Resources.getValue('appBaseEnvURL') + '/subscription-detail';
  }

  render() {
    return (
        <div></div>
    );
  };
}


export default retrieve;
