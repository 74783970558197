import React, { Component } from "react";
import Resources from "../libraries/resources";
import Utils from "../libraries/utils";
import LandingPageService from "../services/api/landing-page";
import ImgVideoPreviewModal from "../helpers/img-video-preview-modal";
import NavMenu from "../components/nav-menu";
import Footer from "../components/footer";

import { Link } from "react-router-dom";

// string to html parse
import htmlParse from 'html-react-parser';

class BlogPage extends Component {

	constructor() {
		super();

		this.state = {
			allBlogItems: [],
			colorArray: [
				"#33bdd8",
				"#fd7021",
				"#fcbc2c",
				"#34a853",
				"#9e35cf",
				"#cf3568"
			],
			isPreviewModal: JSON.parse(localStorage.getItem("isCustomModalOpen")),
			media: {}
		};

		this.menuItems = [
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/' : '/',
				title: Resources.getValue("landingPageHomeMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '',
				title: Resources.getValue("landingPageCorporateMsgTxt"),
				activeClass: 'not-active',
				externalLink: false,
				subLinks: [
					{
						href: '/about',
						title: Resources.getValue("landingPageAboutMsgTxt"),
						activeClass: '',
						externalLink: true
					},
					// {
					// 	href: '#references',
					// 	title: Resources.getValue("landingPageReferencesMsgTxt"),
					// 	activeClass: '',
					// 	externalLink: true
					// },
					{
						href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#contact' : '/' + '#contact',
						title: Resources.getValue("landingPageContactMsgTxt"),
						activeClass: '',
						externalLink: true
					}
				]
			},
			// {
			// 	href: '/features',
			// 	title: Resources.getValue("landingPageFeaturesMsgTxt"),
			// 	activeClass: '',
			// 	externalLink: true
			// },
			{
				href: '/education',
				title: Resources.getValue("landingPageEducationMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/blog',
				title: "Blog",
				activeClass: 'home-color-set active',
				externalLink: true
			},
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#price' : '/' + '#price',
				title: Resources.getValue("landingPagePriceMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/kvkk',
				title: Resources.getValue("landingPageKavkkMsgTxt"),
				activeClass: '',
				externalLink: true
			}
		];
	}

	componentDidMount() {
		document.title = "Foramind | Blog";
		this.getBlogList();
	}

	getBlogList() {
		var _this = this;
		LandingPageService.getBlogList(this.getBlogsCallback, _this);
	}

	getBlogsCallback() {
		var allBlogItems = JSON.parse(this.response);

		if (allBlogItems && allBlogItems.blogModel.length > 0) {
			this.scope.setState({
				allBlogItems: JSON.parse(JSON.stringify(allBlogItems.blogModel))
			})

			// data var fakat hepsi passive ise
			let result = this.scope.state.allBlogItems.every(function (itm) {
				return itm.isActive === false;
			});
			this.scope.setState({
				yesDataNoActiveData: result
			})
		}
	}

	sharedClick = (isOpen) => {
		this.setState({
			isPreviewModal: isOpen
		});
	};

	openThisOnPopup(e) {
		localStorage.setItem("isCustomModalOpen", true);
		this.setState({
			isPreviewModal: JSON.parse(
				localStorage.getItem("isCustomModalOpen")
			)
		});

		if (e.target.tagName === "IMG") {
			this.setState({
				media: {
					type: e.target.tagName,
					src: e.target.getAttribute('src')
				}
			});
		} else { // video ise
			switch (e.target.tagName) {
				case "A":
					this.setState({
						media: {
							type: "VIDEO",
							src: e.target.previousSibling.querySelector('source').getAttribute('src')
						}
					});
					break;
				case "VIDEO":
					this.setState({
						media: {
							type: "VIDEO",
							src: e.target.querySelector('source').getAttribute('src')
						}
					});
					break;
			}
		}
	}

	render() {
		return (
			<div className="landing-page" id="landing-page">
				{this.state.isPreviewModal ? (
					<ImgVideoPreviewModal sharedClick={this.sharedClick} media={this.state.media} />
				) : null}
				<NavMenu menuItems={this.menuItems} />
				<div className="blog-page">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12">
								<div className="content-wrapper">
									{(this.state.allBlogItems !== null && this.state.allBlogItems.length > 0)
										? this.state.allBlogItems.map((blog, ind) => (
											<div key={ind}>
												{
													// (blog.isActive === true) && (
														<div className="blog-item">
															<div className="blog-item--title-wrapper">
																<div className="blog-item--title">
																<Link to={'/' + blog.blogSlug} style={{color: '#007bff'}}>{blog.blogTitle}</Link>
																	
																</div>
																<div className="blog-item--blogger-info">
																	<div className="name">
																		{blog.createdFirstName + " " + blog.createdLastName}, {Utils.formatDateWithMonthName(blog.blogCreated)}
																	</div>
																	<div className="image">
																		{(blog.createdProfileImage) ? (
																			<div
																				style={{
																					background: "url(" + blog.createdProfileImage + ") center center", backgroundSize: 'contain'
																				}}
																			></div>
																		) : (
																			<div
																				style={{
																					backgroundColor: this.state.colorArray[Math.floor(Math.random() * this.state.colorArray.length)]
																				}}
																			>
																				{blog.createdFirstName[0].toUpperCase() + blog.createdLastName[0].toUpperCase()}
																			</div>
																		)}
																	</div>
																</div>
															</div>
															<div className="blog-item--content-wrapper"
																style={{
																	minHeight:
																		(blog.blogFeaturedImage)
																			? 100 : (blog.blogFeaturedImage) ? 411 : 'auto'
																}}
															>
																{(blog.blogFeaturedImage) && (
																	<div className="img-wrapper">
																			{/* <img src={blog.blogFeaturedImage} alt="Blog Basligi" className="big"
																				onClick={(e) => this.openThisOnPopup(e)}
																			/>
																		 */}
																		{blog.blogFeaturedImage && (
																			<div className="small-images">
																					<div className="item-wrap">
																							<div>
																									<img src={blog.blogFeaturedImage} alt="Blog Basligi" className="small"
																										onClick={(e) => this.openThisOnPopup(e)}
																									/>
																							</div>
																					</div>
																			</div>
																		)}
																	</div>
																)}
															</div>
															
														</div>
													// )
												}
											</div>
										)) : (
											<div className="no-blog-data">
												{Resources.getValue("noBlogDataMsgTxt")}
											</div>
										)}
									{/* data var fakat hepsi passive ise */}
									{this.state.yesDataNoActiveData === true && (
										<div className="no-blog-data">
											{Resources.getValue("noBlogDataMsgTxt")}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	};
}

export default BlogPage;
