import React, { Component } from "react";
import Resources from "../libraries/resources";
import NavMenu from "../components/nav-menu";
import Footer from "../components/footer";

// image
import foramindLogo from "../styles/img/foramind_logo.png";

class HotjarExplicitConsentPage extends Component {

	constructor() {
		super();

		this.menuItems = [
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/' : '/',
				title: Resources.getValue("landingPageHomeMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '',
				title: Resources.getValue("landingPageCorporateMsgTxt"),
				activeClass: 'not-active',
				externalLink: false,
				subLinks: [
					{
						href: '/about',
						title: Resources.getValue("landingPageAboutMsgTxt"),
						activeClass: '',
						externalLink: true
					},
					// {
					// 	href: '/landing-page#references',
					// 	title: Resources.getValue("landingPageReferencesMsgTxt"),
					// 	activeClass: '',
					// 	externalLink: true
					// },
					{
						href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#contact' : '/' + '#contact',
						title: Resources.getValue("landingPageContactMsgTxt"),
						activeClass: '',
						externalLink: true
					}
				]
			},
			// {
			// 	href: '/features',
			// 	title: Resources.getValue("landingPageFeaturesMsgTxt"),
			// 	activeClass: '',
			// 	externalLink: true
			// },
			{
				href: '/education',
				title: Resources.getValue("landingPageEducationMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/blog',
				title: "Blog",
				activeClass: '',
				externalLink: true
			},
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#price' : '/' + '#price',
				title: Resources.getValue("landingPagePriceMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/kvkk',
				title: Resources.getValue("landingPageKavkkMsgTxt"),
				activeClass: '',
				externalLink: true
			}
		];
	}

	componentDidMount() {
		document.title = "Foramind | Hotjar";
	}

	render() {
		return (
			<div className="landing-page" id="landing-page">
				<NavMenu menuItems={this.menuItems} />
				<div className="hotjar-agreement-page">
					<div className="container">
            <div className="row">
							<div className="col-md-12">
								<div className="img-wrapper text-center mb-4">
									<img src={foramindLogo} alt="Foramind" height={100} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 text-center title mb-2">
								<h2>HOTJAR - YURTDIŞI AKTARIM AÇIK RIZA METNİ</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="content-wrapper">
									<p>
                    Zihinler Fora Eğitim Yazilim ve Danişmanlik Sanayi ve Ticaret Anonim Şirketi, 6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamında “Veri Sorumlusu” sıfatına haiz olup; işbu açık rıza metni 6698 Sayılı Kanun, ilgili kanun uyarınca yayınlanan yönetmelikler ve tebliğlerlerde yer alan hükümlere uygun olarak, veri sorumlusu tarafından aşağıda açıklanan verilerinizin yurtdışına aktarılabilmesi için bilgilendirmeye dayalı olarak özgür iradenizle rızanızı temin etmek amacıyla hazırlanmıştır. 
                  </p>
                  <h6><b>I.Kişisel Verilerin Yurtdışına Aktarımı</b></h6>
                  <p>
                    Kanun’un 9. maddesine göre, yurt dışına veri aktarımı; 
                  </p>
                  <ul>
                    <li>
                      •	İlgili kişinin açık rızasının bulunması, 
                    </li>
                    <li>
                      •	İlgili kişinin açık rızasının bulunması, •	Yeterli korumanın bulunduğu ülkelere (Kurul tarafından güvenli kabul edilen ülkeler) kişisel veri aktarımında, Kanun’da belirtilen hallerin varlığı, (Kanun’un 5. maddesinin 2. fıkrası ile 6. maddesinin 3. fıkrasında belirtilen şartlar),
                    </li>
                    <li>
                      •	Yeterli korumanın bulunmadığı ülkelere kişisel veri aktarımında, Kanun’da belirtilen hallerin varlığı durumunda (Kanun’un 5. maddesinin 2. fıkrası ile 6. maddesinin 3. fıkrasında belirtilen şartlar) yeterli korumanın yazılı olarak taahhüt edilmesi ve Kurul’un izninin bulunması, durumlarında gerçekleştirilebilir.
                    </li>
                  </ul>
                  <br/>
                  <h6><b>II.Yurtdışı Aktarımına Konu Kişisel Verileriniz</b></h6>
                  <p>
                    Yurtdışı aktarımına konu kişisel verileriniz işlem güvenliği kategorisi altında; <b>IP Adres Bilgisi, İnternet Tarayıcı Bilgisi, İşletim Sistemi Bilgisi, Tarih, Ülke Bilgisi</b>’dir. <i>(İşlem güvenliği kategorisi altında IP adres bilginiz işlenmekte olup bu veriniz internet tarayıcı bilginiz, işletim sistemi bilginiz, tarih ve ülke bilginiz ile eşleştirilmek sureti ile işlenmektedir.)</i>
                  </p>
									<br/>
                  <h6><b>III.Yurtdışı Aktarımın Amacı ve Hukuki Sebebi </b></h6>
                  <p>
                    Açıklanan kişisel verileriniz geri bildirim alınması amacıyla kullanılan ara yüze ait sunucuların yurt dışında bulunması dolayısıyla geri bildirim hizmetini alabilmemiz ve kullanabilmemiz amacıyla Kanun’un 9. Maddesinde işleme şartları ve ilgili kanunda öngörülen temel ilkelere uygun olarak “açık rızanız” dâhilinde Geri Bildirim Hizmeti Aldığımız Firmaya aktarılmaktadır.
                  </p>
									<br/>
                  <h6><b>IV.Veri Sorumlusu Tarafından Alınan Tedbirler</b></h6>
                  <h6><b>V.Rıza Beyanının Hukuki Mahiyeti, Sonuçları ve Rızanın Geri Alınması</b></h6>
                  <p>
                    <b><u>Kabul beyanını işaretlediğiniz takdirde, veri sorumlusu tarafından yukarıda sayılan kişisel verilerinizin yurtdışına aktarılmasına muvafakat göstermiş olacaksınız ve bu kapsamda “Açık Rıza”nız var kabul edilerek 6698 Sayılı KVK Kanunu’na uygun olarak yurtdışına aktarılabilecektir. IP adres bilginiz; internet tarayıcı bilginiz, işletim sistemi bilginiz, tarih ve ülke bilginiz ile eşleştirilmek sureti ile işlendiğinden tarafınıza tek bir onay seçeneği sunulmuştur.</u></b> Kabul beyanınız halinde, açık rızanız yalnızca yukarıda sayılan kişisel verilerinizin yurtdışına aktarımı için geçerli olup; yukarıda izah edilen işleme amaçları ile sınırlı olarak aktarılabilecektir. 
                  </p>
                  <p>
                    <b><u>KVKK uyarınca, ilgili kişi yurtdışı aktarımına yönelik olarak vermiş olduğunuz açık rıza beyanınızı her zaman geri almanız mümkündür.</u></b> Bu takdirde, veri sorumlusu açık rızanın geri alınması beyanının kendisine ulaştığı tarihten itibaren yurtdışına kişisel veri aktarımına son vermekle yükümlüdür. Açık rızanızın geri alınmasına ilişkin beyan ileriye etkili olarak sonuç doğurmaktadır. Açık Rıza Geri Alım Formu’na ulaşmak için web sitesinde yer alan info@foramind.com adresine e-mail gönderebilirsiniz.
                  </p>
                  <p>
                    KVK Kanunu’nun 3. Maddesi uyarınca, açık rızanın bilgilendirmeye dayanması gerekmektedir. Bu nedenle kabul beyanını işaretlemeden önce, işbu metin ve aydınlatma metni dikkatli şekilde okunmalıdır. 
                  </p>
                  <p>
                    <b>Kişisel verimin yukarıda izah edilen sebep ve amaçlar ile sınırlı şekilde, veri sorumlusu tarafından 6698 Sayılı Kanun’a uygun şekilde III umaralı bölümde belirtilen yere aktarılmasını özgür irademle;</b>
                  </p>
                  <p>
                    <b>Kabul Ediyorum</b> <input type="checkbox" disabled />
                  </p>
                  <p>
                    <b>Kabul Etmiyorum</b> <input type="checkbox" disabled />
                  </p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	};
}

export default HotjarExplicitConsentPage;
