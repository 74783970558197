import { PublicClientApplication } from "@azure/msal-browser";
import Resources from "../libraries/resources";

export const msalConfig = {
    auth: {
        clientId: "ad04bed9-faa7-44f4-93b3-55c72c8b9411",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: Resources.getValue("signalrBaseEnvURL") ,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    }
};
export const msalInstance = new PublicClientApplication(msalConfig);