import React, { Component } from "react";
import Resources from "../libraries/resources";
import NavMenu from "../components/nav-menu";
import Footer from "../components/footer";

// image
import foramindLogo from "../styles/img/foramind_logo.png";

class HotjarClarifyingPage extends Component {

	constructor() {
		super();

		this.menuItems = [
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/' : '/',
				title: Resources.getValue("landingPageHomeMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '',
				title: Resources.getValue("landingPageCorporateMsgTxt"),
				activeClass: 'not-active',
				externalLink: false,
				subLinks: [
					{
						href: '/about',
						title: Resources.getValue("landingPageAboutMsgTxt"),
						activeClass: '',
						externalLink: true
					},
					// {
					// 	href: '/landing-page#references',
					// 	title: Resources.getValue("landingPageReferencesMsgTxt"),
					// 	activeClass: '',
					// 	externalLink: true
					// },
					{
						href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#contact' : '/' + '#contact',
						title: Resources.getValue("landingPageContactMsgTxt"),
						activeClass: '',
						externalLink: true
					}
				]
			},
			// {
			// 	href: '/features',
			// 	title: Resources.getValue("landingPageFeaturesMsgTxt"),
			// 	activeClass: '',
			// 	externalLink: true
			// },
			{
				href: '/education',
				title: Resources.getValue("landingPageEducationMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/blog',
				title: "Blog",
				activeClass: '',
				externalLink: true
			},
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#price' : '/' + '#price',
				title: Resources.getValue("landingPagePriceMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/kvkk',
				title: Resources.getValue("landingPageKavkkMsgTxt"),
				activeClass: '',
				externalLink: true
			}
		];
	}

	componentDidMount() {
		document.title = "Foramind | Hotjar";
	}

	render() {
		return (
			<div className="landing-page" id="landing-page">
				<NavMenu menuItems={this.menuItems} />
				<div className="hotjar-agreement-page">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="img-wrapper text-center mb-4">
									<img src={foramindLogo} alt="Foramind" height={100} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 text-center title mb-2">
								<h2>GERİ BİLDİRİM (HOTJAR) - AYDINLATMA METNİ</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="content-wrapper">
									<h6><b>Veri Sorumlusu</b></h6>
									<p>
										Bu metin, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (Kanun) 10. maddesi ile Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ kapsamında veri sorumlusu sıfatıyla Zihinler Fora Eğitim Yazılım ve Danışmanlık Sanayi ve Ticaret Anonim Şirketi (veri sorumlusu) tarafından siz değerli Ziyaretçimizin (İlgili Kişilerin) aydınlatılması amacıyla hazırlanmıştır.
									</p>
									<br/>
									<h6><b>Tanımlar</b></h6>
									<p>
										<b>Kişisel Veri:</b> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,
									</p>
									<p>
										<b>Kişisel Verilerin İşlenmesi:</b> Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi,
									</p>
									<p>
										<b>Özel Nitelikli Kişisel Veri:</b> Kişilerin ırkı etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkumiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri,
									</p>
									<p>
										<b>İlgili Kişi:</b> Kişisel verisi işlenen gerçek kişiyi,
									</p>
									<p>
										<b>Veri Sorumlusu:</b> Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi,
									</p>
									<p>
										<b>6698 Sayılı Kanun:</b> Kişisel Verilerin Korunması Kanunu’nu ifade eder.
									</p>
									<br/>
									<h6><b>Hangi Kişisel Verilerinizi Hangi Yöntemlerle Topluyoruz?</b></h6>
									<p>
										<b>İşlem güvenliği kategorisi altında</b> IP adres bilginiz işlenmekte olup bu veriniz internet tarayıcı bilginiz, işletim sistemi bilginiz, tarih ve ülke bilginiz ile eşleştirilmek sureti ile işlenmektedir.
									</p>
									<p>
										Açıklanan kişisel verileriniz geri bildirim butonunu aracılığıyla geri bildirimde bulunmanız yoluyla otomatik olarak toplanmaktadır.
									</p>
									<p>
										<b>İşlenen Kişisel Verileriniz, Hangi Amaçlarla ve Hukuki Sebeplerle İşlenmektedir.</b>
									</p>
									<p>
										Kişisel verilerin işlenme şartları başlığını taşıyan 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 5. Maddesine göre, kural olarak kişisel veriler ilgili kişinin açık rızası olmaksızın işlenemez. Ancak KVKK m.5/2’de yer alan şartlardan birinin varlığı halinde, ilgili kişinin açık rızası aranmaksızın kişisel verilerinin işlenmesi mümkündür.
									</p>
									<p>
										Açıklanan kişisel verileriniz Kanun’un 4. Maddesinde belirtilen ilkelere uygun olarak;
									</p>
									<p>
										İşlem Güvenliği (IP Adres Bilgisi, İnternet Tarayıcı Bilgisi, İşletim Sistemi Bilgisi, Tarih, Ülke Bilgisi) kişisel verileriniz, Pazarlama çalışmalarına yönelik faaliyetlerin/geliştirmelerin ve analizlerin gerçekleştirilmesi, planlanması ve icrası amacı ile <i>“İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması”</i> <b>(KVKK M.5/2-f)</b> hukuki sebebine dayalı olarak açık rızanız temin edilmeksizin işlenmektedir.
									</p>
									<br/>
									<h6><b>Kişisel Verileriniz, Kimlere ve Hangi Amaçlarla Aktarılmaktadır</b></h6>
									<p>
										Kişisel verileriniz yukarıda belirtmiş olduğumuz amaçlara hizmet etmesi için Kanun’un 8. Maddesinde belirtilen kişisel verileri işleme şartları ve ilgili kanunda öngörülen temel ilkelere uygun;
									</p>
									<p>
										Kişisel verilerinizin toplanması, muhafaza edilmesi ve güvenli olarak saklanması amacıyla sunucu hizmeti aldığımız Firmaya aktarılmaktadır. Söz konusu aktarım işleme amacı ve hukuki sebebine bağlı olarak <i>“İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması”</i> <b>(KVKK M.5/2-f)</b> hukuki sebebine dayalı olarak açık rızanız temin edilmeksizin gerçekleştirilmektedir.
									</p>
									<p>
										Kişisel veriler kural olarak ilgili kişinin açık rızası olmaksızın yurt dışına aktarılamaz. Açıklanan kişisel verileriniz geri bildirim alınması amacıyla kullanılan ara yüze ait sunucuların yurt dışında bulunması dolayısıyla geri bildirim hizmetini alabilmemiz ve kullanabilmemiz amaçlarıyla Kanun’un 9. Maddesinde işleme şartları ve ilgili kanunda öngörülen temel ilkelere uygun olarak “açık rızanız” dâhilinde Geri Bildirim Hizmeti Aldığımız Firmaya aktarılmaktadır.
									</p>
									<br/>
									<h6><b>Hangi Özel Nitelikli Kişisel Verilerinizi, Hangi Yöntemlerle Topluyoruz?</b></h6>
									<p>
										Tarafınıza ait herhangi bir özel nitelikli kişisel veri toplamamakta ve işlenmemektedir.
									</p>
									<br/>
									<h6><b>Resmi Makamlarla Paylaşım</b></h6>
									<p>
										Ayrıca, kişisel verileriniz, resmi makamlarca ilgili mevzuata uygun şekilde talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapılması zorunda olunan durumlarda resmi makamlara açık rızanız olmaksızın açıklanabilecektir. Kişisel verilerinizin şirketimizi etkileyen hukuki bir ihtilaf ile ilgili ya da potansiyel ihtilaflara ilişkin olarak gerekli olduğu ölçüde kullanılması ve mahkemeler ve diğer yetkili kurum ve kuruluşlarla paylaşılması da mümkün olabilir. Bu çerçevede kişisel verileriniz, Şirketimiz <b>hukuki yükümlülüklerini yerine getirmek</b> (KVKK m. 5/2-ç) ve Şirketimizin <b>haklarının tesisi veya korunması</b> hukuki sebeplerine dayalı (KVKK m.5/2-e) olarak açık rızanıza gerek olmadan işlenebilecektir. Bu doğrultuda resmi makam taleplerinin yerine getirilebilmesi ve hukuki süreçler için zorunlu olması halinde kamu kurum ve kuruluşları ile yargı organlarıyla paylaşılabilecektir. <i>(Bknz. Kişisel Verilerin Korunması Kanuna İlişkin Uygulama Rehberi sy.77)</i>
									</p>
									<br/>
									<h6><b>Kişisel Verilerinize İlişkin Haklarınız</b></h6>
									<p>
										Kişisel Verilerin Korunması Kanunu’nun 11. Maddesi gereği veri sorumlusu sıfatıyla otelimize karşı,
									</p>
									<ul>
										<li>
											•	Kişisel veri işlenip işlenmediğini öğrenme, 
										</li>
										<li>
											•	Kişisel verileri işlenmişse buna ilişkin bilgi talep etme, 
										</li>
										<li>
											•	Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, 
										</li>
										<li>
											•	Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, 
										</li>
										<li>
											• Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,
										</li>
										<li>
											•	7 nci maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,
										</li>
										<li>
											•	(d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
										</li>
										<li>
											•	İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, 
										</li>
										<li>
											•	Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme, haklarına sahipsiniz.
										</li>
									</ul>
									<br/>
									<p>
										Siz değerli Ziyaretçimiz, Kanun’un 11. Maddesi kapsamında yer alan haklarınıza ilişkin taleplerinizi “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkındaki Tebliğe” göre, “https://foramind.com/landing-page” adresinden ya da şirketimizden temin edebileceğiniz <b>“Veri Sahibi Başvuru Formunu”</b> doldurup, imzalayarak <b>“Aşağı Öveçler Mahallesi, 1309 Cadde, No:5/6 Çankaya/ANKARA”</b> adresine yazılı olarak bizzat tevdi edebilir ya da Noter kanalıyla gönderebilir yahut bize daha önce bildirdiğiniz ve kayıtlarımızda yer alan elektronik posta adresinizi kullanmak suretiyle şirketimize iletebilirsiniz. <br/>
										Başvurunuzda yer alan talepleriniz, talebin niteliğine göre en geç otuz (30) gün içinde ücretsiz olarak sonuçlandırılacaktır. Ancak, işlemin Şirket için ayrıca bir maliyeti gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkındaki Tebliğ'de” belirlenen tarifedeki ücret alınabilmektedir. <br/>
										Kişisel veri sahibinin kendisi dışında bir kişinin talepte bulunması için konuya ilişkin olarak kişisel veri sahibi tarafından başvuruda bulunacak kişi adına düzenlenmiş özel vekâletname bulunmalıdır.
									</p>
									<br/>
									<dl>
										<dt>ŞİRKET</dt>
										<dd>
											<b>:</b> <b>Zihinler Fora Eğitim Yazılım ve Danışmanlık Sanayi ve Ticaret Anonim Şirketi</b>
										</dd>
										<dt>MERSİS NO</dt>
										<dd><b>:</b> 0998158780300001</dd>
										<dt>İLETİŞİM LİNKİ</dt>
										<dd><b>:</b> https://foramind.com/landing-page</dd>
										<dt>ADRES</dt>
										<dd><b>:</b> Aşağı Öveçler Mahallesi, 1309 Cadde, No:5/6 Çankaya/ANKARA</dd>
										<dt>E-POSTA</dt>
										<dd><b>:</b> info@foramind.com</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	};
}

export default HotjarClarifyingPage;
