import React, { Component } from "react";
import Resources from "../libraries/resources";
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MyPlannerService from "../services/api/my-planner";
import Utils from "../libraries/utils";

class MyPlanner extends Component {

  constructor(props) {
    super(props)

    this.state = {
      data: [],
      deletedMyPlannerId: null
    }
  };

  componentDidMount() {
    this.myPlanner();
  };

  myPlanner = () => {
    var recordSize = 100;
    var id = JSON.parse(sessionStorage.getItem("userInformation")).id;
    var callback = this.fillMyPlanner;
    MyPlannerService.getPlanList(recordSize, id, callback, this);

  };

  fillMyPlanner() {
    var _this = this;
    const myPlannerList = JSON.parse(this.response).planner;
    var listedItems = document.querySelectorAll('.table-list');
    if (listedItems.length > 0) {
      _this.scope.setState({
        data: []
      })
    }

    if (myPlannerList) {
      for (var i = 0; i < myPlannerList.length; i++) {

        if (myPlannerList[i].modifiedDate)
          myPlannerList[i].modifiedDate = Utils.formatDateTime(myPlannerList[i].modifiedDate)

        if (myPlannerList[i].creationDate)
          myPlannerList[i].creationDate = Utils.formatDateTime(myPlannerList[i].creationDate)

        if (myPlannerList[i].deadline)
          myPlannerList[i].deadline = Utils.formatDateTime(myPlannerList[i].deadline)
      }

      _this.scope.setState({
        data: JSON.parse(JSON.stringify(myPlannerList))
      });
      //   myPlannerList.map(p => {
      //     _this.scope.setState({
      //       data: [..._this.scope.state.data, p]
      //     })
      // })
    }

  };

  deleteMyPlanner(scope) {
    Utils.modalm().close();
    MyPlannerService.delete(scope.state.deletedMyPlannerId, scope.myPlanner, scope);
  };

  deleteRow(id) {
    this.state.data.findIndex(post => {
      return post.id === id
    })
    this.setState({
      deletedMyPlannerId: id
    })
    this.deleteMapApproveModal();
  };

  deleteMapApproveModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("areyousureMsgTxt"),
      bodyContent: '<p>' + Resources.getValue('deletePlanApproveModalMsgTxt') + '</p>',
      buttons: [
        {
          text: Resources.getValue("noMsgTxt"),
          class: 'button fresh-button reject-button',
          href: ''
        },
        {
          text: Resources.getValue("yesMsgTxt"),
          class: 'button yellow-button confirm-button',
          href: ''
        }

      ],
      confirmCallback: this.deleteMyPlanner,
      rejectCallback: null,
      scope: this
    });
  };

  // clickOpenUrl(id, name){
  //   sessionStorage.setItem('openedMapName', name);
  //   sessionStorage.setItem('openedMapId', id);
  //   window.location.href = (Resources.getValue("appBaseEnvURL") + '/map'+ '?plannerId=' + id );
  // }

  /*****************************PUT ISTEGI**************************************/

  cellInfoUpdate(cellInfo, e) {
    const data = [...this.state.data];
    data[cellInfo.index][cellInfo.column.id] = e.target.innerText;
    this.setState({ data });
    MyPlannerService.updateMyPlannerName(cellInfo.original.id, cellInfo.original.name, this.myPlanner, this);
  };

  renderEditable = (cellInfo) => {
    return (
      <div
        contentEditable
        id={cellInfo.original.id}
        suppressContentEditableWarning
        onKeyPress={(e) => {
          if (e.which === 13) {
            if ((e.target.innerText && e.target.innerText.trim()) && (e.target.innerText != cellInfo.original.name)) {
              this.cellInfoUpdate(cellInfo, e);
              e.preventDefault()
            }
          }
        }}

        onBlur={e => {
          if ((e.target.innerText && e.target.innerText.trim()) && (e.target.innerText != cellInfo.original.name)) {
            this.cellInfoUpdate(cellInfo, e);
          } else {
            e.target.innerText = cellInfo.original.name;
          }
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.data[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  };

  updateOnClick(id) {
    var editingCell = document.getElementById(id);
    editingCell.focus();
  };
  /*******************************************************************/
  render() {
    const data = this.state.data;
    return (
      <React.Fragment>
        <Header />
        <div className="myplanner-table">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="template-panel">
                  <div className="row">
                    <div className="col-md-12 px-5 ">
                      <SubHeader title={Resources.getValue("myplannerTitleMsgTxt")} iconClass="fa fa-calendar" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-list">
                        <ReactTable
                          data={data}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.accessor]) === filter.value}
                          previousText={Resources.getValue("previousTextMsgTxt")}
                          nextText={Resources.getValue("nextTextMsgTxt")}
                          pageText={Resources.getValue("pageTextMsgTxt")}
                          rowsText={Resources.getValue("rowsTextMsgTxt")}
                          noDataText={Resources.getValue("noDataTextMsgTxt")}
                          loadingText={Resources.getValue("loadingTextMsgTxt")}
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                          const columns={[
                            {

                              columns: [
                                {
                                  Header: Resources.getValue("plannernameMsgTxt"),
                                  accessor: "name",
                                  filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),

                                  style: {
                                    textAlign: "center"
                                  },
                                  Cell: this.renderEditable
                                }]
                            },

                            {
                              columns: [
                                {
                                  Header: Resources.getValue("creationDateMsgTxt"),
                                  accessor: "creationDate",
                                  filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value),
                                  style: {
                                    textAlign: "center"
                                  }
                                }]
                            },

                            {
                              columns: [
                                {
                                  Header: Resources.getValue("modifieddateMsgTxt"),
                                  accessor: "modifiedDate",
                                  filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value),
                                  style: {
                                    textAlign: "center"
                                  },

                                }]
                            },

                            {
                              columns: [
                                {
                                  Header: Resources.getValue("importanceMsgTxt"),
                                  accessor: "importance",
                                  style: {
                                    textAlign: "center"
                                  },
                                }]
                            },

                            {
                              columns: [
                                {
                                  Header: Resources.getValue("urgencyMsgTxt"),
                                  accessor: "urgency",
                                  style: {
                                    textAlign: "center"
                                  }
                                }]
                            },

                            {
                              columns: [
                                {
                                  Header: Resources.getValue("deadlineMsgTxt"),
                                  accessor: "deadline",
                                  style: {
                                    textAlign: "center"
                                  }
                                }]
                            },

                            {
                              columns: [
                                {
                                  Cell: props => {
                                    return (
                                      <div>
                                        {/* <a onClick={() => {
                                      this.clickOpenUrl(props.original.id, props.original.name)
                                    }} className="action-button" title={Resources.getValue('showMsgTxt')}><i className="fa fa-eye"></i></a> */}

                                        <a onClick={() => {
                                          this.updateOnClick(props.original.id)
                                        }} className="action-button" title={Resources.getValue('editMsgTxt')}><i className="fa fa-pencil"></i></a>

                                        <a onClick={() => {
                                          this.deleteRow(props.original.id)
                                        }} className="action-button" title={Resources.getValue('deleteMsgTxt')} ><i className="fa fa-trash" aria-hidden="true"></i></a>
                                      </div>
                                    )
                                  },
                                  className: "action-column",
                                  style: {
                                    textAlign: "center"
                                  },
                                  sortable: false,
                                  filterable: false,
                                  width: 80,
                                  maxWidth: 80
                                }]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default MyPlanner;
