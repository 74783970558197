import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { msalInstance } from "./config/msalInstance";
import { MsalProvider } from "@azure/msal-react";
import App from "./App";

function RootComponent() {
    return (
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </MsalProvider>
    );
}

export default RootComponent;
