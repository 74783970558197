import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";
import SuccessModal from "../../components/modals/success-modal";

export default function ContactService(data) {
  const xhr = new DataService.createXHR({
    type: "POST",
    url: Resources.getValue("accountContactApiURL"),
    data: data,
  });

  xhr.onerror = function () {
    Utils.loadingScreen("hide");
    openErrorModal();
  };

  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      // close loading when xhr done
      Utils.loadingScreen("hide");
      if (xhr.status == 200 && xhr.response) {
        const responseData = JSON.parse(xhr.response);
        if (responseData.result) {
          openSuccessModal();
        }
      } else if (xhr.status == 400) {
        openWarningModal();
      } else {
        openErrorModal();
      }
    }
  };

  const openErrorModal = function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  };

  const openWarningModal = function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  };

  const openSuccessModal = function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + Resources.getValue("contactsuccessMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href:
            (
              window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] == "foramind" || 
              window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] == ""
            )
              ? Resources.getValue("appBaseEnvURL") + "/"
              : Resources.getValue("appBaseEnvURL") + "/contact",
        },
      ],
    });
  };
}
