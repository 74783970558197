import Resources from "../libraries/resources";

function profileClarifyingText() {
  return (
    "<p><b>Veri Sorumlusu</b></p>" +
    "<p>Bu metin, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (Kanun) 10. maddesi ile Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ kapsamında veri sorumlusu sıfatıyla Zihinler Fora Eğitim Yazılım ve Danışmanlık Sanayi ve Ticaret Anonim Şirketi (veri sorumlusu) tarafından siz değerli Üyemizin (İlgili Kişilerin) aydınlatılması amacıyla hazırlanmıştır.</p>" +
    "<p><b>Tanımlar</b></p>" +
    "<p><b>Kişisel Veri:</b> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,</p>" +
    "<p><b>Kişisel Verilerin İşlenmesi:</b> Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi,</p>" +
    "<p><b>Özel Nitelikli Kişisel Veri:</b> Kişilerin ırkı etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkumiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri,</p>" +
    "<p><b>İlgili Kişi:</b> Kişisel verisi işlenen gerçek kişiyi,</p>" +
    "<p><b>Veri Sorumlusu:</b> Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi,</p>" +
    "<p><b>6698 Sayılı Kanun:</b> Kişisel Verilerin Korunması Kanunu’nu ifade eder.</p>" +
    "<p><b>Hangi Kişisel Verilerinizi Hangi Yöntemlerle Topluyoruz?</b></p>" +
    "<p><b>Görsel kayıtlar kategorisi altında</b> profil fotoğrafı kişisel veriniz işlenmektedir. Ancak eklemiş olduğunuz profil fotoğrafının kişisel veri niteliğinde olmaması bir başka anlatımla fotoğrafın gerçek bir kişiyi gösterir nitelikte olmaması halinde Kişisel Verilerin Korunması Kanunu kapsamında sayılamayacağından iş bu aydınlatma metni profil fotoğrafı bakımında yürürlük bulmayacaktır.</p>" + 
    "<p>Söz konusu kişisel veriniz, hesap ayarları ile profil fotoğrafı eklemeniz ve diğer detay bilgileri iletmeniz aracılığıyla otomatik olmayan yollarla toplanmaktadır.</p>" +
    "<p><b>İşlenen Kişisel Verileriniz, Hangi Amaçlarla ve Hukuki Sebeplerle İşlenmektedir.</b></b>" +
    "<p>Kişisel verilerin işlenme şartları başlığını taşıyan 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 5. Maddesine göre, kural olarak kişisel veriler ilgili kişinin açık rızası olmaksızın işlenemez.  Ancak KVKK m.5/2’de yer alan şartlardan birinin varlığı halinde, ilgili kişinin açık rızası aranmaksızın kişisel verilerinin işlenmesi mümkündür.</p>" +
    "<p>Açıklanan kişisel verileriniz Kanun’un 4. Maddesinde belirtilen ilkelere uygun olarak;</p>" +
    "<p>Görsel kayıtlar kategorisi altında işlenen profil fotoğrafı kişisel veriniz Foremind üye hesabının üye talebi doğrultusunda özgülenmesi amacı ile “açık rızanız” dahilinde işlenmektedir.</p>" +
    "<p><b>Kişisel Verileriniz, Kimlere ve Hangi Amaçlarla Aktarılmaktadır</b></p>" +
    "<p>Kişisel verileriniz yukarıda belirtmiş olduğumuz amaçlara hizmet etmesi için Kanun’un 8. Maddesinde belirtilen kişisel verileri işleme şartları ve ilgili kanunda öngörülen temel ilkelere uygun olarak tabloda belirtilen amaçlarla aktarılmaktadır. İlgili tablo veri ve aktarım eşleştirilmesi ile sunulmuştur;</p>" + 
    "<p>Kişisel verilerinizin toplanması, muhafaza edilmesi ve güvenli olarak saklanması amacıyla sunucu hizmeti aldığımız Firmaya aktarılmaktadır. Söz konusu aktarım işleme amacı ve hukuki sebebine bağlı olarak <i>“açık rızanız”</i> dahilinde gerçekleştirilmektedir.</p>" +
    "<p>Kişisel veriler kural olarak ilgili kişinin açık rızası olmaksızın yurt dışına aktarılamaz. Profil fotoğrafı kişisel verileriniz fotoğraf entegrasyonu sunan sisteme ait sunucuların yurt dışında bulunması dolayısıyla profil fotoğrafı eklenebilmesini temin amacıyla Kanun’un 9. Maddesinde işleme şartları ve ilgili kanunda öngörülen temel ilkelere uygun olarak “açık rızanız” dâhilinde sunucu hizmeti aldığımız Firmaya aktarılmaktadır.</p>" +
    "<p><b>Hangi Özel Nitelikli Kişisel Verilerinizi, Hangi Yöntemlerle Topluyoruz?</b></b>" +
    "<p>Tarafınıza ait herhangi bir özel nitelikli kişisel veri toplamamakta ve işlenmemektedir.</p>" +
    "<p><b>Resmi Makamlarla Paylaşım</b></b>" +
    "<p>Ayrıca, kişisel verileriniz, resmi makamlarca ilgili mevzuata uygun şekilde talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapılması zorunda olunan durumlarda resmi makamlara açık rızanız olmaksızın açıklanabilecektir. Kişisel verilerinizin şirketimizi etkileyen hukuki bir ihtilaf ile ilgili ya da potansiyel ihtilaflara ilişkin olarak gerekli olduğu ölçüde kullanılması ve mahkemeler ve diğer yetkili kurum ve kuruluşlarla paylaşılması da mümkün olabilir. Bu çerçevede kişisel verileriniz, Şirketimiz <b>hukuki yükümlülüklerini yerine getirmek</b> (KVKK m. 5/2-ç) ve Şirketimizin <b>haklarının tesisi veya korunması</b> hukuki sebeplerine dayalı (KVKK m.5/2-e) olarak açık rızanıza gerek olmadan işlenebilecektir. Bu doğrultuda resmi makam taleplerinin yerine getirilebilmesi ve hukuki süreçler için zorunlu olması halinde kamu kurum ve kuruluşları ile yargı organlarıyla paylaşılabilecektir. <i>(Bknz. Kişisel Verilerin Korunması Kanuna İlişkin Uygulama Rehberi sy.77)</i></p>" +
    "<p><b>Kişisel Verilerinize İlişkin Haklarınız</b></b>" +
    "<pKişisel Verilerin Korunması Kanunu’nun 11. Maddesi gereği veri sorumlusu sıfatıyla otelimize karşı, </br><ul><li>•	Kişisel veri işlenip işlenmediğini öğrenme,</li><li>•	Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li><li>•	Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li><li>•	Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li><li>•	 Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li><li>•	7 nci maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,</li><li>•	(d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li><li>•	İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li><li>•	Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme, haklarına sahipsiniz.</li></ul></p>" +
    "<p>Siz değerli Ziyaretçimiz, Kanun’un 11. Maddesi kapsamında yer alan haklarınıza ilişkin taleplerinizi “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkındaki Tebliğe” göre, “https://foramind.com/landing-page” adresinden ya da şirketimizden temin edebileceğiniz “Veri Sahibi Başvuru Formunu” doldurup, imzalayarak “Aşağı Öveçler Mahallesi, 1309 Cadde, No:5/6 Çankaya/ANKARA” adresine yazılı olarak bizzat tevdi edebilir ya da Noter kanalıyla gönderebilir yahut bize daha önce bildirdiğiniz ve kayıtlarımızda yer alan elektronik posta adresinizi kullanmak suretiyle şirketimize iletebilirsiniz. </br> Başvurunuzda yer alan talepleriniz, talebin niteliğine göre en geç otuz (30) gün içinde ücretsiz olarak sonuçlandırılacaktır. Ancak, işlemin Şirket için ayrıca bir maliyeti gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkındaki Tebliğ'de” belirlenen tarifedeki ücret alınabilmektedir. </br> Kişisel veri sahibinin kendisi dışında bir kişinin talepte bulunması için konuya ilişkin olarak kişisel veri sahibi tarafından başvuruda bulunacak kişi adına düzenlenmiş özel vekâletname bulunmalıdır.</p>" +
    "<p><dl><dt>ŞİRKET</dt><dd><b>:</b> <b>Zihinler Fora Eğitim Yazılım ve Danışmanlık </br> Sanayi ve Ticaret Anonim Şirketi</b></dd><dt>MERSİS NO</dt><dd><b>:</b> 0998158780300001</dd></dl><dt>İLETİŞİM LİNKİ</dt><dd><b>:</b> https://foramind.com/landing-page</dd><dt>ADRES</dt><dd><b>:</b> Aşağı Öveçler Mahallesi, 1309 Cadde, No:5/6 </br> Çankaya/ANKARA</dd><dt>E-POSTA</dt><dd><b>:</b> info@foramind.com</dd></dl></p>"
  );
}

export default profileClarifyingText;
