import React, { Component } from "react";
import Utils from "../libraries/utils";
import PaymentService from "../services/api/payment";
import Resources from "../libraries/resources";

// Modals
import ErrorModal from "../components/modals/error-modal";
import SuccessModal from "../components/modals/success-modal";
import ChatGptPaymentService from "../services/api/chat-gpt-payment";

class PaymentResult extends Component {

  constructor(props) {
    super(props);
    
    localStorage.setItem("retrieveUrl", window.location.pathname);
  };

  componentDidMount() {
    this.checkPayment();
  }

  checkPayment(){
    var _this = this;
    var data = {
      token: localStorage.getItem('wq0wdbr9aq01xxky05h1l8'),
      locale: localStorage.getItem("siteLanguage"),
      conversationId: ""
    }
   
    ChatGptPaymentService.postCheckPayment(JSON.stringify(data),this.callbackCheckPAyment, _this);
    
    // PaymentService.postCheckPayment(JSON.stringify(data),this.callbackCheckPAyment, _this);
    
   
  }

  callbackCheckPAyment(){
    var _this = this;
    var checkPayment = JSON.parse(this.response);
    if(checkPayment.paymentStatus == 'SUCCESS'){
      // window.location.href = Resources.getValue('appBaseEnvURL') + '/mind-map-list' + '?success=' + true;
      SuccessModal({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: checkPayment.paymentStatus.errorCode,
        message: "Odeme isleminiz basari ile gerceklestirilmistir. TAMAM butonu ile haritalarim sayfasina gecis yapabilirsiniz.",
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: 'button yellow-button confirm-button',
            href: Resources.getValue('appBaseEnvURL') + '/mind-map-list' + '?success=' + true
          }
        ]
      });
      PaymentService.userSubscriptionDetail();
      localStorage.removeItem("bilingAdress");
      localStorage.removeItem("wq0wdbr9aq01xxky05h1l8");
      localStorage.removeItem("ps5e6r6rq7");
      localStorage.removeItem("918171");
    }else{
      ErrorModal({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: checkPayment.paymentStatus.errorCode,
        message: checkPayment.paymentStatus.errorMessage,
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: 'button yellow-button confirm-button',
            href: Resources.getValue('appBaseEnvURL') + '/payment' + '?failed=' + true
          }
        ],
        redirectAfterClose: Resources.getValue('appBaseEnvURL') + '/payment' + '?failed=' + true
      });
    }
  }

  render() {
    return (
      <div className="success-payment"></div>
    );
  };
}


export default PaymentResult;
