import React, { Component } from "react";
import Resources from "../libraries/resources";
import * as noUiSlider from 'nouislider/distribute/nouislider.js';
import 'nouislider/distribute/nouislider.min.css'
import wNumb from 'wnumb';
import MyPlannerService from "../services/api/my-planner";

class MyPlannerToolBox extends Component {

	constructor(props) {
    super(props)

    this.state = {
      data: [],

    }
  };

	componentDidMount() {
		this.listener();
		this.drawPlanner();

	};

	drawPlanner() {
    document.querySelector(".general-full-name").innerHTML =  JSON.parse(localStorage.getItem("userInformation")).firstName + " " +JSON.parse(localStorage.getItem("userInformation")).lastName;
		document.getElementById("general-myplanner-photo").src =  JSON.parse(localStorage.getItem("userInformation")).avatarImagePath;
		document.querySelector(".reminder-full-name").innerHTML =  JSON.parse(localStorage.getItem("userInformation")).firstName + " " +JSON.parse(localStorage.getItem("userInformation")).lastName;
		document.getElementById("reminder-myplanner-photo").src =  JSON.parse(localStorage.getItem("userInformation")).avatarImagePath
		document.querySelector(".adds-myplanner-photo").innerHTML =  JSON.parse(localStorage.getItem("userInformation")).firstName + " " +JSON.parse(localStorage.getItem("userInformation")).lastName;
		document.getElementById("adds-myplanner-photo").src =  JSON.parse(localStorage.getItem("userInformation")).avatarImagePath;

	}


	listener() {

		document.getElementById("reminderId").style.display = "none"
		document.getElementById("addsId").style.display = "none"
		document.getElementById("historyId").style.display = "none"
		document.getElementById("settingId").style.display = "block"
		document.getElementById("bars-icon").style.color = "#4ac7ff";

		var menu = document.getElementById('myplanner-dropdown-item');
		menu.addEventListener('click', function (e) {
			document.querySelector('.my-planner-toolbox').classList.toggle('open-close');
			document.querySelector('.myplanner-dropdown-item i.fa.fa-chevron-circle-left ').classList.toggle('rotate');
		})

		var settingIcon = document.getElementById('bars-icon');
		settingIcon.addEventListener('click', function (e) {
			document.querySelector('.general-setting').classList.toggle('block');
			// document.querySelector('.myplanner-dropdown-item i.fa.fa-chevron-circle-left ').classList.toggle('rotate');
		})

		var rangeSlider = document.getElementById('slider-range');

		noUiSlider.create(rangeSlider, {
			connect: [true,false],
				start: 0,
				range: {
						'min': 0,
						'max': 100
				},
				tooltips: wNumb({decimals: 0, suffix: '%'}),
    		format: wNumb({decimals: 0})
		});

	
	};

	changeBarsIcon() {
		document.getElementById("bars-icon").style.color = "#4ac7ff";
		document.getElementById("clock-icon").style.color = ""
		document.getElementById("paper-clip-icon").style.color = ""
		document.getElementById("list-icon").style.color = ""

		document.getElementById("settingId").style.display = "block"
		document.getElementById("reminderId").style.display = "none"
		document.getElementById("addsId").style.display = "none"
		document.getElementById("historyId").style.display = "none"
	}

	changeClockIcon() {
		document.getElementById("clock-icon").style.color = "#4ac7ff";
		document.getElementById("bars-icon").style.color = ""
		document.getElementById("paper-clip-icon").style.color = ""
		document.getElementById("list-icon").style.color = ""

		document.getElementById("settingId").style.display = "none"
		document.getElementById("addsId").style.display = "none"
		document.getElementById("historyId").style.display = "none"
		document.getElementById("reminderId").style.display = "block"
	}

	changePaperClickIcon() {

		document.getElementById("paper-clip-icon").style.color = "#4ac7ff";
		document.getElementById("clock-icon").style.color = ""
		document.getElementById("bars-icon").style.color = ""
		document.getElementById("list-icon").style.color = ""
		document.getElementById("settingId").style.display = "none"
		document.getElementById("addsId").style.display = "block"
		document.getElementById("reminderId").style.display = "none"
		document.getElementById("historyId").style.display = "none"
	}

	changeListIcon() {

		document.getElementById("list-icon").style.color = "#4ac7ff";
		document.getElementById("clock-icon").style.color = ""
		document.getElementById("paper-clip-icon").style.color = ""
		document.getElementById("bars-icon").style.color = ""
		document.getElementById("settingId").style.display = "none"
		document.getElementById("addsId").style.display = "none"
		document.getElementById("reminderId").style.display = "none"
		document.getElementById("historyId").style.display = "block"
	}

	render() {
		return (
			<div className="my-planner-wrapper">
				<div className="row ">
					<div className="col-md-12">
						<a className="myplanner-dropdown-item" id="myplanner-dropdown-item" role="button" title={Resources.getValue('toggleMsgTxt')}><i className=" fa fa-chevron-circle-left " aria-hidden="true"></i></a>
						<div className="my-planner-toolbox open-close">
							<div className="row ">
								<div className="col-md-12">
									<div className="">
										<ul>
											<div className="row">
												<div className="col-2 col-md-2">
													<li><a onClick={this.changeBarsIcon}><i id="bars-icon" className="fa fa-bars" aria-hidden="true"></i></a></li>
												</div>
												<div className="col-2 col-md-2">
													<li><a onClick={this.changeClockIcon}><i className="fa fa-clock-o" id="clock-icon" aria-hidden="true"></i></a></li>
												</div>
												<div className="col-2 col-md-2">
													<li><a onClick={this.changePaperClickIcon}><i className="fa fa-paperclip" id="paper-clip-icon" aria-hidden="true"></i></a></li>
												</div>
												<div className="col-4 col-md-4">
													<li><a onClick={this.changeListIcon}><i className="fa fa-list-ul" id="list-icon" aria-hidden="true"></i></a></li>
												</div>
												<div className="col-2 col-md-2">
													<li><a onClick={this.changeColor}><i className="fa fa-ellipsis-v" aria-hidden="true"></i></a></li>
												</div>
											</div>
										</ul>
									</div>
								</div>
							</div>
							<hr />

							{/* General Setting  */}

							<div className="general-setting" id="settingId">
								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-2 col-md-2">
													<img
														src={Resources.getValue("imageMsgTxt")}
														className="myplanner-photo"
														alt="image"
														id="general-myplanner-photo"/>
												</div>
												<div className="col-4 col-md-4">
						
													<span className="general-full-name"></span>
												</div>
												<div className="col-6 col-md-6">
													<span>DeadLine:17/02/1996</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-6 col-md-6">
													<div className="">
														<a><i className="fa fa-folder" aria-hidden="true"></i></a>
														<select id="shape" className="myplanner-select-box">
															<option value="">
																MMS Projesi Tasklari
															</option>
														</select>
													</div>
												</div>
												<div className="col-6 col-md-6">
													<div className="">
														<select id="shape" className="myplanner-select-box">
															<option value="">
																MMS Projesi
															</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-6 col-md-6">
													<div className="">
														<a><i className="fa fa-sitemap" aria-hidden="true"></i></a>
														<select id="shape" className="myplanner-select-box">
															<option value="">
																MMS Projesi Mind...
															</option>
														</select>
													</div>
												</div>

												<div className="col-6 col-md-6">
													<div className="">
														<select id="shape" className="myplanner-select-box">
															<option value="">
																Info
															</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="">
											<div className="row">
												<div className="col-md-2">
													<a><i className="fa fa-signal" aria-hidden="true"></i></a>
												</div>

												<div className="col-md-3">
													<label>Progress</label>
												</div>
												<div className="col-md-7">
												<div className="range-box">
													<div id="slider-range"></div>
												</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<hr />


								<div className="row">
									<div className="col-md-12">
										<div className="myplanner-box">
											<div className="row">
												<div className="col-md-2">
													<a><i className="fa fa-map" aria-hidden="true"></i></a>
												</div>
												<div className="col-md-3">
													<label>My Planner</label>
												</div>
												<div className="col-md-2">
													<label className="myplanner-container">
														<input type="radio" name="myplanner-button" />
														<span className="myplanner-checkmark-now">Now</span>
													</label>
												</div>
												<div className="col-md-2">
													<label className="myplanner-container">
														<input type="radio" name="myplanner-button" />
														<span className="myplanner-checkmark-next">Next</span>
													</label>
												</div>
												<div className="col-md-3">
													<label className="myplanner-container">
														<input type="radio" name="myplanner-button" />
														<span className="myplanner-checkmark-soon">Soon</span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="importance-box">
											<div className="row">
												<div className="col-md-2">
													<a><i className="fa fa-flag" aria-hidden="true"></i></a>
												</div>
												<div className="col-md-3">
													<label>Importance</label>
												</div>
												<div className="col-md-2">
													<label className="importance-container">
														<input type="radio" name="importance-button" />
														<span className="importance-checkmark-yellow"></span>
													</label>
												</div>
												<div className="col-md-2">
													<label className="importance-container">
														<input type="radio" name="importance-button" />
														<span className="importance-checkmark-orange"></span>
													</label>
												</div>
												<div className="col-md-3">
													<label className="importance-container">
														<input type="radio" name="importance-button" />
														<span className="importance-checkmark-red"></span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="urgency-box">
											<div className="row">
												<div className="col-md-2">
													<a><i className="fa fa-bolt" aria-hidden="true"></i></a>
												</div>
												<div className="col-md-3">
													<label>Urgency</label>
												</div>
												<div className="col-md-2">
													<label className="urgency-container">
														<input type="radio" name="urgency-button" />
														<span className="urgency-checkmark-yellow"></span>
													</label>
												</div>
												<div className="col-md-2">
													<label className="urgency-container">
														<input type="radio" name="urgency-button" />
														<span className="urgency-checkmark-orange"></span>
													</label>
												</div>
												<div className="col-md-3">
													<label className="urgency-container">
														<input type="radio" name="urgency-button" />
														<span className="urgency-checkmark-red"></span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr />
							</div>

							{/* General Setting Finish */}
							{/* Reminder Setting */}

							<div className="reminder-setting" id="reminderId">

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-2 col-md-2">
													<img
														src={Resources.getValue("imageMsgTxt")}
														className="myplanner-photo"
														alt="image"
														id="reminder-myplanner-photo"
													/>
												</div>
												<div className="col-6 col-md-6">
												<span className="reminder-full-name"></span>
												</div>
												<div className="col-4 col-md-4">
													<span>DeadLine</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-12 col-md-12">
													<div className="">
														<a><i className="fa fa-calendar-check-o" aria-hidden="true"></i></a>
														<span className="calendar-title">Due 09 Aug</span>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-12 col-md-12">
													<div className="">
														<span className="remindMe-title">Remind Me</span>
														<select>
															<option value="1">1</option>
															<option value="2">2</option>
															<option value="3">3</option>
															<option value="4">4</option>
															<option value="5">5</option>
															<option value="6">6</option>
															<option value="7">7</option>
															<option value="8">8</option>
															<option value="9">9</option>
															<option value="10">10</option>
															<option value="11">11</option>
															<option value="12">12</option>
															<option value="13">13</option>
															<option value="14">14</option>
															<option value="15">15</option>
															<option value="16">16</option>
															<option value="17">17</option>
															<option value="18">18</option>
															<option value="19">19</option>
															<option value="20">20</option>
															<option value="21">21</option>
															<option value="22">22</option>
															<option value="23">23</option>
															<option value="24">24</option>
															<option value="25">25</option>
															<option value="26">26</option>
															<option value="27">27</option>
															<option value="28">28</option>
															<option value="29">29</option>
															<option value="30">30</option>
															<option value="31">31</option>
														</select>

														<span className="days-title">day(s) before at</span>

														<input type="time" id="appt" name="appt"
															min="00:00" max="23:59" required></input>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-12 col-md-12">
													<div className="">
														<span className="remindMe-title">Remind Me</span>
														<select>
															<option value="1">1</option>
															<option value="2">2</option>
															<option value="3">3</option>
															<option value="4">4</option>
															<option value="5">5</option>
															<option value="6">6</option>
															<option value="7">7</option>
															<option value="8">8</option>
															<option value="9">9</option>
															<option value="10">10</option>
															<option value="11">11</option>
															<option value="12">12</option>
															<option value="13">13</option>
															<option value="14">14</option>
															<option value="15">15</option>
															<option value="16">16</option>
															<option value="17">17</option>
															<option value="18">18</option>
															<option value="19">19</option>
															<option value="20">20</option>
															<option value="21">21</option>
															<option value="22">22</option>
															<option value="23">23</option>
															<option value="24">24</option>
															<option value="25">25</option>
															<option value="26">26</option>
															<option value="27">27</option>
															<option value="28">28</option>
															<option value="29">29</option>
															<option value="30">30</option>
															<option value="31">31</option>
														</select>

														<span className="days-title">day(s) before at</span>

														<input type="time" id="appt" name="appt"
															min="00:00" max="23:59" required></input>
													</div>
												</div>
											</div>

											<a href="" className="add-reminder">Add Reminder</a>
										</div>
									</div>
								</div>
								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-6 col-md-6">
													<a classNam=""><i className="fa fa-envelope" aria-hidden="true"></i></a>
													<span className="send-email">Send e-mail:</span>
												</div>

												<div className="col-6 col-md-6">
													<span>test@test.com</span>
												</div>
											</div>
										</div>
									</div>
								</div>

								<hr />
								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-12 col-md-12">
													<span className="remindMe-title">Notifiy me about this task</span>
													<input type="checkbox" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* Reminder Setting Finish */}

							{/* Adds Setting */}

							<div className="adds-setting" id="addsId">

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-2 col-md-2">
													<img
														src={Resources.getValue("imageMsgTxt")}
														className="myplanner-photo"
														alt="image"
														id="adds-myplanner-photo"
														/>
												</div>
												<div className="col-4 col-md-4">
													<span className="adds-myplanner-photo"></span>
												</div>
												<div className="col-6 col-md-6">
													<span>DeadLine</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-12 col-md-12">
													<div className="">
													<form action="/file-upload" className="dropzone">
														<div className="fallback">
															<input name="file" type="file" multiple />
														</div>
													</form>
												</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-12 col-md-12">
													{/* Commet */}
													<a classNam=""><i className="fa fa-comment" aria-hidden="true"></i></a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* Adds Setting Finish*/}

							{/* History Setting */}
							<div className="history-setting" id="historyId">

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-12 col-md-12">
													<span className="history-title">Task Board</span>
												</div>

											</div>
										</div>
									</div>
								</div>
								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="" >
											<div className="row">
												<div className="col-12 col-md-12">
													{/* Task boardlari cek */}
													Task boardlari cek

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* History Setting Finish */}

							
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MyPlannerToolBox;
