import React, { Component } from "react";
import Utils from "../libraries/utils";
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import Resources from "../libraries/resources";
import PaymentService from "../services/api/payment";
import ChatGptPaymentService from "../services/api/chat-gpt-payment";
import SubscriptionInformationModal from "../helpers/subscription-information-modal";
import ChatGptIcon from "../styles/img/gpt-icon.png"

// images
import silverBadgeImg from "../styles/img/free-badge.png";
import goldBadgeImg from "../styles/img/gold-badge.png";

// Modals
import ErrorModal from "../components/modals/error-modal";
import SuccessModal from "../components/modals/success-modal";

class SubscriptionDetail extends Component {
  constructor(props) {
    super(props);

    if (
      JSON.parse(localStorage.getItem("c65s1")) !== null &&
      JSON.parse(localStorage.getItem("c65s1")).companyRemainingProductDays > 0
    ) {
      window.location.href =
        Resources.getValue("appBaseEnvURL") + "/mind-map-list";
    }

    localStorage.setItem("isCustomModalOpen", false);

    this.state = {
      subscriptionData: null,
      isSubscriptionInformationModal: JSON.parse(
        localStorage.getItem("isCustomModalOpen")
      ),
      informationPopupContent: {},
    };
  }

  componentWillUnmount() {
    localStorage.removeItem("918171");
  }

  componentDidMount() {
    document.title =
      "Foramind | " + Resources.getValue("subscriptionDetailMsgTxt");
    this.getSubscriptionInformations();

    // control check payment for payment steps
    // if(
    //   localStorage.getItem('retrieveUrl') &&
    //   (localStorage.getItem('retrieveUrl').includes('retrieve') === true) ||
    //   localStorage.getItem('retrieveControlAfterLogin') &&
    //   (localStorage.getItem('retrieveControlAfterLogin').includes('retrieve') === true)
    // ) {
    //   localStorage.getItem('wq0wdbr9aq01xxky05h1l8') && this.checkPayment();  // check if payment process is done
    // }

    // setTimeout(() => {
    //   localStorage.getItem('wq0wdbr9aq01xxky05h1l8') && this.checkPayment();
    // }, 1000);
  }

  getSubscriptionInformations() {
    PaymentService.userSubscriptionDetail(this.fillUserSubscriptionInfos, this);
  }

  fillUserSubscriptionInfos() {
    var _this = this;
    var userSubscription = JSON.parse(this.response);
    if (userSubscription) {
      _this.scope.setState({
        subscriptionData: JSON.parse(JSON.stringify(userSubscription)),
      });

      // setTimeout(() => {

      // }, 1000);

      localStorage.getItem("wq0wdbr9aq01xxky05h1l8") &&
        _this.scope.checkPayment();
    }
  }

  sharedClick = (isOpen) => {
    this.setState({
      isSubscriptionInformationModal: isOpen,
    });
  };
  openInfosOnPopup(e) {
    localStorage.setItem("isCustomModalOpen", true);
    this.setState({
      isSubscriptionInformationModal: JSON.parse(
        localStorage.getItem("isCustomModalOpen")
      ),
    });

    switch (e.target.dataset.id) {
      case "cancelMembership":
        this.setState({
          informationPopupContent: {
            title: Resources.getValue("subscriptionPageCancelMembershipMsgTxt"),
            content: Resources.getValue("cancelMembershipContentMsgTxt"),
          },
        });
        break;
      case "returnConditions":
        this.setState({
          informationPopupContent: {
            title: Resources.getValue("subscriptionPageReturnConditionsMsgTxt"),
            content: Resources.getValue("returnConditionsContentMsgTxt"),
          },
        });
        break;
      case "helpCenter":
        this.setState({
          informationPopupContent: {
            title: Resources.getValue("subscriptionPageHelpCenterMsgTxt"),
            content: Resources.getValue("helpCenterContentMsgTxt"),
          },
        });
        break;
    }
  }

  // after payment process check payment status
  checkPayment() {
    var _this = this;
    var data = {
      token: localStorage.getItem("wq0wdbr9aq01xxky05h1l8"),
      locale: localStorage.getItem("siteLanguage"),
      conversationId: "",
    };

    if(localStorage.getItem("918171")) {
      ChatGptPaymentService.postCheckPayment(
        JSON.stringify(data),
        this.callbackCheckPAyment,
        _this
      );
    }else {
      PaymentService.postCheckPayment(
        JSON.stringify(data),
        this.callbackCheckPAyment,
        _this
      );
    }
 /*   PaymentService.postCheckPayment(
      JSON.stringify(data),
      this.callbackCheckPAyment,
      _this
    ); */
  }
  callbackCheckPAyment() {
    var checkPayment = JSON.parse(this.response);

    if (checkPayment.paymentStatus == "SUCCESS") {
      SuccessModal({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: Resources.getValue("successMsgTxt"),
        message: Resources.getValue("successPaymentResultTextMsgTxt"),
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: "button yellow-button confirm-button",
            href: "",
          },
        ],
      });
      this.scope.getSubscriptionInformations();
      localStorage.removeItem("bilingAdress");
      localStorage.removeItem("wq0wdbr9aq01xxky05h1l8");
      localStorage.removeItem("retrieveUrl");
      localStorage.removeItem("ps5e6r6rq7");
    } else {
      ErrorModal({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: Resources.getValue("errorMsgTxt"),
        message: checkPayment.paymentStatus.errorMessage,
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: "button yellow-button confirm-button",
            href:
              Resources.getValue("appBaseEnvURL") +
              "/payment" +
              "?failed=" +
              true,
          },
        ],
        redirectAfterClose:
          Resources.getValue("appBaseEnvURL") + "/payment" + "?failed=" + true,
      });
      localStorage.removeItem("retrieveUrl");
    }
    localStorage.removeItem("918171");
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        {this.state.isSubscriptionInformationModal ? (
          <SubscriptionInformationModal
            sharedClick={this.sharedClick}
            popupContent={this.state.informationPopupContent}
          />
        ) : null}
        <div className="dashboard-page mindmap-table wide">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="template-panel subscription-detail">
                  <div className="d-flex justify-content-between align-items-center px-3 w-100">
                    <div>
                      <SubHeader
                        title={Resources.getValue("subscriptionDetailMsgTxt")}
                        iconName="icon-verified"
                      />
                    </div>
                    <a
                      className="back-to-template-list d-none"
                      href={Resources.getValue("appBaseEnvURL") + "/payment"}
                    >
                      {" " + Resources.getValue("goPaymentPagekMsgTxt")}
                    </a>
                  </div>
                  <div className="file-box">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="infos">
                          <ul>
                            <li>
                              {Resources.getValue(
                                "subscriptionPagePackageNameTitleMsgTxt"
                              )}
                              :
                              <span className="val">
                                {this.state.subscriptionData &&
                                  (this.state.subscriptionData.productModel ===
                                  null
                                    ? Resources.getValue(
                                        "subscriptionPageBasicPackageNameMsgTxt"
                                      )
                                    : this.state.subscriptionData.productModel
                                        .id === 1
                                    ? Resources.getValue(
                                        "paymentPageMothlyTitleMsgTxt"
                                      )
                                    : Resources.getValue(
                                        "paymentPageYearlyTitleMsgTxt"
                                      ))}
                              </span>
                            </li>
                            <li>
                              {Resources.getValue(
                                "subscriptionPageRegisterDateTitleMsgTxt"
                              )}
                              :
                              <span className="val">
                                {this.state.subscriptionData &&
                                  Utils.formatDateTimeWithoutTime(
                                    this.state.subscriptionData.userCreationDate
                                  )}
                              </span>
                            </li>
                            {this.state.subscriptionData &&
                              this.state.subscriptionData.productModel ===
                                null && (
                                <li>
                                  {Resources.getValue(
                                    "subscriptionPageFreeDayTitleMsgTxt"
                                  )}
                                  :
                                  <span className="val">
                                    {this.state.subscriptionData.freeDays}{" "}
                                    {this.state.subscriptionData.freeDays > 1
                                      ? Resources.getValue(
                                          "subscriptionPageDaysMsgTxt"
                                        )
                                      : Resources.getValue(
                                          "subscriptionPageDayMsgTxt"
                                        )}
                                  </span>
                                </li>
                              )}
                            {this.state.subscriptionData &&
                              this.state.subscriptionData.productModel !==
                                null && (
                                <>
                                  <li>
                                    {Resources.getValue(
                                      "subscriptionStartTitleMsgTxt"
                                    )}
                                    :
                                    <span className="val">
                                      {Utils.formatDateTimeWithoutTime(
                                        this.state.subscriptionData
                                          .subscriptionStartDate
                                      )}
                                    </span>
                                  </li>
                                  <li>
                                    {Resources.getValue(
                                      "subscriptionEndTitleMsgTxt"
                                    )}
                                    :
                                    <span className="val">
                                      {Utils.formatDateTimeWithoutTime(
                                        this.state.subscriptionData
                                          .subscriptionExpireDate
                                      )}
                                    </span>
                                  </li>
                                </>
                              )}
                          </ul>
                          <div className="line"></div>
                          {/* abonelik varsa */}
                          {this.state.subscriptionData &&
                            this.state.subscriptionData.productModel !==
                              null && (
                              <>
                                <ul>
                                  <li>
                                    {Resources.getValue(
                                      "subscriptionPageInvoiceTitleMsgTxt"
                                    )}
                                    &nbsp;
                                    <a
                                      href={
                                        Resources.getValue("appBaseEnvURL") +
                                        "/subscription-history"
                                      }
                                    >
                                      {Resources.getValue(
                                        "subscriptionPageClickTextMsgTxt"
                                      )}
                                      .
                                    </a>
                                  </li>
                                  <li>
                                    {Resources.getValue(
                                      "subscriptionPageCancelMembershipTitleMsgTxt"
                                    )}
                                    &nbsp;
                                    <a
                                      onClick={(e) => this.openInfosOnPopup(e)}
                                      data-title={Resources.getValue(
                                        "subscriptionPageCancelMembershipMsgTxt"
                                      )}
                                      data-id="cancelMembership"
                                    >
                                      {Resources.getValue(
                                        "subscriptionPageClickTextMsgTxt"
                                      )}
                                      .
                                    </a>
                                  </li>
                                  <li>
                                    {Resources.getValue(
                                      "subscriptionPageReturnConditionsTitleMsgTxt"
                                    )}
                                    &nbsp;
                                    <a
                                      onClick={(e) => this.openInfosOnPopup(e)}
                                      data-title={Resources.getValue(
                                        "subscriptionPageReturnConditionsMsgTxt"
                                      )}
                                      data-id="returnConditions"
                                    >
                                      {Resources.getValue(
                                        "subscriptionPageClickTextMsgTxt"
                                      )}
                                      .
                                    </a>
                                  </li>
                                  <li>
                                    {Resources.getValue(
                                      "subscriptionPageHelpTitleMsgTxt"
                                    )}
                                    &nbsp;
                                    <a
                                      onClick={(e) => this.openInfosOnPopup(e)}
                                      data-title={Resources.getValue(
                                        "subscriptionPageHelpCenterMsgTxt"
                                      )}
                                      data-id="helpCenter"
                                    >
                                      {Resources.getValue(
                                        "subscriptionPageClickTextMsgTxt"
                                      )}
                                      .
                                    </a>
                                  </li>
                                </ul>
                                <div className="line"></div>
                              </>
                            )}
                          {/* abonelik varsa */}
                          <div className="change-package">
                            {this.state.subscriptionData && (
                              <>
                                {this.state.subscriptionData.productModel ===
                                null ? (
                                  <>
                                    Foramind{" "}
                                    {Resources.getValue(
                                      "subscriptionPageBasicPackageNameMsgTxt"
                                    )}
                                    ;{" "}
                                    {Resources.getValue(
                                      "subscriptionPageYourPackageTextMsgTxt"
                                    )}{" "}
                                    &nbsp;
                                    <a
                                      href={
                                        Resources.getValue("appBaseEnvURL") +
                                        "/payment"
                                      }
                                    >
                                      {Resources.getValue("subscribeMsgTxt")}
                                    </a>
                                  </>
                                ) : this.state.subscriptionData.productModel
                                    .id === 1 ? (
                                  <>
                                    {Resources.getValue(
                                      "paymentPageMothlyTitleMsgTxt"
                                    )}
                                    ;{" "}
                                    {Resources.getValue(
                                      "subscriptionPageChangePackageTextMsgTxt"
                                    )}{" "}
                                    <a
                                      href={
                                        Resources.getValue("appBaseEnvURL") +
                                        "/payment"
                                      }
                                    >
                                      {Resources.getValue(
                                        "subscriptionPageClickTextMsgTxt"
                                      )}
                                      .
                                    </a>

                                    <div className="line"> </div>
                                    <div>
                                        <p>
                                        <img style={{
                                          "width": "33px",
                                          "height": "33px",
                                          "marginRight": "9px"
                                        }} src={ChatGptIcon}></img>
                                        {Resources.getValue(
                                          "chatGptPackagesAccess"
                                        )}
                                        <a
                                        href={
                                          Resources.getValue("appBaseEnvURL") +
                                          "/ai-subscription-detail"
                                        }
                                      >
                                        {Resources.getValue(
                                          "subscriptionPageClickTextMsgTxt"
                                        )}
                                        .
                                      </a>
                                        </p> 
                                        </div>
                                  </>
                                ) : (
                                  <>
                                    {Resources.getValue(
                                      "paymentPageYearlyTitleMsgTxt"
                                    )}
                                    ;{" "}
                                    {Resources.getValue(
                                      "subscriptionPageChangePackageTextMsgTxt"
                                    )}{" "}
                                    <a
                                      href={
                                        Resources.getValue("appBaseEnvURL") +
                                        "/payment"
                                      }
                                    >
                                      {Resources.getValue(
                                        "subscriptionPageClickTextMsgTxt"
                                      )}
                                      .
                                    </a>

                                    <div className="line"> </div>
                                    <div>
                                        <p>
                                        <img style={{
                                          "width": "33px",
                                          "height": "33px",
                                          "marginRight": "9px"
                                        }} src={ChatGptIcon}></img>
                                        {Resources.getValue(
                                          "chatGptPackagesAccess"
                                        )}
                                        <a
                                        href={
                                          Resources.getValue("appBaseEnvURL") +
                                          "/ai-subscription-detail"
                                        }
                                      >
                                        {Resources.getValue(
                                          "subscriptionPageClickTextMsgTxt"
                                        )}
                                        .
                                      </a>
                                        </p> 
                                        </div>
                                  </>
                                )}
                              </>
                            
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-title text-center">
                              {this.state.subscriptionData &&
                                (this.state.subscriptionData.productModel ===
                                null
                                  ? Resources.getValue(
                                      "subscriptionPageBasicPackageNameMsgTxt"
                                    )
                                  : this.state.subscriptionData.productModel
                                      .id === 1
                                  ? Resources.getValue(
                                      "paymentPageMothlyTitleMsgTxt"
                                    )
                                  : Resources.getValue(
                                      "paymentPageYearlyTitleMsgTxt"
                                    ))}
                              {this.state.subscriptionData &&
                                this.state.subscriptionData.productModel !==
                                  null && (
                                  <img
                                    src={
                                      this.state.subscriptionData.productModel
                                        .id === 1
                                        ? silverBadgeImg
                                        : goldBadgeImg
                                    }
                                    alt={Resources.getValue("mediumManyMsgTxt")}
                                  />
                                )}
                            </div>
                            <div className="price text-center">
                              {this.state.subscriptionData &&
                                (this.state.subscriptionData.productModel ===
                                null ? (
                                  <>
                                    <span className="many-value started-many">
                                      {Resources.getValue("startedManyMsgTxt")}
                                    </span>
                                    <div className="frequency started-many">
                                      {Resources.getValue("aMonthMsgTxt")}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <span className="kdv-info">
                                      (
                                      {Resources.getValue(
                                        "kdvInfoForPackagesTextMsgTxt"
                                      )}
                                      )
                                    </span>
                                    <div className="price-wrap">
                                      {this.state.subscriptionData.productModel
                                        .currency === "USD" && (
                                        <span className="currency">$</span>
                                      )}
                                      <span className="many-value month-many">
                                        {
                                          this.state.subscriptionData
                                            .productModel.price
                                        }
                                      </span>
                                      {this.state.subscriptionData.productModel
                                        .currency === "TRY" && (
                                        <span className="currency">₺</span>
                                      )}
                                    </div>
                                    <div className="frequency">
                                      {this.state.subscriptionData.productModel
                                        .id === 1
                                        ? Resources.getValue(
                                            "paymentPageMothlyInfoTextMsgTxt"
                                          )
                                        : Resources.getValue(
                                            "paymentPageYearlyInfoTextMsgTxt"
                                          )}
                                    </div>
                                    {this.state.subscriptionData.productModel
                                      .id === 1 ? (
                                      <div className="card-monthly-info">
                                        (
                                        {Resources.getValue(
                                          "dayInfoForMonthlyPackageTextMsgTxt"
                                        )}
                                        )
                                      </div>
                                    ) : null}
                                  </>
                                ))}
                            </div>
                            <ul className="landingPage-ulList li-space-lg">
                              <li className="media">
                                <div className="section-description">
                                  {Resources.getValue("startedli1MsgTxt")}
                                </div>
                                <i className="icon-check_circle"></i>
                              </li>
                              <li className="media">
                                <div className="section-description">
                                  {Resources.getValue("startedli2MsgTxt")}
                                </div>
                                <i className="icon-check_circle"></i>
                              </li>
                              <li className="media">
                                <div className="section-description">
                                  {Resources.getValue("startedli3MsgTxt")}
                                </div>
                                <i className="icon-check_circle"></i>
                              </li>
                              <li className="media">
                                <div className="section-description">
                                  {Resources.getValue("startedli4MsgTxt")}
                                </div>
                                <i className="icon-check_circle"></i>
                              </li>
                              <li className="media">
                                <div className="section-description">
                                  {Resources.getValue("startedli5MsgTxt")}
                                </div>
                                <i className="icon-check_circle"></i>
                              </li>
                              <li className="media">
                                <div className="section-description">
                                  {Resources.getValue("startedli6MsgTxt")}
                                </div>
                                <i className="icon-check_circle"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SubscriptionDetail;
