import React, { Component } from "react";
import Resources from "../libraries/resources";
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MindMapService from "../services/api/mind-map";
import Utils from "../libraries/utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import iconShared from "../styles/img/shared-icon.png";
import iconGridFile from "../styles/img/grid-file-icon.png";
import MapService from "../services/api/map";
import ChatGptMapListIcon from "../styles/img/gpt-gray-icon.png";

class MindMap extends Component {

  constructor(props) {
    super(props)

    this.state = {
      data: [],
      deletedMapId: null,
      name: "",
    }

    localStorage.setItem('isFavFilter', false);
    localStorage.setItem('isSharedFilter', false);
    localStorage.setItem('isAiFilter', false);
    localStorage.setItem('isNewMap', false);
    localStorage.setItem('mapPermission', 0);
    localStorage.removeItem("willGoToNewMapPage");
    
    localStorage.setItem("retrieveUrl", window.location.pathname);
  };

  componentDidMount() {
    document.title = "Foramind | " + Resources.getValue("mindMapsMsgTxt");
    this.getMindMap();
    this.changeLayout();
    this.showHideSearch();
  };

  getMindMap = () => {
    var recordSize = 1000;
    var id = JSON.parse(localStorage.getItem("userInformation")).id;
    var callback = this.fillMindMap;
    MindMapService.getMindMap(recordSize, callback, this, id);
  };

  fillMindMap() {
    var _this = this;
    const mindMapList = JSON.parse(this.response).mindMap;
    
    localStorage.setItem('requestIsDone', false);

    var listedItems = document.querySelectorAll('.table-list');
    if (listedItems.length > 0) {
      _this.scope.setState({
        data: []
      })
    }

    if (mindMapList.length > 0) {
      for (var i = 0; i < mindMapList.length; i++) {
        if (mindMapList[i].modifiedDate)
          mindMapList[i].modifiedDate = Utils.formatDateWithMonthName(mindMapList[i].modifiedDate)

        if (mindMapList[i].creationDate)
          mindMapList[i].creationDate = mindMapList[i].creationDate
          // mindMapList[i].creationDate = Utils.formatDateWithMonthName(mindMapList[i].creationDate)
      }
      _this.scope.setState({
        data: JSON.parse(JSON.stringify(mindMapList))
      })
    } else {
      // if there is no map
      window.location.href = (Resources.getValue("appBaseEnvURL") + '/template-list');
    }
  };

  deleteMindMap(scope) {
    Utils.modalm().close();
    MindMapService.delete(scope.state.deletedMapId, scope.getMindMap, scope);
  };

  deleteRow(id) {
    this.state.data.findIndex(post => {
      return post.id === id
    })
    this.setState({
      deletedMapId: id
    })
    this.deleteMapApproveModal();
  };

  deleteMapApproveModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("areyousureMsgTxt"),
      bodyContent: '<p>' + Resources.getValue('deleteMapApproveModalMsgTxt') + '</p>',
      buttons: [
        {
          text: Resources.getValue("noMsgTxt"),
          class: 'button fresh-button reject-button',
          href: ''
        },
        {
          text: Resources.getValue("yesMsgTxt"),
          class: 'button yellow-button confirm-button',
          href: ''
        }

      ],
      confirmCallback: this.deleteMindMap,
      rejectCallback: null,
      scope: this
    });
  };

  cellInfoUpdate(cellInfo, e) {
    const data = [...this.state.data];
    data[cellInfo.index][cellInfo.column.id] = e.target.innerText;
    this.setState({ data });
    MindMapService.updateMapName(cellInfo.original.id, cellInfo.original.name, this.getMindMap, this);
  };

  renderDateColumn = (cellInfo) => {
    return (
      <div>
        {Utils.formatDateWithMonthName(cellInfo.original.creationDate)}
      </div>
    );
  }

  renderEditable = (cellInfo) => {
    const data = this.state.data[cellInfo.index];
    return (
      <div className="name-fav-wrapper">
        <div className="name-fav-wrapper__item-name">
          <div
            className="editable-name-div none"
            contentEditable
            id={cellInfo.original.id}
            suppressContentEditableWarning
            onKeyPress={(e) => {
              if (e.which === 13) {
                if ((e.target.innerText && e.target.innerText.trim()) && (e.target.innerText != cellInfo.original.name)) {
                  this.cellInfoUpdate(cellInfo, e);
                  e.preventDefault()
                }
              }
            }}
            onBlur={e => {
              if ((e.target.innerText && e.target.innerText.trim()) && (e.target.innerText != cellInfo.original.name)) {
                this.cellInfoUpdate(cellInfo, e);
              } else {
                e.target.innerText = cellInfo.original.name;
              }
            }}
            dangerouslySetInnerHTML={{
              __html: this.state.data[cellInfo.index][cellInfo.column.id]
            }}
          />
          <a 
            onClick={() => {
              this.clickOpenUrl(data.id, data.name);
              localStorage.setItem("mapJsonObj", JSON.stringify(data));
              localStorage.setItem('mapPermission', data.mapPermissionId);
            }}
            title={cellInfo.original.name}
          >
            {data[cellInfo.column.id]}
          </a>
          {cellInfo.original.isMapShared ? (
            <>
              <img src={iconShared} alt={Resources.getValue("sharedMapMsgTxt")} title={Resources.getValue("sharedMapMsgTxt")} />
            </>
          ) : ''}
        </div>
        {cellInfo.original.isCopyMap === true ? (
          (<span className="copied-user-name">
            <i className="fa fa-copy copy-icon" title={Resources.getValue("copiedMapMsgTxt")}></i> ({cellInfo.original.parentMapCreatedUserNameSurname + Resources.getValue('isCopiedInfoMsgTxt')})
          </span>)
        ) : ''}
        <a
          onClick={(e) => {
            if(cellInfo.original.isPublicMap === true) {
              this.makePublicPrivate(cellInfo.original.id, !cellInfo.original.isPublicMap)
            } else {
              e.preventDefault();
            }
          }}
          className={`fav-action public-private-action${cellInfo.original.isPublicMap ? ' active' : ''}`}
          title={cellInfo.original.isPublicMap ? Resources.getValue("makePrivateMsgTxt") :Resources.getValue("makePublicFromListMsgTxt")}
        >
          <i className={`fa ${cellInfo.original.isPublicMap ? 'fa-unlock' : 'fa-lock'}`}></i>
        </a>

        <a className={`fav-action ${cellInfo.original.isAiGenerated ? 'ai-icons' : ''}`}
          title={Resources.getValue("isAiGeneratedMsgTxt")}>
          {cellInfo.original.isAiGenerated ?
              <>
                <img className="chatgpt-icon" src={ChatGptMapListIcon} alt="AI Icon"/>
              </>
              :
              <></>
          }
        </a>
        <a
          onClick={() => this.addRemoveFavorite(cellInfo.original.id, !cellInfo.original.isFavorite)}
          className={`fav-action${cellInfo.original.isFavorite ? ' active' : ''}`}
          title={cellInfo.original.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
        >
          <i className="icon-favorite-icon"></i>
        </a>
        
      </div>
    );
  };

  renderFavListEditable = (cellInfo) => {
    const favoritedData = this.state.data.filter(item => {
      return item.isFavorite === true;
    });

    return (
      <div className="name-fav-wrapper">
        <div className="name-fav-wrapper__item-name">
          <a 
            onClick={() => {
              this.clickOpenUrl(favoritedData[cellInfo.index].id, favoritedData[cellInfo.index].name);
              localStorage.setItem("mapJsonObj", JSON.stringify(favoritedData[cellInfo.index]));
              localStorage.setItem('mapPermission', favoritedData[cellInfo.index].mapPermissionId);
            }}
            title={cellInfo.original.name}
          >
            {favoritedData[cellInfo.index][cellInfo.column.id]}
          </a>

          {cellInfo.original.isAiGenerated ? (
            <img  style={{position: 'absolute', left: '650px', top: "10px", width: "20px",  height: "20px"}} src={ChatGptMapListIcon} alt="AI Icon" />
          ) : ''}
          
          {cellInfo.original.isMapShared ? (
            <img src={iconShared} alt={Resources.getValue("sharedMapMsgTxt")} title={Resources.getValue("sharedMapMsgTxt")} />
          ) : ''}
        </div>
        {cellInfo.original.isCopyMap === true ? (
          (<span className="copied-user-name">
            <i className="fa fa-copy copy-icon" title={Resources.getValue("copiedMapMsgTxt")}></i> ({cellInfo.original.parentMapCreatedUserNameSurname + Resources.getValue('isCopiedInfoMsgTxt')})
          </span>)
        ) : ''}

        <a
          onClick={(e) => {
            if(cellInfo.original.isPublicMap === true) {
              this.makePublicPrivate(cellInfo.original.id, !cellInfo.original.isPublicMap)
            } else {
              e.preventDefault();
            }
          }}
          className={`fav-action public-private-action${cellInfo.original.isPublicMap ? ' active' : ''}`}
          title={cellInfo.original.isPublicMap ? Resources.getValue("makePrivateMsgTxt") :Resources.getValue("makePublicFromListMsgTxt")}
        >
          <i className={`fa ${cellInfo.original.isPublicMap ? 'fa-unlock' : 'fa-lock'}`}></i>
        </a>
        <a
          onClick={() => this.addRemoveFavorite(cellInfo.original.id, !cellInfo.original.isFavorite)}
          className={`fav-action${cellInfo.original.isFavorite ? ' active' : ''}`}
          title={cellInfo.original.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
        >
          <i className="icon-favorite-icon"></i>
        </a>
      </div>
    );
  };

  renderSharedListEditable = (cellInfo) => {
    const sharedData = this.state.data.filter(item => {
      return item.isMapShared === true;
    });
    return (
      <div className="name-fav-wrapper">
        <div className="name-fav-wrapper__item-name">
          <a 
            onClick={() => {
              this.clickOpenUrl(sharedData[cellInfo.index].id, sharedData[cellInfo.index].name);
              localStorage.setItem("mapJsonObj", JSON.stringify(sharedData[cellInfo.index]));
              localStorage.setItem('mapPermission', sharedData[cellInfo.index].mapPermissionId);
            }}
            title={cellInfo.original.name}
          >
            {sharedData[cellInfo.index][cellInfo.column.id]}
          </a>

          {cellInfo.original.isAiGenerated ? (
            <img  style={{position: 'absolute', left: '650px', top: "10px", width: "20px",  height: "20px"}} src={ChatGptMapListIcon} alt="AI Icon" />
          ) : ''}

          {cellInfo.original.isMapShared ? (
            <img src={iconShared} alt={Resources.getValue("sharedMapMsgTxt")} title={Resources.getValue("sharedMapMsgTxt")} />
          ) : ''}
        </div>
        {cellInfo.original.isCopyMap === true ? (
          (<span className="copied-user-name">
            <i className="fa fa-copy copy-icon" title={Resources.getValue("copiedMapMsgTxt")}></i> ({cellInfo.original.parentMapCreatedUserNameSurname + Resources.getValue('isCopiedInfoMsgTxt')})
          </span>)
        ) : ''}
        <a
          onClick={(e) => {
            if(cellInfo.original.isPublicMap === true) {
              this.makePublicPrivate(cellInfo.original.id, !cellInfo.original.isPublicMap)
            } else {
              e.preventDefault();
            }
          }}
          className={`fav-action public-private-action${cellInfo.original.isPublicMap ? ' active' : ''}`}
          title={cellInfo.original.isPublicMap ? Resources.getValue("makePrivateMsgTxt") :Resources.getValue("makePublicFromListMsgTxt")}
        >
          <i className={`fa ${cellInfo.original.isPublicMap ? 'fa-unlock' : 'fa-lock'}`}></i>
        </a>
        <a
          onClick={() => this.addRemoveFavorite(cellInfo.original.id, !cellInfo.original.isFavorite)}
          className={`fav-action${cellInfo.original.isFavorite ? ' active' : ''}`}
          title={cellInfo.original.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
        >
          <i className="icon-favorite-icon"></i>
        </a>
      </div>
    );
  };

  renderAiGeneratedListEditable = (cellInfo) => {
    const aiData = this.state.data.filter((item) => {
      return item.isAiGenerated === true;
    });

    return (
      <div className="name-fav-wrapper">
        <div className="name-fav-wrapper__item-name">
          <a 
            onClick={() => {
              this.clickOpenUrl(aiData[cellInfo.index].id, aiData[cellInfo.index].name);
              localStorage.setItem("mapJsonObj", JSON.stringify(aiData[cellInfo.index]));
              localStorage.setItem('mapPermission', aiData[cellInfo.index].mapPermissionId);
            }}
            title={cellInfo.original.name}
          >
            {aiData[cellInfo.index][cellInfo.column.id]}
          </a>

          {cellInfo.original.isAiGenerated ? (
            <img  style={{position: 'absolute', left: '650px', top: "10px", width: "20px",  height: "20px"}} src={ChatGptMapListIcon} alt="AI Icon" />
          ) : ''}

          {cellInfo.original.isMapShared ? (
            <img src={iconShared} alt={Resources.getValue("sharedMapMsgTxt")} title={Resources.getValue("sharedMapMsgTxt")} />
          ) : ''}
        </div>
        {cellInfo.original.isCopyMap === true ? (
          (<span className="copied-user-name">
            <i className="fa fa-copy copy-icon" title={Resources.getValue("copiedMapMsgTxt")}></i> ({cellInfo.original.parentMapCreatedUserNameSurname + Resources.getValue('isCopiedInfoMsgTxt')})
          </span>)
        ) : ''}
        <a
          onClick={(e) => {
            if(cellInfo.original.isPublicMap === true) {
              this.makePublicPrivate(cellInfo.original.id, !cellInfo.original.isPublicMap)
            } else {
              e.preventDefault();
            }
          }}
          className={`fav-action public-private-action${cellInfo.original.isPublicMap ? ' active' : ''}`}
          title={cellInfo.original.isPublicMap ? Resources.getValue("makePrivateMsgTxt") :Resources.getValue("makePublicFromListMsgTxt")}
        >
          <i className={`fa ${cellInfo.original.isPublicMap ? 'fa-unlock' : 'fa-lock'}`}></i>
        </a>
        <a
          onClick={() => this.addRemoveFavorite(cellInfo.original.id, !cellInfo.original.isFavorite)}
          className={`fav-action${cellInfo.original.isFavorite ? ' active' : ''}`}
          title={cellInfo.original.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
        >
          <i className="icon-favorite-icon"></i>
        </a>
      </div>
    );
  };
  
  updateOnClick(id) {
    var editingCell = document.getElementById(id);
    editingCell.classList.remove('none');
    editingCell.nextElementSibling.classList.add('none');
    editingCell.focus();
  };

  clickOpenUrl(id, name) {
    localStorage.setItem('openedMapName', name);
    localStorage.setItem('openedMapId', id);
    window.open(
      (Resources.getValue("appBaseEnvURL") + '/map' + '?mapId=' + id), 
      '_self', 
      'noopener,noreferrer'
    ); // to prevent open in new tab
    // window.location.href = (Resources.getValue("appBaseEnvURL") + '/map' + '?mapId=' + id);
  };

  addRemoveFavorite(mapId, isFavStatus) {
    const userId = JSON.parse(localStorage.getItem('userInformation')).id;
    const favData = {
      mindMapId: mapId,
      userId: userId,
      isFavorite: isFavStatus
    }
    MapService.AddRemoveFavorite(JSON.stringify(favData), this.favCallback, this);
  };

  favCallback() {
    var _this = this;
    _this.scope.getMindMap();
  }

  makePublicPrivate(mapId, isPublicStatus) {
    const data = {
      mindMapId: mapId,
      isPublicMap: isPublicStatus,
    };
    MapService.SetPublicOrPrivateMap(
      data,
      this.privatePublicCallback,
      this
    );
  }

  privatePublicCallback() {
    var _this = this;
    _this.scope.getMindMap();
  }

  changeLayout() {
    let optionBtn = document.querySelectorAll('.layout-option');
    [].forEach.call(optionBtn, el => {
      el.addEventListener('click', function () {
        [].forEach.call(optionBtn, e => {
          e.classList.remove('active');
          document.querySelector('.show-hide-fav').classList.remove('active');
          document.querySelector('.show-hide-share').classList.remove('active');
          localStorage.setItem('isFavFilter', false);
          localStorage.setItem('isSharedFilter', false);
          const sublist = document.querySelectorAll('.sub-list');
          sublist.forEach((elem) => {
            elem.classList.remove('show');
          });
        });
        el.classList.toggle("active");
        const dataLayout = el.getAttribute("data-layout");
        let allContents = document.querySelectorAll('.layout-option-content');
        allContents.forEach((elem) => {
          elem.classList.remove('show');
        });
        document.querySelector('.layout-option-content[data-layout="' + dataLayout + '"]').classList.add('show');
        document.querySelector(
          '.layout-option-content[data-layout="' + dataLayout + '"] .sub-list:nth-of-type(1)'
        ).classList.add('show');
      })
    });
  }

  showHideSharedList() {
    const shareBtn = document.querySelector('.show-hide-share');
    const subList = document.querySelectorAll('.sub-list');
    const favBtn = document.querySelector('.show-hide-fav');
    const aiBtn = document.querySelector('.show-hide-ai');
    localStorage.setItem('isFavFilter', false);
    favBtn.classList.remove('active');
    aiBtn.classList.remove('active-ai');

    if (shareBtn.classList.contains('active')) {
      shareBtn.classList.remove('active');
      localStorage.setItem('isSharedFilter', false);
      subList.forEach((elem) => {
        elem.classList.remove('show');
      });
      document.querySelector('.layout-option-content.show .sub-list:nth-of-type(1)').classList.add('show');
    } else {
      shareBtn.classList.add('active');
      localStorage.setItem('isSharedFilter', true);
      subList.forEach((elem) => {
        elem.classList.remove('show');
      });
      document.querySelector('.layout-option-content.show .sub-list:nth-of-type(2)').classList.add('show');
    }
  }

  showHideFavList() {
    const favBtn = document.querySelector('.show-hide-fav');
    const subList = document.querySelectorAll('.sub-list');
    const aiBtn = document.querySelector('.show-hide-ai');
    const shareBtn = document.querySelector('.show-hide-share');
    localStorage.setItem('isSharedFilter', false);
    shareBtn.classList.remove('active');
    aiBtn.classList.remove('active-ai');

    if (favBtn.classList.contains('active')) {
      favBtn.classList.remove('active');
      localStorage.setItem('isFavFilter', false);
      subList.forEach((elem) => {
        elem.classList.remove('show');
      });
      document.querySelector('.layout-option-content.show .sub-list:nth-of-type(1)').classList.add('show');
    } else {
      favBtn.classList.add('active');
      localStorage.setItem('isFavFilter', true);
      subList.forEach((elem) => {
        elem.classList.remove('show');
      });
      document.querySelector('.layout-option-content.show .sub-list:nth-of-type(3)').classList.add('show');
    }
  }

  showHideAiList() {
    const aiBtn = document.querySelector('.show-hide-ai');
    const favBtn = document.querySelector('.show-hide-fav');
    const shareBtn = document.querySelector('.show-hide-share');
    favBtn.classList.remove('active');
    shareBtn.classList.remove('active');
    const subList = document.querySelectorAll('.sub-list');
    localStorage.setItem('isSharedFilter', false);
    localStorage.setItem('isFavFilter', false);

    if (aiBtn.classList.contains('active-ai')) {
      aiBtn.classList.remove('active-ai');
      localStorage.setItem('isAiFilter', false);
      subList.forEach((elem) => {
        elem.classList.remove('show');
      });
      document.querySelector('.layout-option-content.show .sub-list:nth-of-type(1)').classList.add('show');
    } else {
      aiBtn.classList.add('active-ai');
      localStorage.setItem('isAiFilter', true);
      subList.forEach((elem) => {
        elem.classList.remove('show');
      });
      document.querySelector('.layout-option-content.show .sub-list:nth-of-type(4)').classList.add('show');
    }
  }

  showHideSearch() {
    const filterSearch = document.querySelectorAll('.ReactTable .rt-thead.-filters');
    const tableBody = document.querySelectorAll('.ReactTable .rt-tbody');
    var filterInput = document.querySelectorAll('.show .ReactTable .rt-thead.-filters input');

    filterInput.forEach((inp, ind) => {
      inp.setAttribute('id', `search${ind}`);
      inp.setAttribute('placeholder', Resources.getValue("searchMsgTxt"));
      inp.setAttribute('class', 'Search-map-list');
    });

    filterSearch.forEach(elem => {
      
      filterInput.forEach((inp, ind) => {
        // document.getElementById(`search${ind}`).focus();
      });
      if(elem.classList.contains('show')) {
        // elem.classList.remove('show');
        tableBody.forEach(el => {
          // el.classList.remove('show-filter-pos');
        });
      } else {
        elem.classList.add('show');
        tableBody.forEach(el => {
          el.classList.add('show-filter-pos');
        });
      }
    });
  }

  render() {
    const data = this.state.data;
    const favoritedData = data.filter(item => {
      return item.isFavorite === true;
    });
    
    const sharedData = data.filter(item => {
      return item.isMapShared === true;
    });

    const aiData = data.filter(item => {
      return item.isAiGenerated === true;
    });

    return (
      <React.Fragment>
        <Header />
        <ToastContainer />
        <div className="mindmap-table wide">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="template-panel">
                  <div className="title-wrapper">
                    <div className="px-3">
                      <SubHeader title={Resources.getValue("mindMapsMsgTxt")} iconName="icon-map_list_icon" />
                    </div>
                    <div className="list-grid-options">
                    <a className="ai show-hide-ai" 
                    title="List AI Icons"
                    onClick={this.showHideAiList}
                  >
                  <img style={{
                    "width": "23px",
                    "height": "23px",
                    "marginRight": "6px",
                    "marginTop": "-12px",
                    "color": "#fd7021",
                    "borderRadius": "50%",
                    }} src={ChatGptMapListIcon}></img>
                  </a>
                      <a title={Resources.getValue("showSharedItemsMsgTxt")}
                        className="share show-hide-share"
                        onClick={this.showHideSharedList}
                      >
                        <i className="icon-map-shared-icon"></i>
                      </a>
                      <a title={Resources.getValue("showFavoritesMsgTxt")}
                        className="fav show-hide-fav"
                        onClick={this.showHideFavList}
                      >
                        <i className="icon-favorite-icon"></i>
                      </a>
                      <a title={Resources.getValue("showAsListMsgTxt")}
                        className="list layout-option active"
                        data-layout="list"
                        onClick={this.changeLayout}
                      >
                        <i className="icon-list-icon"></i>
                      </a>
                      <a title={Resources.getValue("showAsGridMsgTxt")}
                        className="grid layout-option"
                        data-layout="grid"
                        onClick={this.changeLayout}
                      >
                        <i className="icon-grid-icon"></i>
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="col-md-12 px-3 pb-3">
                      <div className="table-list layout-option-content show" data-layout="list">
                        {/* All list */}
                        <div
                          className={`sub-list${JSON.parse(localStorage.getItem('isFavFilter')) ? '' : ' show'}`}
                        >
                          {/* <a onClick={this.showHideSearch} className="list-search-btn">
                            <i className="icon-search-icon"></i>
                          </a> */}
                          <div className="map-table">
                          <ReactTable
                          data={data}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.accessor]) === filter.value}
                          previousText={Resources.getValue("previousTextMsgTxt")}
                          nextText={Resources.getValue("nextTextMsgTxt")}
                          pageText={Resources.getValue("pageTextMsgTxt")}
                          rowsText={Resources.getValue("rowsTextMsgTxt")}
                          ofText={Resources.getValue("ofTextMsgTxt")}
                          noDataText={Resources.getValue("noDataTextMsgTxt")}
                          loadingText={Resources.getValue("loadingTextMsgTxt")}
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                          onPageChange={(data) => {
                            this.setState({
                              listPageCurrent: data
                            });
                          }}
                          page={this.state.listPageCurrent ? this.state.listPageCurrent : undefined}

                          const columns={[
                            {
                              columns: [
                                {
                                  Header: () => (
                                    <div className="title-search-wrap">
                                      <span>{Resources.getValue("nameMsgTxt")}</span>
                                    </div>
                                  ),
                                  accessor: "name",
                                  filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                                  Cell: this.renderEditable,
                                  className: "name-column",
                                }]
                            },
                            {
                              columns: [
                                {
                                  Header: Resources.getValue("creationDateMsgTxt"),
                                  accessor: "creationDate",
                                  filterMethod: (filter, row) => 
                                    row[filter.id].startsWith(filter.value),
                                  Cell: this.renderDateColumn,
                                  className: "date-column",
                                }]
                            },
                            {
                              columns: [
                                {
                                  Cell: props => {
                                    return (
                                      <div>
                                        <a onClick={() => {
                                          this.updateOnClick(props.original.id)
                                        }} className="action-button" title={Resources.getValue('editNameMsgTxt')}>
                                          <i className="icon-edit-icon"></i>
                                        </a>

                                        <a onClick={() => {
                                          this.clickOpenUrl(props.original.id, props.original.name);
                                          localStorage.setItem("mapJsonObj", JSON.stringify(props.original));
                                          localStorage.setItem('mapPermission', props.original.mapPermissionId);
                                        }} className="action-button" title={Resources.getValue('showMsgTxt')}>
                                          <i className="icon-show_icon"></i>
                                        </a>
                                        <a onClick={() => {
                                          this.deleteRow(props.original.id)
                                        }} className="action-button" title={Resources.getValue('deleteMsgTxt')} >
                                          <i className="icon-delete-icon"></i>
                                        </a>
                                      </div>
                                    )
                                  },
                                  className: "action-column",
                                  style: {
                                    textAlign: "center"
                                  },
                                  sortable: false,
                                  filterable: false,
                                }]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                        />
                          </div>
                        </div>
                        {/* Shared List */}
                        <div
                          className={`map-table sub-list${JSON.parse(localStorage.getItem('isSharedFilter')) ? ' show' : ''}`}
                        >
                          {/* <a onClick={this.showHideSearch} className="list-search-btn">
                            <i className="icon-search-icon"></i>
                          </a> */}
                          <ReactTable
                            data={sharedData}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                              String(row[filter.accessor]) === filter.value}
                            previousText={Resources.getValue("previousTextMsgTxt")}
                            nextText={Resources.getValue("nextTextMsgTxt")}
                            pageText={Resources.getValue("pageTextMsgTxt")}
                            rowsText={Resources.getValue("rowsTextMsgTxt")}
                            ofText={Resources.getValue("ofTextMsgTxt")}
                            noDataText={Resources.getValue("noDataTextMsgTxt")}
                            loadingText={Resources.getValue("loadingTextMsgTxt")}
                            defaultFilterMethod={(filter, row) =>
                              String(row[filter.id]) === filter.value}
                            onPageChange={(data) => {
                              this.setState({
                                sharedPageCurrent: data
                              });
                            }}
                            page={this.state.sharedPageCurrent ? this.state.sharedPageCurrent : undefined}

                            const columns={[
                              {
                                columns: [
                                  {
                                    Header: () => (
                                      <div className="title-search-wrap">
                                        <span>{Resources.getValue("nameMsgTxt")}</span>
                                      </div>
                                    ),
                                    accessor: "name",
                                    filterMethod: (filter, row) =>
                                      row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                                    Cell: this.renderSharedListEditable,
                                    className: "name-column",
                                  }]
                              },
                              {
                                columns: [
                                  {
                                    Header: Resources.getValue("creationDateMsgTxt"),
                                    accessor: "creationDate",
                                    filterMethod: (filter, row) =>
                                      row[filter.id].startsWith(filter.value),
                                    Cell: this.renderDateColumn,
                                    className: "date-column",
                                  }]
                              },
                              {
                                columns: [
                                  {
                                    Cell: props => {
                                      return (
                                        <div>
                                          <a onClick={() => {
                                            this.clickOpenUrl(props.original.id, props.original.name);
                                            localStorage.setItem("mapJsonObj", JSON.stringify(props.original));
                                            localStorage.setItem('mapPermission', props.original.mapPermissionId);
                                          }} className="action-button" title={Resources.getValue('showMsgTxt')}>
                                            <i className="icon-show_icon"></i>
                                          </a>
                                          <a onClick={() => {
                                            this.deleteRow(props.original.id)
                                          }} className="action-button" title={Resources.getValue('deleteMsgTxt')} >
                                            <i className="icon-delete-icon"></i>
                                          </a>
                                        </div>
                                      )
                                    },
                                    className: "action-column",
                                    style: {
                                      textAlign: "center"
                                    },
                                    sortable: false,
                                    filterable: false,
                                  }]
                              }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                          />
                        </div>
                        {/* Favorited List */}
                        <div
                          className={`map-table sub-list${JSON.parse(localStorage.getItem('isFavFilter')) ? ' show' : ''}`}
                        >
                          {/* <a onClick={this.showHideSearch} className="list-search-btn">
                            <i className="icon-search-icon"></i>
                          </a> */}
                          <ReactTable
                            data={favoritedData}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                              String(row[filter.accessor]) === filter.value}
                            previousText={Resources.getValue("previousTextMsgTxt")}
                            nextText={Resources.getValue("nextTextMsgTxt")}
                            pageText={Resources.getValue("pageTextMsgTxt")}
                            rowsText={Resources.getValue("rowsTextMsgTxt")}
                            ofText={Resources.getValue("ofTextMsgTxt")}
                            noDataText={Resources.getValue("noDataTextMsgTxt")}
                            loadingText={Resources.getValue("loadingTextMsgTxt")}
                            defaultFilterMethod={(filter, row) =>
                              String(row[filter.id]) === filter.value}
                            onPageChange={(data) => {
                              this.setState({
                                favPageCurrent: data
                              });
                            }}
                            page={this.state.favPageCurrent ? this.state.favPageCurrent : undefined}

                            const columns={[
                              {
                                columns: [
                                  {
                                    Header: () => (
                                      <div className="title-search-wrap">
                                        <span>{Resources.getValue("nameMsgTxt")}</span>
                                      </div>
                                    ),
                                    accessor: "name",
                                    filterMethod: (filter, row) =>
                                      row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                                    Cell: this.renderFavListEditable,
                                    className: "name-column",
                                  }]
                              },
                              {
                                columns: [
                                  {
                                    Header: Resources.getValue("creationDateMsgTxt"),
                                    accessor: "creationDate",
                                    filterMethod: (filter, row) =>
                                      row[filter.id].startsWith(filter.value),
                                    Cell: this.renderDateColumn,
                                    className: "date-column",
                                  }]
                              },
                              {
                                columns: [
                                  {
                                    Cell: props => {
                                      return (
                                        <div>
                                          <a onClick={() => {
                                            this.clickOpenUrl(props.original.id, props.original.name);
                                            localStorage.setItem("mapJsonObj", JSON.stringify(props.original));
                                            localStorage.setItem('mapPermission', props.original.mapPermissionId);
                                          }} className="action-button" title={Resources.getValue('showMsgTxt')}>
                                            <i className="icon-show_icon"></i>
                                          </a>
                                          <a onClick={() => {
                                            this.deleteRow(props.original.id)
                                          }} className="action-button" title={Resources.getValue('deleteMsgTxt')} >
                                            <i className="icon-delete-icon"></i>
                                          </a>
                                        </div>
                                      )
                                    },
                                    className: "action-column",
                                    style: {
                                      textAlign: "center"
                                    },
                                    sortable: false,
                                    filterable: false,
                                  }]
                              }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                          />
                        </div>

                        {/* AI List */}
                        <div
                        className={`map-table sub-list${JSON.parse(localStorage.getItem('isAiFilter')) ? ' show' : ''}`}
                      >
                          <ReactTable
                            data={aiData}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                              String(row[filter.accessor]) === filter.value}
                            previousText={Resources.getValue("previousTextMsgTxt")}
                            nextText={Resources.getValue("nextTextMsgTxt")}
                            pageText={Resources.getValue("pageTextMsgTxt")}
                            rowsText={Resources.getValue("rowsTextMsgTxt")}
                            ofText={Resources.getValue("ofTextMsgTxt")}
                            noDataText={Resources.getValue("noDataTextMsgTxt")}
                            loadingText={Resources.getValue("loadingTextMsgTxt")}
                            onPageChange={(data) => {
                              this.setState({
                                favPageCurrent: data
                              });
                            }}
                            page={this.state.favPageCurrent ? this.state.favPageCurrent : undefined}

                            const columns={[
                              {
                                columns: [
                                  {
                                    Header: () => (
                                      <div className="title-search-wrap">
                                        <span>{Resources.getValue("nameMsgTxt")}</span>
                                      </div>
                                    ),
                                    accessor: "name",
                                    filterMethod: (filter, row) =>
                                      row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                                    Cell: this.renderAiGeneratedListEditable,
                                    className: "name-column",
                                  }]
                              },
                              {
                                columns: [
                                  {
                                    Header: Resources.getValue("creationDateMsgTxt"),
                                    accessor: "creationDate",
                                    filterMethod: (filter, row) =>
                                      row[filter.id].startsWith(filter.value),
                                    Cell: this.renderDateColumn,
                                    className: "date-column",
                                  }]
                              },
                              {
                                columns: [
                                  {
                                    Cell: props => {
                                      return (
                                        <div>
                                          <a onClick={() => {
                                            this.clickOpenUrl(props.original.id, props.original.name);
                                            localStorage.setItem("mapJsonObj", JSON.stringify(props.original));
                                            localStorage.setItem('mapPermission', props.original.mapPermissionId);
                                          }} className="action-button" title={Resources.getValue('showMsgTxt')}>
                                            <i className="icon-show_icon"></i>
                                          </a>
                                          <a onClick={() => {
                                            this.deleteRow(props.original.id)
                                          }} className="action-button" title={Resources.getValue('deleteMsgTxt')} >
                                            <i className="icon-delete-icon"></i>
                                          </a>
                                        </div>
                                      )
                                    },
                                    className: "action-column",
                                    style: {
                                      textAlign: "center"
                                    },
                                    sortable: false,
                                    filterable: false,
                                  }]
                              }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                          />
                        </div>
                      </div>
                      <div className="table-grid layout-option-content" data-layout="grid">
                        {/* All grid */}
                        <div
                          className={`sub-list${JSON.parse(localStorage.getItem('isFavFilter')) ? '' : ' show'}`}
                        >
                          <div className="grid-items">
                            {(data && data.length) ? data.map(item => (
                              <div className="grid-item" key={item.id} title={item.name}>
                              {item.isAiGenerated ? (
                                <img
                                style={{
                                  "width": "23px",
                                  "height": "23px",
                                  "color": "#fd7021",
                                  "marginTop": "20px",
                                  "marginRight": "14px", 
                                  "borderRadius": "50%",
                                  }} 
                                  src={ChatGptMapListIcon}
                                  className="chatgpt-icon"
                                  alt="AI Icon"
                                />
                              ) : ''}
                                <a
                                  onClick={() => this.addRemoveFavorite(item.id, !item.isFavorite)}
                                  className={`fav-action${item.isFavorite ? ' active' : ''}`}
                                  title={item.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
                                >
                                  <i className="icon-favorite-icon"></i>
                                </a>
                                {item.isMapShared ? (
                                  <img
                                    src={iconShared}
                                    className="shared-icon"
                                    alt={Resources.getValue("sharedMapMsgTxt")}
                                  />
                                ) : ''}
                                <a onClick={() => {
                                  this.clickOpenUrl(item.id, item.name);
                                  localStorage.setItem("mapJsonObj", JSON.stringify(item));
                                }}>
                                  <img src={iconGridFile} alt={item.name} />
                                  <div className="name">{item.name}</div>
                                </a>
                              </div>
                            )) : ''}
                          </div>
                        </div>
                        {/* Shared grid */}
                        <div
                          className={`sub-list${JSON.parse(localStorage.getItem('isSharedFilter')) ? ' show' : ''}`}
                        >
                          <div className="grid-items">
                            {(sharedData && sharedData.length) ? sharedData.map(item => (
                              <div className="grid-item" key={item.id} title={item.name}>
                                <a
                                  onClick={() => this.addRemoveFavorite(item.id, !item.isFavorite)}
                                  className={`fav-action${item.isFavorite ? ' active' : ''}`}
                                  title={item.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
                                >
                                  <i className="icon-favorite-icon"></i>
                                </a>
                                {item.isMapShared ? (
                                  <img
                                    src={iconShared}
                                    className="shared-icon"
                                    alt={Resources.getValue("sharedMapMsgTxt")}
                                  />
                                ) : ''}
                                <a onClick={() => {
                                  this.clickOpenUrl(item.id, item.name);
                                  localStorage.setItem("mapJsonObj", JSON.stringify(item));
                                }}>
                                  <img src={iconGridFile} alt={item.name} />
                                  <div className="name">{item.name}</div>
                                </a>
                              </div>
                            )) : ''}
                          </div>
                        </div>
                        {/* Favorited grid */}
                        <div
                          className={`sub-list${JSON.parse(localStorage.getItem('isFavFilter')) ? ' show' : ''}`}
                        >
                          <div className="grid-items">
                            {(favoritedData && favoritedData.length) ? favoritedData.map(item => (
                              <div className="grid-item" key={item.id} title={item.name}>
                                <a
                                  onClick={() => this.addRemoveFavorite(item.id, !item.isFavorite)}
                                  className={`fav-action${item.isFavorite ? ' active' : ''}`}
                                  title={item.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
                                >
                                  <i className="icon-favorite-icon"></i>
                                </a>
                                {item.isMapShared ? (
                                  <img
                                    src={iconShared}
                                    className="shared-icon"
                                    alt={Resources.getValue("sharedMapMsgTxt")}
                                  />
                                ) : ''}
                                <a onClick={() => {
                                  this.clickOpenUrl(item.id, item.name);
                                  localStorage.setItem("mapJsonObj", JSON.stringify(item));
                                }}>
                                  <img src={iconGridFile} alt={item.name} />
                                  <div className="name">{item.name}</div>
                                </a>
                              </div>
                            )) : ''}
                          </div>
                        </div>
                          {/* Ai grid */}
                          <div
                          className={`sub-list${JSON.parse(localStorage.getItem('isAiFilter')) ? ' show' : ''}`}
                        >
                          <div className="grid-items">
                            {(aiData && aiData.length) ? aiData.map(item => (
                              <div className="grid-item" key={item.id} title={item.name}>
                              {item.isAiGenerated ? (
                                <img
                                style={{
                                  "width": "23px",
                                  "height": "23px",
                                  "color": "#fd7021",
                                  "marginTop": "20px",
                                  "marginRight": "14px", 
                                  "borderRadius": "50%",
                                  }} 
                                  src={ChatGptMapListIcon}
                                  className="chatgpt-icon"
                                  alt="AI Icon"
                                />
                              ) : ''}
                                <a
                                  onClick={() => this.addRemoveFavorite(item.id, !item.isFavorite)}
                                  className={`fav-action${item.isFavorite ? ' active' : ''}`}
                                  title={item.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
                                >
                                  <i className="icon-favorite-icon"></i>
                                </a>
                                {item.isMapShared ? (
                                  <img
                                    src={iconShared}
                                    className="shared-icon"
                                    alt={Resources.getValue("sharedMapMsgTxt")}
                                  />
                                ) : ''}
                                <a onClick={() => {
                                  this.clickOpenUrl(item.id, item.name);
                                  localStorage.setItem("mapJsonObj", JSON.stringify(item));
                                }}>
                                  <img src={iconGridFile} alt={item.name} />
                                  <div className="name">{item.name}</div>
                                </a>
                              </div>
                            )) : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default MindMap;