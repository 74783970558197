import React, { Component } from "react";
import MyPlannerToolBox from "../components/my-planner-toolbox";
import Header from "../components/header";

class MyPlannerDashBoard extends Component {

	render() {
		return (

			<React.Fragment>
				<Header />
				<MyPlannerToolBox />
			</React.Fragment>
		);
	}
}

export default MyPlannerDashBoard;
