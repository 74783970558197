import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import DataService from "./data-service";
import ErrorModal from "../../components/modals/error-modal";

const LogoutService = {
  Logout: function () {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("accountLogoutApiURL"),
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LogoutService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("userInformation");
            localStorage.removeItem("userCompanyInformation");
            localStorage.removeItem("retrieveUrl");
            localStorage.removeItem("a1Num");
            localStorage.removeItem("ab0c5");
            localStorage.removeItem("hw8w0");
            localStorage.removeItem("x8s88");
            //localStorage.removeItem("77we1");
            localStorage.removeItem("bhr05");
            localStorage.removeItem("zt931");
            localStorage.removeItem("c65s1");
            localStorage.removeItem("userMail");
            localStorage.removeItem("sharedAnonymusData");
            localStorage.removeItem("openedMapName");
            localStorage.removeItem("mapPresentDatasStore");
            localStorage.removeItem("loginType");
            localStorage.removeItem("gt"); // gecici google login kaydi, daha sonra silinecek
            localStorage.removeItem("userRoleIdList");
            localStorage.removeItem("isShowPopupForTrialDay");
            localStorage.removeItem("beforePresent");
            localStorage.removeItem("zoomMap");
            localStorage.removeItem("countryInfo");
            localStorage.removeItem("918171");
            localStorage.removeItem("remainingMapNumbers");
            localStorage.getItem("googleEmail") !== null &&
            localStorage.removeItem("googleEmail");
            window.location.href = Resources.getValue("newSiteUrl");
            window.location.href = "https://foramind.com";
          }
        } else if (xhr.status == 401) {
          if (window.location.href.indexOf("/login") < 0) {
            window.location.href =
              Resources.getValue("appBaseEnvURL") + "/login";
          }
        } else if (xhr.status == 400) {
          window.location.href =
            Resources.getValue("appBaseEnvURL") + "/mind-map-list";
        } else {
          LogoutService.openErrorModal();
        }
      }
    };
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  },
};

export default LogoutService;
