import React, { Component } from "react";
import Utils from "../libraries/utils";
import Resources from "../libraries/resources";
import foramindLogoBig from "../styles/img/foramind_logo.png";
import foramindLogoSmall from "../styles/img/foramind-beta-logo-small.png";
import ProfileService from "../services/api/profile";
import LogoutService from "../services/api/logout";
import AiPackages from "../services/api/ai-packages";
import LanguageSelect from "../components/language-select";
import WarningModal from "../components/modals/warning-modal";
import ChatGptIcon from "../styles/img/gpt-icon.png"
import ProfileIcon from "../styles/img/profile-icon.png"
import VerifiedIcon from "../styles/img/verified-icon.webp"
import PasswordIcon from "../styles/img/password-icon.webp"
import RemainingMaps from "../styles/img/remaining-map-img.png"
import SubsrictionIcon from "../styles/img/subs-package.png";
import LoginService from "../services/api/login";
class Header extends Component {
  constructor() {
    super();
    this.Logout = this.Logout.bind(this);

    this.state = {
      gptMapNumbers: '',
    }
  }
  // change start trial day info popup status
  changePopupStatus() {
    Utils.modalm().close();
    localStorage.setItem("isShowPopupForTrialDay", false);
  }

  infoPopupWhenUserTrialDayStart() {
    var _this = this;
    WarningModal({
      title: Resources.getValue("informationModalTitleMsgTxt"),
      message: Resources.getValue(
        "startTrialDayInfoAndWelcomePopupContentMsgTxt"
      ),
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
        },
      ],
    });
    document
      .querySelector(".exit-button")
      .addEventListener("click", function () {
        _this.changePopupStatus();
      });
    document
      .querySelector(".modalm-overlay")
      .addEventListener("click", function () {
        _this.changePopupStatus();
      });
    document
      .querySelector(".confirm-button")
      .addEventListener("click", function () {
        _this.changePopupStatus();
      });
  }

  listener() {
    // sub menu event
    var menu = document.getElementsByClassName("sub-menu-toggle");
    for (var i = 0; i < menu.length; i++) {
      menu[i].addEventListener("click", function () {
        var submenu = this.querySelector(".sub-menu");
        var icon = this.querySelector(".dropdown-item i.fa.fa-chevron-up");
        submenu.classList.toggle("none");
        icon.classList.toggle("rotate");
      });
    }

    // Right Menu Listener
    var rightMenu = document.querySelector(".right-menu");
    rightMenu.addEventListener("click", function (e) {
      var element = document.querySelector(".right-menu img");
      if (e.target !== element) {
        return;
      }
      var rightMenuToggle = this.querySelector(".right-menu-toggle");
      // rightMenuToggle.classList.toggle("none");
    });

    var headerMenu = document.querySelector(".navbar-toggleable-md.right-menu");
    var rightDropDownMenu = document.querySelector(
      ".dropdown-menu.right-menu-toggle"
    );

    window.addEventListener("click", function (e) {
      var target = e.target || e.srcElement || e.currentTarget;
      var dMenuWrapper = document.querySelector(
        ".right-menu .dropdown-menu-wrapper"
      );
      var dSubMenuWrapper = document.querySelector(
        ".right-menu .dropdown-menu-wrapper .menu"
      );
      if (headerMenu.contains(target)) {
        // Clicked in box
        dMenuWrapper.classList.toggle("none");
        dSubMenuWrapper.classList.toggle("none");
      } else {
        // Clicked outside the box
        dMenuWrapper.classList.add("none");
        dSubMenuWrapper.classList.add("none");
      }
      if (rightDropDownMenu.contains(target)) {
        dMenuWrapper.classList.remove("none");
        dSubMenuWrapper.classList.remove("none");
      }
    });

    //Right Menu Hover (eski hover)
    // if (!/Mobi/.test(navigator.userAgent)) {
    //   rightMenu.addEventListener('mouseover', function () {
    //     document.querySelector('.right-menu .dropdown-menu-wrapper').style.setProperty("display", "block");
    //     document.querySelector('.right-menu .dropdown-menu').style.setProperty("display", "block");
    //   });

    //   rightMenu.addEventListener('mouseleave', function () {
    //     document.querySelector('.right-menu .dropdown-menu-wrapper').style.setProperty("display", "none");
    //     document.querySelector('.right-menu .dropdown-menu').style.setProperty("display", "none");
    //   });
    // }
  }

  getProfile() {
    var callback = this.fillProfile;
    ProfileService.getProfile(callback, this);
  }

  fillProfile() {
    var userProfil = JSON.parse(this.response);
    var isFreeUser = userProfil?.isFreeUser;
    localStorage.setItem("iFU0", (isFreeUser))

    if (userProfil.user.avatarImagePath === null) {
      document.querySelector("#headerImage").style.display = "none";
    } else {
      if (userProfil.user.hasProfileImage) {
        document.querySelector("#headerImage").src =
          userProfil.user.avatarImagePath;
        document.querySelector("#nameLatter").style.display = "none";
      } else {
        document.querySelector("#nameLatter").innerHTML =
          userProfil.user.avatarImagePath;
        document.querySelector("#headerImage").style.display = "none";
      }
    }
  }

  componentDidMount() {

    const userId = JSON.parse(localStorage.getItem("userInformation")).id
    this.listener();
    this.getProfile();

    AiPackages.getMapNumbers(userId)
      .then(responseData => {
        this.setState(
          { gptMapNumbers: JSON.stringify(responseData) }
        )
      })

    // deneme surumu varsa; basladigini kullaniciya bildirmek icin gosterilen trial day popup (eski kullanicilar icin)
    if (
      JSON.parse(localStorage.getItem("ab0c5")) > 0 &&
      JSON.parse(localStorage.getItem("isShowPopupForTrialDay")) === true
    ) {
      this.infoPopupWhenUserTrialDayStart();
    }

    // deneme surumu yoksa; trial day popup gosterilmiyor
    if (
      JSON.parse(localStorage.getItem("ab0c5")) === 0 &&
      JSON.parse(localStorage.getItem("isShowPopupForTrialDay")) === true
    ) {
      localStorage.setItem("isShowPopupForTrialDay", false);
    }

    if (localStorage.getItem("loginType") != 1) {
      document.querySelector(".change-password").classList.add("none");
    }

    document
      .querySelector("#leftMenu")
      .addEventListener("mouseover", function () {
        document.querySelector("#leftMenu").classList.add("wide");
      });
    document
      .querySelector("#leftMenu")
      .addEventListener("mouseout", function () {
        document.querySelector("#leftMenu").classList.remove("wide");
      });
  }

  Logout() {
    LogoutService.Logout();
  }

  renderRemainingMaps() {

    if ( JSON.parse(localStorage.getItem("iFU0"))  == true) {
      return (
        <>
          <div className="remaining-maps"> {Resources.getValue("remainingMapsTxt")}  {this.state.gptMapNumbers}</div>
          <div className="circle">
            <img style={{ width: "40px", height: "40px", marginTop: "-1px" }} src={RemainingMaps} />
          </div>
        </>
      );
    } else if(JSON.parse(localStorage.getItem("iFU0"))  == false) {
        if((JSON.parse(localStorage.getItem("userCompanyInformation")).id === 12)){
            if(JSON.parse(localStorage.getItem('hw8w0')) != 0){
                 return (
          <>
            <div className="remaining-maps"> {Resources.getValue("remainingMapsTxt")}  {this.state.gptMapNumbers}</div>
            <div className="circle">
              <img style={{ width: "40px", height: "40px", marginTop: "-1px" }} src={RemainingMaps} />
            </div>
          </>
        );
            }
            else return null;
        }
        else {
      if (
       JSON.parse(localStorage.getItem('c65s1')) == null ||
        (JSON.parse(localStorage.getItem('c65s1')) != null && JSON.parse(localStorage.getItem('c65s1')).companyRemainingProductDays === 0)
      ) {
        return null; 
      } else {
        return (
          <>
            <div className="remaining-maps"> {Resources.getValue("remainingMapsTxt")}  {this.state.gptMapNumbers}</div>
            <div className="circle">
              <img style={{ width: "40px", height: "40px", marginTop: "-1px" }} src={RemainingMaps} />
            </div>
          </>
        );
      }
    }    
  }
    } 
  

  render() {
    var urlSplit = window.location.pathname.split("/");
    var currentUrl = urlSplit.slice(-1).pop();

    const userCompany = JSON.parse(
      localStorage.getItem("userCompanyInformation")
    );
    let userCompanyName, userCompanyLogo;
    if (userCompany) {
      userCompanyName = userCompany.companyName;
      userCompanyLogo = userCompany.logo;
    }

    return (
      <header className="header">
        <div className="pull-right">
          {/* kurumsal aboneligi yoksa, bitmis ise, bireysel aboneligi de yoksa (tanisma paketinde ise), deneme suresi devam ediyorsa */}
          {
            (
              JSON.parse(localStorage.getItem("userCompanyInformation")).id === 12
            ) 
              && (
                JSON.parse(localStorage.getItem('c65s1')) === null ||
                (JSON.parse(localStorage.getItem('c65s1')) !== null && JSON.parse(localStorage.getItem('c65s1')).companyRemainingProductDays === 0)
            ) && (
              (
                JSON.parse(localStorage.getItem('hw8w0')) === 0 &&
                JSON.parse(localStorage.getItem('ab0c5')) > 0
              ) && (
                <div className="subscription-link pull-left">
                  <a href={Resources.getValue('appBaseEnvURL') + '/payment'}>
                    {Resources.getValue("subscribeMsgTxt")}
                  </a>
                </div>
              )
            )
          }

          <div className="pull-left remaining-container">
            {this.renderRemainingMaps()}
          </div>

          <div className="language pull-left">
            <LanguageSelect />
          </div>
          <div className="navbar navbar-toggleable-md navbar-light right-menu">
            <a className="" role="button">
              <img id="headerImage" src="" />
              <div id="nameLatter" className="user-name-letters"></div>
            </a>
            <div className="dropdown-menu-wrapper none">
              <div
                className="dropdown-menu menu right-menu-toggle none"
                aria-labelledby="navbarDropdown"
              >
                <div className="arrow"></div>
                <ul className="">
                  <li className="sub-menu-toggle">
                    <a className="dropdown-item up-menu">
                      <i className="icon-settings " aria-hidden="true"></i>
                      {Resources.getValue("accountsettingsMsgTxt")}
                      <i
                        className="animate-icon fa fa-chevron-up arrow-up arrow-size"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <ul className="sub-menu none">
                      <li>
                        <a
                          className="dropdown-item"
                          href={
                            Resources.getValue("appBaseEnvURL") + "/profile"
                          }
                        >
                        <i className="header-icons"><img src={ProfileIcon}></img></i>
                          {Resources.getValue("profileMsgTxt")}
                        </a>
                      </li>
                      { JSON.parse(localStorage.getItem("iFU0")) == false &&  JSON.parse(localStorage.getItem("userCompanyInformation")).id == 12 && localStorage.getItem('hw8w0') != 0 ? (<li>
                        <a
                          className="dropdown-item"
                          href={
                            Resources.getValue("appBaseEnvURL") +
                            "/ai-payment"
                          }
                        >
                          <i className="header-icons"><img src={ChatGptIcon}></img></i>
                          {Resources.getValue(
                            "chatGptPayment"
                          )}
                        </a>
                      </li>) 
                          : console.log()}
                      {JSON.parse(localStorage.getItem("c65s1")) !== null &&
                        JSON.parse(localStorage.getItem("c65s1"))
                          .companyRemainingProductDays > 0 ? null : (
                        <>
                          {/* kurumsal abonelik varsa ve devam ediyorsa odeme ve abonelik bilgilerim menulerini goremeyecek */}
                          <li>
                            <a
                              className="dropdown-item"
                              href={
                                Resources.getValue("appBaseEnvURL") + "/payment"
                              }
                            >
                              <i className="header-icons"><img src={SubsrictionIcon}></img> </i>
                              {Resources.getValue("paymentPageMsgTxt")}
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href={
                                Resources.getValue("appBaseEnvURL") +
                                "/subscription-detail"
                              }
                            >
                            <i className="header-icons"><img src={VerifiedIcon}></img> </i>
                              {Resources.getValue(
                                "subscriptionMenuTitleMsgTxt"
                              )}
                            </a>
                          </li>
                        </>
                      )}
                      <li>
                        <a
                          className="dropdown-item change-password"
                          href={
                            Resources.getValue("appBaseEnvURL") +
                            "/change-password"
                          }
                        >
                        <i className="header-icons"><img src={PasswordIcon}></img> </i>
                          {Resources.getValue("passwordMsgTxt")}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      className="dropdown-item up-menu"
                      href={Resources.getValue("appBaseEnvURL") + "/contact"}
                    >
                      <i className="icon-mail" aria-hidden="true"></i>
                      {Resources.getValue("contactMsgTxt")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item up-menu" onClick={this.Logout}>
                      <i className="icon-logout" aria-hidden="true"></i>
                      {Resources.getValue("logoutMsgTxt")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="left-menu" id="leftMenu">
          <a href={Resources.getValue("newSiteUrl")}>
            <img
              src={userCompanyLogo ? userCompanyLogo : foramindLogoBig}
              height={45}
              className="left-logo big"
              alt={`${userCompanyName ? userCompanyName : "Foramind"}`}
            />
            <img
              src={foramindLogoSmall}
              height={45}
              className="left-logo small"
              alt="Foramind"
            />
          </a>
          <div className="sub-left-menu">
            <div className="" aria-labelledby="navbarDropdown">
              <ul className="">
                {/* <li><a className="dropdown-item" href={Resources.getValue('appBaseEnvURL') + '/dashboard'}><i className="fa fa-home font-aware" aria-hidden="true"></i>{Resources.getValue('homepageMsgTxt')}</a></li> */}
                {/* <li><a className="dropdown-item" href={Resources.getValue('appBaseEnvURL') + '/task-board-list'}><i className="fa fa-server" aria-hidden="true"></i>{Resources.getValue('taskBoardMsgTxt')}</a></li>   */}
                {/* <li><a className="dropdown-item" href={Resources.getValue('appBaseEnvURL') + '/my-planner-list'}><i className="fa fa-calendar" aria-hidden="true"></i>{Resources.getValue('myPlannerMsgTxt')}</a></li>   */}
                {/* <li><a className="dropdown-item" href={Resources.getValue('appBaseEnvURL') + '/mind-map-list'}><i className="fa fa-sitemap" aria-hidden="true"></i>{Resources.getValue('mindMapsMsgTxt')}</a></li> */}
                {/* <li><a className="dropdown-item" href={Resources.getValue('appBaseEnvURL') + '/mind-map-share-list'}><i className="fa fa-share-alt" aria-hidden="true"></i>{Resources.getValue('mindMapsShareMsgTxt')}</a></li> */}

                <li
                  className={`sub-bar${currentUrl === "template-list" ? " current-url" : ""
                    }`}
                >
                  <a
                    href={
                      Resources.getValue("appBaseEnvURL") + "/template-list"
                    }
                  >
                    <i className="icon-create_new_map_icon"></i>
                  </a>
                  <a
                    className="title"
                    href={
                      Resources.getValue("appBaseEnvURL") + "/template-list"
                    }
                  >
                    {Resources.getValue("createNewMapMsgTxt")}
                  </a>
                </li>
                <li
                  className={`sub-bar${currentUrl === "mind-map-list" ? " current-url" : ""
                    }`}
                >
                  <a
                    href={
                      Resources.getValue("appBaseEnvURL") + "/mind-map-list"
                    }
                  >
                    <i className="icon-map_list_icon"></i>
                  </a>
                  <a
                    className="title"
                    href={
                      Resources.getValue("appBaseEnvURL") + "/mind-map-list"
                    }
                  >
                    {Resources.getValue("mindMapsMsgTxt")}
                  </a>
                </li>
                <li
                  className={`sub-bar${currentUrl === "mind-map-share-list" ? " current-url" : ""
                    }`}
                >
                  <a
                    href={
                      Resources.getValue("appBaseEnvURL") + "/mind-map-list"
                    }
                  >
                    <i className="icon-share_list_icon"></i>
                  </a>
                  <a
                    className="title"
                    href={
                      Resources.getValue("appBaseEnvURL") +
                      "/mind-map-share-list"
                    }
                  >
                    {Resources.getValue("mindMapsShareMsgTxt")}
                  </a>
                </li>
                <li
                  className={`sub-bar${currentUrl === "help" ? " current-url" : ""
                    }`}
                >
                  <a href={Resources.getValue("appBaseEnvURL") + "/help"}>
                    <i className="icon-help-outline"></i>
                  </a>
                  <a
                    className="title"
                    href={Resources.getValue("appBaseEnvURL") + "/help"}
                  >
                    {Resources.getValue("helpMenuMsgTxt")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
