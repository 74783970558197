import React, { Component } from "react";
import Select from "react-select";
import Resources from "../libraries/resources";

class LanguageSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            languageSelectVal: { label: Resources.siteLanguage.toUpperCase(), value: Resources.siteLanguage }
        };
    }

    checkSelectedLanguage() {
        var lang = Resources.siteLanguage;
        if (lang) {
            this.setState({ languageSelectVal: { label: lang.toUpperCase(), value: lang } });
        }
    };

    componentDidMount() {
        this.checkSelectedLanguage();
        this.selectsBlurFix();

        // prevent language select input editing
		document.querySelector('#react-select-2-input')?.setAttribute('readonly', 'readonly');
    };

    selectsBlurFix() {
        var portElement = document.querySelector('ul#port');
        if (portElement) {
            var _this = this;
            portElement.addEventListener('click', function () {
                _this.languageSelect.blur();
            });
        };
    };

    languageSelectOnChange(selectedItem) {
        localStorage.setItem('siteLanguage', selectedItem.value);
        window.location.reload();
    };

    render() {

        var languageOptions = [
            { label: 'TR', value: 'tr' },
            { label: 'EN', value: 'en' }
        ];



        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                // borderBottom: '1px dotted pink',
                color: 'black',
                padding: 0,

            }),

            menu: (provided, state) => ({
                ...provided,
                // borderBottom: '1px dotted pink',
                color: 'white',
                padding: 2,
                width: 60,

            }),

            control: styles => ({ ...styles, backgroundColor: 'white', width: 60, height: 25, fontSize: 10, marginLeft: -1 }),

            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';

                return { ...provided, opacity, transition };
            }
        }

        return (
            <Select
                id="shape"
                ref={input => this.languageSelect = input}
                styles={customStyles}
                className="fa-select language-select select"
                options={languageOptions}
                onChange={selectedItem => this.languageSelectOnChange(selectedItem)}
                // placeholder={Resources.getValue('selectDefaultValueMsgTxt')}
                value={this.state.languageSelectVal}
            />
        );
    }
}

export default LanguageSelect;
