import React, { Component } from "react";
import Utils from "../libraries/utils";
import ForgotPasswordService from "../services/api/forgot-password";
import foramindLogo from "../styles/img/foramind_logo.png";
import Resources from "../libraries/resources";
import LanguageSelect from "../components/language-select";
import emailIcon from "../styles/img/email-icon.png";

class ForgotPassword extends Component {

  componentDidMount() {
    Utils.formValidation();

    window.forgotPassword = function () {
      var forgotPassword = {
        email: this.document.querySelector("#email").value
      };
      ForgotPasswordService(JSON.stringify(forgotPassword));
    };
  };

  render() {
    return (
      <div className="">
        <div className="col-10 col-sm-11 col-md-12 col-lg-12 col-xl-12 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-0">
          <div className="login-language pull-right">
            <LanguageSelect />
          </div>
        </div>
        <form className="forgotpassword-form" autoComplete="off">
          <div className="row">
            <div className="form-wrapper">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group text-center form-logo">
                    <a href={Resources.getValue('appBaseEnvURL') + '/login'}>
                      <img
                        src={foramindLogo}
                        width="170"
                        className="logo"
                        alt="Foramind"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group with-icon">
                    <img src={emailIcon} alt={Resources.getValue("emailMsgTxt")} className="left-icon" />
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="validate-required validate-email"
                      placeholder={Resources.getValue("emailMsgTxt")}
                      maxLength="50"
                      title={Resources.getValue("emailMsgTxt")}
                    />
                  </div>
                </div>
              </div>
              <button
                className="yellow-button button submit-form-button login-custom-btn"
                type="button"
                title={Resources.getValue("sendMsgTxt")}
                data-submit-method="forgotPassword"
              >
                {Resources.getValue("sendMsgTxt")}
              </button>

              <a
                className="link-button button create-account-btn mt-3 click-link"
                href={Resources.getValue('appBaseEnvURL') + '/login'}
                title={Resources.getValue("goTologinMsgTxt")}
              >
                {Resources.getValue("goTologinMsgTxt")}
              </a>
            </div>
          </div>
        </form>
      </div>
    );
  };
}

export default ForgotPassword;
