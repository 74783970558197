import React, { Component } from "react";

class SubscriptionInformationModal extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.openModal();
	}

	openModal() {
		var _this = this;
		document.querySelector(".close-modal").addEventListener("click", function () {
			_this.props.sharedClick(false);
			localStorage.setItem('isCustomModalOpen', false);
		})
	}

	render() {
		return (
			<div className="overlay subscription-information-modal">
				<div className="popup">
					<div className="title">
						{this.props.popupContent.title}
					</div>
					<a className="close close-modal" onClick={this.props.handler}>&times;</a>
					<div className="select-shared">
						<div className="modal-content-wrapper">
							<div dangerouslySetInnerHTML={{ __html: this.props.popupContent.content }}></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SubscriptionInformationModal;
