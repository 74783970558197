import React from "react";

const Star = ({ selected = false, onClick = f => f }) =>  {
  
  return (
    <div className={`custom-star fa ${selected ? 'fa-star selected' : 'fa-star-o'}`} onClick={onClick} />
  );
}

export default Star;
