import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";
import SuccessModal from "../../components/modals/success-modal";

var TemplateListService = {
  getTemplateList: function (recordSize, callback, languageId, filterData, _this) {
    const parameterizeArray = (key, arr) => {
      arr = arr.map(encodeURIComponent)
      return '&'+key+'=' + arr.join('&'+key+'=')
    }
    var url="";
    if(filterData.length > 0){
      url =Resources.getValue("templateListApiURL") +
      "?recordSize=" +
      recordSize +
      "&languageId=" +
      languageId +
      "&companyId=" +
      JSON.parse(localStorage.getItem("userInformation")).companyId +
      parameterizeArray('name', filterData)
    }
    else{
      url =Resources.getValue("templateListApiURL") +
      "?recordSize=" +
      recordSize +
      "&languageId=" +
      languageId +
      "&companyId=" +
      JSON.parse(localStorage.getItem("userInformation")).companyId
    }
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: url,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      TemplateListService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          TemplateListService.openWarningModal();
        } else {
          TemplateListService.openErrorModal();
        }
      }
    };
  },

  getSubTemplateList: function (recordSize, callback, languageId, filterData, _this) {
    const parameterizeArray = (key, arr) => {
      arr = arr.map(encodeURIComponent)
      return '&'+key+'=' + arr.join('&'+key+'=')
    }
    var url="";
    if(filterData.length > 0){
      url = Resources.getValue("subTemplateListApiURL") +
      "?templateId=" +
      JSON.parse(localStorage.getItem("selectedTemplateId")) +
      "&recordSize=" +
      recordSize +
      "&languageId=" +
      languageId +
      parameterizeArray('name', filterData)
    }
    else{
      url = Resources.getValue("subTemplateListApiURL") +
      "?templateId=" +
      JSON.parse(localStorage.getItem("selectedTemplateId")) +
      "&recordSize=" +
      recordSize +
      "&languageId=" +
      languageId
    }
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: url,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      TemplateListService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          TemplateListService.openWarningModal();
        } else if (xhr.status == 404) {
          TemplateListService.openWarningModal();
        } else {
          TemplateListService.openErrorModal();
        }
      }
    };
  },

  addNewTemplateCategory: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("addNewTemplateCategoryApiURL"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      TemplateListService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.data) {
            TemplateListService.openAddCategorySuccessModal();
          }
        } else if (xhr.status == 400) {
          TemplateListService.openWarningModal();
        } else {
          TemplateListService.openErrorModal();
        }
      }
    };
  },

  addNewTemplate: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("addNewTemplateApiURL"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      TemplateListService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.data) {
            TemplateListService.openAddTemplateSuccessModal();
          }
        } else if (xhr.status == 400) {
          TemplateListService.openWarningModal();
        } else {
          TemplateListService.openErrorModal();
        }
      }
    };
  },

  deleteTemplateCategory: function (templateId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url:
        Resources.getValue("deleteTemplateCategoryApiURL") +
        "?templateId=" +
        templateId,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      TemplateListService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.isSuccess) {
            callback.apply(_this);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          TemplateListService.openWarningModal();
        } else {
          TemplateListService.openErrorModal();
        }
      }
    };
  },

  updateTemplate: function (data) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("updateTemplateCategoryApiURL"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      TemplateListService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.isSuccess) {
            TemplateListService.openUpdateTemplateSuccessModal();
          }
        } else if (xhr.status == 400) {
          TemplateListService.openWarningModal();
        } else {
          TemplateListService.openErrorModal();
        }
      }
    };
  },

  updateCategory: function (data) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("updateTemplateCategoryApiURL"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      TemplateListService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.isSuccess) {
            TemplateListService.openUpdateCategorySuccessModal();
          }
        } else if (xhr.status == 400) {
          TemplateListService.openWarningModal();
        } else {
          TemplateListService.openErrorModal();
        }
      }
    };
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("loginErrormodalMsgTxt") + "</p>",
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openAddTemplateSuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + Resources.getValue("addTemplateSuccessMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/sub-template-list",
        },
      ],
    });
  },

  openAddCategorySuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + Resources.getValue("addCategorySuccessMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/template-list",
        },
      ],
    });
  },

  openUpdateCategorySuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message:
        "<p>" + Resources.getValue("updateCategorySuccessMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/template-list",
        },
      ],
    });
  },

  openUpdateTemplateSuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message:
        "<p>" + Resources.getValue("updateTemplateSuccessMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/sub-template-list",
        },
      ],
    });
  },
};

export default TemplateListService;
