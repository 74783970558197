import React, { Component } from "react";
import Hammer from "react-hammerjs";
import MM from "../libraries/map";

class Tip extends Component {

  constructor() {
    super();
  }

  componentDidUpdate() {
    this.writeZoomRatio();
  }

  writeZoomRatio() {
    const mapScale = JSON.parse(localStorage.getItem("zoomMap")).toFixed(2);
    const posObj = {
      scale192: { val: 1.92, text: '100%' },
      scale184: { val: 1.84, text: '96%' },
      scale176: { val: 1.76, text: '92%' },
      scale168: { val: 1.68, text: '88%' },
      scale160: { val: 1.60, text: '84%' },
      scale152: { val: 1.52, text: '80%' },
      scale144: { val: 1.44, text: '76%' },
      scale136: { val: 1.36, text: '72%' },
      scale128: { val: 1.28, text: '68%' },
      scale120: { val: 1.20, text: '64%' },
      scale112: { val: 1.12, text: '60%' },
      scale104: { val: 1.04, text: '56%' },
      scale096: { val: 0.96, text: '52%' },
      scale088: { val: 0.88, text: '48%' },
      scale080: { val: 0.80, text: '44%' },
      scale072: { val: 0.72, text: '40%' },
      scale064: { val: 0.64, text: '36%' },
      scale056: { val: 0.56, text: '32%' },
      scale048: { val: 0.48, text: '28%' },
      scale040: { val: 0.40, text: '24%' },
      scale032: { val: 0.32, text: '20%' },
      scale024: { val: 0.24, text: '16%' },
      scale016: { val: 0.16, text: '12%' },
      scale008: { val: 0.08, text: '8%' },
    };
    let toFixedScaleText = "scale" + mapScale.split('.')[0] + mapScale.split('.')[1];
    return posObj[toFixedScaleText] && posObj[toFixedScaleText].text;
  }
  
  portOnPinch(e) {
    if (e.pointers[0].type == 'pointermove' && e.pointers[1].type == 'pointermove') {
      var dist;
      if (e.deltaY) {
        if (e.deltaY > 0) {
          dist = -1;
        } else if (e.deltaY < 0) {
          dist = 1;
        }
      }
      if (dist) {
        MM.App.adjustFontSize(dist);
      }
    }
  };

  zoomInOutButton(e){
    MM.App.adjustFontSize(e);
    var ratio = document.querySelector("#port").style.fontSize;
    this.setState({
      zoomRatio : ratio
    })
  }

  mapDeselectEvent() {
    MM.Item.prototype.deselect(MM.App.current);
    MM.App.hideNewToolbox();
  }

  render() {
    return (
      <Hammer onPinch={this.portOnPinch} options={{
        recognizers: {
          pinch: { enable: true }
        }
      }}>
        <div className="tip">
          <div className="a4-grid-lines horizontal"></div>
          <ul id="port" onClick={() => {this.mapDeselectEvent()}}>
            <div id="tip" className="none">
              Press ‘Tab’ to Insert Child, ‘Enter’ to Insert Sibling Node. For
              more tips/news, follow.
            </div>
          </ul>
          <button id="zoom-in" className="zoom-in"><i className="icon-add-icon plus-minus" aria-hidden="true"></i></button>
          <button id="zoom-out" className="zoom-out"><i className="icon-minus-icon plus-minus" aria-hidden="true"></i></button>
          <span className="zoom-ratio">{this.writeZoomRatio()}</span>
        </div>
      </Hammer >
    );
  }
}

export default Tip;
