import Resources from "../libraries/resources";

function ExplicitConsentText() {
  return (
    "<p>Zihinler Fora Eğitim Yazilim ve Danişmanlik Sanayi ve Ticaret Anonim Şirketi, 6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamında “Veri Sorumlusu” sıfatına haiz olup; işbu açık rıza metni 6698 Sayılı Kanun, ilgili kanun uyarınca yayınlanan yönetmelikler ve tebliğlerlerde yer alan hükümlere uygun olarak, Zihinler Fora Eğitim Yazilim ve Danişmanlik Sanayi ve Ticaret Anonim Şirketi tarafından kişisel verilerinizden <b>“Profil Fotoğrafınızın”</b> işlenmesi, yurtiçi ve yurtdışına aktarılması hususunda bilgilendirmeye dayalı olarak özgür iradenizle rızanızı temin etmek amacıyla hazırlanmıştır.</p>" +
    "<p><b>I. Kişisel Veri İşleme Faaliyeti ve Toplanma Yöntemi</b></p>" +
    "<p>Kişisel veriniz; üyelik oluşturulması ana faaliyeti ve üye tercihi ile profilin özgülenmesi alt faaliyeti kapsamında; hesap ayarları ile profil fotoğrafı eklemeniz aracılığıyla otomatik olmayan yollarla toplanmaktadır. Eklemiş olduğunuz profil fotoğrafının kişisel veri niteliğinde olmaması bir başka anlatımla fotoğrafın gerçek bir kişiyi gösterir nitelikte olmaması halinde Kişisel Verilerin Korunması Kanunu kapsamında sayılamayacağından iş bu açık rıza metni yürürlük bulmayacaktır.</p>" +
    "<p><b>II. Kişisel Verinin İşlenme Amacı, Hukuki Sebebi ve Saklama Süresi </b></p>" +
    "<p>Profil fotoğrafınız, Zihinler Fora Eğitim Yazilim ve Danişmanlik Sanayi ve Ticaret Anonim Şirketi; Foremind üye hesabının özgülenmesi amacıyla sınırlı olarak işlenebilecektir. Bu amaç bakımından kişisel verinizin işlenmesine açık rıza vermek istemiyorsanız iş bu açık rıza metninin sonunda ayrıca belirtebilirsiniz.</p>" +
    "<p>Profil fotoğrafınızın, Zihinler Fora Eğitim Yazilim ve Danişmanlik Sanayi ve Ticaret Anonim Şirketi tarafından işlenmesinin hukuki sebebi; işbu açık rıza metninin kabulü halinde açık rızanızın varlığıdır. Açık rızanızın varlığı halinde profil fotoğrafınız üyeliğinizin devam ettiği süre boyunca saklanabilecektir.</p>" +
    "<p><b>III. Kişisel Verinin Yurtiçinde Aktarımı, Aktarım Amaçları ve Hukuki Sebebi</b></p>" +
    "<p>Profil fotoğrafınız, Zihinler Fora Eğitim Yazilim ve Danişmanlik Sanayi ve Ticaret Anonim Şirketi tarafından toplanması, muhafaza edilmesi ve güvenli olarak saklanması amacıyla Kanun’un 9. Maddesinde işleme şartları ve ilgili kanunda öngörülen temel ilkelere uygun olarak “açık rızanız” dâhilinde sunucu hizmeti aldığımız Firmaya aktarılmaktadır.</p>" +
    "<p><b>IV.Kişisel Verilerin Yurtdışına Aktarımı, Aktarım Amacı ve Hukuki Sebebi</b></p>" +
    "<p>Kanun’un 9. maddesine göre, yurt dışına veri aktarımı;</p>" + 
    "<p><ul><li>•	İlgili kişinin açık rızasının bulunması,</li><li>•	Yeterli korumanın bulunduğu ülkelere (Kurul tarafından güvenli kabul edilen ülkeler) kişisel veri aktarımında, Kanun’da belirtilen hallerin varlığı, (Kanun’un 5. maddesinin 2. fıkrası ile 6. maddesinin 3. fıkrasında belirtilen şartlar),</li><li>•	Yeterli korumanın bulunmadığı ülkelere kişisel veri aktarımında, Kanun’da belirtilen hallerin varlığı durumunda (Kanun’un 5. maddesinin 2. fıkrası ile 6. maddesinin 3. fıkrasında belirtilen şartlar) yeterli korumanın yazılı olarak taahhüt edilmesi ve Kurul’un izninin bulunması, durumlarında gerçekleştirilebilir.</li></ul></p>" +
    "<p>Açıklanan kişisel veriniz (Profil Fotoğrafı) fotoğraf entegrasyonu sunan sisteme ait sunucuların yurt dışında bulunması dolayısıyla profil fotoğrafı eklenebilmesini temin amacıyla Kanun’un 9. Maddesinde işleme şartları ve ilgili kanunda öngörülen temel ilkelere uygun olarak “açık rızanız” dâhilinde sunucu hizmeti aldığımız Firmaya aktarılmaktadır.</b>" +
    "<p><b>V. Veri Sorumlusu Tarafından Alınan Tedbirler</b></p>" +
    "<p><b>VI. Rıza Beyanının Hukuki Mahiyeti, Sonuçları ve Rızanın Geri Alınması</b></p>" +
    "<p><b><u>Kabul beyanını işaretlediğiniz takdirde, veri sorumlusu tarafından “Profil Fotoğrafınızın” işlenmesine, yurtiçinde aktarılmasına ve yurtdışında aktarılmasına ayrı ayrı muvafakat göstermiş olacaksınız ve bu kapsamda “Açık Rıza”nız var kabul edilerek 6698 Sayılı KVK Kanunu’na uygun olarak işlenebilecek, yurtiçine ve yurtdışına aktarılabilecektir. <mark>Kullanılan sunucudan kaynaklı olarak kişisel verinizin işlenmesi, yurtiçi ve yurtdışı aktarımı bir bütün olup herhangi birine onay vermediğiniz takdirde kişisel verinizi işlememiz mümkün değildir. Bu nedenle tarafınıza tek bir onay seçeneği sunulmuştur.</mark></u></b> <i><mark>(Kullanılan sunucu, CDN olup, internet üzerindeki birçok veri merkezinde bulunan dağınık ve geniş bir sunucu sistemidir. CDN’nin amacı, kesintisiz bir şekilde yüksek performans ile son kullanıcılara içerik sunmaktır.)</mark></i> Kabul beyanınız halinde, açık rızanız yalnızca “Profil Fotoğrafınız” için geçerli olup; yukarıda izah edilen işleme amaçları ile sınırlı olarak işlenebilecek ve aktarılabilecektir.</p>" +
    "<p><b><u>KVKK uyarınca, ilgili kişi olarak kişisel verinizin işlenmesine, yurtiçine ve yurtdışına aktarılmasına yönelik olarak vermiş olduğunuz açık rıza beyanınızı her zaman geri almanız mümkündür.</u></b> Bu takdirde, veri sorumlusu açık rızanın geri alınması beyanının kendisine ulaştığı tarihten itibaren veri işleme veya aktarma faaliyetini durdurmakla yükümlüdür. Açık rızanızın geri alınmasına ilişkin beyan ileriye etkili olarak sonuç doğurmaktadır. <mark>Açık Rıza Geri Alım Formu’na ulaşmak için web sitesinde yer alan info@foramind.com adresine e-mail gönderebilirsiniz. Ayrıca müşteri panelinden profil fotoğrafınızı kaldırmanız da mümkündür.</mark></p>" +
    "<p>KVK Kanunu’nun 3. Maddesi uyarınca, açık rızanın bilgilendirmeye dayanması gerekmektedir. Bu nedenle kabul beyanını işaretlemeden önce, işbu metin ve aydınlatma metni dikkatli şekilde okunmalıdır.</p>" +
    "<p><b>Kişisel verimin yukarıda belirtilen toplanma yöntemi ile toplanarak, izah edilen sebep ve amaçlar ile sınırlı şekilde, veri sorumlusu tarafından 6698 Sayılı Kanun’a uygun şekilde işlenmesini, III ve V numaralı bölümde belirtilen yere aktarılmasını özgür irademle;</b></b>" +
    "<p class='agree-with-checkbox'><b>Kabul Ediyorum</b> <input type='checkbox' name='checkbox1' disabled /></p>" +
    "<p class='agree-with-checkbox'><b>Kabul Etmiyorum</b> <input type='checkbox' name='checkbox2' disabled /></p>"
  );
}

export default ExplicitConsentText;
