import React, { Component } from "react";
import PaymentService from "../services/api/payment";
import Utils from "../libraries/utils";
import LoginService from "../services/api/login";
import loginRegisterLogo from "../styles/img/foramind_logo.png";
import keyIcon from "../styles/img/key-icon.png";
import personIcon from "../styles/img/person-icon.png";
import visibleIcon from "../styles/img/visible-icon.png";
import visibleOffIcon from "../styles/img/visible-off-icon.png";
import Resources from "../libraries/resources";
import AccountActivationService from "../services/api/account-activation";
import LanguageSelect from "../components/language-select";
import GoogleLogin from "react-google-login";
import AzureLogin from "./azure-login";


// import FacebookLogin from 'react-facebook-login';
import GetUserInfoAfterGoogleLoginModal from "../helpers/get-user-info-after-google-login-modal";
import Button from "bootstrap/js/src/button";

class Login extends Component {
  
  constructor(props) {
    super(props);
    this.redirectLogin();

    this.state = {
      registerConfirmationToken: Utils.getParameterByName(
        "registerConfirmationToken"
      ),
      userMail: Utils.getParameterByName("userMail"),
      userControl: Utils.getParameterByName("userControl"),
      passwordInputType: "password",
    };
    this.rememberMe = this.rememberMe.bind(this);
    LoginService.resfreshToken();

    localStorage.setItem("retrieveUrl", window.location.pathname);
  }

  componentDidMount() {
    document.title = "Foramind | " + Resources.getValue("loginMsgTxt");
    Utils.formValidation();
    window.loginForamind = function () {
      LoginService.getToken();
    };

    this.registerAccountActivation();
    this.rememberMeControl();

    this.getCountryIP();
  };
  redirectLogin = () => {
    if (Utils.getParameterByName("redirectLogin")) {
      localStorage.setItem("token", Utils.getParameterByName("token"));
      localStorage.setItem(
        "refreshToken",
        Utils.getParameterByName("refreshToken")
      );
      LoginService.getUserInformation();
         
    }
  };

  getCountryIP() {
    var callback = this.fillCountryIp;
    PaymentService.userIpAndCountryDetail(callback, this);
  }

  fillCountryIp() {
    var userCountryInfo = JSON.parse(this.response);
    localStorage.setItem("countryInfo", userCountryInfo.country_code);
  }

  responseGoogle = (response) => {
    localStorage.setItem("gt", JSON.stringify(response)); // gecici google login kaydi, daha sonra silinecek
    if (response.tokenId) {
      var token = response.tokenId;
      // localStorage.setItem("googleEmail", response.profileObj.email)
      LoginService.googleLogin(token);
    }
  };


  registerAccountActivation() {
    var accountactivation = {
      userMail: this.state.userMail,
      registerConfirmationToken: this.state.registerConfirmationToken,
      userControl: this.state.userControl,
    };

    if (accountactivation.userControl === "1") {
      accountactivation.userControl = true;
    } else {
      accountactivation.userControl = false;
    }

    if (this.state.registerConfirmationToken) {
      AccountActivationService(
        JSON.stringify(accountactivation),
        LoginService.accountAutoLogin
      );
    }
  }

  rememberMe() {
    var rememberMe = document.querySelector("#rememberMe input").checked;
    var userMail = document.querySelector("#email").value;
    if (rememberMe) {
      localStorage.setItem("rememberMe", rememberMe);
      localStorage.setItem("userMail", userMail);
    } else {
      localStorage.setItem("rememberMe", "");
      localStorage.setItem("userMail", "");
    }
  }

  rememberMeControl() {
    if (
      localStorage.getItem("rememberMe") &&
      localStorage.getItem("rememberMe") != ""
    ) {
      document.querySelector("#email").value = localStorage.getItem("userMail");
      document.querySelector("#rememberMe input").checked = true;
      localStorage.setItem("userMail", document.querySelector("#email").value);
    } else {
      document.querySelector("#email").value = "";
      document.querySelector("#rememberMe input").checked = false;
      localStorage.setItem("userMail", "");
    }
  }

  changeVisible = () => {
    var passInput = document.querySelector("#password");
    if (passInput.type === "password") {
      passInput.type = "text";
      this.setState({
        passwordInputType: passInput.type,
      });
    } else {
      passInput.type = "password";
      this.setState({
        passwordInputType: passInput.type,
      });
    }
  };

  render() {
    // sayfa yuklendiginde kontrol edilliyor (google ile login'de)
    if (localStorage.getItem("googleEmail") !== null) {
      if (
        localStorage.getItem("userInformation") !== null &&
        JSON.parse(localStorage.getItem("userInformation")).hasSetUserType ===
          false
      ) {
        if (
          JSON.parse(localStorage.getItem("userInformation")).companyId != 12 ||
          JSON.parse(localStorage.getItem("userInformation")).companyId != 3
        ) {
          localStorage.setItem("isCustomModalOpen", false);
          LoginService.baseRedirect("");
        } else {
          localStorage.setItem("isCustomModalOpen", true);
        }
      } else {
        localStorage.setItem("isCustomModalOpen", false);
      }
    } else {
      localStorage.setItem("isCustomModalOpen", false);
    }

    return (
      <div>
        {JSON.parse(localStorage.getItem("isCustomModalOpen")) === true ? (
          <GetUserInfoAfterGoogleLoginModal />
        ) : null}
        <div className="col-10 col-sm-11 col-md-12 col-lg-12 col-xl-12 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-0">
          <div className="login-language pull-right">
            <LanguageSelect />
          </div>
        </div>
        <form className="login-form" autoComplete="off">
          <div className="form-wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group text-center form-logo">
                  <a
                      href={
                        Resources.getValue("appBaseEnvURL") === "/foramind"
                            ? Resources.getValue("appBaseEnvURL")
                            : "/"
                      }
                  >
                    <img
                        src={loginRegisterLogo}
                        width="170"
                        className="logo"
                        alt="Foramind"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group with-icon">
                  <img
                      src={personIcon}
                      alt={Resources.getValue("emailMsgTxt")}
                      className="left-icon"
                  />
                  <input
                      type="text"
                      id="email"
                      name="email"
                      className="validate-required validate-email"
                      placeholder={Resources.getValue("emailMsgTxt")}
                      maxLength="50"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group with-icon">
                  <img
                      src={keyIcon}
                      alt={Resources.getValue("passwordMsgTxt")}
                      className="left-icon"
                  />
                  <input
                      type="password"
                      id="password"
                      name="password"
                      className="validate-required"
                      placeholder={Resources.getValue("passwordMsgTxt")}
                      maxLength="50"
                  />
                  <img
                      src={
                        this.state.passwordInputType == "text"
                            ? visibleIcon
                            : visibleOffIcon
                      }
                      alt={Resources.getValue("visibilityMsgTxt")}
                      className="right-icon"
                      onClick={this.changeVisible}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="form-group remeber-forgot-wrapper">
                  <label
                      id="rememberMe"
                      className="register-checkbox container-checkbox login-label silver-gray-button"
                  >
                    {Resources.getValue("rememberMeMsgTxt")}
                    <input type="checkbox" onChange={this.rememberMe}/>
                    <span className="checkmark"></span>
                  </label>
                  <a
                      className="link-button button forgot-pass-btn mr15"
                      href={
                          Resources.getValue("appBaseEnvURL") + "/forgot-password"
                      }
                      title={Resources.getValue("forgotpasswordMsgTxt")}
                  >
                    {Resources.getValue("forgotpasswordMsgTxt")}
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <button
                      className="yellow-button button submit-form-button login-custom-btn"
                      type="button"
                      title={Resources.getValue("loginMsgTxt")}
                      data-submit-method="loginForamind"
                  >
                    {Resources.getValue("loginMsgTxt")}
                  </button>
                </div>
                {/* 
                <div className="form-group">
                  <a
                    className="link-button button create-account-btn click-link"
                    href={Resources.getValue("appBaseEnvURL") + "/register"}
                    title={Resources.getValue("registerMsgTxt")}
                  >
                    {Resources.getValue("registerMsgTxt")}
                  </a>
                </div>
                */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <GoogleLogin
                      clientId="545262035465-o6771i0sk22ncm48o0pumkbrlc48ml1f.apps.googleusercontent.com"
                      buttonText={Resources.getValue("googleLoginMsgTxt")}
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={"single_host_origin"}
                      className="login-social-media google-btn"
                      icon={true}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <AzureLogin/>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <FacebookLogin
                    clientId="932211558586-iqde3d87nc1s3g01l7ch7g91fiiogg6u.apps.googleusercontent.com"
                    textButton={Resources.getValue("facebookLoginMsgTxt")}
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    cssClass="login-social-media facebook-btn"
                    icon="fa-facebook"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
