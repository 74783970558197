import React, { Component } from "react";
import Utils from "../libraries/utils";
import ProfileService from "../services/api/profile";
import Header from "../components/header";
import Resources from "../libraries/resources";
import IMask from 'imask';
import "../libraries/jscolor";
import Resizer from 'react-image-file-resizer';
import ClarifyingText from "../components/profile-clarifying-text";
import ExplicitConsentText from "../components/profile-explicit-consent-text";

class Profile extends Component {

  constructor() {
    super()
    this.state = {
      file: "",
      phoneNumber: "",
      imagePreviewUrl: "",
      imagePreviewBase64Type: "",
      imageAvatarCode: "",
      avatar: {
        name: "",
        extension: "",
        type: "",
        referenceId: "",
        referenceIdType: 0,
        file: ""
      }
    };

    this.isCheckboxRequired = false;

    localStorage.setItem("retrieveUrl", window.location.pathname);
  };

  componentDidMount() {
    this.phoneNumberMask()

    // var _this = this;
    // window.profile = function () {
    //   var profileData = {
    //     firstName: this.document.querySelector("#firstName").value,
    //     lastName: this.document.querySelector("#lastName").value,
    //     company: this.document.querySelector("#company").value,
    //     jobTitle: this.document.querySelector("#jobTitle").value,
    //     phoneNumber: document.querySelector("#phoneNumber").value,
    //     email: this.document.querySelector("#email").value,
    //     avatarImage: _this.state.avatar,
    //     avatarColorCode: this.document.querySelector("#avatarColorCode").value,
    //   };
    //   console.log(profileData);
    //   ProfileService.update(JSON.stringify(profileData));
    // }

    this.getProfile();
  };

  removeProfileImage = () => {
    var profileData = {
      firstName: document.querySelector("#firstName").value,
      lastName: document.querySelector("#lastName").value,
      company: document.querySelector("#company").value,
      jobTitle: document.querySelector("#jobTitle").value,
      phoneNumber: document.querySelector("#phoneNumber").value,
      email: document.querySelector("#email").value,
      avatarImage: null,
      avatarColorCode: document.querySelector("#avatarColorCode").value,
    };
    if (document.querySelector("#checkbox1-clarify").checked && document.querySelector("#checkbox2-explicit").checked) {
      ProfileService.update(JSON.stringify(profileData));
    } else {
      Utils.modalm().open({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: Resources.getValue("warningMsgTxt"),
        bodyContent: '<p>' + Resources.getValue("profileImageRemoveAgreementReqiredInfoMsgTxt") + '</p>',
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: 'button yellow-button confirm-button',
            href: ''
          }
        ]
      });
      return false;
    }
  }

  updateProfile = () => {
    var _this = this;

    var profileData = {
      firstName: document.querySelector("#firstName").value,
      lastName: document.querySelector("#lastName").value,
      company: document.querySelector("#company").value,
      jobTitle: document.querySelector("#jobTitle").value,
      phoneNumber: document.querySelector("#phoneNumber").value,
      email: document.querySelector("#email").value,
      // avatarImage: _this.state.avatar,
      avatarColorCode: document.querySelector("#avatarColorCode").value,
    };

    if (profileData.phoneNumber.length > 0 && profileData.phoneNumber.length < 18) {
      alert(Resources.getValue('wrongPhoneNumberMsgTxt'));
      return false;
    }

    if (
      (_this.state.getUserInfos.company === profileData.company) &&
      (_this.state.getUserInfos.jobTitle === profileData.jobTitle) &&
      (_this.state.avatar.file === '')
    ) {
      // bu uc alani da guncellemediysek
      ProfileService.update(JSON.stringify(profileData));
    } else {
      // bu uc alani ya da herhangi birini guncellediysek
      this.isCheckboxRequired = true;
      document.querySelector('.required-err').classList.add('red');
      document.querySelector('.required-err').classList.remove('none');
      if (document.querySelector("#checkbox1-clarify").checked && document.querySelector("#checkbox2-explicit").checked) {
        document.querySelector('.required-err').classList.remove('red');
        document.querySelector('.required-err').classList.add('none');
        if (_this.state.avatar.file !== '') {
          // image eklendiyse
          const avatarImage = _this.state.avatar;
          const profileNewData = {
            ...profileData,
            avatarImage
          }
          ProfileService.update(JSON.stringify(profileNewData));
        } else {
          // image eklenmediyse
          ProfileService.update(JSON.stringify(profileData));
        }
      } else {
        return false;
      }
    }
    this.isCheckboxRequired = false;
    // ProfileService.update(JSON.stringify(profileData));
  }

  checkboxeCheckControl = (e) => {
    if (e.target.checked) {
      e.target.parentNode.querySelector(".required-err").classList.remove('red');
      e.target.parentNode.querySelector(".required-err").classList.add('none');
    }
  }

  phoneNumberMask() {
    IMask(document.getElementById('phoneNumber'), {
      mask: '{+9\\0}(500)-000-00-00'
    });
  };

  handleChangeAvatarImage = (e) => {
    var _fileObj = e.target.files[0];
    var fileSizeLimit = 2048; // 2048 kb max image fize size - (2 MB) 

    if (e.target.files.length > 0) {
      if (_fileObj.size < (fileSizeLimit * 1024)) {
        Resizer.imageFileResizer(
          _fileObj, // file obj
          5000, // New image max width (ratio is preserved)
          5000, // New image max height (ratio is preserved)
          'jpeg', // Can be either JPEG, PNG or WEBP.
          50, // A number between 0 and 100. Used for the JPEG compression.(if no compress is needed, just set it to 100)
          0, // Rotation to apply to the image. Rotation is limited to multiples of 90 degrees.(if no rotation is needed, just set it to 0) (0, 90, 180, 270, 360)
          blob => { // blob or base64 url
            var optimizedFileObj = new File([blob], (new Date().getTime().toString()) + (Math.floor(Math.random() * 10000000000000000) + 1).toString(), { type: blob.type });
            // file read & tobase64 & setState
            var reader = new FileReader();
            reader.readAsDataURL(optimizedFileObj);
            reader.onload = () => {
              var image = reader.result.split(';base64,')[1];
              this.setState({
                avatar: {
                  name: optimizedFileObj.name,
                  extension: 'jpg',
                  type: optimizedFileObj.type,
                  referenceId: "",
                  referenceIdType: 0,
                  file: image
                },
                file: _fileObj,
                imagePreviewUrl: image,
                imagePreviewBase64Type: reader.result.substr(0, reader.result.lastIndexOf("base64,") + 7),
              });
            }
          },
          'blob' // blob or base64
        );
      }
      else {
        Utils.modalm().open({
          exitButtonText: Resources.getValue("exitMsgTxt"),
          title: Resources.getValue("errorMsgTxt"),
          bodyContent: '<p>' + Resources.getValue("imageFileSizeMsgTxt").replace('*_*_*', fileSizeLimit) + '</p>',
          buttons: [
            {
              text: Resources.getValue("okMsgTxt"),
              class: 'button yellow-button confirm-button',
              href: ''
            }
          ]
        });
      }
    }
  }

  handleClickAvatarImage = (e) => {
    document.getElementById('avatarImage').click();
  }

  handleClickAvatarCode = (e) => {
    document.getElementById('avatarColorCode').jscolor.show();
  };

  getProfile() {
    var callback = this.fillProfile;
    ProfileService.getProfile(callback, this);
  };

  fillProfile() {
    var _this = this;
    var userProfil = JSON.parse(this.response);

    document.querySelector('#phoneNumber').value = userProfil.user.phoneNumber;
    document.querySelector('#firstName').value = userProfil.user.firstName;
    document.querySelector('#lastName').value = userProfil.user.lastName;
    document.querySelector('#email').value = userProfil.user.email;
    document.querySelector('#company').value = userProfil.user.company;
    document.querySelector('#jobTitle').value = userProfil.user.jobTitle;

    _this.scope.setState({
      getUserInfos: {
        company: userProfil.user.company,
        jobTitle: userProfil.user.jobTitle,
        avatarImage: userProfil.user.avatarImagePath
      }
    })

    localStorage.setItem('newUserProfileImage', userProfil.user.avatarImagePath);
    localStorage.setItem('userHasImage', JSON.stringify(userProfil.user.hasProfileImage));

    if (userProfil.user.avatarImagePath === null) {
      document.querySelector('#image').style.display = "none"
      document.querySelector('#img-wrapper').style.backgroundColor = '#' + userProfil.user.avatarColorCode;
    } else {
      document.querySelector('#avatarColorCode').style.display = 'none';
      document.querySelector('#a-position1').style.display = 'none';

      if (userProfil.user.hasProfileImage) {
        document.querySelector('#image').src = userProfil.user.avatarImagePath;
        // document.querySelector('#image').src = userProfil.user.avatarImagePath + '?timeStamp=' + new Date().getTime();
        document.querySelector('#img-wrapper').style.backgroundColor = "transparent";
        document.querySelector('#userNameLetter').style.display = 'none';
      } else {
        document.querySelector('#userNameLetter').innerHTML = userProfil.user.avatarImagePath;
        document.querySelector('#image').style.display = "none"
      }
    }

    document.querySelector('#avatarColorCode').value = userProfil.user.avatarColorCode;
    document.querySelector('#avatarColorCode').style.backgroundColor = '#' + userProfil.user.avatarColorCode;
  }

  wrapperImage() {
    if (this.state.imagePreviewBase64Type && this.state.imagePreviewUrl) {
      document.querySelector('#image').style.display = "block";
      document.querySelector('#img-wrapper').style.backgroundColor = "transparent";
    }
  };

  clarificationTextModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("profileUpdateClarifyAgreementTitle"),
      bodyContent:
        '<div class="membership-agreement">' + ClarifyingText() + "</div>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
    });
  }

  explicitConsentTextModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("profileUpdateExplicitConsentTextTitle"),
      bodyContent:
        '<div class="membership-agreement">' +
        ExplicitConsentText() +
        "</div>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="profile-page mindmap-table wide" style={{ height: "calc(100% - 78px)" }}>
          <div className="container profile-panel">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="profile-form">
                  <form>
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 offset-0 offset-sm-0 offset-md-2 offset-lg-2 offset-xl-2">
                          <div className="form-group">
                            <h4>{Resources.getValue("profileMsgTxt")}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 offset-0 offset-sm-0 offset-md-2 offset-lg-2 offset-xl-2">
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0 mb-4">
                              <div className="form-group">
                                <div className="text-center">
                                  <div className="img-wrapper" id="img-wrapper">
                                    <img
                                      src={this.state.imagePreviewBase64Type + this.state.imagePreviewUrl}
                                      className="photo"
                                      alt="profilePhoto"
                                      id="image"
                                      onChange={this.wrapperImage()}
                                    />
                                    <div id="userNameLetter" className="user-name-letters"></div>
                                  </div>
                                  <div className="edit-box">
                                    <input
                                      id="avatarColorCode"
                                      name="avatarColorCode"
                                      className="jscolor color-box none"
                                      readOnly={true}
                                      value="00ceff"
                                    />
                                    <a id="a-position1" className="a-position1 none" onClick={(e) => this.handleClickAvatarCode(e)}><i className="fa fa-magic"></i></a>

                                    <a id="a-position1" className="a-position1 remove-photo" onClick={() => this.removeProfileImage()} title={Resources.getValue("removeProfileImageMsgTxt")}>
                                      <i className='fa fa-times'></i>
                                    </a>

                                    <input
                                      type="file"
                                      id="avatarImage"
                                      name="avatarImage"
                                      className="none"
                                      onChange={(e) => this.handleChangeAvatarImage(e)}
                                      accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
                                    <a className="a-position2" onClick={(e) => this.handleClickAvatarImage(e)} title={Resources.getValue("selectFileMsgTxt")}>
                                      <i className='fa fa-image'></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  id="firstName"
                                  name="firstName"
                                  className="validate-required"
                                  placeholder={Resources.getValue("firstnameMsgTxt")}
                                  maxLength="50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="lastName"
                                  name="lastName"
                                  className="validate-required"
                                  placeholder={Resources.getValue("lastnameMsgTxt")}
                                  maxLength="50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  placeholder={'+90 ' + Resources.getValue("phonenumberMsgTxt")}
                                  className="validate-required"
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  data-minlength="18"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="email"
                                  name="email"
                                  className="validate-required validate-email profile-email-input"
                                  placeholder={Resources.getValue("currentemailMsgTxt")}
                                  maxLength="50"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="company"
                                  name="company"
                                  className="validate-required"
                                  placeholder={Resources.getValue("companyMsgTxt")}
                                  maxLength="50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="jobTitle"
                                  name="jobTitle"
                                  className="validate-required"
                                  placeholder={Resources.getValue("jobtitleMsgTxt")}
                                  maxLength="50"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="profile-agreement-info">
                                <i
                                  className="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i> {'  '}
                                {Resources.getValue("profileAgreementsInfoMsgText")}
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0 mt-3 mb-1">
                              <div className="agreement-wrapper">
                                <label className="register-checkbox container-checkbox register-label">
                                  <input
                                    type="checkbox"
                                    className="validate-required"
                                    // className={this.isCheckboxRequired ? 'validate-required' : ''}
                                    id="checkbox1-clarify"
                                    name="checkbox1-clarify"
                                  // onChange={(e) => this.checkboxeCheckControl(e)}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <span className="text">
                                  <b>
                                    <u>
                                      <a onClick={this.clarificationTextModal}>{Resources.getValue("profileUpdateClarifyAgreement")}</a>
                                    </u>
                                  </b>
                                  {" " + Resources.getValue("registerMembershipAgreement") + " "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0 mt-3 mb-2">
                              <div className="agreement-wrapper">
                                <label className="register-checkbox container-checkbox register-label">
                                  <input
                                    type="checkbox"
                                    className="validate-required"
                                    // className={this.isCheckboxRequired ? 'validate-required' : ''}
                                    id="checkbox2-explicit"
                                    name="checkbox2-explicit"
                                  // onChange={(e) => this.checkboxeCheckControl(e)}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <span className="text">
                                  <b>
                                    <u>
                                      <a onClick={this.explicitConsentTextModal}>{Resources.getValue("profileUpdateExplicitConsentText")}</a>
                                    </u>
                                  </b>
                                  {" " + Resources.getValue("registerMembershipAgreement") + " "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row required-err none">
                            <span>{Resources.getValue("profileUpdateAgreementReqiredInfoMsgTxt")}</span>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0 mt-3">
                              <div className="form-group">
                                {/* <button
                                  className="yellow-button button submit-form-button float-right"
                                  type="button"
                                  title={Resources.getValue("saveMsgTxt")}
                                  data-submit-method="profile"
                                >
                                  {Resources.getValue("saveMsgTxt")}
                                </button> */}
                                <button
                                  className="yellow-button button float-right"
                                  type="button"
                                  title={Resources.getValue("saveMsgTxt")}
                                  onClick={this.updateProfile}
                                >
                                  {Resources.getValue("saveMsgTxt")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  };
}

export default Profile;
