function DistantSalesContractAgreements() {

  return `
  <p>
    <strong><u>1-TARAFLAR</u></strong>
  </p>
  <p>
    İşbu Mesafeli Satış Sözleşmesi ("Sözleşme"), Aşağı Öveçler Mah., 1309.Cad., No:5/6 Çankaya/ANKARA adresinde mukim Zihinler Fora Eğitim Yazılım ve Danışmanlık Sanayi ve Ticaret Anonim Şirketi (“Sağlayıcı”) ile <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden erişilen hizmeti işbu sözleşme şartlarını kabul ederek satın almak isteyen Kullanıcı (“Kullanıcı”) arasında akdedilmiştir. 
  </p>
  <p>
    <strong><u>2-TANIMLAR</u></strong>
  </p>
  <p>
    <strong><u>3-SÖZLEŞMENİN KONUSU</u></strong>
  </p>
  <p>
    İş bu sözleşmenin konusu Sağlayıcı tarafından <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden erişilen bulut tabanlı yazılım hizmetinden; Kullanıcı’nın faydalanması, sunulacak hizmetlerin ve kullanım koşullarının belirlenmesi ile Tarafların hak ve yükümlülüklerinin gösterilmesidir.
  </p>
  <p>
    İş bu sözleşmede öngörülen koşulları yerine getiremeyeceğiniz ya da karşılamayacağınız kanaatini taşımanız halinde sözleşmeyi onaylamayınız ve uygulamayı kullanmayınız.
  </p>
  <p>
    <strong><u>4-HİZMET VE KULLANIM KOŞULLARI</u></strong>
  </p>
  <p>
    İş bu sözleşme kapsamında Sağlayıcı tarafından sunulacak hizmet, <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden sunulan ve tarayıcı ile ulaşılan, Foramind Zihin Haritalama yazılımına ilişkin kullanım haklarının sözleşme süresince sağlanmasından ibarettir. Foramind Zihin Haritalama yazılımı, WEB sitesi üzerinden düşüncelerin ve bilgilerin görselleştirilmesini sağlayarak diyagram oluşturan bir yazılım uygulamasıdır.
  </p>
  <p>
    Foramind web uygulaması; kutu oluşturma, kutu renk seçimi, boyut ve çerçeve renk seçimi, metin girişi, metin font ve boyut değişikliği, mevcut veya yeni oluşturulan haritanın; favoriye alınması, paylaşılması, indirilmesi, harita oluşturulabilmesi için kısa yol gösterimi, resim ekleme, arka fon seçenekleri arasında seçim yapabilme, simge seçme, paylaşılanları görebilme, şablon kullanma, kutu yer değiştirme, admin paneli kullanma özelliklerini içermektedir.
  </p>
  <p>
    Kullanıcı tarafından Ön Bilgilendirme Metni ve işbu Mesafeli Satış Sözleşmesi’ne ilişkin “Okudum, kabul ediyorum” sekmesinin işaretlenmesi ile ödeme işleminin gerçekleştirilmesinden sonra Kullanıcı yazılımı kullanmaya başlamak için “Maplist”e yönlendirilir ve Sağlayıcı tarafından teslim ve ifanın yerine getirildiği kabul edilir. Kullanıcı seçtiği pakette öngörülen süre boyunca <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesinden üye girişi yaparak hizmeti kullanabilir. 
  </p>
  <p>
    Kullanıcı, kendisine Sağlayıcı tarafından yapılacak tüm bildirimler için <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesine  kayıt olurken bildirdiği iletişim bilgilerinin kullanılacağını kabul eder. İletişim bilgilerinin geçerli olması, yetkili kişiye ait olması ve güncel tutulması Kullanıcı’nın yükümlülüğündedir. 
  </p>
  <p>
    Kullanıcı, iş bu sözleşme kapsamında almış olduğu hizmeti; yetkisiz erişime açamaz, üçüncü kişilere hizmet sağlamak için kullandıramaz, herhangi bir içeriği yeniden yayınlayamaz, dağıtamaz, kopyalayamaz ve satamaz. Hizmetten yararlanma hakkını üçüncü kişilere devredemez. Kullanıcı, Sağlayıcı’nın önceden rızasını almaksızın uygulamada veya barındırılan hizmetlerde herhangi bir yük testi, sızma testi yapamaz ve üçüncü kişilere yaptıramaz.
  </p>
  <p>
    Kullanıcı, kendi belirleyeceği ve güvenli şekilde saklamakla yükümlü olduğu şifre ile hizmeti kullanacaktır. Kullanıcı istediği takdirde şifreyi değiştirebilir. Şifrenin değiştirilmesi, yeni şifre belirlenmesi ve korunması Kullanıcı’nın sorumluluğundadır. 
  </p>
  <p>
    Kullanıcı, iş bu sözleşme kapsamında sunulacak hizmetleri sağlamak için doğrudan veya dolaylı olarak tersine mühendislik, kaynak kodu değiştirme, kaynak kodunu çözme veya başka bir şekilde yazılımın kaynak kodunu türetmeye teşebbüs etmeyecek ve üçüncü kişilerden bu yönde talepte bulunmayacaktır. 
  </p>
  <p>
    Kullanıcı, uygulamayı kopyalamamayı, dağıtmamayı, rehin kurmamayı, temlik etmemeyi ve üçüncü kişi lehine hak tesis etmemeyi kabul eder. Kullanıcı, erişim ve belgelere ulaşım hakkını benzer bir ürün veya rekabetçi bir ürün oluşturmak için kullanmayacaktır. Kullanıcı, haksız rekabet teşkil edebilecek eylem, işlem ve tasarruflarda bulunmayacak; bunların hazırlık tasarımlarını tanıtımlarını veya reklamlarını yapmayacak ve bunlara aracı olmayacaktır. 
  </p>
  <p>
    Kullanıcı, sunulan hizmetlere veya uygulamaya zarar verebilecek ve hizmetlerin kullanılabilirliğini bozacak şekilde eylemlerde bulunmayacaktır. 
  </p>
  <p>
    Kullanıcı, uygulamayı kullanırken ulusal ve uluslararası mevzuata, kamu düzenine ve internet kullanımına ilişkin kurallara uygun davranacaktır. Kullanıcı, hizmetleri sisteme zarar verecek surette kullanamaz. 
  </p>
  <p>
    Kullanıcı, iş bu sözleşme kapsamındaki hizmetleri kullanırken ve/veya diyagram ya da içerik oluştururken; telif hakkı, marka, tasarım, patent ve diğer fikri ve sınai mülkiyet hakları ile kişisel verilerin korunması, tüketici haklarının korunması, haksız rekabet ve reklamlarla ilgili mevzuat dâhil fakat bunlarla sınırlı olmamak üzere yasalardan ve mevzuattan kaynaklanan her türlü gerekliliğe uygun hareket edecektir. 
  </p>
  <p>
    Sağlayıcı, işbu madde altında öngörmüş olduğu kullanım koşullarında teknik yenilikler ve yasal düzenlemelerde değişebilecek koşullar nedeniyle ileriye yönelik değişik yapma hakkını saklı tutar. Kullanıcı güncel kullanım koşullarına <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> adresinden ulaşabilecektir. 
  </p>
  <p>
    <strong><u>5-ÜCRETLENDİRME</u></strong>
  </p>
  <p>
    İş bu sözleşmede belirtilen hizmete ilişkin ücret <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden sipariş sırasında belirtilmektedir. Bu ücretlere varsa vergiler sonradan dâhil edilerek hesaplanır ve site üzerinden gösterilerek ödeme gerçekleştirilir. Ödemeden sonra 1 hafta içerisinde Sağlayıcı tarafından fatura düzenlenerek Kullanıcı’ya gönderilir. 
  </p>
  <p>
    Sağlayıcı önceden haber vermeden fiyatlar ve tarifeler üzerinde ileriye dönük olarak değişiklik yapma hakkını saklı tutar. Bu değişiklikler Kullanıcının içinde bulunduğu kullanım periyodunu etkilemeyecek ve bir sonraki hizmet dönemi için geçerli olacaktır. 
  </p>
  <p>
    Kullanıcı, Sağlayıcı’dan satın aldığı hizmetin kullanılması sırasında yürürlükte olan veya sözleşme sürecinde yürürlüğe girecek olan vergi, harç ve benzeri yükümlülüklerin kendisine ait olduğunu ve karşılayacağını kabul eder. 
  </p>
  <p>
    <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesinde bulunan çevrimiçi ödeme yöntemi kredi kartı ile ödemedir. Başka herhangi bir ödeme yöntemi bulunmamaktadır. WEB sitesinde 1 aylık ve 1 yıllık olmak üzere iki paket bulunmaktadır. Kullanıcı seçtiği pakete uygun olarak 1 aylık ya da 1 yıllık ücreti peşin olarak ödemektedir. Kullanıcı’nın seçtiği paketin süresinin sona ermesi halinde ise hizmeti kullanmaya devam etmesi için <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden yeniden satın alma işlemini gerçekleştirmesi gerekmektedir.  Sağlayıcı tarafından Kullanıcı’nın kredi kartı bilgileri kaydedilmemekte olup, otomatik para çekme işlemi yapılmamaktadır. 
  </p>
  <p>
    Kullanıcının çevrimiçi ödeme yöntemine ait bilgilerinin; yanlış, sahte, gerçeğe aykırı veya hatalı olduğunun Sağlayıcı tarafından üçüncü kişilerin şikâyeti ya da Savcılık makamları ve Mahkeme kanalıyla öğrenilmesi halinde Kullanıcı Sağlayıcı’nın uğradığı/uğraması muhtemel her türlü zararını ayrıca bir ihtara ve ihbara gerek olmaksızın ilk talepte tazmin eder.
  </p>
  <p>
    Ödemelere ilişkin tüm masraflar Kullanıcı’ya aittir. Ödeme Kullanıcı’dan tam olarak tahsil edilemezse ya da önceden tahsil edilen ücret bakımından kendisine yapılan ödeme ile ilgili herhangi bir sorun ortaya çıkarsa (çalıntı kredi kartı ile yapılan işlemler vb.) Kullanıcı, Sağlayıcı’nın ödemeyi tahsil amacıyla her türlü hukuki yola başvurabileceğini bildiğini kabul eder.
  </p>
  <p>
    <strong><u>6-HİZMET DÜZEYİ</u></strong>
  </p>
  <p>
    Sağlayıcı geri bildirimler doğrultusunda hizmette eklemeler yapabileceği gibi ihtiyaç duyulan güncelleme ve değişiklikleri de yapabilecektir.
  </p>
  <p>
    İş bu sözleşme kapsamında sunulan hizmete ilişkin yazılım bulut tabanlı bir teknolojiye sahip olup herhangi bir kurulum yapılma ihtiyacı bulunmamaktadır. Yazılımda kullanılacak ve üretilecek içerik, belge ve dokümanlar Veri Merkezindeki bulut sisteminde tutulacaktır. Teknolojik yol haritasındaki özellikler yazılıma kazandırıldığında Kullanıcı yeni özellikleri herhangi bir güncelleme yapmadan kullanabilecektir.
  </p>
  <p>
    Hizmetin makul seviyede kesintisiz sağlanabilmesi amacıyla bulut taban sağlayıcı tarafından ikişer uygulama ve veri tabanı sunucu bulundurulmaktadır. Satın alma hareketleri de kayıt altına alınmakta olup (kişisel veri işlenmesi anlamında hareket takibi söz konusu değildir) ve yaşanabilecek sorunlara anında müdahale edilmektedir. Sunucular Linux Ubuntu (işletim sitemi) mevcut cihazlarda çalıştırılarak performansı arttırılmaktadır. Veri tabanı sunucularında Mysql (çok kullanıcılı yazılımlarda kullanılan veri tabanı türüdür) kullanılmaktadır. Uygulama sunucularında ise bellek sızıntılarını minimuma indirmiş ve uzaktan servisler arasında yüksek güvenlik düzeyi sağlayan .NET Framework (yazılım geliştirme platformu) kullanılmaktadır.
  </p>
  <p>
    Kullanıcı tarafından yazılım kullanımı suretiyle oluşturulan diyagramların ve işlemlerin düzenli olarak yedeği alınmakta olup veri kayıplarına karşı güvenlik sağlanmaktadır.
  </p>
  <p>
    Sağlayıcı iş bu sözleşme süresince yazılım bakım ve destek hizmetini sağlayacaktır. İş bu sözleşme kapsamında sunulan hizmete ilişkin destek ve bakım hizmetleri dış kaynak sağlayıcı tarafından gerçekleştirilmekte olup gerekli hallerde ve amaçla sınırlı olarak dış kaynak sağlayıcı yazılıma erişim sağlayabilecektir. 
  </p>
  <p>
    Sağlayıcı hizmet üzerinde olumsuz bir etkisi olması muhtemel planlanmış bakım hizmetlerine ilişkin müşteriyi en az bir iş günü öncesinde bilgilendirecektir. Bilgilendirmenin <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden yapılması yeterli olacaktır.
  </p>
  <p>
    Sağlayıcı, yazılım güvenlik düzeyinin sağlanması ve teknik destek, bakım hizmetlerinin sağlanması amacıyla makul önlemleri dış kaynak sağlayıcı aracılığıyla temin etmektedir. 
  </p>
  <p>
    Kullanıcı, oluşabilecek problem ve gecikmeler ve hizmette oluşabilecek aksaklıklar nedeniyle Sağlayıcı’yı sorumlu tutmayacaktır. Kullanıcı aksaklığı rapor ederek çözüm talebinde bulunacağını kabul eder. Kullanıcı, destek talebi olması halinde <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesinde yer alan iletişim formu aracılığıyla ya da <a href="mailto:info@foramind.com">info@foramind.com</a> elektronik posta adresine bildirimde bulunacaktır. Sağlayıcı ve/veya dış kaynak sağlayıcı ilgili bildirime ilişkin desteği çalışma saatleri içerisinde kalmak kaydıyla makul sürede sağlayacaktır. Sağlayıcı’nın gerekli gördüğü hallerde destek hizmetinin sağlanması amacıyla uzaktan bağlantı kurulabilecek olup bu halde içeriğe erişim sağlanmasına Kullanıcı tarafından müsaade edileceği taahhüt edilmektedir. Teknik destek ve bakım hizmetleri dışında Sağlayıcı tarafından içeriğe herhangi bir erişim sağlanmayacaktır. 
  </p>
  <p>
    Sağlayıcı tarafından hizmetin kullanımına ilişkin garanti belgesi ve kullanım kılavuzu sağlanmayacaktır. Ayrıca hizmetin kullanılmasına ilişkin eğitim verilmemektedir. 
  </p>
  <p>
    İş bu sözleşme kapsamında sunulan hizmet <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden sağlanmakta olup yararlanılabilmesi için güncel bir tarayıcıya ve internet bağlantısına sahip olması gerekmektedir. İnternet kota limitinin aşılması, üçüncü parti yazılımlarla kullanılması, aygıt ve donanım gereksinimlerinin yazılım için yeterli olmaması, aygıtta yazılımı engelleyen virüs bulunması, donanım veya işletim sistemi hatası, bağlantı hatası, voltaj dalgalanması ve bunlarla sınırlı olmamak kaydıyla Sağlayıcı’ya atfedilemeyecek bir nedenle yazılımın kullanılamaması veya sınırlı kullanımı halinde Sağlayıcı sorumlu olmayacaktır.
  </p>
  <p>
    <strong><u>9-FİKRİ MÜLKİYET HAKLARI</u></strong>
  </p>
  <p>
    <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi, yazılım ve uygulama içerisinde yer alan, 6769 sayılı Sınai Mülkiyet Kanunu ve 5846 sayılı Fikir ve Sanat Eserleri Kanununa tabi her türlü manevi, mali, fikri mülkiyet ve ticari haklar Sağlayıcı’ya ve/veya Sağlayıcı’ya münhasır lisans sağlayana aittir. Hizmetlerin kullandırılması; <a href="https://www.foramind.com" target="_blank">www.foramind.com</a>, WEB sitesi ve yazılıma ilişkin hak ve menfaatlerin Kullanıcı’ya devredildiği anlamına gelmeyecektir. Sağlayıcı Kullanıcı’ya; sözleşme süresi ile sınırlı, münhasır olmayan, devredilemeyen, alt lisansa konu olmayan kullanma lisans hakkı tanımaktadır.
  </p>
  <p>
    <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden sunulan uygulamaya ilişkin bilgi, belge ve dokümanlar 5846 sayılı Fikir ve Sanat Eserleri Kanunu kapsamında sahibinin hususiyeti ile imal edilmiş eserlerdir. Sağlayıcı sunulan yazılıma bağlı hizmet kapsamında Kullanıcı’ya, içerik (diyagram) üretme, indirme ve paylaşma hakkı ile sınırlı bir izin vermektedir. İlgili eserin ve esere bağlı bilgi ve belgelerin anılan amaç dışında kullanılması, çoğaltılması, dağıtılması, işlenmesi fikri mülkiyet hakkının ihlali anlamına gelecek olup hukuki ve cezai yaptırıma tabidir.
  </p>
  <p>
    Kullanıcı, sunulan hizmet kapsamında oluşturulan içeriklere (diyagram) ilişkin fikri mülkiyet hakları Sağlayıcı’ya aittir. 
  </p>
  <p>
    Kullanıcı, hizmetleri sağlamak için kullanılan yazılım ya da belgelerde yer alan herhangi bir telif hakkı, ticari marka veya diğer mülkiyet bildirimlerini değiştiremez, kaldıramaz veya gizleyemez.
  </p>
  <p>
    <strong><u>10-SORUMLULUK</u></strong>
  </p>
  <p>
    Sağlayıcı, işbu sözleşme kapsamında sunulan hizmetlerin sürekliliği için gereken çabayı sarf eder. Ancak sunduğu hizmetin Kullanıcı’nın ihtiyacını tam olarak karşılayacağını, kesintisizliğini, hatasızlığını ve güvenilirlik seviyesini garanti etmez. Bu bakımdan oluşabilecek veri kaybı ya da bilgisayar sistemi hataları nedeniyle Sağlayıcı’nın sorumluluğu doğmayacaktır. Kullanıcı’nın bu sözleşme kapsamında sağlanan herhangi bir hizmetin kullanılmasından ve/veya kullanılamamasından oluşabilecek doğrudan veya dolaylı zararlardan Sağlayıcı sorumlu değildir.
  </p>
  <p>
    Kullanıcı, Sağlayıcı tarafından sunulan hizmet kapsamında görselleştirdiği düşünce ve bilgilerden kendisinin sorumlu olduğunu, Sağlayıcı’nın herhangi bir kontrol yükümlülüğü olmadığını, içerik nedeniyle ortaya çıkabilecek her türlü hukuki ve cezai sorumluluğun kendisine ait olduğunu kabul eder.
  </p>
  <p>
    Sağlayıcı’nın, Kullanıcı tarafından uygulamanın kullanımı suretiyle oluşturulan içerikten ya da kullanımdan doğrudan veya dolaylı oluşabilecek maddi, manevi, hukuki yahut ekonomik olarak sorumluluğu bulunmamaktadır. 
  </p>
  <p>
    Kullanıcı kendisinin eylemlerinden ve ihmallerinden tek başına sorumludur. Sağlayıcı, Kullanıcı tarafından doğrudan veya dolaylı olarak neden olunan herhangi bir veri veya işlev kaybından sorumlu olmayacaktır.
  </p>
  <p>
    Sağlayıcı’nın, Kullanıcıların hesap oluşturma ve giriş sırasında kullanmış oldukları kimlik doğrulama bilgilerinin doğruluğundan sorumluluğu bulunmamaktadır.
  </p>
  <p>
    Kullanıcı şifresini paylaşması, kaybetmesi veya üçüncü kişilerce öğrenilmesi durumlarında durumu derhal Sağlayıcı’ya bildirerek yeni şifre temin edecektir. Aksi takdirde şifresi ile yapılan işlemlerden Sağlayıcı’ya ve üçüncü kişilere karşı sorumlu olacaktır.
  </p>
  <p>
    Kullanıcı, yazılımı iş bu sözleşme hükümlerine aykırı kullandığı takdirde, tüm sorumluluğun kendisine ait olduğunu ve bu nedenle ortaya çıkabilecek zarar ve ziyandan ve üçüncü şahıslar tarafından açılacak her türlü dava, zarar veya ziyan taleplerinden sorumlu olacağını, Sağlayıcı’nın söz konusu dava, şikâyet ve benzeri konularda hiçbir şekilde taraf ve muhatap olmayacağını kabul eder. Sözleşme hükümlerinin Kullanıcı tarafından ihlali nedeniyle, Sağlayıcı’nın üçüncü şahıslar tarafından açılacak herhangi bir davada taraf addedilerek üçüncü şahıslara herhangi bir tazminat ödemesi veya yükümlülük altına sokulması durumunda, başta ödemiş olduğu tazminat olmak üzere, bundan dolayı uğrayacağı her türlü zararı için rücu hakkı saklıdır.
  </p>
  <p>
    Sağlayıcı’nın, iş bu sözleşme kapsamında Kullanıcı veya Kullanıcı nezdinde doğabilecek her türlü zarardan sorumluluğu her halükarda Kullanıcı’nın seçmiş olduğu pakete göre 1 aylık/ 1 yıllık hizmet bedeli ücreti ile sınırlıdır. 
  </p>
  <p>
    <strong><u>11-KİŞİSEL VERİLERİN KORUNMASI</u></strong>
  </p>
  <p>
    Kullanıcı’nın uygulamayı kullanmak suretiyle oluşturmuş olduğu içerikler, Kullanıcı’nın talimatları doğrultusunda Sağlayıcı tarafından işlenecektir. Söz konusu içeriklere ilişkin veri işleme faaliyeti depolama ile sınırlı olup depolama merkezi hizmet bulut hizmet sağlayıcı tarafından karşılanmaktadır. Sağlayıcı’nın üçüncü taraf veri işleme merkezini değiştirme hakkı saklıdır. İçerikler veri merkezinde bulunan bulut tabanda tutulacaktır. 
  </p>
  <p>
    Sağlayıcı veri işleyen sıfatı ile sağlamış olduğu hizmete ilişkin; kişisel verilerin hukuka aykırı olarak işlenmesini önlemek, kişisel verilerin hukuka aykırı olarak erişilmesini önlemek ve kişisel verilerin muhafazasını sağlamak için gerekli idari ve teknik tedbirleri almaktadır. Ancak uygulama vasıtasıyla sunmuş olduğu hizmet kapsamı kişisel veri işlenmesi değildir. Bu bakımdan oluşturulan içeriklerde kişisel veri bulunup bulunmadığının tespiti ile kontrolü yönünde Sağlayıcı’nın sorumluluğu bulunmamaktadır. 
  </p>
  <p>
    Sağlayıcı, herhangi bir güvenlik ihlali yahut hukuka aykırı veri elde edildiğinden haberdar olduğu takdirde Kullanıcı’ya 72 saat içerisinde bildirimde bulunacaktır.
  </p>
  <p>
    Kullanıcı, Kişisel Verilerin Korunması Kanunu, ikincil mevzuat ve iş bu sözleşmede belirtilen kişisel verilerin korunmasına ilişkin yükümlülüklerin yerine getirilmemesi dolayısıyla üçüncü kişilerce ileri sürülecek idari/hukuki zarar, tazminat ve taleplerden sorumludur. Söz konusu yaptırımlar dolayısıyla Sağlayıcı tarafından yapılan ödemelere ilişkin rücu hakkı saklıdır. 
  </p>
  <p>
    <strong><u>12-GÜVENLİK</u></strong>
  </p>
  <p>
    Kullanıcı oluşturulacak kullanıcı adı ve parolanın muhafazasından, güvenliğinden sorumludur. Kullanıcı, herhangi bir parolanın veya kullanıcı adının güvenlik ihlali ve yetkisiz kullanımını derhal Sağlayıcı’ya bildirecektir.
  </p>
  <p>
    Kullanıcı, Sağlayıcı’nın yazılı rızasını almadan yazılım ve hizmetlere üçüncü şahısların erişimine izin vermeyecektir.
  </p>
  <p>
    Kullanıcı, Sağlayıcı’ya tüm güvenlik ihlallerini, yazılı ve hizmetlere yetkisiz erişimi ve parolaların kötüye kullanımını bildirmekle yükümlüdür.
  </p>
  <p>
    Kullanıcı, yazılım ve hizmetlerin güvenliğinin sağlanması amacıyla erişim sağlanan aygıt ve donanımlarda gerekli güvenlik önlemlerini almakla yükümlüdür.
  </p>
  <p>
    Sağlayıcı tarafından veri kaybının önlenmesi amacıyla saatlik periyotlarla yedekleme işlemi gerçekleştirilmektedir.
  </p>
  <p>
    <strong><u>13-GİZLİLİK</u></strong>
  </p>
  <p>
    Kullanıcı, yazılım ve hizmetlerin kullanım amacı dışında kalan içerik, ortam ve platform tedarikçileri dâhil olmak üzere eserler üzerindeki hak sahibi üçüncü kişilere ve Sağlayıcı’ya ilişkin öğrenilen gizli bilgileri muhafaza etmeyi, kısmen veya tamamen üçüncü kişilere doğrudan veya dolaylı olarak hiçbir şekilde ifşa etmemeyi veya açıklamamayı kabul eder.
  </p>
  <p>
    İşbu Sözleşme herhangi bir nedenle sona erse dahi, Kullanıcı’nın gizlilik ve fikri mülkiyet hakları ile ilgili yükümlülükleri herhangi bir süre kısıtlamasına tabi olmaksızın yürürlükte kalacaktır.
  </p>
  <p>
    <strong><u>14-MÜCBİR SEBEP</u></strong>
  </p>
  <p>
    Hukuken mücbir sebep sayılan tüm durumlarda, Sağlayıcı işbu sözleşme ile belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle sorumlu değildir. Mücbir sebep hallerinde; gecikme, eksik ifa etme, ifa etmeme hali temerrüt addedilmeyecek, gerçekleşen olası veri kayıplarından; Kullanıcı tarafından Sağlayıcı’dan herhangi bir nam altında tazminat talep edilemeyecektir. Mücbir sebep terimi; Ayaklanma, ambargo, devlet müdahalesi, isyan, işgal, savaş, seferberlik, grev, lokavt, iş eylemleri veya boykotlar dahil olmak üzere siber saldırı, iletişim sorunları, altyapı ve internet arızaları, sisteme ilişkin iyileştirme veya yenileştirme çalışmaları, işletim sistemi ve altyapı sorunları ile bu sebeple meydana gelebilecek arızalar, elektrik kesintisi, yangın, patlama, fırtına, sel, deprem, göç, salgın veya diğer bir doğal felaket veya Sağlayıcı’nın kontrolü dışında gerçekleşen, kusurundan kaynaklanmayan ve makul olarak öngörülemeyecek diğer olaylar olarak yorumlanacaktır.
  </p>
  <p>
    Sözleşme kapsamında, Tarafların hiçbiri, tazminat yükümlülükleri hariç olmak üzere, Mücbir Sebep nedeniyle yükümlülüklerini yerine getirememekten veya gecikmeden dolayı sorumlu tutulmazlar. Tarafların sorumlu tutulmamaları için yükümlülüğü yerine getirmemenin veya gecikmenin, makul tedbirlerin alınmış olmasına rağmen önlenemeyecek nitelikte olması zorunludur. Mücbir sebep halinde taraflar müşterek karar ile sözleşmeyi askıya alabilecektir. Askı süresinin 60 günü aşması halinde Taraflar diğer tarafa yazılı bildirimde bulunmak suretiyle sözleşmeyi sona erdirebilecektir.
  </p>
  <p>
    <strong><u>15-SÜRE, YENİLEME, CAYMA HAKKI VE ÇIKIŞ HÜKÜMLERİ</u></strong>
  </p>
  <p>
    İşbu sözleşmenin süresi Kullanıcı’nın <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden seçtiği pakete göre 1 ay veya 1 yıldır. Sağlayıcı sözleşme süresi boyunca yazılım ve hizmetlere Kullanıcılara yönelik erişim sağlayacaktır. İşbu sözleşme, Kullanıcı’nın seçtiği paket süresinin sonunda kendiliğinden sona erer. Kullanıcı’nın süresinin sonunda hizmeti kullanmaya devam etmesi/yenilenmesi için <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden yeniden satın alma işlemini gerçekleştirmesi gerekmektedir
  </p>
  <p>
    Kullanıcı, <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden Foramind Zihin Haritalama yazılımını kullanmak için 1 ay veya 1 yıllık paket satın alarak Maplist’e yönlendirildiği tarihten itibaren 14 (ondört) gün içerisinde, Sağlayıcı’ya bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin bu Sözleşme’den cayma hakkını kullanabilir. Kullanıcı, işbu Sözleşmeyi kabul ederek cayma hakkı konusunda bilgilendirildiğini kabul eder. 
  </p>
  <p>
    Kullanıcı, cayma hakkını kullanmak istediği takdirde cayma kararını bildiren açık bir beyanı <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesinde yer alan iletişim formu aracılığıyla ya da <a href="mailto:info@foramind.com">info@foramind.com</a> adresi üzerinden yöneltmiş olmalıdır. Cayma kararını bildiren beyanın Sağlayıcı’ya ulaşması halinde Sağlayıcı cayma talebinin kendisine ulaştığını ilişkin teyit bilgisini Kullanıcı’ya verir. 
  </p>
  <p>
    İşbu Sözleşme’nin herhangi bir şekilde sona ermesi halinde, Kullanıcı tarafından oluşturulan içerikler (diyagramlar) Sağlayıcı’ya verilmeyecektir. 
  </p>
  <p>
    Kullanıcı’nın, iş bu sözleşmede sunulan hizmete benzer bir hizmeti başka bir sağlayıcıdan almak istemesi halinde oluşturulan içerik (diyagram) geçişi Sağlayıcı tarafından karşılanmayacaktır. 
  </p>
  <p>
    Sağlayıcı, Kullanıcı’nın hukuka aykırı veri saklaması ve paylaşması, faaliyetlerinin hukuki, teknik ve bilgi güvenliği anlamında risk oluşturması, herhangi bir şekilde yasalara aykırı davranması ve/veya işbu sözleşme hükümlerinden birini ihlal etmesi halinde sağlanan hizmetleri kısmen veya tamamen geçici veya sürekli olarak askıya alabilir veya tek taraflı olarak sözleşmeyi derhal feshedebilir. Kullanıcı, söz konusu hallerde Sağlayıcı aleyhine herhangi bir hak iddia edemez ve tazminat talebinde bulunamaz.
  </p>
  <p>
    <strong><u>16-UYUŞMAZLIKLARIN ÇÖZÜMÜ</u></strong>
  </p>
  <p>
    İşbu Sözleşme'den doğabilecek uyuşmazlıklarda, Ticaret Bakanlığı'nca yasa gereği her yıl ilan edilen parasal sınırlar dâhilinde İl ve İlçe Tüketici Hakem Heyetleri, bu sınırları aşan durumlarda Tüketici Mahkemeleri görevli/yetkilidir. Kullanıcı, bu çerçevede, kendisinin veya dilerse Sağlayıcı’nın yerleşim yerindeki (ikametgahındaki) Hakem Heyetleri ve Tüketici Mahkemeleri'ne başvurabilir.
  </p>
  <p>
    İşbu sözleşme ve sözleşmenin yerine getirilmesi ve yorumu ile ilgili olarak ortaya çıkabilecek her türlü ihtilafın çözümü Türk Hukukuna tabi olacak ve Ankara Merkez Mahkemeleri ve İcra Daireleri münhasır yetkiye sahip olacaktır.
  </p>
  <p>
    Sağlayıcı iş bu sözleşme kapsamındaki yükümlülüklerini veya haklarını tamamen kendi takdirinde olmak üzere kısmen veya tamamen başkalarına devir ve temlik edebilir veya alt sözleşme yapabilir.
  </p>
  <p>
    Sağlayıcı’nın elektronik ve fiziki kayıtları işbu sözleşme kapsamında meydana gelebilecek uyuşmazlıklarda HMK Madde 193 uyarınca delil kabul edilecektir.
  </p>
  <p>
    <strong><u>17-YÜRÜRLÜK VE DİĞER HÜKÜMLER</u></strong>
  </p>
  <p>
    İşbu madde dâhil, 17 ana maddeden ve 7 sahifeden oluşan işbu Sözleşme, Kullanıcı tarafından elektronik ortamda kabul edilmekle birlikte yürürlüğe girecek olup; Taraflarca Sözleşmede belirtilen koşullar ve usüller doğrultusunda sona erdirilmediği sürece Kullanıcı tarafından seçilen pakette belirlenen sürede (1 ay/1 yıl) yürürlükte kalmaya devam edecektir. 
  </p>
  <p>
    Kullanıcı, <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesi üzerinden Sözleşme’yi kabul ederek ve seçtiği pakete ilişkin ödemeyi gerçekleştirdiğinde işbu Sözleşme’nin tüm şartlarını kabul etmiş sayılır. 
  </p>
  <p>
    Kullanıcı, işbu Sözleşme’de ve <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesinde yer alan Ön Bilgilendirme Metni’nde yazılı tüm koşulları ve açıklamaları okuduğunu, satışa konu yazılımın temel nitelikleri, satış fiyatı, ödeme şekli, teslimat koşulları, cayma hakkı ve diğer bilgilendirmeler hakkında bilgi sahibi olduğunu, tamamını <a href="https://www.foramind.com" target="_blank">www.foramind.com</a> WEB sitesindende elektronik rotamda gördüğünü ve bunlara elektronik ortamda onay vererek verdiği sipariş ile Sözleşme hükümlerini kabul ettiğini kabul ve beyan eder. 
  </p>
  <p>
    Sağlayıcı tarafından, siparişin alınmasını müteakip işbu Sözleşme ve Ön Bilgilendirme Metni Kullanıcı’nın önceden bildirdiği e-mail adresine sipariş teyidi ve özeti ile birlikte gönderilmektedir. Ayrıca Kullanıcı, metinlere müşteri paneli üzerinden de ulaşabilir. 
  </p>
`;
}

export default DistantSalesContractAgreements;
