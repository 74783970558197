import React, { Component } from "react";
import Resources from "../libraries/resources";
import ContactService from "../services/api/contact";
import IMask from 'imask';
import Utils from "../libraries/utils";
import LandingPageService from "../services/api/landing-page";
import ReactStars from "react-rating-stars-component";
import Star from '../components/star';
import AddReviewModal from "../helpers/add-review-modal";
import EnterpriseApplyModal from "../helpers/enterprise-apply-modal";
import ContactClarifyingText from "../components/contact-clarifying-text";
import NavMenu from "../components/nav-menu";
import Footer from "../components/footer";
// images
import mapScreenWithMac from "../styles/img/map-screen-on-monitor.png";
import mapScreen from "../styles/img/map-screen-on-laptop.png";
import feature1 from "../styles/img/feature-bg-1_tiny.png";
import feature2 from "../styles/img/feature-bg-2_tiny.png";
import feature3 from "../styles/img/feature-bg-3_tiny.png";
import feature1Image from "../styles/img/take-note_tiny.png";
import feature2Image from "../styles/img/improve-mind_tiny.png";
import feature3Image from "../styles/img/creative-mind_tiny.png";
import planImg from "../styles/img/planlama_tiny.png";
import brainStormingImg from "../styles/img/beyin-firtinasi_tiny.png";
import bahcesehirLogo from "../styles/img/Bahcesehir_new_logo.png";
import bkmobilLogo from "../styles/img/bk_mobil_logo.png";
import homeBg from "../styles/img/header-banner-img-group_tiny_web.png";
import silverBadgeImg from "../styles/img/free-badge.png";
import goldBadgeImg from "../styles/img/gold-badge.png";
import redBookmarkImg from "../styles/img/bookmark-red.png";

class LandingPage extends Component {

	constructor() {
		super();

		localStorage.setItem("isCustomModalOpen", false);
		localStorage.setItem("reviewRate", 0);

		this.state = {
			allReviews: [],
			isReviewModal: JSON.parse(localStorage.getItem("isCustomModalOpen")),
			reviewRateValue: JSON.parse(localStorage.getItem("reviewRate")),
			colorArray: [
				"#33bdd8",
				"#fd7021",
				"#fcbc2c",
				"#34a853",
				"#9e35cf",
				"#cf3568"
			],
			isEnterpriseApplyModal: JSON.parse(localStorage.getItem("isCustomModalOpen"))
		};

		this.changeReviewRate = this.changeReviewRate.bind(this);
		this.setReviewRate = this.setReviewRate.bind(this);

    localStorage.setItem("retrieveUrl", window.location.pathname);

		this.menuItems = [
			{
				href: '#home',
				title: Resources.getValue("landingPageHomeMsgTxt"),
				activeClass: 'home-color-set active',
				externalLink: false
			},
			{
				href: '',
				title: Resources.getValue("landingPageCorporateMsgTxt"),
				activeClass: 'not-active',
				externalLink: false,
				subLinks: [
					{
						href: '/about',
						title: Resources.getValue("landingPageAboutMsgTxt"),
						activeClass: '',
						externalLink: true
					},
					// {
					// 	href: '#references',
					// 	title: Resources.getValue("landingPageReferencesMsgTxt"),
					// 	activeClass: '',
					// 	externalLink: false
					// },
					{
						href: '#contact',
						title: Resources.getValue("landingPageContactMsgTxt"),
						activeClass: '',
						externalLink: false
					}
				]
			},
			// {
			// 	href: '/features',
			// 	title: Resources.getValue("landingPageFeaturesMsgTxt"),
			// 	activeClass: '',
			// 	externalLink: true
			// },
			{
				href: '/education',
				title: Resources.getValue("landingPageEducationMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/blog',
				title: "Blog",
				activeClass: '',
				externalLink: true
			},
			{
				href: '#price',
				title: Resources.getValue("landingPagePriceMsgTxt"),
				activeClass: '',
				externalLink: false
			},
			{
				href: '/kvkk',
				title: Resources.getValue("landingPageKavkkMsgTxt"),
				activeClass: '',
				externalLink: true
			}
		];

		this.tokenExpire = false;
		const token = localStorage.getItem('token');
		if (token) {
			const tokenJSON = JSON.parse(atob(token.split('.')[1]));
			if (tokenJSON.exp < new Date().getTime() / 1000) { // ms cinsinden
				this.tokenExpire = true;
			}
		}
	}

	componentDidMount() {
		Utils.formValidation();
		this.phoneNumberMask();

		window.contactSend = function () {
			var contactData = {
				name: this.document.querySelector("#name").value,
				surname: this.document.querySelector("#surname").value,
				email: this.document.querySelector("#email").value,
				phone: document.querySelector("#phone").value,
				description: this.document.querySelector("#description").value,
			};
			ContactService(JSON.stringify(contactData));
		}

		// document.title = "Foramind | " + Resources.getValue("landingPageHomeMsgTxt");

		this.getPrice();
		this.getReviewList();
		// this.getCountOfUsersAndTeachersAndMaps();
		//price card row default position
		document.getElementById('price-card-row').scrollLeft += 532;
	}

	numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}

	/* getCountOfUsersAndTeachersAndMaps() {
		var _this = this;
		LandingPageService.getCountOfUsersAndTeachersAndMaps(this.getCountOfUsersAndTeachersAndMapsCallback, _this);
	} */

	/* getCountOfUsersAndTeachersAndMapsCallback() {
		var allCountOfUsersAndTeachersAndMaps = JSON.parse(this.response);

		if (allCountOfUsersAndTeachersAndMaps) {
			this.scope.setState({
				allCountOfUsersAndTeachersAndMaps
			});
		}
	} */

	getReviewList() {
		var _this = this;
		LandingPageService.getReviewList(this.getReviewsCallback, _this);
	}

	getReviewsCallback() {
		var allReviews = JSON.parse(this.response);

		if (allReviews.length > 0) {
			this.scope.setState({
				allReviews: JSON.parse(JSON.stringify(allReviews))
			})
		}
	}

	setReviewRate(starsSelected) {
		localStorage.setItem("reviewRate", starsSelected);
		this.setState({
			reviewRateValue: starsSelected
		});
	}

	changeReviewRate(starsSelected) {
		if (localStorage.getItem('userInformation')) {
			localStorage.setItem("isCustomModalOpen", true);
			this.setState({
				isReviewModal: JSON.parse(
					localStorage.getItem("isCustomModalOpen")
				),
				reviewRateValue: starsSelected
			});
			localStorage.setItem("reviewRate", starsSelected);
		} else {
			window.location.href = Resources.getValue('appBaseEnvURL') + '/login';
		}
	}

	readMore(e) {
		e.preventDefault(e);
		let featureDiv = e.target.closest('.feature');
		featureDiv.classList.add('full-height');
	}

	lessMore(e) {
		e.preventDefault(e);
		let featureDiv = e.target.closest('.feature');
		featureDiv.classList.remove('full-height');
	}

	readMoreTriple(e) {
		e.preventDefault(e);
		let featureDiv = e.target.closest('.feature-wrap');
		featureDiv.classList.add('full-height');
	}

	lessMoreTriple(e) {
		e.preventDefault(e);
		let featureDiv = e.target.closest('.feature-wrap');
		featureDiv.classList.remove('full-height');
	}

	getPrice() {
		var _this = this;
		var langId = Resources.siteLanguage;
		LandingPageService.getPrice(this.getPriceCards, _this, langId);
	}

	getPriceCards() {
		var getPrice = JSON.parse(this.response).productList;
		document.querySelector(".month-many").innerText = getPrice[0].price;
		document.querySelector(".year-many").innerText = getPrice[1].price;
	}

	phoneNumberMask() {
		IMask(document.getElementById('phone'), {
			mask: '{+9\\0}(000)-000-00-00'
		});
	};

	contactClarificationTextModal() {
		Utils.modalm().open({
			exitButtonText: Resources.getValue("exitMsgTxt"),
			title: Resources.getValue("contactClarifyAgreementTitle"),
			bodyContent:
				'<div class="membership-agreement">' + ContactClarifyingText() + "</div>",
			buttons: [
				{
					text: Resources.getValue("okMsgTxt"),
					class: "button yellow-button confirm-button",
					href: "",
				},
			],
		});
	}

	sharedClick = (isOpen) => {
		this.setState({
			isReviewModal: isOpen,
			isEnterpriseApplyModal: isOpen
		});
	};

	render() {
		return (
			<div className="landing-page" id="landing-page">
				{this.state.isReviewModal ? (
					<AddReviewModal sharedClick={this.sharedClick} reviewRate={this.state.reviewRateValue} setReviewRate={this.setReviewRate} />
				) : null}
				{this.state.isEnterpriseApplyModal ? (
					<EnterpriseApplyModal sharedClick={this.sharedClick} />
				) : null}
				<NavMenu menuItems={this.menuItems} />
				<header className="home hero-bg" id="home">
					<img src={homeBg} alt="" />
					<div className="header-content">
						<div className="">
							<div className="">
								<div className="">
									<div className="text-container d-flex flex-column">
										<h1>
											<span className="page-template-free">
												{Resources.getValue("homeHeaderTitleMsgTxt")}
											</span>
										</h1>
										<p className="w-100 p-large">
											{Resources.getValue("homeHeaderSubContentMsgTxt")}
										</p>
										<p className="w-100 mb-3">
											<a href={Resources.getValue('appBaseEnvURL') + '/register'}
												className="yellow-button button free-btn"
											>
												{Resources.getValue("homeHeaderFreeTryBtnMsgTxt")}
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

				{/* <div className="foramind-counts">
					<div className="container">
						<div className="foramind-counts-wrap">
							<div className="member-count">
								<i className="icon-people"></i>
								<div className="title">
									{Resources.getValue("memberCountTitleMsgTxt")}
								</div>
								<div className="line"></div>
								<div className="count-info">
									{
										this.state.allCountOfUsersAndTeachersAndMaps
											? this.numberWithCommas(this.state.allCountOfUsersAndTeachersAndMaps.userCount)
											: 0
									}
								</div>
							</div>
							<div className="map-count">
								<i className="icon-maps"></i>
								<div className="title">
									{Resources.getValue("mapCountTitleMsgTxt")}
								</div>
								<div className="line"></div>
								<div className="count-info">
									{
										this.state.allCountOfUsersAndTeachersAndMaps
											? this.numberWithCommas(this.state.allCountOfUsersAndTeachersAndMaps.mapCount)
											: 0
									}
								</div>
							</div>
							<div className="template-count">
								<i className="icon-template-icon"></i>
								<div className="title">
									{Resources.getValue("templateCountTitleMsgTxt")}
								</div>
								<div className="line"></div>
								<div className="count-info">
									{
										this.state.allCountOfUsersAndTeachersAndMaps
											? this.numberWithCommas(this.state.allCountOfUsersAndTeachersAndMaps.templateCount)
											: 0
									}
								</div>
							</div>
							<div className="teacher-count none">
								<i className="icon-teacher"></i>
								<div className="title">
									{Resources.getValue("teacherCountTitleMsgTxt")}
								</div>
								<div className="line"></div>
								<div className="count-info">
									{
										this.state.allCountOfUsersAndTeachersAndMaps
											? this.numberWithCommas(this.state.allCountOfUsersAndTeachersAndMaps.teacherCount)
											: 0
									}
								</div>
							</div>
						</div>
					</div>
				</div> */}

				<div className="sub-features1">
					<div className="container">
						<div className="row">
							<div className="col-lg-6">
								<div className="image-container image-container__top">
									<img className="img-fluid" src={mapScreenWithMac} alt="Foramind, Zihin Haritası, Mind Map" />
								</div>
							</div>
							<div className="col-lg-6">
								<div className="text-container text-container__top">
									<h2 className="max-w">{Resources.getValue("landingPageWhatIsMindMappingTitleMsgTxt")}</h2>
									<p>{Resources.getValue("landingPageWhatIsMindMappingDescMsgTxt")}</p>
								</div>
							</div>
						</div>
						<div className="row sub-features1__bottom">
							<div className="col-lg-6">
								<div className="text-container text-container__bottom">
									<h2>{Resources.getValue("landingPageWhatIsMindMapTitleMsgTxt")}</h2>
									<ul className="landingPage-ulList li-space-lg">
										<li className="media">
											<div className="section-description">
												{Resources.getValue("landingPageWhatIsMindMapDescMsgTxt")}
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="image-container image-container__bottom">
									<img className="img-fluid" src={mapScreen} alt="Foramind, Zihin Haritası, Mind Map" />
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div id="education" className="education none">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-6">
								<div className="text-container">
									<h2>{Resources.getValue("landingPageEducationTitleMsgTxt")}</h2>
									<h6>{Resources.getValue("landingPageEducationSubTitleMsgTxt")}</h6>
									<div className="trainer">
										<img src={akinbeyImage} alt="Akın Kağan AKÇALI" />
										<div className="trainer_wrapper">
											<div className="trainer_infos">
												<div className="trainer_name">Akın Kağan AKÇALI</div>
												<div className="trainer_role">{Resources.getValue("trainerMsgTxt")}</div>
												<div className="trainer_details">
													{Resources.getValue("trainerDetailSummaryMsgTxt")}
												</div>
												<a href="/education" className="see-more">
													<span>{Resources.getValue("seeMoreForTrainerMsgTxt")}</span>
												</a>
											</div>
											<div className="gradient-area"></div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="image-container">
									<div className="big">
										<video controls controlsList="nodownload" poster={videoCoverImg} preload="metadata" disablePictureInPicture>
											<source src={educationVideo} type="video/mp4" />
										</video>
									</div>
									<div className="small">
										<a href="/education"><img className="img-fluid" src={videoSS1} alt="Foramind" /></a>
										<a href="/education"><img className="img-fluid" src={videoSS2} alt="Foramind" /></a>
										<a href="/education" className="see-more">
											<span>{Resources.getValue("seeMoreForEducationMsgTxt")}</span>
											<i className="fa fa-arrow-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}

				<div id="features" className="triple-features">
					<div className="container relative-container">
						<div className="row triple-features-row">
							<div className="col-lg-4">
								<div className="feature feature-1"
									onMouseOver={e => this.readMore(e)} onMouseOut={e => this.lessMore(e)}
								>
									<div className="color-bg text-center">
										<img src={feature1} alt="Foramind, Zihin Haritası, Mind Map" />
										<img src={feature1Image} className="small-img" alt="Foramind, Zihin Haritası, Mind Map" />
									</div>
									<div className="text-wrapper">
										<h1>{Resources.getValue("landingPageFeature1TitleMsgTxt")}</h1>
										<div>
											<p>
												{Resources.getValue("landingPageFeature1Content1MsgTxt")} <br />
												{Resources.getValue("landingPageFeature1Content2MsgTxt")}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="feature feature-2"
									onMouseOver={e => this.readMore(e)} onMouseOut={e => this.lessMore(e)}
								>
									<div className="color-bg text-center">
										<img src={feature2} alt="Foramind, Zihin Haritası, Mind Map" />
										<img src={feature2Image} className="small-img" alt="Foramind, Zihin Haritası, Mind Map" />
									</div>
									<div className="text-wrapper">
										<h1>{Resources.getValue("landingPageFeature2TitleMsgTxt")}</h1>
										<div>
											<p>
												{Resources.getValue("landingPageFeature2Content1MsgTxt")} <br />
												{Resources.getValue("landingPageFeature2Content2MsgTxt")}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="feature feature-3"
									onMouseOver={e => this.readMore(e)} onMouseOut={e => this.lessMore(e)}
								>
									<div className="color-bg text-center">
										<img src={feature3} alt="Foramind, Zihin Haritası, Mind Map" />
										<img src={feature3Image} className="small-img" alt="Foramind, Zihin Haritası, Mind Map" />
									</div>
									<div className="text-wrapper">
										<h1>{Resources.getValue("landingPageFeature3TitleMsgTxt")}</h1>
										<div>
											<p>
												{Resources.getValue("landingPageFeature3Content1MsgTxt")} <br />
												{Resources.getValue("landingPageFeature3Content2MsgTxt")}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="triple-features double-wrapper">
					<div className="container">
						<div className="row double-features-row">
							<div className="col-lg-6">
								<div className="feature-wrap" 
								onMouseOver={e => this.readMoreTriple(e)} onMouseOut={e => this.lessMoreTriple(e)}>
									<div className="img-bg text-center">
										<img src={planImg} alt="Foramind, Zihin Haritası, Mind Map" />
									</div>
									<div className="text-wrapper">
										<h1>{Resources.getValue("landingPageFeaturesBasic2MsgTxt")}</h1>
										<div>
											<p>
												{Resources.getValue("landingPageFeaturesBasic2aTextMsgTxt")}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="feature-wrap" 
								onMouseOver={e => this.readMoreTriple(e)} onMouseOut={e => this.lessMoreTriple(e)}>
									<div className="img-bg text-center">
										<img src={brainStormingImg} alt="Foramind, Zihin Haritası, Mind Map" />
									</div>
									<div className="text-wrapper">
										<h1>{Resources.getValue("landingPageFeaturesBasic1MsgTxt")}</h1>
										<div>
											<p>
												{Resources.getValue("landingPageFeaturesBasic1TextMsgTxt")}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="foramind-counts">
					<div className="container">
						<div className="foramind-counts-wrap">
							<div className="member-count">
								<i className="icon-people"></i>
								<div className="title">
									{Resources.getValue("memberCountTitleMsgTxt")}
								</div>
								<div className="line"></div>
								<div className="count-info">
									{
										this.state.allCountOfUsersAndTeachersAndMaps
											? this.numberWithCommas(this.state.allCountOfUsersAndTeachersAndMaps.userCount)
											: 0
									}
								</div>
							</div>
							<div className="map-count">
								<i className="icon-maps"></i>
								<div className="title">
									{Resources.getValue("mapCountTitleMsgTxt")}
								</div>
								<div className="line"></div>
								<div className="count-info">
									{
										this.state.allCountOfUsersAndTeachersAndMaps
											? this.numberWithCommas(this.state.allCountOfUsersAndTeachersAndMaps.mapCount)
											: 0
									}
								</div>
							</div>
							<div className="template-count">
								<i className="icon-template-icon"></i>
								<div className="title">
									{Resources.getValue("templateCountTitleMsgTxt")}
								</div>
								<div className="line"></div>
								<div className="count-info">
									{
										this.state.allCountOfUsersAndTeachersAndMaps
											? this.numberWithCommas(this.state.allCountOfUsersAndTeachersAndMaps.templateCount)
											: 0
									}
								</div>
							</div>
							<div className="teacher-count none">
								<i className="icon-teacher"></i>
								<div className="title">
									{Resources.getValue("teacherCountTitleMsgTxt")}
								</div>
								<div className="line"></div>
								<div className="count-info">
									{
										this.state.allCountOfUsersAndTeachersAndMaps
											? this.numberWithCommas(this.state.allCountOfUsersAndTeachersAndMaps.teacherCount)
											: 0
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div id="references" className="references hero-bg">
					<div className="container">
						<div className="title">
							{Resources.getValue("landingPageReferencesMsgTxt")}
						</div>
						<div className="references-wrap">
							<div className="reference">
								<img src={bahcesehirLogo} alt="Foramind, Zihin Haritası, Mind Map" />
							</div>
							<div className="reference">
								<img src={bkmobilLogo} alt="BK Mobil" />
							</div>
						</div>
					</div>
				</div> */}

				<div id="price" className="pricing hero-bg">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<h2>{Resources.getValue("landingPageFeaturesPricingMsgTxt")}</h2>
							</div>
						</div>
						<div id="price-card-row" className="row price-card-row">
							<div className="col-lg-3 col-12 price-card">
								<div className="card">
									<div className="card-body">
										<div className="card-title">{Resources.getValue("landingPageFeaturesPricingCardBodyMsgTxt")}</div>
										<div className="card-subtitle">{Resources.getValue("landingPageFeaturesPricingCardBodyTextMsgTxt")}</div>
										<div className="price">
											<span className="many-value started-many">{Resources.getValue("startedManyMsgTxt")}</span>
											<div className="frequency started-many">{Resources.getValue("aMonthMsgTxt")}</div>
										</div>
										<ul className="landingPage-ulList li-space-lg">
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli1MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli2MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli3MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli4MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli5MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli6MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
										</ul>
										<div className="button-wrapper">
											<a className="discover-button page-scroll" href={Resources.getValue('appBaseEnvURL') + '/register'}>{Resources.getValue("landingPageSignUpMsgTxt")}</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-12 price-card">
								<div className="card">
									<div className="card-body">
										<div className="card-title">
											{Resources.getValue("mediumManyMsgTxt")}
											<img src={silverBadgeImg} alt={Resources.getValue("mediumManyMsgTxt")} />
										</div>
										<div className="card-subtitle">{Resources.getValue("mediumCardSubTitleMsgTxt")}</div>
										<div className="price">
											<span className="kdv-info">
												({Resources.getValue("kdvInfoForPackagesTextMsgTxt")})
											</span>
											<div className="price-wrap">
												{localStorage.getItem("siteLanguage") === 'en' && (
													<span className="currency">$</span>
												)}
												<span className="many-value month-many"></span>
												{localStorage.getItem("siteLanguage") === 'tr' && (
													<span className="currency">₺</span>
												)}
											</div>
											<div className="frequency">
												{Resources.getValue("montlyManyTimeMsgTxt")}&nbsp;
												<span className="card-monthly-info">
													({Resources.getValue("dayInfoForMonthlyPackageTextMsgTxt")})
												</span>
											</div>
										</div>
										<ul className="landingPage-ulList li-space-lg">
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli1MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli2MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli3MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli4MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli5MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli6MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
										</ul>
										<div className="button-wrapper">
											{(this.tokenExpire === false && localStorage.getItem('userInformation')) ? (
												<a className="discover-button page-scroll" href={Resources.getValue('appBaseEnvURL') + '/payment?selectedPackage=monthly'}>{Resources.getValue("buyMsgTxt")}</a>
											) : (
												<a className="discover-button page-scroll" href={Resources.getValue('appBaseEnvURL') + '/login?continueWith=payment'}>{Resources.getValue("buyMsgTxt")}</a>
											)
											}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-12 price-card">
								<div className="card best-card">
									<div className="label"
										style={{ backgroundImage: 'url(' + redBookmarkImg + ')' }}>
										<p className="best-value">{Resources.getValue("bestValueMsgTxt")}</p>
									</div>
									<div className="card-body">
										<div className="card-title">
											{Resources.getValue("completeManyMsgTxt")}
											<img src={goldBadgeImg} alt={Resources.getValue("completeManyMsgTxt")} />
										</div>
										<div className="card-subtitle">{Resources.getValue("completeCardSubtitleMsgTxt")}</div>
										<div className="price">
											<span className="kdv-info">
												({Resources.getValue("kdvInfoForPackagesTextMsgTxt")})
											</span>
											<div className="price-wrap">
												{localStorage.getItem("siteLanguage") === 'en' && (
													<span className="currency">$</span>
												)}
												<span className="many-value year-many"></span>
												{localStorage.getItem("siteLanguage") === 'tr' && (
													<span className="currency">₺</span>
												)}
											</div>
											<div className="frequency">{Resources.getValue("completedManyTimeMsgTxt")}</div>
										</div> 
										<ul className="landingPage-ulList li-space-lg">
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli1MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli2MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli3MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli4MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli5MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli6MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
										</ul>
										<div className="button-wrapper">
											{(this.tokenExpire === false && localStorage.getItem('userInformation')) ? (
												<a className="discover-button page-scroll" href={Resources.getValue('appBaseEnvURL') + '/payment?selectedPackage=yearly'}>{Resources.getValue("buyMsgTxt")}</a>
											) : (
												<a className="discover-button page-scroll" href={Resources.getValue('appBaseEnvURL') + '/login?continueWith=payment'}>{Resources.getValue("buyMsgTxt")}</a>
											)
											}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-12 price-card">
								<div className="card">
									<div className="card-body enterprise-subscription">
										<div className="card-title">
											{Resources.getValue("companySubscriptionApplyTitleMsgTxt")}
										</div>
										<div className="price">
											<div className="frequency">
												<a
												onClick={(e) => {
													e.preventDefault();
													localStorage.setItem("isCustomModalOpen", true);
													this.setState({
														isEnterpriseApplyModal: JSON.parse(
															localStorage.getItem("isCustomModalOpen")
														)
													});
												}}>{Resources.getValue("clickForApplyMsgTxt")}</a>
											</div>
										</div>
										<ul className="landingPage-ulList li-space-lg enterprice">
										<li className="media">
												<div className="section-description">{Resources.getValue("startedli7MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli8MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli9MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli10MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli1MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli2MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli3MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli4MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli5MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
											<li className="media">
												<div className="section-description">{Resources.getValue("startedli6MsgTxt")}</div>
												<i className="icon-check_circle"></i>
											</li>
										</ul>
										<div className="button-wrapper">
											<a className="discover-button" 
												onClick={(e) => {
													e.preventDefault();
													localStorage.setItem("isCustomModalOpen", true);
													this.setState({
														isEnterpriseApplyModal: JSON.parse(
															localStorage.getItem("isCustomModalOpen")
														)
													});
												}}
											>
												{Resources.getValue("applyMsgTxt")}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="contact" className="contact hero-bg">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<h2>
									{Resources.getValue("contactInformationMsgTxt")}
									<div className="landing-page-mail">
										<i className="i-icon fa fa-envelope"></i>
										<a className="landing-page-mail" href="mailto:info@zihinlerfora.com">info@zihinlerfora.com</a>
									</div>
								</h2>
							</div>
						</div>
					</div>
					<div className="container">
						<div id="contactForm">
							<form onSubmit={(e) => { e.preventDefault() }}>
								<div className="row ">
									<div className="col-md-6">
										<div className="row ">
											<div className="col-md-12">
												<div className="form-group">
													<input
														type="text"
														id="name"
														name="name"
														className="form-control-input validate-required"
														placeholder={Resources.getValue("firstnameMsgTxt")}
														maxLength="50"
													/>
												</div>
											</div>
										</div>
										<div className="row ">
											<div className="col-md-12">
												<div className="form-group">
													<input
														type="text"
														id="surname"
														name="surname"
														className="form-control-input validate-required"
														placeholder={Resources.getValue("lastnameMsgTxt")}
														maxLength="50"
													/>
												</div>
											</div>
										</div>
										<div className="row ">
											<div className="col-md-12">
												<div className="form-group">
													<input
														placeholder={'+90 ' + Resources.getValue("phonenumberMsgTxt")}
														className="form-control-input validate-required"
														id="phone"
														name="phone"
														data-minlength="18"
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<input
														type="text"
														id="email"
														name="email"
														className="form-control-input validate-required validate-email"
														placeholder={Resources.getValue("emailMsgTxt")}
														maxLength="50"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<textarea
														rows="4"
														cols="66"
														type="text"
														id="description"
														name="description"
														className="form-control-textarea validate-required"
														placeholder={Resources.getValue("description")}
														maxLength="500"
														title={Resources.getValue("description")}
													/>
												</div>
											</div>
										</div>
										<div className="row agr-row">
											<div className="col-md-12 mb-3">
												<div className="agreement-wrapper">
													<label className="register-checkbox container-checkbox register-label">
														<input
															type="checkbox"
															className="validate-required"
															id="checkbox"
															name="checkbox"
														/>
														<span className="checkmark"></span>
													</label>
													<span className="text">
														<b>
															<u>
																<a onClick={this.contactClarificationTextModal}>
																	{Resources.getValue("contactCheckboxClarifyAgreement")}
																</a>
															</u>
														</b>
														{" " + Resources.getValue("registerCheckboxAllowClarifyAgreement") + " "}
													</span>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<button
														className="form-control-submit-button button submit-form-button"
														type="submit"
														title={Resources.getValue("contactsendMsgTxt")}
														data-submit-method="contactSend"
													>
														{Resources.getValue("contactsendMsgTxt")}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className="feedbacks">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="top-area">
									<div className="titles">
										<h1 className="title">{Resources.getValue("landingPageFeedbacksTitleMsgTxt")}</h1>
									</div>
									<div className="stars-wrap">
										<p className="sub-title">{Resources.getValue("landingPageFeedbacksSubtitleMsgTxt")}</p>
										<div className="stars">
											{/* Custom star component for rating */}
											<div className="star-rating">
												{[...Array(5)].map((n, i) => (
													<Star
														key={i}
														selected={i < this.state.reviewRateValue}
														onClick={() => this.changeReviewRate(i + 1)}
													/>
												))}
											</div>
										</div>
									</div>
								</div>
								<div className="bottom-area">
									<div className="feedback-list">
										{this.state.allReviews.length ? this.state.allReviews.map(review => (
											<div className="feedback-list__item" key={review.reviewId}>
												<div className="d-flex flex-fill">
													{(review.hasProfileImage === true) ? (
														(review.hideMyProfileImage === false) ? (
															<div className="user-img"
																style={{ backgroundImage: "url(" + review.profileImagePath + ")", backgroundSize: 'cover' }}
															>
															</div>
														) : (
															<div className="user-img"
																style={{ backgroundColor: this.state.colorArray[Math.floor(Math.random() * this.state.colorArray.length)] }}
															>
																{
																	(review.nameSurname.split(' ').length === 2)
																		? review.nameSurname.split(' ')[0][0] + review.nameSurname.split(' ')[1][0]
																		: review.nameSurname.split(' ')[0][0] + review.nameSurname.split(' ')[2][0]
																}
															</div>
														)
													) : (
														<div className="user-img"
															style={{ backgroundColor: this.state.colorArray[Math.floor(Math.random() * this.state.colorArray.length)] }}
														>
															{review.profileImagePath}
														</div>
													)}
													<div className="right-wrap">
														{
															review.hideMyNameSurname === false ? (
																<div className="name">
																	{review.nameSurname} - {Utils.formatDateTimeWithoutTime(review.createdDate)}
																</div>
															) : (
																<div className="name">
																	{
																		(review.nameSurname.split(' ').length === 2)
																			? review.nameSurname.split(' ')[0][0] + '*** ' + review.nameSurname.split(' ')[1][0] + '***' + ' - ' + Utils.formatDateTimeWithoutTime(review.createdDate)
																			: review.nameSurname.split(' ')[0][0] + '*** ' + review.nameSurname.split(' ')[2][0] + '***' + ' - ' + Utils.formatDateTimeWithoutTime(review.createdDate)
																	}
																</div>
															)
														}
														{review.title ? (
															<div className="title">{review.title}</div>
														) : ''}
														<div className="text-area">
															<div className="text">
																{review.description}
															</div>
															<div className="date none">
																{Utils.formatDateTimeWithoutTime(review.createdDate)}
															</div>
														</div>
													</div>
												</div>
												<div className="star-area">
													<ReactStars {...{
														size: 20,
														count: 5,
														color: "#fcbc2c",
														activeColor: "#fcbc2c",
														value: review.star,
														edit: false,
														a11y: true,
														emptyIcon: <i className="fa-star-o" />,
														filledIcon: <i className="fa fa-star" />
													}} />
												</div>
											</div>
										)) : (
											<div className="pb-3">
												{Resources.getValue('noReviewMsgTxt')}
											</div>
										)}
										<div className="gradient-area"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	};
}

export default LandingPage;
