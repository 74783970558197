import React, { Component } from "react";
import Utils from "../libraries/utils";
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import Resources from "../libraries/resources";
import ChatGptPaymentService from "../services/api/chat-gpt-payment";
import SubscriptionInformationModal from "../helpers/subscription-information-modal";
import ReactTable from "react-table";
import "react-table/react-table.css";
// Modals
import ErrorModal from "../components/modals/error-modal";
import SuccessModal from "../components/modals/success-modal";

class ChatGptSubscriptionDetail extends Component {
  constructor(props) {
    super(props);

    if (
      JSON.parse(localStorage.getItem("c65s1")) !== null &&
      JSON.parse(localStorage.getItem("c65s1")).companyRemainingProductDays > 0
    ) {
      window.location.href =
        Resources.getValue("appBaseEnvURL") + "/mind-map-list";
    }

    localStorage.setItem("isCustomModalOpen", false);

    this.state = {
      subscriptionData: null,
      isSubscriptionInformationModal: JSON.parse(
        localStorage.getItem("isCustomModalOpen")
      ),
      informationPopupContent: {},
    };
  }

  componentDidMount() {
    document.title =
      "Foramind | " + Resources.getValue("subscriptionGptDetailMsgTxt");
    this.getSubscriptionInformations();

  }

  getSubscriptionInformations() {
    ChatGptPaymentService.userSubscriptionDetail(this.fillUserSubscriptionInfos, this, JSON.parse(localStorage.getItem("userInformation")).id);
  }

  fillUserSubscriptionInfos() {
    var _this = this;
    var userSubscription = this.response;

    if (userSubscription) {
     _this.scope.setState({
        subscriptionData: JSON.parse(userSubscription)
      }); 

    /**   localStorage.getItem("wq0wdbr9aq01xxky05h1l8") &&
        _this.scope.checkPayment();  */
    }
  }

  sharedClick = (isOpen) => {
    this.setState({
      isSubscriptionInformationModal: isOpen,
    });
  };
  openInfosOnPopup(e) {
    localStorage.setItem("isCustomModalOpen", true);
    this.setState({
      isSubscriptionInformationModal: JSON.parse(
        localStorage.getItem("isCustomModalOpen")
      ),
    });
  }

  // after payment process check payment status

  render() {

    const subscriptionData = this.state?.subscriptionData?.data || [];
    const columns = [
      {
        Header: Resources.getValue("PackageName"),
        accessor: "name",
      },
      {
        Header: Resources.getValue("PackageMapNumbers"),
        accessor: "numberOfMaps",
        Cell: ({ value }) => (
          <span>
            {value} <span>{Resources.getValue("numberMaps")}</span>
          </span>
        ),
      },
      {
        Header: Resources.getValue("PackageBuyDate"),
        accessor: "buyDate",
        Cell: ({ value }) => Utils.formatDateTimeWithoutTime(value),
      },
      {
        Header: Resources.getValue("PackagePrice"),
        accessor: "price",
        Cell: ({ value, row }) => {
          console.log("Row Object:", row);
         return( <span>
            {row?._original?.currency === "USD" && <span className="currency"> $</span>}
            {value}
            {row?._original?.currency === "TRY" && <span className="currency"> ₺</span>}
          </span>)
         },
      },
    ];

    return (
      <React.Fragment>
        <Header />
        {this.state.isSubscriptionInformationModal ? (
          <SubscriptionInformationModal
            sharedClick={this.sharedClick}
            popupContent={this.state.informationPopupContent}
          />
        ) : null}
        <div className="dashboard-page mindmap-table wide">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="template-panel subscription-detail">
                  <div className="d-flex justify-content-between align-items-center px-3 w-100">
                    <div>
                      <SubHeader
                        title={Resources.getValue("subscriptionGptDetailMsgTxt")}
                        iconName="icon-verified"
                      />
                    </div>
                    <a
                      className="back-to-template-list d-none"
                      href={Resources.getValue("appBaseEnvURL") + "/payment"}
                    >
                      {" " + Resources.getValue("goPaymentPagekMsgTxt")}
                    </a>
                  </div>
                  <div className="file-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="infos">
                        {this.state?.subscriptionData?.data?.length > 0 ? (
                          <ReactTable
                          data={subscriptionData}
                          columns={columns}
                          defaultPageSize={10}
                          className="-striped -highlight"
                          noDataText={Resources.getValue("noDataTextMsgTxt")}
                        />
                        ) :  <ReactTable
                        columns={columns}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        noDataText={Resources.getValue("noDataTextMsgTxt")}
                      />}  
                          {/* abonelik varsa */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChatGptSubscriptionDetail;
