import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";

var MyPlannerService = {
  getPlanList: function (recordSize, id, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url:
        Resources.getValue("plannerListApiURL") +
        "?id=" +
        id +
        "&recordSize=" +
        recordSize,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MyPlannerService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          MyPlannerService.openWarningModal();
        } else {
          MyPlannerService.openErrorModal();
        }
      }
    };
  },

  delete: function (plannerId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "DELETE",
      token: "Bearer",
      url:
        Resources.getValue("myPlannerDeleteByIdApiURL") +
        "?plannerId=" +
        plannerId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MyPlannerService.openErrorModal();
    };
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(_this);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          MyPlannerService.openWarningModal();
        } else {
          MyPlannerService.openErrorModal();
        }
      }
    };
  },

  updateMyPlannerName: function (plannerId, myPlannerName, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "PUT",
      token: "Bearer",
      url:
        Resources.getValue("myPlannerUpdateApiURL") +
        "?plannerId=" +
        plannerId +
        "&name=" +
        myPlannerName,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      MyPlannerService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          MyPlannerService.openWarningModal();
        } else if (xhr.status == 409) {
          MyPlannerService.openExistNameErrorModal();
        } else {
          MyPlannerService.openErrorModal();
        }
      }
    };
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openExistNameErrorModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("existNameErrorModalMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "/my-planner-list",
        },
      ],
    });
  },
};

export default MyPlannerService;
