import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";

const SharedMapService = {
  getSharedMap: function (userId, mapId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url:
        Resources.getValue("sharedWithMeMind") +
        "?userId=" +
        userId +
        "&mindMapId=" +
        mapId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
            if (responseData.sharedMindMapMail) {
              localStorage.setItem(
                "content",
                responseData.sharedMindMapMail.content
              );
              localStorage.setItem(
                "backgroundName",
                responseData.sharedMindMapMail.backgroundName
              );
              localStorage.setItem("userIsOwner", false);
            }
          } else {
            // tum paylasilan mapler icin kontrol
            SharedMapService.openNotAuthorizedWarningModal();
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          } else {
            SharedMapService.openWarningModal();
          }
        } else {
          SharedMapService.openErrorModal();
        }
      }
    };
  },

  getMindMapSharedByUserIdAsync: function (
    userId,
    recordSize,
    callback,
    _this
  ) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url:
        Resources.getValue("sharedWithMeMindList") +
        "?id=" +
        userId +
        "&recordSize=" +
        recordSize,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          } else {
            SharedMapService.openWarningModal();
          }
        } else {
          SharedMapService.openErrorModal();
        }
      }
    };
  },

  getPublicMindMapById: function (mindMapId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      url:
        Resources.getValue("getPublicMindMapByIdApiURL") +
        "?mindMapId=" +
        mindMapId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.setItem("openedMapName", responseData.mindMap.name);
            localStorage.setItem(
              "mapPermission",
              responseData.mindMap.mapPermissionId
            );
            document.querySelector(".editor-header-tools").innerText =
              localStorage.getItem("openedMapName");
            localStorage.setItem(
              "isMapPublicControl",
              responseData.mindMap.isPublicMap
            );
            localStorage.setItem(
              "isMapPublic",
              responseData.mindMap.isPublicMap
            );
            callback.apply(xhr);
            if (
              (localStorage.getItem("userInformation") &&
                responseData.mindMap.createdBy !==
                  JSON.parse(localStorage.getItem("userInformation")).id) ||
              localStorage.getItem("userInformation") === null
            ) {
              // public ozelligi degistirildiyse, artik public degilse (baskasi actiysa)
              if (responseData.mindMap.isPublicMap === false) {
                document.querySelector("#port").style.display = "none";
                SharedMapService.openMapIsNotPublicWarningModal();
              }
            } else if (
              localStorage.getItem("userInformation") &&
              responseData.mindMap.createdBy ===
                JSON.parse(localStorage.getItem("userInformation")).id
            ) {
              // public ozelligi degistirildiyse, artik public degilse (map sahibi actiysa)
              if (responseData.mindMap.isPublicMap === false) {
                SharedMapService.openMapIsNotPublicButItsYoursWarningModal();
              }
            }
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          } else {
            SharedMapService.openWarningModal();
          }
        } else {
          SharedMapService.openErrorModal();
        }
      }
    };
  },

  getSharedAnonymousMindMapAsync: function (
    privateKey,
    email,
    mindMapId,
    callback,
    _this
  ) {
    const xhr = new DataService.createXHR({
      type: "GET",
      url:
        Resources.getValue("getSharedAnonymousMindMapAsync") +
        "?privateKey=" +
        privateKey +
        "&email=" +
        email +
        "&mindMapId=" +
        mindMapId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.setItem(
              "openedMapName",
              responseData.sharedMindMapMail.mindMapName
            );
            document.title = localStorage.getItem("openedMapName");
            localStorage.setItem(
              "IsAnonymous",
              responseData.sharedMindMapMail.isAnonymous
            );
            localStorage.setItem("openedMapId", mindMapId);
            if (
              localStorage.getItem("userInformation") === null ||
              localStorage.getItem("userInformation") === undefined
            ) {
              window.location.href =
                Resources.getValue("appBaseEnvURL") + "/login";
            }
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          }
          if (
            (responseData.errors.length &&
              responseData.errors[0] ===
                "You are not authorized to view the map.")
          ) {
            // --- farkli kullanici ile giris yapildiysa
            SharedMapService.openNotAuthorizedWarningModal();
          } else if (
            responseData.errors.length &&
            responseData.errors[0].includes("Cannot find Mind Map Id =")
          ) {
            // -- harita bulunamadi (silinmis / yetki kaldirilmis)
            SharedMapService.openNotFindMapWarningModal();
          }
        } else {
          // SharedMapService.openErrorModal();
        }
      }
    };
  },

  delete: function (mindMapId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "DELETE",
      token: "Bearer",
      url:
        Resources.getValue("deleteSharedMindMapFile") +
        "?mindMapId=" +
        mindMapId,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(_this);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          } else {
            SharedMapService.openWarningModal();
          }
        } else {
          SharedMapService.openErrorModal();
        }
      }
    };
  },

  hasMindMapPermisionForUser: function (mapId, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url:
        Resources.getValue("hasMindMapPermisionForUser") +
        "?MindMapId=" +
        mapId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result === true) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          } else if (responseData.result === false) {
            return false;
            // Kullanicinin duzenleme yetkisi olmadiginda uyari gostermiyoruz, yalnizca haritayi aciyoruz.
          } else {
            SharedMapService.openWarningModal();
          }
        } else {
          SharedMapService.openErrorModal();
        }
      }
    };
  },

  saveMap: function (data, mapName) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("shareMindMapToUser"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            window.location.href =
              Resources.getValue("appBaseEnvURL") + "/mind-map-share-list";
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          } else {
            SharedMapService.openWarningAnonymousModal();
          }
        } else if (xhr.status == 409) {
          SharedMapService.openExistNameErrorModal(mapName);
        } else {
          SharedMapService.openErrorModal();
        }
      }
    };
  },

  // save copy with name change
  saveACopyOfMap: function (data, mapName) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("saveACopyOfMap"),
      data: JSON.stringify(data),
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            window.location.href = Resources.getValue('appBaseEnvURL') + "/mind-map-list";
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          } else {
            SharedMapService.openWarningAnonymousModal();
          }
        } else if (xhr.status == 409) {
          SharedMapService.openExistNameErrorModal(mapName);
        } else {
          SharedMapService.openErrorModal();
        }
      }
    };
  },

  saveACopyOfMapForPublicMap: function (data, mapName) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("saveACopyOfMapForPublicLink"),
      data: JSON.stringify(data),
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            window.location.href = Resources.getValue('appBaseEnvURL') + "/mind-map-list";
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          } else {
            if(
              responseData.errors.length > 0 && 
              responseData.errors[0] === "There is a registered map with this name"
            ) {
              SharedMapService.sameNameExistForPublicCopyModal();
            } else {
              SharedMapService.openWarningAnonymousModal();
            }
          }
        } else if (xhr.status == 409) {
          SharedMapService.openExistNameErrorModal(mapName);
        } else {
          SharedMapService.openErrorModal();
        }
      }
    };
  },

  addMindMapToMyAccount: function (data, mapName) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("addMindMapToMyAccountApiURL"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      SharedMapService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            window.location.href =
              Resources.getValue("appBaseEnvURL") + "/mind-map-list";
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.statusCode == 402) {
            SharedMapService.paymentStatusCode();
          } else {
            SharedMapService.openWarningAnonymousModal();
          }
        } else if (xhr.status == 409) {
          SharedMapService.openExistNameErrorModal(mapName);
        } else {
          SharedMapService.openErrorModal();
        }
      }
    };
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openWarningAnonymousModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("anonymousSharedUserMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/mind-map-list",
        },
      ],
      redirectAfterClose:
        Resources.getValue("appBaseEnvURL") + "/mind-map-list",
    });
  },

  sameNameExistForPublicCopyModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("publicMapCopyWarningMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
      redirectAfterClose: "",
    });
  },

  openExistNameErrorModal: function (mapName) {
    let changeUrl;
    if (mapName === "createMap") {
      changeUrl = "/template-list";
    } else {
      changeUrl = "/mind-map-list";
    }

    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("existNameErrorModalMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + changeUrl,
        },
      ],
    });
  },

  openMapIsNotPublicWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("mapIsNotPublicInfoMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("goToMyMapsMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/mind-map-list",
        },
      ],
    });
  },

  openMapIsNotPublicButItsYoursWarningModal: function () {
    WarningModal({
      title: Resources.getValue("modalInfoTitleMsgTxt"),
      message:
        "<p>" +
        Resources.getValue("mapIsNotPublicButItsYoursInfoMsgTxt") +
        "</p>",
    });
  },

  openNotAuthorizedWarningModal: function () {
    WarningModal({
      title: Resources.getValue("modalTitleInfoMsgTxt"),
      message: "<p>" + Resources.getValue("youCanNotSeeThisMapMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("goToMyMapsMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/mind-map-list",
        },
      ],
    });
  },

  openNotFindMapWarningModal: function () {
    WarningModal({
      title: Resources.getValue("modalTitleInfoMsgTxt"),
      message: "<p>" + Resources.getValue("youNotFindMapMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("goToMyMapsMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/mind-map-list",
        },
      ],
    });
  }
};

export default SharedMapService;
