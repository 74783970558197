import React, { Component } from "react";
import Resources from "../libraries/resources";
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Utils from "../libraries/utils";
import PaymentService from "../services/api/payment";

class SubscriptionHistory extends Component {

  constructor(props) {
    super(props)

    if(
      JSON.parse(localStorage.getItem('c65s1')) !== null &&
      JSON.parse(localStorage.getItem('c65s1')).companyRemainingProductDays > 0
    ) {
      window.location.href = Resources.getValue('appBaseEnvURL') + '/mind-map-list'
    }

    this.state = {
      data: [],
    }
  };

  componentDidMount() {
    document.title = "Foramind | " + Resources.getValue("subscriptionHistoryPageTitleMsgTxt");
    this.getSubscriptionList();
  };

  getSubscriptionList = () => {
    var callback = this.fillSubscriptionHistory;
    PaymentService.userSubscriptionHistory(callback, this);
  };

  fillSubscriptionHistory() {
    var _this = this;
    const subscriptionList = JSON.parse(this.response).userSubscriptionList;

    localStorage.setItem('requestIsDone', false);

    var listedItems = document.querySelectorAll('.table-list');
    if (listedItems.length > 0) {
      _this.scope.setState({
        data: []
      })
    }

    if (subscriptionList) {
      for (var i = 0; i < subscriptionList.length; i++) {
        if (subscriptionList[i].creationDate)
          subscriptionList[i].creationDate = subscriptionList[i].creationDate
      }
      _this.scope.setState({
        data: JSON.parse(JSON.stringify(subscriptionList))
      })
    }
  };

  renderCreationDateColumn = (cellInfo) => {
    return (
      <div>
        {Utils.formatDateTimeWithoutTime(cellInfo.original.creationDate)}
      </div>
    );
  }

  renderStartEndDateColumn = (cellInfo) => {
    return (
      <div>
        {Utils.formatDateTimeWithoutTime(cellInfo.original.startDate)} - {Utils.formatDateTimeWithoutTime(cellInfo.original.endDate)}
      </div>
    );
  }

  renderPriceColumn = (cellInfo) => {
    return (
      <div>
        {cellInfo.original.currency === 'TRY' 
        ? (cellInfo.original.productPrice + '₺') 
        : (('$' + cellInfo.original.productPrice))}
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="mindmap-table wide">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="template-panel subscription-history">
                  <div className="title-wrapper">
                    <div className="d-flex justify-content-between align-items-center px-3 w-100">
                      <div>
                        <SubHeader title={Resources.getValue("subscriptionHistoryPageTitleMsgTxt")} />
                      </div>
                      <a
                        className="back-to-template-list"
                        href={Resources.getValue('appBaseEnvURL') + '/subscription-detail'}
                        style={{ width: '200px' }}
                      >
                        <i className="icon icon-arrow-back p-0"></i> 
                        {' ' + Resources.getValue('backToSubscriptionDetailMsgTxt')}
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="col-md-12 px-3 pb-3">
                      <div className="table-list layout-option-content show">
                        <div
                          className="sub-list show"
                        ><div className="map-table subscription-map-table">
                        <ReactTable
                        data={this.state.data}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                          String(row[filter.accessor]) === filter.value}
                        previousText={Resources.getValue("previousTextMsgTxt")}
                        nextText={Resources.getValue("nextTextMsgTxt")}
                        pageText={Resources.getValue("pageTextMsgTxt")}
                        rowsText={Resources.getValue("rowsTextMsgTxt")}
                        ofText={Resources.getValue("ofTextMsgTxt")}
                        noDataText={Resources.getValue("noDataTextMsgTxt")}
                        loadingText={Resources.getValue("loadingTextMsgTxt")}
                        defaultFilterMethod={(filter, row) =>
                          String(row[filter.id]) === filter.value}
                        onPageChange={(data) => {
                          this.setState({
                            listPageCurrent: data
                          });
                        }}
                        page={this.state.listPageCurrent ? this.state.listPageCurrent : undefined}

                        const columns={[
                          {
                            columns: [
                              {
                                Header: Resources.getValue("actionDateMsgTxt"),
                                accessor: "creationDate",
                                Cell: this.renderCreationDateColumn,
                                className: "action-date-column",
                              }]
                          },
                          {
                            columns: [
                              {
                                Header: Resources.getValue("subscriptionStartDateMsgTxt") + ' - ' + Resources.getValue("subscriptionEndDateMsgTxt"),
                                Cell: this.renderStartEndDateColumn,
                                className: "subscription-date-column",
                              }]
                          },
                          {
                            columns: [
                              {
                                Header: Resources.getValue("totalMsgTxt"),
                                Cell: this.renderPriceColumn,
                                className: "total-column",
                                filterable: false,
                              }]
                          }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                      />
                        </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default SubscriptionHistory;
