import React, { Component } from "react";
import educationPageBannerImg from "../styles/img/education-page-banner-img.png";
import trainerImg from "../styles/img/education-trainer-img.jpg";
import mindMapRulesSampleImgTR from "../styles/img/zihin-haritalarinin-kurallari-cover.png";
import mindMapRulesVideoTR from "../styles/img/zihin-haritalarinin-kurallari.mp4";
import mindMapRulesSampleImgEN from "../styles/img/zihin-haritalarinin-kurallari-cover-EN.png";
import mindMapRulesVideoEN from "../styles/img/zihin-haritalarinin-kurallari-EN.mp4";
import educationMapSampleImg from "../styles/img/map-sample-img2.png";
import udemyImgTR from "../styles/img/udemy-img-TR.PNG";
import udemyImgEN from "../styles/img/udemy-img-EN.PNG";
import zihinlerForaImg from "../styles/img/zihinler-fora.jpg";
import zihinHaritaDernegiImg from "../styles/img/zihin-haritasi-dernegi.jpg";
import Resources from "../libraries/resources";
import NavMenu from "../components/nav-menu";
import Footer from "../components/footer";
import {Helmet} from "react-helmet";

class EducationPage extends Component {

	constructor() {
		super();

		this.menuItems = [
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/' : '/',
				title: Resources.getValue("landingPageHomeMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '',
				title: Resources.getValue("landingPageCorporateMsgTxt"),
				activeClass: 'not-active',
				externalLink: false,
				subLinks: [
					{
						href: '/about',
						title: Resources.getValue("landingPageAboutMsgTxt"),
						activeClass: '',
						externalLink: true
					},
					// {
					// 	href: '#references',
					// 	title: Resources.getValue("landingPageReferencesMsgTxt"),
					// 	activeClass: '',
					// 	externalLink: true
					// },
					{
						href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#contact' : '/' + '#contact',
						title: Resources.getValue("landingPageContactMsgTxt"),
						activeClass: '',
						externalLink: true
					}
				]
			},
			// {
			// 	href: '/features',
			// 	title: Resources.getValue("landingPageFeaturesMsgTxt"),
			// 	activeClass: '',
			// 	externalLink: true
			// },
			{
				href: '/education',
				title: Resources.getValue("landingPageEducationMsgTxt"),
				activeClass: 'home-color-set active',
				externalLink: true
			},
			{
				href: '/blog',
				title: "Blog",
				activeClass: '',
				externalLink: true
			},
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#price' : '/' + '#price',
				title: Resources.getValue("landingPagePriceMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/kvkk',
				title: Resources.getValue("landingPageKavkkMsgTxt"),
				activeClass: '',
				externalLink: true
			}
		];
	}

	componentDidMount() {
		// document.title = "Foramind | " + Resources.getValue("landingPageEducationMsgTxt");
	}

	render() {
		let udemyURL = '';
		Resources.siteLanguage === 'en'
			? udemyURL = "https://www.udemy.com/course/for-a-mind-learn-to-learn-with-mind-mapping/?referralCode=6ED9E8F5CBB7C394F92E"
			: udemyURL = "https://www.udemy.com/course/zihinlerfora-zihin-haritalari-ile-ogrenmeyi-ogrenelim/?referralCode=2B564D86FD568B8BD5A7";

		return (
			<div className="landing-page" id="landing-page">
				<Helmet>
				<meta name="description" content="Zihin Haritası ile Öğrenme - Türkiye’nin ilk ve tek Zihin Haritası Yazılımı olan Foramind ile öğrenme süreçlerinizde zihin haritalama tekniklerini kullanabilirsiniz. 15 Gün Ücretsiz Deneyebilirsiniz." />
  				<meta name="keywords" content="foramind nedir, zihin haritası yazılımı, zihin haritası ile öğrenme , foramind, mind mapping software, zihin haritalama teknikleri" />
                <title>Zihin Haritası ile Öğrenme - ForaMind – Zihin Haritası Yazılımı</title>
            </Helmet>
				<NavMenu menuItems={this.menuItems} />
				<div className="education-page">
					<div id="educationBanner" className="education-banner">
						<div className="banner">
							<div className="container">
								<div className="row">
									<div className="col-lg-6">
										<div className="text-container">
											<h1>{Resources.getValue("educationPageMainTitleMsgTxt")}</h1>
											{/* <a href="#educationInfos" className="nav-link down-btn-orange">
												<i className="icon-down-arrow"></i>
												<span>{Resources.getValue("educationPageClickForTrainer1MsgTxt")}</span>
											</a> */}
										</div>
									</div>
									<div className="col-lg-6">
										<div className="image-container">
											<img alt="Foramind, Zihin Haritası, Mind Map" className="img-fluid" src={educationPageBannerImg} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="educationInfos" className="education-infos">
						<div className="container">
							{/* <h1>{Resources.getValue("educationPageEducationTitleMsgTxt")}</h1> */}
							<div className="text-wrapper">
								<div className="img-wrapper">
									<img src={educationMapSampleImg} alt="Foramind, Zihin Haritası, Mind Map" />
								</div>
								<p className="mb-4">
									{Resources.getValue("educationPageEducationInfoPart1MsgTxt")}
								</p>
								<p className="mb-4">
									{Resources.getValue("educationPageEducationInfoPart2MsgTxt")}
								</p>
								<p className="mb-4">
									{Resources.getValue("educationPageEducationInfoPart3MsgTxt")}
								</p>
								<p className="mb-4">
									{Resources.getValue("educationPageEducationInfoPart4MsgTxt")}
								</p>
								<p>
									{Resources.getValue("educationPageEducationInfoPart5MsgTxt")}
								</p>
							</div>
						</div>
						<div className="container none">
							<div className="row">
								<div className="col-12">
									<h1>{Resources.getValue("educationPageTrainerInfosMsgTxt")}</h1>
								</div>
								<div className="col-12">
									<div className="education-infos-wrap">
										<div className="image-wrap">
											<img src={trainerImg} alt="Akın Kağan AKÇALI" />
											<div>
												<h6>Akın Kağan AKÇALI</h6>
												<p>{Resources.getValue("trainerMsgTxt")}</p>

												<div className="logos">
													<img src={zihinlerForaImg} alt="Zihinler Fora" />
													<img src={zihinHaritaDernegiImg} alt="Türkiye Zihin Haritaları Derneği" />
												</div>
											</div>
										</div>
										<div className="text-wrap">
											<p>
												{Resources.getValue("educationPageTrainerCVPart1MsgTxt")}
											</p>
											<p>
												{Resources.getValue("educationPageTrainerCVPart2MsgTxt")}
											</p>
											<p>
												<span>
													{Resources.getValue("educationPageTrainerCVPart3MsgTxt")}
												</span>
											</p>
											<p>
												{Resources.getValue("educationPageTrainerCVPart4MsgTxt")}
											</p>
											<p className="mb-0">
												{Resources.getValue("educationPageTrainerCVPart5MsgTxt")}
											</p>
											<p>
												{Resources.getValue("educationPageTrainerCVPart6MsgTxt")}
											</p>
											<p className="mb-0">
												<span>
													{Resources.getValue("educationPageTrainerCVPart7MsgTxt")}
												</span>
											</p>
											<p>
												{Resources.getValue("educationPageTrainerCVPart8MsgTxt")}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="educationRules" className="education-rules">
						<div className="education-rules-wrapper">
							<div className="container">
								<h1>{Resources.getValue("educationPageMMRulesTitleMsgTxt")}</h1>
								<div className="text-wrapper">
									<div className="img-wrapper">
										{Resources.siteLanguage === 'en' ? (
											<video controls controlsList="nodownload" poster={mindMapRulesSampleImgEN} preload="metadata" disablePictureInPicture>
												<source src={mindMapRulesVideoEN} type="video/mp4" />
											</video>
										) : (
											<video controls controlsList="nodownload" poster={mindMapRulesSampleImgTR} preload="metadata" disablePictureInPicture>
												<source src={mindMapRulesVideoTR} type="video/mp4" />
											</video>
										)}
									</div>
									<p className="mb-4">
										{Resources.getValue("educationPageMMRulesPart1MsgTxt")}
									</p>
									<p className="mb-4">
										{Resources.getValue("educationPageMMRulesPart2MsgTxt")}
									</p>
									<p className="mb-4">
										{Resources.getValue("educationPageMMRulesPart3MsgTxt")}
									</p>
									<p className="mb-4">
										{Resources.getValue("educationPageMMRulesPart4MsgTxt")}
									</p>
									<p>
										{Resources.getValue("educationPageMMRulesPart5MsgTxt")}
									</p>
									<p>
										{Resources.getValue("educationPageMMRulesPart6MsgTxt")}
									</p>
									<p>
										{Resources.getValue("educationPageMMRulesPart7MsgTxt")}
									</p>
								</div>
							</div>
						</div>
						<div className="education-info-wrapper none">
							<div className="container">
								<h1>{Resources.getValue("educationPageEducationTitleMsgTxt")}</h1>
								<div className="text-wrapper">
									<div className="img-wrapper">
										<img src={educationMapSampleImg} alt="Foramind, Zihin Haritası, Mind Map" />
									</div>
									<p className="mb-4">
										{Resources.getValue("educationPageEducationInfoPart1MsgTxt")}
									</p>
									<p className="mb-4">
										{Resources.getValue("educationPageEducationInfoPart2MsgTxt")}
									</p>
									<p className="mb-4">
										{Resources.getValue("educationPageEducationInfoPart3MsgTxt")}
									</p>
									<p className="mb-4">
										{Resources.getValue("educationPageEducationInfoPart4MsgTxt")}
									</p>
									<p>
										{Resources.getValue("educationPageEducationInfoPart5MsgTxt")}
									</p>
								</div>
							</div>
						</div>
					</div>
					
					<div className="udemy-link-wrapper none">
						<div className="container-fluid">
							<div className="text-wrapper text-center">
								{Resources.getValue("educationPageUdemyOrientationMsgTxt")} {' '}
								<a href={udemyURL} target="_blank">{Resources.getValue("clickThisMsgTxt")}</a>
							</div>
							<div className="img-wrapper text-center">
								<a href={udemyURL} target="_blank">
									{Resources.siteLanguage === 'en' ? (
										<img src={udemyImgEN} alt={Resources.getValue("udemyCourseNameMsgTxt")} />
									) : (
										<img src={udemyImgTR} alt={Resources.getValue("udemyCourseNameMsgTxt")} />
									)}
								</a>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	};
}

export default EducationPage;
