import React, { Component } from "react";

import Header from "../components/header";

import SubHeader from "../components/sub-header";

import Resources from "../libraries/resources";

import TemplateListService from "../services/api/template-list";

import Utils from "../libraries/utils";

import MapService from "../services/api/map";

import AddCategoryModal from "../helpers/add-category-modal";

import ChatGptService from "../services/api/chatgpt-map-create";

import AiPackages from "../services/api/ai-packages";

import DocumentsServices from "../services/api/document";

// images

import blankTemplateLogo from "../styles/img/plus-icon.png";

import newCategoryCover from "../styles/img/new-category-cover.png";

import moreOptionsIcon from "../styles/img/more-options-icon.png";

import gptModalImage from "../styles/img/gpt-modal.png";

import robotImage from "../styles/img/Robot.png";

import closeImage from "../styles/img/close.png";

import "../styles/css/template-list.scss";

class TemplateList extends Component {
  constructor(props) {
    super(props);

    this.createChatGptMap = this.createChatGptMap.bind(this);

    localStorage.removeItem("selectedTemplateId");

    localStorage.removeItem("selectedTemplateName");

    localStorage.removeItem("deletedTemplateCategoryId");

    localStorage.setItem("isCustomModalOpen", false);

    this.state = {
      templateData: [],

      selectedCategoryName: "",

      isAddCategoryModal: JSON.parse(localStorage.getItem("isCustomModalOpen")),

      isUpdateProcess: false,

      isUserHasPermissionForOptions: false,

      tags: [],

      documents: [],

      inputModal: false,

      gptSubject: "",

      chatGptMapLanguage: Resources.siteLanguage,

      isLanguageTurkish: false,

      isLanguageEnglish: false,

      gptMapNumbers: 0,

      isDocument: false,

      documentId: null,

      userRole: JSON.parse(localStorage.getItem("userRoleIdList")),
    };
  }

  componentDidMount() {
    document.title = "Foramind | " + Resources.getValue("createMindMapMsgTxt");

    this.getTemplateList();

    const userId = JSON.parse(localStorage.getItem("userInformation")).id;
    const componyId = JSON.parse(
      localStorage.getItem("userInformation")
    ).companyId;
    AiPackages.getMapNumbers(userId).then((responseData) => {
      this.setState({ gptMapNumbers: responseData });
    });

    DocumentsServices.getDocuments(componyId).then((responseData) => {
      this.setState({ documents: responseData });
    });

    // user role control for edilt/delete permission

    if (localStorage.getItem("userRoleIdList")) {
      JSON.parse(localStorage.getItem("userRoleIdList")).forEach((role) => {
        if (role === 3 || role === 1 || role === 4) {
          this.setState({
            isUserHasPermissionForOptions: true,
          });
        }
      });
    }
  }

  openChatGptModal = () => {
    this.setState({ inputModal: true });

    if (this.state.chatGptMapLanguage === "tr") {
      this.setState({ isLanguageTurkish: true });
    }
    if (this.state.chatGptMapLanguage === "en") {
      this.setState({ isLanguageEnglish: true });
    }
  };

  handleClose = () => {
    this.setState({
      inputModal: false,
      gptSubject: "",
      isDocument: false,
      documentId: null,
    });

    this.setState({ chatGptMapLanguage: Resources.siteLanguage });
  };

  documentSwitchHandle = (value) => {
    if (this.state.isDocument === true) this.setState({ documentId: null });
    this.setState({ isDocument: value });
  };

  getTemplateList = () => {
    var recordSize = 1000;

    var languageId = Resources.siteLanguage;

    TemplateListService.getTemplateList(
      recordSize,
      this.fillUserTemplatelist,
      languageId,
      this.state.tags,
      this
    );
  };

  fillUserTemplatelist() {
    var _this = this;

    var userTemplatelist = JSON.parse(this.response).templateList;

    if (userTemplatelist) {
      _this.scope.setState({
        templateData: JSON.parse(JSON.stringify(userTemplatelist)),
      });
    }
  }

  goToSubTamplatesEvent(id, name) {
    localStorage.setItem("selectedTemplateId", id);

    localStorage.setItem("selectedTemplateName", name);

    this.goToSubTemplatesListPage(name, id);
  }

  goToSubTemplatesListPage(templateData, templateId) {
    if (templateData !== "yeni") {
      localStorage.setItem("selectedTemplateId", templateId);

      window.location.href =
        Resources.getValue("appBaseEnvURL") + "/sub-template-list";
    }
  }

  sharedClick = (isOpen) => {
    this.setState({
      isAddCategoryModal: isOpen,

      isUpdateProcess: isOpen,
    });
  };

  newMap(templateData, templateName) {
    if (templateData == "yeni") {
      localStorage.removeItem("openedMapName");

      localStorage.removeItem("openedMapId");

      localStorage.setItem("mapTemplate", templateData);

      localStorage.setItem("isNewMap", true);

      var data = {
        name: "yeni",

        content:
          '{backgroundName: "", "pageBgImage": "", "root":{"id":"' +
          localStorage.getItem("createdNewMapContentId") +
          '","text":"yeni","layout":"map","collapsed":false,"nodeBackground":true,"imageItem":false,"nodeLine":true}}',

        backgroundName: "",

        templateId: null,
      };

      var mapName = "yeni";

      localStorage.setItem("userIsOwner", true);

      localStorage.setItem("mapPermission", 0);

      // create service

      MapService.create(JSON.stringify(data), mapName);
    } else {
      localStorage.removeItem("openedMapName");

      localStorage.removeItem("openedMapId");

      localStorage.setItem("mapTemplate", templateData);

      var data = {
        name: templateName,

        content:
          '{backgroundName: "", "pageBgImage": "", "root":{"id":"' +
          localStorage.getItem("createdNewMapContentId") +
          '","text":"yeni","layout":"map","collapsed":false,"nodeBackground":true,"imageItem":false,"nodeLine":true}}',

        backgroundName: "",
      };

      var mapName = templateName;

      // create service

      MapService.create(JSON.stringify(data), mapName);
    }
  }

  createChatGptMap() {
    var question = this.state.gptSubject;

    var gptLanguage = this.state.chatGptMapLanguage;

    var documentId =
      this.state.documentId === null || undefined
        ? null
        : this.state.documentId;

    var gptLanguageService;

    if (gptLanguage === "tr") {
      gptLanguageService = "Türkçe";
    }
    if (gptLanguage === "en") {
      gptLanguageService = "English";
    }

    const data =
      this.state.isDocument === false
        ? {
            question: question,
            language: gptLanguageService,
          }
        : {
            question: question,
            language: gptLanguageService,
            documentIds: [documentId],
          };

    this.state.isDocument === false
      ? ChatGptService.CreateMap(JSON.stringify(data), function () {
          ChatGptService.redirectMap(localStorage.getItem("openedMapId"));
        })
      : DocumentsServices.CreateMapWithDocument(
          JSON.stringify(data),
          function () {
            DocumentsServices.redirectMap(
              localStorage.getItem("resposeDataDocumentId")
            );
          }
        );
  }

  errorModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),

      title: Resources.getValue("errorMsgTxt"),

      bodyContent:
        "<p>" + Resources.getValue("templatelistmapnameMsgTxt") + "</p>",

      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),

          class: "button yellow-button confirm-button",

          href: "",
        },
      ],
    });
  }

  createMap() {
    document
      .querySelector("#create-map")
      .addEventListener("click", function () {
        document.querySelector(".new-map-wrapper").classList.remove("none");
      });
  }

  deleteCategoryTemplate(scope) {
    Utils.modalm().close();

    TemplateListService.deleteTemplateCategory(
      JSON.parse(localStorage.getItem("deletedTemplateCategoryId")),

      scope.getTemplateList,

      scope
    );
  }

  deleteTemplateCategoryWarning(id, name) {
    localStorage.setItem("deletedTemplateCategoryId", id);

    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),

      title: Resources.getValue("warningMsgTxt"),

      bodyContent:
        "<b>" +
        name +
        "</b>: " +
        Resources.getValue("categoryWillRemoveWithSubsAreYouSureMsgTxt"),

      buttons: [
        {
          text: Resources.getValue("yesMsgTxt"),

          class: "button yellow-button confirm-button",

          href: "",
        },

        {
          text: Resources.getValue("noMsgTxt"),

          class: "button fresh-button reject-button",

          href: "",
        },
      ],

      confirmCallback: this.deleteCategoryTemplate,

      rejectCallback: null,

      scope: this,
    });
  }

  removeTag = (i) => {
    const newTags = [...this.state.tags];

    newTags.splice(i, 1);

    this.setState({ tags: newTags }, (tags) => this.getTemplateList());

    setTimeout(() => {
      var HeaderHeight =
        "calc(100% - " +
        document.querySelector(".template-list-header").offsetHeight +
        "px)";

      document.getElementById("file-box").style.maxHeight = HeaderHeight;
    }, 200);
  };

  inputKeyDown = (e) => {
    const val = e.target.value;

    if (e.key === "Enter" && val) {
      if (
        this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }

      this.setState({ tags: [...this.state.tags, val] }, (tags) =>
        this.getTemplateList()
      );

      this.tagInput.value = null;

      setTimeout(() => {
        var HeaderHeight =
          "calc(100% - " +
          document.querySelector(".template-list-header").offsetHeight +
          "px)";

        document.getElementById("file-box").style.maxHeight = HeaderHeight;
      }, 200);
    } else if (e.key === "Backspace" && !val && this.state.tags.length > 0) {
      this.removeTag(this.state.tags.length - 1);
    }
  };

  render() {
    const { tags } = this.state;

    return (
      <React.Fragment>
        <Header />

        <div className="dashboard-page mindmap-table category-tampleta-grid wide">
          {this.state.isAddCategoryModal ? (
            <AddCategoryModal
              sharedClick={this.sharedClick}
              isUpdateProcess={this.state.isUpdateProcess}
              selectedCategory={this.state.selectedCategory}
              selectedCategoryName={this.state.selectedCategoryName}
            />
          ) : null}

          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="template-panel">
                  <div>
                    <div className="px-3 template-list-header">
                      <SubHeader
                        title={Resources.getValue("createMindMapMsgTxt")}
                        iconName="icon-create_new_map_icon"
                      />
                      <div className="header-subcontainer">
                        <div className="input-tag">
                          <ul className="input-tag__tags">
                            {tags.map((tag, i) => (
                              <li key={tag}>
                                {tag}
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.removeTag(i);
                                  }}
                                >
                                  +
                                </button>
                              </li>
                            ))}
                            <li className="input-tag__tags__input">
                              <input
                                type="text"
                                onKeyDown={this.inputKeyDown}
                                ref={(c) => {
                                  this.tagInput = c;
                                }}
                                placeholder={Resources.getValue(
                                  "categoryFilterMsgTxt"
                                )}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="file-box" id="file-box">
                    <div className="row">
                      {this.state.inputModal ? (
                        <div className="gpt-modal">
                          <section className="modal-main custom-modal-main">
                            <div className="chat-gpt-header">
                              <img
                                src={gptModalImage}
                                alt="Foramind, Zihin Haritası, Mind Map"
                              />

                              <h1>{Resources.getValue("chatGPTCreate")}</h1>

                              <button
                                onClick={this.handleClose}
                                type="button modal-button"
                                className="close"
                                aria-label="Close"
                              >
                                <img
                                  src={closeImage}
                                  alt="Foramind, Zihin Haritası, Mind Map"
                                />
                              </button>
                            </div>

                            <form>
                              <label htmlFor="gpt-subject">
                                {Resources.getValue("chatGptMapSubject")}
                              </label>

                              <input
                                type="text"
                                placeholder={Resources.getValue("fillSubject")}
                                onChange={(e) => {
                                  this.setState({ gptSubject: e.target.value });
                                }}
                                id="gpt-subject"
                              />

                              <div className="form-check">
                                {this.state.userRole != 2 && (
                                  <div className="switch-ai-or-documents-container">
                                    <div>
                                      <label style={{ fontSize: "15px" }}>
                                        {Resources.getValue(
                                          "useDocumentMsgTxt"
                                        )}
                                      </label>
                                    </div>
                                    <div className="switch-ai-or-documents-container__inner-container">
                                      <label
                                        class="switch document-switch"
                                        style={{ marginRight: "none" }}
                                      >
                                        <input
                                          type="checkbox"
                                          onClick={(e) => {
                                            this.documentSwitchHandle(
                                              e.target.checked
                                            );
                                          }}
                                        />
                                        <span class="slider round"></span>
                                      </label>
                                      {this.state.isDocument === true && (
                                        <select
                                          className="custom-select-document"
                                          name="document"
                                          id="document"
                                          onChange={(e) => {
                                            this.setState({
                                              documentId: Number(
                                                e.target.value
                                              ),
                                            });

                                            console.log(this.state);
                                          }}
                                          defaultValue=""
                                        >
                                          <option value="" disabled>
                                            {Resources.getValue(
                                              "pleaseSelectDocumentMsgTxt"
                                            )}
                                          </option>
                                          {this.state.documents.map(
                                            (document) => (
                                              <option
                                                key={document.id}
                                                value={document.id}
                                              >
                                                {document.contentName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </div>
                                )}

                                <div className="form-check__header">
                                  <h5>
                                    {Resources.getValue("languageSelect")}:
                                  </h5>
                                </div>

                                <div className="language-options">
                                  <div className="language-option">
                                    <input
                                      className=""
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      value="tr"
                                      defaultChecked={
                                        this.state.isLanguageTurkish
                                      }
                                      onClick={(e) =>
                                        this.setState({
                                          chatGptMapLanguage: e.target.value,
                                        })
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault1"
                                    >
                                      {Resources.getValue("languageTurkish")}
                                    </label>
                                  </div>

                                  <div className="language-option">
                                    <input
                                      className=""
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      value="en"
                                      defaultChecked={
                                        this.state.isLanguageEnglish
                                      }
                                      onClick={(e) =>
                                        this.setState({
                                          chatGptMapLanguage: e.target.value,
                                        })
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault2"
                                    >
                                      {Resources.getValue("languageEnglish")}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="action-buttons">
                                {this.state.isDocument === false ? (
                                  <>
                                    <button
                                      type="button"
                                      id="cancel-gpt"
                                      onClick={this.handleClose}
                                    >
                                      {Resources.getValue("cancelChatGpt")}
                                    </button>

                                    <button
                                      id="save-gpt"
                                      type="button"
                                      onClick={this.createChatGptMap}
                                      className={
                                        this.state.gptSubject.trim() === ""
                                          ? "create-mind-map-ai-passive"
                                          : "create-mind-map-ai-active"
                                      }
                                      disabled={
                                        this.state.gptSubject.trim() === ""
                                          ? true
                                          : false
                                      }
                                    >
                                      {Resources.getValue("createMap")}
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      id="cancel-gpt"
                                      onClick={this.handleClose}
                                    >
                                      {Resources.getValue("cancelChatGpt")}
                                    </button>

                                    <button
                                      id="save-gpt"
                                      type="button"
                                      onClick={this.createChatGptMap}
                                      className={
                                        this.state.documentId !== null &&
                                        this.state.gptSubject.trim() !== ""
                                          ? "create-mind-map-ai-active"
                                          : "create-mind-map-ai-passive"
                                      }
                                      disabled={
                                        this.state.documentId !== null &&
                                        this.state.gptSubject.trim() !== ""
                                          ? false
                                          : true
                                      }
                                    >
                                      {Resources.getValue("createMap")}
                                    </button>
                                  </>
                                )}
                              </div>
                            </form>
                          </section>
                        </div>
                      ) : null}

                      <div className="new-map-wrapper none col-12 col-sm-8 col-md-6 col-lg-4 col-xl-4">
                        <input
                          type="text"
                          className="new-map"
                          maxLength="30"
                          placeholder={Resources.getValue("examplenameMsgTxt")}
                        />
                      </div>
                    </div>

                    <div className="template-list-board row">
                      <div className="list-item">
                        <div className="template-item-list-wrapper">
                          <a
                            className="template-blank"
                            data-name="blank"
                            title={Resources.getValue("addBlankMapMsgTxt")}
                            onClick={() => this.newMap("yeni")}
                          >
                            <img
                              alt="templateLogo"
                              className="template-image"
                              src={blankTemplateLogo}
                              alt={Resources.getValue("addBlankMapMsgTxt")}
                            />
                          </a>
                        </div>

                        <p className="item-name">
                          {Resources.getValue("addBlankMapMsgTxt")}
                        </p>
                      </div>
                      {this.state.gptMapNumbers > 0 && (
                        <div className="list-item">
                          <div className="template-item-list-wrapper">
                            <a
                              className="template-blank"
                              data-name="blank"
                              title={Resources.getValue("addChatGPTMsgTxt")}
                              onClick={this.openChatGptModal}
                            >
                              <img
                                className="gpt-image"
                                src={robotImage}
                                alt={Resources.getValue("addChatGPTMsgTxt")}
                              />
                            </a>
                          </div>

                          <p className="item-name">
                            {Resources.getValue("addChatGPTMsgTxt")}
                          </p>
                        </div>
                      )}
                      {this.state.templateData.length ? (
                        <>
                          {this.state.templateData
                            .sort((a, b) =>
                              a.name.toLowerCase() > b.name.toLowerCase()
                                ? 1
                                : -1
                            )
                            .map((catItem) => {
                              return (
                                <div
                                  key={catItem.id}
                                  className="list-item-wrap"
                                >
                                  <a
                                    className={`more-options-btn

                                  ${
                                    this.state.isUserHasPermissionForOptions ===
                                      false ||
                                    (this.state
                                      .isUserHasPermissionForOptions === true &&
                                      JSON.parse(
                                        localStorage.getItem("userInformation")
                                      ).companyId !== catItem.companyId)
                                      ? " none"
                                      : ""
                                  }`}
                                  >
                                    <img
                                      src={moreOptionsIcon}
                                      alt={Resources.getValue("optionsMsgTxt")}
                                    />

                                    <div className="options">
                                      <div
                                        onClick={() =>
                                          this.deleteTemplateCategoryWarning(
                                            catItem.id,

                                            catItem.name
                                          )
                                        }
                                      >
                                        {Resources.getValue("deleteMsgTxt")}
                                      </div>

                                      <div
                                        onClick={() => {
                                          localStorage.setItem(
                                            "isCustomModalOpen",

                                            true
                                          );

                                          this.setState({
                                            isAddCategoryModal: JSON.parse(
                                              localStorage.getItem(
                                                "isCustomModalOpen"
                                              )
                                            ),

                                            isUpdateProcess: true,

                                            selectedCategory: catItem,

                                            selectedCategoryName: catItem.name,
                                          });
                                        }}
                                      >
                                        {Resources.getValue("editMsgTxt")}
                                      </div>
                                    </div>
                                  </a>

                                  <div
                                    className="list-item template-item-list"
                                    data-id={catItem.id}
                                    onClick={() =>
                                      this.goToSubTamplatesEvent(
                                        catItem.id,

                                        catItem.name
                                      )
                                    }
                                  >
                                    <div className="template-item-list-wrapper">
                                      <a
                                        className="item-image-click"
                                        data-name={catItem.name}
                                        data-id={catItem.id}
                                      >
                                        <img
                                          src={catItem.image}
                                          alt="itemImage"
                                          className="item-image template-image"
                                        />
                                      </a>
                                    </div>

                                    <p className="item-name">{catItem.name}</p>
                                  </div>
                                </div>
                              );
                            })}

                          <div
                            className={`list-item

                            ${
                              this.state.isUserHasPermissionForOptions === false
                                ? " none"
                                : ""
                            }`}
                          >
                            <div className="template-item-list-wrapper">
                              <a
                                className="item-image-click add-new-cat"
                                title={Resources.getValue(
                                  "addNewCategoryMapMsgTxt"
                                )}
                                onClick={() => {
                                  localStorage.setItem(
                                    "isCustomModalOpen",

                                    true
                                  );

                                  this.setState({
                                    isAddCategoryModal: JSON.parse(
                                      localStorage.getItem("isCustomModalOpen")
                                    ),
                                  });
                                }}
                              >
                                <img
                                  alt="templateLogo"
                                  className="template-image"
                                  src={blankTemplateLogo}
                                  alt={Resources.getValue(
                                    "addNewCategoryMapMsgTxt"
                                  )}
                                />

                                <p className="item-name">
                                  {Resources.getValue(
                                    "addNewCategoryMapMsgTxt"
                                  )}
                                </p>
                              </a>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className={`list-item

                          ${
                            this.state.isUserHasPermissionForOptions === false
                              ? " none"
                              : ""
                          }`}
                        >
                          <div className="template-item-list-wrapper">
                            <a
                              className="item-image-click add-new-cat"
                              title={Resources.getValue(
                                "addNewCategoryMapMsgTxt"
                              )}
                              onClick={() => {
                                localStorage.setItem("isCustomModalOpen", true);

                                this.setState({
                                  isAddCategoryModal: JSON.parse(
                                    localStorage.getItem("isCustomModalOpen")
                                  ),
                                });
                              }}
                            >
                              <img
                                alt="templateLogo"
                                className="template-image"
                                src={blankTemplateLogo}
                                alt={Resources.getValue(
                                  "addNewCategoryMapMsgTxt"
                                )}
                              />

                              <p className="item-name">
                                {Resources.getValue("addNewCategoryMapMsgTxt")}
                              </p>
                            </a>
                          </div>
                        </div>
                      )}

                      {/* eski kategori liste itemlar */}

                      {/* <div className="list-item template-item-list none">

                        <div className="template-item-list-wrapper">

                          <a className="item-image-click">

                            <img alt="itemImage" className="item-image" />

                          </a>

                        </div>

                        <p className="item-name"></p>

                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TemplateList;
