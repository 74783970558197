import React, { Component } from "react";
import aboutPageImage from "../styles/img/about-page-img.png";
import Resources from "../libraries/resources";
import NavMenu from "../components/nav-menu";
import Footer from "../components/footer";
import {Helmet} from "react-helmet";
class AboutPage extends Component {

	constructor() {
		super();

		this.menuItems = [
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/' : '/',
				title: Resources.getValue("landingPageHomeMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '',
				title: Resources.getValue("landingPageCorporateMsgTxt"),
				activeClass: 'not-active',
				externalLink: false,
				subLinks: [
					{
						href: '/about',
						title: Resources.getValue("landingPageAboutMsgTxt"),
						activeClass: '',
						externalLink: true
					},
					// {
					// 	href: '#references',
					// 	title: Resources.getValue("landingPageReferencesMsgTxt"),
					// 	activeClass: '',
					// 	externalLink: true
					// },
					{
						href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#contact' : '/' + '#contact',
						title: Resources.getValue("landingPageContactMsgTxt"),
						activeClass: '',
						externalLink: true
					}
				]
			},
			// {
			// 	href: '/features',
			// 	title: Resources.getValue("landingPageFeaturesMsgTxt"),
			// 	activeClass: '',
			// 	externalLink: true
			// },
			{
				href: '/education',
				title: Resources.getValue("landingPageEducationMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/blog',
				title: "Blog",
				activeClass: '',
				externalLink: true
			},
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#price' : '/' + '#price',
				title: Resources.getValue("landingPagePriceMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/kvkk',
				title: Resources.getValue("landingPageKavkkMsgTxt"),
				activeClass: '',
				externalLink: true
			}
		];
	}

	componentDidMount() {
		// document.title = "Foramind | " + Resources.getValue("landingPageAboutMsgTxt");
	}

	render() {
		return (
			<div className="landing-page" id="landing-page">
				<Helmet>
				<meta name="description" content="Foramind Nedir? Türkiye’nin ilk ve tek Zihin Haritası Yazılımı olan Foramind ile öğrenme süreçlerinizde zihin haritalama tekniklerini kullanabilirsiniz. 15 Gün Ücretsiz Deneyebilirsiniz." />
  				<meta name="keywords" content="foramind nedir, zihin haritası yazılımı, zihin haritalama, foramind, mind mapping software, zihin haritalama teknikleri" />
                <title>Hakkımızda - ForaMind – Zihin Haritası Yazılımı | Zihin Haritalama</title>
            </Helmet>
				<NavMenu menuItems={this.menuItems} />
				<div className="about-page">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h2>{Resources.getValue("landingPageAboutMsgTxt")}</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="img-wrapper text-center">
									<img src={aboutPageImage} alt="Foramind, Zihin Haritası, Mind Map" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="content-wrapper text-center">
									<p className="manager-text none">
										“{Resources.getValue('aboutPageGeneralManagerTextMsgTxt')}”
										<br /><br />
										<span>Cem Kalyoncu / {Resources.getValue('aboutPageGeneralManagerMsgTxt')}</span>
									</p>
									<p>
										{Resources.getValue('aboutPageForamindContentMsgTxt')}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	};
}

export default AboutPage;
