import React, { Component } from "react";
import Resources from "../libraries/resources";
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Utils from "../libraries/utils";
import SharedMapService from "../services/api/shared-map";
import MapService from "../services/api/map";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import iconShared from "../styles/img/shared-icon.png";
import iconGridFile from "../styles/img/grid-file-icon.png";

class MindMapShare extends Component {

  constructor(props) {
    super(props)

    this.state = {
      data: [],
      deletedMapId: null,
      name: "",
    }

    localStorage.setItem('isFavFilter', false);
    localStorage.setItem('isNewMap', false);
    localStorage.setItem('mapPermission', 0);
    
    localStorage.setItem("retrieveUrl", window.location.pathname);
  };

  componentDidMount() {
    document.title = "Foramind | " + Resources.getValue("mindMapsShareMsgTxt");
    this.getMindMapShare();
    this.changeLayout();
    this.showHideSearch()
  };

  getMindMapShare = () => {
    var userId     = JSON.parse(localStorage.getItem('userInformation')).id;
    var recordSize = 1000;

    var callback = this.fillMindMapShare || this.scope.fillMindMapShare;
    var scope    = this.fillMindMapShare ? this : this.scope;
    SharedMapService.getMindMapSharedByUserIdAsync(userId, recordSize, callback, scope);
  };

  fillMindMapShare() {
    var _this = this;
    const mindMapList = JSON.parse(this.response).mindMap;

    var listedItems = document.querySelectorAll('.table-list');
    if (listedItems.length > 0) {
      _this.scope.setState({
        data: []
      })
    }

    if (mindMapList) {
      for (var i = 0; i < mindMapList.length; i++) {

        if (mindMapList[i].modifiedDate)
          mindMapList[i].modifiedDate = Utils.formatDateWithMonthName(mindMapList[i].modifiedDate)

        if (mindMapList[i].creationDate)
          mindMapList[i].creationDate = mindMapList[i].creationDate
          // mindMapList[i].creationDate = Utils.formatDateWithMonthName(mindMapList[i].creationDate)
      }
      _this.scope.setState({
        data: JSON.parse(JSON.stringify(mindMapList))
      })
    }
  };

  deleteMindMap(scope) {
    Utils.modalm().close();
    SharedMapService.delete(scope.state.deletedMapId, scope.getMindMapShare, scope);
  };

  deleteRow(id) {
    this.state.data.findIndex(post => {
      return post.id === id
    })
    this.setState({
      deletedMapId: id
    })
    this.deleteMapApproveModal();
  };

  deleteMapApproveModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("areyousureMsgTxt"),
      bodyContent: '<p>' + Resources.getValue('deleteMapApproveWithPermissionModalMsgTxt') + '</p>',
      buttons: [
        {
          text: Resources.getValue("noMsgTxt"),
          class: 'button fresh-button reject-button',
          href: ''
        },
        {
          text: Resources.getValue("yesMsgTxt"),
          class: 'button yellow-button confirm-button',
          href: ''
        }

      ],
      confirmCallback: this.deleteMindMap,
      rejectCallback: null,
      scope: this
    });
  };

  renderDateColumn = (cellInfo) => {
    return (
      <div>
        {Utils.formatDateWithMonthName(cellInfo.original.creationDate)}
      </div>
    );
  }

  renderFavAction = (cellInfo) => {
    const data = this.state.data[cellInfo.index];
    return (
      <div className="name-fav-wrapper">
        <div className="name-fav-wrapper__item-name">
          <a 
            onClick={() => {
              this.clickOpenUrl(data.id, data.name, data.mapPermissionId);
              localStorage.setItem("mapJsonObj", JSON.stringify(data));
              localStorage.setItem('mapPermission', data.mapPermissionId);
            }}
            title={cellInfo.original.name}
          >
            {data[cellInfo.column.id]}
          </a>
          {cellInfo.original.isMapShared ? (
            <img src={iconShared} alt={Resources.getValue("sharedMapMsgTxt")} />
          ) : ''}
        </div>
        {/* {cellInfo.original.isMapShared ? (
          <img src={iconShared} alt={Resources.getValue("sharedMapMsgTxt")} />
        ) : ''} */}
        {cellInfo.original.sharedUserNameSurname ? (
          (<span className="shared-user-name">
            ({cellInfo.original.sharedUserNameSurname + Resources.getValue('isSharedInfoMsgTxt')})
          </span>)
        ) : ''}
        <a 
          onClick={() => this.addRemoveFavorite(cellInfo.original.id, !cellInfo.original.isFavorite)}
          className={`fav-action${cellInfo.original.isFavorite ? ' active' : ''}`}
          title={cellInfo.original.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
        >
          <i className="icon-favorite-icon"></i>
        </a>
      </div>
    );
  };

  renderFavListFavAction = (cellInfo) => {
    const favoritedData = this.state.data.filter(item => {
      return item.isFavorite === true;
    });
    return (
      <div className="name-fav-wrapper">
        <div className="name-fav-wrapper__item-name">
          <a 
            onClick={() => {
              this.clickOpenUrl(favoritedData[cellInfo.index].id, favoritedData[cellInfo.index].name, favoritedData[cellInfo.index].mapPermissionId);
              localStorage.setItem("mapJsonObj", JSON.stringify(favoritedData[cellInfo.index]));
              localStorage.setItem('mapPermission', favoritedData[cellInfo.index].mapPermissionId);
            }}
            title={cellInfo.original.name}
          >
            {favoritedData[cellInfo.index][cellInfo.column.id]}
          </a>
          {cellInfo.original.isMapShared ? (
            <img src={iconShared} alt={Resources.getValue("sharedMapMsgTxt")} />
          ) : ''}
        </div>
        {cellInfo.original.sharedUserNameSurname ? (
          (<span className="shared-user-name">
            ({cellInfo.original.sharedUserNameSurname + Resources.getValue('isSharedInfoMsgTxt')})
          </span>)
        ) : ''}
        <a 
          onClick={() => this.addRemoveFavorite(cellInfo.original.id, !cellInfo.original.isFavorite)}
          className={`fav-action${cellInfo.original.isFavorite ? ' active' : ''}`}
          title={cellInfo.original.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
        >
          <i className="icon-favorite-icon"></i>
        </a>
      </div>
    );
  };

  clickOpenUrl(id, name, permission) {
    localStorage.setItem('openedMapName', name);
    localStorage.setItem('openedMapId', id);
    var mapId = Utils.getParameterByName('mindMapId') || localStorage.getItem('openedMapId');
    var userId = JSON.parse(localStorage.getItem("userInformation")).id;
    if(permission === 2) {
      window.location.href = Resources.getValue("appBaseEnvURL") + '/map?mapId=' + mapId;
    } else {
      window.location.href = (Resources.getValue("appBaseEnvURL") + '/shared-map' + '?id=' + userId + '&mindMapId=' + id + '&sharedMap=' + true);
    }
  };

  addRemoveFavorite = (mapId, isFavStatus) => {
    const userId = JSON.parse(localStorage.getItem('userInformation')).id;
    const favData = {
      mindMapId: mapId,
      userId: userId,
      isFavorite: isFavStatus
    }
    MapService.AddRemoveFavorite(JSON.stringify(favData), this.favCallback, this);
  };

  favCallback() {
    var _this = this;
    _this.scope.getMindMapShare();
  }

  changeLayout() {
    let optionBtn = document.querySelectorAll('.layout-option');
    [].forEach.call(optionBtn, el => {
      el.addEventListener('click', function () {
        [].forEach.call(optionBtn, e => {
          e.classList.remove('active');
          document.querySelector('.show-hide-fav').classList.remove('active');
          localStorage.setItem('isFavFilter', false);
          const sublist = document.querySelectorAll('.sub-list');
          sublist.forEach((elem) => {
            elem.classList.remove('show');
          });
        });
        el.classList.toggle("active");
        const dataLayout = el.getAttribute("data-layout");
        let allContents = document.querySelectorAll('.layout-option-content');
        allContents.forEach((el) => {
          el.classList.remove('show');
        });
        document.querySelector('.layout-option-content[data-layout="'+ dataLayout +'"]').classList.add('show');
        document.querySelector(
          '.layout-option-content[data-layout="' + dataLayout + '"] .sub-list:nth-of-type(1)'
        ).classList.add('show');
      })
    });
  }

  showHideFavList() {
    const favBtn = document.querySelector('.show-hide-fav');
    const subList = document.querySelectorAll('.sub-list');

    if (favBtn.classList.contains('active')) {
      favBtn.classList.remove('active');
      localStorage.setItem('isFavFilter', false);
      subList.forEach((elem) => {
        elem.classList.remove('show');
      });
      document.querySelector('.layout-option-content.show .sub-list:nth-of-type(1)').classList.add('show');
    } else {
      favBtn.classList.add('active');
      localStorage.setItem('isFavFilter', true);
      subList.forEach((elem) => {
        elem.classList.remove('show');
      });
      document.querySelector('.layout-option-content.show .sub-list:nth-of-type(2)').classList.add('show');
    }
  }

  showHideSearch() {
    const filterSearch = document.querySelectorAll('.ReactTable .rt-thead.-filters');
    const tableBody = document.querySelectorAll('.ReactTable .rt-tbody');
    var filterInput = document.querySelectorAll('.show .ReactTable .rt-thead.-filters input');

    filterInput.forEach((inp, ind) => {
      inp.setAttribute('id', `search${ind}`);
      inp.setAttribute('placeholder', Resources.getValue("searchMsgTxt"));
      inp.setAttribute('class', 'Search-map-list');
    });

    filterSearch.forEach(elem => {
      filterInput.forEach((inp, ind) => {
        // document.getElementById(`search${ind}`).focus();
      });
      if(elem.classList.contains('show')) {
        // elem.classList.remove('show');
        tableBody.forEach(el => {
          // el.classList.remove('show-filter-pos');
        });
      } else {
        elem.classList.add('show');
        tableBody.forEach(el => {
          el.classList.add('show-filter-pos');
        });
      }
    });
  }

  render() {
    const data = this.state.data;
    const favoritedData = data.filter(item => {
      return item.isFavorite === true;
    });
    return (
      <React.Fragment>
        <Header />
        <ToastContainer />
        <div className="mindmap-table wide">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="template-panel">
                  <div className="title-wrapper">
                    <div className="px-3">
                      <SubHeader title={Resources.getValue("mindMapsShareMsgTxt")} iconName="icon-share_list_icon" />
                    </div>
                    <div className="list-grid-options">
                      <a title={Resources.getValue("showFavoritesMsgTxt")} 
                        className="fav show-hide-fav"
                        onClick={this.showHideFavList}
                      >
                        <i className="icon-favorite-icon"></i>
                      </a>
                      <a title={Resources.getValue("showAsListMsgTxt")} 
                        className="list layout-option active" 
                        data-layout="list"
                        onClick={this.changeLayout}
                      >
                        <i className="icon-list-icon"></i>
                      </a>
                      <a title={Resources.getValue("showAsGridMsgTxt")} 
                        className="grid layout-option" 
                        data-layout="grid"
                        onClick={this.changeLayout}
                      >
                        <i className="icon-grid-icon"></i>
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="col-md-12 px-3 pb-3">
                      <div className="table-list layout-option-content show" data-layout="list">
                        {/* All list */}
                        <div
                          className={`sub-list${JSON.parse(localStorage.getItem('isFavFilter')) ? '' : ' show'}`}
                        >
                          {/* <a onClick={this.showHideSearch} className="list-search-btn">
                            <i className="icon-search-icon"></i>
                          </a> */}
                          <div className="map-table">
                          <ReactTable
                          data={data}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.accessor]) === filter.value}
                          previousText={Resources.getValue("previousTextMsgTxt")}
                          nextText={Resources.getValue("nextTextMsgTxt")}
                          pageText={Resources.getValue("pageTextMsgTxt")}
                          rowsText={Resources.getValue("rowsTextMsgTxt")}
                          ofText={Resources.getValue("ofTextMsgTxt")}
                          noDataText={Resources.getValue("noDataTextMsgTxt")}
                          loadingText={Resources.getValue("loadingTextMsgTxt")}
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                          onPageChange={(data) => {
                            this.setState({
                              listPageCurrent: data
                            });
                          }}
                          page={this.state.listPageCurrent ? this.state.listPageCurrent : undefined}

                          const columns={[
                            {
                              columns: [
                                {
                                  Header: () => (
                                    <div className="title-search-wrap">
                                      <span>{Resources.getValue("nameMsgTxt")}</span>
                                    </div>
                                  ),
                                  accessor: "name",
                                  filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                                  Cell: this.renderFavAction,
                                  className: "name-column",
                                }]
                            },
                            {
                              columns: [
                                {
                                  Header: Resources.getValue("creationDateMsgTxt"),
                                  accessor: "creationDate",
                                  filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value),
                                  Cell: this.renderDateColumn,
                                  className: "date-column",
                                }]
                            },
                            {
                              columns: [
                                {
                                  Cell: props => {
                                    return (
                                      <div>
                                        <a onClick={() => {
                                          this.clickOpenUrl(props.original.id, props.original.name, props.original.mapPermissionId);
                                          localStorage.setItem("mapJsonObj", JSON.stringify(props.original));
                                          localStorage.setItem('mapPermission', props.original.mapPermissionId);
                                        }} className="action-button" title={Resources.getValue('showMsgTxt')}>
                                          <i className="icon-show_icon"></i>
                                        </a>
                                        <a onClick={() => {
                                          this.deleteRow(props.original.id)
                                        }} className="action-button" title={Resources.getValue('deleteMsgTxt')} >
                                          <i className="icon-delete-icon"></i>
                                        </a>
                                      </div>
                                    )
                                  },
                                  className: "action-column",
                                  style: {
                                    textAlign: "center"
                                  },
                                  sortable: false,
                                  filterable: false,
                                }]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                        />
                          </div>
                        </div>
                        {/* Favorited List */}
                        <div
                          className={`sub-list${JSON.parse(localStorage.getItem('isFavFilter')) ? ' show' : ''}`}
                        >
                          {/* <a onClick={this.showHideSearch} className="list-search-btn">
                            <i className="icon-search-icon"></i>
                          </a> */}
                          <div className="map-table">
                          <ReactTable
                          data={favoritedData}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.accessor]) === filter.value}
                          previousText={Resources.getValue("previousTextMsgTxt")}
                          nextText={Resources.getValue("nextTextMsgTxt")}
                          pageText={Resources.getValue("pageTextMsgTxt")}
                          rowsText={Resources.getValue("rowsTextMsgTxt")}
                          ofText={Resources.getValue("ofTextMsgTxt")}
                          noDataText={Resources.getValue("noDataTextMsgTxt")}
                          loadingText={Resources.getValue("loadingTextMsgTxt")}
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                          onPageChange={(data) => {
                            this.setState({
                              favPageCurrent: data
                            });
                          }}
                          page={this.state.favPageCurrent ? this.state.favPageCurrent : undefined}

                          const columns={[
                            {
                              columns: [
                                {
                                  Header: () => (
                                    <div className="title-search-wrap">
                                      <span>{Resources.getValue("nameMsgTxt")}</span>
                                    </div>
                                  ),
                                  accessor: "name",
                                  filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                                  Cell: this.renderFavListFavAction,
                                  className: "name-column",
                                }]
                            },
                            {
                              columns: [
                                {
                                  Header: Resources.getValue("creationDateMsgTxt"),
                                  ccessor: "creationDate",
                                  filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value),
                                  Cell: this.renderDateColumn,
                                  className: "date-column",
                                }]
                            },
                            {
                              columns: [
                                {
                                  Cell: props => {
                                    return (
                                      <div>
                                        <a onClick={() => {
                                          this.clickOpenUrl(props.original.id, props.original.name, props.original.mapPermissionId);
                                          localStorage.setItem("mapJsonObj", JSON.stringify(props.original));
                                          localStorage.setItem('mapPermission', props.original.mapPermissionId);
                                        }} className="action-button" title={Resources.getValue('showMsgTxt')}>
                                          <i className="icon-show_icon"></i>
                                        </a>
                                        <a onClick={() => {
                                          this.deleteRow(props.original.id)
                                        }} className="action-button" title={Resources.getValue('deleteMsgTxt')} >
                                          <i className="icon-delete-icon"></i>
                                        </a>
                                      </div>
                                    )
                                  },
                                  className: "action-column",
                                  style: {
                                    textAlign: "center"
                                  },
                                  sortable: false,
                                  filterable: false,
                                }]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                        />
                          </div>
                        </div>
                      </div>
                      <div className="table-grid layout-option-content" data-layout="grid">
                        {/* All grid */}
                        <div
                          className={`sub-list${JSON.parse(localStorage.getItem('isFavFilter')) ? '' : ' show'}`}
                        >
                          <div className="grid-items">
                            {(data && data.length) ? data.map(item => (
                              <div className="grid-item" key={item.id} title={item.name}>
                                <a
                                  onClick={() => this.addRemoveFavorite(item.id, !item.isFavorite)}
                                  className={`fav-action${item.isFavorite ? ' active' : ''}`}
                                  title={item.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
                                >
                                  <i className="icon-favorite-icon"></i>
                                </a>
                                {item.isMapShared ? (
                                  <img
                                    src={iconShared}
                                    className="shared-icon"
                                    alt={Resources.getValue("sharedMapMsgTxt")}
                                  />
                                ) : ''}
                                <a onClick={() => {
                                  this.clickOpenUrl(item.id, item.name, item.mapPermissionId);
                                  localStorage.setItem("mapJsonObj", JSON.stringify(item));
                                }}>
                                  <img src={iconGridFile} alt={item.name} />
                                  <div className="name">{item.name}</div>
                                </a>
                              </div>
                            )) : ''}
                          </div>
                        </div>
                        {/* Favorited grid */}
                        <div
                          className={`sub-list${JSON.parse(localStorage.getItem('isFavFilter')) ? ' show' : ''}`}
                        >
                          <div className="grid-items">
                            {(favoritedData && favoritedData.length) ? favoritedData.map(item => (
                              <div className="grid-item" key={item.id} title={item.name}>
                                <a
                                  onClick={() => this.addRemoveFavorite(item.id, !item.isFavorite)}
                                  className={`fav-action${item.isFavorite ? ' active' : ''}`}
                                  title={item.isFavorite ? Resources.getValue("removeFromFavListMsgTxt") : Resources.getValue("addToFavListMsgTxt")}
                                >
                                  <i className="icon-favorite-icon"></i>
                                </a>
                                {item.isMapShared ? (
                                  <img
                                    src={iconShared}
                                    className="shared-icon"
                                    alt={Resources.getValue("sharedMapMsgTxt")}
                                  />
                                ) : ''}
                                <a onClick={() => {
                                  this.clickOpenUrl(item.id, item.name, item.mapPermissionId);
                                  localStorage.setItem("mapJsonObj", JSON.stringify(item));
                                }}>
                                  <img src={iconGridFile} alt={item.name} />
                                  <div className="name">{item.name}</div>
                                </a>
                              </div>
                            )) : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default MindMapShare;
