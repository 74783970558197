import React from "react";
import MM from "../libraries/map";
import Tip from "../components/tip";
import Toolbox from "../components/toolbox";
import SharedToolbox from "../components/shared-toolbox";
import ContextMenu from "../components/context-menu";
import HelpMenu from "../components/help-menu";
import Utils from "../libraries/utils";
import MapService from "../services/api/map";
import Resources from "../libraries/resources";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import { withRouter } from "react-router-dom";

class Map extends React.Component {
  MapApp = null;

  constructor(props) {
    super();
    localStorage.setItem("openedMapId", Utils.getParameterByName("mapId"));

    // if map name == null
    this.checkMapName();

    this.hubURL =
      Resources.getValue("signalrBaseEnvURL") +
      "/api/mindmaphub?mindMapId=" +
      localStorage.getItem("openedMapId") +
      "&eMail=" +
      localStorage.getItem("userMail");

    this.state = {
      UserIsOwner: false,
      hubConnection: null,
    };
  }

  componentWillMount() {
    this.createHubConnection();
    if (
      localStorage.getItem("zoomMap") === null ||
      localStorage.getItem("zoomMap") === "" ||
      localStorage.getItem("zoomMap") === undefined ||
      localStorage.getItem("zoomMap") === NaN
    ) {
      localStorage.setItem("zoomMap", "1.04");
    } else {
      localStorage.setItem("zoomMap", localStorage.getItem("zoomMap"));
    }
  }

  componentDidMount() {
    this.MapApp = MM.App.init();
    MM.App.io.restore();
    this.listener();
    this.checkOpenedMap();
    this.startHubConnection();
  }

  createHubConnection() {
    const connection = new HubConnectionBuilder()
      .withUrl(`${this.hubURL}`, {

        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        // skipNegotiation: false,
        // transport: HttpTransportType.LongPolling,

        // skipNegotiation: true,
        // transport: HttpTransportType.WebSockets,
        skipNegotiation: false,
        transport: HttpTransportType.LongPolling,

      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();

    this.setState({
      hubConnection: connection,
    });
  }

  startHubConnection() {
    var mapObj = JSON.parse(localStorage.getItem("mapJsonObj"));

    if (mapObj.isMapShared === true) {
      // console.log('benim mapim/degil ve bu map paylasildi');

      this.state.hubConnection
        .start()
        .then(() => {
          // console.log("Connection started");
        })
        .catch((err) => {
          console.log("Error while establishing connection", err);
        });

      // if token exist and user is logged in
      this.state.hubConnection.on(
        "MapReceived",
        (mindMapId, mapJson, backgroundName) => {
          console.log("MapReceived", mindMapId, mapJson);
          MM.UI.Backend.File.sync.setHubConnectionStatus("active");
          var newData = JSON.parse(mapJson);
          MM.App.setMap(MM.Map.fromJSON(newData));
          window.backgroundImage = backgroundName;
          document.querySelector("#port").style.background =
            MM.App.map._getPageBgImage();
          MM.UI.Backend.File.sync.setHubConnectionStatus("syncSave");
        }
      );

      MM.UI.Backend.File.sync.setHubConnection(this.state.hubConnection);
    }
  }

  sendMessage() {
    var mapId = localStorage.getItem("openedMapId");
    // var mapContent = JSON.stringify(MM.App.map.toJSON());

    this.state.hubConnection
      .invoke("SendMapToUsers", mapId)
      .catch((err) => console.error(err));
  }

  listener() {
    var _this = this;
    window.onbeforeunload = function () {
      _this.cleaner();
    };
  }

  cleaner() {
    localStorage.removeItem("newMapName");
    localStorage.removeItem("mapTemplate");
  }

  mapPageStyle() {
    document.querySelector("body").style.overflow = "hidden";
  }

  checkMapName() {
    if (
      (!localStorage.getItem("newMapName") ||
        !localStorage.getItem("mapTemplate")) &&
      (!localStorage.getItem("openedMapName") ||
        !localStorage.getItem("openedMapId"))
    ) {
      window.location.href =
        Resources.getValue("appBaseEnvURL") + "/mind-map-list";
    }
  }

  checkOpenedMap() {
    var mapId =
      Utils.getParameterByName("mapId") || localStorage.getItem("openedMapId");
    var mapTemplate = localStorage.getItem("mapTemplate");

    if (mapId && !mapTemplate) {
      MapService.getMap(mapId, this.drawOpenedMap, this);
    } else if (mapTemplate && mapTemplate != "yeni") {
      MM.UI.Backend._loadDone(JSON.parse(mapTemplate));
      // clear template
      localStorage.removeItem("mapTemplate");
    } else {
      const map = new MM.Map();
      map.createBlankMap();
      this.MapApp.setMap(map);
    }
  }

  drawOpenedMap() {
    var _this = this;
    var data = JSON.parse(this.response).mindMap;
    var mapData = JSON.parse(this.response).mindMap.content;
    var mapBackgroundImage = JSON.parse(this.response).mindMap.backgroundName;
    var UserIsOwner = JSON.parse(this.response).userIsOwner;
    localStorage.getItem("openedMapName", data.name);
    localStorage.setItem("userIsOwner", UserIsOwner);
    localStorage.setItem("mapPermission", data.mapPermissionId);
    if (data.isMapShared) {
      localStorage.setItem("isMapShared", true);
      localStorage.setItem(
        "mapJsonObj",
        JSON.stringify(JSON.parse(this.response).mindMap)
      );
    } else {
      localStorage.setItem("isMapShared", false);
      localStorage.setItem(
        "mapJsonObj",
        JSON.stringify(JSON.parse(this.response).mindMap)
      );
    }

    if (mapData) {
      MM.UI.Backend._loadDone(JSON.parse(mapData));
      var container = document.querySelector(".item");
      MM.App.scrollZoom(container, 1.9, 0.5);
      MM.App.buttonZoom(container, 1.9, 0.5);
    }

    if (mapBackgroundImage) {
      document.querySelector("#port").style.background = mapBackgroundImage;
    }
  }

  render() {
    this.mapPageStyle();
    return (
      <React.Fragment>
        <Tip />
        {JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
        JSON.parse(localStorage.getItem("mapPermission")) === 0 ||
        JSON.parse(localStorage.getItem("mapPermission")) === 3 ? (
          <Toolbox />
        ) : (
          <SharedToolbox />
        )}
        {/* <Toolbox UserIsOwner={this.state.UserIsOwner} goBack={this.props.history.goBack} /> */}
        <HelpMenu />
        <ContextMenu />
      </React.Fragment>
    );
  }
}

export default withRouter(Map);
