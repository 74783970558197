import React, { Component } from "react";
import freeBadgeImg from "../styles/img/free-badge.png";
import Resources from "../libraries/resources";
import NavMenu from "../components/nav-menu";
import Footer from "../components/footer";

class FeaturesPage extends Component {

  constructor() {
		super();

		this.menuItems = [
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/' : '/',
				title: Resources.getValue("landingPageHomeMsgTxt"),
				activeClass: '',
				externalLink: true
			},
      {
				href: '',
				title: Resources.getValue("landingPageCorporateMsgTxt"),
				activeClass: 'not-active',
				externalLink: false,
				subLinks: [
					{
						href: '/about',
						title: Resources.getValue("landingPageAboutMsgTxt"),
						activeClass: '',
						externalLink: true
					},
					// {
					// 	href: '#references',
					// 	title: Resources.getValue("landingPageReferencesMsgTxt"),
					// 	activeClass: '',
					// 	externalLink: true
					// },
					{
						href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#contact' : '/' + '#contact',
						title: Resources.getValue("landingPageContactMsgTxt"),
						activeClass: '',
						externalLink: true
					}
				]
			},
			// {
			// 	href: '/features',
			// 	title: Resources.getValue("landingPageFeaturesMsgTxt"),
			// 	activeClass: 'home-color-set active',
			// 	externalLink: true
			// },
			{
				href: '/education',
				title: Resources.getValue("landingPageEducationMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/blog',
				title: "Blog",
				activeClass: '',
				externalLink: true
			},
      {
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#price' : '/' + '#price',
				title: Resources.getValue("landingPagePriceMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/kvkk',
				title: Resources.getValue("landingPageKavkkMsgTxt"),
				activeClass: '',
				externalLink: true
			}
		];
	}

  componentDidMount() {
    document.title = "Foramind | " + Resources.getValue("landingPageFeaturesMsgTxt");
  }

  render() {
    return (
      <div className="landing-page features-p" id="landing-page">
        <NavMenu menuItems={this.menuItems} />
        <div className="features-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>{Resources.getValue("featuresPageTitleMsgTxt")}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="content-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {Resources.getValue("landingPageFeaturesMsgTxt")}
                        </th>
                        <th>
                          <img src={freeBadgeImg} alt={Resources.getValue("freeMsgTxt")} />
                          {Resources.getValue("freeMsgTxt")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {Resources.getValue("featureCreateBoxMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureBoxColorMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureBoxShapeTypesMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureBoxSizesMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureBoxBorderColorMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureTextColorMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureFontTypesMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureMapFavoriteMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureMapShareMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureMapDownloadMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureShortcutsMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureAddImageMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureBgTypesMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureAddIconMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureSeeSharedMapsMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureMapTemplatesMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureAdminPanelMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {Resources.getValue("featureBoxRelocationFlexibilityMsgTxt")}
                        </td>
                        <td>
                          <i className="icon-check_circle"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}

export default FeaturesPage;
