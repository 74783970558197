import React, { useEffect, useState } from 'react';
import { msalInstance } from '../config/msalInstance';
import Resources from "../libraries/resources";
import azureIcon from "../../src/styles/img/azure-btn.svg";
import LoginService from "../services/api/login";

function AzureLogin() {
    const [accessToken, setAccessToken] = useState(null);

    const responseAzure = (token) => {
        if (token) {
            LoginService.azureLogin(token);
        }
    };

    const handleLogin = async () => {
        try {
            await msalInstance.loginRedirect({
                scopes: ["api://ad04bed9-faa7-44f4-93b3-55c72c8b9411/foramind-login"]
            });
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    useEffect(() => {
        const handleRedirectResponse = async () => {
            try {
                await msalInstance.initialize(); // MSAL istemcisini başlatma
                const response = await msalInstance.handleRedirectPromise();
                if (response) {
                    msalInstance.setActiveAccount(response.account);
                    setAccessToken(response.accessToken);
                } else {
                    const allAccounts = msalInstance.getAllAccounts();
                    if (allAccounts.length > 0) {
                        msalInstance.setActiveAccount(allAccounts[0]);
                        const tokenResponse = await msalInstance.acquireTokenSilent({
                            scopes: ["api://ad04bed9-faa7-44f4-93b3-55c72c8b9411/foramind-login"],
                            account: allAccounts[0]
                        });
                        setAccessToken(tokenResponse.accessToken);
                    }
                }
            } catch (error) {
                console.error("Failed to handle redirect response", error);
            }
        };

        handleRedirectResponse();
    }, []);

    useEffect(() => {
        if (accessToken) {
            responseAzure(accessToken);
        }
    }, [accessToken]);

    useEffect(() => {
        const messageListener = (event) => {

            if (event.data.action === 'triggerAzureLogin') {
                // Azure ile giriş butonunu tetikleyin
                document.getElementById('azure-login-button').click();
            }
        };

        window.addEventListener('message', messageListener);

        return () => {
            window.removeEventListener('message', messageListener);
        };
    }, []);

    return (
        <div>
            <button
                id="azure-login-button"
                style={{padding: '10px 10px 10px 0px !important', fontSize: '14px'}}
                onClick={handleLogin}
                className="login-social-media google-btn"
            >
                <img style={{ marginRight: '20px'}} src={azureIcon} alt="Azure Icon"/>
                {Resources.getValue("azureLoginMsgTxt")}
            </button>
        </div>

    );
}

export default AzureLogin;
