import React, { Component } from "react";
import Resources from "../libraries/resources";
import Utils from "../libraries/utils";
import LandingPageService from "../services/api/landing-page";
import ImgVideoPreviewModal from "../helpers/img-video-preview-modal";
import NavMenu from "../components/nav-menu";
import Footer from "../components/footer";

import { Link } from "react-router-dom";

// string to html parse
import htmlParse from 'html-react-parser';

class BlogDetailPage extends Component {

	constructor() {
		super();
		this.state = {
			allBlogItem: [],
			colorArray: [
				"#33bdd8",
				"#fd7021",
				"#fcbc2c",
				"#34a853",
				"#9e35cf",
				"#cf3568"
			],
			isPreviewModal: JSON.parse(localStorage.getItem("isCustomModalOpen")),
			media: {}
		};

		this.menuItems = [
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/' : '/',
				title: Resources.getValue("landingPageHomeMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '',
				title: Resources.getValue("landingPageCorporateMsgTxt"),
				activeClass: 'not-active',
				externalLink: false,
				subLinks: [
					{
						href: '/about',
						title: Resources.getValue("landingPageAboutMsgTxt"),
						activeClass: '',
						externalLink: true
					},
					// {
					// 	href: '#references',
					// 	title: Resources.getValue("landingPageReferencesMsgTxt"),
					// 	activeClass: '',
					// 	externalLink: true
					// },
					{
						href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#contact' : '/' + '#contact',
						title: Resources.getValue("landingPageContactMsgTxt"),
						activeClass: '',
						externalLink: true
					}
				]
			},
			// {
			// 	href: '/features',
			// 	title: Resources.getValue("landingPageFeaturesMsgTxt"),
			// 	activeClass: '',
			// 	externalLink: true
			// },
			{
				href: '/education',
				title: Resources.getValue("landingPageEducationMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/blog',
				title: "Blog",
				activeClass: 'home-color-set active',
				externalLink: true
			},
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#price' : '/' + '#price',
				title: Resources.getValue("landingPagePriceMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/kvkk',
				title: Resources.getValue("landingPageKavkkMsgTxt"),
				activeClass: '',
				externalLink: true
			}
		];
	}

	componentDidMount() {
		document.title = "Foramind | Blog";
		this.getBlogList();
	}

	getBlogList() {
		var _this = this;
		var blogName;
		if(this.props.match.params.slug) {
			blogName = this.props.match.params.slug;
		} else {
			blogName = window.location.href.split('/blog/')[1];
		}
		LandingPageService.getBlogItem(this.getBlogsCallback, blogName, _this);
	}

	getBlogsCallback() {
		var allBlogItem = JSON.parse(this.response);

		if (allBlogItem) {
			this.scope.setState({
				allBlogItem: JSON.parse(JSON.stringify(allBlogItem.data))
			})
			console.log(allBlogItem);
			console.log(JSON.parse(JSON.stringify(allBlogItem.data)));

			// data var fakat hepsi passive ise
			// let result = this.scope.state.allBlogItems.every(function (itm) {
			// 	return itm.isActive === false;
			// });
			// this.scope.setState({
			// 	yesDataNoActiveData: result
			// })
		}
	}

	sharedClick = (isOpen) => {
		this.setState({
			isPreviewModal: isOpen
		});
	};

	openThisOnPopup(e) {
		localStorage.setItem("isCustomModalOpen", true);
		this.setState({
			isPreviewModal: JSON.parse(
				localStorage.getItem("isCustomModalOpen")
			)
		});

		if (e.target.tagName === "IMG") {
			this.setState({
				media: {
					type: e.target.tagName,
					src: e.target.getAttribute('src')
				}
			});
		} else { // video ise
			switch (e.target.tagName) {
				case "A":
					this.setState({
						media: {
							type: "VIDEO",
							src: e.target.previousSibling.querySelector('source').getAttribute('src')
						}
					});
					break;
				case "VIDEO":
					this.setState({
						media: {
							type: "VIDEO",
							src: e.target.querySelector('source').getAttribute('src')
						}
					});
					break;
			}
		}
	}

	render() {
		return (
      <React.Fragment>
			<div className="landing-page" id="landing-page">
				{this.state.isPreviewModal ? (
					<ImgVideoPreviewModal sharedClick={this.sharedClick} media={this.state.media} />
				) : null}
				<NavMenu menuItems={this.menuItems} />
				<div className="blog-page-detail">
					
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12">
								<div className="content-wrapper">
								<a className="back-blog-list"
									href={Resources.getValue('appBaseEnvURL') + '/blog'}
									>{Resources.getValue('backToBlogListMsgTxt')}</a>
									{(this.state.allBlogItem !== null)
										? 
											<div>
												{
													(this.state.allBlogItem.isActive === true) && (
														<div className="blog-item">
															<div className="blog-item--title-wrapper">
																<div className="blog-item--title">
																	{this.state.allBlogItem.blogTitle}
																</div>
																<div className="blog-item--blogger-info">
																	<div className="name">
																		{this.state.allBlogItem.blogUserFirstName + " " + this.state.allBlogItem.blogUserLastName}, {Utils.formatDateWithMonthName(this.state.allBlogItem.createdDate)}
																	</div>
																	<div className="image">
																		{(this.state.allBlogItem.blogUserImage) ? (
																			<div
																				style={{
																					background: "url(" + this.state.allBlogItem.blogUserImage + ") center center", backgroundSize: 'contain'
																				}}
																			></div>
																		) : (
																			<div
																				style={{
																					backgroundColor: this.state.colorArray[Math.floor(Math.random() * this.state.colorArray.length)]
																				}}
																			>
																				{this.state.allBlogItem.blogUserFirstName[0].toUpperCase() + this.state.allBlogItem.blogUserLastName[0].toUpperCase()}
																			</div>
																		)}
																	</div>
																</div>
															</div>
															<div className="blog-item--content-wrapper"
																style={{
																	minHeight:
																		(this.state.allBlogItem.blogFiles && this.state.allBlogItem.blogFiles.length === 1)
																			? 300 : (this.state.allBlogItem.blogFiles && this.state.allBlogItem.blogFiles.length > 1) ? 411 : 'auto'
																}}
															>
																{(this.state.allBlogItem.blogFiles && this.state.allBlogItem.blogFiles.length > 0) && (
																	<div className="img-wrapper">
																		{this.state.allBlogItem.blogFiles[0].filePath.substr(this.state.allBlogItem.blogFiles[0].filePath.length - 6).split('.')[1] === 'mp4' ? (
																			<div className="video-wrapper big">
																				<video className="big" nocontrols="true" controlsList="nodownload" preload="metadata" disablePictureInPicture poster={this.state.allBlogItem.blogFiles[0].coverImagePath && this.state.allBlogItem.blogFiles[0].coverImagePath}>
																					<source src={this.state.allBlogItem.blogFiles[0].filePath} type="video/mp4" />
																				</video>
																				<a onClick={(e) => this.openThisOnPopup(e)}></a>
																			</div>
																		) : (
																			<img src={this.state.allBlogItem.blogFiles[0].filePath} alt="Blog Basligi" className="big"
																				onClick={(e) => this.openThisOnPopup(e)}
																			/>
																		)}
																		{this.state.allBlogItem.blogFiles.length > 1 && (
																			<div className="small-images">
																				{this.state.allBlogItem.blogFiles.map((file, ind) => (
																					<div className="item-wrap" key={ind + 1}>
																						{(ind >= 1) && (
																							<div>
																								{file.filePath.substr(file.filePath.length - 6).split('.')[1] === 'mp4' ? (
																									<div className="video-wrapper small">
																										<video className="small" nocontrols="true" controlsList="nodownload" preload="metadata" disablePictureInPicture onClick={(e) => this.openThisOnPopup(e)}>
																											<source src={file.filePath} type="video/mp4" />
																										</video>
																										<a onClick={(e) => this.openThisOnPopup(e)}></a>
																									</div>
																								) : (
																									<img src={file.filePath} alt="Blog Basligi" className="small"
																										onClick={(e) => this.openThisOnPopup(e)}
																									/>
																								)}
																							</div>
																						)}
																					</div>
																				))}
																			</div>
																		)}
																	</div>
																)}
																{htmlParse(this.state.allBlogItem.blogContent)}
															</div>
														</div>
													)
												}
											</div>
										 : (
											<div className="no-blog-data">
												{Resources.getValue("noBlogDataMsgTxt")}
											</div>
										)}
									{/* data var fakat hepsi passive ise */}
									{this.state.yesDataNoActiveData === true && (
										<div className="no-blog-data">
											{Resources.getValue("noBlogDataMsgTxt")}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
      </React.Fragment>
		);
	};
}

export default BlogDetailPage;
