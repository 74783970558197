import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";

const DataService = {
  createXHR: function (_serviceObj) {
    const defaultSettings = {
      keepScope: null,
      contentType: "application/json",
      loadingAnimation: true,
    };

    // merge defaults with customized object
    const serviceObj = { ...defaultSettings, ..._serviceObj };

    // create xhr http request object
    const xhr = new XMLHttpRequest();

    // keep caller func scope
    xhr.scope = serviceObj.keepScope;

    // open xhr & set xhr type & set base+api url
    xhr.open(
      serviceObj.type,
      (serviceObj.isExtarnalApiUrl && serviceObj.isExtarnalApiUrl === true)
        ? serviceObj.url
        : Resources.getValue("apiBaseEnvURL") + serviceObj.url
    );

    // add content type
    xhr.setRequestHeader("Content-type", serviceObj.contentType);

    // add token if has
    if (serviceObj.token === "Bearer") {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
    }

    // send data or nothing
    if (serviceObj.data) {
      xhr.send(serviceObj.data);
    } else {
      xhr.send();
    }

    // open loading overlay
    if (serviceObj.loadingAnimation) {
      if (serviceObj.url === "/ChatGpt/GetChatResponse") { Utils.loadingScreenChatGpt("show"); }
      else {
        if (serviceObj.loadingAnimation) {
          Utils.loadingScreen("show");
        }
      }
    }

    // return xhr http request object
    return xhr;
  },
};

export default DataService;
