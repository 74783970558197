import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";
import SuccessModal from "../../components/modals/success-modal";
const mapJsonObj = {
  isPublicMap: false,
  isFavorite: false,
};

var DocumentsServices = {
  getDocuments: function (companyId) {
    return new Promise((resolve) => {
      const xhr = new DataService.createXHR({
        type: "GET",
        token: "Bearer",
        url: Resources.getValue("getDocuments") + "?companyId=" + companyId,
      });

      xhr.onerror = function () {
        Utils.loadingScreen("hide");
      };

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          // close loading when xhr done
          Utils.loadingScreen("hide");
          if (xhr.status == 200 && xhr.response) {
            const responseData = JSON.parse(xhr.response);
            if (responseData) {
              resolve(responseData.data);
            }
          } else if (xhr.status == 401) {
            LoginService.login();
          }
        }
      };
    });
  },

  CreateMapWithDocument: function (data, callback) {
    Utils.loadingScreenChatGpt("show");
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("getEmbedingChatResponseApiUrl"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreenChatGpt("hide");
      DocumentsServices.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreenChatGpt("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            localStorage.removeItem("openedMapName");
            localStorage.removeItem("openedMapId");
            console.log(responseData);
            localStorage.setItem(
              "resposeDataDocumentId",
              JSON.parse(JSON.stringify(responseData)).id
            );
            localStorage.setItem(
              "resposeDataDocumentName",
              JSON.parse(JSON.stringify(responseData)).name
            );

            let createdMapName = JSON.parse(JSON.stringify(responseData)).name;
            localStorage.setItem("openedMapName", createdMapName);
            localStorage.setItem("mapPermission", 0);
            localStorage.setItem("mapJsonObj", JSON.stringify(mapJsonObj));
            localStorage.setItem("isMapShared", false);
            localStorage.setItem("mapPermission", 0);
            callback();
            // DocumentsServices.openSuccessModal();
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          Utils.loadingScreenChatGpt("hide");
          DocumentsServices.openWarningModal();
        } else {
          Utils.loadingScreenChatGpt("hide");
          DocumentsServices.openErrorModal();
        }
      }
    };
  },

  redirectMap(id) {
    window.open(
      Resources.getValue("appBaseEnvURL") + "/map" + "?mapId=" + id,
      "_self",
      "noopener,noreferrer"
    );
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openSuccessModal: function () {
    SuccessModal({
      title: Resources.getValue(""),
      message: "<p>" + Resources.getValue("") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/",
        },
      ],
      redirectAfterClose: Resources.getValue("appBaseEnvURL") + "/",
    });
  },
};

export default DocumentsServices;
