import React from "react";
import { Route } from "react-router-dom";
import Resources from "../libraries/resources";
import Utils from "../libraries/utils";
import LogoutService from "../services/api/logout";

// Modals
import WarningModal from "../components/modals/warning-modal";

export const PrivateRoute = ({ component: Component, customParams: CustomParams, ...rest }) => {

  CustomParams = CustomParams || {};

  return (<Route
    {...rest}
    render={props => {

      const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
      };

      // get token & userInformation
      var token = localStorage.getItem("token");
      var userInformation = localStorage.getItem('userInformation');

      if (token && userInformation) {
        // parse token & information
        var parsedToken = parseJwt(token);
        var parsedUserInformation = JSON.parse(userInformation);

        // token security control
        if (parsedToken && parsedUserInformation && parsedToken.email == parsedUserInformation.email && parsedToken.sub == parsedUserInformation.id) {
          if (CustomParams.paymentRequired) {
            // authorised so return component

            const todatDate = new Date().toLocaleDateString();
            const updatedTodayDateTime = new Date(todatDate.split('.')[2] + '-' + todatDate.split('.')[1] + '-' + todatDate.split('.')[0] + 'T' + new Date().toLocaleTimeString());
            
            // kurumdan Login/Login ile geliyorsa isExrarnal alani true olacak ve direkt iceri alinacak, degilse diger kontroller yapilacak
               /*    if (JSON.parse(localStorage.getItem('77we1')) === true) {
              // isFreeUser true ise; her halukarda (kurum abonelik olsa da olmasa da) iceri al
              return <Component {...props} />;
            } else { */
              // isFreeUser diger durumlarda hep false olacak ve bu sekilde kontrollere devam et
              if (
                (JSON.parse(localStorage.getItem('c65s1')) !== null)
              ) {
                // 1- abonelik var ama henuz baslamadiysa
                if (
                  updatedTodayDateTime < new Date(JSON.parse(localStorage.getItem('c65s1')).startDate) && JSON.parse(localStorage.getItem('ab0c5')) > 0
                ) {
                  if(!JSON.parse(localStorage.getItem('c65s1')).isActive){
                    WarningModal({
                      exitButtonText: Resources.getValue("exitMsgTxt"),
                      title: Resources.getValue("informationModalTitleMsgTxt"),
                      message: '<p>' + Resources.getValue("companySubscriptionIsNotActiveMsgTxt") + '</p>',
                      buttons: [
                        {
                          text: Resources.getValue("logout2MsgTxt"),
                          class: 'button yellow-button confirm-button',
                          href:''
                         }
                      ]
                    });
                    document.querySelector('.exit-button').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                    document.querySelector('.modalm-overlay').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                    document.querySelector('.confirm-button').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                  }
                  // 1.1- abonelik var, fakat henuz abonelik baslamamis - deneme suresi devam ediyor
                  return <Component {...props} />;
                } else if (
                  updatedTodayDateTime < new Date(JSON.parse(localStorage.getItem('c65s1')).startDate) && JSON.parse(localStorage.getItem('ab0c5')) === 0
                ) { 
                  if(!JSON.parse(localStorage.getItem('c65s1')).isActive){
                    WarningModal({
                      exitButtonText: Resources.getValue("exitMsgTxt"),
                      title: Resources.getValue("informationModalTitleMsgTxt"),
                      message: '<p>' + Resources.getValue("companySubscriptionIsNotActiveMsgTxt") + '</p>',
                      buttons: [
                        {
                          text: Resources.getValue("logout2MsgTxt"),
                          class: 'button yellow-button confirm-button',
                          href:''
                         }
                      ]
                    });
                    document.querySelector('.exit-button').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                    document.querySelector('.modalm-overlay').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                    document.querySelector('.confirm-button').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                    
                  }
                  // 1.2- abonelik var, fakat henuz abonelik baslamamis - deneme suresi bitmis
                  WarningModal({
                    exitButtonText: Resources.getValue("exitMsgTxt"),
                    title: Resources.getValue("informationModalTitleMsgTxt"),
                    message: '<p>' + Resources.getValue("companySubscriptionIsNotStartedYetMsgTxt") + '</p>',
                    buttons: [
                      {
                        text: Resources.getValue("subscribeMsgTxt"),
                        class: 'button yellow-button confirm-button',
                        href: Resources.getValue('appBaseEnvURL') + '/payment'
                      }
                    ],
                    redirectAfterClose: Resources.getValue('appBaseEnvURL') + '/payment'
                  });
                }

                // 2- kurumsal abonelik basladi, kullanildi ve suresi bitti, deneme suresi bitmis
                if (
                  updatedTodayDateTime > new Date(JSON.parse(localStorage.getItem('c65s1')).endDate)
                ) {
                  // 2.1- kurumsal abonelik var, fakat abonelik bitmis
                  // bireysel abonelik varsa
                  if (
                   // JSON.parse(localStorage.getItem('77we1')) ||
                    JSON.parse(localStorage.getItem('ab0c5')) > 0 ||
                    JSON.parse(localStorage.getItem('x8s88')) > 0
                  ) {
                    return <Component {...props} />;
                  } else {
                    // bireysel abonelik yoksa
                    WarningModal({
                      exitButtonText: Resources.getValue("exitMsgTxt"),
                      title: Resources.getValue("informationModalTitleMsgTxt"),
                      message: '<p>' + Resources.getValue("companySubscriptionIsFinishedButPersonelSubscriptionNotStartedYetMsgTxt") + '</p>',
                      buttons: [
                        {
                          text: Resources.getValue("logout2MsgTxt"),
                          class: 'button yellow-button confirm-button',
                          href: ''
                        }
                      ]
                    });
                    document.querySelector('.exit-button').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                    document.querySelector('.modalm-overlay').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                    document.querySelector('.confirm-button').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                  }
                }

                // 2.2- kurumsal abonelik var ve devam ediyorsa
                if (
                  new Date(JSON.parse(localStorage.getItem('c65s1')).startDate) <= updatedTodayDateTime <= new Date(JSON.parse(localStorage.getItem('c65s1')).endDate)
                ) {
                  if(!JSON.parse(localStorage.getItem('c65s1')).isActive){
                    WarningModal({
                      exitButtonText: Resources.getValue("exitMsgTxt"),
                      title: Resources.getValue("informationModalTitleMsgTxt"),
                      message: '<p>' + Resources.getValue("companySubscriptionIsNotActiveMsgTxt") + '</p>',
                      buttons: [
                        {
                          text: Resources.getValue("logout2MsgTxt"),
                          class: 'button yellow-button confirm-button',
                          href:''
                         }
                      ]
                    });
                    document.querySelector('.exit-button').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                    document.querySelector('.modalm-overlay').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                    document.querySelector('.confirm-button').addEventListener('click', function () {
                      LogoutService.Logout();
                    });
                  }
                  return <Component {...props} />;
                }
              } else if (JSON.parse(localStorage.getItem('c65s1')) === null) {
                // kurumsal abonelik zaten yoksa, direkt olarak bireysel abonelik kontrol ediliyor
                if (
                //  JSON.parse(localStorage.getItem('77we1')) ||
                  JSON.parse(localStorage.getItem('ab0c5')) > 0 ||
                  JSON.parse(localStorage.getItem('x8s88')) > 0
                ) {
                  return <Component {...props} />;
                } else {
                  WarningModal({
                    exitButtonText: Resources.getValue("exitMsgTxt"),
                    title: Resources.getValue("informationModalTitleMsgTxt"),
                    message: '<p>' + Resources.getValue("paymentStatusModalMsgTxt") + '</p>',
                    buttons: [
                      {
                        text: Resources.getValue("subscribeMsgTxt"),
                        class: 'button yellow-button confirm-button',
                        href: Resources.getValue('appBaseEnvURL') + '/payment'
                      }
                    ],
                    redirectAfterClose: Resources.getValue('appBaseEnvURL') + '/payment'
                  });
                }
              }
           // }

          } else {
            // payment redirect
            return <Component {...props} />;
          }
        } else {
          window.location.href = Resources.getValue('appBaseEnvURL') + "/login";
        }
      } else {
        window.location.href = Resources.getValue('appBaseEnvURL') + "/login";
      }
    }}
  />);
};

export default PrivateRoute;
