import React, { Component } from "react";
import Utils from "../libraries/utils";
import ContactService from "../services/api/contact";
import Header from "../components/header";
import Resources from "../libraries/resources";
// import IMask from 'imask';
import "../libraries/jscolor";
import ProfileService from "../services/api/profile";
import ClarifyingText from "../components/account-contact-clarifying-text";

class Contact extends Component {

  constructor() {
    super()
    // this.state = {
    //   phone: "",
    // };
  };

  componentDidMount() {
    document.title = "Foramind | " + Resources.getValue("contactMsgTxt");
    Utils.formValidation();
    // this.phoneNumberMask();
    // this.getUserPlans();

    window.contact = function () {
      var contactData = {
        name: this.document.querySelector("#name").value,
        surname: this.document.querySelector("#surname").value,
        email: this.document.querySelector("#email").value,
        // phone: document.querySelector("#phone").value,
        description: this.document.querySelector("#description").value,
      };
      ContactService(JSON.stringify(contactData));
    }
    this.getProfile();
  };

  // phoneNumberMask() {
  //   IMask(document.getElementById('phone'), {
  //     mask: '{+9\\0}(000)-000-00-00'
  //   });
  // };

  keyUpHandler(refName) {
    var len = refName.target.textLength;
    document.getElementById('description').value = document.getElementById('description').value.substring(0, 500);
    if (len >= 500) {
      document.getElementById('current').innerHTML = 500;
      document.getElementById('description').maxLength = 500;
      return false;
    }
    else {
      document.getElementById('current').innerHTML = len;
    }
  }

  getProfile() {
    var callback = this.fillProfile
    ProfileService.getProfile(callback, this);
  };

  fillProfile() {
    var userProfil = JSON.parse(this.response);
    // document.querySelector('#phone').value = userProfil.user.phoneNumber;
    document.querySelector('#name').value = userProfil.user.firstName;
    document.querySelector('#surname').value = userProfil.user.lastName;
    document.querySelector('#email').value = userProfil.user.email;
  }

  clarificationTextModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("accountContactClarifyAgreementTitle"),
      bodyContent:
        '<div class="membership-agreement">' + ClarifyingText() + "</div>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="mindmap-table wide">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <div className="profile-form">
                  <form>
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 offset-0 offset-sm-0 offset-md-2 offset-lg-2 offset-xl-2">
                          <div className="form-group">
                            <h4>{Resources.getValue("contactMsgTxt")}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 offset-0 offset-sm-0 offset-md-2 offset-lg-2 offset-xl-2">
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  className="validate-required"
                                  placeholder={Resources.getValue("firstnameMsgTxt")}
                                  maxLength="50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="surname"
                                  name="surname"
                                  className="validate-required"
                                  placeholder={Resources.getValue("lastnameMsgTxt")}
                                  maxLength="50"
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="row ">
                          <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                            <div className="form-group">
                              <input
                                placeholder={'+90 ' + Resources.getValue("phonenumberMsgTxt")}
                                className="validate-required"
                                id="phone"
                                name="phone"
                                data-minlength="18"
                              />
                            </div>
                          </div>
                        </div> */}
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="email"
                                  name="email"
                                  className="validate-required validate-email contact-email-input"
                                  placeholder={Resources.getValue("currentemailMsgTxt")}
                                  maxLength="50"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <textarea
                                  rows="4"
                                  cols="66"
                                  type="text"
                                  id="description"
                                  name="description"
                                  className="validate-required"
                                  placeholder={Resources.getValue("description")}
                                  title={Resources.getValue("description")}
                                  onKeyUp={this.keyUpHandler}
                                  onChange={this.keyUpHandler}
                                /><div id="the-count">
                                  <span id="current">0</span>
                                  <span id="maximum">/ 500</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0 mt-3 mb-1">
                              <div className="agreement-wrapper">
                                <label className="register-checkbox container-checkbox register-label">
                                  <input
                                    type="checkbox"
                                    className="validate-required"
                                    id="checkbox1"
                                    name="checkbox1"
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <span className="text">
                                  <b>
                                    <u>
                                      <a onClick={this.clarificationTextModal}>
                                        {Resources.getValue("accountContactClarifyAgreement")}
                                      </a>
                                    </u>
                                  </b>
                                  {" " + Resources.getValue("registerMembershipAgreement") + " "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <button
                                  className="yellow-button button submit-form-button float-right"
                                  type="button"
                                  title={Resources.getValue("contactsendMsgTxt")}
                                  data-submit-method="contact">
                                  {Resources.getValue("contactsendMsgTxt")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default Contact;
