import React from "react";
// import MM from "../libraries/shared-map";
import MM from "../libraries/map";
import Utils from "../libraries/utils";
import Resources from "../libraries/resources";
import SharedMapService from "../services/api/shared-map";
import SharedTip from "../components/shared-tip";
import SharedToolbox from "../components/shared-toolbox";
import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
import { withRouter } from 'react-router-dom';

class SharedMap extends React.Component {

  constructor(props) {
    super(props);

    localStorage.setItem('isNewMap', false);

    this.checkMapName();

    this.hubURL = Resources.getValue("signalrBaseEnvURL") + "/api/mindmaphub?mindMapId=" + localStorage.getItem('openedMapId') + "&eMail=" + localStorage.getItem('userMail');

    this.state = {
      hubConnection: null,
    };
  };

  componentWillMount() {
    this.createHubConnection();
    if (
      localStorage.getItem("zoomMap") === null ||
      localStorage.getItem("zoomMap") === '' ||
      localStorage.getItem("zoomMap") === undefined ||
      localStorage.getItem("zoomMap") === NaN
    ) {
      localStorage.setItem("zoomMap", '1.04');
    } else {
      localStorage.setItem("zoomMap", localStorage.getItem("zoomMap")) 
    }
  }

  componentDidMount() {
    MM.App.init();
    MM.App.io.restore();
    this.checkOpenedMap(); 
    this.hasMindMapPermisionForUser();
    this.startHubConnection();
  };

  createHubConnection() {
    const connection = new HubConnectionBuilder()
      .withUrl(`${this.hubURL}`, {
        // accessTokenFactory: () => {
        //   return localStorage.getItem("token");
        // },

        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        // skipNegotiation: false,
        // transport: HttpTransportType.LongPolling,

        // skipNegotiation: true,
        // transport: HttpTransportType.WebSockets,
        skipNegotiation: false,
        transport: HttpTransportType.LongPolling,

      })
      .withAutomaticReconnect()
      // .configureLogging(LogLevel.Debug)
      .build();

    this.setState({
      hubConnection: connection,
    });
  }

  startHubConnection() {
    this.state.hubConnection
      .start()
      .then(() => {})
      .catch((err) => console.log("Error while establishing connection", err));

    // if token exist and user is logged in
    this.state.hubConnection.on("MapReceived", (mindMapId, mapJson, backgroundName) => {
      console.log('MapReceived', mindMapId, mapJson);
      MM.UI.Backend.File.sync.setHubConnectionStatus("active");
      var newData = JSON.parse(mapJson);
      MM.App.setMap(MM.Map.fromJSON(newData));
      window.backgroundImage = backgroundName;
      document.querySelector(
        "#port"
      ).style.background = MM.App.map._getPageBgImage();
      MM.UI.Backend.File.sync.setHubConnectionStatus("syncSave");
    });

    // if token not exist and user is not logged in
    this.state.hubConnection.on("AnonymousMapReceived",(mindMapId, mapJson, backgroundName) => {
      MM.UI.Backend.File.sync.setHubConnectionStatus("active");
      var newData = JSON.parse(mapJson);
      MM.App.setMap(MM.Map.fromJSON(newData));
      window.backgroundImage = backgroundName;
      document.querySelector(
        "#port"
      ).style.background = MM.App.map._getPageBgImage();
      MM.UI.Backend.File.sync.setHubConnectionStatus("syncSave");
    });

    MM.UI.Backend.File.sync.setHubConnection(this.state.hubConnection);
  }

  sendMessage() {
    var mapId = localStorage.getItem("openedMapId");
    // var mapContent = JSON.stringify(MM.App.map.toJSON());

    this.state.hubConnection
      .invoke("SendMapToUsers", mapId)
      .catch((err) => console.error(err));
  }

  mapPageStyle() {
    document.querySelector('body').style.overflow = 'hidden';
  };

  hasMindMapPermisionForUser() {
    var mapId = Utils.getParameterByName('mindMapId') || localStorage.getItem('openedMapId');
    SharedMapService.hasMindMapPermisionForUser(mapId, this.permissionControl);
  }

  permissionControl() {
    var data = JSON.parse(this.response);
    var mapId = Utils.getParameterByName('mindMapId') || localStorage.getItem('openedMapId');
    if(data.result === true) {
      window.location.href = Resources.getValue("appBaseEnvURL") + '/map?mapId=' + mapId;
    }
  }

  checkMapName() {
    if((!localStorage.getItem('newMapName') || !localStorage.getItem('mapTemplate')) && (!localStorage.getItem('openedMapName') || !localStorage.getItem('openedMapId'))){
      window.location.href = Resources.getValue('appBaseEnvURL') + '/mind-map-list';
    }
  };

  checkOpenedMap() {
    var mapId       = Utils.getParameterByName('mapId') || localStorage.getItem('openedMapId');
    var mapTemplate = localStorage.getItem('mapTemplate');
    var userId      = JSON.parse(localStorage.getItem("userInformation")).id;

    if(mapId && !mapTemplate){
      SharedMapService.getSharedMap(userId,mapId,this.drawOpenedMap);
    }else if(mapTemplate && mapTemplate != 'yeni'){
      MM.UI.Backend._loadDone(JSON.parse(mapTemplate));
      localStorage.removeItem('mapTemplate');
    }
  };

  drawOpenedMap() {
    var _this = this;
    const data = JSON.parse(this.response).sharedMindMapMail;
    var mapData = JSON.parse(this.response).sharedMindMapMail.content;
    var mapBackgroundImage = JSON.parse(this.response).sharedMindMapMail.backgroundName;
    var UserIsOwner = JSON.parse(this.response).userIsOwner.userIsOwner;
    localStorage.setItem("mapJsonObj", JSON.stringify(JSON.parse(this.response).sharedMindMapMail));
    localStorage.setItem('mapPermission', JSON.parse(this.response).sharedMindMapMail.mapPermissionId);

    // if(UserIsOwner === false) {
    //   var id = localStorage.getItem('openedMapId');
    //   var userId = JSON.parse(localStorage.getItem("userInformation")).id;
    //   window.location.href = (Resources.getValue("appBaseEnvURL") + '/shared-map' + '?id=' + userId + '&mindMapId=' + id + '&sharedMap=' + true);
    // }

    if(localStorage.getItem('token')) {
      var id = localStorage.getItem('openedMapId');
      if(data.mapPermissionId === 2) {
        window.location.href = (Resources.getValue('appBaseEnvURL') + '/map' + '?mapId=' + id);
      }
      if(data.mapPermissionId === 1) {
        localStorage.setItem('mapPermission', '1');
      }
    }

    if(mapData){
      MM.UI.Backend._loadDone(JSON.parse(mapData));
    }

    if(mapBackgroundImage){
      document.querySelector("#port").style.background = mapBackgroundImage
    }
  };

  render() {
    this.mapPageStyle();
    localStorage.setItem('mapPermission', '1');
    return (
      <React.Fragment>
        <SharedTip />
        <SharedToolbox />
      </React.Fragment>
    );
  };
}

export default withRouter(SharedMap);
