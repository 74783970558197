import React, { Component } from "react";
import Utils from "../libraries/utils";
import NewPasswordService from "../services/api/new-password";
import LoginService from "../services/api/login";
import foramindLogo from "../styles/img/foramind_logo.png";
import Resources from "../libraries/resources";
import ReCAPTCHA from "react-google-recaptcha";


class NewPassword extends Component {

  constructor() {
    super()
    this.state = {
      value: "",
      reCap: false,
      register: false,
      resetToken: Utils.getParameterByName("resetToken"),
      userMail: Utils.getParameterByName("userMail"),
    }
    if (!this.state.resetToken) {
      window.location.href = Resources.getValue('appBaseEnvURL') + "/login"
    }
  };

  componentDidMount() {
    Utils.formValidation();
    var _this = this;
    window.newPassword = function () {
      var newPassword = {
        userMail: _this.state.userMail,
        newPassword: this.document.querySelector('#password').value,
        resetToken: _this.state.resetToken
      };
      NewPasswordService(JSON.stringify(newPassword), _this.fillNewPassword, _this);
    }
  };

  fillNewPassword() {
    var _this = this;
    var userMail = _this.scope.state.userMail;
    var newPassword = document.querySelector('#password').value;
    LoginService.getToken(userMail, newPassword, 'passwordChangedInfo');
  }

  listener() {

    // lang menu event
    var languageSelectElement = document.querySelector('.language-select');
    languageSelectElement.addEventListener('change', function () {
      localStorage.setItem('siteLanguage', this.value);
      window.location.reload();
    });

    languageSelectElement.value = Resources.siteLanguage;
  };

  render() {
    const recaptchaRef = React.createRef();
    return (
      <div className="">
        <div className="col-10 col-sm-11 col-md-12 col-lg-12 col-xl-12 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-0">
          <div className="login-language pull-right">
            <select className="language-select">
              <option value="tr">TR</option>
              <option value="en">EN</option>
            </select>
          </div>
        </div>
        <form className="new-password-form" autoComplete="off">
          <div className="form-wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group text-center form-logo">
                  <img
                    src={foramindLogo}
                    width="150"
                    className="logo"
                    alt="Foramind"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="validate-required validate-password "
                    placeholder={Resources.getValue("newpasswordMsgTxt")}
                    maxLength="20"
                    title={Resources.getValue("passwordMsgTxt")}
                    data-target-password
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="password"
                    id="confirmpassword"
                    name="confirmpassword"
                    className="validate-required validate-password validate-repeat-password"
                    placeholder={Resources.getValue("confirmpasswordMsgTxt")}
                    maxLength="20"
                    title={Resources.getValue("confirmpasswordMsgTxt")}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="normal"
                    sitekey="6LeQvsEUAAAAAI1dnxwWiurOtD7BFTR-rCVH5Tyr"
                    name="google-captcha"
                    className="recaptcha-style"
                    hl={Resources.siteLanguage}
                  />
                  <input type="text" name="google-captcha-input" id="google-captcha-input" className="invisible google-captcha-input validate-google-captcha" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <button
                    className="yellow-button button submit-form-button float-right"
                    type="button"
                    title={Resources.getValue("okMsgTxt")}
                    data-submit-method="newPassword"
                  >
                    {Resources.getValue("okMsgTxt")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };
}

export default NewPassword;
