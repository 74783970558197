import React from "react";
import MM from "../libraries/map"; // shared-map (eski)
import Utils from "../libraries/utils";
import SharedMapService from "../services/api/shared-map";
import AnonymosSharedTip from "../components/anonymous-shared-tip";
import AnonymousSharedToolbox from "../components/anonymous-shared-toolbox";
import LoginService from "../services/api/login";
import Resources from "../libraries/resources";

class AnonymousShared extends React.Component {

  constructor() {
    super();

    localStorage.setItem('isNewMap', false);
  }

  componentDidMount() {
    var mindMapId =
      Utils.getParameterByName("mindMapId") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).mindMapId;
    var email =
      Utils.getParameterByName("email") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).email;
    var privateKey =
      Utils.getParameterByName("privateKey") ||
      JSON.parse(localStorage.getItem("sharedAnonymusData")).privateKey;

    var sharedAnonymusData = {
      mindMapId,
      email,
      privateKey,
    };
    localStorage.setItem(
      "sharedAnonymusData",
      JSON.stringify(sharedAnonymusData)
    );

    this.checkOpenedMap();
    // this.hasMindMapPermisionForUser();
    // MM.App.init();
    // MM.App.io.restore();
  };


  mapPageStyle() {
    document.querySelector('body').style.overflow = 'hidden';
  };

  
  hasMindMapPermisionForUser() {
    var mapId = Utils.getParameterByName('mindMapId') || localStorage.getItem('openedMapId');
    SharedMapService.hasMindMapPermisionForUser(mapId, this.permissionControl);
  }

  permissionControl() {
    var data = JSON.parse(this.response);
    var mapId = Utils.getParameterByName('mindMapId') || localStorage.getItem('openedMapId');
    if(data.result === true) {
      window.location.href = Resources.getValue("appBaseEnvURL") + '/map?mapId=' + mapId;
    } else if(data.result === false && data.errors[0] === "You are not authorized to view the map.") {
      Utils.loadingScreen("hide");
      Utils.modalm().open({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: Resources.getValue("warningMsgTxt"),
        bodyContent: "<p>" + Resources.getValue("youCanNotSeeThisMapMsgTxt") + "</p>",
        buttons: [
          {
            text: Resources.getValue("goToMyMapsMsgTxt"),
            class: "button yellow-button confirm-button",
            href: Resources.getValue("appBaseEnvURL") + "/mind-map-list",
          },
        ],
      });
    }
  }


  checkOpenedMap() {
    var mindMapId   = Utils.getParameterByName('mindMapId')  || JSON.parse(localStorage.getItem('sharedAnonymusData')).mindMapId;
    var email       = Utils.getParameterByName('email')      || JSON.parse(localStorage.getItem('sharedAnonymusData')).email;
    var privateKey  = Utils.getParameterByName('privateKey') || JSON.parse(localStorage.getItem('sharedAnonymusData')).privateKey;

    var sharedAnonymusData = {
      mindMapId,
      email,
      privateKey
    };
    localStorage.setItem("sharedAnonymusData", JSON.stringify(sharedAnonymusData));

    if(mindMapId && privateKey && email){
      SharedMapService.getSharedAnonymousMindMapAsync(privateKey, email, mindMapId, this.drawOpenedMap, this);
    }
    
    // var sharedAnonymusData = {
    //   mindMapId,
    //   email,
    //   privateKey
    // }
    // sessionStorage.setItem("sharedAnonymusData",JSON.stringify(sharedAnonymusData))
    // var userId      = sessionStorage.getItem('userInformation');
    // if(userId){
    //   if(JSON.parse(userId).id){
    //     if(mindMapId && privateKey && email ){
    //       SharedMapService.getSharedAnonymousMindMapAsync(privateKey,email, mindMapId);
    //     }
    //   }
    // }else{
    //   LoginService.login()
    // }
  };

  drawOpenedMap() {
    var _this = this;
    const data = JSON.parse(this.response).sharedMindMapMail;
    localStorage.setItem("mapJsonObj", JSON.stringify(data));
    localStorage.setItem('mapPermission', JSON.parse(this.response).sharedMindMapMail.mapPermissionId);
    localStorage.setItem("IsAnonymous", data.isAnonymous);
    localStorage.setItem('openedMapName', data.mindMapName);
    var mapData = JSON.parse(this.response).sharedMindMapMail.content;
    // var mapBackgroundImage = JSON.parse(this.response).sharedMindMapMail.backgroundName;

    if(localStorage.getItem('token')) {
      var id = localStorage.getItem('openedMapId') || Utils.getParameterByName('mindMapId');
      var userId = JSON.parse(localStorage.getItem("userInformation")).id;

      if(data.mapPermissionId === 1) {
        window.location.href = (Resources.getValue("appBaseEnvURL") + '/shared-map' + '?id=' + userId + '&mindMapId=' + id + '&sharedMap=' + true);
      } else if(data.mapPermissionId === 2 || data.mapPermissionId === 3) {
        (data.mapPermissionId === 3) && localStorage.setItem('mapPermission', 0);
        window.location.href = (Resources.getValue('appBaseEnvURL') + '/map' + '?mapId=' + id);
      }
    } else {
      LoginService.redirectToLogin();
    }

    if(mapData){
      // MM.UI.Backend._loadDone(JSON.parse(mapData));
      Utils.loadingScreen("show"); // for not show map, only show loading
    }

    // if(mapBackgroundImage){
    //   document.querySelector(".tip").style.background = mapBackgroundImage
    // }
  };

  render() {
    this.mapPageStyle();
    return (
      <div>
        <AnonymosSharedTip />
        <AnonymousSharedToolbox goBack={this.props.history.goBack} />
      </div>
    );
  };
}

export default AnonymousShared;
