import React, { Component } from "react";
import Resources from "../libraries/resources";
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import ReactTable from "react-table";
import "react-table/react-table.css";
// import MyPlannerService from "../services/api/my-planner";

class TaskBoards extends Component {

  // constructor(props){
  //   super(props)
  //   this.state = {
  //     data: this.fillMyPlanner

  //   }
  // }
  // componentDidMount() {
  //   this.myPlanner();
  // };

  // myPlanner() {
  //   var recordSize = 100;
  //   var _id = sessionStorage.getItem("userInformation");
  //   var id = JSON.parse(_id).id
  //   MyPlannerService.getPlanList(recordSize, id, this.fillMyPlanner, this);
  // };

  // fillMyPlanner(){
  //   var myPlannerList    = JSON.parse(this.response).id;
  // }

  render() {
    const data = this.state;
    return (
      <React.Fragment>
        <Header />
        <div className="taskboards-table">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="template-panel">
                  <div className="row">
                    <div className="col-md-12 px-5">
                      <SubHeader title={Resources.getValue("taskBoardMsgTxt")} iconClass="fa fa-calendar" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-list">
                        <ReactTable
                          _data={data}
                          filterable
                          previousText={Resources.getValue("previousTextMsgTxt")}
                          nextText={Resources.getValue("nextTextMsgTxt")}
                          pageText={Resources.getValue("pageTextMsgTxt")}
                          rowsText={Resources.getValue("rowsTextMsgTxt")}
                          noDataText={Resources.getValue("noDataTextMsgTxt")}
                          loadingText={Resources.getValue("loadingTextMsgTxt")}
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                          columns={[
                            {

                              columns: [
                                {
                                  Header: Resources.getValue("plannernameMsgTxt"),
                                  accessor: "plannername",
                                  filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value) &&
                                    row[filter.id].endsWith(filter.value)
                                },
                              ]
                            },
                            {
                              columns: [
                                {
                                  Header: Resources.getValue("creationDateMsgTxt"),
                                  accessor: "creationDate"
                                },
                              ]
                            },

                            {
                              columns: [
                                {
                                  Header: Resources.getValue("modifieddateMsgTxt"),
                                  accessor: "modifieddate"
                                },
                              ]
                            },
                            {
                              columns: [
                                {
                                  Header: Resources.getValue("importanceMsgTxt"),
                                  accessor: "importance"
                                },
                              ]
                            },
                            {
                              columns: [
                                {
                                  Header: Resources.getValue("urgencyMsgTxt"),
                                  accessor: "urgency"
                                },
                              ]
                            },
                            {
                              columns: [
                                {
                                  Header: Resources.getValue("urgencyMsgTxt"),
                                  accessor: "urgency"
                                },
                              ]
                            },
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default TaskBoards;
