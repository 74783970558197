import React, { Component } from "react";
import Utils from "../libraries/utils";
import RegisterService from "../services/api/register";
import loginRegisterLogo from "../styles/img/foramind_logo.png";
import keyIcon from "../styles/img/key-icon.png";
import personIcon from "../styles/img/person-icon.png";
import emailIcon from "../styles/img/email-icon.png";
import visibleIcon from "../styles/img/visible-icon.png";
import visibleOffIcon from "../styles/img/visible-off-icon.png";
import Resources from "../libraries/resources";
import ReCAPTCHA from "react-google-recaptcha";
import LanguageSelect from "../components/language-select";
import MembershipAgreementText from "../components/membership-agreement-text";
import ClarifyingText from "../components/register-clarifying-text";
import KVKKDeclarationText from "../components/kvkk-declaration-text";
import GoogleLogin from "react-google-login";
import LoginService from "../services/api/login";
import GetUserInfoAfterGoogleLoginModal from "../helpers/get-user-info-after-google-login-modal";

class Register extends Component {
  constructor() {
    super();

    this.state = {
      value: "",
      reCap: false,
      register: false,
      passwordInputType: 'password'
    };
  }

  componentDidMount() {
    document.title = "Foramind | " + Resources.getValue("registerMsgTxt");
    Utils.formValidation();

    window.register = function () {
      var registerData = {
        firstname: this.document.querySelector("#firstName").value,
        lastname: this.document.querySelector("#lastName").value,
        email: this.document.querySelector("#email").value.toLowerCase(),
        password: this.document.querySelector("#password").value,
        kvkkConfirmStatus: this.document.querySelector(
          "#kvkkConfirmStatus input"
        ).checked,
      };
      RegisterService(JSON.stringify(registerData));
    };
  }

  listener() {
    // sub menu event
    var menu = document.getElementsByClassName("sub-menu-toggle");
    for (var i = 0; i < menu.length; i++) {
      menu[i].addEventListener("click", function () {
        var submenu = this.querySelector(".sub-menu");
        var icon = this.querySelector(".dropdown-item i.fa.fa-chevron-up");
        submenu.classList.toggle("none");
        icon.classList.toggle("rotate");
      });
    }
  }

  membershipAgreementModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("membershipAgreement"),
      bodyContent:
        '<div class="membership-agreement">' +
        MembershipAgreementText() +
        "</div>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
    });
  }

  clarificationTextModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("registerClarifyAgreementTitle"),
      bodyContent:
        '<div class="membership-agreement">' + ClarifyingText() + "</div>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
    });
  }

  kvkkAgreementModal() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("allowMemberShipAgreement"),
      bodyContent:
        '<div class="membership-agreement">' + KVKKDeclarationText() + "</div>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
    });
  }

  changeVisible = () => {
    var passInput = document.querySelector("#password");
    if (passInput.type === "password") {
      passInput.type = "text";
      this.setState({
        passwordInputType: passInput.type
      });
    } else {
      passInput.type = "password";
      this.setState({
        passwordInputType: passInput.type
      });
    }
  }

  responseGoogle = (response) => {
    localStorage.setItem("gt", JSON.stringify(response)); // geçici google login kaydı, daha sonra silinecek
    if (response.tokenId) {
      var token = response.tokenId;
      LoginService.googleLogin(token);
    }
  };

  render() {
    const recaptchaRef = React.createRef();
    // sayfa yuklendiginde kontrol edilliyor (google ile login'de)
    if (localStorage.getItem("googleEmail") !== null) {
      if (
          localStorage.getItem("userInformation") !== null &&
          JSON.parse(localStorage.getItem("userInformation")).hasSetUserType ===
          false
      ) {
        if (
            JSON.parse(localStorage.getItem("userInformation")).companyId != 12 ||
            JSON.parse(localStorage.getItem("userInformation")).companyId != 3
        ) {
          localStorage.setItem("isCustomModalOpen", false);
          LoginService.baseRedirect("");
        } else {
          localStorage.setItem("isCustomModalOpen", true);
        }
      } else {
        localStorage.setItem("isCustomModalOpen", false);
      }
    } else {
      localStorage.setItem("isCustomModalOpen", false);
    }
    return (
      <div>
        {JSON.parse(localStorage.getItem("isCustomModalOpen")) === true ? (
            <GetUserInfoAfterGoogleLoginModal />
        ) : null}
        
        <div className="col-10 col-sm-11 col-md-12 col-lg-12 col-xl-12 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-0">
          <div className="login-language pull-right">
            <LanguageSelect />
          </div>
        </div>
        <form className="register-form" autoComplete="off">
          <div className="form-wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group form-logo">
                  <div className="text-center">
                    <a href={Resources.getValue('appBaseEnvURL') === '/foramind' ? Resources.getValue('appBaseEnvURL') : '/'}>
                      <img
                          src={loginRegisterLogo}
                          width="170"
                          className="logo"
                          alt="Foramind"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group with-icon">
                  <img src={personIcon} alt={Resources.getValue("firstnameMsgTxt")} className="left-icon"/>
                  <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="validate-required"
                      placeholder={Resources.getValue("firstnameMsgTxt")}
                      maxLength="50"
                      title={Resources.getValue("firstnameMsgTxt")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group with-icon">
                  <img src={personIcon} alt={Resources.getValue("lastnameMsgTxt")} className="left-icon"/>
                  <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="validate-required"
                      placeholder={Resources.getValue("lastnameMsgTxt")}
                      maxLength="50"
                      title={Resources.getValue("lastnameMsgTxt")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group with-icon">
                  <img src={emailIcon} alt={Resources.getValue("emailMsgTxt")} className="left-icon"/>
                  <input
                      type="text"
                      id="email"
                      name="email"
                      className="validate-required validate-email"
                      placeholder={Resources.getValue("emailMsgTxt")}
                      maxLength="50"
                      title={Resources.getValue("emailMsgTxt")}
                      autoCapitalize="none"
                  />
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    placeholder={'+90 ' + Resources.getValue("phonenumberMsgTxt")}
                    className="validate-required"
                    id="phoneNumber"
                    name="phoneNumber"
                    data-minlength="18"
                  />
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group with-icon">
                  <img src={keyIcon} alt={Resources.getValue("passwordMsgTxt")} className="left-icon"/>
                  <input
                      type="password"
                      id="password"
                      name="password"
                      className="validate-required validate-password"
                      placeholder={Resources.getValue("passwordMsgTxt")}
                      maxLength="20"
                      title={Resources.getValue("passwordMsgTxt")}
                      data-target-password
                  />
                  <img
                      src={this.state.passwordInputType == 'text' ? visibleIcon : visibleOffIcon}
                      alt={Resources.getValue('visibilityMsgTxt')}
                      className="right-icon"
                      onClick={this.changeVisible}
                  />
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="password"
                    id="confirmpassword"
                    name="confirmpassword"
                    className="validate-required validate-password validate-repeat-password"
                    placeholder={Resources.getValue("confirmpasswordMsgTxt")}
                    maxLength="20"
                    title={Resources.getValue("confirmpasswordMsgTxt")}
                  />
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <ReCAPTCHA
                      ref={recaptchaRef}
                      size="normal"
                      sitekey="6LcMUHMfAAAAAGLQnvXvUzyhyy1S2fb3yAXw18IG"
                      name="google-captcha"
                      className="recaptcha-style"
                      id="google-captcha"
                      hl={Resources.siteLanguage}
                  />
                  <input
                      type="text"
                      name="google-captcha-input"
                      id="google-captcha-input"
                      className="invisible google-captcha-input validate-google-captcha"
                  />
                  {this.state.register && !this.state.reCap ? (
                      <label className="recaptcha-text">
                        {Resources.getValue("requiredMsgTxt")}
                      </label>
                  ) : (
                      ""
                  )}
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 mb-3">
                <div className="agreement-wrapper">
                  <label className="register-checkbox container-checkbox register-label">
                    <input
                        type="checkbox"
                        className="validate-required"
                        id="checkbox"
                        name="checkbox"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span className="text">
                    <b>
                      <u>
                        <a onClick={this.membershipAgreementModal}>{Resources.getValue("membershipAgreement")}</a>
                      </u>
                    </b>
                    {" " + Resources.getValue("registerMembershipAgreement") + " "}
                  </span>
                  <a onClick={this.membershipAgreementModal}>
                    <i
                        className="fa fa-question-circle"
                        aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="agreement-wrapper">
                  <label
                      id="kvkkConfirmStatus"
                      className="register-checkbox container-checkbox register-label"
                  >
                    <input type="checkbox" className="validate-required" name="kvkkConfirmStatus"/>
                    <span className="checkmark"></span>
                  </label>
                  <span className="text">
                    <b>
                      <u>
                        <a onClick={this.clarificationTextModal}>
                          {Resources.getValue("registerCheckboxClarifyAgreement")}
                        </a>
                      </u>
                    </b>
                    {" " + Resources.getValue("registerCheckboxAllowClarifyAgreement") + " "}
                  </span>
                  <a onClick={this.clarificationTextModal}>
                    <i
                        className="fa fa-question-circle"
                        aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row ">
              <div className="col-md-12">
                <div className="agreement-wrapper">
                  <label
                    id="kvkkConfirmStatus"
                    className="register-checkbox container-checkbox register-label"
                  >
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                  <span className="text">
                    <b>
                      <u>
                        <a onClick={this.kvkkAgreementModal}>{Resources.getValue("registerCheckboxAllowAgreement")}</a>
                      </u>
                    </b>
                    {" " + Resources.getValue("registerCheckboxAllow") + " "}
                  </span>
                  <a onClick={this.kvkkAgreementModal}>
                    <i
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div> */}
            <div className="row ">
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <button
                      className="yellow-button button submit-form-button login-custom-btn"
                      type="button"
                      title={Resources.getValue("registerSaveMsgTxt")}
                      data-submit-method="register"
                  >
                    {Resources.getValue("registerSaveMsgTxt")}
                  </button>
                </div>
                <div className="form-group">
                  <a
                      className="link-button button create-account-btn click-link"
                      href={Resources.getValue("appBaseEnvURL") + "/login"}
                      title={Resources.getValue("goTologinMsgTxt")}
                  >
                    {Resources.getValue("goTologinMsgTxt")}
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <GoogleLogin
                      clientId="545262035465-o6771i0sk22ncm48o0pumkbrlc48ml1f.apps.googleusercontent.com"
                      buttonText={Resources.getValue("googleLoginMsgTxt")}
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={"single_host_origin"}
                      className="login-social-media google-btn"
                      icon={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Register;
