import React, { Component } from "react";
import PaymentService from "../services/api/payment";
// const https = require('https');

class SampasEnerjiPage extends Component {

	constructor() {
		super();
		this.state = { 
			activeTab: "enerji-tuketimi",
			detailedBuilding: null
 		};
	}

	componentDidMount() {
		this.getBuildingDetails();
	}

	getBuildingDetails() {
    var callback = this.fillBuildingDetails
    PaymentService.getBuildingDetails(callback, this, '92');
  }

  fillBuildingDetails() {
    var _this = this;
    var buildingInfo = JSON.parse(this.response);
    _this.scope.setState({
      detailedBuilding: buildingInfo
    })
  }

	showInMap() {
		//
	}


	render() {
		return (
			<div className="landing-page">
				<div className="sampas-page">
					<div className="sampas-widget">
						<div className="tablo">
							<ul className="list-unstyled">
								<li>
									<span className="title">Bina Numarası</span>
									<span className="info">: {this.state.detailedBuilding?.bina?.BinaNumarasi}92</span>
								</li>
								<li>
									<span className="title">Site Apartman Adı</span>
									<span className="info">: {this.state.detailedBuilding?.bina?.SiteApartmanAdi}ZABITA MÜDÜRLÜĞÜ</span>
								</li>
								<li>
									<span className="title">Bina Kullanımı</span>
									<span className="info">: {this.state.detailedBuilding?.bina?.BinaKullanimi}</span>
								</li>
							</ul>
							<ul className="list-unstyled">
								<li>
									<span className="title">Mahalle Adı</span>
									<span className="info">: {this.state.detailedBuilding?.bina?.MahalleAdi}</span>
								</li>
								<li>
									<span className="title">Cadde Sokak Adı</span>
									<span className="info">: {this.state.detailedBuilding?.bina?.CaddeSokakAdi}</span>
								</li>
								<li>
									<span className="title">İnşaat Sınıfı</span>
									<span className="info">: {this.state.detailedBuilding?.bina?.InsaatSinifi}</span>
								</li>
								<li>
									<span className="title">İnşaat Türü</span>
									<span className="info">:{this.state.detailedBuilding?.bina?.InsaatTuru}</span>
								</li>
							</ul>
						</div>
						<div className="tabs">
							<button
								className={`btn tabs-buttons ${this.state.activeTab === "enerji-tuketimi" && 'active'}`}
								onClick={() => this.setState({ activeTab: "enerji-tuketimi" })}
							>
								Enerji Tüketimi
							</button>
							<button
								className={`btn tabs-buttons ml-1 ${this.state.activeTab === "enerji-kimlik-belge" && 'active'}`}
								onClick={() => this.setState({ activeTab: "enerji-kimlik-belge" })}
							>
								Enerji Kimlik Belgesi
							</button>
							<button
								className={`btn tabs-buttons ml-1 ${this.state.activeTab === "harita" && 'active'}`}
								onClick={() => this.setState({ activeTab: "harita" })}
							>
								Harita
							</button>
							<div className="tab-contents show">
								{this.state.activeTab === "enerji-tuketimi" && (
									<>
									<table>
										<thead>
											<tr>
												<th>Tüketim Kwh</th>
												<th>Tüketim Tl</th>
												<th>Fatura Dönemi Yıl</th>
												<th>Abone Numarası</th>
												<th>Fatura Dönemi Ay</th>
											</tr>
										</thead>
										<tbody>
										{this.state.detailedBuilding?.bina?.enerjiVerileri.map((enerji) => {
											return (
												<>
													<tr>
														<td>{enerji?.TuketimKwh}</td>
														<td>{enerji?.TuketimTl}</td>
														<td>{enerji?.FaturaDonemiYil}</td>
														<td>{enerji?.AboneNumarasi}</td>
														<td>{enerji?.FaturaDonemiAy}</td>
													</tr>
												</>
											)
										})}
											<tr>
												<td>1015.56</td>
												<td>828.69</td>
												<td>2022</td>
												<td>1008820062</td>
												<td>5</td>
											</tr>
											<tr>
												<td>1015.56</td>
												<td>828.69</td>
												<td>2022</td>
												<td>1008820062</td>
												<td>5</td>
											</tr>
										</tbody>
									</table>
									</>
								)}
								{this.state.activeTab === "enerji-kimlik-belge" && (
									<>
										<object width="100%" height="170" data="https://kafein.fra1.digitaloceanspaces.com/foramind/ekb-enerji-kimlik-belgesi-c3840.pdf" type="application/pdf"> </object>
									</>
								)}
								{this.state.activeTab === "harita" && (
									<>
										{/* <iframe width="100%" height="160" frameborder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://www.openstreetmap.org/export/embed.html?bbox=${'36.2338856586507'}%2C${'37.0769751233189'}%2C${'36.2338856586507'}%2C${'37.0769751233189'}&amp;layer=mapnik&amp;marker=${'37.0769751233189'}%2C${'36.2338856586507'}`} style={{ border: '1px solid black' }}></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=37.0769751233189&amp;mlon=36.2338856586507#map=18/37.0769751233189/36.2338856586507&amp;layers=N">Daha Büyük Haritayı Göster</a></small> */}

										<iframe width="100%" height="160" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=36.230431795120246%2C37.07554386408584%2C36.23733043670655%2C37.078398539940615&amp;layer=mapnik&amp;marker=37.07697%2C36.23388" style={{ border: '1px solid black' }}></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=37.07697&amp;mlon=36.23388#map=18/37.07697/36.23388&amp;layers=N">Daha Büyük Haritayı Göster</a></small>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
}

export default SampasEnerjiPage;
