import React, { Component } from "react";
import Hammer from "react-hammerjs";
import MM from "../libraries/map"; // shared-map (eski)

class AnonymosSharedTip extends Component {

  constructor(props){
    super(props);
    this.state= { 
      zoomRatio : "65%"
    }
  }

  componentDidMount(){
    var _this = this;
    document.addEventListener('wheel',function (event){
      //only vertical scroll
      var ratio = document.querySelector("#port").style.fontSize;
      _this.setState({
        zoomRatio : ratio
    })
    })
  }
  
  portOnPinch(e) {
    if (e.pointers[0].type == 'pointermove' && e.pointers[1].type == 'pointermove') {
      var dist;
      if (e.deltaY) {
        if (e.deltaY > 0) {
          dist = -1;
        } else if (e.deltaY < 0) {
          dist = 1;
        }
      }
      if (dist) {
        MM.App.adjustFontSize(dist);
      }
    }
  };

  zoomInOutButton(e){
    MM.App.adjustFontSize(e);
    var ratio = document.querySelector("#port").style.fontSize;
    this.setState({
      zoomRatio : ratio
    })
  }

  
  render() {
    return (
      <Hammer onPinch={this.portOnPinch} options={{
        recognizers: {
          pinch: { enable: true }
        }
      }}>
        <div className="tip" className="none">
          <ul id="port">
            <div id="tip">
              Press ‘Tab’ to Insert Child, ‘Enter’ to Insert Sibling Node. For
              more tips/news, follow.
            </div>
          </ul>
          <button id="zoom-in" className="zoom-in" onClick={()=>{this.zoomInOutButton(1)}}><i className="fa fa-plus plus-minus" aria-hidden="true"></i></button>
          <button id="zoom-out" className="zoom-out" onClick={()=>{this.zoomInOutButton(-1)}}><i className="fa fa-minus plus-minus" aria-hidden="true"></i></button>
          <span className="zoom-ratio">{this.state.zoomRatio }</span>
        </div>
      </Hammer >
    );
  }
}

export default AnonymosSharedTip;
