import React, { Component } from "react";
import Resources from "../libraries/resources";
import Utils from "../libraries/utils";
import MM from "../libraries/map";

class HelpMenu extends Component {

  componentDidMount() {
    this.listener();
    this.openModal()
  };

  openModal() {
    document.querySelector(".close-help").addEventListener("click", function () {
      document.querySelector("#help").classList.remove("visible")
    })
  }

  listener() {

    // mobile swipe
    if (window.innerWidth && (window.innerWidth < 768)) { // if is mobile
      Utils.swipe(document.querySelector('#help'), null, MM.Command.Help.execute);
    }
  };

  render() {
    return (
      <div className="help-menu">
        <div id="help" className="ui">
          <a className="close-help" id="">&times;</a>
          <div className="help-wrap">
            <p>
              <span>{Resources.getValue('navigationMsgTxt')}</span>
            </p>
            <table className="navigation"></table>

            <p>
              <span>{Resources.getValue('manipulationMsgTxt')}</span>
            </p>
            <table className="manipulation"></table>

            <p>
              <span>{Resources.getValue('editingMsgTxt')}</span>
            </p>
            <table className="editing"></table>

            <p>
              <span>{Resources.getValue('otherMsgTxt')}</span>
            </p>
            <table className="other"></table>
          </div>
        </div>
      </div>
    );
  }
}

export default HelpMenu;
