import MapService from "../services/api/map";
import Utils from "../libraries/utils";
import Resources from "../libraries/resources";
import Resizer from "react-image-file-resizer";
import * as htmlToImage from "html-to-image";

const layoutMapUpdateDelay = new Utils.delay();
const onItemTextChangeDelay = new Utils.delay();
let xSpaceBetweenNodes = 40; // horizontal space between all nodes
let ySpaceBetweenNodes = 15; // vertical space between all nodes
let mapIsLoadedDisplayed = false; // flag for map loading detection
let itemOrders = [];

const colorPalette = [
  "#4aa2fd",
  "#ff84cb",
  "#59d46b",
  "#c195ff",
  "#f78788",
  "#61998d",
  "#d8b332",
  "#9c3b5e",
  "#dc4e4f",
  "#ff7f00",
];

const autoPainter = function () {
  var color = colorPalette[0];
  colorPalette.shift();

  if (color) colorPalette.push(color);

  return color;
};

/* My Mind web app: all source files combined. */
if (!Function.prototype.bind) {
  Function.prototype.bind = function (thisObj) {
    var fn = this;
    var args = Array.prototype.slice.call(arguments, 1);
    return function () {
      return fn.apply(
        thisObj,
        args.concat(Array.prototype.slice.call(arguments))
      );
    };
  };
}

var MM = {
  _subscribers: {},

  publish: function (message, publisher, data) {
    var subscribers = this._subscribers[message] || [];
    subscribers.forEach(function (subscriber) {
      subscriber.handleMessage(message, publisher, data);
    });
  },

  subscribe: function (message, subscriber) {
    if (!(message in this._subscribers)) {
      this._subscribers[message] = [];
    }
    var index = this._subscribers[message].indexOf(subscriber);
    if (index == -1) {
      this._subscribers[message].push(subscriber);
    }
  },

  unsubscribe: function (message, subscriber) {
    var index = this._subscribers[message].indexOf(subscriber);
    if (index > -1) {
      this._subscribers[message].splice(index, 1);
    }
  },

  generateId: function () {
    var id =
      String(new Date().getTime()) +
      String(Math.floor(Math.random() * 100000000000000000000));
    return id;
  },
};

var Promise = function (executor) {
  this._state = 0; /* 0 = pending, 1 = fulfilled, 2 = rejected */
  this._value = null; /* fulfillment / rejection value */

  this._cb = {
    fulfilled: [],
    rejected: [],
  };

  this._thenPromises = []; /* promises returned by then() */

  executor && executor(this.fulfill.bind(this), this.reject.bind(this));
};

Promise.resolve = function (value) {
  return new Promise().fulfill(value);
};

Promise.reject = function (value) {
  return new Promise().reject(value);
};

Promise.prototype.then = function (onFulfilled, onRejected) {
  this._cb.fulfilled.push(onFulfilled);
  this._cb.rejected.push(onRejected);

  var thenPromise = new Promise();

  this._thenPromises.push(thenPromise);

  if (this._state > 0) {
    setTimeout(this._processQueue.bind(this), 0);
  }

  /* 3.2.6. then must return a promise. */
  return thenPromise;
};

/**
 * Fulfill this promise with a given value
 * @param {any} value
 */
Promise.prototype.fulfill = function (value) {
  if (this._state != 0) {
    return this;
  }

  this._state = 1;
  this._value = value;

  this._processQueue();

  return this;
};

/**
 * Reject this promise with a given value
 * @param {any} value
 */
Promise.prototype.reject = function (value) {
  if (this._state != 0) {
    return this;
  }

  this._state = 2;
  this._value = value;

  this._processQueue();

  return this;
};

/**
 * Pass this promise's resolved value to another promise
 * @param {Promise} promise
 */
Promise.prototype.chain = function (promise) {
  return this.then(promise.fulfill.bind(promise), promise.reject.bind(promise));
};

/**
 * @param {function} onRejected To be called once this promise gets rejected
 * @returns {Promise}
 */
Promise.prototype["catch"] = function (onRejected) {
  return this.then(null, onRejected);
};

Promise.prototype._processQueue = function () {
  while (this._thenPromises.length) {
    var onFulfilled = this._cb.fulfilled.shift();
    var onRejected = this._cb.rejected.shift();
    this._executeCallback(this._state == 1 ? onFulfilled : onRejected);
  }
};

Promise.prototype._executeCallback = function (cb) {
  var thenPromise = this._thenPromises.shift();

  if (typeof cb != "function") {
    if (this._state == 1) {
      /* 3.2.6.4. If onFulfilled is not a function and promise1 is fulfilled, promise2 must be fulfilled with the same value. */
      thenPromise.fulfill(this._value);
    } else {
      /* 3.2.6.5. If onRejected is not a function and promise1 is rejected, promise2 must be rejected with the same reason. */
      thenPromise.reject(this._value);
    }
    return;
  }

  try {
    var returned = cb(this._value);

    if (returned && typeof returned.then == "function") {
      /* 3.2.6.3. If either onFulfilled or onRejected returns a promise (call it returnedPromise), promise2 must assume the state of returnedPromise */
      var fulfillThenPromise = function (value) {
        thenPromise.fulfill(value);
      };
      var rejectThenPromise = function (value) {
        thenPromise.reject(value);
      };
      returned.then(fulfillThenPromise, rejectThenPromise);
    } else {
      /* 3.2.6.1. If either onFulfilled or onRejected returns a value that is not a promise, promise2 must be fulfilled with that value. */
      thenPromise.fulfill(returned);
    }
  } catch (e) {
    /* 3.2.6.2. If either onFulfilled or onRejected throws an exception, promise2 must be rejected with the thrown exception as the reason. */
    thenPromise.reject(e);
  }
};
/**
 * Wait for all these promises to complete. One failed => this fails too.
 */
Promise.all = Promise.when = function (all) {
  var promise = new this();
  var counter = 0;
  var results = [];

  for (var i = 0; i < all.length; i++) {
    counter++;
    all[i].then(
      function (index, result) {
        results[index] = result;
        counter--;
        if (!counter) {
          promise.fulfill(results);
        }
      }.bind(null, i),
      function (reason) {
        counter = 1 / 0;
        promise.reject(reason);
      }
    );
  }

  return promise;
};

/**
 * Promise-based version of setTimeout
 */
Promise.setTimeout = function (ms) {
  var promise = new this();
  setTimeout(function () {
    promise.fulfill();
  }, ms);
  return promise;
};

/**
 * Promise-based version of addEventListener
 */
Promise.event = function (element, event, capture) {
  var promise = new this();
  var cb = function (e) {
    element.removeEventListener(event, cb, capture);
    promise.fulfill(e);
  };
  element.addEventListener(event, cb, capture);
  return promise;
};

/**
 * Promise-based wait for CSS transition end
 */
Promise.transition = function (element) {
  if ("transition" in element.style) {
    return this.event(element, "transitionend", false);
  } else if ("webkitTransition" in element.style) {
    return this.event(element, "webkitTransitionEnd", false);
  } else {
    return new this().fulfill();
  }
};

/**
 * Promise-based version of XMLHttpRequest::send
 */
Promise.send = function (xhr, data) {
  var promise = new this();
  xhr.addEventListener("readystatechange", function (e) {
    if (e.target.readyState != 4) {
      return;
    }
    if (e.target.status.toString().charAt(0) == "2") {
      promise.fulfill(e.target);
    } else {
      promise.reject(e.target);
    }
  });
  xhr.send(data);
  return promise;
};

Promise.worker = function (url, message) {
  var promise = new this();
  var worker = new Worker(url);
  Promise.event(worker, "message").then(function (e) {
    promise.fulfill(e.data);
  });
  Promise.event(worker, "error").then(function (e) {
    promise.reject(e.message);
  });
  worker.postMessage(message);
  return promise;
};
/**
 * Prototype for all things categorizable: shapes, lines, layouts, commands, formats, backends...
 */
MM.Repo = {
  id: "" /* internal ID */,
  label: "" /* human-readable label */,
  getAll: function () {
    var all = [];
    for (var p in this) {
      var val = this[p];
      if (this.isPrototypeOf(val)) {
        all.push(val);
      }
    }
    return all;
  },
  getByProperty: function (property, value) {
    return (
      this.getAll().filter(function (item) {
        return item[property] == value;
      })[0] || null
    );
  },
  getById: function (id) {
    return this.getByProperty("id", id);
  },
  buildOption: function () {
    var o = document.createElement("option");
    o.value = this.id;
    o.innerHTML = this.label;
    return o;
  },
};

MM.Item = function (props) {
  const _props = {};
  Object.assign(_props, props);

  const setItemProps = () => {
    this._parent = null;
    this._children = [];
    this._collapsed = false;
    this._layout = null;
    this._shape = null;
    this._autoShape = true;
    this._parentShape = true;
    this._shapeSize = null;
    this._autoShapeSize = true;
    this._fontFamily = null;
    this._autoFont = true;
    this._fontSize = null;
    this._autoFontSize = true;
    this._line = null;
    this._autoLine = true;
    this._color = null;
    this._borderColor = null;
    this._textColor = null;
    this._value = null;
    this._side = _props.side ? _props.side : null;
    this._nodeBackground = _props.nodeBackground === false ? false : true;
    this._imageItem = false;
    this._nodeLine = true;
    this._position = null;
    this._icon = null;
    this._bgColor = null;
    this._image = null;
    this._id = MM.generateId();
    this._order = null;
    this._oldText = "";
    this._imageItemSize = null;
  };

  const createDomElements = () => {
    const domElementsForViewer = {
      node: document.createElement("li"),
      content: document.createElement("div"),
      icon: document.createElement("span"),
      bgColor: document.createElement("div"),
      image: document.createElement("img"),
      value: document.createElement("span"),
      text: document.createElement("div"),
      imgButtonsWrapper: document.createElement("div"),
      uploadBtn: document.createElement("div"),
      removeImgBtn: document.createElement("div"),
      sizeUpBtn: document.createElement("div"),
      sizeDownBtn: document.createElement("div"),
      getFormatBtn: document.createElement("div"),
      children: document.createElement("ul"),
      options: document.createElement("div"),
      expandCompress: document.createElement("div"),
      showHideToolbox: document.createElement("div"),
      itemOrderWrapper: document.createElement("div"),
      itemOrderInput: document.createElement("input"),
      selectPresentItemInfoWrap: document.createElement("div"),
      selectPresentItemBtn: document.createElement("div"),
      selectPresentItemIDText: document.createElement("div"),
    };
    const domElementsForEditor = {
      ...domElementsForViewer,
      addChildLeftSide: document.createElement("div"),
      addChildRightSide: document.createElement("div"),
      addChildOptions: document.createElement("div"),
      addText: document.createElement("div"),
      addBox: document.createElement("div"),
      addImage: document.createElement("div"),
    };
    this._dom =
      (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
        window.location.pathname.includes("public-share-link") === false) ||
      JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
      JSON.parse(localStorage.getItem("mapPermission")) === 3
        ? domElementsForEditor
        : domElementsForViewer;
  };

  const setDomElementsProps = () => {
    this._dom.node.className = "item " + (_props.classes ? _props.classes : "");
    // this._dom.node.style.transform = "scale(0.9, 0.9)";
    this._dom.content.classList.add("content");
    this._dom.icon.classList.add("icon");
    this._dom.bgColor.classList.add("bg-color");
    this._dom.image.classList.add("image");
    this._dom.value.classList.add("value");
    this._dom.text.classList.add("text");
    this._dom.imgButtonsWrapper.classList.add("img-buttons");
    this._dom.uploadBtn.className = "fa upload-img-btn";
    this._dom.removeImgBtn.className = "fa remove-img-btn";
    this._dom.uploadBtn.setAttribute(
      "title",
      Resources.getValue("itemImageUploadButtonMsgTxt")
    );
    this._dom.removeImgBtn.setAttribute(
      "title",
      Resources.getValue("itemImageRemoveButtonMsgTxt")
    );
    this._dom.sizeUpBtn.className = "fa size-up-btn";
    this._dom.sizeDownBtn.className = "fa size-down-btn";
    this._dom.sizeUpBtn.setAttribute(
      "title",
      Resources.getValue("imageItemSizeUpButtonMsgTxt")
    );
    this._dom.sizeDownBtn.setAttribute(
      "title",
      Resources.getValue("imageItemSizeDownButtonMsgTxt")
    );
    this._dom.children.classList.add("children");
    this._dom.options.classList.add("options");
    this._dom.expandCompress.className = "fa expand-compress";
    this._dom.showHideToolbox.className = "icon-toolbox show-hide-toolbox";
    this._dom.showHideToolbox.setAttribute(
      "title",
      Resources.getValue("itemToolboxButtonMsgTxt")
    );
    // order input
    this._dom.itemOrderInput.type = "text";
    this._dom.itemOrderInput.className = "order-val";
    this._dom.itemOrderWrapper.className = "order-value-wrapper none";
    // present item btn
    this._dom.selectPresentItemInfoWrap.className =
      "select-item-for-present none";
    this._dom.selectPresentItemBtn.setAttribute(
      "title",
      Resources.getValue("selectMsgTxt")
    );
    this._dom.selectPresentItemBtn.className = "select-item-btn";
    this._dom.selectPresentItemBtn.innerHTML =
      Resources.getValue("selectMsgTxt");
    this._dom.selectPresentItemIDText.className = "select-item-id-text";

    // this._dom.node.setAttribute('id', this._id);

    if (
      (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
        window.location.pathname.includes("public-share-link") === false) ||
      JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
      JSON.parse(localStorage.getItem("mapPermission")) === 3
    ) {
      this._dom.addChildLeftSide.className = "fa add-child left";
      this._dom.addChildLeftSide.setAttribute("data-side", "left");
      this._dom.addChildRightSide.className = "fa add-child right";
      this._dom.addChildRightSide.setAttribute("data-side", "right");
      this._dom.addChildOptions.className = "add-child-options";
      this._dom.addText.className = "fa add-text";
      this._dom.addBox.className = "fa add-box";
      this._dom.addImage.className = "fa add-image";
    }

    if (
      JSON.parse(localStorage.getItem("mapPermission")) === 1 ||
      (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
        window.location.pathname.includes("public-share-link") === true)
    ) {
      this._dom.node.classList.contains("root") &&
        this._dom.options.classList.add("center-button");
    }
  };

  const appendDomElements = () => {
    if (
      (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
        window.location.pathname.includes("public-share-link") === false) ||
      JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
      JSON.parse(localStorage.getItem("mapPermission")) === 3
    ) {
      this._dom.addChildOptions.appendChild(this._dom.addText);
      this._dom.addChildOptions.appendChild(this._dom.addBox);
      this._dom.addChildOptions.appendChild(this._dom.addImage);
      this._dom.addChildLeftSide.appendChild(
        this._dom.addChildOptions.cloneNode(true)
      );
      this._dom.addChildRightSide.appendChild(
        this._dom.addChildOptions.cloneNode(true)
      );
      this._dom.options.appendChild(this._dom.addChildLeftSide);
      this._dom.options.appendChild(this._dom.expandCompress);
      this._dom.options.appendChild(this._dom.addChildRightSide);
      this._dom.content.appendChild(this._dom.text);

      this._dom.node.appendChild(this._dom.imgButtonsWrapper);
      this._dom.imgButtonsWrapper.appendChild(this._dom.uploadBtn);
      this._dom.imgButtonsWrapper.appendChild(this._dom.removeImgBtn);
      this._dom.imgButtonsWrapper.appendChild(this._dom.sizeUpBtn);
      this._dom.imgButtonsWrapper.appendChild(this._dom.sizeDownBtn);

      this._dom.options.appendChild(this._dom.showHideToolbox);
      this._dom.node.appendChild(this._dom.itemOrderWrapper);
      this._dom.itemOrderWrapper.appendChild(this._dom.itemOrderInput);
      this._dom.node.appendChild(this._dom.selectPresentItemInfoWrap);
      this._dom.selectPresentItemInfoWrap.appendChild(
        this._dom.selectPresentItemBtn
      );
      this._dom.selectPresentItemInfoWrap.appendChild(
        this._dom.selectPresentItemIDText
      );
      this._dom.node.appendChild(this._dom.content);
      this._dom.node.appendChild(this._dom.options);
    } else {
      this._dom.options.appendChild(this._dom.expandCompress);
      this._dom.content.appendChild(this._dom.text);
      this._dom.node.appendChild(this._dom.content);
      this._dom.node.appendChild(this._dom.options);
    }
  };

  const bindDomElementsEvents = () => {
    const item = this;

    if (
      (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
        window.location.pathname.includes("public-share-link") === false) ||
      JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
      JSON.parse(localStorage.getItem("mapPermission")) === 3
    ) {
      this._dom.sizeUpBtn.addEventListener("click", function () {
        item.imegeItemSizeUpFunc();
      });
      this._dom.sizeDownBtn.addEventListener("click", function () {
        item.imegeItemSizeDownFunc();
      });
      this._dom.uploadBtn.addEventListener("click", function () {
        item.handleEventImageItemUpdateImageClick();
      });
      this._dom.removeImgBtn.addEventListener("click", function () {
        item.handleEventImageItemRemoveImageClick();
      });
      this._dom.showHideToolbox.addEventListener("click", function () {
        item.showHideToolbox(item);
      });
      this._dom.selectPresentItemBtn.addEventListener("click", function () {
        item.selectPresentItemFunc(item);
      });

      // add child left events
      this._dom.addChildLeftSide
        .querySelector(".add-box")
        .addEventListener("click", function () {
          const side = this.closest(".add-child").getAttribute("data-side");
          const nodeBackground = true;
          const textItem = false;
          const withTabButton = false;
          MM.Command.InsertChild.execute(
            side,
            nodeBackground,
            textItem,
            withTabButton
          );
        });
      this._dom.addChildLeftSide
        .querySelector(".add-text")
        .addEventListener("click", function () {
          const side = this.closest(".add-child").getAttribute("data-side");
          const nodeBackground = false;
          const textItem = true;
          const withTabButton = false;
          MM.Command.InsertChild.execute(
            side,
            nodeBackground,
            textItem,
            withTabButton
          );
        });
      this._dom.addChildLeftSide
        .querySelector(".add-image")
        .addEventListener("click", function () {
          const side = this.closest(".add-child").getAttribute("data-side");
          item.handleEventImageItemClick(side);
        });

      // add child right events
      this._dom.addChildRightSide
        .querySelector(".add-box")
        .addEventListener("click", function () {
          const side = this.closest(".add-child").getAttribute("data-side");
          const nodeBackground = true;
          const textItem = false;
          const withTabButton = false;
          MM.Command.InsertChild.execute(
            side,
            nodeBackground,
            textItem,
            withTabButton
          );
        });
      this._dom.addChildRightSide
        .querySelector(".add-text")
        .addEventListener("click", function () {
          const side = this.closest(".add-child").getAttribute("data-side");
          const nodeBackground = false;
          const textItem = true;
          const withTabButton = false;
          MM.Command.InsertChild.execute(
            side,
            nodeBackground,
            textItem,
            withTabButton
          );
        });
      this._dom.addChildRightSide
        .querySelector(".add-image")
        .addEventListener("click", function () {
          const side = this.closest(".add-child").getAttribute("data-side");
          item.handleEventImageItemClick(side);
        });

      // other events
      this._dom.text.addEventListener("input", () => {
        item.handleEventItemTextOnInput();
      });
      this._dom.itemOrderInput.addEventListener("input", () => {
        this.handleEventItemOrderInputOnInput();
      });
    }

    // other events
    this._dom.expandCompress.addEventListener("click", () => {
      item.handleEventItemExpand();
    });
  };

  const init = (() => {
    setItemProps();
    createDomElements();
    setDomElementsProps();
    appendDomElements();
    bindDomElementsEvents();
  })();
};

MM.Item.createCanvas = (item, parentItem) => {
  const canvas = document.createElement("canvas");
  canvas.setAttribute("data-item-id", item._id);
  parentItem._dom.node.prepend(canvas);

  return canvas;
};

MM.Item.clearCanvas = (item) => {
  const parentItem = item._parent;
  const oldCanvas = parentItem._dom.node.querySelector(
    `[data-item-id="${item._id}"]`
  );

  if (oldCanvas) {
    oldCanvas.remove();
  }
};

// recursively turn side for sub children
MM.Item.recursivelyTurnSide = function (item) {
  // if non child sub item > return and exit, go parent level loop or exit loop
  if (!item._children || item._children.length === 0) {
    return;
  }

  // each for child of sub item
  item._children.forEach((child) => {
    // set new side & new position
    MM.Item.setNewSidePosition(item, child);

    // again search for sub children recursively
    MM.Item.recursivelyTurnSide(child);
  });
};

// recursively search intersect for sub children
MM.Item.recursivelySearchForIntersect = function (item) {
  // if non child sub item > return and exit, go parent level loop or exit loop
  if (!item._children || item._children.length === 0) {
    return;
  }

  // each for child of sub item
  item._children.forEach((child) => {
    // detect intersect for child
    MM.Map.detectIntersection(child);

    // again search for sub children recursively
    MM.Item.recursivelySearchForIntersect(child);
  });
};

// set new side & position
MM.Item.setNewSidePosition = function (item, child, leftPos) {
  // set newSide for child of sub item
  const _newSide = child._side === "right" ? "left" : "right";

  // set new side of child
  child._side = _newSide;
  child._dom.node.setAttribute("data-child-direction", _newSide);

  // calc & define
  const childPosX = leftPos || child._dom.node.offsetLeft;
  const childWidth = child._dom.content.offsetWidth;
  const itemWidth = item._dom.content.offsetWidth;
  let childPosXNew = 0;
  let lineWidth = 0;

  // calc by direction / right or left
  if (child._side === "left") {
    lineWidth = childPosX - itemWidth;
    childPosXNew = -(childWidth + lineWidth);
  } else {
    lineWidth = Math.abs(childPosX) - childWidth;
    childPosXNew = itemWidth + lineWidth;
  }

  // set new left position of child
  child._dom.node.style.left = `${childPosXNew}px`;
  child._position.left = childPosXNew;

  // set top position of child (again set)
  child._dom.node.style.top = `${child._position.top}px`;

  // create position object for child
  const newPosition = {
    left: childPosXNew,
    top: child._dom.node.offsetTop,
  };

  // set position of child & apply
  const childAction = new MM.Action.SetPosition(child, newPosition);
  MM.App.action(childAction);

  // detect intersect for child
  MM.Map.detectIntersection(child);
};

MM.Item.dragDropSetPosition = function (item, ghostPosition) {
  // calc pos for dragged item
  let itemPosX = item._dom.node.offsetLeft;
  const positionLeft = itemPosX + ghostPosition.left;
  const positionTop =
    item._dom.node.offsetTop -
    (item._dom.node.offsetHeight / 2 - item._dom.content.offsetHeight / 2) +
    ghostPosition.top;
  const position = { left: positionLeft, top: positionTop };

  // set position of dragged item
  const itemAction = new MM.Action.SetPosition(item, position);
  MM.App.action(itemAction);

  // detect intersect for dragged item
  MM.Map.detectIntersection(item);

  // refresh itemPosX for dragged item
  itemPosX = item._dom.node.offsetLeft;

  // detect is side changed for dragged item
  if (
    (item._side === "right" && itemPosX < 0) ||
    (item._side === "left" && itemPosX >= 0)
  ) {
    // if has child dragged item
    if (item._children && item._children.length > 0) {
      MM.Item.recursivelyTurnSide(item);
    }

    // set newSide for dragged item
    const newSide = item._side === "right" ? "left" : "right";

    // change side of dragged item
    item._side = newSide;
    item._dom.node.setAttribute("data-child-direction", newSide);
  } else {
    // if side is not changed && dragged item has children > check children for intersect recursively
    if (item._children && item._children.length > 0) {
      MM.Item.recursivelySearchForIntersect(item);
    }
  }
};

MM.Item.COLOR = "#999999";
// MM.Item.BGCOLOR = "#999999";
MM.Item.BGCOLOR = "#fff";
MM.Item.BORDER_COLOR = "#999999";
MM.Item.TEXT_COLOR = "#000000 !important";
MM.Item.RE =
  /\b(([a-z][\w-]+:\/\/\w)|(([\w-]+\.){2,}[a-z][\w-]+)|([\w-]+\.[a-z][\w-]+\/))[^\s]*([^\s,.;:?!<>\(\)\[\]'"])?($|\b)/i;

MM.Item.fromJSON = function (data, props) {
  return new this(props).fromJSON(data);
};

MM.Item.prototype.toJSON = function () {
  var data = {
    id: this._id,
    text: this.getText(),
  };

  // dallari duzeltmesi icin
  this.reDrawNodeLine();

  // root ise ve root ta image varsa
  // if(this.isRoot() && this._dom.content.classList.contains('only')) {
  //   this._children.forEach(child => {
  //     // root buyuklugune gore dallari ayarlayabilsin diye
  //     child.reDrawNodeLine();
  //   })
  // }

  this._dom.node.setAttribute("data-id", this._id); // to set node id

  if (this._side) {
    data.side = this._side;
  }
  if (this._position) {
    data.position = this._position;
  }
  if (this._oldPosition) {
    data.oldPosition = this._oldPosition;
  }
  if (this._imageItemSize) {
    data.imageItemSize = this._imageItemSize;
  }
  if (this._oldImageItemSize) {
    data.oldImageItemSize = this._oldImageItemSize;
  }
  if (this._color) {
    data.color = this._color;
  }
  if (this._borderColor) {
    data.borderColor = this._borderColor;
  }
  if (this._textColor) {
    data.textColor = this._textColor;
  }
  if (this._icon) {
    data.icon = this._icon;
  }
  if (this._bgColor) {
    data.bgColor = this._bgColor;
  }
  if (this._image) {
    data.image = this._image;
  }
  if (this._value) {
    data.value = this._value;
  }
  if (this._order) {
    data.order = this._order;
  }
  if (this._layout) {
    data.layout = this._layout.id;
  }
  if (!this._autoShape) {
    data.shape = this._shape.id;
  }
  if (!this._autoShapeSize) {
    data.shapeSize = this._shapeSize.id;
  }
  if (!this._autoFont) {
    data.font = this._fontFamily.id;
  }
  if (!this._autoFontSize) {
    data.fontSize = this._fontSize.id;
  }
  if (!this._autoLine) {
    data.line = this._line.id;
  }

  data.collapsed = this._collapsed ? true : false;
  data.nodeBackground = this._nodeBackground;
  data.imageItem = this._imageItem;
  data.nodeLine = this._nodeLine;

  if (this._children.length) {
    data.children = this._children.map(function (child) {
      return child.toJSON();
    });
  }

  return data;
};

/**
 * Only when creating a new item. To merge existing items, use .mergeWith().
 */
MM.Item.prototype.fromJSON = function (data) {
  this.setText(data.text);
  if (data.id) {
    this._id = data.id;
  }
  if (data.side) {
    this._side = data.side;
  }
  if (data.position) {
    this._position = data.position;
  }
  if (data.oldPosition) {
    this._oldPosition = data.oldPosition;
  }
  if (data.imageItemSize) {
    this._imageItemSize = data.imageItemSize;
  }
  if (data.oldImageItemSize) {
    this._oldImageItemSize = data.oldImageItemSize;
  }
  if (data.color) {
    this._color = data.color;
  }
  if (data.borderColor) {
    this._borderColor = data.borderColor;
  }
  if (data.textColor) {
    this._textColor = data.textColor;
  }
  if (data.icon) {
    this._icon = data.icon;
  }
  if (data.bgColor) {
    this._bgColor = data.bgColor;
  }
  if (data.image) {
    this._image = data.image;
  }
  if (data.value) {
    this._value = data.value;
  }
  if (data.order) {
    this._order = data.order;
  }
  if (data.collapsed) {
    this.collapse();
  }
  if (data.layout) {
    this._layout = MM.Layout.getById(data.layout);
  }
  if (data.shape) {
    this.setShape(MM.Shape.getById(data.shape));
  }
  if (data.shapeSize) {
    this.setShapeSize(MM.ShapeSize.getById(data.shapeSize));
  }
  if (data.font) {
    this.setFont(MM.Font.getById(data.font));
  }
  if (data.fontSize) {
    this.setFontSize(MM.FontSize.getById(data.fontSize));
  }
  if (data.line) {
    this.setLine(MM.Line.getById(data.line));
  }

  (data.children || []).forEach(function (child) {
    this.insertChild(MM.Item.fromJSON(child));
  }, this);

  if (data.nodeBackground === false) {
    this.addRemoveNodeBackground(data.nodeBackground);
  }

  if (data.imageItem === true) {
    this.imageItem();
  }

  if (data.nodeLine === false) {
    this.addRemoveNodeLine(data.nodeLine);
  }

  return this;
};

MM.Item.prototype.mergeWith = function (data) {
  var dirty = 0;

  if (this.getText() != data.text && !this._dom.text.contentEditable) {
    this.setText(data.text);
  }

  if (this._order != data.order) {
    this._order = data.order;
    dirty = 1;
  }

  if (this._side != data.side) {
    this._side = data.side;
    dirty = 1;
  }

  if (this._nodeBackground != data.nodeBackground) {
    this._nodeBackground = data.nodeBackground;
    dirty = 1;
  }

  if (this._imageItem != data.imageItem) {
    this._imageItem = data.imageItem;
    dirty = 1;
  }

  if (this._nodeLine != data.nodeLine) {
    this._nodeLine = data.nodeLine;
    dirty = 1;
  }

  if (this._position != data.position) {
    this._position = data.position;
    dirty = 1;
  }

  if (this._oldPosition != data.oldPosition) {
    this._oldPosition = data.oldPosition;
    dirty = 1;
  }

  if (this._imageItemSize != data.imageItemSize) {
    this._imageItemSize = data.imageItemSize;
    dirty = 1;
  }

  if (this._oldImageItemSize != data.oldImageItemSize) {
    this._oldImageItemSize = data.oldImageItemSize;
    dirty = 1;
  }

  if (this._color != data.color) {
    this._color = data.color;
    dirty = 2;
  }

  if (this._borderColor != data.borderColor) {
    this._borderColor = data.borderColor;
    dirty = 2;
  }

  if (this._textColor != data.textColor) {
    this._textColor = data.textColor;
    dirty = 1;
  }

  if (this._icon != data.icon) {
    this._icon = data.icon;
    dirty = 1;
  }

  if (this._bgColor != data.bgColor) {
    this._bgColor = data.bgColor;
    dirty = 2;
  }

  if (this._image != data.image) {
    this._image = data.image;
    dirty = 1;
  }

  if (this._value != data.value) {
    this._value = data.value;
    dirty = 1;
  }

  if (this._collapsed != !!data.collapsed) {
    this[this._collapsed ? "expand" : "collapse"]();
  }

  if (this.getOwnLayout() != data.layout) {
    this._layout = MM.Layout.getById(data.layout);
    dirty = 2;
  }

  var s = this._autoShape ? null : this._shape.id;
  if (s != data.shape) {
    this.setShape(MM.Shape.getById(data.shape));
  }

  var ss = this._autoShapeSize ? null : this._shapeSize.id;
  if (ss != data.shapeSize) {
    this.setShapeSize(MM.ShapeSize.getById(data.shapeSize));
  }

  var ff = this._autoFont ? null : this._fontFamily.id;
  if (ff != data.font) {
    this.setFont(MM.Font.getById(data.font));
  }

  var fs = this._autoFontSize ? null : this._fontSize.id;
  if (fs != data.fontSize) {
    this.setFontSize(MM.FontSize.getById(data.fontSize));
  }

  var l = this._autoLine ? null : this._line.id;
  if (l != data.line) {
    this.setLine(MM.Line.getById(data.line));
  }

  (data.children || []).forEach(function (child, index) {
    if (index >= this._children.length) {
      /* new child */
      this.insertChild(MM.Item.fromJSON(child));
    } else {
      /* existing child */
      var myChild = this._children[index];
      if (myChild.getId() == child.id) {
        /* recursive merge */
        myChild.mergeWith(child);
      } else {
        /* changed; replace */
        this.removeChild(this._children[index]);
        this.insertChild(MM.Item.fromJSON(child), index);
      }
    }
  }, this);

  /* remove dead children */
  var newLength = (data.children || []).length;
  while (this._children.length > newLength) {
    this.removeChild(this._children[this._children.length - 1]);
  }

  if (dirty == 1) {
    this.update();
  }
  if (dirty == 2) {
    this.updateSubtree();
  }
};

MM.Item.prototype.clone = function () {
  var data = JSON.parse(JSON.stringify(this));

  var removeId = function (obj) {
    delete obj.id;
    obj.children && obj.children.forEach(removeId);
  };
  removeId(data);

  return this.constructor.fromJSON(data);
};

MM.Item.prototype.select = function () {
  // ilk basta root secili ise baska item a tiklaninca current class ayarlamasi icin
  MM.App.map._root._dom.node.classList.remove("current");
  MM.App.map._root.getChildren().forEach(function (itm) {
    itm.deselect();
    itm._dom.node.classList.remove("current");
  });

  this._dom.node.classList.add("current");

  if (
    (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
      window.location.pathname.includes("public-share-link") === false) ||
    JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
    JSON.parse(localStorage.getItem("mapPermission")) === 3
  ) {
    MM.App.selectedItemToolboxSettings = {
      fontFamily: {
        id: this._fontFamily.id,
        value: this._fontFamily.label,
      },
      fontSize: this._fontSize.id,
      textColor: this._textColor ? this._textColor : "#212529",
      shapeSize: this._shapeSize.id,
      shape: this._shape.id,
    };
    localStorage.setItem(
      "selectedItemSettings",
      JSON.stringify(MM.App.selectedItemToolboxSettings)
    );
  }

  this.getMap().ensureItemVisibility(this);
  MM.publish("item-select", this);

  // set item format control -------
  if (MM.App.formattingIsActive === true) {
    if (this.isRoot()) {
      // map ilk yuklendiginde sorun cikarmasin diye
      if (MM.App.itemFormatObj !== null) {
        this.setItemFormat();
        MM.App.editing === true && (MM.App.editing = false);
      } else {
        return;
      }
    } else {
      this.setItemFormat();
      MM.App.editing === true && (MM.App.editing = false);
      MM.App.map._root.deselect();
      MM.App.map._root._dom.node.classList.remove("current");
      MM.App.map._root.getChildren().forEach(function (itm) {
        itm.deselect();
        itm._dom.node.classList.remove("current");
      });
      MM.App.current = null;
      this.deselect();
      this._dom.node.classList.remove("current");
    }
  }
  // set item format control -------
};

MM.Item.prototype.deselect = function () {
  // editing end
  if (MM.App.editing) {
    // MM.Command.Finish.execute(); // item focus olunca metin secimini engelliyordu, o yuzden commentlendi
  }
  // deselect
  const currentSelectedItem = document.querySelector(".current");
  if (currentSelectedItem) {
    currentSelectedItem.classList.remove("current");
  }
};

MM.Item.setTextStyle = function (tag) {
  const textDiv = MM.App.current._dom.text;
  let newStyledText;
  if (textDiv.innerHTML.indexOf("<" + tag + ">") <= -1) {
    // yoksa
    document.querySelector(`.text-style-${tag}`) &&
      document.querySelector(`.text-style-${tag}`).classList.add("active");
    newStyledText = "<" + tag + ">" + textDiv.innerHTML + "</" + tag + ">";
    var action = new MM.Action.SetText(MM.App.current, newStyledText);
    MM.App.action(action);
  } else {
    // varsa
    document.querySelector(`.text-style-${tag}`) &&
      document.querySelector(`.text-style-${tag}`).classList.remove("active");
    newStyledText = textDiv.innerHTML
      .replace("<" + tag + ">", "")
      .replace("</" + tag + ">", "");
    var action = new MM.Action.SetText(MM.App.current, newStyledText);
    MM.App.action(action);
  }
};

// componentToHex & rgbToHex; to convert rgb to hex color
MM.Item.componentToHex = function (c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};
MM.Item.rgbToHex = function (r, g, b) {
  return (
    "#" +
    MM.Item.componentToHex(r) +
    MM.Item.componentToHex(g) +
    MM.Item.componentToHex(b)
  );
};

MM.Item.prototype.newToolboxDynamicValueArrangements = function () {
  // for selected item font family
  document.querySelector(".font-button > span").innerHTML =
    MM.App.selectedItemToolboxSettings.fontFamily.value;
  document.querySelectorAll(".fontSelect").forEach((item) => {
    item.classList.remove("font-select");
  });
  document
    .querySelector(
      '.fontSelect[data-font="' +
        MM.App.selectedItemToolboxSettings.fontFamily.id +
        '"]'
    )
    .classList.add("font-select");

  // for selected item font size
  document.querySelector(".fontSize-button > span").innerHTML =
    MM.App.selectedItemToolboxSettings.fontSize;
  document.querySelectorAll(".fontSizeSelect").forEach((item) => {
    item.classList.remove("fontSize-select");
  });
  document.querySelector(
    '.fontSizeSelect[data-font-size="' +
      MM.App.selectedItemToolboxSettings.fontSize +
      '"]'
  ) &&
    document
      .querySelector(
        '.fontSizeSelect[data-font-size="' +
          MM.App.selectedItemToolboxSettings.fontSize +
          '"]'
      )
      .classList.add("fontSize-select");

  // for selected item text color
  document.querySelector(".text-toolbar").style.backgroundColor =
    MM.App.selectedItemToolboxSettings.textColor.split("!important")[0];
  document
    .querySelectorAll(".text-toolbar > .color-list-wrapper-text .color-item")
    .forEach((itm) => {
      itm.classList.remove("text-color-select");
    });
  var getTextRgbaValues =
    document.querySelector(".text-toolbar").style.backgroundColor;
  var convertedRgbValues = MM.Item.rgbToHex(
    Number(
      getTextRgbaValues
        .split("(")[1]
        .split(")")[0]
        .split(",")[0]
        .replace(/\s/g, "")
    ),
    Number(
      getTextRgbaValues
        .split("(")[1]
        .split(")")[0]
        .split(",")[1]
        .replace(/\s/g, "")
    ),
    Number(
      getTextRgbaValues
        .split("(")[1]
        .split(")")[0]
        .split(",")[2]
        .replace(/\s/g, "")
    )
  );
  document.querySelector(
    '.text-toolbar .color-item[data-color="' +
      convertedRgbValues.toUpperCase() +
      '"]'
  ) &&
    document
      .querySelector(
        '.text-toolbar .color-item[data-color="' +
          convertedRgbValues.toUpperCase() +
          '"]'
      )
      .classList.add("text-color-select");

  // for selected item border color
  document
    .querySelectorAll(
      ".border-toolbar > .color-list-wrapper-border .color-item"
    )
    .forEach((itm) => {
      itm.classList.remove("border-color-select");
    });
  var getBorderColor;
  if (MM.App.current._color && MM.App.current._color.includes("!important")) {
    getBorderColor =
      MM.App.current._color.split(" ")[0] &&
      MM.App.current._color.split(" ")[0].toUpperCase();
  } else {
    getBorderColor =
      MM.App.current._color && MM.App.current._color.toUpperCase();
  }
  // var getBorderColor = MM.App.current._color && MM.App.current._color.toUpperCase();

  document.querySelector(
    '.border-toolbar .color-item[data-color="' + getBorderColor + '"]'
  ) &&
    document
      .querySelector(
        '.border-toolbar .color-item[data-color="' + getBorderColor + '"]'
      )
      .classList.add("border-color-select");

  // for selected item bg color
  document
    .querySelectorAll(
      ".background-toolbar > .color-list-wrapper-picker .color-item"
    )
    .forEach((itm) => {
      itm.classList.remove("bg-color-select");
    });
  var getBgColor;
  if (
    MM.App.current._bgColor &&
    MM.App.current._bgColor.includes("!important")
  ) {
    getBgColor =
      MM.App.current._bgColor.split(" ")[0] &&
      MM.App.current._bgColor.split(" ")[0].toUpperCase();
  } else {
    getBgColor =
      MM.App.current._bgColor && MM.App.current._bgColor.toUpperCase();
  }
  // var getBgColor = MM.App.current._bgColor && MM.App.current._bgColor.toUpperCase();

  document.querySelector(
    '.background-toolbar .color-item[data-color="' + getBgColor + '"]'
  ) &&
    document
      .querySelector(
        '.background-toolbar .color-item[data-color="' + getBgColor + '"]'
      )
      .classList.add("bg-color-select");

  // for item shape size
  document.querySelectorAll(".shapeSizeSelect").forEach((item) => {
    item.classList.remove("shape-size-select");
  });
  document
    .querySelector(
      '.shapeSizeSelect[datashapesize="' +
        MM.App.selectedItemToolboxSettings.shapeSize +
        '"]'
    )
    .classList.add("shape-size-select");

  // for item shape type
  document.querySelectorAll(".shapeSelectItem").forEach((item) => {
    item.classList.remove("shape-select");
  });
  document
    .querySelector(
      '.shapeSelectItem[data-shape="' +
        MM.App.selectedItemToolboxSettings.shape +
        '"]'
    )
    .classList.add("shape-select");

  // for item text style
  const textDiv = MM.App.current._dom.text;
  if (textDiv.innerHTML.indexOf("<b>") > -1) {
    document.querySelector(".text-style-b").classList.add("active");
  } else {
    document.querySelector(".text-style-b").classList.remove("active");
  }
  if (textDiv.innerHTML.indexOf("<i>") > -1) {
    document.querySelector(".text-style-i").classList.add("active");
  } else {
    document.querySelector(".text-style-i").classList.remove("active");
  }
  if (textDiv.innerHTML.indexOf("<u>") > -1) {
    document.querySelector(".text-style-u").classList.add("active");
  } else {
    document.querySelector(".text-style-u").classList.remove("active");
  }
};

MM.Item.prototype.update = function () {
  var map = this.getMap();

  if (!map || !map.isVisible()) {
    return this;
  }

  MM.publish("item-change", this);

  if (this._autoShape) {
    /* check for changed auto-shape */
    var autoShape = this._getAutoShape();
    if (autoShape != this._shape) {
      if (this._shape) {
        this._shape.unset(this);
      }
      this._shape = autoShape;
      this._shape.set(this);
    }
  }

  if (this._autoShapeSize) {
    /* check for changed auto-shape */
    var autoShapeSize = this._getAutoShapeSize();
    if (autoShapeSize != this._shapeSize) {
      if (this._shapeSize) {
        this._shapeSize.unset(this);
      }
      this._shapeSize = autoShapeSize;
      this._shapeSize.set(this);
    }
  }

  if (this._autoFont) {
    /* check for changed auto-shape */
    var autoFont = this._getAutoFont();
    if (autoFont != this._fontFamily) {
      if (this._fontFamily) {
        this._fontFamily.unset(this);
      }
      this._fontFamily = autoFont;
      this._fontFamily.set(this);
    }
  }

  if (this._autoFontSize) {
    /* check for changed auto-shape */
    var autoFontSize = this._getAutoFontSize();
    if (autoFontSize != this._fontSize) {
      if (this._fontSize) {
        this._fontSize.unset(this);
      }
      this._fontSize = autoFontSize;
      this._fontSize.set(this);
    }
  }

  if (this._autoLine) {
    /* check for changed auto-line */
    var autoLine = this._getAutoLine();
    if (autoLine != this._line) {
      if (this._line) {
        this._line.unset(this);
      }
      this._line = autoLine;
      this._line.set(this);
    }
  }

  this.checkChildrenCount();
  this._updateIcon();
  this._updateBgColor();
  this._updateImage();

  this._dom.node.classList[this._collapsed ? "add" : "remove"]("collapsed");

  this.getLayout().update(this);
  this.getFont().update(this);
  this.getFontSize().update(this);
  this.getShape().update(this);
  this.getShapeSize().update(this);
  this.getLine().update(this);

  // this is for undo-redo actions
  MM.Map.undoSetActivePassive();
  MM.Map.redoSetActivePassive();

  MM.Map.save();

  return this;
};

MM.Item.prototype.checkChildrenCount = function () {
  if (this.getChildren().length > 0)
    this._dom.node.classList.add("has-children");
  else this._dom.node.classList.remove("has-children");
};

MM.Item.prototype.updateSubtree = function () {
  this._children.forEach(function (child) {
    child.updateSubtree(true);
  });
  return this.update();
};

MM.Item.prototype.setText = function (text) {
  this._dom.text.innerHTML = text;
  this._findLinks(this._dom.text);
  return this.update();
};

MM.Item.prototype.getOrder = function () {
  return this._order;
};

MM.Item.prototype.setOrder = function (order) {
  this._order = order;
  // this._dom.node.setAttribute('data-order', order);
  return this.update();
};

MM.Item.prototype.getId = function () {
  return this._id;
};

MM.Item.prototype.getText = function () {
  return this._dom.text.innerHTML;
};

MM.Item.prototype.collapse = function () {
  if (this._collapsed) {
    return;
  }

  // collapse item
  this._collapsed = true;
  this.update();

  return this;
};

MM.Item.prototype.expand = function () {
  if (!this._collapsed) {
    return;
  }

  // expand item
  this._collapsed = false;
  this.update();
  this.updateSubtree();

  return this;
};

MM.Item.prototype.isCollapsed = function () {
  return this._collapsed;
};

MM.Item.prototype.setValue = function (value) {
  this._value = value;
  return this.update();
};

MM.Item.prototype.getValue = function () {
  return this._value;
};

MM.Item.prototype.setIcon = function (icon) {
  this._icon = icon;
  this.update();

  // check space for item
  MM.Map.checkNodeSpace(this, "item-resize");

  // detect intersect for children
  MM.Map.detectIntersectionForChildren(this);

  // detect intersect for this item
  MM.Map.detectIntersection(this);

  this.reDrawNodeLine();
  MM.Mouse._newToolboxSetReposition(this);

  return this;
};

MM.Item.prototype.getIcon = function () {
  return this._icon;
};

MM.Item.prototype.setBgColor = function (bgColor) {
  this._bgColor = bgColor;
  return this.update();
};

MM.Item.prototype.getBgColor = function () {
  return this._bgColor || (this.isRoot() && "#fff");
};

MM.Item.prototype.setImage = function (image) {
  this._image = image;
  return this.update();
};

MM.Item.prototype.getImage = function () {
  return this._image;
};

MM.Item.prototype.setSide = function (side) {
  this._side = side;
  return this;
};

MM.Item.prototype.getSide = function () {
  return this._side;
};

MM.Item.prototype.setPosition = function (position) {
  this._position = position;
  MM.App.hideNewToolbox();
  return this;
};

MM.Item.prototype.getPosition = function () {
  return this._position;
};

MM.Item.prototype.setImageItemSize = function (size) {
  this._imageItemSize = size;
  return this;
};

MM.Item.prototype.getImageItemSize = function () {
  return this._imageItemSize;
};

MM.Item.prototype.addRemoveNodeBackground = function (status) {
  if (!this.isRoot()) {
    this._nodeBackground = status;

    if (!this._nodeBackground) {
      this._dom.content.removeAttribute("style");
      this._dom.bgColor.removeAttribute("style");
    }
    this._dom.node.classList.toggle("non-background");

    this.update();
  }

  return this;
};

MM.Item.prototype.addRemoveNodeLine = function (status) {
  if (!this.isRoot()) {
    this._nodeLine = status;
    this.reDrawNodeLine();
  }
  return this;
};

MM.Item.prototype.changeLineColorWithBorder = function (color) {
  // for change border color with item's line color (if its parent is root)
  if (
    MM.App.current._parent._dom.node.querySelector(
      `.root [data-item-id="${MM.App.current._id}"]`
    )
  ) {
    let itemCanvas = MM.App.current._parent._dom.node
      .querySelector(`.root [data-item-id="${MM.App.current._id}"]`)
      .getContext("2d");
    itemCanvas.strokeStyle = color;
    itemCanvas.fillStyle = color;
    (MM.App.current._children.length !== 0 ||
      MM.App.current._parent.isRoot()) &&
      itemCanvas.fill();
    itemCanvas.stroke();
  }
};

// Select item for present data
MM.Item.prototype.selectPresentItemFunc = function (item) {
  let itemContent = item._dom.node.querySelector(".content");

  let currentPresentDatas = MM.App.selectedPresentItems;
  let newItem = {
    nodeText:
      itemContent.classList.contains("only") === true
        ? "resim "
        : item._dom.text.innerText.substring(0, 16),
    nodeId: item._dom.node.dataset.id,
  };
  currentPresentDatas.push(newItem);
  localStorage.setItem(
    "mapPresentDatasStore",
    JSON.stringify(currentPresentDatas)
  );
  localStorage.setItem("changePresentItemSavedControlStore", true);
  MM.App.selectedPresentItems = currentPresentDatas;
  window.newItemAdded = true;
  document.getElementById("refresh-list-btn").click(); // to refresh left list

  // to hide selected item button
  document
    .querySelector(
      ".item[data-id='" + newItem.nodeId + "'] .select-item-for-present"
    )
    .classList.add("none");

  // start present button hide control
  if (MM.App.selectedPresentItems.length > 0) {
    document.querySelector(".start-present").classList.remove("none");
  } else {
    document.querySelector(".start-present").classList.add("none");
  }

  // Utils.loadingScreen("show");
  // htmlToImage
  //   .toPng(document.getElementById(`${item._dom.node.getAttribute('id')}`), {
  //     quality: 1,
  //     width: document.getElementById(`${item._dom.node.getAttribute('id')}`).innerWidth,
  //     height: document.getElementById(`${item._dom.node.getAttribute('id')}`).innerHeight,
  //   })
  //   .then(function (dataUrl) {
  //     Utils.loadingScreen("hide");
  //     let currentPresentDatas = MM.App.selectedPresentItems;
  //     let newItem = {
  //       text: (itemContent.classList.contains('only') === true)
  //             ? 'resim '
  //             : item._dom.text.innerText.substring(0, 16),
  //       nodeId: item._dom.node.dataset.id,
  //       image: dataUrl
  //     }
  //     currentPresentDatas.push(newItem);
  //     localStorage.setItem("mapPresentDatasStore", JSON.stringify(currentPresentDatas));
  //     MM.App.selectedPresentItems = currentPresentDatas;
  //     window.newItemAdded = true;
  //     document.getElementById("refresh-list-btn").click(); // to refresh left list
  //     console.log(currentPresentDatas);

  //     // to hide selected item button
  //     document.querySelector(".item[data-id='" + newItem.nodeId + "'] .select-item-for-present").classList.add('none');

  //     // start present button hide control
  //     if(MM.App.selectedPresentItems.length > 0) {
  //       document.querySelector('.start-present').classList.remove('none');
  //     } else {
  //       document.querySelector('.start-present').classList.add('none');
  //     }
  //   });
};

MM.Item.prototype.showHideToolbox = function (item) {
  if (
    (item &&
      JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
      window.location.pathname.includes("public-share-link") === false) ||
    JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
    JSON.parse(localStorage.getItem("mapPermission")) === 3
  ) {
    // to get item value and set new value when we changed
    this.newToolboxDynamicValueArrangements();

    // set new tolbox position with item tooolbox edit button
    var newToolbox = document.querySelector(".new-toolbox-wrapper");
    newToolbox.classList.toggle("none");

    MM.Mouse._newToolboxSetReposition(item);

    // hide all color pickers and remove active for all toolbox items
    var picker = document.querySelector("#color-list-wrapper-picker");
    var border = document.querySelector("#color-list-wrapper-border");
    var text = document.querySelector("#color-list-wrapper-text");
    picker.classList.add("none");
    border.classList.add("none");
    text.classList.add("none");
    let allWillCloseDropdown = document.querySelectorAll(".toolbox-item");
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });
  }
};

MM.Item.prototype.itemTextStyleControlOnFormatAction = function (
  itemTextStyles
) {
  let newStyledText = this._dom.text.innerHTML;

  if (itemTextStyles !== "") {
    if (
      itemTextStyles.includes("b") &&
      !itemTextStyles.includes("i") &&
      !itemTextStyles.includes("u")
    ) {
      // sadece bold kopyalandi ise
      if (this._dom.text.innerHTML.indexOf("<b>") <= -1) {
        // yoksa
        newStyledText = "<b>" + newStyledText + "</b>";
      }
      // digerleri varsa
      if (this._dom.text.innerHTML.indexOf("<i>") > -1) {
        newStyledText = newStyledText.replace("<i>", "").replace("</i>", "");
      }
      if (this._dom.text.innerHTML.indexOf("<u>") > -1) {
        newStyledText = newStyledText.replace("<u>", "").replace("</u>", "");
      }
    } else if (
      itemTextStyles.includes("b") &&
      itemTextStyles.includes("i") &&
      !itemTextStyles.includes("u")
    ) {
      // sadece bold ve italik kopyalandi ise
      if (this._dom.text.innerHTML.indexOf("<b>") <= -1) {
        // yoksa
        newStyledText = "<b>" + newStyledText + "</b>";
      }
      if (this._dom.text.innerHTML.indexOf("<i>") <= -1) {
        // yoksa
        newStyledText = "<i>" + newStyledText + "</i>";
      }
      // digerleri varsa
      if (this._dom.text.innerHTML.indexOf("<u>") > -1) {
        newStyledText = newStyledText.replace("<u>", "").replace("</u>", "");
      }
    } else if (
      itemTextStyles.includes("b") &&
      !itemTextStyles.includes("i") &&
      itemTextStyles.includes("u")
    ) {
      // sadece bold ve underline kopyalandi ise
      if (this._dom.text.innerHTML.indexOf("<b>") <= -1) {
        // yoksa
        newStyledText = "<b>" + newStyledText + "</b>";
      }
      if (this._dom.text.innerHTML.indexOf("<u>") <= -1) {
        // yoksa
        newStyledText = "<u>" + newStyledText + "</u>";
      }
      // digerleri varsa
      if (this._dom.text.innerHTML.indexOf("<i>") > -1) {
        newStyledText = newStyledText.replace("<i>", "").replace("</i>", "");
      }
    } else if (
      !itemTextStyles.includes("b") &&
      itemTextStyles.includes("i") &&
      itemTextStyles.includes("u")
    ) {
      // sadece italic ve underline kopyalandi ise
      if (this._dom.text.innerHTML.indexOf("<i>") <= -1) {
        // yoksa
        newStyledText = "<i>" + newStyledText + "</i>";
      }
      if (this._dom.text.innerHTML.indexOf("<u>") <= -1) {
        // yoksa
        newStyledText = "<u>" + newStyledText + "</u>";
      }
      // digerleri varsa
      if (this._dom.text.innerHTML.indexOf("<b>") > -1) {
        newStyledText = newStyledText.replace("<b>", "").replace("</b>", "");
      }
    } else if (
      !itemTextStyles.includes("b") &&
      itemTextStyles.includes("i") &&
      !itemTextStyles.includes("u")
    ) {
      // sadece italic kopyalandi ise
      if (this._dom.text.innerHTML.indexOf("<i>") <= -1) {
        // yoksa
        newStyledText = "<i>" + newStyledText + "</i>";
      }
      // digerleri varsa
      if (this._dom.text.innerHTML.indexOf("<b>") > -1) {
        newStyledText = newStyledText.replace("<b>", "").replace("</b>", "");
      }
      if (this._dom.text.innerHTML.indexOf("<u>") > -1) {
        newStyledText = newStyledText.replace("<u>", "").replace("</u>", "");
      }
    } else if (
      !itemTextStyles.includes("b") &&
      !itemTextStyles.includes("i") &&
      itemTextStyles.includes("u")
    ) {
      // sadece underline kopyalandi ise
      if (this._dom.text.innerHTML.indexOf("<u>") <= -1) {
        // yoksa
        newStyledText = "<u>" + newStyledText + "</u>";
      }
      // digerleri varsa
      if (this._dom.text.innerHTML.indexOf("<i>") > -1) {
        newStyledText = newStyledText.replace("<i>", "").replace("</i>", "");
      }
      if (this._dom.text.innerHTML.indexOf("<b>") > -1) {
        newStyledText = newStyledText.replace("<b>", "").replace("</b>", "");
      }
    } else if (
      itemTextStyles.includes("b") &&
      itemTextStyles.includes("i") &&
      itemTextStyles.includes("u")
    ) {
      // bold, italic, underline kopyalandi ise
      if (this._dom.text.innerHTML.indexOf("<b>") <= -1) {
        // yoksa
        newStyledText = "<b>" + newStyledText + "</b>";
      }
      if (this._dom.text.innerHTML.indexOf("<i>") <= -1) {
        // yoksa
        newStyledText = "<i>" + newStyledText + "</i>";
      }
      if (this._dom.text.innerHTML.indexOf("<u>") <= -1) {
        // yoksa
        newStyledText = "<u>" + newStyledText + "</u>";
      }
    }
    var action = new MM.Action.SetText(this, newStyledText);
    MM.App.action(action);
  } else {
    if (this._dom.text.innerHTML.indexOf("<b>") > -1) {
      // varsa kaldir
      newStyledText = newStyledText.replace("<b>", "").replace("</b>", "");
    }
    if (this._dom.text.innerHTML.indexOf("<i>") > -1) {
      // varsa kaldir
      newStyledText = newStyledText.replace("<i>", "").replace("</i>", "");
    }
    if (this._dom.text.innerHTML.indexOf("<u>") > -1) {
      // varsa kaldir
      newStyledText = newStyledText.replace("<u>", "").replace("</u>", "");
    }
    var action = new MM.Action.SetText(this, newStyledText);
    MM.App.action(action);
  }
};

MM.Item.prototype.applyActionsForSetItemFormat = function (formatData) {
  const actionFont = new MM.Action.SetFont(this, formatData.fontFamily);
  let type = MM.App.formatItemAction === "format" ? "format-font" : "";
  MM.App.action(actionFont, type);
  const actionFontSize = new MM.Action.SetFontSize(this, formatData.fontSize);
  MM.App.action(actionFontSize);
  let newTextColor = formatData.textColor;
  const actionTextColor = new MM.Action.SetTextColor(this, newTextColor);
  MM.App.action(actionTextColor);
  const actionBgColor = new MM.Action.SetColor(this, formatData.itemBgColor);
  MM.App.action(actionBgColor);
  this._dom.text.style.color = newTextColor;

  if (formatData.itemBgColor) {
    if (formatData.itemType === null) {
      // text item degil ise bg color set etmesi icin
      const actionBorderColor = new MM.Action.SetBgColor(
        this,
        formatData.itemBorderColor
      );
      MM.App.action(actionBorderColor);
    }
  }

  // text style control (bold, italic, underline)
  this.itemTextStyleControlOnFormatAction(formatData.textFontStyle);

  !this.isRoot() && this.changeLineColorWithBorder(formatData.itemBgColor);
};

MM.Item.prototype.getItemTextStyle = function (item) {
  const textDiv = item._dom.text;
  let isTextStyle = "";

  if (textDiv.innerHTML.indexOf("<b>") > -1) {
    isTextStyle += "b,";
  }
  if (textDiv.innerHTML.indexOf("<i>") > -1) {
    isTextStyle += "i,";
  }
  if (textDiv.innerHTML.indexOf("<u>") > -1) {
    isTextStyle += "u,";
  }

  if (isTextStyle[isTextStyle.length - 1] === ",") {
    isTextStyle = isTextStyle.substring(0, isTextStyle.length - 1);
  }

  return isTextStyle;
};

MM.Item.prototype.getItemFormat = function () {
  let itemFormatObjNew = {
    fontFamily: this.getFont(),
    fontSize: this.getFontSize(),
    textColor: this.getTextColor(),
    itemBgColor: this.getColor(),
    itemBorderColor: this.getBgColor(),
    textFontStyle: this.getItemTextStyle(this), // bold, italic, underline icin
  };
  if (
    this._dom.node.classList.contains("non-background") &&
    !this._dom.node.classList.contains("image-item")
  ) {
    // text item ise bg uygulanmasin sadece border uygulasin diye
    itemFormatObjNew = {
      ...itemFormatObjNew,
      itemType: "text-item",
    };
  } else {
    itemFormatObjNew = {
      ...itemFormatObjNew,
      itemType: null,
    };
  }
  MM.App.itemFormatObj = itemFormatObjNew;
  MM.App.formattingIsActive = true;
  MM.App.formatItemAction = "format";
  document.querySelector(".format-painter-info").classList.remove("passive");
  document.querySelector(".format-painter-info").classList.add("active");

  setTimeout(() => {
    // ayni anda birden fazla item secili olarak kalmasin diye
    MM.App.map._root.deselect();
    MM.App.map._root._dom.node.classList.remove("current");
    MM.App.map._root.getChildren().forEach(function (itm) {
      itm.deselect();
      itm._dom.node.classList.remove("current");
    });
    MM.App.current = null;
    this.deselect();
    this._dom.node.classList.remove("current");
  }, 100);
  document.querySelector("body").classList.add("cursor-brush");
};

MM.Item.prototype.setItemFormat = function () {
  if (MM.App.itemFormatObj !== null) {
    if (
      this._dom.node.classList.contains("non-background") &&
      this._dom.node.classList.contains("image-item")
    ) {
      // yapistirilmak istenen item image item ise
      Utils.modalm().open({
        title: Resources.getValue("warningSendMailMsgTxt"),
        bodyContent:
          "<p>" +
          Resources.getValue("setFormatForImageItemWarningMsgTxt") +
          "</p>",
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: "button yellow-button confirm-button",
            href: "",
          },
        ],
        confirmCallback: null,
        rejectCallback: null,
      });
      return false;
    } else if (
      this._dom.node.classList.contains("non-background") &&
      !this._dom.node.classList.contains("image-item")
    ) {
      // yapistirilmak istenen item text item ise
      // sadece metin ozellikleri ve dal rengi yapistirilabilir
      if (MM.App.textItemInfoModalSayac === 1) {
        Utils.modalm().open({
          title: Resources.getValue("infoTitleMsgTxt"),
          bodyContent:
            "<p>" +
            Resources.getValue("setFormatForTextItemInfoMsgTxt") +
            "</p>",
          buttons: [
            {
              text: Resources.getValue("okMsgTxt"),
              class: "button yellow-button confirm-button",
              href: "",
            },
          ],
          confirmCallback: null,
          rejectCallback: null,
        });
        MM.App.textItemInfoModalSayac = 0;
      }
      this.applyActionsForSetItemFormat(MM.App.itemFormatObj);
      this.reDrawNodeLine();
    } else if (
      !this._dom.node.classList.contains("non-background") &&
      !this._dom.node.classList.contains("image-item")
    ) {
      // yapistirilmak istenen item box item ise
      this.applyActionsForSetItemFormat(MM.App.itemFormatObj);
      this.reDrawNodeLine();
    }
  }
};

MM.Item.prototype.imageItem = function () {
  this._imageItem = true;
  this._dom.node.classList.add("non-background");
  this._dom.node.classList.add("image-item");

  return this;
};

MM.Item.prototype.reDrawNodeLine = function () {
  if (this._parent) {
    MM.Layout.Graph.drawConnectorLines(this._parent, [this]);
  }

  return this;
};

MM.Item.prototype.getPosition = function () {
  return this._position;
};

MM.Item.prototype.getChildren = function () {
  return this._children;
};

// bg color
MM.Item.prototype.setColor = function (color) {
  this._color = color;
  return this.updateSubtree();
};

MM.Item.prototype.getColor = function () {
  return (
    this._color || (this.isRoot() ? MM.Item.COLOR : this._parent.getColor())
  );
};

MM.Item.prototype.getOwnColor = function () {
  return this._color;
};

// border color
MM.Item.prototype.setBorderColor = function (borderColor) {
  this._borderColor = borderColor;
  return this.updateSubtree();
};

MM.Item.prototype.getBorderColor = function () {
  return (
    this._borderColor ||
    (this.isRoot() ? MM.Item.BORDER_COLOR : this._parent.getBorderColor())
  );
};

MM.Item.prototype.getOwnBorderColor = function () {
  return this._borderColor;
};

// text color
MM.Item.prototype.setTextColor = function (textColor) {
  this._textColor = textColor;
  return this.updateSubtree();
};

MM.Item.prototype.getTextColor = function () {
  return this._textColor;
  // || (this.isRoot() ? MM.Item.TEXT_COLOR : this._parent.getTextColor())
};

MM.Item.prototype.getOwnTextColor = function () {
  return this._textColor;
};

MM.Item.prototype.getLayout = function () {
  return this._layout || this._parent.getLayout();
};

MM.Item.prototype.getOwnLayout = function () {
  return this._layout;
};

MM.Item.prototype.setLayout = function (layout) {
  this._layout = layout;
  return this.updateSubtree();
};

MM.Item.prototype.getShape = function () {
  return this._shape;
};

MM.Item.prototype.getOwnShape = function () {
  return this._autoShape ? null : this._shape;
};

MM.Item.prototype.setShape = function (shape) {
  if (this._shape) {
    this._shape.unset(this);
  }

  if (shape) {
    this._autoShape = false;
    this._shape = shape;
  } else {
    this._autoShape = true;
    this._shape = this._getAutoShape();
  }

  this._shape.set(this);
  this.update();

  // check space for item
  MM.Map.checkNodeSpace(this, "item-resize");

  // detect intersect for children
  MM.Map.detectIntersectionForChildren(this);

  // detect intersect for this item
  MM.Map.detectIntersection(this);

  // re draw line of nodes
  this.reDrawNodeLine();

  MM.Mouse._newToolboxSetReposition(this);

  return this;
};

// Font
MM.Item.prototype.getFont = function () {
  return this._fontFamily;
};

MM.Item.prototype.getOwnFont = function () {
  return this._autoFont ? null : this._fontFamily;
};

MM.Item.prototype.setFont = function (font) {
  if (this._fontFamily) {
    this._fontFamily.unset(this);
  }

  if (font) {
    this._autoFont = false;
    this._fontFamily = font;
  } else {
    this._autoFont = true;
    this._fontFamily = this._getAutoFont();
  }

  this._fontFamily.set(this);
  this.update();

  // check space for item
  MM.Map.checkNodeSpace(this, "item-resize");

  // detect intersect for children
  MM.Map.detectIntersectionForChildren(this);

  // detect intersect for this item
  MM.Map.detectIntersection(this);

  MM.Mouse._newToolboxSetReposition(this);

  return this;
};

// Font Size
MM.Item.prototype.getFontSize = function () {
  return this._fontSize;
};

MM.Item.prototype.getOwnFontSize = function () {
  return this._autoFontSize ? null : this._fontSize;
};

MM.Item.prototype.setFontSize = function (fontSize) {
  if (this._fontSize) {
    this._fontSize.unset(this);
  }

  if (fontSize) {
    this._autoFontSize = false;
    this._fontSize = fontSize;
  } else {
    this._autoFontSize = true;
    this._fontSize = this._getAutoFontSize();
  }

  this._fontSize.set(this);
  !this.isRoot() && this.reDrawNodeLine();
  this._dom.node.style.width = this._dom.content.style.width;
  this.update();

  // check space for item
  MM.Map.checkNodeSpace(this, "item-resize");

  // detect intersect for children
  MM.Map.detectIntersectionForChildren(this);

  // detect intersect for this item
  MM.Map.detectIntersection(this);

  MM.Mouse._newToolboxSetReposition(this);

  return this;
};

MM.Item.prototype.getShapeSize = function () {
  return this._shapeSize;
};

MM.Item.prototype.getOwnShapeSize = function () {
  return this._autoShapeSize ? null : this._shapeSize;
};

MM.Item.prototype.setShapeSize = function (shapeSize) {
  if (!this._dom.node.classList.contains("non-background")) {
    // image item ya da text item degilse size degistirmak icin
    if (this._shapeSize) {
      this._shapeSize.unset(this);
    }

    if (shapeSize) {
      this._autoShapeSize = false;
      this._shapeSize = shapeSize;
    } else {
      this._autoShapeSize = true;
      this._shapeSize = this._getAutoShapeSize();
    }

    this._shapeSize.set(this);
    this.update();

    // check space for item
    MM.Map.checkNodeSpace(this, "item-resize");

    // detect intersect for children
    MM.Map.detectIntersectionForChildren(this);

    // detect intersect for this item
    MM.Map.detectIntersection(this);

    // re draw lines of node
    if (!this.isRoot()) {
      this.reDrawNodeLine();
    }
    MM.Mouse._newToolboxSetReposition(this);

    return this;
  }

  return this;
};

MM.Item.prototype.getLine = function () {
  return this._line;
};

MM.Item.prototype.getOwnLine = function () {
  return this._autoLine ? null : this._line;
};

MM.Item.prototype.setLine = function (line) {
  if (this._line) {
    this._line.unset(this);
  }

  if (line) {
    this._autoLine = false;
    this._line = line;
  } else {
    this._autoLine = true;
    this._line = this._getAutoLine();
  }

  this._line.set(this);
  return this.update();
};

MM.Item.prototype.getDOM = function () {
  return this._dom;
};

MM.Item.prototype.getMap = function () {
  var item = this._parent;
  while (item) {
    if (item instanceof MM.Map) {
      return item;
    }
    item = item.getParent();
  }
  return null;
};

MM.Item.prototype.getParent = function () {
  return this._parent;
};

MM.Item.prototype.isRoot = function () {
  return this._parent instanceof MM.Map;
};

MM.Item.prototype.setParent = function (parent) {
  this._parent = parent;
  return this.updateSubtree();
};

MM.Item.prototype.setItemOrderForAdd = function (item) {
  document.querySelectorAll(".item").forEach((itm, ind) => {
    // var actionOrder = new MM.Action.SetOrder(item, (ind + 1));
    // MM.App.action(actionOrder);
    var actionOrder = new MM.Action.SetOrder(item, Math.max(...itemOrders));
    MM.App.action(actionOrder);
  });
};

MM.Item.prototype.insertChild = function (child, index) {
  if (!child) {
    child = new MM.Item();
  }

  if (!this._children.length) {
    this._dom.node.appendChild(this._dom.children);
  }

  if (typeof index === "undefined") {
    index = this._children.length;
  }

  // to set item order
  this.setItemOrderForAdd(child);

  var next = null;
  if (index < this._children.length) {
    next = this._children[index].getDOM().node;
  }

  // append to dom
  this._dom.children.insertBefore(child.getDOM().node, next);
  this._children.splice(index, 0, child);

  if (
    this._children[index] &&
    this._children[index]._bgColor == null &&
    this._children[index]._borderColor == null &&
    this._children[index]._color == null
  ) {
    this.setColorPropsOfNewChild(child, index);
  }

  if (!child._nodeBackground) {
    child._dom.node.classList.add("non-background");
  }

  // to set itemOrders again
  itemOrders = [];
  document.querySelectorAll(".item").forEach((itm, ind) => {
    itemOrders.push(Number(itm.dataset.order));
  });
  itemOrders.sort();
  localStorage.setItem("ItemOrders", JSON.stringify(itemOrders));
  // console.log(itemOrders);

  return child.setParent(this);
};

MM.Item.prototype.setColorPropsOfNewChild = function (child, index) {
  if (this._parent) {
    // if not root
    if ((index = !null && typeof index != "undefined" && this._parent._root)) {
      // 1st level nodes color settings
      var autoColor = autoPainter();
      // child._bgColor = autoColor;
      child._bgColor = "#ffffff";
      child._color = autoColor;
    } else {
      // 2nd level nodes color settings
      child._bgColor = "#ffffff";
      child._color = this._color;
      child._textColor = "#444444";
      child._line = this._line;
      child._shapeSize = this._shapeSize;
      child._autoLine = false;
    }
  }
  return child;
};

MM.Item.recursiveChildFunc = function (child) {
  // if non child sub item > return and exit, go parent level loop or exit loop
  if (!child._children || child._children.length === 0) {
    return;
  }

  // each for child of sub item
  child._children.forEach((c) => {
    if (itemOrders.indexOf(c._order) > -1) {
      itemOrders.splice(c._order - 1, 1);
    }

    MM.Item.recursiveChildFunc(child);
  });
};

// itemOrders array item duplicate control
MM.Item.itemOrdersDuplicateControl = function () {
  let result;
  for (let i = 0; i < itemOrders.length; i++) {
    // nested loop
    for (let j = 0; j < itemOrders.length; j++) {
      // do not compare same elements
      if (i !== j) {
        // check if elements match
        if (itemOrders[i] === itemOrders[j]) {
          // duplicate element found
          result = true;
          // terminate inner loop
          break;
        }
      }
    }
    // terminate outer loop
    if (result) {
      break;
    }
  }
  if (result) {
    return true;
  } else {
    return false;
  }
};

MM.Item.prototype.removeChild = function (child) {
  const node = child.getDOM().node;

  if (!node.parentNode) {
    return;
  }

  const index = this._children.indexOf(child);
  this._children.splice(index, 1);
  node.parentNode.removeChild(node);
  MM.Item.clearCanvas(child);

  // item silinince present listesinden de o item'in silinmesi icin kontrol
  // let currentPresentDatas = MM.App.selectedPresentItems;
  // for( var i = 0; i < currentPresentDatas.length; i++){
  //   if (currentPresentDatas[i].nodeId === node.dataset.id) {
  //     currentPresentDatas.splice(i, 1);
  //     var mapId = Utils.getParameterByName("mapId") || localStorage.getItem("openedMapId");
  //     const savePresentData = {
  //       mindMapId: mapId,
  //       presentationNodes :currentPresentDatas
  //     };
  //     MapService.CreateMindMapPresentation(JSON.stringify(savePresentData));
  //   }
  // }

  // localStorage.setItem("mapPresentDatasStore", JSON.stringify(currentPresentDatas));
  // MM.App.selectedPresentItems = currentPresentDatas;
  // window.newItemAdded = true;
  // document.getElementById("refresh-list-btn").click(); // to refresh left list
  // console.log(currentPresentDatas);

  return this.update();
};

MM.Item.prototype.hideSoft = function (item) {
  item.classList.add("none");
  item.style.opacity = 0;
  item.style.visibility = "hidden";
  item.classList.remove("none");

  return this;
};

MM.Item.prototype.showSoft = function (item) {
  item.style.opacity = 1;
  item.style.visibility = "visible";

  return this;
};

MM.Item.prototype.startEditing = function () {
  this._oldText = this.getText();
  this._dom.text.contentEditable = true;
  this._dom.text.focus(); /* switch to 2b */
  this._dom.text.classList.add("focus");

  document.execCommand("styleWithCSS", null, false);
  this._dom.text.addEventListener("input", this);
  this._dom.text.addEventListener("keydown", this);
  this._dom.text.addEventListener("blur", this);

  if (this.isRoot()) {
    this.focusTextEnd(this._dom.text); // root'ta text focus olunca en sona odaklansin diye
  }

  return this;
};

MM.Item.prototype.focusTextEnd = function (el) {
  var range = document.createRange();
  var sel = window.getSelection();
  var _el = el.lastChild;

  if (el.lastChild != null) {
    return MM.Item.prototype.focusTextEnd(_el);
  }
  if (el.textContent == "") {
    range.setStart(el, 0);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    el.focus();
  } else {
    range.setStart(
      el.parentElement.lastChild,
      el.parentElement.lastChild.length
    );
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    el.parentElement.focus();
  }
};

MM.Item.prototype.stopEditing = function () {
  this._dom.text.removeEventListener("input", this);
  this._dom.text.removeEventListener("keydown", this);
  this._dom.text.removeEventListener("blur", this);

  this._dom.text.blur();
  this._dom.text.classList.remove("focus");
  this._dom.text.contentEditable = false;
  var result = this._dom.text.innerHTML;
  this._dom.text.innerHTML = this._oldText;
  this._oldText = "";

  MM.Clipboard.focus();

  return result;
};

MM.Item.prototype.handleEventItemExpand = function () {
  if (this._collapsed) {
    this.expand();
  } else {
    this.collapse();
  }
  MM.App.select(this);
};

MM.Item.prototype.imegeItemSizeUpFunc = function () {
  let currentWidth = this._dom.content.offsetWidth;
  let currentHeight = this._dom.content.offsetHeight;

  this._dom.node.querySelector(".content").style.width =
    currentWidth + (currentWidth * 20) / 100 + "px";
  this._dom.node.querySelector(".content").style.height =
    currentHeight + (currentHeight * 20) / 100 + "px";

  let newSize = {
    w: currentWidth + (currentWidth * 20) / 100,
    h: currentHeight + (currentHeight * 20) / 100,
  };

  const sizeUpAction = new MM.Action.SetImageItemSize(this, newSize);
  MM.App.action(sizeUpAction);

  MM.App.current = this;
  this._dom.node.classList.add("current");

  this.reDrawNodeLine();

  // check space for item
  MM.Map.checkNodeSpace(this, "item-resize");

  // detect intersect for children
  MM.Map.detectIntersectionForChildren(this);

  // detect intersect for this item
  MM.Map.detectIntersection(this);
};

MM.Item.prototype.imegeItemSizeDownFunc = function () {
  let currentWidth = this._dom.content.offsetWidth;
  let currentHeight = this._dom.content.offsetHeight;

  this._dom.node.querySelector(".content").style.width =
    currentWidth - (currentWidth * 20) / 100 + "px";
  this._dom.node.querySelector(".content").style.height =
    currentHeight - (currentHeight * 20) / 100 + "px";

  let newSize = {
    w: currentWidth - (currentWidth * 20) / 100,
    h: currentHeight - (currentHeight * 20) / 100,
  };

  const sizeDownAction = new MM.Action.SetImageItemSize(this, newSize);
  MM.App.action(sizeDownAction);

  MM.App.current = this;
  this._dom.node.classList.add("current");

  this.reDrawNodeLine();

  // check space for item
  MM.Map.checkNodeSpace(this, "item-resize");

  // detect intersect for children
  MM.Map.detectIntersectionForChildren(this);

  // detect intersect for this item
  MM.Map.detectIntersection(this);
};

MM.Item.prototype.handleEventImageItemClick = function (side) {
  const imageUploadElement = document.querySelector(".map-image-upload");
  imageUploadElement.setAttribute("data-image-item", "true");
  imageUploadElement.setAttribute("data-image-item-side", side || this._side);
  imageUploadElement.click();
};

// upload image func for image item and image bg item
MM.Item.prototype.handleEventImageItemUpdateImageClick = function () {
  const imageUploadElement = document.querySelector(".map-image-upload");
  imageUploadElement.click();
};

// remove image func for image item and image bg item
MM.Item.prototype.handleEventImageItemRemoveImageClick = function () {
  const _image = this._image;
  if (
    (this._dom.node.classList.contains("non-background") &&
      this._dom.node.classList.contains("image-item")) ||
    this.isRoot()
  ) {
    // image item ise
    MM.Action.DeleteImageFromServer(this.getImage());
    let action = null;
    action = new MM.Action.SetImage(this, null);
    MM.App.action(action, "newImageItem");
    this._dom.node.classList.remove("non-background");
    this._dom.node.classList.remove("image-item");
    this._dom.node.classList.remove("image-item-box");
    this._imageItem = false;
    this._dom.node.style.top =
      (parseFloat(this._dom.node.style.top.split("px")[0]) + 12).toString() +
      "px";
    // if (this._parent.isRoot()) {
    //   this._dom.node.style.width = "108px";
    //   this._dom.node.style.height = "72px";
    // } else {
    //   this._dom.node.style.width = "92px";
    //   this._dom.node.style.height = "64px";
    // }
    this.reDrawNodeLine();
    this._dom.options.style = "";

    // for root ?
    // this._dom.node.querySelector('.content').style.width = '167px';
    // this._dom.node.querySelector('.content').style.height = '80px';

    this._dom.node.querySelector(".content").style.width = "auto";
    this._dom.node.querySelector(".content").style.height = "auto";

    const sizeUpAction = new MM.Action.SetImageItemSize(this, null);
    MM.App.action(sizeUpAction);

    MM.App.current._parent.update();
  } else {
    // image item degil ise
    if (_image) {
      MM.Action.DeleteImageFromServer(this.getImage());
      let action = null;
      action = new MM.Action.SetImage(this, null);
      MM.App.action(action);
    }
  }
};

MM.Item.prototype.handleEventItemOrderInputOnInput = function () {
  let itemPresentOrder = [];
  let itemOrderInputVal = Number(
    this._dom.node.querySelector(".order-value-wrapper > input").value
  );
  if (
    this._dom.node.querySelector(".order-value-wrapper > input").value !== ""
  ) {
    document.querySelectorAll(".item").forEach(function (el) {
      itemPresentOrder.push(Number(el.dataset.presentorder));
    });
    itemPresentOrder.sort();

    if (itemPresentOrder.indexOf(itemOrderInputVal) > -1 === true) {
      document.querySelector(
        ".item[data-presentorder='" +
          itemOrderInputVal +
          "'] .order-value-wrapper > input"
      ).value = this._dom.node.dataset.presentorder;

      document
        .querySelector(".item[data-presentorder='" + itemOrderInputVal + "']")
        .setAttribute("data-presentorder", this._dom.node.dataset.presentorder);

      this._dom.node.setAttribute("data-presentorder", itemOrderInputVal);
    } else {
      console.log("bu sira no YOK");
    }
  }
};

MM.Item.prototype.handleEventItemTextOnInput = function () {
  onItemTextChangeDelay.set(() => {
    this.update();

    if (!this.isRoot()) {
      this._parent.update();
    }

    // check space for item
    MM.Map.checkNodeSpace(this, "item-resize");

    // detect intersect for children
    MM.Map.detectIntersectionForChildren(this);

    // detect intersect for this item
    MM.Map.detectIntersection(this);

    MM.Mouse._newToolboxSetReposition(this);
  }, 300);
};

MM.Item.prototype._getAutoShape = function () {
  // return MM.Shape.Ellipse;
  return MM.Shape.Rectangle;
};

MM.Item.prototype._getAutoShapeSize = function () {
  return MM.ShapeSize.Auto;
};

MM.Item.prototype._getAutoFont = function () {
  return MM.Font.IndieFlower;
};

MM.Item.prototype._getAutoFontSize = function () {
  return MM.FontSize.FontSize17;
};

MM.Item.prototype._getAutoLine = function () {
  return MM.Line.Solid;
};

MM.Item.prototype._updateIcon = function () {
  this._dom.icon.className = "icon";
  this._dom.icon.style.display = "";

  var icon = this._icon;
  if (icon) {
    if (icon.split("-")[0] === "fa") {
      this._dom.icon.classList.add("fa");
    }
    this._dom.icon.classList.add(icon);
  } else {
    this._dom.icon.style.display = "none";
  }
};

MM.Item.prototype._updateBgColor = function () {
  const bgColor = this._bgColor;

  // set bg-color element backgorund color (fake border element)
  if (this._nodeBackground) {
    if (bgColor && bgColor.indexOf("important") > -1) {
      this._dom.bgColor.style.setProperty(
        "background-color",
        bgColor.split(" ")[0]
      );
    } else {
      this._dom.bgColor.style.backgroundColor = bgColor;
    }
  }
};

MM.Item.prototype._updateImage = function () {
  const _image = this._image;

  if (_image) {
    this._dom.image.src = _image;

    // set node bg = image
    this._dom.bgColor.style.backgroundImage = "url('" + _image + "')";

    this._dom.content.classList.add("only");
    this._dom.bgColor.classList.add("only");
  } else {
    this._dom.content.classList.remove("only");
    this._dom.bgColor.classList.remove("only");

    // set node bg = none
    this._dom.bgColor.style.backgroundImage = "none";
  }

  this._dom.image.style.display = "none";
};

MM.Item.prototype._findLinks = function (node) {
  var children = [].slice.call(node.childNodes);
  for (var i = 0; i < children.length; i++) {
    var child = children[i];
    switch (child.nodeType) {
      case 1 /* element */:
        if (child.nodeName.toLowerCase() == "a") {
          continue;
        }
        this._findLinks(child);
        break;

      case 3 /* text */:
        var result = child.nodeValue.match(this.constructor.RE);
        if (result) {
          var before = child.nodeValue.substring(0, result.index);
          var after = child.nodeValue.substring(
            result.index + result[0].length
          );
          var link = document.createElement("a");
          link.innerHTML = link.href = result[0];

          if (!link.href.includes("https")) {
            link.href = "https://" + result[0];
          }
          link.target = "_blank";

          link.classList.add("box-link");
          link.style.color = "unset";
          link.style.fontWeight = "bold";

          if (before) {
            node.insertBefore(document.createTextNode(before), child);
          }

          node.insertBefore(link, child);

          if (after) {
            child.nodeValue = after;
            i--; /* re-try with the aftertext */
          } else {
            node.removeChild(child);
          }
        }
        break;
    }
  }
};

MM.Map = function () {};

MM.Map.undoSetActivePassive = function () {
  // set disable option of undo button
  if (
    window.location.pathname.includes("public-share-link") === false ||
    JSON.parse(localStorage.getItem("mapPermission")) !== 1
  ) {
    const undoButton = document.querySelector("#undo-changes-btn");
    if (!MM.App.history.length || MM.App.historyIndex <= 0) {
      undoButton.setAttribute("disabled", "");
    } else {
      undoButton.removeAttribute("disabled");
    }
  }
};

MM.Map.redoSetActivePassive = function () {
  // set disable option of redo button
  if (
    window.location.pathname.includes("public-share-link") === false ||
    JSON.parse(localStorage.getItem("mapPermission")) !== 1
  ) {
    const redoButton = document.querySelector("#redo-changes-btn");
    if (MM.App.history.length > 0 && MM.App.isRedoAvailable === true) {
      redoButton.removeAttribute("disabled");
    } else {
      redoButton.setAttribute("disabled", "");
    }
  }
};

MM.Map.prototype.createBlankMap = function () {
  const map = {
    text:
      localStorage.getItem("newMapName") ||
      localStorage.getItem("openedMapName") ||
      "Foramind",
    layout: MM.Layout.Map,
  };
  const props = { classes: "root" };
  this._setRoot(new MM.Item(props).setText(map.text).setLayout(map.layout));
};

MM.Map.save = function () {
  if (MM.UI.Backend.File.sync.getHubConnectionStatus() !== "active") {
    layoutMapUpdateDelay.set(() => {
      MM.UI.Backend.File.save();
      MM.App.fitMapScreenPageLoadControl();
    }, 1000);
  }
};

MM.Map.fromJSON = function (data) {
  return new this().fromJSON(data);
};

MM.Map.prototype.toJSON = function () {
  var data = {
    pageBgImage: this._getPageBgImage(),
    scale: this._getScale(),
    root: this._root.toJSON(),
  };

  // eger root'ta image var ise class ekle
  if (MM.App.map._root._dom.content.classList.contains("only")) {
    MM.App.map._root._dom.node.classList.add("image-item-box");
  }

  return data;
};

MM.Map.prototype.fromJSON = function (data) {
  const props = { classes: "root" };
  this._setRoot(MM.Item.fromJSON(data.root, props));
  this._setPageBgImage(data.pageBgImage);
  setTimeout(() => {
    this._setScale(data.scale);
  }, 600);
  return this;
};

MM.Map.prototype._setScale = function (scale) {
  document.querySelector(".root").style.transform =
    "scale(" + scale + "," + scale + ")";
  // document.querySelector(".a4-grid-lines").style.transform =
  //   "scale(" + scale + "," + scale + ")";
};

MM.Map.prototype._getScale = function () {
  setTimeout(() => {
    const scale =
      document.querySelector(".root").style.transform &&
      document
        .querySelector(".root")
        .style.transform.split("scale(")[1]
        .split(",")[0];
    // document.querySelector(".a4-grid-lines").style.transform = split("scale(")[1].split(",")[0];
    const numberScale = parseFloat(scale).toFixed(2);
    localStorage.setItem("zoomMap", numberScale);
    return numberScale;
  }, 600);
};

MM.Map.prototype._setPageBgImage = function (bgImage) {
  ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
    window.location.pathname.includes("public-share-link") === false) ||
    JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
    JSON.parse(localStorage.getItem("mapPermission")) === 3) &&
    (document.querySelector("#port").style.background = bgImage);
};

MM.Map.prototype._getPageBgImage = function () {
  var backgroundImageSelect = document.querySelectorAll(
    ".background-imageSelect"
  );
  var urlImage;
  [].forEach.call(backgroundImageSelect, (el) => {
    if (el.classList.contains("background-image-select")) {
      if (el.getAttribute("data-background-image")) {
        urlImage =
          "#f2f2f2  url(" +
          el.getAttribute("data-background-image") +
          ") no-repeat";
        localStorage.setItem(
          "tempBg",
          el.getAttribute("data-background-image")
        );
      } else if (el.getAttribute("data-background-color")) {
        urlImage = el.getAttribute("data-background-color");
        localStorage.setItem("tempBg", urlImage);
      }
    }
  });

  return urlImage;
};

MM.Map.prototype.mergeWith = function (data) {
  /* store a sequence of nodes to be selected when merge is over */
  var ids = [];
  var current = MM.App.current;
  var node = current;
  while (node != this) {
    ids.push(node.getId());
    node = node.getParent();
  }

  this._root.mergeWith(data.root);

  if (current.getMap()) {
    /* selected node still in tree, cool */
    /* if one of the parents got collapsed, act as if the node got removed */
    var node = current.getParent();
    var hidden = false;
    while (node != this) {
      if (node.isCollapsed()) {
        hidden = true;
      }
      node = node.getParent();
    }
    if (!hidden) {
      return;
    } /* nothing bad happened, continue */
  }

  /* previously selected node is no longer in the tree OR it is folded */

  /* what if the node was being edited? */
  if (MM.App.editing) {
    current.stopEditing();
  }

  /* get all items by their id */
  var idMap = {};
  var scan = function (item) {
    idMap[item.getId()] = item;
    item.getChildren().forEach(scan);
  };
  scan(this._root);

  /* select the nearest existing parent */
  while (ids.length) {
    var id = ids.shift();
    if (id in idMap) {
      MM.App.select(idMap[id]);
      return;
    }
  }
};

MM.Map.prototype.isVisible = function () {
  return this._visible;
};

MM.Map.prototype.update = function () {
  this._root.updateSubtree();
  return this;
};

MM.Map.prototype.show = function (container) {
  const rootNode = this._root.getDOM().node;
  const oldMap = container.querySelector("li");
  let oldMapPosition = null;

  if (oldMap) {
    oldMapPosition = { left: oldMap.offsetLeft, top: oldMap.offsetTop };
    oldMap.remove();
  }

  container.appendChild(rootNode);
  this._visible = true;
  this._root.updateSubtree();

  if (oldMapPosition) {
    this._moveTo(oldMapPosition.left, oldMapPosition.top);
  } else {
    if (this._root.getDOM().content.classList.contains("only")) {
      this.rootImageCenter();
    } else {
      this.center();
    }
    // this.center();
  }

  MM.App.buttonZoom(rootNode, 1.92, 0.5);
  MM.App.scrollZoom(rootNode, 1.92, 0.5);

  // select root after map open
  setTimeout(() => {
    MM.App.select(this._root);
  }, 500);

  return this;
};

MM.Map.prototype.hide = function () {
  var node = this._root.getDOM().node;
  node.parentNode.removeChild(node);
  this._visible = false;
  return this;
};

MM.Map.prototype.center = function () {
  var node = this._root.getDOM().node;
  var nodeContent = node.querySelector(".content");
  var port = MM.App.portSize;

  var left =
    (port[0] -
      (node.offsetWidth + Number(nodeContent.style.left.split("px")[0]))) /
    2;
  var top =
    (port[1] -
      (nodeContent.offsetHeight +
        Number(nodeContent.style.top.split("px")[0]))) /
    2;

  this._moveTo(Math.round(left), Math.round(top));
  return this;
};

MM.Map.prototype.rootImageCenter = function () {
  var node = this._root.getDOM().node;
  var nodeContent = node.querySelector(".content");
  var port = MM.App.portSize;

  var left = nodeContent.style.width
    ? (port[0] - Number(nodeContent.style.width.split("px")[0])) / 2
    : (port[0] - nodeContent.offsetWidth) / 2;

  var top = nodeContent.style.height
    ? (port[1] - Number(nodeContent.style.height.split("px")[0])) / 2
    : (port[1] - nodeContent.offsetHeight) / 2;

  this._moveTo(Math.round(left), Math.round(top));
  return this;
};

MM.Map.prototype.centerHorizontal = function () {
  var node = this._root.getDOM().node;
  var nodeContent = node.querySelector(".content");
  var port = MM.App.portSize;

  var left =
    (port[0] -
      (node.offsetWidth + Number(nodeContent.style.left.split("px")[0]))) /
    2;
  var top = Number(node.style.top.split("px")[0]);

  this._moveTo(Math.round(left), Math.round(top));
  return this;
};

MM.Map.prototype.move = function (x, y) {
  this._moveTo(x, y);
  return this;
};

MM.Map.prototype.moveBy = function (dx, dy) {
  MM.App.hideNewToolbox();
  return this._moveTo(this._position[0] + dx, this._position[1] + dy);
};

MM.Map.prototype.getItemFor = function (node) {
  if (!node.classList.contains("item")) {
    const closestItemElement = node.closest(".item");
    if (closestItemElement) {
      node = closestItemElement;
    } else {
      return null;
    }
  }

  var scan = function (item, node) {
    if (item._dom.node == node) {
      return item;
    }
    var children = item.getChildren();
    for (var i = 0; i < children.length; i++) {
      var result = scan(children[i], node);
      if (result) {
        return result;
      }
    }
    return null;
  };

  return scan(this._root, node);
};

MM.Map.prototype.ensureItemVisibility = function (item) {
  if (!item) {
    return;
  }

  var padding = 10;
  var node = item.getDOM().content;
  var itemRect = node.getBoundingClientRect();
  var root = this._root.getDOM().node;
  var parentRect = root.parentNode.getBoundingClientRect();
  var delta = [0, 0];

  var dx = parentRect.left - itemRect.left + padding;
  if (dx > 0) {
    delta[0] = dx;
  }

  var dx = parentRect.right - itemRect.right - padding;
  if (dx < 0) {
    delta[0] = dx;
  }

  var dy = parentRect.top - itemRect.top + padding;
  if (dy > 0) {
    delta[1] = dy;
  }

  var dy = parentRect.bottom - itemRect.bottom - padding;
  if (dy < 0) {
    delta[1] = dy;
  }

  if (delta[0] || delta[1]) {
    this.moveBy(delta[0], delta[1]);
  }
};

MM.Map.prototype.getParent = function () {
  return null;
};

MM.Map.prototype.getRoot = function () {
  return this._root;
};

MM.Map.prototype.getName = function () {
  var name = this._root.getText();
  return MM.Format.br2nl(name).replace(/\n/g, " ").replace(/<.*?>/g, "").trim();
};

MM.Map.prototype.getId = function () {
  return this._root.getId();
};

MM.Map.prototype._moveTo = function (left, top) {
  this._position = [left, top];

  var node = this._root.getDOM().node;
  node.style.left = left + "px";
  node.style.top = top + "px";
};

MM.Map.prototype._setRoot = function (item) {
  this._root = item;
  this._root.setParent(this);
};

MM.Map.sortByTopPosition = function (children) {
  // sort children by top positions
  const childrenArrSortedByTopPos = [];
  for (const child of children) {
    childrenArrSortedByTopPos.push({
      element: child,
      topPos: child._dom.node.offsetTop,
    });
  }
  return childrenArrSortedByTopPos.sort(Utils.sortArrByProp("topPos"));
};

MM.Map.placeNodes = function (item) {
  // set variables
  let itemTopDistance = 0;
  let itemLeftDistance = 0;

  // calc item's left position
  if (item._side === "right") {
    itemLeftDistance = item._parent._dom.node.offsetWidth + xSpaceBetweenNodes;
  } else {
    itemLeftDistance = -(item._dom.content.offsetWidth + xSpaceBetweenNodes);
  }

  // set item's left position
  item._dom.node.style.left = `${itemLeftDistance}px`;

  // get all children with new added.
  let childrenAll = item._parent.getChildren();

  // detect children as same direction with item
  const childrenAsSameDirection = [];
  for (let i = 0; i < childrenAll.length - 1; i++) {
    if (item._side === childrenAll[i]._side) {
      childrenAsSameDirection.push(childrenAll[i]);
    }
  }

  // if has same direction 1 or more children
  if (childrenAsSameDirection.length > 0) {
    // sort children by top positions
    let childrenArrSortedByTopPos = MM.Map.sortByTopPosition(
      childrenAsSameDirection
    );

    // match previous child & current child & search position between two child.
    let previousChild = null;
    let nodeIsPositioned = false;
    for (let i = 0; i < childrenArrSortedByTopPos.length; i++) {
      const child = childrenArrSortedByTopPos[i];
      if (previousChild) {
        const diffOfTopPositions = Math.abs(
          previousChild.topPos - child.topPos
        );
        const previousContentHeight =
          previousChild.element._dom.content.offsetHeight;
        const childContentHeight = child.element._dom.content.offsetHeight;
        const ySpaceAmount =
          diffOfTopPositions - previousContentHeight - ySpaceBetweenNodes * 2;
        if (ySpaceAmount >= childContentHeight) {
          itemTopDistance =
            previousChild.topPos +
            previousChild.element._dom.content.offsetHeight +
            ySpaceBetweenNodes;
          nodeIsPositioned = true;
          break;
        } else {
          previousChild = child;
        }
      } else {
        previousChild = child;
      }
    }

    // if no there space between two child, positioned child top or bottom
    if (!nodeIsPositioned) {
      const topChildBySortedArr = childrenArrSortedByTopPos[0];
      const bottomChildBySortedArr =
        childrenArrSortedByTopPos[childrenArrSortedByTopPos.length - 1];
      if (
        Math.abs(topChildBySortedArr.topPos) >=
        Math.abs(bottomChildBySortedArr.topPos)
      ) {
        itemTopDistance =
          bottomChildBySortedArr.topPos +
          bottomChildBySortedArr.element._dom.content.offsetHeight +
          ySpaceBetweenNodes;
      } else {
        itemTopDistance =
          topChildBySortedArr.topPos -
          topChildBySortedArr.element._dom.content.offsetHeight -
          ySpaceBetweenNodes;
      }
    }
  } else {
    // if has no child, calc to top position
    itemTopDistance =
      item._parent._dom.content.offsetHeight / 2 -
      item._dom.content.offsetHeight / 2;
  }

  // set item's top position
  item._dom.node.style.top = `${itemTopDistance}px`;

  // set item's _position prop
  item._position = { left: itemLeftDistance, top: itemTopDistance };

  // if 1st level > none control
  if (item._parent._parent._children) {
    MM.Map.checkNodeSpace(item, "child-added");
  }

  // detect intersect for item
  MM.Map.detectIntersection(item);
};

MM.Map.checkNodeSpace = function (item, action) {
  // check node spaces when if map is loaded & showed all nodes
  if (!mapIsLoadedDisplayed || !item._parent) {
    return;
  }

  if (action === "child-added" && item._parent._parent._children) {
    // get all children same level nodes of parent & same direction with item
    const sameDirectionAllChildren = [];
    for (const child of item._parent._parent._children) {
      if (
        (item._dom.node.offsetLeft > 0 && child._dom.node.offsetLeft > 0) ||
        (item._dom.node.offsetLeft < 0 && child._dom.node.offsetLeft < 0)
      ) {
        sameDirectionAllChildren.push(child);
      }
    }

    // sort children by top positions
    let parentChildrenArrSortedByTopPos = MM.Map.sortByTopPosition(
      sameDirectionAllChildren
    );

    // find parent index in sortedNodes
    let parentIndexInSortedArr = null;
    for (let i = 0; i < parentChildrenArrSortedByTopPos.length; i++) {
      if (parentChildrenArrSortedByTopPos[i].element === item._parent) {
        parentIndexInSortedArr = i;
        break;
      }
    }

    // find prev & next nodes of parent node
    const previousNodeOfParent =
      parentChildrenArrSortedByTopPos[parentIndexInSortedArr - 1];
    const nextNodeOfParent =
      parentChildrenArrSortedByTopPos[parentIndexInSortedArr + 1];

    // sort children by top positions (same level nodes of item)
    let itemChildrenArrSortedByTopPos = MM.Map.sortByTopPosition(
      item._parent._children
    );

    // find top node in parent children
    const topNodeOfParentChildren = itemChildrenArrSortedByTopPos[0];
    const bottomNodeOfParentChildren =
      itemChildrenArrSortedByTopPos[itemChildrenArrSortedByTopPos.length - 1];

    // if has prev node parent node
    if (previousNodeOfParent) {
      let mostNearPrevNodePosY = 0;
      let bottomPositionedNodePreviousParentChildrenPosY = null;

      // if previousNodeOfParent has child
      if (previousNodeOfParent.element._children.length > 0) {
        // sort children by top positions (children of previous parent item)
        let previousParentChildrenArrSortedByTopPos = MM.Map.sortByTopPosition(
          previousNodeOfParent.element._children
        );

        // get bottom positioned item from previous parent children (last child)
        const bottomPositionedNodePreviousParentChildren =
          previousParentChildrenArrSortedByTopPos[
            previousParentChildrenArrSortedByTopPos.length - 1
          ];

        // get position of bottom positioned node previous parent child (pos Y)
        bottomPositionedNodePreviousParentChildrenPosY =
          bottomPositionedNodePreviousParentChildren.element._dom.node.getBoundingClientRect()
            .y;
      }

      // get position prev node of parent (pos Y)
      const prevNodeOfParentPosY =
        previousNodeOfParent.element._dom.node.getBoundingClientRect().y;

      // decide to most near node, previous parent node or its child (it has?)
      mostNearPrevNodePosY =
        prevNodeOfParentPosY >=
          bottomPositionedNodePreviousParentChildrenPosY ||
        !bottomPositionedNodePreviousParentChildrenPosY
          ? prevNodeOfParentPosY
          : bottomPositionedNodePreviousParentChildrenPosY;

      // calc space
      const spaceAmount =
        Math.abs(
          mostNearPrevNodePosY -
            topNodeOfParentChildren.element._dom.node.getBoundingClientRect().y
        ) -
        (topNodeOfParentChildren.element._dom.content.offsetHeight +
          ySpaceBetweenNodes);

      // if no space between nodes (ySpaceBetweenNodes added)
      if (spaceAmount < 0) {
        for (let k = parentIndexInSortedArr - 1; k >= 0; k--) {
          // calc positionToBe
          const prevNodeOfParentChildTopPosToBe =
            spaceAmount + parentChildrenArrSortedByTopPos[k].topPos;
          // set top position
          parentChildrenArrSortedByTopPos[
            k
          ].element._dom.node.style.top = `${prevNodeOfParentChildTopPosToBe}px`;
          parentChildrenArrSortedByTopPos[k].element._position.top =
            prevNodeOfParentChildTopPosToBe;
          // re draw line of node
          MM.Layout.Graph.drawConnectorLines(
            parentChildrenArrSortedByTopPos[k].element._parent,
            [parentChildrenArrSortedByTopPos[k].element]
          );
        }
      }
    }

    // if has next node parent node
    if (nextNodeOfParent) {
      let mostNearNextNodePosY = 0;
      let topPositionedNodeNextParentChildrenPosY = null;

      // if nextNodeOfParent has child
      if (nextNodeOfParent.element._children.length > 0) {
        // sort children by top positions (children of next parent item)
        let previouesParentChildrenArrSortedByTopPos = MM.Map.sortByTopPosition(
          nextNodeOfParent.element._children
        );

        // get top positioned item from next parent children (first child)
        const topPositionedNodeNextParentChildren =
          previouesParentChildrenArrSortedByTopPos[0];

        // get position of top positioned node previoues parent child (pos Y)
        topPositionedNodeNextParentChildrenPosY =
          topPositionedNodeNextParentChildren.element._dom.node.getBoundingClientRect()
            .y;
      }

      // get position next node of parent (pos Y)
      const nextNodeOfParentPosY =
        nextNodeOfParent.element._dom.node.getBoundingClientRect().y;

      // decide to most near node, next parent node or its child (it has?)
      mostNearNextNodePosY =
        nextNodeOfParentPosY <= topPositionedNodeNextParentChildrenPosY ||
        !topPositionedNodeNextParentChildrenPosY
          ? nextNodeOfParentPosY
          : topPositionedNodeNextParentChildrenPosY;

      // calc space
      const spaceAmount =
        mostNearNextNodePosY -
        bottomNodeOfParentChildren.element._dom.node.getBoundingClientRect().y -
        (bottomNodeOfParentChildren.element._dom.content.offsetHeight +
          ySpaceBetweenNodes);

      // if no space between nodes (ySpaceBetweenNodes added)
      if (spaceAmount < 0) {
        for (
          let j = parentIndexInSortedArr + 1;
          j < parentChildrenArrSortedByTopPos.length;
          j++
        ) {
          // calc positionToBe
          const nextNodeOfParentChildTopPosToBe =
            Math.abs(spaceAmount) + parentChildrenArrSortedByTopPos[j].topPos;
          // set top position
          parentChildrenArrSortedByTopPos[
            j
          ].element._dom.node.style.top = `${nextNodeOfParentChildTopPosToBe}px`;
          parentChildrenArrSortedByTopPos[j].element._position.top =
            nextNodeOfParentChildTopPosToBe;
          // re draw line of node
          MM.Layout.Graph.drawConnectorLines(
            parentChildrenArrSortedByTopPos[j].element._parent,
            [parentChildrenArrSortedByTopPos[j].element]
          );
        }
      }
    }
  } else if (action === "item-resize" && item._parent._children) {
    // get all children same level nodes & same direction with item
    const sameDirectionAllChildren = [];
    for (const child of item._parent._children) {
      if (
        (item._dom.node.offsetLeft > 0 && child._dom.node.offsetLeft > 0) ||
        (item._dom.node.offsetLeft < 0 && child._dom.node.offsetLeft < 0)
      ) {
        sameDirectionAllChildren.push(child);
      }
    }

    // sort same level children by top positions
    let parentChildrenArrSortedByTopPos = MM.Map.sortByTopPosition(
      sameDirectionAllChildren
    );

    // find item index in sortedNodes
    let itemIndexInSortedArr = null;
    for (let i = 0; i < parentChildrenArrSortedByTopPos.length; i++) {
      if (parentChildrenArrSortedByTopPos[i].element === item) {
        itemIndexInSortedArr = i;
        break;
      }
    }

    // find prev & next nodes
    const previousNode =
      parentChildrenArrSortedByTopPos[itemIndexInSortedArr - 1];
    const nextNode = parentChildrenArrSortedByTopPos[itemIndexInSortedArr + 1];

    // item's Y position
    const itemPosY = item._dom.node.getBoundingClientRect().y;

    // if has prev node
    if (previousNode) {
      // get position prev node (pos Y)
      const prevNodePosY =
        previousNode.element._dom.node.getBoundingClientRect().y;

      // calc space amount
      const spaceAmount =
        Math.abs(prevNodePosY - itemPosY) -
        (previousNode.element._dom.content.offsetHeight + ySpaceBetweenNodes);

      // if no space between nodes (ySpaceBetweenNodes added)
      if (spaceAmount < 0) {
        for (let k = itemIndexInSortedArr - 1; k >= 0; k--) {
          // calc positionToBe
          const prevNodeTopPosToBe =
            spaceAmount + parentChildrenArrSortedByTopPos[k].topPos;
          // set top position
          parentChildrenArrSortedByTopPos[
            k
          ].element._dom.node.style.top = `${prevNodeTopPosToBe}px`;
          parentChildrenArrSortedByTopPos[k].element._position.top =
            prevNodeTopPosToBe;
          // re draw line of node
          MM.Layout.Graph.drawConnectorLines(
            parentChildrenArrSortedByTopPos[k].element._parent,
            [parentChildrenArrSortedByTopPos[k].element]
          );
        }
      }
    }

    // if has next node
    if (nextNode) {
      // get position next node (pos Y)
      const nextNodePosY = nextNode.element._dom.node.getBoundingClientRect().y;

      // calc space amount
      const spaceAmount =
        Math.abs(itemPosY - nextNodePosY) -
        (item._dom.content.offsetHeight + ySpaceBetweenNodes);

      // if no space between nodes (ySpaceBetweenNodes added)
      if (spaceAmount < 0) {
        for (
          let j = itemIndexInSortedArr + 1;
          j < parentChildrenArrSortedByTopPos.length;
          j++
        ) {
          // calc positionToBe
          const nextNodeTopPosToBe =
            Math.abs(spaceAmount) + parentChildrenArrSortedByTopPos[j].topPos;
          // set top position
          parentChildrenArrSortedByTopPos[
            j
          ].element._dom.node.style.top = `${nextNodeTopPosToBe}px`;
          parentChildrenArrSortedByTopPos[j].element._position.top =
            nextNodeTopPosToBe;
          // re draw line of node
          MM.Layout.Graph.drawConnectorLines(
            parentChildrenArrSortedByTopPos[j].element._parent,
            [parentChildrenArrSortedByTopPos[j].element]
          );
        }
      }
    }

    // detect intersect for children
    MM.Map.detectIntersectionForChildren(item);
  }
};

MM.Map.detectIntersection = function (item) {
  // detect intersections when if map is loaded & showed all nodes
  if (!mapIsLoadedDisplayed) {
    return;
  }

  let allRePositionAttemtpsCount = 0; // count of all re position attemtps
  let allRePositionAttemptsCountMax = 160; // MAX count of all re position attempts
  let rePositionAttemtpsCount = 0; // count of re position attemtps
  let rePositionAttemptsCountMax = 10; // MAX count of re position attempts
  let positionIncreaseAmount = 5; // left pos increase amount per attempt
  let rePositionDirectionIndex = 0; // ["forward", "backward", "top", "bottom"]
  let allNodes = null;
  let itemRect = null;
  let isThereAnIntersection = true; // default set any intersect

  // get all nodes except item
  const getAllNodes = function () {
    item._dom.node.classList.add("not-select");
    allNodes = document.querySelectorAll(".item:not(.not-select)");
    item._dom.node.classList.remove("not-select");
  };

  // search intersection
  const searchIntersection = function () {
    itemRect = item._dom.node.getBoundingClientRect();
    let intersectionDetected = false;
    for (const node of allNodes) {
      if (node.closest("ul").closest(".item") === item._dom.node) {
        continue; // if node is child of item > not check intersect now for childs
      }
      const nodeRect = node.getBoundingClientRect();
      if (
        (itemRect.x >= nodeRect.x &&
          itemRect.x <= nodeRect.x + nodeRect.width &&
          itemRect.y >= nodeRect.y &&
          itemRect.y <= nodeRect.y + nodeRect.height) ||
        (itemRect.x >= nodeRect.x &&
          itemRect.x <= nodeRect.x + nodeRect.width &&
          itemRect.y <= nodeRect.y &&
          itemRect.y + itemRect.height >= nodeRect.y) ||
        (itemRect.x <= nodeRect.x &&
          itemRect.x + itemRect.width >= nodeRect.x &&
          itemRect.y <= nodeRect.y &&
          itemRect.y + itemRect.height >= nodeRect.y) ||
        (itemRect.x <= nodeRect.x &&
          itemRect.x + itemRect.width >= nodeRect.x &&
          itemRect.y >= nodeRect.y &&
          itemRect.y <= nodeRect.y + nodeRect.height)
      ) {
        // intersection found, go rePositionNode
        intersectionDetected = true;
        rePositionNode();
        break;
      }
    }

    // if any intersection found
    if (
      intersectionDetected &&
      allRePositionAttemtpsCount < allRePositionAttemptsCountMax
    ) {
      if (
        rePositionAttemtpsCount === rePositionAttemptsCountMax &&
        rePositionDirectionIndex < 3 // attempts count is max & all directions not tried
      ) {
        // attempts count clear & change direction with index
        rePositionAttemtpsCount = 0;
        rePositionDirectionIndex++;

        // increase pos diff, at backward & bottom (only back directions 1 & 3)
        if (rePositionDirectionIndex === 1 || rePositionDirectionIndex === 3) {
          positionIncreaseAmount += 5;
        }
      } else if (
        rePositionAttemtpsCount === rePositionAttemptsCountMax &&
        rePositionDirectionIndex === 3 // attempts count is max & all directions when tried
      ) {
        // reset attempts counter & direction index / increase pos diff
        rePositionAttemtpsCount = 0;
        rePositionDirectionIndex = 0;
        positionIncreaseAmount += 5;
      }
    } else {
      // if intersection not found
      isThereAnIntersection = false;

      // re draw line of node if intersection not found
      MM.Layout.Graph.drawConnectorLines(item._parent, [item]);
    }
  };

  // re position to intersect item
  const rePositionNode = function () {
    const topPositionOfItem = item._dom.node.offsetTop;
    const leftPositionOfItem = item._dom.node.offsetLeft;

    // if direction horizontal (forward / backward)
    if (rePositionDirectionIndex < 2) {
      // decide increase amount by direction forward or backward
      const increaseAmountToLeftPos =
        rePositionDirectionIndex === 0
          ? -positionIncreaseAmount
          : positionIncreaseAmount;

      // add to left position of item
      const newLeftPositionOfItem =
        leftPositionOfItem + increaseAmountToLeftPos;

      // set node style left pos
      item._dom.node.style.left = `${newLeftPositionOfItem}px`;

      // set item's _position prop
      item._position.top = topPositionOfItem;
      item._position.left = newLeftPositionOfItem;
    } else {
      // if direction vertical (top / bottom)
      // decide increase amount by direction top or bottom
      const increaseAmountToTopPos =
        rePositionDirectionIndex === 2
          ? -positionIncreaseAmount
          : positionIncreaseAmount;

      // add to top position of item
      const newTopPositionOfItem = topPositionOfItem + increaseAmountToTopPos;

      // set node style top pos
      item._dom.node.style.top = `${newTopPositionOfItem}px`;

      // set item's _position prop
      item._position.top = newTopPositionOfItem;
      item._position.left = leftPositionOfItem;
    }
  };

  const init = (function () {
    getAllNodes();

    while (isThereAnIntersection) {
      // loop while any intersect
      searchIntersection();
      rePositionAttemtpsCount++;
      allRePositionAttemtpsCount++;
    }
  })();
};

MM.Map.detectIntersectionForChildren = function (item) {
  // if has children > detectIntersection for children
  if (item._children.length > 0) {
    // loop for each item
    item._children.forEach((child) => {
      MM.Map.detectIntersection(child);
    });
  }
};

MM.Keyboard = {};
MM.Keyboard.init = function () {
  window.addEventListener("keydown", this);
  window.addEventListener("keypress", this);
};

MM.Keyboard.handleEvent = function (e) {
  /* mode 2a: ignore keyboard when the activeElement resides somewhere inside of the UI pane */
  var node = document.activeElement;
  while (node && node != document) {
    if (node.classList.contains("ui")) {
      return;
    }
    node = node.parentNode;
  }

  var commands = MM.Command.getAll();
  for (var i = 0; i < commands.length; i++) {
    var command = commands[i];

    if (
      command.keys === undefined &&
      command.editMode === true &&
      MM.App.formattingIsActive === true
    ) {
      MM.App.editing = false;
      MM.App.editMode = {
        value: false,
      };
      const action1 = new MM.Action.FinishItemFormat();
      MM.App.action(action1);
    }
    if (command.keys === undefined) {
      if (command.editMode === true) {
        MM.App.editMode = {
          value: false,
        };
      }
      continue;
    }

    if (!command.isValid()) {
      continue;
    }

    var keys = command.keys;
    for (var j = 0; j < keys.length; j++) {
      if (this._keyOK(keys[j], e)) {
        command.prevent && e.preventDefault();
        command.execute(e);
        return;
      }
    }
  }
};

MM.Keyboard._keyOK = function (key, e) {
  if ("keyCode" in key && e.type != "keydown") {
    return false;
  }
  if ("charCode" in key && e.type != "keypress") {
    return false;
  }
  for (var p in key) {
    if (key[p] != e[p]) {
      return false;
    }
  }
  return true;
};
MM.Tip = {
  _node: null,

  handleEvent: function () {
    this._hide();
  },

  handleMessage: function () {
    this._hide();
  },

  init: function () {
    this._node = document.querySelector("#tip");
    this._node.addEventListener("click", this);
  },

  _hide: function () {
    this._node.removeEventListener("click", this);
    this._node.classList.add("hidden");
    this._node = null;
  },
};
MM.Action = function () {};
MM.Action.prototype.perform = function () {};
MM.Action.prototype.undo = function () {};

MM.Action.Multi = function (actions, actionName) {
  this._actions = actions;
};
MM.Action.Multi.prototype = Object.create(MM.Action.prototype);
MM.Action.Multi.prototype.perform = function () {
  this._actions.forEach(function (action) {
    action.perform();
  });
};
MM.Action.Multi.prototype.undo = function () {
  this._actions
    .slice()
    .reverse()
    .forEach(function (action) {
      action.undo();
    });
};

MM.Action.InsertNewItem = function (
  parent,
  index,
  side,
  nodeBackground,
  itemShape
) {
  const props = {
    side: side,
    nodeBackground: nodeBackground,
    shape: itemShape,
  };
  this._parent = parent;
  this._index = index;
  this._item = new MM.Item(props);
};
MM.Action.InsertNewItem.prototype = Object.create(MM.Action.prototype);
MM.Action.InsertNewItem.prototype.perform = function () {
  this._parent.expand();
  this._item = this._parent.insertChild(this._item, this._index);
};
MM.Action.InsertNewItem.prototype.undo = function () {
  this._parent.removeChild(this._item);
  MM.App.select(this._parent);
};

MM.Action.AddRemoveNodeBackground = function (item) {
  this._item = item;
};
MM.Action.AddRemoveNodeBackground.prototype = Object.create(
  MM.Action.prototype
);
MM.Action.AddRemoveNodeBackground.prototype.perform = function () {
  this._item.addRemoveNodeBackground(!this._item._nodeBackground);
};
MM.Action.AddRemoveNodeBackground.prototype.undo = function () {
  this._item.addRemoveNodeBackground(!this._item._nodeBackground);
};

MM.Action.AddRemoveNodeLine = function (item) {
  this._item = item;
};
MM.Action.AddRemoveNodeLine.prototype = Object.create(MM.Action.prototype);
MM.Action.AddRemoveNodeLine.prototype.perform = function () {
  this._item.addRemoveNodeLine(!this._item._nodeLine);
};
MM.Action.AddRemoveNodeLine.prototype.undo = function () {
  this._item.addRemoveNodeLine(!this._item._nodeLine);
};

MM.Action.GetItemFormat = function (item) {
  this._item = item;
};
MM.Action.GetItemFormat.prototype = Object.create(MM.Action.prototype);
MM.Action.GetItemFormat.prototype.perform = function () {
  this._item.getItemFormat();
};
MM.Action.GetItemFormat.prototype.undo = function () {};

MM.Action.SetItemFormat = function (item) {
  this._item = item;
};
MM.Action.SetItemFormat.prototype = Object.create(MM.Action.prototype);
MM.Action.SetItemFormat.prototype.perform = function () {
  this._item.setItemFormat();
};
MM.Action.SetItemFormat.prototype.undo = function () {};

MM.Action.FinishItemFormat = function () {};
MM.Action.FinishItemFormat.prototype = Object.create(MM.Action.prototype);
MM.Action.FinishItemFormat.prototype.perform = function () {
  MM.App.itemFormatObj = null;
  MM.App.editing = false;
  MM.App.formattingIsActive = false;
  document.querySelector("body").classList.remove("cursor-brush");
  document.querySelector(".format-painter-btn").classList.remove("active");
  document.querySelector(".format-painter-info").classList.add("passive");
  document.querySelector(".format-painter-info").classList.remove("active");
};
MM.Action.FinishItemFormat.prototype.undo = function () {
  // this._item.setItemFormat(!this._item._nodeLine);
};
MM.Action.FinishItemFormat.prototype.undo = function () {};

MM.Action.AppendItem = function (parent, item) {
  this._parent = parent;
  this._item = item;
};
MM.Action.AppendItem.prototype = Object.create(MM.Action.prototype);
MM.Action.AppendItem.prototype.perform = function () {
  this._parent.insertChild(this._item);
  MM.App.select(this._item);
};
MM.Action.AppendItem.prototype.undo = function () {
  this._parent.removeChild(this._item);
  MM.App.select(this._parent);
};

MM.Action.RemoveItem = function (item) {
  this._item = item;
  this._parent = item.getParent();
  this._index = this._parent.getChildren().indexOf(this._item);
};
MM.Action.RemoveItem.prototype = Object.create(MM.Action.prototype);
MM.Action.RemoveItem.prototype.perform = function () {
  this._parent.removeChild(this._item);
  MM.App.select(this._parent);
};
MM.Action.RemoveItem.prototype.undo = function () {
  this._parent.insertChild(this._item, this._index);
  this._item.reDrawNodeLine();
  MM.App.select(this._item);
};

MM.Action.MoveItem = function (item, newParent, newIndex, newSide) {
  this._item = item;
  this._newParent = newParent;
  this._newIndex = newIndex;
  this._newSide = newSide || "";
  this._oldParent = item.getParent();
  this._oldIndex = this._oldParent.getChildren().indexOf(item);
  this._oldSide = item.getSide();
};
MM.Action.MoveItem.prototype = Object.create(MM.Action.prototype);
MM.Action.MoveItem.prototype.perform = function () {
  this._item.setSide(this._newSide);
  if (this._newIndex === null) {
    this._newParent.insertChild(this._item);
  } else {
    this._newParent.insertChild(this._item, this._newIndex);
  }
  MM.App.select(this._item);
};
MM.Action.MoveItem.prototype.undo = function () {
  this._item.setSide(this._oldSide);
  this._oldParent.insertChild(this._item, this._oldIndex);
  MM.App.select(this._newParent);
};

MM.Action.SetLayout = function (item, layout) {
  this._item = item;
  this._layout = layout;
  this._oldLayout = item.getOwnLayout();
};
MM.Action.SetLayout.prototype = Object.create(MM.Action.prototype);
MM.Action.SetLayout.prototype.perform = function () {
  this._item.setLayout(this._layout);
};
MM.Action.SetLayout.prototype.undo = function () {
  this._item.setLayout(this._oldLayout);
};

MM.Action.SetShape = function (item, shape) {
  this._item = item;
  this._shape = shape;
  this._oldShape = item.getOwnShape();
};
MM.Action.SetShape.prototype = Object.create(MM.Action.prototype);
MM.Action.SetShape.prototype.perform = function () {
  this._item.setShape(this._shape);
};
MM.Action.SetShape.prototype.undo = function () {
  this._item.setShape(this._oldShape);
};

// Font Action
MM.Action.SetFont = function (item, font) {
  this._item = item;
  this._fontFamily = font;
  this._oldFontFamily = item.getOwnFont();
};
MM.Action.SetFont.prototype = Object.create(MM.Action.prototype);
MM.Action.SetFont.prototype.perform = function () {
  this._item.setFont(this._fontFamily);
  this._item.reDrawNodeLine();
  this._item.update();
};
MM.Action.SetFont.prototype.undo = function () {
  this._item.setFont(this._oldFontFamily);
};

// Font Size Action
MM.Action.SetFontSize = function (item, fontSize) {
  this._item = item;
  this._fontSize = fontSize;
  this._oldFontSize = item.getOwnFontSize();
};
MM.Action.SetFontSize.prototype = Object.create(MM.Action.prototype);
MM.Action.SetFontSize.prototype.perform = function () {
  this._item.setFontSize(this._fontSize);
  this._item.reDrawNodeLine();
  this._item.update();
};
MM.Action.SetFontSize.prototype.undo = function () {
  this._item.setFontSize(this._oldFontSize);
};

MM.Action.SetShapeSize = function (item, shapeSize) {
  this._item = item;
  this._shapeSize = shapeSize;
  this._oldShapeSize = item.getOwnShapeSize();
};
MM.Action.SetShapeSize.prototype = Object.create(MM.Action.prototype);
MM.Action.SetShapeSize.prototype.perform = function () {
  this._item.setShapeSize(this._shapeSize);
};
MM.Action.SetShapeSize.prototype.undo = function () {
  this._item.setShapeSize(this._oldShapeSize);
};

MM.Action.SetLine = function (item, line) {
  this._item = item;
  this._line = line;
  this._oldLine = item.getOwnLine();
};
MM.Action.SetLine.prototype = Object.create(MM.Action.prototype);
MM.Action.SetLine.prototype.perform = function () {
  this._item.setLine(this._line);
};
MM.Action.SetLine.prototype.undo = function () {
  this._item.setLine(this._oldLine);
};

// bg color
MM.Action.SetColor = function (item, color) {
  this._item = item;
  this._color = color;
  this._oldColor = item.getOwnColor();
};
MM.Action.SetColor.prototype = Object.create(MM.Action.prototype);
MM.Action.SetColor.prototype.perform = function () {
  this._item.setColor(this._color);
};
MM.Action.SetColor.prototype.undo = function () {
  this._item.setColor(this._oldColor);
};

// border color
MM.Action.SetBorderColor = function (item, borderColor) {
  this._item = item;
  this._borderColor = borderColor;
  this._oldBorderColor = item.getOwnBorderColor();
};
MM.Action.SetBorderColor.prototype = Object.create(MM.Action.prototype);
MM.Action.SetBorderColor.prototype.perform = function () {
  this._item.setBorderColor(this._borderColor);
};
MM.Action.SetBorderColor.prototype.undo = function () {
  this._item.setBorderColor(this._oldBorderColor);
};

// text color
MM.Action.SetTextColor = function (item, textColor) {
  this._item = item;
  this._textColor = textColor;
  this._oldTextColor = item.getOwnTextColor() + " !important";
};
MM.Action.SetTextColor.prototype = Object.create(MM.Action.prototype);
MM.Action.SetTextColor.prototype.perform = function () {
  this._item.setTextColor(this._textColor);
};
MM.Action.SetTextColor.prototype.undo = function () {
  this._item.setTextColor(this._oldTextColor);
};

MM.Action.SetText = function (item, text) {
  this._item = item;
  this._text = text;
  this._oldText = item.getText();
  this._oldValue = item.getValue(); /* adjusting text can also modify value! */
};
MM.Action.SetText.prototype = Object.create(MM.Action.prototype);
MM.Action.SetText.prototype.perform = function () {
  this._item.setText(this._text);
  var numText = Number(this._text);
  if (numText == this._text) {
    this._item.setValue(numText);
  }
};
MM.Action.SetText.prototype.undo = function () {
  this._item.setText(this._oldText);
  this._item.setValue(this._oldValue);
  document.querySelectorAll(".root > canvas").forEach((item) => {
    if (this._item._id === item.dataset.itemId) {
      this._item.reDrawNodeLine();
    }
  });
};

MM.Action.SetOrder = function (item, order) {
  this._item = item;
  this._order = order + 1;
};
MM.Action.SetOrder.prototype = Object.create(MM.Action.prototype);
MM.Action.SetOrder.prototype.perform = function () {
  this._item.setOrder(this._order);
};

MM.Action.SetValue = function (item, value) {
  this._item = item;
  this._value = value;
  this._oldValue = item.getValue();
};
MM.Action.SetValue.prototype = Object.create(MM.Action.prototype);
MM.Action.SetValue.prototype.perform = function () {
  this._item.setValue(this._value);
};
MM.Action.SetValue.prototype.undo = function () {
  this._item.setValue(this._oldValue);
};

// icon
MM.Action.SetIcon = function (item, icon) {
  this._item = item;
  this._icon = icon;
  this._oldIcon = item.getIcon();
};
MM.Action.SetIcon.prototype = Object.create(MM.Action.prototype);
MM.Action.SetIcon.prototype.perform = function () {
  this._item.setIcon(this._icon);
};
MM.Action.SetIcon.prototype.undo = function () {
  this._item.setIcon(this._oldIcon);
};
MM.Action.RemoveIcon = function (item) {
  this._item = item;
  this._icon = null;
  this._oldIcon = null;
};
MM.Action.RemoveIcon.prototype = Object.create(MM.Action.prototype);
MM.Action.RemoveIcon.prototype.perform = function () {
  this._item.setIcon(this._icon);
};

// bgColor
MM.Action.SetBgColor = function (item, bgColor) {
  this._item = item;
  this._bgColor = bgColor;
  this._oldBgColor = item.getBgColor();
};
MM.Action.SetBgColor.prototype = Object.create(MM.Action.prototype);
MM.Action.SetBgColor.prototype.perform = function () {
  this._item.setBgColor(this._bgColor);
};
MM.Action.SetBgColor.prototype.undo = function () {
  this._item.setBgColor(this._oldBgColor);
};

// image resize & upload
MM.Action.ConvertAndUploadImage = function (file) {
  Utils.loadingScreen("show");

  var _fileObj = file;

  try {
    var reModelAndUploadImage = function (optimizedFileObj) {
      var fileModel = {
        name: optimizedFileObj.name.split(".")[0],
        extension: "png",
        type: "image/png",
        referenceId: "",
        referenceIdType: 0,
        file: null,
      };

      var reader = new FileReader();
      reader.readAsDataURL(optimizedFileObj);
      reader.onload = function () {
        fileModel.file = reader.result.split(";base64,")[1];
        MapService.imageFileUpload(JSON.stringify(fileModel));
        Utils.loadingScreen("hide");
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    Resizer.imageFileResizer(
      _fileObj, // file obj
      5000, // New image max width (ratio is preserved)
      5000, // New image max height (ratio is preserved)
      "jpeg", // Can be either JPEG, PNG or WEBP.
      40, // A number between 0 and 100. Used for the JPEG compression.(if no compress is needed, just set it to 100)
      0, // Rotation to apply to the image. Rotation is limited to multiples of 90 degrees.(if no rotation is needed, just set it to 0) (0, 90, 180, 270, 360)
      (blob) => {
        // blob or base64 url
        var optimizedFileObj = new File(
          [blob],
          new Date().getTime().toString() +
            (Math.floor(Math.random() * 10000000000000000) + 1).toString(),
          { type: blob.type }
        );
        reModelAndUploadImage(optimizedFileObj);
      },
      "blob" // blob or base64
    );
  } catch (err) {
    Utils.loadingScreen("hide");
  }
};

MM.Action.SetImage = function (item, image) {
  this._item = item;
  this._image = image;
  this._oldImage = item.getImage();
};

MM.Action.SetImage.prototype = Object.create(MM.Action.prototype);
MM.Action.SetImage.prototype.perform = function () {
  this._item.setImage(this._image);
};

MM.Action.SetImage.prototype.undo = function () {
  this._item.setImage(this._oldImage);
};

MM.Action.DeleteImage = function () {
  MM.Action.DeleteImageFromServer(MM.App.current.getImage());
  let action = null;
  if (MM.App.current._parent._root) {
    const bgColor =
      document.querySelector(".color-picker").value + " !important";
    action = new MM.Action.SetBgColor(MM.App.current, bgColor);
    MM.App.action(action);

    const color =
      document.querySelector(".color-picker-border").value + " !important";
    action = new MM.Action.SetColor(MM.App.current, color);
    MM.App.action(action);
  }

  action = new MM.Action.SetImage(MM.App.current, null);
  MM.App.action(action);
  Utils.modalm().close();
};

MM.Action.DeleteImageFromServer = function (_oldImage) {
  if (_oldImage) {
    var fileId = _oldImage.split("/").pop();
    MapService.imageFileDelete(fileId);
  }
};

MM.Action.SetSide = function (item, side) {
  this._item = item;
  this._side = side;
  this._oldSide = item.getSide();
};
MM.Action.SetSide.prototype = Object.create(MM.Action.prototype);
MM.Action.SetSide.prototype.perform = function () {
  this._item.setSide(this._side);
  this._item.getMap().update();
};
MM.Action.SetSide.prototype.undo = function () {
  this._item.setSide(this._oldSide);
  this._item.getMap().update();
};

MM.Action.SetPosition = function (item, position) {
  this._item = item;
  this._position = position;
  this._oldPosition = item.getPosition();
};
MM.Action.SetPosition.prototype = Object.create(MM.Action.prototype);
MM.Action.SetPosition.prototype.perform = function () {
  this._item.setPosition(this._position);
  this._item._parent && this._item._parent.update();
};
MM.Action.SetPosition.prototype.undo = function () {
  this._item.setPosition(this._oldPosition);
  this._item.getMap().update();
};

MM.Action.SetImageItemSize = function (item, size) {
  this._item = item;
  this._imageItemSize = size;
  this._oldImageItemSize = item.getImageItemSize();
};
MM.Action.SetImageItemSize.prototype = Object.create(MM.Action.prototype);
MM.Action.SetImageItemSize.prototype.perform = function () {
  this._item.setImageItemSize(this._imageItemSize);
  this._item.getMap().update();
};
MM.Action.SetImageItemSize.prototype.undo = function () {
  this._item.setImageItemSize(this._oldImageItemSize);
  this._item.getMap().update();
};

MM.Clipboard = {
  _item: null,
  _mode: "",
  _delay: 50,
  _node: document.createElement("textarea"),
};

MM.Clipboard.init = function () {
  this._node.style.position = "absolute";
  this._node.style.width = 0;
  this._node.style.height = 0;
  this._node.style.left = "-100px";
  this._node.style.top = "-100px";
  document.body.appendChild(this._node);
};

MM.Clipboard.focus = function () {
  this._node.focus();
  this._empty();
};

MM.Clipboard.copy = function (sourceItem) {
  if (!sourceItem) {
    return;
  }

  this._endCut();
  this._item = sourceItem.clone();
  this._mode = "copy";
};

MM.Clipboard.cut = function (sourceItem) {
  if (!sourceItem || MM.App.current.isRoot()) {
    this._endCut();
    MM.Clipboard._empty();
    return;
  }

  this._endCut();
  this._item = sourceItem.clone();
  this._originalItem = sourceItem;
  this._originalItem._dom.node.classList.add("cut");
  this._mode = "cut";
};

MM.Clipboard.paste = function (targetItem) {
  if (!targetItem) {
    return;
  }

  // abort by pasting on the same node or the parent
  if (this._mode === "cut") {
    if (
      this._originalItem == targetItem ||
      this._originalItem.getParent() == targetItem
    ) {
      this._endCut();
      return;
    }
  }

  if (this._mode === "cut" || this._mode === "copy") {
    // get clone from moved item
    const movedItem = this._item.clone();

    // append moved item
    const appendAction = new MM.Action.AppendItem(targetItem, movedItem);
    MM.App.action(appendAction);

    // detect is side changed for appended item
    if (targetItem._side !== movedItem._side) {
      // set new side & new position
      MM.Item.setNewSidePosition(
        targetItem,
        movedItem,
        movedItem._position.left
      );

      // if has child appended item
      if (movedItem._children && movedItem._children.length > 0) {
        MM.Item.recursivelyTurnSide(movedItem);
      }
    } else {
      // set position if side not changed
      const positionAction = new MM.Action.SetPosition(
        movedItem,
        movedItem._position
      );
      MM.App.action(positionAction);

      // detect intersect for moved item
      MM.Map.detectIntersection(movedItem);

      // if side is not changed && appended item has children > check children for intersect recursively
      if (movedItem._children && movedItem._children.length > 0) {
        MM.Item.recursivelySearchForIntersect(movedItem);
      }
    }

    // after paste process
    if (this._mode === "cut") {
      // delete image of item & remove item & end cut process
      MM.Action.DeleteImageFromServer(this._originalItem.getImage());
      const action = new MM.Action.RemoveItem(this._originalItem);
      MM.App.action(action, "removeItem");
      this._endCut();
    }
  }
};

MM.Clipboard._empty = function () {
  /* safari needs a non-empty selection in order to actually perfrom a real copy on cmd+c */
  this._node.value = "\n";
  this._node.selectionStart = 0;
  this._node.selectionEnd = this._node.value.length;
};

MM.Clipboard._endCut = function () {
  if (this._mode != "cut") {
    return;
  }

  this._originalItem._dom.node.classList.remove("cut");
  this._originalItem = null;
  this._mode = "";
};

MM.Clipboard.getImage = function (event) {
  if (
    event.clipboardData &&
    event.clipboardData.files &&
    event.clipboardData &&
    event.clipboardData.files.length > 0
  ) {
    for (var i = 0; i < event.clipboardData.files.length; i++) {
      if (event.clipboardData.files[i].type.indexOf("image/") > -1) {
        MM.Action.ConvertAndUploadImage(event.clipboardData.files[i]);
        break;
      }
    }
  }
};

MM.Menu = {
  _dom: {},
  _port: null,

  open: function (x, y) {
    this._dom.node.style.display = "";
    var w = this._dom.node.offsetWidth;
    var h = this._dom.node.offsetHeight;
    var left = x;
    var top = y;

    if (left > this._port.offsetWidth / 2) {
      left -= w;
    }
    if (top > this._port.offsetHeight / 2) {
      top -= h;
    }

    this._dom.node.style.left = left + "px";
    this._dom.node.style.top = top + "px";
  },

  close: function () {
    ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
      window.location.pathname.includes("public-share-link") === false) ||
      JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
      JSON.parse(localStorage.getItem("mapPermission")) === 3) &&
      (this._dom.node.style.display = "none");
  },

  handleEvent: function (e) {
    if (e.currentTarget != this._dom.node) {
      this.close();
      return;
    }

    e.stopPropagation(); /* no dragdrop, no blur of activeElement */
    e.preventDefault(); /* we do not want to focus the button */

    var command = e.target.getAttribute("data-command");
    if (!command) {
      return;
    }

    command = MM.Command[command];
    if (!command.isValid()) {
      return;
    }

    command.execute();
    this.close();
  },

  init: function (port) {
    this._port = port;
    this._dom.node = document.querySelector("#menu");
    if (
      (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
        window.location.pathname.includes("public-share-link") === false) ||
      (JSON.parse(localStorage.getItem("mapPermission")) === 2 &&
        window.location.pathname.includes("public-share-link") === false) ||
      (JSON.parse(localStorage.getItem("mapPermission")) === 3 &&
        window.location.pathname.includes("public-share-link") === false)
    ) {
      var buttons = this._dom.node.querySelectorAll("[data-command]");
      [].slice.call(buttons).forEach(function (button) {
        button.innerHTML =
          MM.Command[button.getAttribute("data-command")].label;
      });
    }
    this._port.addEventListener("mousedown", this);
    ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
      window.location.pathname.includes("public-share-link") === false) ||
      (JSON.parse(localStorage.getItem("mapPermission")) === 2 &&
        window.location.pathname.includes("public-share-link") === false) ||
      (JSON.parse(localStorage.getItem("mapPermission")) === 3 &&
        window.location.pathname.includes("public-share-link") === false)) &&
      this._dom.node.addEventListener("mousedown", this);
    ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
      window.location.pathname.includes("public-share-link") === false) ||
      (JSON.parse(localStorage.getItem("mapPermission")) === 2 &&
        window.location.pathname.includes("public-share-link") === false) ||
      (JSON.parse(localStorage.getItem("mapPermission")) === 3 &&
        window.location.pathname.includes("public-share-link") === false)) &&
      this.close();
  },
};

// Commands
MM.Command = Object.create(MM.Repo, {
  keys: { value: [] },
  editMode: { value: false },
  prevent: { value: true } /* prevent default keyboard action? */,
  label: { value: "" },
});

MM.Command.isValid = function () {
  return this.editMode === null || this.editMode == MM.App.editing;
};

MM.Command.execute = function () {};

MM.Command.FullScreen = Object.create(MM.Command, {
  label: { value: Resources.getValue("fullScreenMsgTxt") },
  keys: {
    value: [{ keyCode: 122 }], // F11
  },
  prevent: {
    value: false,
  } /* allow default keyboard action for F11 (full-screen) */,
});

MM.Command.Center = Object.create(MM.Command, {
  label: { value: Resources.getValue("centerMapMsgTxt") },
  keys: { value: [{ keyCode: 35 }] }, // End
});

MM.Command.Center.execute = function () {
  MM.App.map.center();
};

MM.Command.ZoomIn = Object.create(MM.Command, {
  label: { value: Resources.getValue("zoomInMsgTxt") },
  keys: { value: [{ charCode: "+".charCodeAt(0), ctrlKey: true }] },
});

MM.Command.ZoomIn.execute = function () {
  MM.App.adjustFontSize(1);
};

MM.Command.ZoomOut = Object.create(MM.Command, {
  label: { value: Resources.getValue("zoomOutMsgTxt") },
  keys: { value: [{ charCode: "-".charCodeAt(0), ctrlKey: true }] },
});

MM.Command.ZoomOut.execute = function () {
  MM.App.adjustFontSize(-1);
};

MM.Command.Pan = Object.create(MM.Command, {
  label: { value: Resources.getValue("panthemapMsgTxt") },
  keys: {
    value: [
      {
        keyCode: "W".charCodeAt(0),
        ctrlKey: false,
        altKey: true,
        metaKey: false,
        shiftKey: false,
      },
      {
        keyCode: "A".charCodeAt(0),
        ctrlKey: false,
        altKey: true,
        metaKey: false,
        shiftKey: false,
      },
      {
        keyCode: "S".charCodeAt(0),
        ctrlKey: false,
        altKey: true,
        metaKey: false,
        shiftKey: false,
      },
      {
        keyCode: "D".charCodeAt(0),
        ctrlKey: false,
        altKey: true,
        metaKey: false,
        shiftKey: false,
      },
    ],
  },
  chars: { value: [] },
});

MM.Command.Pan.execute = function (e) {
  var ch = String.fromCharCode(e.keyCode);
  var index = this.chars.indexOf(ch);
  if (index > -1) {
    return;
  }

  if (!this.chars.length) {
    window.addEventListener("keyup", this);
    this.interval = setInterval(this._step.bind(this), 50);
  }

  this.chars.push(ch);
  this._step();
};

MM.Command.Pan._step = function () {
  var dirs = {
    W: [0, -1],
    A: [-1, 0],
    S: [0, 1],
    D: [1, 0],
  };
  var offset = [0, 0];

  this.chars.forEach(function (ch) {
    offset[0] += dirs[ch][0];
    offset[1] += dirs[ch][1];
  });

  MM.App.map.moveBy(15 * offset[0], 15 * offset[1]);
};

MM.Command.Pan.handleEvent = function (e) {
  var ch = String.fromCharCode(e.keyCode);
  var index = this.chars.indexOf(ch);
  if (index > -1) {
    this.chars.splice(index, 1);
    if (!this.chars.length) {
      window.removeEventListener("keyup", this);
      clearInterval(this.interval);
    }
  }
};

MM.Command.Fold = Object.create(MM.Command, {
  label: { value: Resources.getValue("foldUnfoldMsgTxt") },
  keys: {
    value: [
      // { keyCode: "F".charCodeAt(0), ctrlKey: true },
      { keyCode: 113, altKey: true }, // Alt + F2
    ],
  },
});

MM.Command.Fold.execute = function () {
  var item = MM.App.current;
  if (item.isCollapsed()) {
    item.expand();
  } else {
    item.collapse();
  }
  MM.App.map.ensureItemVisibility(item);
};

// if(
//   localStorage.getItem("isPresentModeActive") &&
//   JSON.parse(localStorage.getItem("isPresentModeActive")) === false
// ) {
MM.Command.Select = Object.create(MM.Command, {
  label: { value: Resources.getValue("moveselectionMsgTxt") },
  keys: {
    value: [
      {
        keyCode: 38,
        altKey: true,
        ctrlKey: false,
        metaKey: false,
        shiftKey: false,
      }, // ↑
      {
        keyCode: 37,
        altKey: true,
        ctrlKey: false,
        metaKey: false,
        shiftKey: false,
      }, // ←
      {
        keyCode: 40,
        altKey: true,
        ctrlKey: false,
        metaKey: false,
        shiftKey: false,
      }, // ↓
      {
        keyCode: 39,
        altKey: true,
        ctrlKey: false,
        metaKey: false,
        shiftKey: false,
      }, // →
    ],
  },
});
MM.Command.Select.execute = function (e) {
  var dirs = {
    37: "left",
    38: "top",
    39: "right",
    40: "bottom",
  };
  var dir = dirs[e.keyCode];

  if (MM.App.editing === false) {
    var layout = MM.App.current.getLayout();
    var item = /*MM.App.map*/ layout.pick(MM.App.current, dir);
    MM.App.select(item);
  }
};
// }

MM.Command.SelectRoot = Object.create(MM.Command, {
  label: { value: Resources.getValue("selectrootMsgTxt") },
  keys: { value: [{ keyCode: 36 }] }, // Home
});
MM.Command.SelectRoot.execute = function () {
  var item = MM.App.current;
  while (!item.isRoot()) {
    item = item.getParent();
  }
  MM.App.select(item);
};

MM.Command.SelectParent = Object.create(MM.Command, {
  label: { value: Resources.getValue("selectParentMsgTxt") },
  keys: { value: [{ keyCode: 8, ctrlKey: true }] }, // Ctrl + Backspace
});

MM.Command.SelectParent.execute = function () {
  if (MM.App.current.isRoot()) {
    return;
  }
  MM.App.select(MM.App.current.getParent());
};

// --- sadece duzuneleme yetkisi olan kisilerin kullanabilecegi komutlar ---
if (
  (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
    window.location.pathname.includes("public-share-link") === false) ||
  JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
  JSON.parse(localStorage.getItem("mapPermission")) === 3
) {
  MM.Command.Undo = Object.create(MM.Command, {
    label: { value: Resources.getValue("undoMsgTxt") },
    keys: { value: [{ keyCode: "Z".charCodeAt(0), ctrlKey: true }] }, // Ctrl + Z
  });

  MM.Command.Undo.isValid = function () {
    return MM.Command.isValid.call(this) && !!MM.App.historyIndex;
  };

  MM.Command.Undo.execute = function () {
    MM.App.history[MM.App.historyIndex - 1].undo();
    MM.App.historyIndex--;
    MM.App.history.length > 0 && (MM.App.isRedoAvailable = true);

    // control for redo button after click undo
    MM.Map.redoSetActivePassive();
  };

  MM.Command.Redo = Object.create(MM.Command, {
    label: { value: Resources.getValue("redoMsgTxt") },
    keys: { value: [{ keyCode: "Y".charCodeAt(0), ctrlKey: true }] }, // Ctrl + Y
  });

  MM.Command.Redo.isValid = function () {
    return (
      MM.Command.isValid.call(this) &&
      MM.App.historyIndex != MM.App.history.length
    );
  };

  MM.Command.Redo.execute = function () {
    if (MM.App.historyIndex === MM.App.history.length - 1) {
      MM.App.history[MM.App.historyIndex].perform();
      document.querySelector("#redo-changes-btn").setAttribute("disabled", "");
      MM.App.isRedoAvailable = false;
      return false;
    } else {
      MM.App.history[MM.App.historyIndex].perform();
      MM.App.historyIndex++;
    }
  };

  MM.Command.AddRemoveNodeBackground = Object.create(MM.Command, {
    label: { value: Resources.getValue("addRemoveNodeBackground") },
    keys: {
      value: [
        // { keyCode: "".charCodeAt(0), ctrlKey: true }
      ],
    }, // Ctrl + B
  });

  MM.Command.AddRemoveNodeBackground.execute = function (item) {
    const _item = item || MM.App.current;
    const action = new MM.Action.AddRemoveNodeBackground(_item);
    MM.App.action(action, "addRemoveBackground");
  };

  MM.Command.AddRemoveNodeLine = Object.create(MM.Command, {
    label: { value: Resources.getValue("addRemoveNodeLine") },
    keys: { value: [{ keyCode: "L".charCodeAt(0), ctrlKey: true }] }, // Ctrl + L
  });

  MM.Command.AddRemoveNodeLine.execute = function () {
    const item = MM.App.current;
    const action = new MM.Action.AddRemoveNodeLine(item);
    MM.App.action(action);
  };

  MM.Command.GetItemFormat = Object.create(MM.Command, {
    label: { value: Resources.getValue("getItemFormat") },
    keys: {
      value: [
        // { keyCode: "F".charCodeAt(0), ctrlKey: true }
      ],
    },
  });

  MM.Command.GetItemFormat.execute = function () {
    const item = MM.App.current;
    const action = new MM.Action.GetItemFormat(item);
    MM.App.action(action);
  };

  MM.Command.SetItemFormat = Object.create(MM.Command, {
    label: { value: Resources.getValue("setItemFormat") },
    keys: {
      value: [
        // { keyCode: "F".charCodeAt(0), ctrlKey: true }
      ],
    },
  });

  MM.Command.SetItemFormat.execute = function () {
    const item = MM.App.current;
    const action = new MM.Action.SetItemFormat(item);
    MM.App.action(action);
  };

  MM.Command.FinishItemFormat = Object.create(MM.Command, {
    label: { value: Resources.getValue("finishItemFormat") },
    keys: {
      value: [
        { keyCode: 27 }, // ESC
      ],
    },
    editMode: { value: false },
  });

  MM.Command.FinishItemFormat.execute = function () {
    if (MM.App.editing === true) {
      MM.App.editing = false;
      MM.App.editMode = false;
      const action1 = new MM.Action.FinishItemFormat();
      MM.App.action(action1);
    } else {
      const action2 = new MM.Action.FinishItemFormat();
      MM.App.action(action2);
    }
  };

  MM.Command.InsertChild = Object.create(MM.Command, {
    label: { value: Resources.getValue("insertAChildMsgTxt") },
    keys: {
      value: [
        { keyCode: 9, ctrlKey: false },
        { keyCode: 45 }, // Tab/Insert
      ],
    },
    editMode: { value: null },
  });

  MM.Command.InsertChild.execute = function (
    side,
    nodeBackground,
    textItem,
    withTabButton
  ) {
    // dont insert child when formatter active
    if (MM.App.formattingIsActive) {
      return;
    }

    // get current item (parent of new child)
    const _item = MM.App.current;
    // const _itemShape = _item.getShape();

    // for tab insert - decide item side
    if (typeof side !== "string" && _item.isRoot()) {
      let leftSideChildren = [];
      let rightSideChildren = [];
      for (const child of _item._children) {
        if (child._side === "right") {
          rightSideChildren.push(child);
        } else {
          leftSideChildren.push(child);
        }
      }
      if (leftSideChildren.length >= rightSideChildren.length) {
        side = "right";
      } else {
        side = "left";
      }
    } else if (typeof side !== "string") {
      side = _item._side;
    }

    // for tab insert - decide item background
    if (typeof nodeBackground === "undefined") {
      nodeBackground = true;
    }

    let setNodeBackground;
    if (withTabButton === false && textItem === true) {
      // + butonu ile text item ekleniyor
      setNodeBackground = false;
    } else if (withTabButton === false && textItem === false) {
      // + butonu ile box item ekleniyor
      setNodeBackground = true;
    } else if (
      withTabButton === undefined &&
      textItem === undefined &&
      _item._nodeBackground === false
    ) {
      // tab ile  text  item ekleniyor
      setNodeBackground = false;
    } else if (
      withTabButton === undefined &&
      textItem === undefined &&
      _item._nodeBackground === true
    ) {
      // tab ile box item ekleniyor
      setNodeBackground = true;
    }

    // insert item to dom
    const action = new MM.Action.InsertNewItem(
      _item,
      _item.getChildren().length,
      side,
      setNodeBackground
    );
    MM.App.action(action, "newItem");
    // textItem === true ? false : _item._nodeBackground // eski hali

    // set selected node to new added item
    _item._dom.node.classList.remove("current"); // Tab/Insert ile eklerken parent in butonlarini gostermesin diye
    MM.App.current = action._item;
    MM.App.select(MM.App.current);
    // to change new item shape as its parent
    // var actionShape = new MM.Action.SetShape(MM.App.current, _itemShape);
    // MM.App.action(actionShape);

    // update item & start edit
    setTimeout(function () {
      MM.App.current._parent.update();
      MM.Command.Edit.execute();
      MM.App.current.startEditing();
    }, 200);

    return action._item;
  };

  MM.Command.FinishAndNewSibling = Object.create(MM.Command, {
    label: { value: Resources.getValue("addSiblingItemMsgTxt") },
    keys: {
      value: [{ keyCode: 13, altKey: false, ctrlKey: false, shiftKey: false }], // Enter
    },
    editMode: { value: null },
  });

  MM.Command.FinishAndNewSibling.execute = function () {
    if (!MM.App.current) {
      return;
    }

    // dont insert child when formatter active
    if (MM.App.formattingIsActive) {
      return;
    }

    if (MM.App.editing == true) {
      // yeni eklemiyor sadece text i kaydedip item i seciyor
      MM.App.editing = false;
      var text = MM.App.current.stopEditing();
      var action = new MM.Action.SetText(MM.App.current, text);
      MM.App.action(action, "set-text");
      MM.App.select(MM.App.current);
    } else {
      // editleme yoksa ayni seviyeye yeni item ekliyor
      // add new child to parent (new sibling) -----
      // get current item (parent of new child)
      const _item = MM.App.current;
      let side;

      if (_item && _item._parent && _item._parent.isRoot() === true) {
        side = _item._side;
      } else {
        side = _item._parent._side;
      }

      // insert item to dom
      const actionNewItem = new MM.Action.InsertNewItem(
        _item._parent,
        _item._parent.getChildren().length,
        side,
        _item._parent._nodeBackground
      );
      MM.App.action(actionNewItem, "newItem");

      // set selected node to new added item
      _item._dom.node.classList.remove("current"); // Enter ile eklerken parent in butonlarini gostermesin diye
      MM.App.current = actionNewItem._item;
      MM.App.select(MM.App.current);

      // update item & start edit
      setTimeout(function () {
        MM.App.current._parent.update();
        MM.Command.Edit.execute();
        MM.App.current.startEditing();
      }, 200);

      return actionNewItem._item;
    }
  };

  MM.Command.AddImage = Object.create(MM.Command, {
    label: { value: Resources.getValue("addRemoveImageMsgTxt") },
  });

  MM.Command.AddImage.execute = function () {
    if (!MM.App.current.getImage()) {
      // add image
      document.querySelector(".map-image-upload").click();
    } else {
      // delete image
      Utils.modalm().open({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: Resources.getValue("areyousureMsgTxt"),
        bodyContent:
          "<p>" + Resources.getValue("deleteImageApproveModalMsgTxt") + "</p>",
        buttons: [
          {
            text: Resources.getValue("noMsgTxt"),
            class: "button fresh-button reject-button",
            href: "",
          },
          {
            text: Resources.getValue("yesMsgTxt"),
            class: "button yellow-button confirm-button",
            href: "",
          },
        ],
        confirmCallback: MM.Action.DeleteImage,
        rejectCallback: null,
      });
    }
  };

  MM.Command.Delete = Object.create(MM.Command, {
    label: { value: Resources.getValue("deleteAnItemMsgTxt") },
    keys: { value: [{ keyCode: 46 }] }, // Delete
  });

  MM.Command.Delete.isValid = function () {
    if (!MM.App.current) {
      return false;
    }

    return (
      (MM.Command.isValid.call(this) && !MM.App.current.isRoot()) ||
      MM.App.current.isRoot()
    );
  };

  MM.Command.Delete.execute = function () {
    if (MM.App.current.isRoot()) {
      Utils.modalm().open({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: Resources.getValue("errorMsgTxt"),
        bodyContent:
          "<p>" + Resources.getValue("isRootDeleteModalMsgTxt") + "</p>",
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: "button yellow-button confirm-button",
            href: "",
          },
        ],
      });
    } else if (!MM.App.current.isRoot()) {
      Utils.modalm().open({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: Resources.getValue("areyousureMsgTxt"),
        bodyContent:
          "<p>" + Resources.getValue("deleteNodeApproveModalMsgTxt") + "</p>",
        buttons: [
          {
            text: Resources.getValue("noMsgTxt"),
            class: "button fresh-button reject-button",
            href: "",
          },
          {
            text: Resources.getValue("yesMsgTxt"),
            class: "button yellow-button confirm-button",
            href: "",
          },
        ],
        confirmCallback: MM.Command.DeleteItem,
        rejectCallback: null,
      });
    }
  };

  MM.Command.DeleteItem = function () {
    const item = MM.App.current;
    MM.Action.DeleteImageFromServer(item.getImage());
    const action = new MM.Action.RemoveItem(item);
    MM.App.action(action, "removeItem");
    Utils.modalm().close();
  };

  MM.Command.Save = Object.create(MM.Command, {});

  MM.Command.Save.execute = function () {};

  MM.Command.SaveAs = Object.create(MM.Command, {});
  MM.Command.SaveAs.execute = function () {};

  MM.Command.Load = Object.create(MM.Command, {});
  MM.Command.Load.execute = function () {};
  MM.Command.New = Object.create(MM.Command, {
    label: { value: Resources.getValue("openNewMapMsgTxt") },
    keys: { value: [{ keyCode: "N".charCodeAt(0), altKey: true }] }, // Alt + N
  });

  MM.Command.New.execute = function () {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("areyousureMsgTxt"),
      bodyContent: "<p>" + Resources.getValue("newMapCreateMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("noMsgTxt").toUpperCase(),
          class: "button fresh-button reject-button",
          href: "",
        },
        {
          text: Resources.getValue("yesMsgTxt").toUpperCase(),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
      confirmCallback: MM.Command.New.Map,
      rejectCallback: null,
      scope: null,
    });
  };

  MM.Command.New.Map = function () {
    localStorage.removeItem("openedMapName");
    localStorage.removeItem("openedMapId");
    window.location.href =
      Resources.getValue("appBaseEnvURL") + "/template-list";
  };

  MM.Command.Help = Object.create(MM.Command, {
    label: { value: Resources.getValue("showHideHelpPanelMsgTxt") },
    keys: { value: [{ keyCode: 112, altKey: true }] }, // Alt + F1
  });

  MM.Command.Help.execute = function () {
    MM.App.help.toggle();
  };

  MM.Command.UI = Object.create(MM.Command, {
    label: { value: Resources.getValue("showHideUIPanelMsgTxt") },
    keys: { value: [{ charCode: "*".charCodeAt(0) }] },
  });

  MM.Command.UI.execute = function () {
    // if jscolor opened when mobile swipe. close it
    var jsColorInputs = document.querySelectorAll(".jscolor");
    for (var i = 0; i < jsColorInputs.length; i++) {
      jsColorInputs[i].jscolor.hide();
    }
  };

  MM.Command.Copy = Object.create(MM.Command, {
    label: { value: Resources.getValue("copyItemMsgTxt") },
    prevent: { value: false },
    keys: {
      value: [
        { keyCode: "C".charCodeAt(0), ctrlKey: true },
        // {keyCode: "C".charCodeAt(0), metaKey:true}
      ],
    },
  });

  MM.Command.Copy.execute = function () {
    MM.Clipboard.copy(MM.App.current);
  };

  MM.Command.Cut = Object.create(MM.Command, {
    label: { value: Resources.getValue("cutItemMsgTxt") },
    prevent: { value: false },
    keys: {
      value: [
        { keyCode: "X".charCodeAt(0), ctrlKey: true },
        // {keyCode: "X".charCodeAt(0), metaKey:true}
      ],
    },
  });

  MM.Command.Cut.execute = function () {
    if (MM.App.current) {
      MM.Clipboard.cut(MM.App.current);
    }
  };

  MM.Command.Paste = Object.create(MM.Command, {
    label: { value: Resources.getValue("pasteItemMsgTxt") },
    prevent: { value: false },
    keys: {
      value: [
        { keyCode: "V".charCodeAt(0), ctrlKey: true },
        // {keyCode: "V".charCodeAt(0), metaKey:true}
      ],
    },
  });

  MM.Command.Paste.execute = function () {
    MM.Clipboard.paste(MM.App.current);
  };

  MM.Command.Edit = Object.create(MM.Command, {
    label: { value: Resources.getValue("editAnItemMsgTxt") },
    keys: {
      value: [
        { keyCode: 113 }, // F2
      ],
    },
  });

  MM.Command.Edit.execute = function () {
    MM.App.current.startEditing();
    MM.App.editing = true;
  };

  MM.Command.Finish = Object.create(MM.Command, {
    keys: {
      // value: [{ keyCode: 13, altKey: false, ctrlKey: false, shiftKey: true }], // Shift + Enter
    },
    editMode: { value: true },
  });

  MM.Command.Finish.execute = function () {
    if (!MM.App.current) {
      return;
    }

    MM.App.editing = false;
    var text = MM.App.current.stopEditing();
    var action = new MM.Action.SetText(MM.App.current, text);
    MM.App.action(action, "set-text");
  };

  MM.Command.Newline = Object.create(MM.Command, {
    label: { value: Resources.getValue("lineBreakMsgTxt") },
    keys: {
      value: [
        { keyCode: 13, shiftKey: true, ctrlKey: false }, // Shift + Enter
        // { keyCode: 34 }, // Page Down
      ],
    },
    editMode: { value: true },
  });

  MM.Command.Newline.execute = function () {
    var range = getSelection().getRangeAt(0);
    var br = document.createElement("br");
    range.insertNode(br);
    range.setStartAfter(br);
    MM.App.current.updateSubtree();

    // check space for item
    MM.Map.checkNodeSpace(MM.App.current, "item-resize");

    // detect intersect for active node's children
    MM.Map.detectIntersectionForChildren(MM.App.current);

    // detect intersect for active node's item object
    MM.Map.detectIntersection(MM.App.current);

    // re draw line of node
    MM.App.current.reDrawNodeLine();

    // set new tolbox position for new change
    MM.Mouse._newToolboxSetReposition(MM.App.current);
  };

  MM.Command.Cancel = Object.create(MM.Command, {
    editMode: { value: false },
    keys: { value: [{ keyCode: 27 }] }, // ESC
  });

  MM.Command.Cancel.execute = function () {
    MM.App.editing = false;
    MM.App.current.stopEditing();
    var oldText = MM.App.current.getText();
    if (!oldText) {
      /* newly added node */
      var action = new MM.Action.RemoveItem(MM.App.current);
      MM.App.action(action);
    }
  };

  MM.Command.Style = Object.create(MM.Command, {
    editMode: { value: null },
    command: { value: "" },
  });

  MM.Command.Style.execute = function () {
    // if (MM.App.editing) {
    //   // document.execCommand(this.command, null, null);
    // } else {
    //   MM.Command.Edit.execute();
    //   var selection = getSelection();
    //   var range = selection.getRangeAt(0);
    //   range.selectNodeContents(MM.App.current.getDOM().text);
    //   selection.removeAllRanges();
    //   selection.addRange(range);
    //   this.execute();
    //   MM.Command.Finish.execute();
    // }

    switch (this.command) {
      case "bold":
        MM.Item.setTextStyle("b");
        break;
      case "italic":
        MM.Item.setTextStyle("i");
        break;
      case "underline":
        MM.Item.setTextStyle("u");
        break;
      case "strikeThrough":
        MM.Item.setTextStyle("strike");
        break;
    }
  };

  MM.Command.Bold = Object.create(MM.Command.Style, {
    command: { value: "bold" },
    label: { value: Resources.getValue("boldMsgTxt") },
    keys: { value: [{ keyCode: "B".charCodeAt(0), ctrlKey: true }] },
  });

  MM.Command.Underline = Object.create(MM.Command.Style, {
    command: { value: "underline" },
    label: { value: Resources.getValue("underlineMsgTxt") },
    keys: { value: [{ keyCode: "U".charCodeAt(0), ctrlKey: true }] },
  });

  MM.Command.Italic = Object.create(MM.Command.Style, {
    command: { value: "italic" },
    label: { value: Resources.getValue("italicMsgTxt") },
    keys: { value: [{ keyCode: "I".charCodeAt(0), ctrlKey: true }] },
  });

  MM.Command.Strikethrough = Object.create(MM.Command.Style, {
    command: { value: "strikeThrough" },
    label: { value: Resources.getValue("strikeThroughMsgTxt") },
    keys: { value: [{ keyCode: "J".charCodeAt(0), ctrlKey: true }] }, // Ctrl + J
  });

  MM.Command.Value = Object.create(MM.Command, {
    label: { value: "Set value" },
    keys: {
      value: [
        {
          charCode: "v".charCodeAt(0),
          ctrlKey: true,
          altKey: true,
          metaKey: false,
        },
      ],
    },
  });

  MM.Command.Value.execute = function () {
    var item = MM.App.current;
    var oldValue = item.getValue();
    var newValue = prompt("Set item value", oldValue);
    if (newValue == null) {
      return;
    }

    if (!newValue.length) {
      newValue = null;
    }

    var numValue = parseFloat(newValue);
    var action = new MM.Action.SetValue(
      item,
      isNaN(numValue) ? newValue : numValue
    );
    MM.App.action(action);
  };
}

MM.Layout = Object.create(MM.Repo, {
  ALL: { value: [] },
});

MM.Layout.getAll = function () {
  return this.ALL;
};

MM.Layout.update = function (item) {
  return this;
};

MM.Layout.getChildDirection = function (child) {
  return "";
};

MM.Layout.pick = function (item, dir) {
  var opposite = {
    left: "right",
    right: "left",
    top: "bottom",
    bottom: "top",
  };

  /* direction for a child */
  if (!item.isCollapsed()) {
    var children = item.getChildren();
    for (var i = 0; i < children.length; i++) {
      var child = children[i];
      if (this.getChildDirection(child) == dir) {
        return child;
      }
    }
  }

  if (item.isRoot()) {
    return item;
  }

  var parentLayout = item.getParent().getLayout();
  var thisChildDirection = parentLayout.getChildDirection(item);
  if (thisChildDirection == dir) {
    return item;
  } else if (thisChildDirection == opposite[dir]) {
    return item.getParent();
  } else {
    return parentLayout.pickSibling(
      item,
      dir == "left" || dir == "top" ? -1 : +1
    );
  }
};

MM.Layout.pickSibling = function (item, dir) {
  if (item.isRoot()) {
    return item;
  }

  var children = item.getParent().getChildren();
  var index = children.indexOf(item);
  index += dir;
  index = (index + children.length) % children.length;
  return children[index];
};

MM.Layout._fillContent = function (item) {
  // append content elements
  item._dom.content.insertBefore(item._dom.image, item._dom.content.firstChild);
  item._dom.content.insertBefore(item._dom.icon, item._dom.content.firstChild);
  item._dom.content.insertBefore(
    item._dom.bgColor,
    item._dom.content.firstChild
  );

  // set side / or parent's side / or default side set to dom prop & _side prop
  const itemSide =
    item._side || (item._parent && item._parent._side) || "right";
  item._dom.node.setAttribute("data-child-direction", itemSide);
  item._side = itemSide;
};

MM.Layout.Graph = Object.create(MM.Layout, {
  SPACING_RANK: { value: 16 },
  childDirection: { value: "" },
});

MM.Layout.Graph.getChildDirection = function (child) {
  return this.childDirection;
};

MM.Layout.Graph.create = function (direction, id, label) {
  var layout = Object.create(this, {
    childDirection: { value: direction },
    id: { value: id },
    label: { value: label },
  });
  MM.Layout.ALL.push(layout);
  return layout;
};

MM.Layout.Graph.drawConnectorLines = (parentItem, itemList) => {
  // not draw parent item is collapsed or parent item is root
  if (!parentItem || parentItem._root || parentItem.isCollapsed()) {
    return;
  }

  // definitions
  const lineCutOff = 5;
  let lineWidth = 3;

  // root a image bg eklendiyse
  if (
    MM.App.current?._parent?._root &&
    MM.App.current._dom.content.classList.contains("only")
  ) {
    MM.App.current._dom.node.classList.add("image-item-box");
    // root ta image var ise ortalama hesaplamasi icin
    MM.App.map.rootImageCenter();
  }

  itemList.forEach((item) => {
    // set node position on map - left & right
    item._dom.node.style.left = `${item._position.left}px`;
    item._dom.node.style.top = `${item._position.top}px`;

    // set node size (for image items)
    item._dom.content.style.width = `${item._imageItemSize?.w}px`;
    item._dom.content.style.height = `${item._imageItemSize?.h}px`;
    if (item._dom.content.classList.contains("only")) {
      item._dom.node.classList.add("image-item-box");
    }

    // clear old canvas
    MM.Item.clearCanvas(item);

    if (item._nodeLine) {
      // create canvas
      const canvas = MM.Item.createCanvas(item, item._parent);
      const ctx = canvas.getContext("2d");
      const childColor = item.getColor().split(" ")[0]; // clear "!important"

      // define/calc variables
      // parentItem's
      let startPointX, startPointY, endPointX, endPointY;
      const parentItemContentOffsetLeft = parentItem._dom.content.offsetLeft;
      const parentItemContentOffsetTop = parentItem._dom.content.offsetTop;
      const parentItemContentOffsetWidth = parentItem._dom.content.offsetWidth;
      const parentItemContentOffsetHeight =
        parentItem._dom.content.offsetHeight;
      const parentItemContentHalfPointX =
        parentItemContentOffsetLeft + parentItemContentOffsetWidth / 2;
      const parentItemContentHalfPointY =
        parentItemContentOffsetTop + parentItemContentOffsetHeight / 2;

      // item's
      const itemNodeOffsetLeft = item._dom.node.offsetLeft;
      const itemNodeOffsetTop = item._dom.node.offsetTop;
      const itemContentOffsetWidth = item._dom.content.offsetWidth;
      const itemContentOffsetHeight = item._dom.content.offsetHeight;
      const itemEndPointX = itemNodeOffsetLeft + itemContentOffsetWidth;
      const itemEndPointY = itemNodeOffsetTop + itemContentOffsetHeight;
      const itemContentHalfPointX =
        itemNodeOffsetLeft + itemContentOffsetWidth / 2;
      const itemContentHalfPointY =
        itemNodeOffsetTop + itemContentOffsetHeight / 2;

      // set startPointY points
      startPointY =
        parentItemContentOffsetTop + parentItemContentOffsetHeight / 2;

      // set startPointX points
      if (!parentItem._nodeBackground) {
        if (item._side === "right") {
          startPointX =
            parentItemContentOffsetLeft + parentItemContentOffsetWidth;
        } else {
          startPointX = parentItemContentOffsetLeft;
        }
      } else {
        startPointX = parentItemContentHalfPointX;
      }

      // set endPointX & endPointY points
      if (!item._nodeBackground) {
        let alignPoint = null;
        // set align point
        if (parentItem._side === "right") {
          alignPoint =
            parentItemContentOffsetLeft + parentItemContentOffsetWidth / 2;
        } else {
          alignPoint = parentItemContentOffsetLeft;
        }
        if (itemNodeOffsetLeft > alignPoint) {
          endPointX = itemNodeOffsetLeft;
          endPointY = itemContentHalfPointY;
        } else if (
          itemNodeOffsetLeft <= alignPoint &&
          itemEndPointX > alignPoint
        ) {
          endPointX = itemContentHalfPointX;
          if (parentItemContentHalfPointY < itemContentHalfPointY) {
            endPointY = itemNodeOffsetTop;
          } else {
            endPointY = itemEndPointY;
          }
        } else {
          endPointX = itemEndPointX;
          endPointY = itemContentHalfPointY;
        }
      } else {
        endPointX = itemContentHalfPointX;
        endPointY = itemNodeOffsetTop + itemContentOffsetHeight / 2;
      }

      // decide canvas min-height & min-width settings
      if (endPointX <= 0 && endPointY >= 0) {
        canvas.style.left = `${endPointX}px`;
        canvas.style.top = `0px`;
        startPointX += Math.abs(endPointX);
        endPointX += Math.abs(endPointX);
      } else if (endPointX > 0 && endPointY < 0) {
        canvas.style.left = `0px`;
        canvas.style.top = `${endPointY - lineCutOff}px`;
        startPointY += Math.abs(endPointY);
        endPointY += Math.abs(endPointY) + lineCutOff;
      } else if (endPointX <= 0 && endPointY <= 0) {
        canvas.style.left = `${endPointX}px`;
        canvas.style.top = `${endPointY - lineCutOff}px`;
        startPointX += Math.abs(endPointX);
        endPointX += Math.abs(endPointX);
        startPointY += Math.abs(endPointY);
        endPointY += Math.abs(endPointY) + lineCutOff;
      } else {
        canvas.style.left = `0px`;
        canvas.style.top = `0px`;
      }

      // set canvas width & height - offsets
      canvas.width = Math.abs(endPointX) + startPointX + lineCutOff;
      canvas.height = Math.abs(endPointY) + startPointY;

      // 1st level nodes
      if (item._parent.isRoot()) {
        const angle =
          Math.atan2(endPointY - startPointY, endPointX - startPointX) +
          Math.PI / 2;
        const dx = Math.cos(angle) * 6;
        const dy = Math.sin(angle) * 6;

        // draw line
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = childColor;
        ctx.fillStyle = childColor;
        ctx.beginPath();
        ctx.moveTo(startPointX - dx, startPointY - dy);
        ctx.quadraticCurveTo(
          (endPointX + startPointX) / 2,
          endPointY,
          endPointX,
          endPointY
        );
        ctx.quadraticCurveTo(
          (endPointX + startPointX) / 2,
          endPointY,
          startPointX + dx,
          startPointY + dy
        );
        ctx.fill();
        ctx.stroke();
      } else {
        // 2nd & low level nodes
        // draw line
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = childColor;
        ctx.beginPath();
        ctx.moveTo(endPointX, endPointY);
        ctx.bezierCurveTo(
          endPointX,
          endPointY,
          startPointX,
          endPointY,
          startPointX,
          startPointY
        );
        ctx.stroke();
      }
    }
  });
};

MM.Layout.Map = Object.create(MM.Layout.Graph, {
  id: { value: "map" },
  label: { value: Resources.getValue("mapMsgTxt") },
  LINE_THICKNESS: { value: 8 },
});
MM.Layout.ALL.push(MM.Layout.Map);

MM.Layout.Map.update = function (item) {
  this._drawMap(item);
};

MM.Layout.Map.getChildDirection = function (child) {
  return child.getSide() || "right";
};

MM.Layout.Map._drawMap = function (item) {
  // fill content of items
  this._fillContent(item);

  if (!item._position && !item.isRoot()) {
    // place nodes on map
    MM.Map.placeNodes(item);
  }

  // draw lines
  const children = item.getChildren();
  if (children.length > 0) {
    MM.Layout.Graph.drawConnectorLines(item, children);
  }
};

// Icon
MM.Icon = Object.create(MM.Repo, {});

MM.Icon.set = function (item) {
  item.getDOM().node.classList.add("iconset-" + this.id);
  return this;
};

MM.Icon.unset = function (item) {
  item.getDOM().node.classList.remove("iconset-" + this.id);
  return this;
};

MM.Icon.update = function (item) {
  return this;
};

// Font
MM.Font = Object.create(MM.Repo, {});

MM.Font.set = function (item) {
  item.getDOM().node.classList.add("font-family-" + this.id);
  return this;
};

MM.Font.unset = function (item) {
  item.getDOM().node.classList.remove("font-family-" + this.id);
  return this;
};

MM.Font.update = function (item) {
  return this;
};

MM.Font.ArchitectsDaughter = Object.create(MM.Font, {
  id: { value: "architects-daughter" },
  label: { value: Resources.getValue("architectsDaughterFontTxt") },
});
MM.Font.IndieFlower = Object.create(MM.Font, {
  id: { value: "indie-flower" },
  label: { value: Resources.getValue("indieFlowerFontTxt") },
});
MM.Font.NerkoOne = Object.create(MM.Font, {
  id: { value: "nerko-one" },
  label: { value: Resources.getValue("nerkoOneFontTxt") },
});
MM.Font.Caveat = Object.create(MM.Font, {
  id: { value: "caveat" },
  label: { value: Resources.getValue("caveatFontTxt") },
});
MM.Font.Cairo = Object.create(MM.Font, {
  id: { value: "cairo" },
  label: { value: Resources.getValue("cairoFontTxt") },
});
MM.Font.Courgette = Object.create(MM.Font, {
  id: { value: "courgette" },
  label: { value: "Courgette" },
});
MM.Font.SourceSansPro = Object.create(MM.Font, {
  id: { value: "source-sans-pro" },
  label: { value: Resources.getValue("sourceSansProFontTxt") },
});
MM.Font.PatrickHand = Object.create(MM.Font, {
  id: { value: "patrick-hand" },
  label: { value: Resources.getValue("patrickHandFontTxt") },
});
MM.Font.Nunito = Object.create(MM.Font, {
  id: { value: "nunito" },
  label: { value: Resources.getValue("nunitoFontTxt") },
});
MM.Font.GrapeNuts = Object.create(MM.Font, {
  id: { value: "grape-nuts" },
  label: { value: "Grape Nuts" },
});
MM.Font.Kalam = Object.create(MM.Font, {
  id: { value: "kalam" },
  label: { value: "Kalam" },
});
MM.Font.Forum = Object.create(MM.Font, {
  id: { value: "forum" },
  label: { value: "Forum" },
});
MM.Font.ShadowsIntoLightTwo = Object.create(MM.Font, {
  id: { value: "shadows-into-light-two" },
  label: { value: "Shadows Into Light Two" },
});
MM.Font.Lemonada = Object.create(MM.Font, {
  id: { value: "lemonada" },
  label: { value: "Lemonada" },
});
MM.Font.Syne = Object.create(MM.Font, {
  id: { value: "syne" },
  label: { value: "Syne" },
});

// Font Size
MM.FontSize = Object.create(MM.Repo, {});

MM.FontSize.set = function (item) {
  item.getDOM().node.classList.add("font-size-" + this.id);
  return this;
};

MM.FontSize.unset = function (item) {
  item.getDOM().node.classList.remove("font-size-" + this.id);
  return this;
};

MM.FontSize.update = function (item) {
  return this;
};

// Font Size Options
MM.FontSize.FontSize11 = Object.create(MM.FontSize, {
  id: { value: "11" },
  label: { value: "11" },
});
MM.FontSize.FontSize12 = Object.create(MM.FontSize, {
  id: { value: "12" },
  label: { value: "12" },
});
MM.FontSize.FontSize13 = Object.create(MM.FontSize, {
  id: { value: "13" },
  label: { value: "13" },
});
MM.FontSize.FontSize14 = Object.create(MM.FontSize, {
  id: { value: "14" },
  label: { value: "14" },
});
MM.FontSize.FontSize15 = Object.create(MM.FontSize, {
  id: { value: "15" },
  label: { value: "15" },
});
MM.FontSize.FontSize16 = Object.create(MM.FontSize, {
  id: { value: "16" },
  label: { value: "16" },
});

// default font size 17
MM.FontSize.FontSize17 = Object.create(MM.FontSize, {
  id: { value: "17" },
  label: { value: "17" },
});
MM.FontSize.FontSize18 = Object.create(MM.FontSize, {
  id: { value: "18" },
  label: { value: "18" },
});
MM.FontSize.FontSize19 = Object.create(MM.FontSize, {
  id: { value: "19" },
  label: { value: "19" },
});
MM.FontSize.FontSize20 = Object.create(MM.FontSize, {
  id: { value: "20" },
  label: { value: "20" },
});
MM.FontSize.FontSize21 = Object.create(MM.FontSize, {
  id: { value: "21" },
  label: { value: "21" },
});
MM.FontSize.FontSize22 = Object.create(MM.FontSize, {
  id: { value: "22" },
  label: { value: "22" },
});
MM.FontSize.FontSize23 = Object.create(MM.FontSize, {
  id: { value: "23" },
  label: { value: "23" },
});
MM.FontSize.FontSize24 = Object.create(MM.FontSize, {
  id: { value: "24" },
  label: { value: "24" },
});
MM.FontSize.FontSize25 = Object.create(MM.FontSize, {
  id: { value: "25" },
  label: { value: "25" },
});
MM.FontSize.FontSize26 = Object.create(MM.FontSize, {
  id: { value: "26" },
  label: { value: "26" },
});
MM.FontSize.FontSize27 = Object.create(MM.FontSize, {
  id: { value: "27" },
  label: { value: "27" },
});
MM.FontSize.FontSize28 = Object.create(MM.FontSize, {
  id: { value: "28" },
  label: { value: "28" },
});
MM.FontSize.FontSize29 = Object.create(MM.FontSize, {
  id: { value: "29" },
  label: { value: "29" },
});
MM.FontSize.FontSize30 = Object.create(MM.FontSize, {
  id: { value: "30" },
  label: { value: "30" },
});
MM.FontSize.FontSize36 = Object.create(MM.FontSize, {
  id: { value: "36" },
  label: { value: "36" },
});
MM.FontSize.FontSize48 = Object.create(MM.FontSize, {
  id: { value: "48" },
  label: { value: "48" },
});
MM.FontSize.FontSize60 = Object.create(MM.FontSize, {
  id: { value: "60" },
  label: { value: "60" },
});
MM.FontSize.FontSize72 = Object.create(MM.FontSize, {
  id: { value: "72" },
  label: { value: "72" },
});

MM.Shape = Object.create(MM.Repo, {
  VERTICAL_OFFSET: { value: 0.5 },
});

MM.Shape.set = function (item) {
  item.getDOM().node.classList.add("shape-" + this.id);
  return this;
};

MM.Shape.unset = function (item) {
  item.getDOM().node.classList.remove("shape-" + this.id);
  return this;
};

MM.Shape.update = function (item) {
  const backgroundColor = item.getColor();
  const color = item.getTextColor();

  // set backgorund color
  if (item._nodeBackground) {
    if (backgroundColor && backgroundColor.indexOf("important") > -1) {
      if (
        (MM.App.current &&
          MM.App.current._dom.bgColor.style.backgroundImage == "none") ||
        (item && item._dom.bgColor.style.backgroundImage == "none") ||
        (item._parent._root &&
          item._dom.bgColor.style.backgroundImage == "none")
      ) {
        item
          .getDOM()
          .content.style.setProperty(
            "background-color",
            backgroundColor.split(" ")[0]
          );
      }
    } else {
      item.getDOM().content.style.backgroundColor = backgroundColor;
    }
  }

  // set text color
  if (color && color.indexOf("important") > -1) {
    item
      .getDOM()
      .content.style.setProperty("color", color.split(" ")[0], "important");
    item
      .getDOM()
      .content.querySelector(".text")
      .style.setProperty("color", color.split(" ")[0], "important");
  } else {
    item.getDOM().content.style.color = color;
  }

  return this;
};

MM.Shape.getHorizontalAnchor = function (item) {
  var node = item.getDOM().content;
  return Math.round(node.offsetLeft + node.offsetWidth / 2) + 0.5;
};

MM.Shape.getVerticalAnchor = function (item) {
  var node = item.getDOM().content;
  return (
    node.offsetTop + Math.round(node.offsetHeight * this.VERTICAL_OFFSET) + 0.5
  );
};
MM.Shape.Underline = Object.create(MM.Shape, {
  id: { value: "underline" },
  label: { value: "Underline" },
  VERTICAL_OFFSET: { value: -3 },
});

MM.Shape.Underline.update = function (item) {
  var dom = item.getDOM();

  var ctx = dom.node
    .querySelector(`[data-item-id="${item._id}"]`)
    .getContext("2d");
  ctx.strokeStyle = item.getColor().split(" ")[0]; // clear "!important"

  var left = dom.content.offsetLeft;
  var right = left + dom.content.offsetWidth;

  var top = this.getVerticalAnchor(item);

  ctx.beginPath();
  ctx.moveTo(left, top);
  ctx.lineTo(right, top);
  ctx.stroke();
};

MM.Shape.Underline.getVerticalAnchor = function (item) {
  var node = item.getDOM().content;
  return node.offsetTop + node.offsetHeight + this.VERTICAL_OFFSET + 0.5;
};
MM.Shape.Box = Object.create(MM.Shape, {
  id: { value: "box" },
  label: { value: Resources.getValue("boxMsgTxt") },
});
MM.Shape.Ellipse = Object.create(MM.Shape, {
  id: { value: "ellipse" },
  label: { value: Resources.getValue("ellipseMsgTxt") },
});
MM.Shape.Circle = Object.create(MM.Shape, {
  id: { value: "circle" },
  label: { value: Resources.getValue("circleMsgTxt") },
});
MM.Shape.Rhombus = Object.create(MM.Shape, {
  id: { value: "rhombus" },
  label: { value: Resources.getValue("rhombusMsgTxt") },
});
MM.Shape.Pentagon = Object.create(MM.Shape, {
  id: { value: "pentagon" },
  label: { value: Resources.getValue("pentagonMsgTxt") },
});
MM.Shape.Octagon = Object.create(MM.Shape, {
  id: { value: "octagon" },
  label: { value: Resources.getValue("octagonMsgTxt") },
});
MM.Shape.Message = Object.create(MM.Shape, {
  id: { value: "message" },
  label: { value: Resources.getValue("messageMsgTxt") },
});
MM.Shape.Parallelogram = Object.create(MM.Shape, {
  id: { value: "parallelogram" },
  label: { value: Resources.getValue("parallelogramMsgTxt") },
});

MM.Shape.Cloud = Object.create(MM.Shape, {
  id: { value: "cloud" },
  label: { value: Resources.getValue("cloudMsgTxt") },
});

MM.Shape.Rectangle = Object.create(MM.Shape, {
  id: { value: "rectangle" },
  label: { value: Resources.getValue("rectangleMsgTxt") },
});

MM.ShapeSize = Object.create(MM.Repo, {});

MM.ShapeSize.set = function (item) {
  item.getDOM().node.classList.add("shapeSize-" + this.id);
  return this;
};

MM.ShapeSize.unset = function (item) {
  item.getDOM().node.classList.remove("shapeSize-" + this.id);
  return this;
};

MM.ShapeSize.update = function (item) {
  return this;
};

MM.ShapeSize.getHorizontalAnchor = function (item) {
  var node = item.getDOM().content;
  return Math.round(node.offsetLeft + node.offsetWidth / 2) + 0.5;
};

MM.ShapeSize.getVerticalAnchor = function (item) {
  const verticalAnchor =
    item._dom.node.offsetTop + item._dom.node.offsetHeight / 2;
  return verticalAnchor;
};

MM.ShapeSize.Auto = Object.create(MM.ShapeSize, {
  id: { value: "auto" },
  label: { value: Resources.getValue("autoMsgTxt") },
});
MM.ShapeSize.Small = Object.create(MM.ShapeSize, {
  id: { value: "small" },
  label: { value: Resources.getValue("smallMsgTxt") },
});
MM.ShapeSize.Medium = Object.create(MM.ShapeSize, {
  id: { value: "medium" },
  label: { value: Resources.getValue("mediumMsgTxt") },
});
MM.ShapeSize.Large = Object.create(MM.ShapeSize, {
  id: { value: "large" },
  label: { value: Resources.getValue("largeMsgTxt") },
});
MM.ShapeSize.Largest = Object.create(MM.ShapeSize, {
  id: { value: "largest" },
  label: { value: Resources.getValue("largestMsgTxt") },
});

MM.Line = Object.create(MM.Repo, {});

MM.Line.set = function (item) {
  item.getDOM().node.classList.add("line-" + this.id);
  return this;
};

MM.Line.unset = function (item) {
  item.getDOM().node.classList.remove("line-" + this.id);
  return this;
};

MM.Line.update = function (item) {
  return this;
};

MM.Line.Solid = Object.create(MM.Line, {
  id: { value: "solid" },
  label: { value: Resources.getValue("solidMsgTxt") },
});

MM.Line.Dashed = Object.create(MM.Line, {
  id: { value: "dashed" },
  label: { value: Resources.getValue("dashedMsgTxt") },
});

MM.Line.Dotted = Object.create(MM.Line, {
  id: { value: "dotted" },
  label: { value: Resources.getValue("dottedMsgTxt") },
});

MM.Format = Object.create(MM.Repo, {
  extension: { value: "" },
  mime: { value: "" },
});

MM.Format.getByName = function (name) {
  var index = name.lastIndexOf(".");
  if (index == -1) {
    return null;
  }
  var extension = name.substring(index + 1).toLowerCase();
  return this.getByProperty("extension", extension);
};

MM.Format.getByMime = function (mime) {
  return this.getByProperty("mime", mime);
};

MM.Format.to = function (data) {};
MM.Format.from = function (data) {};

MM.Format.nl2br = function (str) {
  return str.replace(/\n/g, "<br/>");
};

MM.Format.br2nl = function (str) {
  return str.replace(/<br\s*\/?>/g, "\n");
};
MM.Format.JSON = Object.create(MM.Format, {
  id: { value: "json" },
  label: { value: "Native (JSON)" },
  extension: { value: "mymind" },
  mime: { value: "application/vnd.mymind+json" },
});

MM.Format.JSON.to = function (data) {
  return JSON.stringify(data, null, "\t") + "\n";
};

MM.Format.JSON.from = function (data) {
  return JSON.parse(data);
};

MM.Backend = Object.create(MM.Repo);

MM.Backend.reset = function () {};

MM.Backend.save = function (data, name) {};

MM.Backend.load = function (name) {};
MM.Backend.Local = Object.create(MM.Backend, {
  label: { value: "Browser storage" },
  id: { value: "local" },
  prefix: { value: "mm.map." },
});

MM.Backend.Local.save = function (data, id, name) {
  localStorage.setItem(this.prefix + id, data);

  var names = this.list();
  names[id] = name;
  localStorage.setItem(this.prefix + "names", JSON.stringify(names));
};

MM.Backend.Local.load = function (id) {
  var data = localStorage.getItem(this.prefix + id);
  if (!data) {
    throw new Error("There is no such saved map");
  }
  return data;
};

MM.Backend.Local.remove = function (id) {
  localStorage.removeItem(this.prefix + id);

  var names = this.list();
  delete names[id];
  localStorage.setItem(this.prefix + "names", JSON.stringify(names));
};

MM.Backend.Local.list = function () {
  try {
    var data = localStorage.getItem(this.prefix + "names") || "{}";
    return JSON.parse(data);
  } catch (e) {
    return {};
  }
};

MM.Backend.Image = Object.create(MM.Backend, {
  id: { value: "image" },
  label: { value: "Image" },
  url: { value: "", writable: true },
});

MM.Backend.Image.save = function (data, name) {};

MM.Backend.File = Object.create(MM.Backend, {
  id: { value: "file" },
  label: { value: "File" },
  input: { value: document.createElement("input") },
});

MM.Backend.File.save = function (data, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href =
    "data:text/plain;base64," + btoa(unescape(encodeURIComponent(data)));
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);

  var promise = new Promise().fulfill();
  return promise;
};

MM.Backend.File.load = function () {
  var promise = new Promise();

  this.input.type = "file";

  this.input.onchange = function (e) {
    var file = e.target.files[0];
    if (!file) {
      return;
    }

    var reader = new FileReader();
    reader.onload = function () {
      promise.fulfill({ data: reader.result, name: file.name });
    };
    reader.onerror = function () {
      promise.reject(reader.error);
    };
    reader.readAsText(file);
  }.bind(this);

  this.input.click();
  return promise;
};

MM.UI = function () {
  this._node = document.querySelector(".ui");

  this._help = document.querySelector(".help-button");

  this._layout = new MM.UI.Layout();
  this._shape = new MM.UI.Shape();
  this._fontFamily = new MM.UI.Font();
  this._fontSize = new MM.UI.FontSize();
  this._shapeSize = new MM.UI.ShapeSize();
  this._line = new MM.UI.Line();
  this._icon = new MM.UI.Icon();
  this._bgColor = new MM.UI.BgColor();
  this._image = new MM.UI.Image();
  this._color = new MM.UI.Color();
  this._borderColor = new MM.UI.BorderColor();
  this._textColor = new MM.UI.TextColor();
  this._value = new MM.UI.Value();

  this._node.addEventListener("click", this);

  ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
    window.location.pathname.includes("public-share-link") === false) ||
    (JSON.parse(localStorage.getItem("mapPermission")) === 2 &&
      window.location.pathname.includes("public-share-link") === false) ||
    (JSON.parse(localStorage.getItem("mapPermission")) === 3 &&
      window.location.pathname.includes("public-share-link") === false)) &&
    this._help.addEventListener("click", this);
  this._node.addEventListener("change", this);
};

MM.UI.prototype.handleMessage = function (message, publisher) {
  switch (message) {
    case "item-select":
      this._update();
      break;

    case "item-change":
      if (publisher == MM.App.current) {
        this._update();
      }
      break;
  }
};

MM.UI.prototype.handleEvent = function (e) {
  switch (e.type) {
    case "click":
      if (e.target.closest(".select")) return;

      if (e.target.nodeName.toLowerCase() != "select") {
        MM.Clipboard.focus();
      } /* focus the clipboard (2c) */

      var node = e.target;
      while (node != document) {
        var command = node.getAttribute("data-command");
        if (command) {
          MM.Command[command].execute();
          return;
        }
        node = node.parentNode;
      }

      break;

    case "change":
      MM.Clipboard.focus(); /* focus the clipboard (2c) */
      break;
  }
};

MM.UI.prototype.getWidth = function () {
  return this._node.classList.contains("visible") ? this._node.offsetWidth : 0;
};

MM.UI.prototype._update = function () {
  this._layout.update();
  this._shape.update();
  this._shapeSize.update();
  this._line.update();
  this._icon.update();
  this._image.update();
  this._value.update();
};
MM.UI.Layout = function () {};

MM.UI.Layout.prototype.update = function () {};

MM.UI.Layout.prototype.handleEvent = function (e) {};

MM.UI.Layout.prototype._getOption = function (value) {};

MM.UI.Layout.prototype._buildGroup = function (label) {};

MM.UI.Shape = function () {};

MM.UI.Shape.prototype.update = function () {
  var value = "";
  var shape = MM.App.current.getOwnShape();
  if (shape) {
    value = shape.id;
  }

  ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
    window.location.pathname.includes("public-share-link") === false) ||
    JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
    JSON.parse(localStorage.getItem("mapPermission")) === 3) &&
    (this._select.value = value);
};

MM.UI.Shape.prototype.handleEvent = function (e) {
  var shape = MM.Shape.getById(this._select.value);
  var action = new MM.Action.SetShape(MM.App.current, shape);
  MM.App.action(action);
};

MM.UI.ShapeSize = function () {};

MM.UI.ShapeSize.prototype.update = function () {};

MM.UI.ShapeSize.prototype.handleEvent = function (e) {};

MM.UI.Font = function () {};

MM.UI.Font.prototype.update = function () {};

MM.UI.Font.prototype.handleEvent = function (e) {};

MM.UI.FontSize = function () {};

MM.UI.FontSize.prototype.update = function () {};

MM.UI.FontSize.prototype.handleEvent = function (e) {};

MM.UI.Line = function () {};

MM.UI.Line.prototype.update = function () {};

MM.UI.Line.prototype.handleEvent = function (e) {};

MM.UI.Value = function () {
  this._select = document.querySelector("#value");
  this._select.addEventListener("change", this);
};

MM.UI.Value.prototype.update = function () {
  var value = MM.App.current.getValue();
  if (value === null) {
    value = "";
  }
  if (typeof value == "number") {
    value = "num";
  }

  this._select.value = value;
};

MM.UI.Value.prototype.handleEvent = function (e) {
  var value = this._select.value;
  if (value == "num") {
    MM.Command.Value.execute();
  } else {
    var action = new MM.Action.SetValue(MM.App.current, value || null);
    MM.App.action(action);
  }
};

MM.UI.Color = function () {};

MM.UI.Color.prototype.handleEvent = function (e) {
  if (MM.App.current._parent._root) {
    if (MM.App.current._dom.bgColor.style.backgroundImage == "none") {
      var color =
        document.querySelector(".color-picker-border").value + " !important";
      var action = new MM.Action.SetColor(MM.App.current, color);
      MM.App.action(action);
    } else {
      var color = "transparent" + "!important";
      var action = new MM.Action.SetColor(MM.App.current, color);
      MM.App.action(action);
    }
  } else {
    var color = document.querySelector(".color-picker-border").value;
    var action = new MM.Action.SetColor(MM.App.current, color);
    MM.App.action(action);
    // for change border color with item's line color
    let itemCanvas = MM.App.current._parent._dom.node
      .querySelector(`.root [data-item-id="${MM.App.current._id}"]`)
      .getContext("2d");
    itemCanvas.strokeStyle = color;
    itemCanvas.fillStyle = color;
    (MM.App.current._children.length !== 0 ||
      MM.App.current._parent.isRoot()) &&
      itemCanvas.fill();
    itemCanvas.stroke();
  }
};

MM.UI.BorderColor = function () {};

MM.UI.BorderColor.prototype.handleEvent = function (e) {};

MM.UI.TextColor = function () {};

MM.UI.TextColor.prototype.handleEvent = function (e) {
  var textColor =
    document.querySelector(".color-picker-text").value + " !important";
  var action = new MM.Action.SetTextColor(MM.App.current, textColor);
  MM.App.action(action);
};

// icon - native select disabled
MM.UI.Icon = function () {};

MM.UI.Icon.prototype.update = function () {};

// - native select disabled
MM.UI.Icon.prototype.handleEvent = function (e) {};

// react select change version used
MM.UI.Icon.prototype.change = function (value) {};

// bgColor
MM.UI.BgColor = function () {};

MM.UI.BgColor.prototype.handleEvent = function (e) {
  if (MM.App.current._parent._root) {
    if (MM.App.current._dom.bgColor.style.backgroundImage == "none") {
      var bgColor =
        document.querySelector(".color-picker").value + " !important";
      var action = new MM.Action.SetBgColor(MM.App.current, bgColor);
      MM.App.action(action);
    } else {
      var bgColor = "transparent" + "!important";
      var action = new MM.Action.SetBgColor(MM.App.current, bgColor);
      MM.App.action(action);
    }
  } else {
    var bgColor = document.querySelector(".color-picker").value;
    var action = new MM.Action.SetBgColor(MM.App.current, bgColor);
    MM.App.action(action);
  }
};

// image
MM.UI.Image = function () {
  this._input = document.querySelector(".map-image-upload");
  this._input.addEventListener("change", this);
};

MM.UI.Image.prototype.update = function () {};

MM.UI.Image.prototype.handleEvent = function (e) {
  var fileSizeLimit = 3072; // 3072 kb max image fize size - (3 MB)

  if (e.target.files.length > 0) {
    if (e.target.files[0].size < fileSizeLimit * 1024) {
      MM.Action.ConvertAndUploadImage(e.target.files[0]);
      e.target.value = null; // clear input after image upload
    } else {
      Utils.modalm().open({
        exitButtonText: Resources.getValue("exitMsgTxt"),
        title: Resources.getValue("errorMsgTxt"),
        bodyContent:
          "<p>" +
          Resources.getValue("imageFileSizeMsgTxt").replace(
            "*_*_*",
            fileSizeLimit
          ) +
          "</p>",
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: "button yellow-button confirm-button",
            href: "",
          },
        ],
      });
    }
  }
};

if (
  (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
    window.location.pathname.includes("public-share-link") === false) ||
  JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
  JSON.parse(localStorage.getItem("mapPermission")) === 3
) {
  MM.UI.Help = function () {
    this._node = document.querySelector("#help");
    this._map = {
      8: "Backspace",
      9: "Tab",
      // 13: "↩",
      13: "Enter",
      27: "ESC",
      33: "PgUp",
      34: "PgDown",
      35: "End",
      36: "Home",
      37: "←",
      38: "↑",
      39: "→",
      40: "↓",
      45: "Insert",
      46: "Delete",
      65: "A",
      68: "D",
      83: "S",
      87: "W",
      112: "F1",
      113: "F2",
      114: "F3",
      115: "F4",
      116: "F5",
      117: "F6",
      118: "F7",
      119: "F8",
      120: "F9",
      121: "F10",
      122: "F11",
      "-": "&minus;",
    };

    this._build();
  };

  MM.UI.Help.prototype.toggle = function () {
    this._node.classList.toggle("visible");
  };

  MM.UI.Help.prototype._build = function () {
    var t = this._node.querySelector(".navigation");
    this._buildRow(t, "Pan");
    this._buildRow(t, "Select");
    this._buildRow(t, "SelectRoot");
    this._buildRow(t, "SelectParent");
    this._buildRow(t, "Center");
    this._buildRow(t, "ZoomIn", "ZoomOut");
    this._buildRow(t, "Fold");
    var t = this._node.querySelector(".manipulation");
    this._buildRow(t, "InsertChild");
    this._buildRow(t, "FinishAndNewSibling");
    this._buildRow(t, "Delete");
    this._buildRow(t, "AddRemoveNodeLine");
    this._buildRow(t, "Copy");
    this._buildRow(t, "Cut");
    this._buildRow(t, "Paste");

    var t = this._node.querySelector(".editing");
    this._buildRow(t, "Edit");
    this._buildRow(t, "Newline");
    this._buildRow(t, "Bold");
    this._buildRow(t, "Italic");
    this._buildRow(t, "Underline");
    this._buildRow(t, "Strikethrough");

    var t = this._node.querySelector(".other");
    this._buildRow(t, "FinishItemFormat");
    this._buildRow(t, "New");
    this._buildRow(t, "Undo", "Redo");
    this._buildRow(t, "Save");
    this._buildRow(t, "SaveAs");
    this._buildRow(t, "Load");
    this._buildRow(t, "Help");
    this._buildRow(t, "FullScreen");
  };

  MM.UI.Help.prototype._buildRow = function (table, commandName) {
    var row = table.insertRow(-1);

    var labels = [];
    var keys = [];

    for (var i = 1; i < arguments.length; i++) {
      var command = MM.Command[arguments[i]];
      labels.push(command.label);
      keys = keys.concat(command.keys.map(this._formatKey, this));
    }

    row.insertCell(-1).innerHTML = labels.join("/");
    row.insertCell(-1).innerHTML = keys.join("/");
  };

  MM.UI.Help.prototype._formatKey = function (key) {
    var str = "";
    if (key.ctrlKey) {
      str += "Ctrl+";
    }
    if (key.altKey) {
      str += "Alt+";
    }
    if (key.shiftKey) {
      str += "Shift+";
    }
    if (key.charCode) {
      var ch = String.fromCharCode(key.charCode);
      str += this._map[ch] || ch; /* ch.toUpperCase() */
    }
    if (key.keyCode) {
      str += this._map[key.keyCode] || String.fromCharCode(key.keyCode);
    }

    return str;
  };
}

MM.UI.IO = function () {
  this._prefix = "mm.app.";
  this._mode = "";
  this._node = document.querySelector("#io");
  this._heading = this._node.querySelector("h3");

  this._backend = this._node.querySelector("#backend");
  this._currentBackend = null;
  this._backends = {};
  var ids = ["local", "file"];
  ids.forEach(function (id) {
    var ui = MM.UI.Backend.getById(id);
    ui.init(this._backend);
    this._backends[id] = ui;
  }, this);

  this._backend.value =
    localStorage.getItem(this._prefix + "backend") || MM.Backend.File.id;
  this._backend.addEventListener("change", this);
};

MM.UI.IO.prototype.restore = function () {
  var parts = {};
  window.location.search
    .substring(1)
    .split("&")
    .forEach(function (item) {
      var keyvalue = item.split("=");
      parts[decodeURIComponent(keyvalue[0])] = decodeURIComponent(keyvalue[1]);
    });

  /* backwards compatibility */
  if ("map" in parts) {
    parts.url = parts.map;
  }

  var backend = MM.UI.Backend.getById(parts.b);
  if (backend) {
    /* saved backend info */
    backend.setState(parts);
    return;
  }
};

MM.UI.IO.prototype.handleMessage = function (message, publisher) {
  switch (message) {
    case "map-new":
      this._setCurrentBackend(null);
      break;

    case "save-done":
    case "load-done":
      this.hide();
      this._setCurrentBackend(publisher);
      break;
  }
};

MM.UI.IO.prototype.show = function (mode) {
  this._mode = mode;
  this._node.classList.add("visible");
  this._heading.innerHTML = mode;

  this._syncBackend();
  window.addEventListener("keydown", this);
};

MM.UI.IO.prototype.hide = function () {
  if (!this._node.classList.contains("visible")) {
    return;
  }
  this._node.classList.remove("visible");
  MM.Clipboard.focus();
  window.removeEventListener("keydown", this);
};

MM.UI.IO.prototype.quickSave = function () {
  this._backends.file.save();
};

MM.UI.IO.prototype.handleEvent = function (e) {
  switch (e.type) {
    case "keydown":
      if (e.keyCode == 27) {
        this.hide();
      }
      break;

    case "change":
      this._syncBackend();
      break;
  }
};

MM.UI.IO.prototype._syncBackend = function () {
  var all = this._node.querySelectorAll("div[id]");
  [].slice.apply(all).forEach(function (node) {
    node.style.display = "none";
  });

  this._node.querySelector("#" + this._backend.value).style.display = "";

  this._backends[this._backend.value].show(this._mode);
};

/**
 * @param {MM.UI.Backend} backend
 */
MM.UI.IO.prototype._setCurrentBackend = function (backend) {
  if (this._currentBackend && this._currentBackend != backend) {
    this._currentBackend.reset();
  }

  if (backend) {
    localStorage.setItem(this._prefix + "backend", backend.id);
  }
  this._currentBackend = backend;
  try {
    this._updateURL(); /* fails when on file:/// */
  } catch (e) {}
};

MM.UI.IO.prototype._updateURL = function () {
  var data = this._currentBackend && this._currentBackend.getState();
  if (!data) {
    window.history.replaceState(null, "", ".");
  } else {
    var arr = Object.keys(data).map(function (key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    });
    window.history.replaceState(null, "", "?" + arr.join("&"));
  }
};
MM.UI.Backend = Object.create(MM.Repo);

MM.UI.Backend.init = function (select) {
  this._backend = MM.Backend.getById(this.id);
  this._mode = "";
  this._prefix = "mm.app." + this.id + ".";

  this._node = document.querySelector("#" + this.id);

  this._cancel = this._node.querySelector(".cancel");
  this._cancel.addEventListener("click", this);

  this._go = this._node.querySelector(".go");
  this._go.addEventListener("click", this);

  select.appendChild(this._backend.buildOption());
};

MM.UI.Backend.reset = function () {
  this._backend.reset();
};

MM.UI.Backend.setState = function (data) {};

MM.UI.Backend.getState = function () {
  return null;
};

MM.UI.Backend.handleEvent = function (e) {
  switch (e.target) {
    case this._cancel:
      MM.App.io.hide();
      break;

    case this._go:
      this._action();
      break;
  }
};

MM.UI.Backend.save = function () {};

MM.UI.Backend.load = function () {};

MM.UI.Backend.show = function (mode) {
  this._mode = mode;

  this._go.innerHTML = mode.charAt(0).toUpperCase() + mode.substring(1);

  var all = this._node.querySelectorAll("[data-for]");
  [].concat.apply([], all).forEach(function (node) {
    node.style.display = "none";
  });

  var visible = this._node.querySelectorAll("[data-for~=" + mode + "]");
  [].concat.apply([], visible).forEach(function (node) {
    node.style.display = "";
  });

  /* switch to 2a: steal focus from the current item */
  this._go.focus();
};

MM.UI.Backend._action = function () {
  switch (this._mode) {
    case "save":
      this.save();
      break;

    case "load":
      this.load();
      break;
  }
};

MM.UI.Backend._saveDone = function () {
  MM.publish("save-done", this);
};

MM.UI.Backend._loadDone = function (json) {
  MM.App.setMap(MM.Map.fromJSON(json));

  // eger root'ta bg image var ise
  if (json.root.imageItemSize !== null) {
    document.getElementById("root").querySelector(".content").style.width =
      json.root.imageItemSize?.w + "px";
    document.getElementById("root").querySelector(".content").style.height =
      json.root.imageItemSize?.h + "px";
  }
};

MM.UI.Backend._error = function (e) {
  console.log(`Error: ${e}`);
};

MM.UI.Backend._buildList = function (list, select) {
  var data = [];

  for (var id in list) {
    data.push({ id: id, name: list[id] });
  }

  data.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });

  data.forEach(function (item) {
    var o = document.createElement("option");
    o.value = item.id;
    o.innerHTML = item.name;
    select.appendChild(o);
  });
};
MM.UI.Backend.File = Object.create(MM.UI.Backend, {
  id: { value: "file" },
});

MM.UI.Backend.File.init = function (select) {
  MM.UI.Backend.init.call(this, select);
};

MM.UI.Backend.File.show = function (mode) {
  MM.UI.Backend.show.call(this, mode);

  this._go.innerHTML = mode == "save" ? "Save" : "Browse";
};

MM.UI.Backend.File._action = function () {
  localStorage.setItem(this._prefix + "format", this._format.value);

  MM.UI.Backend._action.call(this);
};

MM.UI.Backend.File.save = function (silent) {
  if (localStorage.getItem("openedMapName")) {
    var json = MM.App.map.toJSON();
    var data = {
      name: localStorage.getItem("openedMapName"),
      content: JSON.stringify(json),
    };

    var mapModel = {
      id: localStorage.getItem("openedMapId"),
      name: localStorage.getItem("openedMapName"),
      content: data.content,
      backgroundName: MM.App.map._getPageBgImage(),
    };

    if (
      mapModel.id &&
      mapModel.name &&
      mapModel.content &&
      mapModel.content.length > 30
    ) {
      
      localStorage.setItem("content", mapModel.content); // guncellemeleri content e kaydetme

      if (((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
      window.location.pathname.includes("public-share-link") === false) ||
     JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
     JSON.parse(localStorage.getItem("mapPermission")) === 3)) {

 


    MapService.updateMindMapByPermission(mapModel);



 

  localStorage.setItem("content", mapModel.content); // güncellemeleri content'e kaydetme
}
    }
  }
};

MM.UI.Backend.File.sync = (function () {
  var hubConnection;
  var hubConnectionStatus;

  var setHubConnection = function (connectionObj) {
    hubConnection = connectionObj;
  };

  var setHubConnectionStatus = function (status) {
    hubConnectionStatus = status;
  };

  var getHubConnectionStatus = function () {
    return hubConnectionStatus;
  };

  var init = function () {
    var mapId = localStorage.getItem("openedMapId");
    // var mapContent = JSON.stringify(MM.App.map.toJSON());
    // var backgroundName = MM.App.map._getPageBgImage();

    if (window.location.pathname.includes("public-share-link") === false) {
      hubConnection
        .invoke("SendMapToUsers", mapId)
        .catch((err) => console.error(err));
    }
  };

  return {
    init: init,
    setHubConnection: setHubConnection,
    setHubConnectionStatus: setHubConnectionStatus,
    getHubConnectionStatus: getHubConnectionStatus,
  };
})();

MM.UI.Backend.File.load = function () {
  this._backend.load().then(this._loadDone.bind(this), this._error.bind(this));
};

MM.UI.Backend.File._loadDone = function (data) {
  try {
    var format = MM.Format.getByName(data.name) || MM.Format.JSON;
    var json = format.from(data.data);
  } catch (e) {
    this._error(e);
  }

  MM.UI.Backend._loadDone.call(this, json);
};

MM.UI.Backend.Image = Object.create(MM.UI.Backend, {
  id: { value: "image" },
});

MM.UI.Backend.Image.save = function () {};

MM.UI.Backend.Image.load = null;
MM.UI.Backend.Local = Object.create(MM.UI.Backend, {
  id: { value: "local" },
});

MM.UI.Backend.Local.init = function (select) {
  MM.UI.Backend.init.call(this, select);

  this._list = this._node.querySelector(".list");
  this._remove = this._node.querySelector(".remove");
  this._remove.addEventListener("click", this);
};

MM.UI.Backend.Local.handleEvent = function (e) {
  MM.UI.Backend.handleEvent.call(this, e);

  switch (e.target) {
    case this._remove:
      var id = this._list.value;
      if (!id) {
        break;
      }
      this._backend.remove(id);
      this.show(this._mode);
      break;
  }
};

MM.UI.Backend.Local.show = function (mode) {
  MM.UI.Backend.show.call(this, mode);

  this._go.disabled = false;

  if (mode == "load") {
    var list = this._backend.list();
    this._list.innerHTML = "";
    if (Object.keys(list).length) {
      this._go.disabled = false;
      this._remove.disabled = false;
      this._buildList(list, this._list);
    } else {
      this._go.disabled = true;
      this._remove.disabled = true;
      var o = document.createElement("option");
      o.innerHTML = "(no maps saved)";
      this._list.appendChild(o);
    }
  }
};

MM.UI.Backend.Local.setState = function (data) {
  this._load(data.id);
};

MM.UI.Backend.Local.getState = function () {
  var data = {
    b: this.id,
    id: MM.App.map.getId(),
  };
  return data;
};

MM.UI.Backend.Local.save = function () {
  var json = MM.App.map.toJSON();
  var data = MM.Format.JSON.to(json);

  try {
    this._backend.save(data, MM.App.map.getId(), MM.App.map.getName());
    this._saveDone();
  } catch (e) {
    this._error(e);
  }
};

MM.UI.Backend.Local.load = function () {
  this._load(this._list.value);
};

MM.UI.Backend.Local._load = function (id) {
  try {
    var data = this._backend.load(id);
    var json = MM.Format.JSON.from(data);
    this._loadDone(json);
  } catch (e) {
    this._error(e);
  }
};

MM.Mouse = {
  TOUCH_DELAY: 500,
  _port: null,
  _cursor: [0, 0],
  _pos: [0, 0] /* ghost pos */,
  _mode: "",
  _item: null,
  _ghost: null,
  _oldDragState: null,
  _touchTimeout: null,
};

MM.Mouse.init = function (port) {
  this._port = port;
  this._port.addEventListener("touchstart", this);
  this._port.addEventListener("mousedown", this);
  this._port.addEventListener("click", this);
  this._port.addEventListener("dblclick", this);
  this._port.addEventListener("wheel", this);
  this._port.addEventListener("mousewheel", this);
  this._port.addEventListener("contextmenu", this);
};

MM.Mouse.handleEvent = function (e) {
  switch (e.type) {
    case "click":
      // if closest options true > no select item
      if (e.target.closest(".options")) {
        if (e.target.classList.contains("show-hide-toolbox")) {
          e.stopPropagation();
        }
        return;
      }

      var item = MM.App.map.getItemFor(e.target);
      MM.App.current = item;

      ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
        window.location.pathname.includes("public-share-link") === false) ||
        JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
        JSON.parse(localStorage.getItem("mapPermission")) === 3) &&
        MM.App.hideNewToolbox();

      // find closest
      function getClosest(el, tag) {
        tag = tag.toUpperCase();
        do {
          if (el.nodeName === tag) {
            return el;
          }
        } while ((el = el.parentNode));

        return null;
      }
      if (
        getClosest(e.target, "div") ||
        e.target.classList.contains("content")
      ) {
        if (item) {
          MM.App.select(item);
          e.stopPropagation();
        }
      }

      break;

    case "dblclick":
      var item = MM.App.map.getItemFor(e.target);
      MM.App.current = item;

      if (
        (item &&
          JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
          window.location.pathname.includes("public-share-link") === false) ||
        JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
        JSON.parse(localStorage.getItem("mapPermission")) === 3
      ) {
        MM.Command.Edit.execute();
      }
      break;

    case "contextmenu":
      this._endDrag();
      e.preventDefault();
      var item = MM.App.map.getItemFor(e.target);
      if (item) {
        MM.App.current = item;
        MM.App.map._root.deselect();
        MM.App.map._root._dom.node.classList.remove("current");
        MM.App.map._root.getChildren().forEach(function (itm) {
          itm.deselect();
          itm._dom.node.classList.remove("current");
        });
        item.select();
      }
      ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
        window.location.pathname.includes("public-share-link") === false &&
        JSON.parse(localStorage.getItem("beforePresent")) === false) ||
        JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
        JSON.parse(localStorage.getItem("mapPermission")) === 3) &&
        MM.Menu.open(e.clientX, e.clientY);
      break;

    case "touchstart":
      if (e.touches.length > 1) {
        return;
      }
      e.clientX = e.touches[0].clientX;
      e.clientY = e.touches[0].clientY;
    case "mousedown":
      var item = MM.App.map.getItemFor(e.target);
      if (MM.App.editing) {
        if (item == MM.App.current) {
          return;
        } /* ignore dnd on edited node */
        MM.Command.Finish.execute(); /* clicked elsewhere => finalize edit */
      }

      if (e.type == "mousedown") {
        e.preventDefault();
      } /* to prevent blurring the clipboard node */

      if (e.type == "touchstart") {
        /* context menu here, after we have the item */
        this._touchTimeout = setTimeout(function () {
          item && MM.App.select(item);
          ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
            window.location.pathname.includes("public-share-link") === false) ||
            JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
            JSON.parse(localStorage.getItem("mapPermission")) === 3) &&
            MM.Menu.open(e.clientX, e.clientY);
        }, this.TOUCH_DELAY);
      }

      this._startDrag(e, item);
      break;

    case "touchmove":
      if (e.touches.length > 1) {
        return;
      }
      e.clientX = e.touches[0].clientX;
      e.clientY = e.touches[0].clientY;
      clearTimeout(this._touchTimeout);
    case "mousemove":
      this._processDrag(e);
      break;

    case "touchend":
      clearTimeout(this._touchTimeout);
    case "mouseup":
      this._endDrag();
      break;

    case "wheel":
    case "mousewheel":
      var dir = 0;
      if (e.wheelDelta) {
        if (e.wheelDelta < 0) {
          dir = -1;
        } else if (e.wheelDelta > 0) {
          dir = 1;
        }
      }
      if (e.deltaY) {
        if (e.deltaY > 0) {
          dir = -1;
        } else if (e.deltaY < 0) {
          dir = 1;
        }
      }
      if (dir) {
        MM.App.adjustFontSize(dir);
      }
      break;
  }
};

MM.Mouse._newToolboxSetReposition = function (item) {
  var newToolbox = document.querySelector(".new-toolbox-wrapper");
  if (!newToolbox) {
    return;
  }

  var itemRect = item.getDOM().node.getBoundingClientRect();
  var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  var pos = {
    left:
      itemRect.left +
      scrollLeft -
      (newToolbox.offsetWidth - itemRect.width) / 2,
    top: itemRect.top + scrollTop + (itemRect.height + 26),
  };
  newToolbox.style.left = pos.left + "px";
  newToolbox.style.top = pos.top + "px";

  if (item._imageItem === false) {
    if (item._nodeBackground === false) {
      newToolbox.querySelector(".new-toolbox-bottom").style.justifyContent =
        "space-around";
      newToolbox.querySelector(".shape-size-wrapper").classList.add("none");
      newToolbox.querySelector(".shape-toolbar").classList.add("none");
      newToolbox.querySelector(".background-toolbar").classList.add("none");
    } else {
      newToolbox.querySelector(".new-toolbox-bottom").style.justifyContent =
        "space-between";
      newToolbox.querySelector(".shape-size-wrapper").classList.remove("none");
      newToolbox.querySelector(".shape-toolbar").classList.remove("none");
      newToolbox.querySelector(".background-toolbar").classList.remove("none");
    }
  } else {
    MM.App.hideNewToolbox();
  }
};

MM.Mouse._startDrag = function (e, item) {
  if (e.type == "mousedown") {
    e.preventDefault(); /* no selections allowed. only for mouse; preventing touchstart would prevent Safari from emulating clicks */
    this._port.addEventListener("mousemove", this);
    this._port.addEventListener("mouseup", this);
  } else {
    this._port.addEventListener("touchmove", this);
    this._port.addEventListener("touchend", this);
  }

  this._cursor[0] = e.clientX;
  this._cursor[1] = e.clientY;

  if (item && !item.isRoot()) {
    if (
      (JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
        window.location.pathname.includes("public-share-link") === false &&
        JSON.parse(localStorage.getItem("beforePresent")) === false) ||
      JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
      JSON.parse(localStorage.getItem("mapPermission")) === 3
    ) {
      this._mode = "drag";
      this._item = item;
    }
  } else {
    this._mode = "pan";
    this._port.style.cursor = "move";
  }
};

MM.Mouse._processDrag = function (e) {
  e.preventDefault();
  const dx = e.clientX - this._cursor[0];
  const dy = e.clientY - this._cursor[1];
  this._cursor[0] = e.clientX;
  this._cursor[1] = e.clientY;

  switch (this._mode) {
    case "drag":
      if (!this._ghost) {
        this._port.style.cursor = "move";
        this._buildGhost(dx, dy);
      }
      this._moveGhost(dx, dy);
      break;

    case "pan":
      MM.App.map.moveBy(dx, dy);
      break;
  }
};

MM.Mouse._endDrag = function () {
  this._port.style.cursor = "";
  this._port.removeEventListener("mousemove", this);
  this._port.removeEventListener("mouseup", this);

  if (this._mode == "pan") {
    return;
  }

  if (this._ghost) {
    const ghostPosition = {
      left: this._ghost.offsetLeft,
      top: this._ghost.offsetTop,
    };
    MM.Item.dragDropSetPosition(this._item, ghostPosition);
    MM.Mouse._removeGhost();
  }

  this._item = null;
};

MM.Mouse._removeGhost = function () {
  this._ghost.parentNode.removeChild(this._ghost);
  this._ghost = null;
};

MM.Mouse._buildGhost = function () {
  var content = this._item.getDOM().content;
  this._ghost = content.cloneNode(true);
  this._ghost.classList.add("ghost");
  this._pos[0] = content.offsetLeft;
  this._pos[1] = content.offsetTop;
  content.parentNode.appendChild(this._ghost);
};

MM.Mouse._moveGhost = function (dx, dy) {
  const zoomBalanceValue =
    1 + (1 - parseFloat(window.localStorage.getItem("zoomMap")));
  this._pos[0] += dx * zoomBalanceValue;
  this._pos[1] += dy * zoomBalanceValue;
  this._ghost.style.left = `${this._pos[0]}px`;
  this._ghost.style.top = `${this._pos[1]}px`;
};

MM.Mouse._finishDragDrop = function (state) {
  const target = state.item;
  let action = null;

  switch (state.result) {
    case "append":
      action = new MM.Action.MoveItem(this._item, target);
      break;

    case "sibling":
      const index = target.getParent().getChildren().indexOf(target);
      const targetIndex =
        index +
        (state.direction == "right" || state.direction == "bottom" ? 1 : 0);
      action = new MM.Action.MoveItem(
        this._item,
        target.getParent(),
        targetIndex,
        target.getSide()
      );
      break;

    default:
      return;
  }

  MM.App.action(action);
};

MM.App = {
  isRedoAvailable: false,
  selectedItemToolboxSettings: null,
  keyboard: null,
  current: null,
  editing: false,
  textItemInfoModalSayac: 1,
  itemFormatObj: null,
  formattingIsActive: false,
  itemAction: null,
  addItemAction: null,
  removeItemAction: null,
  formatItemAction: null,
  tempActionArray: [],
  history: [],
  historyIndex: 0,
  portSize: [0, 0],
  map: null,
  bg: null,
  ui: null,
  io: null,
  help: null,
  _port: null,
  _drag: {
    pos: [0, 0],
    item: null,
    ghost: null,
  },
  _fontSize: 75,
  selectedPresentItems: JSON.parse(
    localStorage.getItem("mapPresentDatasStore")
  ),

  action: function (action, type) {
    if (this.historyIndex < this.history.length) {
      /* remove undoed actions */
      this.history.splice(
        this.historyIndex,
        this.history.length - this.historyIndex
      );
    }

    action["actionType"] = type ? type : "";
    this.tempActionArray.push(action);
    this.history.push(action);

    this.historyIndex++;

    if (type && type === "newItem") {
      this.addItemAction = "add";
      this.itemAction = "add";
    }
    if (type && type === "removeItem") {
      this.removeItemAction = "remove";
      this.itemAction = "remove";
    }
    if (type && type === "format-font") {
      this.formatItemAction = "format-font";
      this.itemAction = "format-font";
    }

    action.perform();
    return this;
  },

  setMap: function (map) {
    this.map = map;
    this.map.show(this._port);
    this.history = [];
    this.historyIndex = 0;
    mapIsLoadedDisplayed = true; // set true to this global variable when map is loaded & showed all items
    this.fitMapScreenPageLoadControl(); // map yuklenirken mapin sigip sigmadigi kontrol ediliyor
  },

  select: function (item) {
    if (this.current && this.current != item) {
      this.current.deselect();
    }

    this.current = item;
    this.current.select();
  },

  hideNewToolbox: function () {
    var newToolbox = document.querySelector(".new-toolbox-wrapper");
    if (newToolbox) {
      !newToolbox.classList.contains("none") &&
        newToolbox.classList.add("none");
    }
  },

  adjustFontSize: function (diff) {
    ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
      window.location.pathname.includes("public-share-link") === false) ||
      JSON.parse(localStorage.getItem("mapPermission")) === 2 ||
      JSON.parse(localStorage.getItem("mapPermission")) === 3) &&
      this.map.ensureItemVisibility(this.current);
  },

  scrollZoom: function (container, max_scale, factor) {
    if (!container) {
      return;
    }
    var target = container.children[0] && container;

    var size = { w: target.offsetWidth, h: target.offsetHeight };
    var pos = { x: 0, y: 0 };
    var zoom_target = { x: 0, y: 0 };
    var zoom_point = { x: 0, y: 0 };
    var scale = 0.08;
    const posObj = {
      scale192: { val: 1.92, text: "100%" },
      scale184: { val: 1.84, text: "96%" },
      scale176: { val: 1.76, text: "92%" },
      scale168: { val: 1.68, text: "88%" },
      scale160: { val: 1.6, text: "84%" },
      scale152: { val: 1.52, text: "80%" },
      scale144: { val: 1.44, text: "76%" },
      scale136: { val: 1.36, text: "72%" },
      scale128: { val: 1.28, text: "68%" },
      scale120: { val: 1.2, text: "64%" },
      scale112: { val: 1.12, text: "60%" },
      scale104: { val: 1.04, text: "56%" },
      scale096: { val: 0.96, text: "52%" },
      scale088: { val: 0.88, text: "48%" },
      scale080: { val: 0.8, text: "44%" },
      scale072: { val: 0.72, text: "40%" },
      scale064: { val: 0.64, text: "36%" },
      scale056: { val: 0.56, text: "32%" },
      scale048: { val: 0.48, text: "28%" },
      scale040: { val: 0.4, text: "24%" },
      scale032: { val: 0.32, text: "20%" },
      scale024: { val: 0.24, text: "16%" },
      scale016: { val: 0.16, text: "12%" },
      scale008: { val: 0.08, text: "8%" },
    };

    // target.style.transformOrigin = "0 0";

    var newScale = localStorage.getItem("zoomMap");
    target.style.transform = "scale(" + newScale + "," + newScale + ")";
    // document.querySelector(".a4-grid-lines").style.transform = "scale(" + newScale + "," + newScale + ")";

    target.addEventListener("wheel", scrolled);

    document
      .querySelector(".tip")
      .addEventListener("wheel", (e) => e.preventDefault(), { passive: false });

    function scrolled(e) {
      // if(e.defaultPrevented == true){
      scale = parseFloat(JSON.parse(localStorage.getItem("zoomMap")));
      MM.App.fitMapScreenPageLoadControl(); // sayfata sigiyor mu control

      zoom_point.x = e.pageX - container.offsetLeft;
      zoom_point.y = e.pageY - container.offsetTop;

      MM.App.hideNewToolbox();

      e.preventDefault();

      var delta = e.delta || e.wheelDelta < 0 ? -1 : 1;
      if (delta === undefined) {
        //we are on firefox
        delta = e.detail;
      }

      delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

      // determine the point on where the slide is zoomed in
      zoom_target.x = (zoom_point.x - pos.x) / scale;
      zoom_target.y = (zoom_point.y - pos.y) / scale;

      // apply zoom
      // scale += delta * factor * scale * 0.3;
      // scale = Math.max(0.1, Math.min(max_scale, scale));
      if (delta === 1) {
        // buyuyor
        scale += 0.08;
      } else if (delta === -1) {
        // kuculuyor
        scale -= 0.08;
      }
      scale <= 0.08 && (scale = 0.08);
      scale >= 1.92 && (scale = 1.92);

      // calculate x and y based on zoom
      pos.x = -zoom_target.x * scale + zoom_point.x;
      pos.y = -zoom_target.y * scale + zoom_point.y;

      // Make sure the slide stays in its container area when zooming out
      if (pos.x > 0) pos.x = 0;
      if (pos.x + size.w * scale < size.w) pos.x = -size.w * (scale - 1);
      if (pos.y > 0) pos.y = 0;
      if (pos.y + size.h * scale < size.h) pos.y = -size.h * (scale - 1);

      localStorage.setItem("zoomMap", scale.toFixed(2));
      update();
      // }
    }

    function update() {
      let toFixedScale = parseFloat(scale).toFixed(2);
      let toFixedScaleText =
        "scale" + toFixedScale.split(".")[0] + toFixedScale.split(".")[1];
      document.querySelector(".zoom-ratio").innerHTML =
        posObj[toFixedScaleText].text;
      MM.Map.prototype._setScale(posObj[toFixedScaleText].val);
    }
  },

  buttonZoom: function (container, max_scale, factor) {
    if (!container) {
      return;
    }
    var target = container.children[0] && container;

    var size = { w: target.offsetWidth, h: target.offsetHeight };
    var pos = { x: 0, y: 0 };
    var zoom_target = { x: 0, y: 0 };
    var zoom_point = { x: 0, y: 0 };
    var scale;
    const posObj = {
      scale192: { val: 1.92, text: "100%" },
      scale184: { val: 1.84, text: "96%" },
      scale176: { val: 1.76, text: "92%" },
      scale168: { val: 1.68, text: "88%" },
      scale160: { val: 1.6, text: "84%" },
      scale152: { val: 1.52, text: "80%" },
      scale144: { val: 1.44, text: "76%" },
      scale136: { val: 1.36, text: "72%" },
      scale128: { val: 1.28, text: "68%" },
      scale120: { val: 1.2, text: "64%" },
      scale112: { val: 1.12, text: "60%" },
      scale104: { val: 1.04, text: "56%" },
      scale096: { val: 0.96, text: "52%" },
      scale088: { val: 0.88, text: "48%" },
      scale080: { val: 0.8, text: "44%" },
      scale072: { val: 0.72, text: "40%" },
      scale064: { val: 0.64, text: "36%" },
      scale056: { val: 0.56, text: "32%" },
      scale048: { val: 0.48, text: "28%" },
      scale040: { val: 0.4, text: "24%" },
      scale032: { val: 0.32, text: "20%" },
      scale024: { val: 0.24, text: "16%" },
      scale016: { val: 0.16, text: "12%" },
      scale008: { val: 0.08, text: "8%" },
    };

    // target.style.transformOrigin = "0 0";
    var newScale = localStorage.getItem("zoomMap");
    target.style.transform = "scale(" + newScale + "," + newScale + ")";
    // document.querySelector(".a4-grid-lines").style.transform = "scale(" + newScale + "," + newScale + ")";

    document.querySelector(".zoom-in").addEventListener("click", zooInmClick);
    document.querySelector(".zoom-out").addEventListener("click", zoomOutClick);

    function zooInmClick(e) {
      if (e.defaultPrevented == false) {
        scale = parseFloat(JSON.parse(localStorage.getItem("zoomMap")));
        MM.App.fitMapScreenPageLoadControl(); // sayfata sigiyor mu control

        zoom_point.x = e.pageX - container.offsetLeft;
        zoom_point.y = e.pageY - container.offsetTop;

        MM.App.hideNewToolbox();

        e.preventDefault();

        var delta = e.delta || e.wheelDelta < 0 ? -1 : 1;
        if (delta === undefined) {
          //we are on firefox
          delta = e.detail;
        }

        delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

        // determine the point on where the slide is zoomed in
        zoom_target.x = (zoom_point.x - pos.x) / scale;
        zoom_target.y = (zoom_point.y - pos.y) / scale;

        // apply zoom
        scale += 0.08;
        scale >= 1.92 && (scale = 1.92);

        // calculate x and y based on zoom
        pos.x = -zoom_target.x * scale + zoom_point.x;
        pos.y = -zoom_target.y * scale + zoom_point.y;

        // Make sure the slide stays in its container area when zooming out
        if (pos.x > 0) pos.x = 0;
        if (pos.x + size.w * scale < size.w) pos.x = -size.w * (scale - 1);
        if (pos.y > 0) pos.y = 0;
        if (pos.y + size.h * scale < size.h) pos.y = -size.h * (scale - 1);

        localStorage.setItem("zoomMap", scale.toFixed(2));
        update();
      }
    }

    function zoomOutClick(e) {
      if (e.defaultPrevented == false) {
        scale = parseFloat(JSON.parse(localStorage.getItem("zoomMap")));
        MM.App.fitMapScreenPageLoadControl(); // sayfata sigiyor mu control

        zoom_point.x = e.pageX - container.offsetLeft;
        zoom_point.y = e.pageY - container.offsetTop;

        MM.App.hideNewToolbox();

        e.preventDefault();

        var delta = e.delta || e.wheelDelta < 0 ? 1 : -1;
        if (delta === undefined) {
          //we are on firefox
          delta = -e.detail;
        }

        delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

        // determine the point on where the slide is zoomed in
        zoom_target.x = (zoom_point.x - pos.x) / scale;
        zoom_target.y = (zoom_point.y - pos.y) / scale;

        // apply zoom
        scale -= 0.08;
        scale <= 0.08 && (scale = 0.08);

        // calculate x and y based on zoom
        pos.x = -zoom_target.x * scale + zoom_point.x;
        pos.y = -zoom_target.y * scale + zoom_point.y;

        // Make sure the slide stays in its container area when zooming out
        if (pos.x > 0) pos.x = 0;
        if (pos.x + size.w * scale < size.w) pos.x = -size.w * (scale - 1);
        if (pos.y > 0) pos.y = 0;
        if (pos.y + size.h * scale < size.h) pos.y = -size.h * (scale - 1);

        localStorage.setItem("zoomMap", scale.toFixed(2));
        update();
      }
    }

    function update() {
      let scale = parseFloat(JSON.parse(localStorage.getItem("zoomMap")));
      let toFixedScale = parseFloat(scale).toFixed(2);
      let toFixedScaleText =
        "scale" + toFixedScale.split(".")[0] + toFixedScale.split(".")[1];
      document.querySelector(".zoom-ratio").innerHTML =
        posObj[toFixedScaleText]?.text;
      MM.Map.prototype._setScale(posObj[toFixedScaleText].val);
    }
  },

  fitMapScreenPageLoadControl: function () {
    var leftVal, rightVal, topVal, bottomVal;
    var lefts = [];
    var tops = [];
    var rect, scrollLeft, scrollTop;

    document.querySelectorAll(".item").forEach((item) => {
      // item.classList.remove("current");
      rect = item.getBoundingClientRect();
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      lefts.push(rect.left + scrollLeft);
      tops.push(rect.top + scrollTop);
    });

    leftVal = Math.min.apply(null, lefts);
    rightVal = Math.max.apply(null, lefts);
    topVal = Math.min.apply(null, tops);
    bottomVal = Math.max.apply(null, tops);
    if (
      topVal < 50 ||
      bottomVal >= window.innerHeight - (window.innerHeight / 100) * 9 ||
      leftVal < 50 ||
      rightVal >= window.innerWidth - (window.innerWidth / 100) * 9
    ) {
      // yukardan/asagidan sigmiyor || sagdan/soldan sigamiyor
      document.getElementById("fit-map") &&
        document.getElementById("fit-map").removeAttribute("disabled");
    } else {
      // ekrana sigiyor
      document.getElementById("fit-map") &&
        document.getElementById("fit-map").setAttribute("disabled", true);
    }
  },

  handleMessage: function (message, publisher) {
    switch (message) {
      case "ui-change":
        this._syncPort();
        break;

      case "item-change":
        if (publisher.isRoot() && publisher.getMap() == this.map) {
          document.title =
            (localStorage.getItem("openedMapName") ||
              localStorage.getItem("newMapName")) + " :: Foramind";
        }
        break;
    }
  },

  handleEvent: function (e) {
    switch (e.type) {
      case "resize":
        this._syncPort();
        break;

      case "beforeunload":
        e.preventDefault();
        return "";
        break;
    }
  },

  init: function () {
    this._port = document.querySelector("#port");
    this.ui = new MM.UI();
    this.io = new MM.UI.IO();
    ((JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
      window.location.pathname.includes("public-share-link") === false) ||
      (JSON.parse(localStorage.getItem("mapPermission")) === 2 &&
        window.location.pathname.includes("public-share-link") === false) ||
      (JSON.parse(localStorage.getItem("mapPermission")) === 3 &&
        window.location.pathname.includes("public-share-link") === false)) &&
      (this.help = new MM.UI.Help());

    MM.Tip.init();
    MM.Keyboard.init();
    MM.Menu.init(this._port);
    MM.Mouse.init(this._port);
    MM.Clipboard.init();

    window.addEventListener("resize", this);

    this._syncPort();

    // MM listener
    var listener = (function () {
      // document paste event
      document.addEventListener("paste", function (event) {
        if (MM.App.current) {
          // if focused text area > return
          if (!MM.App.current._dom.text.isContentEditable) {
            MM.Clipboard.getImage(event);
          }
        }
      });
    })();

    return this;
  },

  _syncPort: function () {
    this.portSize = [
      window.innerWidth - this.ui.getWidth(),
      window.innerHeight,
    ];
    this._port.style.width = this.portSize[0] + "px";
    this._port.style.height = this.portSize[1] + "px";
    if (this.map) {
      this.map.ensureItemVisibility(this.current);
    }
  },
};

export default MM;
