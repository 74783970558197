import React, { Component } from "react";
import Resources from "../libraries/resources";

import Header from "../components/header";
import SubHeader from "../components/sub-header";

import Utils from "../libraries/utils";

import DataService from "../services/api/data-service";

import DefaultImage from "../styles/img/foramind-video.png";

import HelpPageService from "../services/api/help-page";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: "video", data: [], titles: [] };
  }

  componentDidMount() {
    this.getHelpPageList();
  }

  getHelpPageList = () => {
    var recordSize = 1000;
    HelpPageService.getHelpPageList(
      recordSize,
      this.fillHelpPagelist,
      this.state.titles,
      this
    );
  };

  fillHelpPagelist() {
    var videoInfoModel = JSON.parse(this.response).videoInfoModel;

    if (videoInfoModel) {
      this.scope.setState({
        
        data: JSON.parse(JSON.stringify(videoInfoModel)),
      });
    }
  }

  removeTitle = (i) => {
    const newTitles = [...this.state.titles];
    newTitles.splice(i, 1);
    this.setState({ titles: newTitles }, (titles) => this.getHelpPageList());
    setTimeout(() => {
      var HeaderHeight =
        "calc(100% - " +
        document.querySelector(".filter-line").offsetHeight +
        "px)";
      document.getElementById("video-container").style.maxHeight = HeaderHeight;
    }, 200);
  };

  inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (
        this.state.titles.find(
          (title) => title.toLowerCase() === val.toLowerCase()
        )
      ) {
        return;
      }
      this.setState({ titles: [...this.state.titles, val] }, (titles) =>
        this.getHelpPageList()
      );
      this.titleInput.value = null;
      setTimeout(() => {
        var HeaderHeight =
          "calc(100% - " +
          document.querySelector(".filter-line").offsetHeight +
          "px)";
        document.getElementById("video-container").style.maxHeight = HeaderHeight;
      }, 200);
    } else if (e.key === "Backspace" && !val && this.state.titles.length > 0) {
      this.removeTitle(this.state.titles.length - 1);
    }
  };

  render() {
    const { titles } = this.state;
    return (
      <React.Fragment>
        <Header />

        <div className="mindmap-table wide">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="template-panel subscription-history">
                  <div className="title-wrapper">
                    <div className="d-flex justify-content-between align-items-center px-3 w-100">
                      <div>
                        <SubHeader
                          title={Resources.getValue("helpMenuMsgTxt")}
                          iconName="icon-help-outline"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn tabs-buttons ml-1"
                    onClick={() => this.setState({ activeTab: "video" })}
                  >
                    Video
                  </button>
                  <button
                    className="btn tabs-buttons ml-2"
                    onClick={() => this.setState({ activeTab: "eposta" })}
                  >
                    E-Posta
                  </button>
                  <hr className="hr-border" />
                  <div>
                    <div className="col-md-12 px-3 pb-3">
                      <div className="table-list layout-option-content show">
                        <div className="sub-list show">
                          {this.state.activeTab === "video" && (
                            <>
                              <div className="filter-line row">
                                <div className="title-type title-type col-lg-8 col-md-6 col-sm-4 col-xs-12">
                                  <b>{Resources.getValue("videoHelpMsgTxt")}</b>
                                </div>
                                <div className="input-title col-lg-4 col-md-6 col-sm-8 col-xs-12 ">
                                  <ul className="input-title_titles">
                                    {titles.map((title, i) => (
                                      <li key={title}>
                                        {title}
                                        <button
                                          type="button"
                                          onClick={() => {
                                            this.removeTitle(i);
                                          }}
                                        >
                                          +
                                        </button>
                                      </li>
                                    ))}
                                    <li className="input-titles_titles_input">
                                    <input
                                      type="text"
                                      onKeyDown={this.inputKeyDown}
                                      ref={(c) => {
                                        this.titleInput = c;
                                      }}
                                      placeholder={Resources.getValue(
                                        "categoryFilterMsgTxt"
                                      )}
                                    />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="video-container" id="video-container">
                                <div className="row ">
                                  {this.state.data.map((data) => {
                                    return (
                                      <div
                                        className=" outline-sbox col-lg-4 col-md-6 col-sm-12"
                                        key={data.id}
                                      >
                                        <div className="sbox">
                                          <a
                                            href={data.video}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={
                                                data.image
                                                  ? data.image
                                                  : DefaultImage
                                              }
                                              alt="Foramind, Zihin Haritası, Mind Map"
                                              height={200}
                                            />
                                          </a>
                                          <div className="card-body">
                                            <a
                                              href={data.video}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <h6 className="card-title">
                                                {data.title}
                                              </h6>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          )}

                          {this.state.activeTab === "eposta" && (
                            <>
                              <div>
                                <b>{Resources.getValue("emailHelpMsgTxt")}</b>
                              </div>
                              <div>
                                <a
                                  href="mailto:info@foramind.com"
                                  style={{ color: "#33bdd8" }}
                                >
                                  info@foramind.com
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Help;
