import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";
import SuccessModal from "../../components/modals/success-modal";

var LandingPageService = {
  getPrice: function (callback, _this, langId) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("getProductListApiURL") + "?langId=" + langId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        }
      }
    };
  },
  getChatGptPrice: function (callback, _this, langId) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("getAllAiPackage") + "?currency=" + langId,
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        }
      }
    };
  },

  /*getCountOfUsersAndTeachersAndMaps: function (callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("getCountOfUsersAndTeachersAndMapsApiURL"),
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          LandingPageService.openWarningModal();
        }
      }
    };
  }, */

  getBlogList: function (callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("getBlogListApiUrl"),
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (
            responseData.errors &&
            responseData.errors[0] !== "Blog Not Found"
          ) {
            LandingPageService.openReviewWarningModal();
          }
        }
      }
    };
  },
  getBlogItem: function (callback,slug, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("getBlogItemApiUrl") +"/"+ slug,
      keepScope: _this,
    });
    xhr.onerror = function () {
      Utils.loadingScreen("hide");
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 400) {
          const responseData = JSON.parse(xhr.response);
          if (
            responseData.errors &&
            responseData.errors[0] !== "Blog Not Found"
          ) {
            LandingPageService.openReviewWarningModal();
          }
        } else if (xhr.status == 404) {
          // yanlis slug girilmistir, boyle bir blog item yoksa;
          window.location.href = Resources.getValue("appBaseEnvURL") + '/';
        }
      }
    };
  },
  getReviewList: function (callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("getReviewListApiUrl"),
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 400) {
          LandingPageService.openReviewWarningModal();
        }
      }
    };
  },

  sendReview: function (data) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("createReviewApiUrl"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LandingPageService.openReviewErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            LandingPageService.openReviewSuccessModal();
          }
        } else if (xhr.status == 400) {
          LandingPageService.openReviewWarningModal();
        } else {
          LandingPageService.openReviewErrorModal();
        }
      }
    };
  },

  sendCorporationSubscriptionApply: function (data) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("createCorporateSubscriptionApiUrl"),
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      LandingPageService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.isSuccess) {
            LandingPageService.openSubscriptionSuccessModal();
          }
        } else if (xhr.status == 400) {
          LandingPageService.openWarningModal();
        } else {
          LandingPageService.openErrorModal();
        }
      }
    };
  },

  openSubscriptionSuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + Resources.getValue("createSubscriptionSuccessmodalMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: ""
        },
      ],
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  },

  openReviewWarningModal: function () {
    WarningModal({
      title: Resources.getValue("warningMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openReviewErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("reviewSendErrorMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue('appBaseEnvURL') === '/foramind' ? Resources.getValue('appBaseEnvURL') + '/' : '/',
        },
      ],
    });
  },

  openReviewSuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + Resources.getValue("reviewSendSuccessMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue('appBaseEnvURL') === '/foramind' ? Resources.getValue('appBaseEnvURL') + '/' : '/',
        },
      ],
    });
  },
};

export default LandingPageService;
