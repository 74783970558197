import React, { Component } from "react";
import Resources from "../libraries/resources";
import Select from "react-select";
import Header from "../components/header";
import ProfileService from "../services/api/profile";
import Utils from "../libraries/utils";
import LandingPageService from "../services/api/landing-page";
import ChatGptPaymentService from "../services/api/chat-gpt-payment";
import LoginService from "../services/api/login";
import DistantSalesContractAgreements from "../components/distant-sales-contract"; // mesafeli satis sozlesmesi
// import PreliminaryInformationText from "../components/preliminary-information-text"; // on bilgilendirme metni
// import EcommerceComplianceProcessText from "../components/ecommerce-compliance-process"; // e-ticaret uyum sureci
import IMask from "imask";


import iyzicoLogo from "../styles/img/iyzico_logo_band_colored.png";

// Modals
import WarningModal from "../components/modals/warning-modal";

class ChatGptPayment extends Component {
  constructor() {
    super();
    this.state = {
      userId: "",
      userName: "",
      userSurname: "",
      conversationId: "",
      paymentStatus: "",
      phone: "",
      email: "",
      value: JSON.parse(localStorage.getItem("x8s88")),
      userIp: "",
      userCountryCode: "",
      products: [],
      selectedPackageName: "",
      selectedPackageId: "",
      selectedPackageParam: null,
    };
    this.BuyAgreementModal = this.BuyAgreementModal.bind(this);
    

    localStorage.setItem("retrieveUrl", window.location.pathname);
  }

  componentDidMount() {
    Utils.formValidation();
    this.inputsCharControl();

    document.title = "Foramind | " + Resources.getValue("paymentPageMsgGptTxt");

    var _this = this;
    window.payment = function () {
      _this.refreshToken();
    };
    this.listener();
    // this.phoneNumberMask();
  }

  inputsCharControl() {
    // number input char control (8 for backspace && 48-57 for 0-9 numbers)
    document.querySelectorAll(".only-number-input").forEach((inpt) => {
      inpt.addEventListener("keypress", function (evt) {
        if ((evt.which != 8 && evt.which < 48) || evt.which > 57) {
          evt.preventDefault();
        }
      });
    });
    // text input's char control (for only letters) -- city & state
    document.querySelectorAll(".only-letter-input").forEach((inpt) => {
      inpt.addEventListener("keypress", function (evt) {
        var regex = new RegExp("^[a-zA-Z wığüşöçĞÜŞÖÇİ]+$");
        var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
          evt.preventDefault();
          return false;
        }
      });
    });
    // text input's char control (for only letters) -- country
    document
      .querySelector(".country-select input")
      .addEventListener("keypress", function (evt) {
        var regex = new RegExp("^[a-zA-Z wığüşöçĞÜŞÖÇİ]+$");
        var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
          evt.preventDefault();
          return false;
        }
      });

    // number input char control for phone number (8 for backspace && 48-57 for 0-9 numbers)
    document
      .querySelector("#phone")
      .addEventListener("keypress", function (evt) {
        if ((evt.which != 8 && evt.which < 48) || evt.which > 57) {
          evt.preventDefault();
        }
      });
  }

  phoneNumberMask() {
    IMask(document.getElementById("phone"), {
      mask: "{+9\\0}(000)-000-00-00",
    });
  }

  bilingAdress() {
    var bilingAdress = {
      line1: document.querySelector("#line1").value,
      line2: document.querySelector("#line2").value,
      city: document.querySelector("#city").value,
      state: document.querySelector("#state").value,
      // zip: document.querySelector("#zip").value,
      zip: "11111",
      // total: Resources.getValue("totalValueMsgTxt") + document.querySelector(".activate .price .many-value").getAttribute("data-price") + "₺",
      total:
        Resources.getValue("totalValueMsgTxt") +
          document.querySelector(".activate .price .many-value").innerHTML +
          this.state.userCountryCode ===
        "TR"
          ? "₺"
          : "$",
      // identityNumber: document.querySelector('#tcknNumber').value,
      identityNumber: "11111111111",
      fullname: this.state.userName + " " + this.state.userSurname,
      phone: document.querySelector("#phone").value,
      email: this.state.email,
      active: document.querySelectorAll(".activate")[0].classList[1],
    };
    localStorage.setItem("bilingAdress", JSON.stringify(bilingAdress));
  }

  failedPayment() {
    var failedPayment = Utils.getParameterByName("failed");
    var bilingAdress = JSON.parse(localStorage.getItem("bilingAdress"));

    if (failedPayment) {
      document.querySelector("#line1").value = bilingAdress.line1;
      document.querySelector("#line2").value = bilingAdress.line2;
      document.querySelector("#city").value = bilingAdress.city;
      document.querySelector("#state").value = bilingAdress.state;
      // document.querySelector("#zip").value = bilingAdress.zip;
      // document.querySelector('#tcknNumber').value = bilingAdress.identityNumber;
      document.querySelector("#fullName").value = bilingAdress.fullname;
      document.querySelector("#phone").value = bilingAdress.phone;
      document.querySelector("#email").value = bilingAdress.email;
      if (bilingAdress.active == "month-card") {
        document.querySelector(".month-card").classList.add("activate");
        document.querySelector(".year-card").classList.add("deactivate");
        document.querySelector(".yellow-button").disabled = false;
      } else if (bilingAdress.active == "year-card") {
        document.querySelector(".month-card").classList.add("deactivate");
        document.querySelector(".year-card").classList.add("activate");
        document.querySelector(".yellow-button").disabled = false;
      }
    }
  }

  getCountryIP() {
    var callback = this.fillCountryIp;
    ChatGptPaymentService.userIpAndCountryDetail(callback, this);
  }

  fillCountryIp() {
    var _this = this;
    var userCountryInfo = JSON.parse(this.response);
    _this.scope.setState({
      userIp: userCountryInfo.ip,
      userCountryCode: userCountryInfo.country_code,
    });
  }

  getProfile() {
    var callback = this.fillProfile;
    ProfileService.getProfile(callback, this);
  }

  fillProfile() {
    var _this = this;
    var userProfil = JSON.parse(this.response);
    document.querySelector("#fullName").value =
      userProfil.user.firstName + " " + userProfil.user.lastName;
    // document.querySelector('#phone').value = userProfil.user.phoneNumber;
    document.querySelector("#email").value = userProfil.user.email;

    _this.scope.setState({
      userId: userProfil.user.id,
      userName: userProfil.user.firstName,
      userSurname: userProfil.user.lastName,
      phone: userProfil.user.phone,
      email: userProfil.user.email,
    });
  }

  listener() {
    this.getCountryIP();
    this.getProfile();
    this.selectPacketPricing();
    this.failedPayment();
    this.getProductUser();
  }

  iyzicoCloseClick() {
    var _this = this;
    document.addEventListener("click", function (e) {
      // iyzico odeme ekrani kapatirken
      if (
        (e.path[1].nodeName !== "svg" &&
          e.path[1].className.includes("-Close") === true) ||
        e.path[1].nodeName === "svg"
      ) {
        var conversationId = _this.state.conversationId;
        var paymentStatus = 2;
        ChatGptPaymentService.putPaymentStatus(conversationId, paymentStatus);
        Utils.loadingScreen("hide");
        delete window.iyziInit;
      }
    });
  }

  refreshToken() {
    if (this.tokenParse() < 1) {
      LoginService.resfreshToken(this.submitForm);
    } else {
      this.collectFormData();
    }
  }

  tokenParse() {
    var token = localStorage.getItem("token");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    var tokenDate = JSON.parse(jsonPayload).exp;
    var date = new Date(tokenDate * 1000);
    var hours = date.getHours();
    var today = new Date();
    var todayHours = today.getHours();
    var endHours = Math.abs(todayHours - hours);
    return endHours;
  }

  submitForm(payment) {
    Utils.loadingScreen("show");
    var _this = this;
    ChatGptPaymentService.postPayment(
      JSON.stringify(payment),
      this.fillPayment,
      _this
    );
    this.bilingAdress();
    this.iyzicoCloseClick();
  }

  fillPayment() {
    var _this = this;
    var iyzicoPayment = JSON.parse(this.response);
    console.log(iyzicoPayment);
    var iyzicoToken = iyzicoPayment.checkoutFormContent;
    var tokenParse = iyzicoToken
      .replace('<script type="text/javascript">', "")
      .replace("</script>", "");
    window.eval(tokenParse);
    _this.scope.setState({
      conversationId: iyzicoPayment.conversationId,
    });
  }


  collectFormData() {
    var _this = this;
    var payment = {
      checkoutAiFormRequest: {
        buyer: {
          id: String(_this.state.userId),
          name: _this.state.userName,
          surname: _this.state.userSurname,
          // identityNumber: document.querySelector('#tcknNumber').value,
          identityNumber: "11111111111",
          email: document.querySelector("#email").value,
          gsmNumber: document.querySelector("#phone").value,
          registrationDate: "",
          lastLoginDate: "",
          registrationAddress:
            document.querySelector("#line1").value +
            document.querySelector("#line2").value,
          city: document.querySelector("#city").value,
          country: document.querySelector("#country").innerText,
          // zipCode: document.querySelector('#zip').value,
          zipCode: "111111",
          ip: "",
        },
        shippingAddress: {
          Address:
            document.querySelector("#line1").value +
            document.querySelector("#line2").value,
          // zipCode: document.querySelector('#zip').value,
          zipCode: "111111",
          contactName: _this.state.userName + _this.state.userSurname,
          city: document.querySelector("#city").value,
          country: document.querySelector("#country").innerText,
        },
        billingAddress: {
          Address:
            document.querySelector("#line1").value +
            document.querySelector("#line2").value,
          // zipCode: document.querySelector('#zip').value,
          zipCode: "111111",
          contactName: document.querySelector("#fullName").value,
          city: document.querySelector("#city").value,
          country: document.querySelector("#country").innerText,
        },
        basketItem: {
          id: (this.state.selectedPackageId).toString(),
          // price: document.querySelector(".activate .price .many-value").getAttribute("data-price") + ".0",
          price: "",
          name: this.state.selectedPackageName,
          category1: "subscription",
          category2: "",
          itemType: "",
          subMerchantKey: "",
          subMerchantPrice: "",
        },
        locale: localStorage.getItem("siteLanguage"),
        // price: document.querySelector(".activate .price .many-value").getAttribute("data-price"),
        price: "",
        // paidPrice: document.querySelector(".activate .price .many-value").getAttribute("data-price") + ".0",
        paidPrice: "",
        currency: "",
        conversationId: "",
        userIpAddress: _this.state.userIp,
        userCountryCode: _this.state.userCountryCode,
      },
        mapAiPackageId: (this.state.selectedPackageId).toString()
    };
    this.submitForm(payment);
  }


  selectPacketPricing(index) {
    const product = this.state.products[index];
    this.setState((prevState) => ({
      selectedPackageParam: prevState.selectedPackageParam === index ? null : index,
      selectedPackageName: product?.name,
      selectedPackageId: product?.id
    }));

   console.log(product);
  }

  getProductUser() {
    var langId = Resources.siteLanguage;
    var _this = this;
    if (localStorage.getItem("countryInfo") === "TR") {
      LandingPageService.getChatGptPrice(this.fillProduct, this, "TRY");
      // turkiyede
    } else {
      LandingPageService.getChatGptPrice(this.fillProduct, this, "USD");
      // turkiyede degil
    }
  }

  fillProduct() {
    var _this = this;
    var product = JSON.parse(this.response).data;
    _this.scope.setState({
      products: product
    });
  }

  BuyAgreementModal() {
    WarningModal({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("distantSalesContractTitleMsgTxt"),
      message:
        '<div class="membership-agreement">' +
        DistantSalesContractAgreements() +
        "</div>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button button confirm-button",
          href: "",
        },
      ],
    });
  }

  render() {
    var countryOptions = [
      { label: Resources.getValue("arnavutlukMsgTxt"), value: "AL" },
      { label: Resources.getValue("ermenistanMsgTxt"), value: "AM" },
      { label: Resources.getValue("avustralyaMsgTxt"), value: "AU" },
      { label: Resources.getValue("avusturyaMsgTxt"), value: "AT" },
      { label: Resources.getValue("azerbaycanMsgTxt"), value: "AZ" },
      { label: Resources.getValue("bahreynMsgTxt"), value: "BH" },
      { label: Resources.getValue("belcikaMsgTxt"), value: "BE" },
      { label: Resources.getValue("bosnaHersekMsgTxt"), value: "BA" },
      { label: Resources.getValue("bulgaristanMsgTxt"), value: "BG" },
      { label: Resources.getValue("kanadaMsgTxt"), value: "CA" },
      { label: Resources.getValue("cinMsgTxt"), value: "CN" },
      { label: Resources.getValue("hirvatistanMsgTxt"), value: "HR" },
      { label: Resources.getValue("kibrisMsgTxt"), value: "CY" },
      { label: Resources.getValue("cekCumhuriyetiMsgTxt"), value: "CZ" },
      { label: Resources.getValue("danimarkaMsgTxt"), value: "DK" },
      { label: Resources.getValue("misirMsgTxt"), value: "EG" },
      { label: Resources.getValue("estonyaMsgTxt"), value: "EE" },
      { label: Resources.getValue("finlandiyaMsgTxt"), value: "FI" },
      { label: Resources.getValue("fransaMsgTxt"), value: "FR" },
      { label: Resources.getValue("gurcistanMsgTxt"), value: "GE" },
      { label: Resources.getValue("almanyaMsgTxt"), value: "DE" },
      { label: Resources.getValue("yunanistanMsgTxt"), value: "GR" },
      { label: Resources.getValue("hongKongMsgTxt"), value: "HK" },
      { label: Resources.getValue("macaristanMsgTxt"), value: "HU" },
      { label: Resources.getValue("hindistanMsgTxt"), value: "IN" },
      { label: Resources.getValue("iranMsgTxt"), value: "IR" },
      { label: Resources.getValue("irakMsgTxt"), value: "IQ" },
      { label: Resources.getValue("irlandaMsgTxt"), value: "IE" },
      { label: Resources.getValue("italyaMsgTxt"), value: "IT" },
      { label: Resources.getValue("japonyaMsgTxt"), value: "JP" },
      { label: Resources.getValue("kazakistanMsgTxt"), value: "KZ" },
      { label: Resources.getValue("koreMsgTxt"), value: "KR" },
      { label: Resources.getValue("kuveytMsgTxt"), value: "KW" },
      { label: Resources.getValue("kirgizistanMsgTxt"), value: "KG" },
      { label: Resources.getValue("letonyaMsgTxt"), value: "LV" },
      { label: Resources.getValue("makedonyaMsgTxt"), value: "MK" },
      { label: Resources.getValue("maltaMsgTxt"), value: "MT" },
      { label: Resources.getValue("hollandaMsgTxt"), value: "NL" },
      { label: Resources.getValue("yeniZelandaMsgTxt"), value: "NZ" },
      { label: Resources.getValue("norvecMsgTxt"), value: "NO" },
      { label: Resources.getValue("polonyaMsgTxt"), value: "PL" },
      { label: Resources.getValue("portekizMsgTxt"), value: "PT" },
      { label: Resources.getValue("katarMsgTxt"), value: "QA" },
      { label: Resources.getValue("romanyaMsgTxt"), value: "RO" },
      { label: Resources.getValue("rusyaMsgTxt"), value: "RU" },
      { label: Resources.getValue("arabistanMsgTxt"), value: "SA" },
      { label: Resources.getValue("sirbistanKaradagMsgTxt"), value: "CS" },
      { label: Resources.getValue("ispanyaMsgTxt"), value: "ES" },
      { label: Resources.getValue("isvecMsgTxt"), value: "SE" },
      { label: Resources.getValue("isvicreMsgTxt"), value: "CH" },
      { label: Resources.getValue("tacikistanMsgTxt"), value: "TJ" },
      { label: Resources.getValue("taylandMsgTxt"), value: "TH" },
      { label: Resources.getValue("turkiyeMsgTxt"), value: "TR" },
      { label: Resources.getValue("turkmenistanMsgTxt"), value: "TM" },
      { label: Resources.getValue("ukraynaMsgTxt"), value: "UA" },
      { label: Resources.getValue("birlesikArapMsgTxt"), value: "AE" },
      { label: Resources.getValue("birlesikKrallikMsgTxt"), value: "GB" },
      { label: Resources.getValue("abdMsgTxt"), value: "US" },
      { label: Resources.getValue("ozbekistanMsgTxt"), value: "UZ" },
    ];

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: "black",
        padding: 0,
      }),
      menu: (provided, state) => ({
        ...provided,
        color: " #f2f2f2",
        backgroundColor: "#f2f2f2",
        padding: 0,
        width: "90%",
        borderRadius: 10,
      }),
      control: (styles) => ({
        ...styles,
        color: " #f2f2f2",
        background: "#f2f2f2",
        width: "100%",
        height: 40,
        fontSize: 14,
        marginLeft: 0,
        borderRadius: 130,
        top: "40%",
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        return { ...provided, opacity, transition };
      },
    };

    return (
      <React.Fragment>
        <Header />
        <div className="payment-page mindmap-table wide">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="buy-form">
                  <div className="buy-header">
                    <i className="icon-basket-icon"></i>
                    {Resources.getValue("chatGptPagePricingMsgTxt")}
                  </div>
                  <form>
                    <div className="form-wrap-top row">
                      <div className="form-wrap col-md-6">
                        <div className="row user-info">
                          <div className="col-md-12">
                            <div className="many-show-text">
                              {Resources.getValue("personalInformationMsgTxt")}:
                            </div>
                            {/* <div className="row ">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="tcknNumber"
                                    name="tcknNumberMsgTxt"
                                    className="validate-required validate-tc-no only-number-input"
                                    placeholder={Resources.getValue("tcknNumMsgTxt")}
                                    maxLength="11"
                                  />
                                </div>
                              </div>
                            </div> */}
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    className="validate-required color-gray"
                                    readOnly="readOnly"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row ">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    placeholder={Resources.getValue(
                                      "phonenumberMsgTxt"
                                    )}
                                    className="validate-required"
                                    id="phone"
                                    name="phone"
                                    // data-minlength="18"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="validate-required color-gray"
                                    readOnly="readOnly"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div className="buy-checkbox">
                                <label className="register-checkbox container-checkbox buy-label">
                                  <a
                                    className="distant-sales-contract-color"
                                    onClick={this.BuyAgreementModal}
                                  >
                                    {Resources.getValue(
                                      "distantSalesContractMsgTxt"
                                    )}
                                  </a>{" "}
                                  {Resources.getValue("iAgreeMsgTxt")}
                                  <input
                                    type="checkbox"
                                    className="validate-required"
                                    id="checkbox"
                                    name="checkbox"
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <p style={{fontSize: "10px"}}>{Resources.getValue("chatGptAgreementTxt")}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <div className="many-show-text">
                              {Resources.getValue("bilingAdressMsgTXT")}:
                            </div>
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="line1"
                                    name="line1"
                                    className="validate-required"
                                    placeholder={Resources.getValue(
                                      "line1MsgTxt"
                                    )}
                                    maxLength="60"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="line2"
                                    name="line2"
                                    className="validate-required"
                                    placeholder={Resources.getValue(
                                      "line2MsgTxt"
                                    )}
                                    maxLength="60"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row ">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    className="validate-required only-letter-input"
                                    placeholder={Resources.getValue(
                                      "cityMsgTxt"
                                    )}
                                    maxLength="50"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="state"
                                    name="state"
                                    className="validate-required only-letter-input"
                                    placeholder={Resources.getValue(
                                      "state2MsgTxt"
                                    )}
                                    maxLength="50"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row ">
                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="zip"
                                    name="zip"
                                    className="validate-required only-number-input"
                                    placeholder={Resources.getValue("zipMsgTXT")}
                                    maxLength="10"
                                  />
                                </div>
                              </div> */}

                              <div className="col-md-6">
                                <div className="form-group">
                                  <Select
                                    id="country"
                                    // ref={input => this.languageSelect = input}
                                    styles={customStyles}
                                    className="fa-select select country-select validate-react-select"
                                    options={countryOptions}
                                    inputProps={{ autoComplete: "off" }}
                                    onChange={function (e) {
                                      if (e) {
                                        var event = new Event("change");
                                        document
                                          .querySelector(
                                            ".country-select input"
                                          )
                                          .dispatchEvent(event);
                                      }
                                    }}
                                    // value={this.state.countrySelectVal}
                                    placeholder={Resources.getValue(
                                      "countryMsgTXT"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="style1 d-none" />
                      </div>
                        {/** payment*/}
                      <div className="payment-cards-2 col-md-6 gpt-payment" id="pricing">
                        <div className="row">
                          <div className="col-md-12">
                          <div className="scrollable">
                          <ul>
                          <div className="container">
                              <div className="row">
                                      {this.state?.products?.map((item, index) => {
                                        return (
                                          <div style={{marginTop: "10px" }} className="col-md-6" key={item.name}>
                                          <li onClick={() => this.selectPacketPricing(index)}>
                                          <div className={`card year-card ${
                                            this.state.selectedPackageParam === index
                                              ? " activate"
                                              : ""}`}> 
                                          <div className="card-body">
                                          <div className="title-wrap">
                                            <div className="card-title">
                                            {item.numberOfMaps}
                                            <span> </span>
                                            <span> 
                                            {Resources.getValue(
                                             "chatGptNumberOfMaps"
                                           )}
                                            </span>
                                            </div>
                                          </div>
                                          <div className="price">
                                            <div>
                                            <span>{item.price}</span>
                                              {localStorage.getItem("countryInfo") !==
                                                "TR" && (
                                                <span className="currency">$</span>
                                              )}
                                              <span className="many-value"></span>
                                              {localStorage.getItem("countryInfo") ===
                                                "TR" && (
                                                <span className="currency">₺</span>
                                              )}
                                            </div>
                                          </div>
                                          <div className="title-wrap item-text">
                                                <p>{item.name}</p>
                                          </div>
                                        </div>
                                          </div>
                                          </li>
                                          </div>
                                        )
                                        })}
                              </div>
                          </div>
                          </ul>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* buy button */}
                    <div className="row w-100">
                      <div className="col-md-6 none">
                        <div className="form-group buy-button">
                          <span></span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="buy-button text-right">
                          <button
                            className="yellow-button button submit-form-button"
                            type="button"
                            title={Resources.getValue("buyCapitalLetterMsgTxt")}
                            data-submit-method="payment"
                            disabled={
                              this.state.selectedPackageParam || this.state.selectedPackageParam == 0
                                ? false
                                : true}
                          >
                            {Resources.getValue("buyCapitalLetterMsgTxt")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="buy-footer text-center">
                    <hr />
                    <img src={iyzicoLogo} alt="Iyzico" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChatGptPayment;
