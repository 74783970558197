import React, { Component } from "react";
import Utils from "../libraries/utils";
import ChangePasswordService from "../services/api/change-password";
import Resources from "../libraries/resources";
import Header from "../components/header";

class ChangePassword extends Component {

  componentDidMount() {
    document.title = "Foramind | " + Resources.getValue("changepasswordMsgTxt");
    Utils.formValidation();

    window.changePassword = function () {
      var changePassword = {
        currentPassword: this.document.querySelector('#currentPassword').value,
        newPassword: this.document.querySelector('#newPassword').value,
      };
      ChangePasswordService(JSON.stringify(changePassword));
    };
  };


  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="mindmap-table wide">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2">
                <div className="change-password-form">
                  <form >
                    <div className="form-wrapper ">
                      <div className="row ">
                        <div className="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 offset-2 offset-sm-2 offset-md-3 offset-lg-3 offset-xl-3 mb-4">
                          <div className="form-group">
                            <h4>{Resources.getValue("changepasswordMsgTxt")}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-0 offset-sm-0 offset-md-3 offset-lg-3 offset-xl-3">
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group ">
                                <input
                                  type="password"
                                  id="currentPassword"
                                  name="currentPassword"
                                  className="validate-required"
                                  placeholder={Resources.getValue("currentpasswordMsgTxt")}
                                  maxLength="50"
                                  data-old-password
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  type="password"
                                  id="newPassword"
                                  name="newPassword"
                                  className="validate-required validate-repeat-password"
                                  placeholder={Resources.getValue("newpasswordMsgTxt")}
                                  maxLength="50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <input
                                  type="password"
                                  id="confirmpassword"
                                  name="confirmpassword"
                                  className="validate-required validate-password"
                                  placeholder={Resources.getValue("confirmpasswordMsgTxt")}
                                  maxLength="20"
                                  title={Resources.getValue("confirmpasswordMsgTxt")}
                                  data-target-password
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xl-12 offset-2 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
                              <div className="form-group">
                                <button
                                  className="yellow-button button submit-form-button float-right"
                                  type="button"
                                  name="button"
                                  title={Resources.getValue("saveMsgTxt")}
                                  data-submit-method="changePassword"
                                >
                                  {Resources.getValue("saveMsgTxt")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}
export default ChangePassword;
