import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import PaymentModel from "../../model/payment-model";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";

var ChatGptPaymentService = {
  postPayment: function (data, callback, _this) {
    //for set Ai payment true
    localStorage.setItem("918171", true)
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("postAiPaymentApiURL"),
      keepScope: _this,
      data: data,
    });
    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      ChatGptPaymentService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          var responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
            PaymentModel.ss7ss8w9erp = responseData.token; // Tokeni donen deger
            PaymentModel.f7r8e9w55s = responseData.conversationId; // convarsation id
            localStorage.setItem("ps5e6r6rq7", JSON.stringify(PaymentModel)); // Information
          }
        } else if (xhr.status == 400) {
            ChatGptPaymentService.requestRepeater.methods.repeater(
                ChatGptPaymentService.postPayment,
            [data, callback, _this],
          );
          ChatGptPaymentService.openRepeaterWarningModal();
        } else {
          ChatGptPaymentService.requestRepeater.methods.repeater(
            ChatGptPaymentService.postPayment,
            [data, callback, _this],
            3
          );
          ChatGptPaymentService.openRepeaterErrorModal();
        }
      }
    };
  },

  putPaymentStatus: function (customerOrderId, paymentStatus) {
    const xhr = new DataService.createXHR({
      type: "PUT",
      token: "Bearer",
      url:
        Resources.getValue("paymentHistoryApiURL") +
        "?customerOrderId=" +
        customerOrderId +
        "&paymentStatus=" +
        paymentStatus,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      ChatGptPaymentService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          var responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            PaymentModel.x4r6eeg99y = paymentStatus; // payment status
            PaymentModel.f7r8e9w55s = customerOrderId; // convarsation id
            localStorage.setItem("ps5e6r6rq7", JSON.stringify(PaymentModel)); //payment Information
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          ChatGptPaymentService.requestRepeater.methods.repeater(
            ChatGptPaymentService.putPaymentStatus,
            [customerOrderId, paymentStatus],
            3
          );
          ChatGptPaymentService.openRepeaterWarningModal();
        } else if (xhr.status == 500) {
          ChatGptPaymentService.requestRepeater.methods.repeater(
            ChatGptPaymentService.putPaymentStatus,
            [customerOrderId, paymentStatus],
            3
          );
          ChatGptPaymentService.openRepeaterErrorModal();
        }
      }
    };
  },

  postCheckPayment: function (data, callback, _this) {
    const xhr = new DataService.createXHR({
      type: "POST",
      token: "Bearer",
      url: Resources.getValue("checkAiPaymentApiURL"),
      keepScope: _this,
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      ChatGptPaymentService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          var responseData = JSON.parse(xhr.response);

          if (responseData) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 400) {
          var responseData = JSON.parse(xhr.response);
          if (responseData.errorCode == null) {
            ChatGptPaymentService.requestRepeater.methods.repeater(
              ChatGptPaymentService.postCheckPayment,
              [data, callback, _this],
              3
            );
            ChatGptPaymentService.paymentWarningModal();
          } else {
            ChatGptPaymentService.requestRepeater.methods.repeater(
              ChatGptPaymentService.postCheckPayment,
              [data, callback, _this],
              3
            );
            ChatGptPaymentService.errorCodeModal(
              responseData.errorCode,
              responseData.errorMessage
            );
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else {
          var responseData = JSON.parse(xhr.response);
          if (responseData.errorCode == null) {
            ChatGptPaymentService.requestRepeater.methods.repeater(
              ChatGptPaymentService.postCheckPayment,
              [data, callback, _this],
              3
            );
            ChatGptPaymentService.openErrorModal();
          } else {
            ChatGptPaymentService.requestRepeater.methods.repeater(
              ChatGptPaymentService.postCheckPayment,
              [data, callback, _this],
              3
            );
            ChatGptPaymentService.errorCodeModal(
              responseData.errorCode,
              responseData.errorMessage
            );
          }
        }
      }
    };
  },

  userIpAndCountryDetail: function (callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: "https://ipapi.co/json/?key=MbOOO7PBCKlAUHDuQzM8KHFErEeEN78e77YNMamfmSTDxGjSCw",
      keepScope: _this ? _this : null,
      isExtarnalApiUrl: true,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      ChatGptPaymentService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          var responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
            localStorage.setItem("countryInfo", responseData.country_code);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          ChatGptPaymentService.openWarningModal();
        } else {
          ChatGptPaymentService.openErrorModal();
        }
      }
    };
  },

  userSubscriptionDetail: function (callback, _this, userId) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("chatGptSubscriptionDetailApiURL")  + "?userId=" + userId,
      keepScope: _this ? _this : null,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      ChatGptPaymentService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          var responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          ChatGptPaymentService.openWarningModal();
        } else {
          ChatGptPaymentService.openErrorModal();
        }
      }
    };
  },

  // sampas demo get service
  getBuildingDetails: function (callback, _this, binaNo) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      // url: `http://192.168.40.104:8290/GetBuildingEnergyConsumptionData?bina_no=${binaNo}`,
      url: `https://192.168.40.104:8253/GetBuildingEnergyConsumptionData?bina_no=${binaNo}`,
      keepScope: _this ? _this : null,
      isExtarnalApiUrl: true,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      ChatGptPaymentService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          var responseData = JSON.parse(xhr.response);
          if (responseData) {
            callback.apply(xhr);
            // localStorage.setItem("countryInfo", responseData.country_code)
          }
        } else if (xhr.status == 400) {
          ChatGptPaymentService.openWarningModal();
        } else {
          ChatGptPaymentService.openErrorModal();
        }
      }
    };
  },



  requestRepeater: (function () {
    var methods = {},
      definitions = {};
    definitions.requestCounter = 0;

    methods.repeater = function (func, paramArr, repeatLength) {
      if (definitions.requestCounter < repeatLength) {
        func(paramArr);
        definitions.requestCounter++;
      } else {
        definitions.requestCounter = 0;
      }
    };

    methods.init = (function () {
      methods.repeater();
    })();

    return {
      methods: methods,
    };
  })(),

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("paymentErrorModalMsgTxt") + "</p>",
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("paymentWarningmodalMsgTxt") + "</p>",
    });
  },

  openRepeaterWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("firstWarningmodalMsgTxt") + "</p>",
    });
  },

  openRepeaterErrorModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("firstWarningmodalMsgTxt") + "</p>",
    });
  },

  errorCodeModal: function (errorCode, errorMessage) {
    WarningModal({
      title: errorCode,
      message: "<p>" + errorMessage + "</p>",
      redirectAfterClose:
        Resources.getValue("appBaseEnvURL") + "/payment?failed=" + true,
    });
  },
};

export default ChatGptPaymentService;
