import React, { Component } from "react";
import Resources from "../libraries/resources";
import Utils from "../libraries/utils";

class ImgVideoPreviewModal extends Component {

	constructor(props) {
    super(props);
	}
	componentDidMount() {
    this.openModal();
  }

	warningModal() {
		Utils.modalm().open({
			exitButtonText: Resources.getValue("exitMsgTxt"),
			title: Resources.getValue("warningSendMailMsgTxt"),
			bodyContent: Resources.getValue("warningmodalMsgTxt"),
			buttons: [
				{
					text: Resources.getValue("okMsgTxt"),
					class: 'button yellow-button confirm-button',
					href: ''
				},
			],
		});
	}

	openModal() {
		var _this = this;
		document.querySelector(".close-modal").addEventListener("click", function () {
      _this.props.sharedClick(false);
      localStorage.setItem('isCustomModalOpen', false);
		})
	}

	render() {
		return (
			<div className="overlay">
				<div className="popup img-video-preview-modal">
					<div className="title">
						<div className="text">
							{Resources.getValue("previewMsgTxt")}
						</div>
					</div>
					<a className="close close-modal" onClick={this.props.handler}>&times;</a>
					<div className="select-shared">
						<ul>
							<li className="buttons shared-select">
								<div className="email-tab-contents">
									<div className="email-box active">
										{this.props.media.type === 'IMG' ? (
                      <>
                        <img src={this.props.media.src} alt="Image" />
                      </>
                    ) : (
                      <video controls controlsList="nodownload" preload="metadata" disablePictureInPicture>
                        <source src={this.props.media.src} type="video/mp4" />
                      </video>
                    )}
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default ImgVideoPreviewModal;
