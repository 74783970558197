import React, { Component } from "react";
import Utils from "../libraries/utils";
import "../libraries/jscolor";
import Resources from "../libraries/resources";
import MM from "../libraries/map";
import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
import { FixedSizeList as List } from "react-window";
import SharedMapService from "../services/api/shared-map";

import PublicMapNameChangeModalComponent from "../helpers/public-map-name-change-modal";

class PublicShareLinkToolbox extends Component {
  constructor(props) {
    super(props);

    localStorage.setItem("isNewMap", false);
    localStorage.setItem("isCustomModalOpen", false);

    this.state = {
      isPublicMapNameModal: JSON.parse(
        localStorage.getItem("isCustomModalOpen")
      ),
    }
  }

  componentDidMount() {
    this.drawMapName();
  }

  sharedClick = (isOpen) => {
    this.setState({
      isPublicMapNameModal: isOpen
    });
  };

  htmlToPdf() {
    MM.App.map.center();
    Utils.loadingScreen("show");
    document.querySelector(".download-options").classList.remove("show");

    var leftVal, rightVal, topVal, bottomVal;
    var lefts = [];
    var tops = [];
    var rect, scrollLeft, scrollTop;
    document.querySelectorAll(".item").forEach((item) => {
      item.classList.remove("current");

      rect = item.getBoundingClientRect();
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      lefts.push(rect.left + scrollLeft);
      tops.push(rect.top + scrollTop);
    });

    leftVal = Math.min.apply(null, lefts);
    rightVal = Math.max.apply(null, lefts);
    topVal = Math.min.apply(null, tops);
    bottomVal = Math.max.apply(null, tops);
    var w = rightVal - leftVal;
    var h = bottomVal - topVal;

    setTimeout(() => {
      var projectName = localStorage.getItem("openedMapName") + ".jpg";
      // var mapTransform = document.querySelector(".root").style.transform;
      // document.querySelector(".tip").style.display = "none";
      document.querySelector(".toolbox").style.display = "none";
      document.querySelector(".zoom-in").style.display = "none";
      document.querySelector(".zoom-out").style.display = "none";
      document.querySelector(".zoom-ratio").style.display = "none";
      document.querySelector("._hj_feedback_container") &&
        (document.querySelector("._hj_feedback_container").style.display =
          "block");
      document.querySelector(".item.root").classList.remove("current");

      if (leftVal > 0 || leftVal < 0) {
        var val =
          parseFloat(
            document.querySelector(".root").style.left.split("px")[0]
          ) - leftVal;
        document.querySelector(".root").style.left = val + 10 + "px";
        if (leftVal < 0) {
          w = w + val / 2 + 5;
        }
      }
      if (topVal > 0 || topVal < 0) {
        var val =
          parseFloat(document.querySelector(".root").style.top.split("px")[0]) -
          topVal;
        document.querySelector(".root").style.top = val + 10 + "px";
        if (topVal < 0) {
          h = h + val / 2 + 5;
        }
      }

      w = w + document.querySelector(".root").offsetWidth;
      h = h + document.querySelector(".root").offsetHeight + (document.querySelector(".root").offsetHeight / 2);

      htmlToImage
        .toPng(document.getElementById("port"), {
          quality: 1,
          width: w,
          height: h,
        })
        .then(function (dataUrl) {
          var doc = new jsPDF({
            unit: "px",
            format: [
              // Number(document.getElementById('port').style.width.split('px')[0]),
              // Number(document.getElementById('port').style.height.split('px')[0])
              w,
              h,
            ],
            orientation: "landscape",
          });
          doc.addImage(dataUrl, "JPEG", 10, 10);
          doc.save(localStorage.getItem("openedMapName") + ".pdf");
          Utils.loadingScreen("hide");
          document.querySelector(".toolbox").style.display = "block";
          document.querySelector(".zoom-in").style.display = "inline-block";
          document.querySelector(".zoom-out").style.display = "inline-block";
          document.querySelector(".zoom-ratio").style.display = "inline-block";
          document.querySelector("._hj_feedback_container") &&
            (document.querySelector("._hj_feedback_container").style.display =
              "block");
          MM.App.map.center();
          document.querySelector(".item.root").classList.add("current");
        });
    }, 100);
  }

  htmlToImage() {
    document.querySelectorAll(".root > canvas").forEach((item) => {
      item.style.zIndex = 0;
    });

    var mapTransform = document.querySelector(".root").style.transform;
    var leftVal, rightVal, topVal, bottomVal;
    var lefts = [];
    var tops = [];
    var rect, scrollLeft, scrollTop;

    document.querySelectorAll(".item").forEach((item) => {
      item.classList.remove("current");

      rect = item.getBoundingClientRect();
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      lefts.push(rect.left + scrollLeft);
      tops.push(rect.top + scrollTop);
    });

    leftVal = Math.min.apply(null, lefts);
    rightVal = Math.max.apply(null, lefts);
    topVal = Math.min.apply(null, tops);
    bottomVal = Math.max.apply(null, tops);
    var w = rightVal - leftVal;
    var h = bottomVal - topVal;

    document.querySelector(".download-options").classList.remove("show");

    setTimeout(() => {
      var projectName = localStorage.getItem("openedMapName") + ".jpg";
      // var mapTransform = document.querySelector(".root").style.transform;
      // document.querySelector(".tip").style.display = "none";
      document.querySelector(".toolbox").style.display = "none";
      document.querySelector(".zoom-in").style.display = "none";
      document.querySelector(".zoom-out").style.display = "none";
      document.querySelector(".zoom-ratio").style.display = "none";
      document.querySelector("._hj_feedback_container") &&
        (document.querySelector("._hj_feedback_container").style.display =
          "block");
      document.querySelector(".item.root").classList.remove("current");

      // MM.App.map.center();

      if (leftVal > 0 || leftVal < 0) {
        var val =
          parseFloat(
            document.querySelector(".root").style.left.split("px")[0]
          ) - leftVal;
        document.querySelector(".root").style.left = val + 10 + "px";
        if (leftVal < 0) {
          w = w + val / 2 + 5;
        }
      }
      if (topVal > 0 || topVal < 0) {
        var val =
          parseFloat(document.querySelector(".root").style.top.split("px")[0]) -
          topVal;
        document.querySelector(".root").style.top = val + 10 + "px";
        if (topVal < 0) {
          h = h + val / 2 + 5;
        }
      }

      w = w + document.querySelector(".root").offsetWidth;
      h = h + document.querySelector(".root").offsetHeight + (document.querySelector(".root").offsetHeight / 2);

      Utils.loadingScreen("show");
      htmlToImage
        .toPng(document.getElementById("port"), {
          quality: 1,
          width: w,
          height: h,
        })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = projectName;
          link.href = dataUrl;
          link.click();
          document.querySelector(".toolbox").style.display = "block";
          document.querySelector(".zoom-in").style.display = "inline-block";
          document.querySelector(".zoom-out").style.display = "inline-block";
          document.querySelector(".zoom-ratio").style.display = "inline-block";
          document.querySelector("._hj_feedback_container") &&
            (document.querySelector("._hj_feedback_container").style.display =
              "block");
          document.querySelector(".root").style.transform = mapTransform;
          Utils.loadingScreen("hide");
          MM.App.map.center();
          document.querySelector(".root").style.transform = mapTransform;
          document.querySelector(".item.root").classList.add("current");
          document.querySelectorAll(".root > canvas").forEach((item) => {
            item.style.zIndex = -15;
          });
        });
    }, 500);
  }

  saveMap() {}

  copyMap() {
    var data = {
      name: localStorage.getItem("openedMapName"),
      content: localStorage.getItem("content"),
      backgroundName: localStorage.getItem("backgroundName"),
    };
    SharedMapService.addMindMapToMyAccount(JSON.stringify(data));
  }

  drawMapName() {
    document.title = localStorage.getItem("openedMapName");
  }

  downloadMenu() {
    document.querySelector(".download-options").classList.toggle("show");
  }

  fitMapToScreen() {
    MM.App.map.center();

    const posObj = {
      scale192: { val: 1.92, text: '100%' },
      scale184: { val: 1.84, text: '96%' },
      scale176: { val: 1.76, text: '92%' },
      scale168: { val: 1.68, text: '88%' },
      scale160: { val: 1.60, text: '84%' },
      scale152: { val: 1.52, text: '80%' },
      scale144: { val: 1.44, text: '76%' },
      scale136: { val: 1.36, text: '72%' },
      scale128: { val: 1.28, text: '68%' },
      scale120: { val: 1.20, text: '64%' },
      scale112: { val: 1.12, text: '60%' },
      scale104: { val: 1.04, text: '56%' },
      scale096: { val: 0.96, text: '52%' },
      scale088: { val: 0.88, text: '48%' },
      scale080: { val: 0.80, text: '44%' },
      scale072: { val: 0.72, text: '40%' },
      scale064: { val: 0.64, text: '36%' },
      scale056: { val: 0.56, text: '32%' },
      scale048: { val: 0.48, text: '28%' },
      scale040: { val: 0.40, text: '24%' },
      scale032: { val: 0.32, text: '20%' },
      scale024: { val: 0.24, text: '16%' },
      scale016: { val: 0.16, text: '12%' },
      scale008: { val: 0.08, text: '8%' },
    };

    let scale = parseFloat(JSON.parse(localStorage.getItem("zoomMap")));
    recursiveScale(scale);

    function recursiveScale(scaleCurrent) {
      var leftVal, rightVal, topVal, bottomVal;
      var lefts = [];
      var tops = [];
      var rect, scrollLeft, scrollTop;

    document.querySelectorAll(".item").forEach((item) => {
      item.classList.remove("current");
      rect = item.getBoundingClientRect();
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      lefts.push(rect.left + scrollLeft);
      tops.push(rect.top + scrollTop);
    });

    leftVal = Math.min.apply(null, lefts);
    rightVal = Math.max.apply(null, lefts);
    topVal = Math.min.apply(null, tops);
    bottomVal = Math.max.apply(null, tops);
      if(
        topVal < 50 || bottomVal >= window.innerHeight - ((window.innerHeight/100)*9) || leftVal < 50 || rightVal >= window.innerWidth - ((window.innerWidth/100)*9)
      ) {
        // yukardan/asagidan sigmiyor || sagdan/soldan sigamiyor
        document.getElementById('fit-map') && document.getElementById('fit-map').setAttribute('disabled', false);
        let scale = scaleCurrent;
        let newScale = (scale >= 0.24) && (scale - 0.08).toFixed(2);
        if(newScale > 0.0) {
          localStorage.setItem("zoomMap", parseFloat(newScale).toFixed(2));
          let toFixedScaleText =
            "scale" + newScale.split(".")[0] + newScale.split(".")[1];
          document.querySelector(".zoom-ratio").innerHTML =
            posObj[toFixedScaleText].text;
          MM.Map.prototype._setScale(newScale);
          recursiveScale(newScale);
        }
      } else {
        document.getElementById('fit-map') && document.getElementById('fit-map').setAttribute('disabled', true);
        return;
      }
      MM.App.map.center();
    }
    
    document.querySelector(".root").classList.add('current');
  }

  render() {
    document.title = localStorage.getItem("openedMapName");
    return (
      <div className="toolbox">
        {this.state.isPublicMapNameModal ? (
            <PublicMapNameChangeModalComponent sharedClick={this.sharedClick} />
          ) : null}
        <div className="ui-wrapper">
          <button id="fit-map" className="fit-map" 
            onClick={() => this.fitMapToScreen()}
            title={Resources.getValue("fitMapBtnText")}>
            <i className="fa fa-arrows-alt"></i>
          </button>
          <div className="editor-header-left">
            <div className="editor-wrapper">
              <a
                href={Resources.getValue("appBaseEnvURL") + "/mind-map-list"}
                title="Back"
                className="back-btn"
              >
                <i className="icon-arrow-back back-icon"></i>
              </a>
              <div className="doc-name ellipsis">
                <h4
                  type="text"
                  maxLength="50"
                  className="map-name editor-header-tools"
                ></h4>
              </div>
            </div>
            {/* Undo Button */}
            <div className="undo-changes-button none">
              <a className="right-bottom-button" id="undo-changes-btn">
                <i className="icon-arrow-left"></i>
              </a>
            </div>
            {/* Redo Button */}
            <div className="redo-changes-button none">
              <a className="right-bottom-button" id="redo-changes-btn">
                <i className="icon-arrow-right"></i>
              </a>
            </div>
          </div>

          <div className="toolbox-option none">
            <input
              type="file"
              className="map-image-upload"
              accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            />
          </div>

          <div className="toolbox-option none">
            <label>{Resources.getValue("valueMsgTxtValue")}</label>
            <select id="value">
              <option value="">({Resources.getValue("noneMsgTxt")})</option>
              <option value="num">{Resources.getValue("numberMsgTxt")}</option>
              <optgroup label="Formula">
                <option value="sum">{Resources.getValue("sumMsgTxt")}</option>
                <option value="avg">
                  {Resources.getValue("avarageMsgTxt")}
                </option>
                <option value="min">{Resources.getValue("minMsgTxt")}</option>
                <option value="max">{Resources.getValue("maxMsgTxt")}</option>
              </optgroup>
            </select>
          </div>

          <div className="toolbox-option none">
            <label>{Resources.getValue("statusMsgTxt")}</label>
            <select id="status">
              <option value="">{Resources.getValue("noneMsgTxt")}</option>
              <option value="yes">{Resources.getValue("yesMsgTxt")}</option>
              <option value="no">{Resources.getValue("noMsgTxt")}</option>
              <option value="computed">
                {Resources.getValue("autocomputeMsgTxt")}
              </option>
            </select>
          </div>
        </div>

        {/* <button
          className="toogle-button fa fa-chevron-circle-left"
          title={Resources.getValue("toggleMsgTxt")}
        ></button> */}

        {/* <button
          data-command="Help"
          className="help-button toolbox-closed"
          title={Resources.getValue("showHideHelpPanelMsgTxt")}
        >
          <i className="fa fa-question" aria-hidden="true"></i>
        </button> */}

        {/* Editor Header Right */}
          <div className="ui">
            <div className="public-button-wrapper">
              <div className="copy-map">
                <a
                  // onClick={this.copyMap}
                  onClick={() => {
                    localStorage.setItem('isCustomModalOpen', true);
                    this.setState({
                      isPublicMapNameModal: JSON.parse(localStorage.getItem('isCustomModalOpen')),
                    });
                  }}
                  className="right-bottom-button"
                  title={Resources.getValue("copyMapMsgTxt")}
                >
                  <i className="icon-copy-black"></i>
                </a>
              </div>
              <div className="download-link">
                <div
                  onClick={this.downloadMenu}
                  id="image-to-pdf"
                  className="right-bottom-button download-dropdown"
                  title={Resources.getValue("imageToPdf")}
                >
                  <i className="icon-file-download"></i>
                  {/* dropdown */}
                  <div className="download-options">
                    <a
                      onClick={this.htmlToImage}
                      title={"JPEG " + Resources.getValue("imageToPdf")}
                    >
                      JPEG {Resources.getValue("imageToPdf")}
                    </a>
                    <a
                      onClick={this.htmlToPdf}
                      title={"PDF " + Resources.getValue("imageToPdf")}
                    >
                      PDF {Resources.getValue("imageToPdf")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        {/* <div className="help-button save-button" onClick={this.saveMap}>save</div> */}

        <div id="io" className="ui">
          <h3></h3>
          <p>
            <span>{Resources.getValue("storageMsgTxt")}</span>
            <select id="backend"></select>
          </p>

          <div id="file">
            <p className="desc">
              Local files are suitable for loading/saving files from other
              mindmapping applications.
            </p>
            <p data-for="save">
              <span>Format</span>
              <select className="format"></select>
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
          </div>

          <div id="image">
            <p className="desc">Export your design as a PNG image.</p>
            <p data-for="save">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
          </div>

          <div id="local">
            <p className="desc">
              Your browser's localStorage can handle many mind maps and creates
              a permalink, but this URL cannot be shared.
            </p>
            <p data-for="load">
              <span>Saved maps</span>
              <select className="list"></select>
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
            <p data-for="load">
              <button className="remove">Delete</button>
            </p>
          </div>

          <div id="firebase">
            <p className="desc">
              Firebase offers real-time synchronization for true multi-user
              collaboration.
            </p>
            <p data-for="save load">
              <span>Server</span>
              <input type="text" className="server" />
            </p>
            <p data-for="save load">
              <span>Auth</span>
              <select className="auth">
                <option value="">(None)</option>
                <option value="facebook">Facebook</option>
                <option value="twitter">Twitter</option>
                <option value="github">GitHub</option>
                <option value="google">Google</option>
              </select>
            </p>
            <p data-for="load">
              <span>Saved maps</span>
              <select className="list"></select>
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
            <p data-for="load">
              <button className="remove">Delete</button>
            </p>
          </div>

          <div id="webdav">
            <p className="desc">
              Use this to access a generic DAV-like REST API.
            </p>
            <p data-for="save load">
              <span>URL</span>
              <input type="text" className="url" />
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
          </div>

          <div id="gdrive">
            <p className="desc">
              Maps stored in Google Drive have a permalink URL and can be shared
              with other users, if you allow this by setting proper permissions
              (inside Google Drive itself).
            </p>
            <p data-for="save">
              <span>Format</span>
              <select className="format"></select>
            </p>
            <p data-for="save load">
              <button className="go"></button>
              <button className="cancel">Cancel</button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

class MenuList extends Component {
  render() {
    const iconSelectOptionheight = 30;
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * iconSelectOptionheight;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={iconSelectOptionheight}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

export default PublicShareLinkToolbox;
