import Resources from "../../libraries/resources";
import Utils from "../../libraries/utils";
import LoginService from "../api/login";
import DataService from "./data-service";
import WarningModal from "../../components/modals/warning-modal";
import ErrorModal from "../../components/modals/error-modal";
import SuccessModal from "../../components/modals/success-modal";

var ProfileService = {
  update: function (data, _this) {
    const xhr = new DataService.createXHR({
      type: "PUT",
      token: "Bearer",
      url: Resources.getValue("accountProfileApiURL"),
      keepScope: _this,
      data: data,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      ProfileService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            ProfileService.openSuccessModal();
          }
        } else if (xhr.status == 400) {
          ProfileService.openWarningModal();
        } else if (xhr.status == 409) {
          ProfileService.openExistEmailErrorModal();
        } else {
          ProfileService.openErrorModal();
        }
      }
    };
  },

  getProfile: function (callback, _this) {
    const xhr = new DataService.createXHR({
      type: "GET",
      token: "Bearer",
      url: Resources.getValue("accountDetailProfileApiURL"),
      keepScope: _this,
    });

    xhr.onerror = function () {
      Utils.loadingScreen("hide");
      ProfileService.openErrorModal();
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        // close loading when xhr done
        Utils.loadingScreen("hide");
        if (xhr.status == 200 && xhr.response) {
          const responseData = JSON.parse(xhr.response);
          if (responseData.result) {
            callback.apply(xhr);
          }
        } else if (xhr.status == 401) {
          LoginService.login();
        } else if (xhr.status == 400) {
          ProfileService.openWarningModal();
        } else {
          ProfileService.openErrorModal();
        }
      }
    };
  },

  openErrorModal: function () {
    ErrorModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("errormodalMsgTxt") + "</p>",
    });
  },

  openWarningModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message: "<p>" + Resources.getValue("warningmodalMsgTxt") + "</p>",
    });
  },

  openExistEmailErrorModal: function () {
    WarningModal({
      title: Resources.getValue("errorMsgTxt"),
      message:
        "<p>" + Resources.getValue("existEMailErrorModalMsgTxt") + "</p>",
    });
  },

  openSuccessModal: function () {
    SuccessModal({
      title: Resources.getValue("successMsgTxt"),
      message: "<p>" + Resources.getValue("profilesuccessMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("okMsgTxt"),
          class: "button yellow-button confirm-button",
          href: Resources.getValue("appBaseEnvURL") + "/profile",
        },
      ],
      redirectAfterClose: Resources.getValue("appBaseEnvURL") + "/profile",
    });
  },
};

export default ProfileService;
