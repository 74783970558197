import React, { Component } from "react";
import Resources from "../libraries/resources";
import NavMenu from "../components/nav-menu";
import Footer from "../components/footer";

// image
import foramindLogo from "../styles/img/foramind_logo.png";

class DataOwnerFormPage extends Component {

	constructor() {
		super();

		this.menuItems = [
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/' : '/',
				title: Resources.getValue("landingPageHomeMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '',
				title: Resources.getValue("landingPageCorporateMsgTxt"),
				activeClass: 'not-active',
				externalLink: false,
				subLinks: [
					{
						href: '/about',
						title: Resources.getValue("landingPageAboutMsgTxt"),
						activeClass: '',
						externalLink: true
					},
					// {
					// 	href: '/landing-page#references',
					// 	title: Resources.getValue("landingPageReferencesMsgTxt"),
					// 	activeClass: '',
					// 	externalLink: true
					// },
					{
						href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#contact' : '/' + '#contact',
						title: Resources.getValue("landingPageContactMsgTxt"),
						activeClass: '',
						externalLink: true
					}
				]
			},
			// {
			// 	href: '/features',
			// 	title: Resources.getValue("landingPageFeaturesMsgTxt"),
			// 	activeClass: '',
			// 	externalLink: true
			// },
			{
				href: '/education',
				title: Resources.getValue("landingPageEducationMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/blog',
				title: "Blog",
				activeClass: '',
				externalLink: true
			},
			{
				href: Resources.getValue('appBaseEnvURL') === '/foramind' ? '/#price' : '/' + '#price',
				title: Resources.getValue("landingPagePriceMsgTxt"),
				activeClass: '',
				externalLink: true
			},
			{
				href: '/kvkk',
				title: Resources.getValue("landingPageKavkkMsgTxt"),
				activeClass: 'home-color-set active',
				externalLink: true
			}
		];
	}

	componentDidMount() {
		document.title = "Foramind | " + Resources.getValue("landingPageKavkkMsgTxt");
	}

	render() {
		return (
			<div className="landing-page" id="landing-page">
				<NavMenu menuItems={this.menuItems} />
				<div className="kvkk-page text-content">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<a href={Resources.getValue('appBaseEnvURL') + '/kvkk'} className="kvkk-back">
									<i className="fa fa-chevron-left"></i> {Resources.getValue("kvkkGoBackMsgTxt")}
								</a>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="img-wrapper text-center mb-4">
									<img src={foramindLogo} alt="Foramind" height={100} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<h2>{Resources.getValue("kvkkPageDataOwnerFormPageTitleMsgTxt")}</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="content-wrapper">
									<div dangerouslySetInnerHTML={{ __html: Resources.getValue('DataOwnerFormText') }}></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	};
}

export default DataOwnerFormPage;
