import React, { Component } from "react";
import Resources from "../libraries/resources";

class EmojiList extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="icon-list-wrapper">
        <div id="iconListActions" className="icon-list-actions">
          <span className="icon-delete-button" onClick={this.props.removeIcon}>
            <i className="fa fa-times"></i> {Resources.getValue('iconRemoveTxt')}
          </span>
        </div>
        <div className="icon-list emoji-list">
          <div data-icon='emoji-grinning-face' className="iconSelect">
            <span className="emoji-grinning-face"></span>
          </div>
          <div data-icon='emoji-beaming-face-with-smiling-eyes' className="iconSelect">
            <span className="emoji-beaming-face-with-smiling-eyes"></span>
          </div>
          <div data-icon='emoji-grinning-squinting-face' className="iconSelect">
            <span className="emoji-grinning-squinting-face"></span>
          </div>
          <div data-icon='emoji-grinning-face-with-sweat' className="iconSelect">
            <span className="emoji-grinning-face-with-sweat"></span>
          </div>
          <div data-icon='emoji-face-with-tears-of-joy' className="iconSelect">
            <span className="emoji-face-with-tears-of-joy"></span>
          </div>
          <div data-icon='emoji-slightly-smiling-face' className="iconSelect">
            <span className="emoji-slightly-smiling-face"></span>
          </div>
          <div data-icon='emoji-upside-down-face' className="iconSelect">
            <span className="emoji-upside-down-face"></span>
          </div>
          <div data-icon='emoji-winking-face' className="iconSelect">
            <span className="emoji-winking-face"></span>
          </div>
          <div data-icon='emoji-smiling-face-with-halo' className="iconSelect">
            <span className="emoji-smiling-face-with-halo"></span>
          </div>
          <div data-icon='emoji-smiling-face-with-hearts' className="iconSelect">
            <span className="emoji-smiling-face-with-hearts"></span>
          </div>
          <div data-icon='emoji-smiling-face-with-heart-eyes' className="iconSelect">
            <span className="emoji-smiling-face-with-heart-eyes"></span>
          </div>
          <div data-icon='emoji-star-struck' className="iconSelect">
            <span className="emoji-star-struck"></span>
          </div>
          <div data-icon='emoji-face-blowing-a-kiss' className="iconSelect">
            <span className="emoji-face-blowing-a-kiss"></span>
          </div>
          <div data-icon='emoji-smiling-face' className="iconSelect">
            <span className="emoji-smiling-face"></span>
          </div>
          <div data-icon='emoji-winking-face-with-tongue' className="iconSelect">
            <span className="emoji-winking-face-with-tongue"></span>
          </div>
          <div data-icon='emoji-money-mouth-face' className="iconSelect">
            <span className="emoji-money-mouth-face"></span>
          </div>
          <div data-icon='emoji-hugging-face' className="iconSelect">
            <span className="emoji-hugging-face"></span>
          </div>
          <div data-icon='emoji-shushing-face' className="iconSelect">
            <span className="emoji-shushing-face"></span>
          </div>
          <div data-icon='emoji-zipper-mouth-face' className="iconSelect">
            <span className="emoji-zipper-mouth-face"></span>
          </div>
          <div data-icon='emoji-neutral-face' className="iconSelect">
            <span className="emoji-neutral-face"></span>
          </div>
          <div data-icon='emoji-expressionless-face' className="iconSelect">
            <span className="emoji-expressionless-face"></span>
          </div>
          <div data-icon='emoji-face-without-mouth' className="iconSelect">
            <span className="emoji-face-without-mouth"></span>
          </div>
          <div data-icon='emoji-smirking-face' className="iconSelect">
            <span className="emoji-smirking-face"></span>
          </div>
          <div data-icon='emoji-grimacin-face' className="iconSelect">
            <span className="emoji-grimacin-face"></span>
          </div>
          <div data-icon='emoji-lying-face' className="iconSelect">
            <span className="emoji-lying-face"></span>
          </div>
          <div data-icon='emoji-pensive-face' className="iconSelect">
            <span className="emoji-pensive-face"></span>
          </div>
          <div data-icon='emoji-sleepy-face' className="iconSelect">
            <span className="emoji-sleepy-face"></span>
          </div>
          <div data-icon='emoji-drooling-face' className="iconSelect">
            <span className="emoji-drooling-face"></span>
          </div>
          <div data-icon='emoji-sleeping-face' className="iconSelect">
            <span className="emoji-sleeping-face"></span>
          </div>
          <div data-icon='emoji-face-with-medical-mask' className="iconSelect">
            <span className="emoji-face-with-medical-mask"></span>
          </div>
          <div data-icon='emoji-face-with-thermometer' className="iconSelect">
            <span className="emoji-face-with-thermometer"></span>
          </div>
          <div data-icon='emoji-face-with-head-bandage' className="iconSelect">
            <span className="emoji-face-with-head-bandage"></span>
          </div>
          <div data-icon='emoji-nauseated-face' className="iconSelect">
            <span className="emoji-nauseated-face"></span>
          </div>
          <div data-icon='emoji-face-vomiting' className="iconSelect">
            <span className="emoji-face-vomiting"></span>
          </div>
          <div data-icon='emoji-sneezing-face' className="iconSelect">
            <span className="emoji-sneezing-face"></span>
          </div>
          <div data-icon='emoji-hot-face' className="iconSelect">
            <span className="emoji-hot-face"></span>
          </div>
          <div data-icon='emoji-cold-face' className="iconSelect">
            <span className="emoji-cold-face"></span>
          </div>
          <div data-icon='emoji-woozy-face' className="iconSelect">
            <span className="emoji-woozy-face"></span>
          </div>
          <div data-icon='emoji-knocked-out-face' className="iconSelect">
            <span className="emoji-knocked-out-face"></span>
          </div>
          <div data-icon='emoji-exploding-head' className="iconSelect">
            <span className="emoji-exploding-head"></span>
          </div>
          <div data-icon='emoji-cowboy-hat-face' className="iconSelect">
            <span className="emoji-cowboy-hat-face"></span>
          </div>
          <div data-icon='emoji-partying-face' className="iconSelect">
            <span className="emoji-partying-face"></span>
          </div>
          <div data-icon='emoji-smiling-face-with-sunglasses' className="iconSelect">
            <span className="emoji-smiling-face-with-sunglasses"></span>
          </div>
          <div data-icon='emoji-nerd-face' className="iconSelect">
            <span className="emoji-nerd-face"></span>
          </div>
          <div data-icon='emoji-face-with-monocle' className="iconSelect">
            <span className="emoji-face-with-monocle"></span>
          </div>
          <div data-icon='emoji-face-screaming-in-fear' className="iconSelect">
            <span className="emoji-face-screaming-in-fear"></span>
          </div>
          <div data-icon='emoji-confounded-face' className="iconSelect">
            <span className="emoji-confounded-face"></span>
          </div>
          <div data-icon='emoji-persevering-face' className="iconSelect">
            <span className="emoji-persevering-face"></span>
          </div>
          <div data-icon='emoji-disappointed-face' className="iconSelect">
            <span className="emoji-disappointed-face"></span>
          </div>
          <div data-icon='emoji-yawning-face' className="iconSelect">
            <span className="emoji-yawning-face"></span>
          </div>
          <div data-icon='emoji-face-with-steam-from-nose' className="iconSelect">
            <span className="emoji-face-with-steam-from-nose"></span>
          </div>
          <div data-icon='emoji-pouting-face' className="iconSelect">
            <span className="emoji-pouting-face"></span>
          </div>
          <div data-icon='emoji-face-with-symbols-on-mouth' className="iconSelect">
            <span className="emoji-face-with-symbols-on-mouth"></span>
          </div>
          <div data-icon='emoji-smiling-face-with-horns' className="iconSelect">
            <span className="emoji-smiling-face-with-horns"></span>
          </div>
          <div data-icon='emoji-skull-and-crossbones' className="iconSelect">
            <span className="emoji-skull-and-crossbones"></span>
          </div>
          <div data-icon='emoji-pile-of-poo' className="iconSelect">
            <span className="emoji-pile-of-poo"></span>
          </div>
          <div data-icon='emoji-clown-face' className="iconSelect">
            <span className="emoji-clown-face"></span>
          </div>
          <div data-icon='emoji-ogre' className="iconSelect">
            <span className="emoji-ogre"></span>
          </div>
          <div data-icon='emoji-see-no-evil-monkey' className="iconSelect">
            <span className="emoji-see-no-evil-monkey"></span>
          </div>
          <div data-icon='emoji-hear-no-evil-monkey' className="iconSelect">
            <span className="emoji-hear-no-evil-monkey"></span>
          </div>
          <div data-icon='emoji-speak-no-evil-monkey' className="iconSelect">
            <span className="emoji-speak-no-evil-monkey"></span>
          </div>
          <div data-icon='emoji-kiss-mark' className="iconSelect">
            <span className="emoji-kiss-mark"></span>
          </div>
          <div data-icon='emoji-heart-with-arrow' className="iconSelect">
            <span className="emoji-heart-with-arrow"></span>
          </div>
          <div data-icon='emoji-sparkling-heart' className="iconSelect">
            <span className="emoji-sparkling-heart"></span>
          </div>
          <div data-icon='emoji-red-heart' className="iconSelect">
            <span className="emoji-red-heart"></span>
          </div>
          <div data-icon='emoji-orange-heart' className="iconSelect">
            <span className="emoji-orange-heart"></span>
          </div>
          <div data-icon='emoji-yellow-heart' className="iconSelect">
            <span className="emoji-yellow-heart"></span>
          </div>
          <div data-icon='emoji-green-heart' className="iconSelect">
            <span className="emoji-green-heart"></span>
          </div>
          <div data-icon='emoji-blue-heart' className="iconSelect">
            <span className="emoji-blue-heart"></span>
          </div>
          <div data-icon='emoji-purple-heart' className="iconSelect">
            <span className="emoji-purple-heart"></span>
          </div>
          <div data-icon='emoji-hundred-points' className="iconSelect">
            <span className="emoji-hundred-points"></span>
          </div>
          <div data-icon='emoji-anger-symbol' className="iconSelect">
            <span className="emoji-anger-symbol"></span>
          </div>
          <div data-icon='emoji-collision' className="iconSelect">
            <span className="emoji-collision"></span>
          </div>
          <div data-icon='emoji-sweat-droplets' className="iconSelect">
            <span className="emoji-sweat-droplets"></span>
          </div>
          <div data-icon='emoji-dashing-away' className="iconSelect">
            <span className="emoji-dashing-away"></span>
          </div>
          <div data-icon='emoji-waving-hand' className="iconSelect">
            <span className="emoji-waving-hand"></span>
          </div>
          <div data-icon='emoji-raised-back-of-hand' className="iconSelect">
            <span className="emoji-raised-back-of-hand"></span>
          </div>
          <div data-icon='emoji-hand-with-fingers-splayed' className="iconSelect">
            <span className="emoji-hand-with-fingers-splayed"></span>
          </div>
          <div data-icon='emoji-raised-hand' className="iconSelect">
            <span className="emoji-raised-hand"></span>
          </div>
          <div data-icon='emoji-vulcan-salute' className="iconSelect">
            <span className="emoji-vulcan-salute"></span>
          </div>
          <div data-icon='emoji-ok-hand' className="iconSelect">
            <span className="emoji-ok-hand"></span>
          </div>
          <div data-icon='emoji-pinching-hand' className="iconSelect">
            <span className="emoji-pinching-hand"></span>
          </div>
          <div data-icon='emoji-victory-hand' className="iconSelect">
            <span className="emoji-victory-hand"></span>
          </div>
          <div data-icon='emoji-crossed-fingers' className="iconSelect">
            <span className="emoji-crossed-fingers"></span>
          </div>
          <div data-icon='emoji-love-you-gesture' className="iconSelect">
            <span className="emoji-love-you-gesture"></span>
          </div>
          <div data-icon='emoji-sign-of-the-horns' className="iconSelect">
            <span className="emoji-sign-of-the-horns"></span>
          </div>
          <div data-icon='emoji-call-me-hand' className="iconSelect">
            <span className="emoji-call-me-hand"></span>
          </div>
          <div data-icon='emoji-backhand-index-pointing-left' className="iconSelect">
            <span className="emoji-backhand-index-pointing-left"></span>
          </div>
          <div data-icon='emoji-backhand-index-pointing-right' className="iconSelect">
            <span className="emoji-backhand-index-pointing-right"></span>
          </div>
          <div data-icon='emoji-backhand-index-pointing-up' className="iconSelect">
            <span className="emoji-backhand-index-pointing-up"></span>
          </div>
          <div data-icon='emoji-backhand-index-pointing-down' className="iconSelect">
            <span className="emoji-backhand-index-pointing-down"></span>
          </div>
          <div data-icon='emoji-index-pointing-up' className="iconSelect">
            <span className="emoji-index-pointing-up"></span>
          </div>
          <div data-icon='emoji-baby' className="iconSelect">
            <span className="emoji-baby"></span>
          </div>
          <div data-icon='emoji-boy' className="iconSelect">
            <span className="emoji-boy"></span>
          </div>
          <div data-icon='emoji-girl' className="iconSelect">
            <span className="emoji-girl"></span>
          </div>
          <div data-icon='emoji-man-red-hair' className="iconSelect">
            <span className="emoji-man-red-hair"></span>
          </div>
          <div data-icon='emoji-woman' className="iconSelect">
            <span className="emoji-woman"></span>
          </div>
        </div>
      </div>
    );
  }
}


export default EmojiList;
