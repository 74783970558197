import React, { Component } from "react";
import Resources from "../libraries/resources";


class MenuSignInButton extends Component {

  constructor() {
    super()

    this.tokenExpire = false;
		const token = localStorage.getItem('token');
    if(token) {
      const tokenJSON = JSON.parse(atob(token.split('.')[1]));
      if (tokenJSON.exp < new Date().getTime() / 1000) { // ms cinsinden
				this.tokenExpire = true;
      }
    }
  }

  render() {    
    return (
      <>
        {(this.tokenExpire === false && localStorage.getItem('userInformation')) ? (
          <div className="hg-button-container">
          <a className="signin-page yellow-button" href={Resources.getValue('appBaseEnvURL') + '/mind-map-list'}>
            <div className="login-wrap">
              <span>{Resources.getValue("welcomeMsgTxt")}</span>
              {(JSON.parse(localStorage.getItem('userInformation')).firstName !== null && JSON.parse(localStorage.getItem('userInformation')).lastName !== null) ? (
                <div className="user-name">
                  {JSON.parse(localStorage.getItem('userInformation')).firstName + ' ' + JSON.parse(localStorage.getItem('userInformation')).lastName}
                </div>
              ) : (
                <div className="user-name">
                  {JSON.parse(localStorage.getItem('userInformation')).userName.split('@')[0]}
                </div>
              )}
            </div>
          </a>
          </div>
        ) : (
          <div className="hg-button-container">
            <a className="register-button" href={Resources.getValue('appBaseEnvURL') + '/register'}>{Resources.getValue("landingPageRegisterMsgTxt")}
            </a>
            <a className="signin-page yellow-button" href={Resources.getValue('appBaseEnvURL') + '/login'}>			  {Resources.getValue("landingPageSinginMsgTxt")}
            </a>
          </div>
            
          )
        }
      </>
    );
  }
}

export default MenuSignInButton;
