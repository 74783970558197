import React from "react";
import MM from "../libraries/map";
import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Utils from "../libraries/utils";
import shape1 from "../styles/img/shape-1.png";
import shape2 from "../styles/img/shape-2.png";
import shape3 from "../styles/img/shape-3.png";
import shape4 from "../styles/img/shape-4.png";
import shape5 from "../styles/img/shape-5.png";
import shape6 from "../styles/img/shape-6.png";
import shape7 from "../styles/img/shape-7.png";
import shape8 from "../styles/img/shape-8.png";
import shape9 from "../styles/img/shape-9.png";

import Resources from "../libraries/resources";
import CustomModal from "../helpers/custom-modal";
import SaveMapAsTemplateModal from "../helpers/save-map-as-template-modal";
import SharedMapNameChangeModalComponent from "../helpers/shared-map-name-change-modal";
import CopyMyMapNameChangeModalComponent from "../helpers/copy-my-map-name-change-modal";
import ChangeMapNameModal from "../helpers/change-map-name-modal";
import mapColors from "../libraries/map-color-list.json";

// small icon bg images
import backgroundImage1 from "../styles/img/background-image1.png";
import backgroundImage2 from "../styles/img/background-image2.png";
import backgroundImage3 from "../styles/img/background-image3.png";
import backgroundImage4 from "../styles/img/background-image4.png";
import backgroundImage5 from "../styles/img/background-image5.png";
import backgroundImage6 from "../styles/img/background-image6.png";
import backgroundImage7 from "../styles/img/background-image7.png";
import backgroundImage8 from "../styles/img/background-image8.png";
import backgroundImage9 from "../styles/img/background-image9.png";
import backgroundImage112 from "../styles/img/background-image11.png";
import backgroundImage12 from "../styles/img/background-image12.png";
import backgroundImage13 from "../styles/img/background-image13.png";
import backgroundImage14 from "../styles/img/background-image14.png";
import backgroundImage15 from "../styles/img/background-image15.png";
import backgroundImage16 from "../styles/img/background-image16.png";
// big bg image
import backgroundImage11 from "../styles/img/background-image_tiny.png";
import backgroundImage21 from "../styles/img/background-image21_tiny.png";
import backgroundImage31 from "../styles/img/background-image31_tiny.png";
import backgroundImage41 from "../styles/img/background-image41_tiny.png";
import backgroundImage51 from "../styles/img/background-image51_tiny.png";
import backgroundImage61 from "../styles/img/background-image61_tiny.png";
import backgroundImage71 from "../styles/img/background-image71_tiny.png";
import backgroundImage81 from "../styles/img/background-image81_tiny.png";

import createNewIcon from "../styles/img/create_new_icon.png";
import MapService from "../services/api/map";
import SharedMapService from "../services/api/shared-map";
// Emoji List
import EmojiList from "./emoji-list";

class Toolbox extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnDragEnd = this.handleOnDragEnd.bind(this);

    this.handler = this.handler.bind(this); // for left list present items update

    this.showBeforePresentStartClick =  this.showBeforePresentStartClick.bind(this);
    this.showBeforePresentCancelClick =  this.showBeforePresentCancelClick.bind(this);
    this.startPresentClick = this.startPresentClick.bind(this);

    this.presentItemPrevClick = this.presentItemPrevClick.bind(this);
    this.presentItemNextClick = this.presentItemNextClick.bind(this);

    // map ilk yuklendiginde hata vermemesi icin default deger atama yapiyoruz
    localStorage.setItem("selectedItemSettings", JSON.stringify({
      fontFamily: {
        id: "indie-flower",
        value: "Indie Flower"
      },
      fontSize: "24",
      textColor: '#4aa2fd',
      shapeSize: "auto",
      shape: "ellipse"
    }));

    localStorage.setItem("isPresentModeActive", false);
    localStorage.setItem("beforePresent", false);

    localStorage.setItem("willGoToNewMapPage", false);
    localStorage.setItem("isCustomModalOpen", false);
    this.state = {
      isUserMakePublicThisMapWithButton: false,
      isSharedModal: JSON.parse(localStorage.getItem("isCustomModalOpen")),
      isSharedMapNameModal: JSON.parse(
        localStorage.getItem("isCustomModalOpen")
      ),
      isSaveMapAsTemplateModal: JSON.parse(
        localStorage.getItem("isCustomModalOpen")
      ),
      isChangeMapNameModal: JSON.parse(
        localStorage.getItem("isCustomModalOpen")
      ),
      willCopyMapNameModal: JSON.parse(
        localStorage.getItem("isCustomModalOpen")
      ),
      isUserHasPermissionForOptions: false,
      isTeacherUserHasPermissionForOptions: false,
      currentItemsOrders: [],
      presentMode: false,
      mapPresentDatas: [
        // {
        //   id: 0,
        //   text: 'item 1',
        //   nodeId: '1'
        // }
      ],
      presentCurrentItemIndex: 0,
      isDownloadable: false,
      downloadType:"",
    };

    localStorage.setItem("mapPresentDatasStore", JSON.stringify(this.state.mapPresentDatas));
  }

  componentDidMount() {

    document.addEventListener('keydown', (e) => {
      e = e || window.event; 
      if (
        e.keyCode === 37 || e.keyCode === 40 || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.keyCode === 33
      ) {
        // left/down arrow, pgDown pressed
        this.presentItemPrevClick();
      } else if (
          e.keyCode === 39 || e.keyCode === 38 || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.keyCode === 34
        ) {
        // right/up arrow, pgUp pressed
        this.presentItemNextClick();
      }
    })

    this.colorPickerBuild();
    this.changeShape();
    this.changeIcon();
    this.lineChange();
    this.changeBackgroundImage();
    this.changeFont();
    this.changeFontSize();
    this.changeShapeSize();
    this.selectsBlurFix();
    this.toolBoxMobileSwipe();
    this.drawMapName();
    this.isThereChangesForUndo();
    this.isThereChangesForRedo();
    
    this.handler(); // to refresh mapPResentDatas in left list
    // sadece map sahibinde sunum islemlerini yapabilsin diye
    JSON.parse(localStorage.getItem("mapPermission")) === 0 && this.getAllPresentItemsFromApi();

    // user role control for edilt/delete permission
    if (localStorage.getItem("userRoleIdList")) {
      JSON.parse(localStorage.getItem("userRoleIdList")).forEach((role) => {
        if (role === 3 || role === 1) {
          // superadmin ya da kurum admin ise
          this.setState({
            isUserHasPermissionForOptions: true,
            isTeacherUserHasPermissionForOptions: false,
          });
        } else if (role === 4) {
          // superadmin ya da kurum admin degilse ve rolu ogretmen ise
          this.setState({
            isUserHasPermissionForOptions: false,
            isTeacherUserHasPermissionForOptions: true,
          });
        } else {
          this.setState({
            isUserHasPermissionForOptions: false,
            isTeacherUserHasPermissionForOptions: false,
          });
        }
      });
    }
    this.setState({
      changePresentItemSavedControl: false,
    });
  }

  shapeSizeMenu() {
    document
      .getElementById("shapeSizeDropdown")
      .classList.toggle("toolbox-show");
    document
      .querySelector(".shape-size-wrapper")
      .classList.toggle("toolbox-item-border");

    let allWillCloseItem = document.querySelectorAll(
      ".toolbox-item:not(.shape-size-wrapper)"
    );
    allWillCloseItem.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(
      ".toolbox-content:not(#shapeSizeDropdown)"
    );
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  changeShapeSize() {
    const shapeSizeSelect = document.querySelectorAll(".shapeSizeSelect");
    [].forEach.call(shapeSizeSelect, (el) => {
      el.addEventListener("click", function () {
        [].forEach.call(shapeSizeSelect, (e) => {
          e.classList.remove("shape-size-select");
        });
        el.classList.toggle("shape-size-select");
        const dataShapeSize = el.getAttribute("datashapesize");
        const shapeSize = MM.ShapeSize.getById(dataShapeSize);
        const item = MM.App.current;
        const action = new MM.Action.SetShapeSize(item, shapeSize);
        MM.App.action(action);
      });
    });
  }

  fontMenu() {
    document.getElementById("fontDropdown").classList.toggle("toolbox-show");
    document
      .querySelector(".font-wrapper")
      .classList.toggle("toolbox-item-border");

    if (document.querySelector(".font-wrapper").classList.contains("toolbox-item-border")) {
      document.querySelector('.font-button > i').className = 'icon-chevron-up';
    } else {
      document.querySelector('.font-button > i').className = 'icon-chevron-down';
    }

    let allWillClose = document.querySelectorAll(
      ".toolbox-item:not(.font-wrapper)"
    );
    allWillClose.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(
      ".toolbox-content:not(#fontDropdown)"
    );
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  fontSizeMenu() {
    document
      .getElementById("fontSizeDropdown")
      .classList.toggle("toolbox-show");
    document
      .querySelector(".fontSize-wrapper")
      .classList.toggle("toolbox-item-border");

    // auto focus font size input
    if (
      document
        .getElementById("fontSizeDropdown")
        .classList.contains("toolbox-show") === true
    ) {
      document.querySelector(".item-font-size-input").focus();
      document.querySelector('.fontSize-button > i').className = 'icon-chevron-up';
    } else {
      document.querySelector(".item-font-size-input").value = "";
      document.querySelector('.fontSize-button > i').className = 'icon-chevron-down';
    }

    let allWillClose = document.querySelectorAll(
      ".toolbox-item:not(.fontSize-wrapper)"
    );
    allWillClose.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(
      ".toolbox-content:not(#fontSizeDropdown)"
    );
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  changeFont() {
    const fontSelect = document.querySelectorAll(".fontSelect");
    [].forEach.call(fontSelect, (el) => {
      el.addEventListener("click", function () {
        [].forEach.call(fontSelect, (e) => {
          e.classList.remove("font-select");
        });
        el.classList.toggle("font-select");
        const dataFont = el.getAttribute("data-font");
        document.querySelector('.font-button > span').innerHTML
          = document.querySelector('.font-select > div').innerHTML;
        const font = MM.Font.getById(dataFont);
        const item = MM.App.current;
        const action = new MM.Action.SetFont(item, font);
        MM.App.action(action);
      });
    });
  }

  changeFontSize() {
    const fontSizeSelect = document.querySelectorAll(".fontSizeSelect");
    [].forEach.call(fontSizeSelect, (el) => {
      el.addEventListener("click", function () {
        [].forEach.call(fontSizeSelect, (e) => {
          e.classList.remove("fontSize-select");
        });
        el.classList.toggle("fontSize-select");
        const dataFontSize = el.getAttribute("data-font-size");
        document.querySelector('.fontSize-button > span').innerHTML
          = document.querySelector('.fontSize-select > div').innerHTML;
        const fontSize = MM.FontSize.getById(dataFontSize);
        const item = MM.App.current;
        const action = new MM.Action.SetFontSize(item, fontSize);
        MM.App.action(action);
      });
    });
  }

  validateFontSize(evt) {
    var theEvent = evt || window.event;
    // Handle paste
    if (theEvent.type === "paste") {
      key = evt.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  setFontSizeFn() {
    var fontSizeInput = document.querySelector(".item-font-size-input");
    if (
      fontSizeInput.value !== "" &&
      Number(fontSizeInput.value) > 10 &&
      Number(fontSizeInput.value) < 31
    ) {
      const dataFontSize = fontSizeInput.value;
      const fontSize = MM.FontSize.getById(dataFontSize);
      const item = MM.App.current;
      const action = new MM.Action.SetFontSize(item, fontSize);
      MM.App.action(action);
    } else if (
      fontSizeInput.value !== "" &&
      (Number(fontSizeInput.value) < 11 || Number(fontSizeInput.value) > 30)
    ) {
      alert(Resources.getValue("wrongSetMapFontSizeMsgTxt"));
    }
  }

  changeFontSizeInput(e) {
    if (e.keyCode == 13) {
      this.setFontSizeFn();
    }
  }

  sharedClick = (isOpen) => {
    this.setState({
      isSharedModal: isOpen,
      isSharedMapNameModal: isOpen,
      isSaveMapAsTemplateModal: isOpen,
      isChangeMapNameModal: isOpen,
      willCopyMapNameModal: isOpen
    });
  };

  callWhenModalClose = (status) => {
    this.setState({
      isUserMakePublicThisMapWithButton: status,
    });
  };

  shapeMenu() {
    document.getElementById("shapeDropdown").classList.toggle("toolbox-show");
    document
      .querySelector(".shape-wrapper")
      .classList.toggle("toolbox-item-border");

    let allWillClose = document.querySelectorAll(
      ".toolbox-item:not(.shape-wrapper)"
    );
    allWillClose.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(
      ".toolbox-content:not(#shapeDropdown)"
    );
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  changeShape() {
    var shapeSelect = document.querySelectorAll(".shapeSelectItem");
    [].forEach.call(shapeSelect, (el) => {
      el.addEventListener("click", function () {
        [].forEach.call(shapeSelect, (e) => {
          e.classList.remove("shape-select");
        });
        el.classList.toggle("shape-select");
        var dataShape = el.getAttribute("data-shape");
        var shape = MM.Shape.getById(dataShape);

        if (MM.App.current._dom.node.classList.contains("current")) {
          const item = MM.App.current;
          var action = new MM.Action.SetShape(MM.App.current, shape);
          MM.App.action(action);
        }
      });
    });
  }

  backgroundImageMenu() {
    document
      .getElementById("background-imageDropdown")
      .classList.toggle("toolbox-show");
    document
      .querySelector(".background-image-wrapper")
      .classList.toggle("toolbox-item-border");

    let allWillClose = document.querySelectorAll(
      ".toolbox-item:not(.background-image-wrapper)"
    );
    allWillClose.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(
      ".toolbox-content:not(#background-imageDropdown)"
    );
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  changeBackgroundImage() {
    var backgroundImageSelect = document.querySelectorAll(
      ".background-imageSelect"
    );
    [].forEach.call(backgroundImageSelect, (el) => {
      el.addEventListener("click", function () {
        [].forEach.call(backgroundImageSelect, (e) => {
          e.classList.remove("background-image-select");
        });
        el.classList.toggle("background-image-select");
        if (el.getAttribute("data-background-image")) {
          var urlImage = el.getAttribute("data-background-image");
          MM.App.map._setPageBgImage(urlImage);
          window.backgroundImage = "#f2f2f2  url(" + urlImage + ") no-repeat";
          document.querySelector("#port").style.background =
            window.backgroundImage;
          MM.UI.Backend.File.save();
        } else if (el.getAttribute("data-background-color")) {
          var urlColor = el.getAttribute("data-background-color");
          MM.App.map._setPageBgImage(urlColor);
          window.backgroundImage = urlColor;
          document.querySelector("#port").style.background =
            window.backgroundImage;
          MM.UI.Backend.File.save();
        }
      });
    });
  }

  iconMenu() {
    document.getElementById("iconDropdown").classList.toggle("toolbox-show");
    document
      .querySelector(".icon-wrapper")
      .classList.toggle("toolbox-item-border");

    let allWillClose = document.querySelectorAll(
      ".toolbox-item:not(.icon-wrapper)"
    );
    allWillClose.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(
      ".toolbox-content:not(#iconDropdown)"
    );
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  changeIcon() {
    var iconSelect = document.querySelectorAll(".iconSelect");
    [].forEach.call(iconSelect, (el) => {
      el.addEventListener("click", function () {
        [].forEach.call(iconSelect, (e) => {
          e.classList.remove("icon-select");
        });
        el.classList.toggle("icon-select");
        var dataIcon = el.getAttribute("data-icon");
        const item = MM.App.current;
        var action = new MM.Action.SetIcon(MM.App.current, dataIcon);
        MM.App.action(action);
      });
    });
  }

  removeIcon() {
    const iconSelect = document.querySelectorAll(".iconSelect");
    [].forEach.call(iconSelect, (el) => {
      el.addEventListener("click", function () {
        [].forEach.call(iconSelect, (e) => {
          e.classList.remove("icon-select");
        });
      });
    });
    const item = MM.App.current;
    const action = new MM.Action.RemoveIcon(item);
    MM.App.action(action);
    MM.Mouse._newToolboxSetReposition(item);
    document.getElementById("iconDropdown").classList.toggle("toolbox-show");
  }

  lineMenu() {
    document.getElementById("lineDropdown").classList.toggle("toolbox-show");
    document
      .querySelector(".line-wrapper")
      .classList.toggle("toolbox-item-border");

    let allWillClose = document.querySelectorAll(
      ".toolbox-item:not(.line-wrapper)"
    );
    allWillClose.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(
      ".toolbox-content:not(#lineDropdown)"
    );
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  lineChange() {
    var lineSelect = document.querySelectorAll(".lineSelect");
    [].forEach.call(lineSelect, (el) => {
      el.addEventListener("click", function () {
        [].forEach.call(lineSelect, (e) => {
          e.classList.remove("line-select");
        });
        el.classList.toggle("line-select");
        const dataLine = el.getAttribute("data-line");
        const line = MM.Line.getById(dataLine);
        const item = MM.App.current;
        const action = new MM.Action.SetLine(item, line);
        MM.App.action(action);
      });
    });
  }

  backgroundChange() {
    const backgroundSelect = document.querySelectorAll(".backgroundSelect");
    [].forEach.call(backgroundSelect, (el) => {
      el.addEventListener("click", function () {
        [].forEach.call(backgroundSelect, (e) => {
          e.classList.remove("background-select");
        });
        el.classList.toggle("background-select");
      });
    });
  }

  downloadMenu() {
    MM.App.hideNewToolbox();
    document.querySelector(".download-options").classList.toggle("show");
  }

  htmlToPdf = () => {
    var _this = this;
    var isDownloadableVal = _this.state.isDownloadable;
    if(isDownloadableVal === false){
      Utils.modalm().open({
        title: Resources.getValue("warningSendMailMsgTxt"),
        bodyContent:
          "<p>" +
          Resources.getValue("isNotDownloadMapMsgTxt") +
          "</p>",
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: "button yellow-button confirm-button",
            href: "",
          },
        ],
        confirmCallback: null,
        rejectCallback: null,
      });
      return
    }
    MM.App.map.center();
    Utils.loadingScreen("show");
    document.querySelector(".download-options").classList.remove("show");

    var leftVal, rightVal, topVal, bottomVal;
    var lefts = [];
    var tops = [];
    var rect, scrollLeft, scrollTop;
    document.querySelectorAll(".item").forEach((item) => {
      item.classList.remove("current");

      rect = item.getBoundingClientRect();
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      lefts.push(rect.left + scrollLeft);
      tops.push(rect.top + scrollTop);
    });

    leftVal = Math.min.apply(null, lefts);
    rightVal = Math.max.apply(null, lefts);
    topVal = Math.min.apply(null, tops);
    bottomVal = Math.max.apply(null, tops);
    var w = rightVal - leftVal;
    var h = bottomVal - topVal;

    setTimeout(() => {
      var projectName = localStorage.getItem("openedMapName") + ".jpg";
      // var mapTransform = document.querySelector(".root").style.transform;
      // document.querySelector(".tip").style.display = "none";
      document.querySelector(".toolbox").style.display = "none";
      document.querySelector(".zoom-in").style.display = "none";
      document.querySelector(".zoom-out").style.display = "none";
      document.querySelector(".zoom-ratio").style.display = "none";
      document.querySelector("._hj_feedback_container") &&
        (document.querySelector("._hj_feedback_container").style.display =
          "block");
      document.querySelector(".item.root").classList.remove("current");

      if (leftVal > 0 || leftVal < 0) {
        var val =
          parseFloat(
            document.querySelector(".root").style.left.split("px")[0]
          ) - leftVal;
        document.querySelector(".root").style.left = val + 10 + "px";
        if (leftVal < 0) {
          w = w + val / 2 + 5;
        }
      }
      if (topVal > 0 || topVal < 0) {
        var val =
          parseFloat(document.querySelector(".root").style.top.split("px")[0]) -
          topVal;
        document.querySelector(".root").style.top = val + 10 + "px";
        if (topVal < 0) {
          h = h + val / 2 + 5;
        }
      }

      w = w + document.querySelector(".root").offsetWidth;
      h = h + document.querySelector(".root").offsetHeight + (document.querySelector(".root").offsetHeight / 2);

      htmlToImage
        .toPng(document.getElementById("port"), {
          quality: 1,
          width: w,
          height: h,
        })
        .then(function (dataUrl) {
          var doc = new jsPDF({
            unit: "px",
            format: [
              // Number(document.getElementById('port').style.width.split('px')[0]),
              // Number(document.getElementById('port').style.height.split('px')[0])
              w,
              h,
            ],
            orientation: "landscape",
          });
          doc.addImage(dataUrl, "JPEG", 10, 10);
          doc.save(localStorage.getItem("openedMapName") + ".pdf");
          Utils.loadingScreen("hide");
          document.querySelector(".toolbox").style.display = "block";
          document.querySelector(".zoom-in").style.display = "inline-block";
          document.querySelector(".zoom-out").style.display = "inline-block";
          document.querySelector(".zoom-ratio").style.display = "inline-block";
          document.querySelector("._hj_feedback_container") &&
            (document.querySelector("._hj_feedback_container").style.display =
              "block");
          MM.App.map.center();
          document.querySelector(".item.root").classList.add("current");
        });
    }, 100);
  }

  htmlToImage = () =>  {
    var _this = this;
    var isDownloadableVal = _this.state.isDownloadable;
    if(isDownloadableVal === false){
      Utils.modalm().open({
        title: Resources.getValue("warningSendMailMsgTxt"),
        bodyContent:
          "<p>" +
          Resources.getValue("isNotDownloadMapMsgTxt") +
          "</p>",
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: "button yellow-button confirm-button",
            href: "",
          },
        ],
        confirmCallback: null,
        rejectCallback: null,
      });
      return
    }
    document.querySelectorAll(".root > canvas").forEach((item) => {
      item.style.zIndex = 0;
    });

    var mapTransform = document.querySelector(".root").style.transform;
    var leftVal, rightVal, topVal, bottomVal;
    var lefts = [];
    var tops = [];
    var rect, scrollLeft, scrollTop;

    document.querySelectorAll(".item").forEach((item) => {
      item.classList.remove("current");

      rect = item.getBoundingClientRect();
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      lefts.push(rect.left + scrollLeft);
      tops.push(rect.top + scrollTop);
    });

    leftVal = Math.min.apply(null, lefts);
    rightVal = Math.max.apply(null, lefts);
    topVal = Math.min.apply(null, tops);
    bottomVal = Math.max.apply(null, tops);
    var w = rightVal - leftVal;
    var h = bottomVal - topVal;

    document.querySelector(".download-options").classList.remove("show");

    setTimeout(() => {
      var projectName = localStorage.getItem("openedMapName") + ".jpg";
      // var mapTransform = document.querySelector(".root").style.transform;
      // document.querySelector(".tip").style.display = "none";
      document.querySelector(".toolbox").style.display = "none";
      document.querySelector(".zoom-in").style.display = "none";
      document.querySelector(".zoom-out").style.display = "none";
      document.querySelector(".zoom-ratio").style.display = "none";
      document.querySelector("._hj_feedback_container") &&
        (document.querySelector("._hj_feedback_container").style.display =
          "block");
      document.querySelector(".item.root").classList.remove("current");

      // MM.App.map.center();

      if (leftVal > 0 || leftVal < 0) {
        var val =
          parseFloat(
            document.querySelector(".root").style.left.split("px")[0]
          ) - leftVal;
        document.querySelector(".root").style.left = val + 10 + "px";
        if (leftVal < 0) {
          w = w + val / 2 + 5;
        }
      }
      if (topVal > 0 || topVal < 0) {
        var val =
          parseFloat(document.querySelector(".root").style.top.split("px")[0]) -
          topVal;
        document.querySelector(".root").style.top = val + 10 + "px";
        if (topVal < 0) {
          h = h + val / 2 + 5;
        }
      }

      w = w + document.querySelector(".root").offsetWidth;
      h = h + document.querySelector(".root").offsetHeight + (document.querySelector(".root").offsetHeight / 2);

      Utils.loadingScreen("show");
      htmlToImage
        .toPng(document.getElementById("port"), {
          quality: 1,
          width: w,
          height: h,
        })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = projectName;
          link.href = dataUrl;
          link.click();
          document.querySelector(".toolbox").style.display = "block";
          document.querySelector(".zoom-in").style.display = "inline-block";
          document.querySelector(".zoom-out").style.display = "inline-block";
          document.querySelector(".zoom-ratio").style.display = "inline-block";
          document.querySelector("._hj_feedback_container") &&
            (document.querySelector("._hj_feedback_container").style.display =
              "block");
          document.querySelector(".root").style.transform = mapTransform;
          Utils.loadingScreen("hide");
          MM.App.map.center();
          document.querySelector(".root").style.transform = mapTransform;
          document.querySelector(".item.root").classList.add("current");
          document.querySelectorAll(".root > canvas").forEach((item) => {
            item.style.zIndex = -15;
          });
        });
    }, 500);
  }

  changePageStatus() {
    Utils.modalm().close();
    localStorage.setItem("willGoToNewMapPage", false);
  }

  isOpenMapNameModal(scope) {
    Utils.modalm().close();
    localStorage.setItem("willGoToNewMapPage", true);
    scope.openMapNameChangeModal();
  }

  openNewMap() {
    Utils.modalm().open({
      exitButtonText: Resources.getValue("areyousureMsgTxt"),
      title: Resources.getValue("areyousureMsgTxt"),
      bodyContent: "<p>" + Resources.getValue("newMapCreateMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("noMsgTxt"),
          class: "button fresh-button reject-button",
          href: "",
        },
        {
          text: Resources.getValue("yesMsgTxt"),
          class: "button  yellow-button confirm-button",
          href: "",
        },
      ],
      confirmCallback: this.isOpenMapNameModal,
      rejectCallback: this.changePageStatus,
      scope: this,
    });
  }

  colorPickerBuild() {
    var colorList = JSON.parse(JSON.stringify(mapColors)).mapColors.reverse();

    var colorListTemplate = "";
    for (var i = 0; i < colorList.length; i++) {
      colorListTemplate +=
        '<li class="color-item" title="#' +
        colorList[i] + 
        '" data-color="#' +
        colorList[i] +
        '"  value="#' +
        colorList[i] +
        '"   style="background-color:#' +
        colorList[i] +
        '"></li>';
    }

    var picker = document.querySelectorAll(".color-list");
    document.querySelector(".color-picker-border").style.backgroundColor =
      "#4aa2fc";
    document.querySelector(".color-picker-text").style.color = "#ffffff";
    document.querySelector(".color-picker-text").style.backgroundColor =
      "#fecc80";

    //set node text color
    picker[0].innerHTML = colorListTemplate;
    var liList = picker[0].querySelectorAll("li");
    for (var j = 0; j < liList.length; j++) {
      liList[j].addEventListener("click", function () {
        document
          .querySelectorAll(
            '.text-toolbar > .color-list-wrapper-text .color-item'
          ).forEach((itm) => {
            itm.classList.remove("text-color-select");
          })
        var dataColor = this.getAttribute("data-color");
        document
          .querySelector(".color-picker-text")
          .setAttribute("color", dataColor);
        document
          .querySelector(".color-picker-text")
          .setAttribute("value", dataColor);
        document.querySelector(".text-toolbar").style.backgroundColor = dataColor;
        this.classList.add("text-color-select");
        document.querySelector(".color-picker-text").style.background =
          dataColor;
        MM.UI.TextColor.prototype.handleEvent();
      });
    }

    //set node bg color
    picker[1].innerHTML = colorListTemplate;
    var liList = picker[1].querySelectorAll("li");
    for (var j = 0; j < liList.length; j++) {
      liList[j].addEventListener("click", function () {
        document
        .querySelectorAll(
          '.background-toolbar > .color-list-wrapper-picker .color-item'
        ).forEach((itm) => {
          itm.classList.remove("bg-color-select");
        })
        var dataColor = this.getAttribute("data-color");
        document
          .querySelector(".color-picker")
          .setAttribute("value", dataColor);
        this.classList.add("bg-color-select");
        MM.UI.BgColor.prototype.handleEvent();
      });
    }

    //set node border color
    picker[2].innerHTML = colorListTemplate;
    var liList = picker[2].querySelectorAll("li");
    for (var j = 0; j < liList.length; j++) {
      liList[j].addEventListener("click", function () {
        document
          .querySelectorAll(
            '.border-toolbar > .color-list-wrapper-border .color-item'
          ).forEach((itm) => {
            itm.classList.remove("border-color-select");
          })
        var dataColor = this.getAttribute("data-color");
        document
          .querySelector(".color-picker-border")
          .setAttribute("background-color", dataColor);
        document
          .querySelector(".color-picker-border")
          .setAttribute("value", dataColor);
        this.classList.add("border-color-select");
        document.querySelector(".color-picker-border").style.backgroundColor =
          dataColor;

        MM.UI.Color.prototype.handleEvent();
      });
    }
  }

  colorPickerOpenClose(inputEl) {
    var picker = document.querySelector("#color-list-wrapper-picker");
    var border = document.querySelector("#color-list-wrapper-border");
    var text = document.querySelector("#color-list-wrapper-text");

    picker.classList.add("none");
    border.classList.add("none");
    text.classList.add("none");

    if (inputEl) {
      var el = inputEl.target.nextElementSibling;
      el.classList.toggle("none");

      if (el.classList == "color-list-wrapper-border") {
        document
          .querySelector(".border-toolbar")
          .classList.add("toolbox-item-border");
      } else if (el.classList == "color-list-wrapper-picker") {
        document
          .querySelector(".background-toolbar")
          .classList.add("toolbox-item-border");
      } else if (el.classList == "color-list-wrapper-text") {
        document
          .querySelector(".text-toolbar")
          .classList.add("toolbox-item-border");
      }
    }

    // when click port
    document.querySelector("#port").addEventListener("click", function () {
      var colorPicker =
        document.querySelector("#color-picker").nextElementSibling;
      var colorBorder = document.querySelector(
        "#color-picker-border"
      ).nextElementSibling;
      var colorText =
        document.querySelector("#color-picker-text").nextElementSibling;

      colorPicker.classList.add("none");
      colorBorder.classList.add("none");
      colorText.classList.add("none");

      let allItems = document.querySelectorAll(".toolbox-item");
      allItems.forEach(function (el) {
        el.classList.remove("toolbox-item-border");
      });

      let allWillCloseDropdown = document.querySelectorAll(".toolbox-content");
      allWillCloseDropdown.forEach(function (el) {
        el.classList.remove("toolbox-show");
      });

      document.querySelector(".download-options").classList.remove("show");

      // if map name input is active
      if (
        document.querySelector(".editor-header-tools") ===
        document.activeElement
      ) {
        document.querySelector(".editor-header-tools").blur();
      }
    });

    document
      .querySelector("#iconListActions")
      .addEventListener("click", function () {
        document
          .getElementById("iconDropdown")
          .classList.toggle("toolbox-show");
      });
  }

  backgroundMenu() {
    var picker = document.querySelector("#color-list-wrapper-picker");
    var border = document.querySelector("#color-list-wrapper-border");
    var text = document.querySelector("#color-list-wrapper-text");
    border.classList.add("none");
    text.classList.add("none");
    picker.classList.toggle("none");
    document
      .querySelector(".background-toolbar")
      .classList.toggle("toolbox-item-border");

    let allWillClose = document.querySelectorAll(
      ".toolbox-item:not(.background-toolbar)"
    );
    allWillClose.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(".toolbox-content");
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  borderdMenu() {
    var picker = document.querySelector("#color-list-wrapper-picker");
    var border = document.querySelector("#color-list-wrapper-border");
    var text = document.querySelector("#color-list-wrapper-text");
    picker.classList.add("none");
    text.classList.add("none");
    border.classList.toggle("none");
    document
      .querySelector(".border-toolbar")
      .classList.toggle("toolbox-item-border");

    let allWillClose = document.querySelectorAll(
      ".toolbox-item:not(.border-toolbar)"
    );
    allWillClose.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(".toolbox-content");
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  textdMenu() {
    var picker = document.querySelector("#color-list-wrapper-picker");
    var border = document.querySelector("#color-list-wrapper-border");
    var text = document.querySelector("#color-list-wrapper-text");
    picker.classList.add("none");
    border.classList.add("none");
    text.classList.toggle("none");
    document
      .querySelector(".text-toolbar")
      .classList.toggle("toolbox-item-border");

    let allWillClose = document.querySelectorAll(
      ".toolbox-item:not(.text-toolbar)"
    );
    allWillClose.forEach(function (el) {
      el.classList.remove("toolbox-item-border");
    });

    let allWillCloseDropdown = document.querySelectorAll(".toolbox-content");
    allWillCloseDropdown.forEach(function (el) {
      el.classList.remove("toolbox-show");
    });
  }

  drawMapName() {
    setTimeout(() => {
      document.querySelector(".editor-header-tools").value =
        localStorage.getItem("openedMapName");
    }, 100);
  }

  updateMapName() {
    var mapId =
      Utils.getParameterByName("mapId") || localStorage.getItem("openedMapId");
    var mapName = document.querySelector(".editor-header-tools").value;
    document.title = mapName;
    var updateName = "updateName";
    MapService.mapPageUpdateName(mapId, mapName, this, updateName);
  }

  selectsBlurFix() {
    document.querySelector("#port").addEventListener("click", function () { });
  }

  toolBoxMobileSwipe() {
    // mobile swipe
    if (window.innerWidth && window.innerWidth < 768) {
      // if is mobile
      Utils.swipe(
        document.querySelector(".toolbox .ui"),
        MM.Command.UI.execute
      );
    }
  }

  getMapInfo() {
    var userId = JSON.parse(localStorage.getItem("userInformation")).id;
    var mapId =
      Utils.getParameterByName("mapId") || localStorage.getItem("openedMapId");
    const permissionId = JSON.parse(
      localStorage.getItem("mapJsonObj")
    ).mapPermissionId;
    if (permissionId === 2 || permissionId === 3) {
      SharedMapService.getSharedMap(
        userId,
        mapId,
        this.drawOpenedSharedMap,
        this
      );
    } else {
      MapService.getMap(mapId, this.drawOpenedMap, this);
    }
  }

  drawOpenedMap() {
    const data = JSON.parse(this.response).mindMap;
    const mapData = JSON.parse(this.response).sharedMindMapMail;
    localStorage.setItem("mapJsonObj", JSON.stringify(data));
    var mapBackgroundImage = JSON.parse(this.response).mindMap.backgroundName;
    var UserIsOwner = JSON.parse(this.response).userIsOwner;
    localStorage.setItem("userIsOwner", UserIsOwner);

    if (data.isPublicMap === true) {
      document.querySelector('#public-private-btn > i').className = 'icon-lock-open-black';
    } else {
      document.querySelector('#public-private-btn > i').className = 'icon-lock-black';
    }

    if (mapData) {
      MM.UI.Backend._loadDone(JSON.parse(mapData));
      var container = document.querySelector(".item");
      MM.App.scrollZoom(container, 2, 0.5);
      MM.App.buttonZoom(container, 2, 0.5);
    }

    if (mapBackgroundImage) {
      document.querySelector("#port").style.background = mapBackgroundImage;
    }
  }

  drawOpenedSharedMap() {
    const data = JSON.parse(this.response).sharedMindMapMail;
    localStorage.setItem("mapJsonObj", JSON.stringify(data));
    var mapData = JSON.parse(this.response).sharedMindMapMail.content;
    var mapBackgroundImage = JSON.parse(this.response).sharedMindMapMail
      .backgroundName;
    var UserIsOwner = JSON.parse(this.response).userIsOwner;
    localStorage.setItem("userIsOwner", UserIsOwner);

    if (mapData) {
      MM.UI.Backend._loadDone(JSON.parse(mapData));
    }

    if (mapBackgroundImage) {
      document.querySelector("#port").style.background = mapBackgroundImage;
    }
  }

  addRemoveFavorite(mapId, isFavStatus) {
    const userId = JSON.parse(localStorage.getItem("userInformation")).id;
    localStorage.setItem("isNewMap", false);
    const favData = {
      mindMapId: mapId,
      userId: userId,
      isFavorite: isFavStatus,
    };
    MapService.AddRemoveFavorite(
      JSON.stringify(favData),
      this.favCallback,
      this
    );
    if (isFavStatus === true) {
      document.querySelector('#favori-btn').classList.add('active');
      document.querySelector('#favori-btn > i').className = 'icon-favorite-fill';
      document.querySelector('#favori-btn').setAttribute('title', Resources.getValue("removeFromFavMsgTxt"));
    } else {
      document.querySelector('#favori-btn').classList.remove('active');
      document.querySelector('#favori-btn > i').className = 'icon-favorite-border';
      document.querySelector('#favori-btn').setAttribute('title', Resources.getValue("addToFavMsgTxt"));
    }
  }

  favCallback() {
    var _this = this;
    _this.scope.getMapInfo();
  }

  makePublicPrivate(mapId, isPublicStatus) {
    localStorage.setItem("isNewMap", false);
    const data = {
      mindMapId: mapId,
      isPublicMap: isPublicStatus,
    };
    MapService.SetPublicOrPrivateMap(data, this.privatePublicCallback, this);

    if (isPublicStatus === true) {
      MM.App.hideNewToolbox();
      localStorage.setItem("isCustomModalOpen", true);
      this.setState({
        isSharedModal: JSON.parse(localStorage.getItem("isCustomModalOpen")),
        isUserMakePublicThisMapWithButton: true,
      });
      document.querySelector('#public-private-btn > i').className = 'icon-lock-open-black';
      document.querySelector('#public-private-btn').setAttribute('title', Resources.getValue("makePrivateMsgTxt"));
    } else {
      document.querySelector('#public-private-btn > i').className = 'icon-lock-black';
      document.querySelector('#public-private-btn').setAttribute('title', Resources.getValue("makePublicMsgTxt"));
    }
  }

  privatePublicCallback() {
    var _this = this;
    _this.scope.getMapInfo();
  }

  openMapNameChangeModal() {
    MM.App.hideNewToolbox();
    var mapOwner = JSON.parse(localStorage.getItem("userIsOwner"));
    if (
      localStorage.getItem("openedMapName") === "yeni" &&
      localStorage.getItem("blankMapContent") !==
      localStorage.getItem("content")
    ) {
      // popup ac
      localStorage.setItem("isCustomModalOpen", true);
      this.setState({
        isChangeMapNameModal: JSON.parse(
          localStorage.getItem("isCustomModalOpen")
        ),
      });
    } else {
      // yonlendir
      if (JSON.parse(localStorage.getItem("willGoToNewMapPage")) === true) {
        window.location.href =
          Resources.getValue("appBaseEnvURL") + "/template-list";
      } else {
        mapOwner
          ? (window.location.href =
            Resources.getValue("appBaseEnvURL") + "/mind-map-list")
          : (window.location.href =
            Resources.getValue("appBaseEnvURL") + "/mind-map-share-list");
      }
    }
  }

  undoChangesFunc() {
    MM.App.hideNewToolbox();
    if (!MM.App.history.length || MM.App.historyIndex <= 0) {
      document.querySelector("#undo-changes-btn").setAttribute("disabled", "");
    } else {
      document.querySelector("#undo-changes-btn").removeAttribute("disabled");
      MM.Command.Undo.execute();
    }
  }
  redoChangesFunc() {
    MM.App.hideNewToolbox();
    if (MM.App.history.length > 0 && MM.App.isRedoAvailable === true) {
      document.querySelector("#redo-changes-btn").removeAttribute("disabled");
      MM.Command.Redo.execute();
    } else {
      document.querySelector("#redo-changes-btn").setAttribute("disabled", "");
    }
  }

  isThereChangesForUndo() {
    if (!MM.App.history.length || MM.App.historyIndex <= 0) {
      document.querySelector("#undo-changes-btn").setAttribute("disabled", "");
    } else {
      document.querySelector("#undo-changes-btn").removeAttribute("disabled");
    }
  }
  isThereChangesForRedo() {
    if (MM.App.history.length > 0 && MM.App.isRedoAvailable === true) {
      document.querySelector("#redo-changes-btn").removeAttribute("disabled");
    } else {
      document.querySelector("#redo-changes-btn").setAttribute("disabled", "");
    }
  }

  getSelectedItemFormat() {
    MM.App.hideNewToolbox();
    if (MM.App.current !== null) {
      MM.App.current.getItemFormat();
      document.querySelector('.format-painter-btn').classList.add('active');
    } else {
      Utils.modalm().open({
        title: Resources.getValue("warningSendMailMsgTxt"),
        bodyContent:
          "<p>" +
          Resources.getValue("formatPainterNoItemSelectedMsgTxt") +
          "</p>",
        buttons: [
          {
            text: Resources.getValue("okMsgTxt"),
            class: "button yellow-button confirm-button",
            href: "",
          },
        ],
        confirmCallback: null,
        rejectCallback: null,
      });
      return false;
    }
  }


  fitMapToScreen() {
    MM.App.map.center();

    const posObj = {
      scale192: { val: 1.92, text: '100%' },
      scale184: { val: 1.84, text: '96%' },
      scale176: { val: 1.76, text: '92%' },
      scale168: { val: 1.68, text: '88%' },
      scale160: { val: 1.60, text: '84%' },
      scale152: { val: 1.52, text: '80%' },
      scale144: { val: 1.44, text: '76%' },
      scale136: { val: 1.36, text: '72%' },
      scale128: { val: 1.28, text: '68%' },
      scale120: { val: 1.20, text: '64%' },
      scale112: { val: 1.12, text: '60%' },
      scale104: { val: 1.04, text: '56%' },
      scale096: { val: 0.96, text: '52%' },
      scale088: { val: 0.88, text: '48%' },
      scale080: { val: 0.80, text: '44%' },
      scale072: { val: 0.72, text: '40%' },
      scale064: { val: 0.64, text: '36%' },
      scale056: { val: 0.56, text: '32%' },
      scale048: { val: 0.48, text: '28%' },
      scale040: { val: 0.40, text: '24%' },
      scale032: { val: 0.32, text: '20%' },
      scale024: { val: 0.24, text: '16%' },
      scale016: { val: 0.16, text: '12%' },
      scale008: { val: 0.08, text: '8%' },
    };

    let scale = parseFloat(JSON.parse(localStorage.getItem("zoomMap")));
    recursiveScale(scale);

    function recursiveScale(scaleCurrent) {
      var leftVal, rightVal, topVal, bottomVal;
      var lefts = [];
      var tops = [];
      var rect, scrollLeft, scrollTop;

    document.querySelectorAll(".item").forEach((item) => {
      item.classList.remove("current");
      rect = item.getBoundingClientRect();
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      lefts.push(rect.left + scrollLeft);
      tops.push(rect.top + scrollTop);
    });

    leftVal = Math.min.apply(null, lefts);
    rightVal = Math.max.apply(null, lefts);
    topVal = Math.min.apply(null, tops);
    bottomVal = Math.max.apply(null, tops);
      if(
        topVal < 50 || bottomVal >= window.innerHeight - ((window.innerHeight/100)*9) || leftVal < 50 || rightVal >= window.innerWidth - ((window.innerWidth/100)*9)
      ) {
        // yukardan/asagidan sigmiyor || sagdan/soldan sigamiyor
        document.getElementById('fit-map') && document.getElementById('fit-map').setAttribute('disabled', false);
        let scale = scaleCurrent;
        let newScale = (scale >= 0.24) && (scale - 0.08).toFixed(2);
        if(newScale > 0.0) {
          localStorage.setItem("zoomMap", parseFloat(newScale).toFixed(2));
          let toFixedScaleText =
            "scale" + newScale.split(".")[0] + newScale.split(".")[1];
          document.querySelector(".zoom-ratio").innerHTML =
            posObj[toFixedScaleText].text;
          MM.Map.prototype._setScale(newScale);
          recursiveScale(newScale);
        }
      } else {
        document.getElementById('fit-map') && document.getElementById('fit-map').setAttribute('disabled', true);
        return;
      }
      MM.App.map.center();
    }
    
    document.querySelector(".root").classList.add('current');
  }

  // Preset - Sunum Fonksiyonlari
  presentListItemControl() {
    this.state.mapPresentDatas.forEach(function (data, ind) {

      document.querySelector(".item[data-id='" + data.nodeId + "'] .select-item-for-present") &&
       document.querySelector(".item[data-id='" + data.nodeId + "'] .select-item-for-present").classList.add('none');
    });
  }

  handler() { 
    if(window.newItemAdded === true) {
      this.setState({
        mapPresentDatas: MM.App.selectedPresentItems
      });
      window.newItemAdded = false;
    }
  }

  // dnd list drag end func
  handleOnDragEnd(result) {
    if (!result.destination) return;

    if (result.destination.index === result.source.index) {
      return;
    }
    // const items = this.state.mapPresentDatas;
    const items = MM.App.selectedPresentItems;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    this.setState({
      mapPresentDatas: items,
      changePresentItemSavedControl: true
    })
    localStorage.setItem("mapPresentDatasStore", JSON.stringify(items));
  }

  controlVariable = () => {
    this.setState({
      mapPresentDatas: MM.App.selectedPresentItems
    }, () => console.log(this.state));
  }

	getMapPresentItemListCallback() {
		var presentDataResponse = JSON.parse(this.response);
		if (presentDataResponse) {
			this.scope.setState({
				mapPresentDatas: presentDataResponse.data.presentationNodes
			}
      // , () => {
      //   this.state.mapPresentDatas.forEach(function (data) {
      //     if(document.querySelector(".item[data-id='" + data.nodeId + "'] .select-item-for-present")) {
      //       document.querySelector(".item[data-id='" + data.nodeId + "'] .select-item-for-present").classList.add('none');
      //     }
      //   });
      // }
      );
      localStorage.setItem("mapPresentDatasStore", JSON.stringify(presentDataResponse.data.presentationNodes));
		}
    if(presentDataResponse.data.presentationNodes.length > 0) {
      document.querySelector('.start-present').classList.remove('none');
    } else {
      document.querySelector('.start-present').classList.add('none');
    }
	}

  getAllPresentItemsFromApi() {
    //to get present item data from db
    var _this = this;
		MapService.getMapPresentItemList(
      localStorage.getItem("openedMapId"), 
      this.getMapPresentItemListCallback, 
      _this
    );

    this.state.mapPresentDatas.forEach(function (data) {
      if(document.querySelector(".item[data-id='" + data.nodeId + "'] .select-item-for-present")) {
        document.querySelector(".item[data-id='" + data.nodeId + "'] .select-item-for-present").classList.add('none');
      }
    });

      if(document.querySelector('.start-present') && this.state.mapPresentDatas.length > 0) {
        document.querySelector('.start-present').classList.remove('none');
      } else {
        document.querySelector('.start-present').classList.add('none');
      }
    }
  getIsDownloadableMap(DownloadType) {
    var _this =this;
    _this.setState({
				downloadType: DownloadType,
			},()=>{
        var mapId = Utils.getParameterByName("mapId") || localStorage.getItem("openedMapId");
        MapService.getIsDownloadableMap(mapId,this.getMapIsDownloadableCallback, _this);
      });
      
  }
  getMapIsDownloadableCallback() {
    var isDownloadableResault = JSON.parse(this.response);
		if (isDownloadableResault) {
			this.scope.setState({
				isDownloadable: isDownloadableResault.isSuccess
			});
		}
    if(this.scope.state.downloadType === "htmlToImage"){
      this.scope.htmlToImage();
    }
    else if (this.scope.state.downloadType === "htmlToPdf") {
      this.scope.htmlToPdf();
    }
	}
  savePresentDatas = () => {
    var mapId = Utils.getParameterByName("mapId") || localStorage.getItem("openedMapId");
    const savePresentData = {
      mindMapId: mapId,
      presentationNodes :this.state.mapPresentDatas
    };
    let message = Resources.getValue("updateSuccessPresentMsgTxt");
    MapService.CreateMindMapPresentation(JSON.stringify(savePresentData),message);

    localStorage.setItem("changePresentItemSavedControlStore", false);
    this.setState({
      changePresentItemSavedControl: false
    });
  }

  removePresentItemFromList(ind) {
    if (ind !== -1) {
      this.state.mapPresentDatas.splice(ind, 1);
    }
    this.setState({
      mapPresentDatas: this.state.mapPresentDatas,
      changePresentItemSavedControl: true
    })
    localStorage.setItem("mapPresentDatasStore", JSON.stringify(this.state.mapPresentDatas));
    document.querySelectorAll(".item").forEach(function (itm, ind) {
      itm.querySelector(".select-item-for-present").classList.remove('none');
    });
    this.state.mapPresentDatas.forEach(function (data, ind) {
      document.querySelector(".item .select-item-for-present").classList.remove('none');
      document.querySelector(".item[data-id='" + data.nodeId + "'] .select-item-for-present").classList.add('none');
    });

    // start present button hide control
    if(document.querySelector('.start-present') && this.state.mapPresentDatas.length > 0) {
      document.querySelector('.start-present').classList.remove('none');
    } else {
      document.querySelector('.start-present').classList.add('none');
    }
  }

  showBeforePresentStartClick() {
    this.setState({
      presentMode: false
    });

    localStorage.setItem("beforePresent", true);
    localStorage.setItem("changePresentItemSavedControlStore", false);
    document.getElementById('present-items-menu').classList.add('visible');

    document.querySelector('.before-present-start').classList.add('none');
    document.querySelector('.before-present-cancel').classList.remove('none');
    // document.querySelector('.start-present').classList.remove('none');
    document.querySelector('.present-arrows').classList.add('none');

    document.querySelector(".toolbox").style.display = "none";
    document.querySelector(".zoom-in").style.display = "none";
    document.querySelector(".zoom-out").style.display = "none";
    document.querySelector(".zoom-ratio").style.display = "none";
    document.querySelector("._hj_feedback_container") &&
      (document.querySelector("._hj_feedback_container").style.display =
        "none");

    document.querySelectorAll(".item").forEach(function (el, ind) {
      el.classList.remove("current");
      el.querySelector('.options').classList.add('none');
      el.querySelector('.select-item-for-present').classList.remove('none');
    });

    // start present button hide control
    if(this.state.mapPresentDatas.length > 0) {
      document.querySelector('.start-present').classList.remove('none');
    } else {
      document.querySelector('.start-present').classList.add('none');
    }
    
      // silinen item var mi diye kontrol
      let currentPresentDatas = this.state.mapPresentDatas;
      var removeItem = false;

      currentPresentDatas.forEach((el) => {
        if (!document.querySelector('[data-id="' + el.nodeId  + '"]')) {
          removeItem=true;
          currentPresentDatas = currentPresentDatas.filter(x => {
            return x.nodeId != el.nodeId;
          })
        }
  
      })
      if(removeItem){
        currentPresentDatas = currentPresentDatas;
        var mapId = Utils.getParameterByName("mapId") || localStorage.getItem("openedMapId");
      const savePresentData = {
        mindMapId: mapId,
        presentationNodes :currentPresentDatas
      };
      let message = Resources.getValue("deleteMapItemUpdatePresentMsgTxt");
      MapService.CreateMindMapPresentation(JSON.stringify(savePresentData),message);

      }
  
      localStorage.setItem("mapPresentDatasStore", JSON.stringify(currentPresentDatas));
      MM.App.selectedPresentItems = currentPresentDatas;
      window.newItemAdded = true;
      document.getElementById("refresh-list-btn").click(); // to refresh left list
  

    // added item control
    this.presentListItemControl();
  }
  changePresentNotSavedWarningModal(){
    Utils.modalm().open({
      exitButtonText: Resources.getValue("exitMsgTxt"),
      title: Resources.getValue("changePresentNotSavedWarningMsgTxt"),
      bodyContent:
        "<p>" + Resources.getValue("changePresentNotSavedWarningQuestionMsgTxt") + "</p>",
      buttons: [
        {
          text: Resources.getValue("noMsgTxt"),
          class: "button fresh-button reject-button",
          href: "",
        },
        {
          text: Resources.getValue("yesMsgTxt"),
          class: "button yellow-button confirm-button",
          href: "",
        },
      ],
      confirmCallback: this.savePresentDatas,
      rejectCallback: null,
    });
  }
  showBeforePresentCancelClick() {
  this.exitPageFullScreenMode();
    if(this.state.changePresentItemSavedControl === true 
       || localStorage.getItem("changePresentItemSavedControlStore") === "true" 
    ){
      this.changePresentNotSavedWarningModal();
    }
    this.setState({
      presentMode: false,
    });

    localStorage.setItem("isPresentModeActive", false);
    localStorage.setItem("beforePresent", false);

    if(JSON.parse(localStorage.getItem("isPresentModeActive")) == false) {
      document.removeEventListener('keydown', (e) => {
        e = e || window.event; 
        if (
          e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40 ||
          e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'ArrowLeft' ||
          e.keyCode === 33 || e.keyCode === 34 // PgUp - PgDown
        ) {
          return false;
        }
      })
    }

    document.getElementById('present-items-menu').classList.remove('visible');

    document.querySelector('.before-present-start').classList.remove('none');
    document.querySelector('.before-present-cancel').classList.add('none');
    // document.querySelector('.start-present').classList.remove('none');
    document.querySelector('.start-present-info').classList.add('none');
    document.querySelector('.present-arrows').classList.add('none');

    document.querySelector(".toolbox").style.display = "block";
    document.querySelector(".zoom-in").style.display = "inline-block";
    document.querySelector(".zoom-out").style.display = "inline-block";
    document.querySelector(".zoom-ratio").style.display = "inline-block";
    document.querySelector("._hj_feedback_container") &&
      (document.querySelector("._hj_feedback_container").style.display =
        "block");

    document.querySelectorAll(".item").forEach(function (el) {
      el.classList.remove("current");
      el.querySelector('.options').classList.remove('none');
      el.querySelector('.select-item-for-present').classList.add('none');
      el.querySelector('.img-buttons').classList.remove('none');
      // el.querySelector('.select-item-id-text').innerHTML = 'ID: ' + el.dataset.presentorder;
    });
    document.querySelector(".root").classList.add("current");

    let currentZoom = parseFloat(localStorage.getItem("zoomMap"));
    // document.querySelector(".root").style.transform = "scale(" + currentZoom + "," + currentZoom + ")";
    localStorage.setItem("zoomMap", 1.04)
    document.querySelector(".root").style.transform = "scale(" + 1.04 + "," + 1.04 + ")";
    document.querySelector(".zoom-ratio").innerHTML = '56%';
    MM.App.map.center();

    document.querySelector(".root").classList.remove("present-mode-root"); // for transition effect

    if(this.state.mapPresentDatas.length > 0) {
      document.querySelector('.start-present').classList.remove('none');
    } else {
      document.querySelector('.start-present').classList.add('none');
    }
  }

  changePresentItemName(e, id) {
    // item adi guncellenince present listesinden de o item'in guncellenmesi icin kontrol
    let currentPresentDatas = MM.App.selectedPresentItems;
    for( var i = 0; i < currentPresentDatas.length; i++){ 
      if (currentPresentDatas[i].nodeId === id) { 
        currentPresentDatas[i]['nodeText'] = e.target.value;
      }
    }
    localStorage.setItem("mapPresentDatasStore", JSON.stringify(currentPresentDatas));
    MM.App.selectedPresentItems = currentPresentDatas;
    this.setState({
      mapPresentDatas: currentPresentDatas,
      changePresentItemSavedControl: true
    })
    window.newItemAdded = true;
    document.getElementById("refresh-list-btn").click(); // to refresh left list
  }

  startPresentClick() {
    // start fullscreen mode
     this.startPageFullScreenMode();
     setTimeout(() => {
    this.setState({
      presentMode: true,
      presentCurrentItemIndex: 0
    });
  
    localStorage.setItem("isPresentModeActive", true);
    localStorage.setItem("beforePresent", false);

     // Use arrows for next and prev
     if(JSON.parse(localStorage.getItem("isPresentModeActive")) == true) {
      // document.addEventListener('keydown', (e) => {
      //   e = e || window.event; 
      // if (e.keyCode === 37 || e.keyCode === 40) {
      //   // left/down arrow pressed
      //   this.presentItemPrevClick();
      // } else if (e.keyCode === 39 || e.keyCode === 38) {
      //   // right/up arrow pressed
      //   this.presentItemNextClick();
      // }
      // })
    }

    document.getElementById('present-items-menu').classList.remove('visible');

    document.querySelector('.before-present-start').classList.add('none');
    document.querySelector('.before-present-cancel').classList.remove('none');
    document.querySelector('.start-present').classList.add('none');
    document.querySelector('.present-arrows').classList.remove('none');

    document.querySelector(".toolbox").style.display = "none";
    document.querySelector(".zoom-in").style.display = "none";
    document.querySelector(".zoom-out").style.display = "none";
    document.querySelector(".zoom-ratio").style.display = "none";
    document.querySelector("._hj_feedback_container") &&
      (document.querySelector("._hj_feedback_container").style.display =
        "none");

    document.querySelectorAll(".item").forEach(function (el) {
      el.classList.remove("current");
      el.querySelector('.options').classList.add('none');
      el.querySelector('.select-item-for-present').classList.add('none');
      el.querySelector('.img-buttons').classList.add('none');
    });

    // Present Mode

    document.querySelector(".root").style.transform = "scale(2.2, 2.2)";
    
    document.querySelector(".root").classList.add("present-mode-root"); // for transition effect
    
    this.presentItemPositonControls(0);

    // arrow buttons control for present start
    document.querySelector(".before-present-prev").setAttribute("disabled", "");
    if (this.state.mapPresentDatas.length === 1) {
      document.querySelector(".before-present-next").setAttribute("disabled", "");
    } else if (this.state.mapPresentDatas.length > 1) {
      document.querySelector(".before-present-next").removeAttribute("disabled");
    }
  }, 100);
  }

  presentItemPositonControls(itemNodeId) {
    document.querySelectorAll(".item").forEach(function (el) {
      el.classList.remove("current");
    });
    // document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]').classList.add('current'); // aktif olan animasyonlu olmasin istendi

    // left & top of root
    let rectRoot = document.querySelector('.root').getBoundingClientRect();
    // var doc = document.documentElement;
    // var scrollLeftRoot = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    // var scrollTopRoot = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    let scrollLeftRoot = window.pageXOffset || document.documentElement.scrollLeft;
    let scrollTopRoot = window.pageYOffset || document.documentElement.scrollTop;
    let leftRoot = rectRoot.left + scrollLeftRoot;
    let topRoot = rectRoot.top + scrollTopRoot;

    // left & top of first item
    let rect = document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]').getBoundingClientRect();
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let left = rect.left + scrollLeft;
    let top = rect.top + scrollTop;

    if(
      document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]')
        .classList.contains('root') === true
    ) { 
      // ilk sunum elemani root ise
      MM.App.map.center();
      let rootLeftSplit = parseFloat(document.querySelector('.root').style.left.split('px')[0]);
      let itemWidth = parseFloat(document.querySelector('.root').offsetWidth);
      let rootTopSplit = parseFloat(document.querySelector('.root').style.top.split('px')[0]);
      let itemHeight = parseFloat(document.querySelector('.root').offsetHeight);
      // document.querySelector('.root').style.left = rootLeftSplit - (itemWidth) + 'px'; 
      // document.querySelector('.root').style.top = rootTopSplit - (itemHeight) + 'px'; 
      document.querySelector('.root').style.left = (window.innerWidth / 2) - (itemWidth / 2) + 'px'; 
      document.querySelector('.root').style.top = (window.innerHeight / 2) - (itemHeight / 2) + 'px'; 
    } else {
      MM.App.map.center();
      if(
        document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]')
        .dataset.childDirection === 'right'
      ) {
        let leftDistance = left - leftRoot;
        // let leftDistance =  document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]').offsetLeft - document.querySelector('.root').offsetLeft;
        let rootLeftSplit = parseFloat(document.querySelector('.root').style.left.split('px')[0]);
        let itemWidth = parseFloat(document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]').offsetWidth);
        document.querySelector('.root').style.left = (window.innerWidth / 2) - leftDistance - (itemWidth / 2) + 'px'; 
        if(
          top < topRoot
        ) {
          // rootun top degerinden buyukse
          let topDistance = topRoot - top;
          let rootTopSplit = parseFloat(document.querySelector('.root').style.top.split('px')[0]);
          let itemHeight = parseFloat(document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]').offsetHeight);
          document.querySelector('.root').style.top = (window.innerHeight / 2) + topDistance - (itemHeight / 2) + 'px';
        } else {
          let topDistance = top - topRoot;
          let rootTopSplit = parseFloat(document.querySelector('.root').style.top.split('px')[0]);
          let itemHeight = parseFloat(document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]').offsetHeight);
          document.querySelector('.root').style.top = (window.innerHeight / 2) - topDistance - (itemHeight / 2) + 'px';
        }
      } else {
        let leftDistance = leftRoot - left;
        let rootLeftSplit = parseFloat(document.querySelector('.root').style.left.split('px')[0]);
        let itemWidth = parseFloat(document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]').offsetWidth);
        document.querySelector('.root').style.left = (window.innerWidth / 2) + leftDistance - (itemWidth / 2) + 'px';
        if(
          top < topRoot
        ) {
          // rootun top degerinden buyukse
          let topDistance = topRoot - top;
          let rootTopSplit = parseFloat(document.querySelector('.root').style.top.split('px')[0]);
          let itemHeight = parseFloat(document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]').offsetHeight);
          document.querySelector('.root').style.top = (window.innerHeight / 2) + topDistance - (itemHeight / 2) + 'px';
        } else {
          let topDistance = top - topRoot;
          let rootTopSplit = parseFloat(document.querySelector('.root').style.top.split('px')[0]);
          let itemHeight = parseFloat(document.querySelector('[data-id="' + this.state.mapPresentDatas[itemNodeId].nodeId  + '"]').offsetHeight);
          document.querySelector('.root').style.top = (window.innerHeight / 2) - topDistance - (itemHeight / 2) + 'px';
        }
      }
    }
  }

  presentItemPrevClick() {
    if(JSON.parse(localStorage.getItem("isPresentModeActive")) == true) {
      let newIndex = 
        (this.state.presentCurrentItemIndex === 0) 
        ? 0 
        : this.state.presentCurrentItemIndex - 1;
      this.setState({
        presentCurrentItemIndex: newIndex
      }, () => {

        this.presentItemPositonControls(this.state.presentCurrentItemIndex);

        // arrow buttons control for present start [for prev]
        if(this.state.presentCurrentItemIndex >= 1) {
          document.querySelector(".before-present-prev").removeAttribute("disabled");
        } else {
          document.querySelector(".before-present-prev").setAttribute("disabled", "");
        }
        if (this.state.mapPresentDatas.length === 1) {
          document.querySelector(".before-present-next").setAttribute("disabled", "");
        } else if ((this.state.mapPresentDatas.length - 1) > this.state.presentCurrentItemIndex) {
          document.querySelector(".before-present-next").removeAttribute("disabled");
        }
      });
    }
  }

  presentItemNextClick() {
    if(JSON.parse(localStorage.getItem("isPresentModeActive")) == true) {
      let newIndex = 
        (this.state.presentCurrentItemIndex >= (this.state.mapPresentDatas.length - 1)) 
        ? (this.state.mapPresentDatas.length - 1) 
        : this.state.presentCurrentItemIndex + 1;
      this.setState({
        presentCurrentItemIndex: newIndex
      }, () => {

        if(this.state.presentCurrentItemIndex > (this.state.mapPresentDatas.length - 1)) {
          return false;
        }

        this.presentItemPositonControls(this.state.presentCurrentItemIndex);

        // arrow buttons control for present start [for next]
        if(this.state.presentCurrentItemIndex >= 1) {
          document.querySelector(".before-present-prev").removeAttribute("disabled");
        } else {
          document.querySelector(".before-present-prev").setAttribute("disabled", "");
        }
        if (this.state.mapPresentDatas.length === 1) {
          document.querySelector(".before-present-next").setAttribute("disabled", "");
        } else if ((this.state.mapPresentDatas.length - 1) > this.state.presentCurrentItemIndex) {
          document.querySelector(".before-present-next").removeAttribute("disabled");
        } else {
          document.querySelector(".before-present-next").setAttribute("disabled", "");
        }
      });
    }
  }

  startPageFullScreenMode() {
    // if (!document.fullscreenElement) {
    //   document.documentElement.requestFullscreen();
    // }
    var elem = document.body;
    if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
      if (elem.requestFullScreen) {
          elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
      }
  } else {
      if (document.cancelFullScreen) {
          document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
      }
  }
  }

  exitPageFullScreenMode() {
    // if (!!document.fullscreenElement) {
    //   if (document.exitFullscreen) {
    //     document.exitFullscreen();
    //   }
    // }
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
  } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
  }
  }

  // control for duplicate items in currentItemOrders
  currentItemOrdersDuplicateControl() {
    let result;
    for (let i = 0; i < this.state.currentItemsOrders.length; i++) {
      // nested loop
      for (let j = 0; j < this.state.currentItemsOrders.length; j++) {
        // do not compare same elements
        if (i !== j) {
          // check if elements match
          if (this.state.currentItemsOrders[i] === this.state.currentItemsOrders[j]) {
            // duplicate element found
            result = true;
            // terminate inner loop
            break;
          }
        }
      }
      // terminate outer loop
      if (result) {
        break;
      }
    }
    if (result) {
      // console.log('tekrarliyor');
      return true;
    } else {
      // console.log('tekrarlamiyor');
      return false;
    }
  }

  render() {
    var _this = this;

    // selected node's icon > to select value
    MM.UI.Icon.prototype.update = function () {
      var selectedNodeIcon = MM.App.current.getIcon();
      if (selectedNodeIcon) {
        _this.setState({
          iconSelectVal: {
            value: selectedNodeIcon,
            label: selectedNodeIcon.replace(/-/g, " "),
          },
        });
      } else {
        _this.setState({
          iconSelectVal: null,
        });
      }
    };

    // selected node's shape > to select value
    MM.UI.Shape.prototype.update = function () {
      var selectedNodeShape = MM.App.current.getOwnShape();
      if (selectedNodeShape && selectedNodeShape.id) {
        _this.setState({
          shapeSelectVal: {
            value: selectedNodeShape.id,
            label: selectedNodeShape.label,
          },
        });
      } else {
        _this.setState({
          shapeSelectVal: _this.shapeSelectDefault,
        });
      }
    };

    // selected node's line > to select value
    MM.UI.Line.prototype.update = function () {
      var selectedNodeLine = MM.App.current.getOwnLine();
      if (selectedNodeLine && selectedNodeLine.id) {
        _this.setState({
          lineSelectVal: {
            value: selectedNodeLine.id,
            label: selectedNodeLine.label,
          },
        });
      } else {
        _this.setState({
          lineSelectVal: _this.lineSelectDefault,
        });
      }
    };

    // selected node's shape size > to select value
    MM.UI.ShapeSize.prototype.update = function () {
      var selectedNodeShapeSize = MM.App.current.getOwnShapeSize();
      if (selectedNodeShapeSize && selectedNodeShapeSize.id) {
        _this.setState({
          shapeSizeSelectVal: {
            value: selectedNodeShapeSize.id,
            label: selectedNodeShapeSize.label,
          },
        });
      } else {
        _this.setState({
          shapeSizeSelectVal: _this.shapeSizeSelectDefault,
        });
      }
    };

    // selected node's font > to select value
    MM.UI.Font.prototype.update = function () {
      var selectedNodeFont = MM.App.current.getOwnFont();
      if (selectedNodeFont && selectedNodeFont.id) {
        _this.setState({
          fontSelectVal: {
            value: selectedNodeFont.id,
            label: selectedNodeFont.label,
          },
        });
      } else {
        _this.setState({
          fontSelectVal: _this.fontSelectDefault,
        });
      }
    };

    // selected node's font > to select value
    MM.UI.FontSize.prototype.update = function () {
      var selectedNodeFontSize = MM.App.current.getOwnFontSize();
      if (selectedNodeFontSize && selectedNodeFontSize.id) {
        _this.setState({
          fontSizeSelectVal: {
            value: selectedNodeFontSize.id,
            label: selectedNodeFontSize.label,
          },
        });
      } else {
        _this.setState({
          fontSizeSelectVal: _this.fontSizeSelectDefault,
        });
      }
    };

    // shape size select build
    MM.UI._shapeSize = (function () {
      var shapeSizeOptions = [
        { label: Resources.getValue("autoMsgTxt"), value: "auto" },
        { label: Resources.getValue("smallMsgTxt"), value: "small" },
        { label: Resources.getValue("mediumMsgTxt"), value: "medium" },
        { label: Resources.getValue("largeMsgTxt"), value: "large" },
        { label: Resources.getValue("largestMsgTxt"), value: "largest" },
      ];

      return {
        shapeSizeOptions: shapeSizeOptions,
      };
    })();

    // select's disable status.
    MM.Item.prototype.decideLineStatus = function () {
      if (this.isRoot()) {
        _this.setState({
          lineSelectDisableStatus: true,
          lineSelectIsOpen: false,
        });
      } else {
        _this.setState({
          lineSelectDisableStatus: false,
          lineSelectIsOpen: undefined,
        });
      }
    };

    // icon select build
    // var iconSelectBuild = (function () {
    //   var iconOptions = [];
    //   var iconOptionTags = IconList().split("</option>");
    //   for (var i = 0; i < iconOptionTags.length; i++) {
    //     var option = {
    //       value: iconOptionTags[i]
    //         .replace("<option value='", "")
    //         .split("'>")[0]
    //         .trim(),
    //       label: iconOptionTags[i].split("; ")[1]
    //     };

    //     if (option.value && option.label) {
    //       option.label = option.label.replace(/-/g, " ");
    //       iconOptions.push(option);
    //     }
    //   }

    //   var customIconOptions = ({ value, label }) => (
    //     <div className="custom-option">
    //       <i className={value + " fa custom-option-icon"}></i>
    //       <div className="custom-option-label">{label}</div>
    //     </div>
    //   );
    //   return {
    //     iconOptions: iconOptions,
    //     customIconOptions: customIconOptions
    //   };
    // })();

    document.title = localStorage.getItem("openedMapName");

    return (
      <>
        <div className="start-present-info none">
          <i className="fa fa-info-circle mr-2"></i>
          Sunuma baslamadan once kirmizi kutulardan siralamayi degistirebilirsiniz.
        </div>
        {/* Present buttons (sadece harita sahibi[0] gorebilir) */}
        {(
          JSON.parse(localStorage.getItem("mapPermission")) === 0 &&
          window.location.pathname.includes("public-share-link") === false
          ) && (
            <div className="present-buttons">
              <div
                className="show-before-present start-present mr-2"
                title={Resources.getValue("presentStartBtnTxt")}
                onClick={this.startPresentClick}
              >
                <i className="fa fa-play-circle" aria-hidden="true"></i>
              </div>
              <div
                className="show-before-present before-present-start"
                title={Resources.getValue("presentBtnTxt")}
                onClick={this.showBeforePresentStartClick}
              >
                <i className="fa fa-desktop" aria-hidden="true"></i>
              </div>
              
              <div className="present-arrows none">
                <a
                  className="show-before-present before-present-prev"
                  title={Resources.getValue("presentPrevBtnTxt")}
                  onClick={(e) => this.presentItemPrevClick(e)}
                >
                  <i className="fa fa-chevron-left" aria-hidden="true"></i>
                </a>
                <a
                  className="show-before-present before-present-next"
                  title={Resources.getValue("presentNextBtnTxt")}
                  onClick={(e) => this.presentItemNextClick(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
              <div
                className="show-before-present before-present-cancel none"
                title={Resources.getValue("presentCancelBtnTxt")}
                onClick={this.showBeforePresentCancelClick}
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
        )}

        {/* Present Items */}
        <div id="present-items-menu" className="present-items-menu">
          <a id="refresh-list-btn" className="none" onClick={()=> this.handler()}>
            Yenile
          </a>
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            <Droppable droppableId="mapPresentDatas">
              {(provided) => (
                this.state.mapPresentDatas.length == 0 
                ? <div className="not-present-item">
                  <i className="fa fa-desktop" aria-hidden="true"></i>
                  <span>{Resources.getValue("presentNotSelectItem")}</span>
                  </div> :
                <ul className="present-items-menu-items" ref={provided.innerRef} {...provided.droppableProps}>
                  {this.state.mapPresentDatas.map(({nodeText, nodeId, image}, index) => {
                    return (
                      <Draggable key={nodeId} draggableId={nodeId} index={index}>
                        {(provided) => (
                          <li 
                            className="present-selected-item" 
                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                          >
                            <span className="drag-btn">
                              <i className="fa fa-list"></i>
                            </span>
                            <span className="remove-btn" onClick={() => this.removePresentItemFromList(index)}>
                              <i className="fa fa-trash"></i>
                            </span>
                            <div className="present-selected-text">
                              <span>{Resources.getValue("textMsgTxt")}:</span> 
                              <input 
                                type="text" 
                                className="form-control" 
                                defaultValue={nodeText} 
                                onChange={(e) => this.changePresentItemName(e, nodeId)} 
                              />
                            </div>
                            {image && (
                              <img src={image} alt="" />
                            )}
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          <a className="save-present-items-btn" onClick={()=> this.savePresentDatas()}>
            <i className="fa fa-save"></i> {Resources.getValue("saveSmallMsgTxt")}
          </a>
        </div>
        {/* Present Items */}

        {/* toolbox */}
        <div className="toolbox">
          {this.state.willCopyMapNameModal ? (
            <CopyMyMapNameChangeModalComponent sharedClick={this.sharedClick} />
          ) : null}
          {this.state.isSharedModal ? (
            <CustomModal
              sharedClick={this.sharedClick}
              callWhenModalClose={this.callWhenModalClose}
              isUserMakePublicThisMapWithButton={
                this.state.isUserMakePublicThisMapWithButton
              }
            />
          ) : null}
          {this.state.isSharedMapNameModal ? (
            <SharedMapNameChangeModalComponent sharedClick={this.sharedClick} />
          ) : null}
          {this.state.isSaveMapAsTemplateModal ? (
            <SaveMapAsTemplateModal sharedClick={this.sharedClick} />
          ) : null}
          {this.state.isChangeMapNameModal ? (
            <ChangeMapNameModal sharedClick={this.sharedClick} />
          ) : null}
          <div
            className="ui-wrapper"
            onClick={() => {
              this.colorPickerOpenClose(null);
            }}
          >
            <div
              className="format-painter-info passive"
              dangerouslySetInnerHTML={{ __html: Resources.getValue("formatPainterInfoMsgTxt") }}>
            </div>

            <button id="fit-map" className="fit-map" 
              onClick={() => this.fitMapToScreen()}
              title={Resources.getValue("fitMapBtnText")}>
              <i className="fa fa-arrows-alt"></i>
            </button>

            <div className="editor-header-left">
              {/* Change Map Name Input */}
              <div className="editor-wrapper">
                <a
                  onClick={() => this.openMapNameChangeModal()}
                  title="Back"
                  className="back-btn"
                >
                  <i className="icon-arrow-back back-icon"></i>
                </a>
                {(JSON.parse(localStorage.getItem("mapPermission")) === 0) ? (
                  <>
                    <div className="doc-name ellipsis">
                      <input
                        type="text"
                        maxLength="50"
                        className="map-name editor-header-tools"
                        onBlur={this.updateMapName}
                      />
                    </div>
                    <div className="buttons float-right" onClick={this.updateMapName}>
                      <i
                        className="icon-done-black item-update-name"
                        title="Update"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="doc-name ellipsis">
                      <h4
                        type="text"
                        maxLength="50"
                        className="map-name editor-header-tools"
                      >{localStorage.getItem("openedMapName")}</h4>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Undo Button */}
            <div className="editor-right-header">
            <div className={`undo-changes-button`}>
                <a
                  onClick={() => {
                    this.undoChangesFunc();
                  }}
                  className="right-bottom-button"
                  id="undo-changes-btn"
                >
                  <i className="fa fa-rotate-left"></i>
                </a>
              </div>
              {/* Redo Button */}
              <div className={`redo-changes-button`}>
                <a
                  onClick={() => {
                    this.redoChangesFunc();
                  }}
                  className="right-bottom-button"
                  id="redo-changes-btn"
                >
                  <i className="fa fa-rotate-right"></i>
                </a>
              </div>
            </div>
            {/* Private - Public button */}
            <div
              className={`show-users fav-button private-public-btn ${JSON.parse(localStorage.getItem("mapPermission")) !== 0
                ? "none"
                : ""
                }`}
            >
              <a
                onClick={() =>
                  this.makePublicPrivate(
                    localStorage.getItem("openedMapId"),
                    !JSON.parse(localStorage.getItem("isMapPublicControl"))
                  )
                }
                id="public-private-btn"
                title={JSON.parse(localStorage.getItem("mapJsonObj")).isPublicMap === true
                  ? Resources.getValue("makePrivateMsgTxt")
                  : Resources.getValue("makePublicMsgTxt")}
                className={`fav-action`}
              >
                <i
                  className={`${JSON.parse(localStorage.getItem("isNewMap")) === true ||
                    localStorage.getItem("isNewMap") === undefined ||
                    localStorage.getItem("isNewMap") === null ||
                    localStorage.getItem("isNewMap") === ""
                    ? "icon-lock-black"
                    : JSON.parse(localStorage.getItem("mapJsonObj")).isPublicMap === true
                      ? "icon-lock-open-black"
                      : "icon-lock-black"
                    }`}
                ></i>
              </a>
            </div>
            {/* Favorite button */}
            <div className={`show-users fav-button ${(JSON.parse(localStorage.getItem("mapPermission")) !== 0)
              ? "noOwnerFavBtn"
              : ""
              }`}>
              <a
                onClick={() =>
                  this.addRemoveFavorite(
                    localStorage.getItem("openedMapId"),
                    !JSON.parse(localStorage.getItem("mapJsonObj")).isFavorite
                  )
                }
                id="favori-btn"
                title={(JSON.parse(localStorage.getItem("mapJsonObj")).isFavorite === true)
                  ? Resources.getValue("removeFromFavMsgTxt")
                  : Resources.getValue("addToFavMsgTxt")}
                className={`fav-action${JSON.parse(localStorage.getItem("isNewMap")) === true ||
                  localStorage.getItem("isNewMap") === undefined ||
                  localStorage.getItem("isNewMap") === null ||
                  localStorage.getItem("isNewMap") === ""
                  ? ""
                  : (JSON.parse(localStorage.getItem("mapJsonObj")).isFavorite === true)
                    ? " active"
                    : ""
                  }`}
              >
                <i className={`${JSON.parse(localStorage.getItem("isNewMap")) === true ||
                  localStorage.getItem("isNewMap") === undefined ||
                  localStorage.getItem("isNewMap") === null ||
                  localStorage.getItem("isNewMap") === ""
                  ? "icon-favorite-border"
                  : (JSON.parse(localStorage.getItem("mapJsonObj")).isFavorite === true)
                    ? "icon-favorite-fill"
                    : "icon-favorite-border"
                  }`}></i>
              </a>
            </div>

            {/* Copy Map Button */}
            <div className={`copy-map my-map ${(JSON.parse(localStorage.getItem("mapPermission")) === 0)
              ? "noOwnerDownloadBtn"
              : "none"
              }`}>
                <a
                  onClick={() => {
                    localStorage.setItem('isCustomModalOpen', true);
                    this.setState({
                      willCopyMapNameModal: JSON.parse(localStorage.getItem('isCustomModalOpen')),
                    });
                  }}
                  className="right-bottom-button"
                  title={Resources.getValue("copyMapMsgTxt")}
                >
                  <i className="icon-copy-black"></i>
                </a>
              </div>

            {/* Share Button */}
            <div
              className={`shared-link ${JSON.parse(localStorage.getItem("mapPermission")) === 0
                ? ""
                : "none"
                }`}
            >
              <a
                onClick={() => {
                  MM.App.hideNewToolbox();
                  localStorage.setItem("isCustomModalOpen", true);
                  this.setState({
                    isSharedModal: JSON.parse(
                      localStorage.getItem("isCustomModalOpen")
                    ),
                  });
                }}
                className="right-bottom-button share-modal-btn"
                id="shared"
                title={Resources.getValue("sharedpMsgTxt")}
              >
                <i className="icon-share-black"></i>
              </a>
            </div>

            {/* Save A Copy Button */}
            <div
              className={`download-link copy-map-btn ${JSON.parse(localStorage.getItem("mapPermission")) !== 0
                ? "noOwnerDownloadBtn"
                : "none"
                }`}
            >
              <a
                onClick={() => {
                  MM.App.hideNewToolbox();
                  localStorage.setItem("isCustomModalOpen", true);
                  this.setState({
                    isSharedMapNameModal: JSON.parse(
                      localStorage.getItem("isCustomModalOpen")
                    ),
                  });
                }}
                className="right-bottom-button"
                title={Resources.getValue("saveACopyMsgText")}
              >
                <i className="icon-copy-black"></i>
              </a>
            </div>

            {/* Open New Map Button */}
            <div className="download-link none">
              <a
                onClick={() => this.openNewMap()}
                data-command="New"
                className="right-bottom-button"
                title={Resources.getValue("newmapMsgTxt")}
              >
                <img
                  src={createNewIcon}
                  alt={Resources.getValue("newmapMsgTxt")}
                />
                {Resources.getValue("newmapMsgTxt")}
              </a>
            </div>

            {/* Download Button */}
            <div className={`download-link ${JSON.parse(localStorage.getItem("mapPermission")) !== 0
              ? "noOwnerDownloadBtn"
              : ""
              }`}>
              <div
                onClick={this.downloadMenu}
                id="image-to-pdf"
                className="right-bottom-button download-dropdown"
                title={Resources.getValue("imageToPdf")}
              >
                <i className="icon-file-download"></i>
                {/* dropdown */}
                <div className="download-options">
                  <a
                  onClick={() => {
                    this.getIsDownloadableMap("htmlToImage");
                  }}
                    title={"JPEG " + Resources.getValue("imageToPdf")}
                  >
                    JPEG {Resources.getValue("imageToPdf")}
                  </a>
                  <a
                  onClick={() => {
                    this.getIsDownloadableMap("htmlToPdf");
                  }}
                    title={"PDF " + Resources.getValue("imageToPdf")}
                  >
                    PDF {Resources.getValue("imageToPdf")}
                  </a>
                </div>
              </div>
            </div>

            {/* Save as Template Button */}
            <div
              className={`save-template-btn 
            ${this.state.isUserHasPermissionForOptions === false &&
                  this.state.isTeacherUserHasPermissionForOptions === false
                  ? " none"
                  : ""
                }`}
            >
              <a
                onClick={() => {
                  localStorage.setItem("isCustomModalOpen", true);
                  this.setState({
                    isSaveMapAsTemplateModal: JSON.parse(
                      localStorage.getItem("isCustomModalOpen")
                    ),
                  });
                }}
                className="right-bottom-button"
                title={Resources.getValue("saveMapAsTemplateMsgTxt")}
              >
                <i className="icon-create_new_map_icon"></i>
              </a>
            </div>

            {/* Format Painter Button */}
            <div
              className={`format-painter-btn`}
            >
              <a
                onClick={() => {
                  this.getSelectedItemFormat()
                }}
                className="right-bottom-button"
                title={Resources.getValue("getItemFormat")}
              >
                <i className="icon-colorize"></i>
              </a>
            </div>


            {/* New Toolbox -------- */}
            <div className="new-toolbox-wrapper none">
              <div className="new-toolbox-top">
                <div className="new-toolbox-text-top-items">
                  {/* change font */}
                  <div
                    className="font-wrapper toolbox-item"
                    title={Resources.getValue("featureFontTypesMsgTxt")}
                    onClick={this.fontMenu}
                  >
                    <div className="font-dropdown">
                      <div className="font-button">
                        <span>Architects Daughter</span>
                        <i className="icon-chevron-down"></i>
                      </div>
                      <div
                        id="fontDropdown"
                        className="toolbox-content font-dropdown-content"
                      >
                        <div className="font-dropdown-content-wrap">
                          <div
                            className={`fontSelect font-select`}
                            data-font="architects-daughter"
                          >
                            <div className="font-name">Architects Daughter</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="nerko-one"
                          >
                            <div className="font-name">Nerko One</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="indie-flower"
                          >
                            <div className="font-name">Indie Flower</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="caveat"
                          >
                            <div className="font-name">Caveat</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="cairo"
                          >
                            <div className="font-name">Cairo</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="courgette"
                          >
                            <div className="font-name">Courgette</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="source-sans-pro"
                          >
                            <div className="font-name">Source Sans Pro</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="patrick-hand"
                          >
                            <div className="font-name">Patrick Hand</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="nunito"
                          >
                            <div className="font-name">Nunito</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="grape-nuts"
                          >
                            <div className="font-name">Grape Nuts</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="kalam"
                          >
                            <div className="font-name">Kalam</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="forum"
                          >
                            <div className="font-name">Forum</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="shadows-into-light-two"
                          >
                            <div className="font-name">Shadows Into Light Two</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="lemonada"
                          >
                            <div className="font-name">Lemonada</div>
                          </div>
                          <div className={`fontSelect`}
                            data-font="syne"
                          >
                            <div className="font-name">Syne</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* change font size */}
                  <div
                    className="fontSize-wrapper toolbox-item"
                    title={Resources.getValue("featureFontSizeMsgTxt")}
                    onClick={this.fontSizeMenu}
                  >
                    <div className="fontSize-dropdown">
                      <div className="fontSize-button">
                        <span>17</span>
                        <i className="icon-chevron-down"></i>
                      </div>
                      <div
                        id="fontSizeDropdown"
                        className="toolbox-content fontSize-dropdown-content"
                      >
                        <div className="fontSize-dropdown-content-wrap">
                          {/* <div
                            className={`fontSizeSelect fontSize-select`}
                            data-font-size="11"
                          >
                            <div className="fontSize-name">11</div>
                          </div>
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="13"
                          >
                            <div className="fontSize-name">13</div>
                          </div>
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="15"
                          >
                            <div className="fontSize-name">15</div>
                          </div> */}
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="17"
                          >
                            <div className="fontSize-name">17</div>
                          </div>
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="20"
                          >
                            <div className="fontSize-name">20</div>
                          </div>
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="24"
                          >
                            <div className="fontSize-name">24</div>
                          </div>
                          {/* <div
                            className={`fontSizeSelect`}
                            data-font-size="27"
                          >
                            <div className="fontSize-name">27</div>
                          </div> */}
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="30"
                          >
                            <div className="fontSize-name">30</div>
                          </div>
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="36"
                          >
                            <div className="fontSize-name">36</div>
                          </div>
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="48"
                          >
                            <div className="fontSize-name">48</div>
                          </div>
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="60"
                          >
                            <div className="fontSize-name">60</div>
                          </div>
                          <div
                            className={`fontSizeSelect`}
                            data-font-size="72"
                          >
                            <div className="fontSize-name">72</div>
                          </div>
                          <div className="fontsize-input-wrapper w-100 p-2 none">
                            <input
                              type="text"
                              className="form-control item-font-size-input"
                              onKeyPress={(e) => {
                                this.validateFontSize(e);
                              }}
                              onKeyUp={(e) => {
                                this.changeFontSizeInput(e);
                              }}
                            />
                            <a
                              onClick={this.setFontSizeFn}
                              className="btn btn-font-size"
                            >
                              {Resources.getValue("setMsgTxt")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* metin rengi */}
                  <div
                    className="text-toolbar toolbox-item"
                    title={Resources.getValue("featureTextColorMsgTxt")}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.textdMenu(e);
                    }}
                  >
                    <input
                      hidden
                      className="color-picker-text"
                      id="color-picker-text"
                      readOnly={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.colorPickerOpenClose(e);
                      }}
                    />
                    <div
                      id="color-list-wrapper-text"
                      className="toolbox-content color-list-wrapper-text none"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <ul className="color-list"></ul>
                    </div>
                  </div>
                </div>
                <div className="new-toolbox-text-bottom-items">
                  <div className="new-toolbox-text-bottom-items--left none">
                    <div className="style-button">
                      <i className="icon-align-left-icon"></i>
                    </div>
                    <div className="style-button">
                      <i className="icon-align-center-icon"></i>
                    </div>
                    <div className="style-button">
                      <i className="icon-align-right-icon"></i>
                    </div>
                  </div>
                  <div className="new-toolbox-text-bottom-items--right">
                    <div className="style-button text-style-b" onClick={() => {
                      MM.Item.setTextStyle('b')
                    }}>
                      <i className="icon-bold-icon"></i>
                    </div>
                    <div className="style-button text-style-i" onClick={() => {
                      MM.Item.setTextStyle('i')
                    }}>
                      <i className="icon-italic-icon"></i>
                    </div>
                    <div className="style-button text-style-u" onClick={() => {
                      MM.Item.setTextStyle('u')
                    }}>
                      <i className="icon-underlined-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line"></div>
              <div className="new-toolbox-bottom">
                {/* change shape size */}
                <div
                  className="shape-size-wrapper toolbox-item"
                  title={Resources.getValue("featureBoxSizeMsgTxt")}
                  onClick={this.shapeSizeMenu}
                >
                  <div className="shape-size-dropdown">
                    <div className="shape-size-button">
                      <i className="icon-aspect-ratio-icon"></i>
                    </div>
                    <div
                      id="shapeSizeDropdown"
                      className="toolbox-content shape-size-dropdown-content"
                    >
                      <div
                        className="shapeSizeSelect shape-size-select"
                        datashapesize="auto"
                      >
                        Auto
                      </div>
                      <div className="shapeSizeSelect" datashapesize="small">
                        {Resources.getValue("smallMsgTxt")}
                      </div>
                      <div className="shapeSizeSelect" datashapesize="medium">
                        {Resources.getValue("mediumMsgTxt")}
                      </div>
                      <div className="shapeSizeSelect" datashapesize="large">
                        {Resources.getValue("largeMsgTxt")}
                      </div>
                      <div className="shapeSizeSelect" datashapesize="largest">
                        {Resources.getValue("largestMsgTxt")}
                      </div>
                    </div>
                  </div>
                </div>
                {/* shape select */}
                <div className="shape-toolbar">
                  <div
                    className="shape-wrapper toolbox-item"
                    title={Resources.getValue("featureBoxShapeTypesMsgTxt")}
                    onClick={this.shapeMenu}
                  >
                    <div className="shape-dropdown">
                      <div className="shape-button">
                        <i className="icon-shape-icon"></i>
                      </div>
                      <div
                        id="shapeDropdown"
                        className="toolbox-content shape-dropdown-content"
                      >
                        <div
                          className={`shapeSelectItem`}
                          data-shape="box"
                        >
                          <img src={shape1} alt="box" />
                        </div>
                        <div
                          className={`shapeSelectItem`}
                          data-shape="parallelogram"
                        >
                          <img src={shape2} alt="parallelogram" />
                        </div>
                        <div
                          className={`shapeSelectItem`}
                          data-shape="rhombus"
                        >
                          <img src={shape3} alt="rhombus" />
                        </div>
                        <div
                          className={`shapeSelectItem`}
                          data-shape="pentagon"
                        >
                          <img src={shape4} alt="pentagon" />
                        </div>
                        <div
                          className={`shapeSelectItem`}
                          data-shape="octagon"
                        >
                          <img src={shape5} alt="octagon" />
                        </div>
                        <div
                          className={`shapeSelectItem`}
                          data-shape="message"
                        >
                          <img src={shape6} alt="message" />
                        </div>
                        <div
                          className={`shapeSelectItem`}
                          data-shape="cloud"
                        >
                          <img src={shape7} alt="cloud" />
                        </div>
                        <div
                          className={`shapeSelectItem shape-select`}
                          data-shape="rectangle"
                        >
                          <img src={shape8} alt="rectangle" />
                        </div>
                        <div
                          className={`shapeSelectItem`}
                          data-shape="ellipse"
                        >
                          <img src={shape9} alt="ellipse" />
                        </div>
                        <div
                          className={`shapeSelectItem`}
                          data-shape="circle"
                        >
                          <span className="circle-shape"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* arkaplan rengi */}
                <div
                  className="background-toolbar toolbox-item"
                  title={Resources.getValue("featureBoxBgColorMsgTxt")}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.backgroundMenu(e);
                  }}
                >
                  <i className="icon-brush-icon"></i>
                  <input
                    hidden
                    className="color-picker"
                    id="color-picker"
                    readOnly={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.colorPickerOpenClose(e);
                    }}
                  />
                  <div
                    id="color-list-wrapper-picker"
                    className="toolbox-content color-list-wrapper-picker none"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <ul className="color-list"></ul>
                  </div>
                </div>
                {/* cerceve rengi */}
                <div
                  className="border-toolbar toolbox-item"
                  title={Resources.getValue("featureBoxBorderColorMsgTxt")}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.borderdMenu(e);
                  }}
                >
                  <i className="icon-border-icon"></i>
                  <input
                    hidden
                    className="color-picker-border"
                    id="color-picker-border"
                    readOnly={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.colorPickerOpenClose(e);
                    }}
                  />
                  <div
                    id="color-list-wrapper-border"
                    className="toolbox-content color-list-wrapper-border none"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <ul className="color-list"></ul>
                  </div>
                </div>
                {/* icon select */}
                <div className="icon-toolbar">
                  <div
                    className="icon-wrapper toolbox-item"
                    title={Resources.getValue("iconsMsgTxt")}
                    onClick={this.iconMenu}
                  >
                    <div className="icon-dropdown">
                      <div className="icon-button">
                        <i className="icon-emoji-icon"></i>
                      </div>
                      <div
                        id="iconDropdown"
                        className="toolbox-content icon-dropdown-content"
                      >
                        <EmojiList removeIcon={this.removeIcon} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ---- New Toolbox */}

            {/* bacground image select */}
            <div className="background-image-toolbar">
              <div
                className="background-image-wrapper toolbox-item"
                title={Resources.getValue("featureBgTypesMsgTxt")}
                onClick={this.backgroundImageMenu}
              >
                <div className="background-image-dropdown">
                  <div className="background-image-button">
                    <i className="icon-photo-black"></i>
                  </div>
                  <div
                    id="background-imageDropdown"
                    className="toolbox-content background-image-dropdown-content"
                  >
                    <div
                      className="background-imageSelect"
                      data-background-image={backgroundImage11}
                    >
                      <img src={backgroundImage1} alt="backgroundImage1" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-image={backgroundImage21}
                    >
                      <img src={backgroundImage2} alt="backgroundImage2" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-image={backgroundImage31}
                    >
                      <img src={backgroundImage3} alt="backgroundImage3" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-image={backgroundImage41}
                    >
                      <img src={backgroundImage4} alt="backgroundImage4" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-image={backgroundImage51}
                    >
                      <img src={backgroundImage5} alt="backgroundImage5" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-image={backgroundImage61}
                    >
                      <img src={backgroundImage6} alt="backgroundImage6" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-image={backgroundImage71}
                    >
                      <img src={backgroundImage7} alt="backgroundImage7" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-image={backgroundImage81}
                    >
                      <img src={backgroundImage8} alt="backgroundImage8" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-color="#e8f0f5"
                    >
                      <img src={backgroundImage12} alt="backgroundImage12" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-color="#fff5dd"
                    >
                      <img src={backgroundImage13} alt="backgroundImage13" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-color="#f1f8ea"
                    >
                      <img src={backgroundImage14} alt="backgroundImage14" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-color="#e9f7ff"
                    >
                      <img src={backgroundImage112} alt="backgroundImage112" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-color="#ffecec"
                    >
                      <img src={backgroundImage15} alt="backgroundImage15" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-color="#f7f5f7"
                    >
                      <img src={backgroundImage9} alt="backgroundImage9" />
                    </div>
                    <div
                      className="background-imageSelect"
                      data-background-color="#ffffff"
                    >
                      <img src={backgroundImage16} alt="backgroundImage16" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="toolbox-option none">
              <input
                type="file"
                className="map-image-upload"
                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
              />
            </div>

            <div className="toolbox-option none">
              <label>{Resources.getValue("valueMsgTxtValue")}</label>
              <select id="value">
                <option value="">({Resources.getValue("noneMsgTxt")})</option>
                <option value="num">{Resources.getValue("numberMsgTxt")}</option>
                <optgroup label="Formula">
                  <option value="sum">{Resources.getValue("sumMsgTxt")}</option>
                  <option value="avg">
                    {Resources.getValue("avarageMsgTxt")}
                  </option>
                  <option value="min">{Resources.getValue("minMsgTxt")}</option>
                  <option value="max">{Resources.getValue("maxMsgTxt")}</option>
                </optgroup>
              </select>
            </div>

            <div className="toolbox-option none">
              <label>{Resources.getValue("statusMsgTxt")}</label>
              <select id="status">
                <option value="">{Resources.getValue("noneMsgTxt")}</option>
                <option value="yes">{Resources.getValue("yesMsgTxt")}</option>
                <option value="no">{Resources.getValue("noMsgTxt")}</option>
                <option value="computed">
                  {Resources.getValue("autocomputeMsgTxt")}
                </option>
              </select>
            </div>
          </div>

          <button
            data-command="Help"
            className="help-button toolbox-closed"
            title={Resources.getValue("showHideHelpPanelMsgTxt")}
          >
            <i className="icon-help-outline" aria-hidden="true"></i>
          </button>

          <div id="io" className="ui">
            <h3></h3>
            <p>
              <span>{Resources.getValue("storageMsgTxt")}</span>
              <select id="backend"></select>
            </p>

            <div id="file">
              <p className="desc">
                Local files are suitable for loading/saving files from other
                mindmapping applications.
              </p>
              <p data-for="save">
                <span>Format</span>
                <select className="format"></select>
              </p>
              <p data-for="save load">
                <button className="go"></button>
                <button className="cancel">Cancel</button>
              </p>
            </div>

            <div id="image">
              <p className="desc">Export your design as a PNG image.</p>
              <p data-for="save">
                <button className="go"></button>
                <button className="cancel">Cancel</button>
              </p>
            </div>

            <div id="local">
              <p className="desc">
                Your browser's localStorage can handle many mind maps and creates
                a permalink, but this URL cannot be shared.
              </p>
              <p data-for="load">
                <span>Saved maps</span>
                <select className="list"></select>
              </p>
              <p data-for="save load">
                <button className="go"></button>
                <button className="cancel">Cancel</button>
              </p>
              <p data-for="load">
                <button className="remove">Delete</button>
              </p>
            </div>

            <div id="firebase">
              <p className="desc">
                Firebase offers real-time synchronization for true multi-user
                collaboration.
              </p>
              <p data-for="save load">
                <span>Server</span>
                <input type="text" className="server" />
              </p>
              <p data-for="save load">
                <span>Auth</span>
                <select className="auth">
                  <option value="">(None)</option>
                  <option value="facebook">Facebook</option>
                  <option value="twitter">Twitter</option>
                  <option value="github">GitHub</option>
                  <option value="google">Google</option>
                </select>
              </p>
              <p data-for="load">
                <span>Saved maps</span>
                <select className="list"></select>
              </p>
              <p data-for="save load">
                <button className="go"></button>
                <button className="cancel">Cancel</button>
              </p>
              <p data-for="load">
                <button className="remove">Delete</button>
              </p>
            </div>

            <div id="webdav">
              <p className="desc">
                Use this to access a generic DAV-like REST API.
              </p>
              <p data-for="save load">
                <span>URL</span>
                <input type="text" className="url" />
              </p>
              <p data-for="save load">
                <button className="go"></button>
                <button className="cancel">Cancel</button>
              </p>
            </div>

            <div id="gdrive">
              <p className="desc">
                Maps stored in Google Drive have a permalink URL and can be shared
                with other users, if you allow this by setting proper permissions
                (inside Google Drive itself).
              </p>
              <p data-for="save">
                <span>Format</span>
                <select className="format"></select>
              </p>
              <p data-for="save load">
                <button className="go"></button>
                <button className="cancel">Cancel</button>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Toolbox;
