import $ from "jquery";
import "jquery-validation";
import Resources from "../libraries/resources";

var Utils = {
  scrollElement: function (element) {
    // scroll to panel
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      // inline: "nearest"
    });
  },

  sortArrByProp: function (property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  },

  isIOS: (function () {
    var isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    return isIOS;
  })(),

  getParameterByName: function (name, url) {
    if (!url) url = window.location.href.replace("#", "?"); // clear hash if has
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },

  delay: function () {
    var timer;
    var set = function (func, time) {
      if (typeof timer !== "undefined") clearTimeout(timer);
      timer = setTimeout(func, time);
    };
    return { set: set };
  },

  swipe: function (element, rightFunc, leftFunc) {
    var $element = $(element);
    $element.on("touchstart", function (e) {
      var i = e.originalEvent.touches[0].pageX;
      $(this).one("touchmove", function (e) {
        var t = e.originalEvent.touches[0].pageX;
        Math.floor(i - t) > 8 && "function" == typeof leftFunc
          ? leftFunc()
          : Math.floor(i - t) < -8 &&
            "function" == typeof rightFunc &&
            rightFunc();
      });
      $element.on("touchend", function () {
        $(this).off("touchmove");
      });
    });
  },

  triggerEvent: function (element, event) {
    // trigger event manually
    if ("createEvent" in document) {
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent(event, false, true);
      element.dispatchEvent(evt);
    } else {
      element.fireEvent("on" + event);
    }
  },

  // changeLanguageSite: function () {
  //   document.addEventListener("DOMContentLoaded", function() {
  //     var languageSelectElement = document.querySelector('.language-select');
  //     languageSelectElement.addEventListener('change', function(){
  //       localStorage.setItem('siteLanguage', this.value);
  //       window.location.reload();
  //     });

  //   languageSelectElement.value = Resources.siteLanguage;
  // }(),

  formatDateTime: function (dateTime, lang) {
    var date = null,
      lang = null,
      time = null;

    if (dateTime.length == 0) return "";

    date = dateTime.split("T")[0];
    time = dateTime.split("T")[1];
    lang = lang || Resources.siteLanguage;

    var dd = date.slice(8, 10);
    var mm = date.slice(5, 7);
    var yyyy = date.slice(0, 4);

    if (lang == "tr") date = dd + "-" + mm + "-" + yyyy + " " + time;
    else if (lang == "en") date = mm + "-" + dd + "-" + yyyy + " " + time;

    return date;
  },

  formatDateTimeWithoutSecond: function (dateTime, lang) {
    var date = null,
      lang = null,
      time = null;

    if (dateTime.length == 0) return "";

    date = dateTime.split("T")[0];
    time = dateTime.split("T")[1];
    lang = lang || Resources.siteLanguage;

    const dd = date.slice(8, 10);
    const mm = date.slice(5, 7);
    const yyyy = date.slice(0, 4);

    if (lang == "tr")
      date =
        dd +
        "-" +
        mm +
        "-" +
        yyyy +
        " " +
        time.split(":")[0] +
        ":" +
        time.split(":")[1];
    else if (lang == "en")
      date =
        mm +
        "-" +
        dd +
        "-" +
        yyyy +
        " " +
        time.split(":")[0] +
        ":" +
        time.split(":")[1];

    return date;
  },

  formatDateTimeWithoutTime: function (dateTime, lang) {
    var date = null,
      lang = null,
      time = null;

    if (dateTime.length == 0) return "";

    date = dateTime.split("T")[0];
    lang = lang || Resources.siteLanguage;

    const dd = date.slice(8, 10);
    const mm = date.slice(5, 7);
    const yyyy = date.slice(0, 4);

    // if (lang == "tr") date = dd + "." + mm + "." + yyyy;
    // else if (lang == "en") date = mm + "." + dd + "." + yyyy;

    date = dd + "." + mm + "." + yyyy;

    return date;
  },

  formatDateWithMonthName: function (dateTime, lang) {
    var date = null,
      lang = null;

    if (dateTime.length == 0) return "";

    date = dateTime.split("T")[0];
    lang = lang || Resources.siteLanguage;

    const dd = date.slice(8, 10);
    const mm = date.slice(5, 7);
    const yyyy = date.slice(0, 4);

    const monthObj = {
      "01": { text: Resources.getValue("januaryMsgTxt") },
      "02": { text: Resources.getValue("februaryMsgTxt") },
      "03": { text: Resources.getValue("marchMsgTxt") },
      "04": { text: Resources.getValue("aprilMsgTxt") },
      "05": { text: Resources.getValue("mayMsgTxt") },
      "06": { text: Resources.getValue("juneMsgTxt") },
      "07": { text: Resources.getValue("julyMsgTxt") },
      "08": { text: Resources.getValue("augustMsgTxt") },
      "09": { text: Resources.getValue("septemberMsgTxt") },
      10: { text: Resources.getValue("octoberMsgTxt") },
      11: { text: Resources.getValue("novemberMsgTxt") },
      12: { text: Resources.getValue("decemberMsgTxt") },
    };

    date = dd + " " + monthObj[mm].text + " " + yyyy;

    return date;
  },

  only: function () {
    //definitions
    var elements = [];
    var clearKeys = [8, 9, 13];
    var patterns = {
      char: /[0-9a-z A-ZşŞıİçÇöÖüÜĞğ]+/,
      number: /[0-9]+/,
      decimal: /[0-9.,]+/,
      letter: /[a-z A-ZşŞıİçÇöÖüÜĞğ]+/,
    };

    var getElements = function () {
      elements.fields = document.querySelectorAll("[data-only]");
    };

    var listeners = function () {
      for (var i = 0; i < elements.fields.length; i++) {
        // stamp control (for single init)
        if (elements.fields[i].dataset.onlyInit) continue;

        // events binding
        ["keypress", "paste"].forEach(function (event) {
          elements.fields[i].addEventListener(event, function (event) {
            var code = event.keyCode || event.charCode;
            var method = this.getAttribute("data-only");

            if (clearKeys.indexOf(code) != -1) return;

            var val = String.fromCharCode(code);
            var cleanVal = (val || "").replace(/['"[\]\\]/g, "");
            var isTrue = match(method, cleanVal);

            if (!isTrue) event.preventDefault();
          });
        });

        // set init stamp to element
        elements.fields[i].dataset.onlyInit = true;
      }
    };

    var match = function (method, val) {
      return patterns[method].test(val);
    };

    var init = (function () {
      getElements();
      listeners();
    })();
  },

  loadingScreen: function (status) {
    var containerElement = document.querySelector("body");

    if (status == "show") {
      containerElement.dataset.pendingAjaxRequestCount = String(
        (parseInt(containerElement.dataset.pendingAjaxRequestCount) || 0) + 1
      );

      if (containerElement.getAttribute("loading-screen") == "true") return;

      // create loading element
      var loadingDiv = document.createElement("div");
      loadingDiv.classList.add("loading-screen");

      containerElement.setAttribute("loading-screen", "true");
      containerElement.appendChild(loadingDiv);
      loadingDiv.classList.add("show");
    } else if (status == "hide") {
      var pendingAjaxRequestCount =
        containerElement.dataset.pendingAjaxRequestCount;

      if (pendingAjaxRequestCount && parseInt(pendingAjaxRequestCount) > 0) {
        var activeloadingDiv = document.querySelector(".loading-screen");

        containerElement.dataset.pendingAjaxRequestCount = String(
          parseInt(containerElement.dataset.pendingAjaxRequestCount) - 1
        );

        if (activeloadingDiv && pendingAjaxRequestCount == "1") {
          activeloadingDiv.classList.remove("show");
          containerElement.setAttribute("loading-screen", "false");

          setTimeout(function () {
            activeloadingDiv = document.querySelector(".loading-screen");
            if (activeloadingDiv) {
              activeloadingDiv.outerHTML = "";
            }
          }, 300);
        }
      }
    } else if (status == "show-static") {
      if (containerElement.getAttribute("loading-screen-static") == "true")
        return;

      // create loading element
      var loadingDiv = document.createElement("div");
      loadingDiv.classList.add("loading-screen");

      containerElement.setAttribute("loading-screen-static", "true");
      containerElement.appendChild(loadingDiv);
      loadingDiv.classList.add("show");
    } else if (status == "hide-static") {
      var activeloadingDiv = document.querySelector(".loading-screen");

      if (activeloadingDiv) {
        activeloadingDiv.classList.remove("show");
        containerElement.setAttribute("loading-screen-static", "false");

        setTimeout(function () {
          activeloadingDiv = document.querySelector(".loading-screen");
          if (activeloadingDiv) {
            activeloadingDiv.outerHTML = "";
          }
        }, 300);
      }
    }
  },

  loadingScreenChatGpt: function (status) {
    var containerElement = document.querySelector("body");

    if (status == "show") {
      containerElement.dataset.pendingAjaxRequestCount = String(
        (parseInt(containerElement.dataset.pendingAjaxRequestCount) || 0) + 1
      );

      if (containerElement.getAttribute("loading-screen-gpt") == "true") return;
      
      // create loading element
      var loadingDivGpt = document.createElement("div");
      loadingDivGpt.classList.add("loading-screen-gpt");

      containerElement.setAttribute("loading-screen-gpt", "true");
      containerElement.appendChild(loadingDivGpt);
      loadingDivGpt.classList.add("show");
    } else if (status == "hide") {
      var pendingAjaxRequestCount =
        containerElement.dataset.pendingAjaxRequestCount;

      if (pendingAjaxRequestCount && parseInt(pendingAjaxRequestCount) > 0) {
        var activeloadingDiv = document.querySelector(".loading-screen-gpt");

        containerElement.dataset.pendingAjaxRequestCount = String(
          parseInt(containerElement.dataset.pendingAjaxRequestCount) - 1
        );

        if (activeloadingDiv && pendingAjaxRequestCount == "1") {
          activeloadingDiv.classList.remove("show");
          containerElement.setAttribute("loading-screen-gpt", "false");

          setTimeout(function () {
            activeloadingDiv = document.querySelector(".loading-screen-gpt");
            if (activeloadingDiv) {
              activeloadingDiv.outerHTML = "";
            }
          }, 300);
        }
      }
    } else if (status == "show-static") {
      if (containerElement.getAttribute("loading-screen-static") == "true")
        return;

      // create loading element
      var loadingDiv = document.createElement("div");
      loadingDiv.classList.add("loading-screen-gpt");

      containerElement.setAttribute("loading-screen-static", "true");
      containerElement.appendChild(loadingDiv);
      loadingDiv.classList.add("show");
    } else if (status == "hide-static") {
      var activeloadingDiv = document.querySelector(".loading-screen-gpt");

      if (activeloadingDiv) {
        activeloadingDiv.classList.remove("show");
        containerElement.setAttribute("loading-screen-static", "false");

        setTimeout(function () {
          activeloadingDiv = document.querySelector(".loading-screen-gpt");
          if (activeloadingDiv) {
            activeloadingDiv.outerHTML = "";
          }
        }, 300);
      }
    }
  },

  modalm: function () {
    var method = {},
      element = {},
      kitbag = {};

    kitbag = {
      modalContainer: "body",
      overlaySelector: "modalm-overlay",
      modalSelector: "modalm",
      modalWrapperSelector: "wrapper",
      headerSelector: "header",
      sectionSelector: "section",
      footerSelector: "footer",
      titleSelector: "title",
      closeButtonSelector: "fa fa-times exit-button",
      modalParameters: {}, // for callings from out modalm instance
      delay: new this.delay(),
    };

    method.listener = function () {
      element.$modal
        .find("." + kitbag.closeButtonSelector.split(" ").pop())
        .add(element.$overlay)
        .on("click", function () {
          method.close();
        });

      $("window").on("resize orientationchange", function () {
        method.vAlignCenter();
      });
    };

    method.createModal = function () {
      var createBasicMarkup = (function () {
        if (!element.$modalTemplate) {
          element.$modalContainer = $(kitbag.modalContainer);
          element.$overlay = $("<div>").addClass(
            kitbag.overlaySelector + " fadeOut smooth"
          );
          element.$modalTemplate = $("<div>")
            .addClass(kitbag.modalSelector + " fadeOut smooth")
            .append($("<div>").addClass(kitbag.modalWrapperSelector));
        } else {
          element.$modalWrapper.html("");
        }
      })();

      var removeActiveModal = (function () {
        var modalm = document.querySelector(".modalm");
        if (modalm) {
          var modalOverlay = document.querySelector(".modalm-overlay");
          modalm.remove();
          modalOverlay.remove();
        }
      })();

      var addToPage = (function () {
        element.$modalContainer.append(
          element.$modalTemplate,
          element.$overlay
        );
      })();

      var cachingActiveModal = (function () {
        element.$modal = $("." + kitbag.modalSelector);
        element.$overlay = $("." + kitbag.overlaySelector);
        element.$modalWrapper = element.$modal.find(
          "." + kitbag.modalWrapperSelector
        );
        element.$modalKit = element.$modal.add(element.$overlay);
      })();

      var improveModal = (function () {
        // exitButton
        if (kitbag.modalParameters.exitButtonText)
          element.$modal.append(
            $("<i>")
              .addClass(kitbag.closeButtonSelector)
              .attr("title", kitbag.modalParameters.exitButtonText)
              .attr("aria-hidden", true)
          );

        // title
        if (kitbag.modalParameters.title)
          element.$modalWrapper.append(
            $("<div>")
              .addClass(kitbag.headerSelector)
              .append(
                $("<h3>")
                  .addClass(kitbag.titleSelector)
                  .text(kitbag.modalParameters.title)
              )
          );

        // bodyContent
        if (kitbag.modalParameters.bodyContent)
          element.$modalWrapper.append(
            $("<div>")
              .addClass(kitbag.sectionSelector)
              .append(kitbag.modalParameters.bodyContent)
          );

        // buttons
        if (kitbag.modalParameters.buttons) {
          element.$modalWrapper.append(
            '<div class="' + kitbag.footerSelector + '"></div>'
          );
          var $footer = element.$modalWrapper.find("." + kitbag.footerSelector);

          $.each(kitbag.modalParameters.buttons, function (index, value) {
            var $btn = $("<a>");
            var href = value.href;
            var target = value.target;
            var _class = value.class;
            var text = value.text;

            $btn = href ? $btn.attr("href", href) : $btn;
            $btn = target ? $btn.attr("target", target) : $btn;
            $btn = _class ? $btn.addClass(_class) : $btn;
            $btn = text ? $btn.text(text).attr("title", text) : $btn;

            $footer.append($btn);
          });
        }
      })();
    };

    method.callback = function () {
      var confirmCallbackEvent = null;
      var rejectCallbackEvent = null;

      if (typeof kitbag.modalParameters.confirmCallback === "function") {
        confirmCallbackEvent = kitbag.modalParameters.confirmCallback;
      } else {
        confirmCallbackEvent = method.close;
      }

      if (typeof kitbag.modalParameters.rejectCallback === "function") {
        rejectCallbackEvent = kitbag.modalParameters.rejectCallback;
      } else {
        rejectCallbackEvent = method.close;
      }

      var listener = (function () {
        $(".modalm .confirm-button").on("click", function () {
          confirmCallbackEvent(kitbag.modalParameters.scope);
        });

        $(".modalm .reject-button").on("click", function () {
          rejectCallbackEvent(kitbag.modalParameters.scope);
        });
      })();
    };

    method.vAlignCenter = function () {
      var modalHeight = element.$modal.outerHeight();

      element.$modal.css({
        top:
          window.pageYOffset + window.innerHeight / 2 - modalHeight / 2 + "px",
      });
    };

    method.close = function () {
      if (typeof kitbag.modalParameters.redirectAfterClose === "string") {
        window.location.href = kitbag.modalParameters.redirectAfterClose;
      }

      var modalm = document.querySelector(".modalm");
      var modalOverlay = document.querySelector(".modalm-overlay");
      modalm.classList.remove("fadeIn");
      modalOverlay.classList.remove("fadeInHalf");

      setTimeout(function () {
        modalm.remove();
        modalOverlay.remove();
      }, 350);
    };

    method.show = function () {
      kitbag.delay.set(function () {
        method.vAlignCenter();
        element.$modal.addClass("fadeIn");
        element.$overlay.addClass("fadeInHalf");
        method.callback();
      }, 50);
    };

    method.open = function (parameters) {
      kitbag.modalParameters = {
        exitButtonText: parameters.exitButtonText /* string */,
        title: parameters.title /* string */,
        bodyContent: parameters.bodyContent /* html string */,
        buttons: parameters.buttons /* array */,
        confirmCallback: parameters.confirmCallback /* function */,
        rejectCallback: parameters.rejectCallback /* function */,
        scope: parameters.scope /* object */,
        redirectAfterClose: parameters.redirectAfterClose /* string url */,

        // Utils.modalm().open({
        //   exitButtonText: 'Exit',
        //   title: 'Success',
        //   bodyContent: '<p>Register Success</p>',
        //   buttons: [
        //     {
        //         text   : 'OK',
        //         class : 'close-button confirm-button',
        //         href   : '',
        //         target : '_blank'
        //     }
        //   ],
        //   confirmCallback: this.asd, // optional / default action = close modal
        //   rejectCallback: this.bsd,  // optional / default action = close modal
        //   redirectAfterClose: 'string url'  // optional
        // });
      };

      method.createModal();
      method.listener();
      method.show();
    };

    return {
      open: method.open,
      close: method.close,
    };
  },

  formValidation: function () {
    var method = {},
      kitbag = {};

    kitbag = {
      delay: new Utils.delay(),
      pluginSelector: ".submit-form-button",
      initedElStamp: "jQueryOnLoadTypeValidationRulesAdded",
    };

    method.getFormRequirements = function ($elements) {
      $elements.$form = $elements.$triggerEl.closest("form");
      $elements.$formFields = $elements.$form.find(".validate-required");
      $elements.$relatedElements = $elements.$form.find(
        "[data-validate-related-elements]"
      );
    };

    method.listeners = function ($elements) {
      $elements.$triggerEl.on("click", function () {
        method.validateTrigger($elements);
      });

      $elements.$formFields
        .filter(":not(textarea)")
        .on("keypress", function (event) {
          if (event.which == 13) {
            method.validateTrigger($elements);
            event.preventDefault();
          }
        });

      // $elements.$relatedElements.on("change keypress", function() {
      //   method.relatedElementsValidation(this);
      // });

      // $("window").on("load", function() {
      //   $.each($elements.$relatedElements, function(index, element) {
      //     method.relatedElementsValidation(element);
      //   });
      // });
    };

    // method.relatedElementsValidation = function(_element) {
    //   var setValidate = function(method) {
    //     var methods = {};

    //     methods.validate = function(element) {
    //       $(element).removeClass("ignore");
    //     };

    //     methods.ignore = function(element) {
    //       $(element).addClass("ignore");

    //       if (element.nodeName == "SELECT") {
    //         $(element)
    //           .removeClass("error")
    //           .next(".select2-container")
    //           .find(".select2-selection")
    //           .removeClass("error");

    //         var errorLabel = element.parentNode.querySelector("label.error");

    //         if (errorLabel) errorLabel.remove();
    //       }
    //     };

    //     methods.getElement = (function() {
    //       var relatedElements = _element
    //         .getAttribute("data-validate-related-elements")
    //         .split("|");

    //       for (var i = 0; i < relatedElements.length; i++) {
    //         var element = document.querySelector(relatedElements[i]);
    //         methods[method](element);
    //       }
    //     })();
    //   };

    //   var decideValidation = (function() {
    //     var isValidateActive;

    //     if (_element.nodeName == "SELECT")
    //       isValidateActive = $(_element)
    //         .find("option:selected")
    //         .attr("data-validate-active");

    //     if (isValidateActive) setValidate("validate");
    //     else setValidate("ignore");
    //   })();
    // };

    method.initializedElementFilter = function ($el) {
      return $el.filter(function () {
        return !$(this).data(kitbag.initedElStamp);
      });
    };

    method.getElements = function ($elements) {
      $elements.$requiredElements = method.initializedElementFilter(
        $elements.$form.find(".validate-required")
      );
      $elements.$repeatPasswordElements = method.initializedElementFilter(
        $elements.$form.find(".validate-repeat-password")
      );
      $elements.$emailElements = method.initializedElementFilter(
        $elements.$form.find(".validate-email")
      );
      $elements.$urlElements = method.initializedElementFilter(
        $elements.$form.find(".validate-url")
      );
      $elements.$minLengthElements = method.initializedElementFilter(
        $elements.$form.find("[data-minlength]")
      );
      $elements.$passwordElements = method.initializedElementFilter(
        $elements.$form.find(".validate-password")
      );
      $elements.$tcNoElements = method.initializedElementFilter(
        $elements.$form.find(".validate-tc-no")
      );
      $elements.$startDateElements = method.initializedElementFilter(
        $elements.$form.find(".validate-start-date")
      );
      $elements.$endDateElements = method.initializedElementFilter(
        $elements.$form.find(".validate-end-date")
      );
      $elements.$maxValueElements = method.initializedElementFilter(
        $elements.$form.find(".validate-max-value")
      );
      $elements.$taxNoElements = method.initializedElementFilter(
        $elements.$form.find(".validate-tax-no")
      );
      $elements.$googleCaptchaElements = method.initializedElementFilter(
        $elements.$form.find(".validate-google-captcha")
      );
      $elements.$reactSelectElements = method.initializedElementFilter(
        $elements.$form.find(".validate-react-select")
      );
    };

    method.validateTrigger = function ($elements) {
      if (method.validate($elements)) {
        var submitEvent = $elements.$triggerEl.data("submit-method");
        var splittedMethod = submitEvent.split(".");
        var supMethod = splittedMethod[0];
        var subMethod = splittedMethod[1];
        var subSubMethod = splittedMethod[2];

        if (subMethod && subSubMethod)
          window[supMethod][subMethod][subSubMethod]($elements.$form);
        else if (!subSubMethod && subMethod)
          window[supMethod][subMethod]($elements.$form);
        else window[supMethod]($elements.$form);
      } else {
        kitbag.delay.set(function () {
          Utils.scrollElement(document.querySelector(".error"));
        }, 200);
      }
    };

    method.validate = function ($elements) {
      if (!$elements.$form.data("validation-init")) {
        method.validationInit($elements);
        method.addCustomValidations($elements);
        $elements.$form.data("validation-init", true);
      }

      method.addElementsToFormValidation($elements);
      return kitbag.validator.form();
    };

    method.validationInit = function ($elements) {
      kitbag.validator = $elements.$form.validate({
        ignore: ".ignore, :hidden, [readonly]",
        errorClass: "error",
        errorPlacement: function (error, element) {
          if (element[0].closest(".select")) {
            error.insertAfter(element.closest(".select"));
          } else {
            error.insertAfter(element);
          }
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass("error");
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass("error");
        },
      });
    };

    method.addCustomValidations = function ($elements) {
      $.validator.addMethod("repeatPassword", function (value, element) {
        var check = false;
        var $targetField = $elements.$form.find("[data-target-password]");
        var $oldField = $elements.$form.find("[data-old-password]");

        if ($oldField.length > 0) {
          if ($targetField.val() == value && $oldField.val() != value) {
            check = true;
          }
        } else {
          if ($targetField.val() == value) {
            check = true;
          }
        }

        return check;
      });

      $.validator.addMethod("isValidEmailAddress", function (value, element) {
        if (value == "") {
          return true;
        } else {
          var pattern =
            /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
          return pattern.test(value);
        }
      });

      $.validator.addMethod("isValidURL", function (value, element) {
        if (value == "") {
          return true;
        } else {
          var pattern = /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/;
          return pattern.test(value);
        }
      });

      // Şifreniz en az 8 karakterden oluşmalı, en az 1 büyük harf, en az 1 küçük harf ve en az 1 rakam içermelidir.
      $.validator.addMethod("isValidPassword", function (value, element) {
        if (value == "") return true;

        var pattern =
          /^(?=(.*\d){1})(?=.*[a-z wığüşöç])(?=.*[A-Z wĞÜŞÖÇİ])(?=.*[a-zA-Z wığüşöçĞÜŞÖÇİ\d]).{8,}$/;
        return pattern.test(value);
      });

      $.validator.addMethod("isValidTCNumber", function (value, element) {
        value = value.toString();

        // if field is empty, return true.
        if (value == "") return true;

        var isEleven = /^[0-9]{11}$/.test(value);
        var totalX = 0;

        for (var i = 0; i < 10; i++) {
          totalX += Number(value.substr(i, 1));
        }

        var isRuleX = totalX % 10 == value.substr(10, 1);
        var totalY1 = 0;
        var totalY2 = 0;

        for (var i = 0; i < 10; i += 2) {
          totalY1 += Number(value.substr(i, 1));
        }

        for (var i = 1; i < 10; i += 2) {
          totalY2 += Number(value.substr(i, 1));
        }

        var isRuleY = (totalY1 * 7 - totalY2) % 10 == value.substr(9, 0);
        return isEleven && isRuleX && isRuleY;
      });

      $.validator.addMethod("isValidStartDate", function (value, element) {
        var thisPartnerData = element.getAttribute("data-start-date-group");
        var thisPartnerEl = document.querySelector(
          '[data-end-date-group="' + thisPartnerData + '"]'
        );

        if (value && thisPartnerEl.value) {
          if (
            value.convertToDate().getTime() <=
            thisPartnerEl.value.convertToDate().getTime()
          )
            return true;
          else return false;
        } else {
          return true;
        }
      });

      $.validator.addMethod("isValidEndDate", function (value, element) {
        var thisPartnerData = element.getAttribute("data-end-date-group");
        var thisPartnerEl = document.querySelector(
          '[data-start-date-group="' + thisPartnerData + '"]'
        );

        if (value && thisPartnerEl.value) {
          if (
            value.convertToDate().getTime() >=
            thisPartnerEl.value.convertToDate().getTime()
          )
            return true;
          else return false;
        } else {
          return true;
        }
      });

      $.validator.addMethod("maxValue", function (value, element) {
        var maxValue = parseFloat(element.getAttribute("data-maxvalue"));

        if (value <= maxValue) return true;
        else return false;
      });

      $.validator.addMethod("isValidTaxNo", function (value, element) {
        if (value == "") return true;

        var v1 = 0,
          v2 = 0,
          v3 = 0,
          v4 = 0,
          v5 = 0,
          v6 = 0,
          v7 = 0,
          v8 = 0,
          v9 = 0,
          v11 = 0,
          v22 = 0,
          v33 = 0,
          v44 = 0,
          v55 = 0,
          v66 = 0,
          v77 = 0,
          v88 = 0,
          v99 = 0,
          lastDigit = 0,
          sum = 0;

        if (value.length == 10) {
          v1 = (Number(value.charAt(0)) + 9) % 10;
          v2 = (Number(value.charAt(1)) + 8) % 10;
          v3 = (Number(value.charAt(2)) + 7) % 10;
          v4 = (Number(value.charAt(3)) + 6) % 10;
          v5 = (Number(value.charAt(4)) + 5) % 10;
          v6 = (Number(value.charAt(5)) + 4) % 10;
          v7 = (Number(value.charAt(6)) + 3) % 10;
          v8 = (Number(value.charAt(7)) + 2) % 10;
          v9 = (Number(value.charAt(8)) + 1) % 10;
          lastDigit = Number(value.charAt(9));
          v11 = (v1 * 512) % 9;
          v22 = (v2 * 256) % 9;
          v33 = (v3 * 128) % 9;
          v44 = (v4 * 64) % 9;
          v55 = (v5 * 32) % 9;
          v66 = (v6 * 16) % 9;
          v77 = (v7 * 8) % 9;
          v88 = (v8 * 4) % 9;
          v99 = (v9 * 2) % 9;
          if (v1 != 0 && v11 == 0) {
            v11 = 9;
          }
          if (v2 != 0 && v22 == 0) {
            v22 = 9;
          }
          if (v3 != 0 && v33 == 0) {
            v33 = 9;
          }
          if (v4 != 0 && v44 == 0) {
            v44 = 9;
          }
          if (v5 != 0 && v55 == 0) {
            v55 = 9;
          }
          if (v6 != 0 && v66 == 0) {
            v66 = 9;
          }
          if (v7 != 0 && v77 == 0) {
            v77 = 9;
          }
          if (v8 != 0 && v88 == 0) {
            v88 = 9;
          }
          if (v9 != 0 && v99 == 0) {
            v99 = 9;
          }
          sum = v11 + v22 + v33 + v44 + v55 + v66 + v77 + v88 + v99;
          sum = sum % 10 == 0 ? 0 : 10 - (sum % 10);
          return sum == lastDigit ? true : false;
        }
        return false;
      });

      $.validator.addMethod("googleCaptchaIsValid", function (value, element) {
        if (window.grecaptcha.getResponse()) return true;
        else return false;
      });

      $.validator.addMethod("reactSelectIsValid", function (value, element) {
        if (
          element.closest(".select").querySelector("[class*='-placeholder']")
        ) {
          return false;
        } else {
          return true;
        }
      });
    };

    method.addElementsToFormValidation = function ($elements) {
      method.getElements($elements);

      var stamp = function ($el) {
        $el.data(kitbag.initedElStamp, true);
      };

      var selectValidationListener = function ($el) {
        $el[0].addEventListener("change", function () {
          $(this).valid();
        });
      };

      // required
      $.each($elements.$requiredElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          required: true,
          messages: {
            required: Resources.getValue("requiredMsgTxt"),
          },
        });
        stamp($this);
      });

      // repeat password
      $.each($elements.$repeatPasswordElements, function (index, value) {
        var $this = $(value);
        var message =
          $("[data-old-password]").length > 0
            ? Resources.getValue("repeatAndOldPasswordMsgTxt")
            : Resources.getValue("repeatPasswordMsgTxt");
        $this.rules("add", {
          repeatPassword: true,
          messages: {
            repeatPassword: message,
          },
        });
        stamp($this);
      });

      // valid email
      $.each($elements.$emailElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          isValidEmailAddress: true,
          messages: {
            isValidEmailAddress: Resources.getValue("validEmailMsgTxt"),
          },
        });
        stamp($this);
      });

      // valid URL
      $.each($elements.$urlElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          isValidURL: true,
          messages: {
            isValidURL: Resources.getValue("validURLMsgTxt"),
          },
        });
        stamp($this);
      });

      // minlength
      $.each($elements.$minLengthElements, function (index, value) {
        var $this = $(value);
        var minLengthVal = $this.data("minlength");
        $this.rules("add", {
          minlength: minLengthVal,
          messages: {
            minlength: Resources.getValue("minlengthMsgTxt").replace(
              /[*]/g,
              minLengthVal
            ),
          },
        });
        stamp($this);
      });

      // valid password
      $.each($elements.$passwordElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          isValidPassword: true,
          messages: {
            isValidPassword: Resources.getValue("validPasswordMsgTxt"),
          },
        });
        stamp($this);
      });

      // valid TC Number
      $.each($elements.$tcNoElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          isValidTCNumber: true,
          messages: {
            isValidTCNumber: Resources.getValue("notValidTCNumberMsgTxt"),
          },
        });
        stamp($this);
      });

      // valid Start Date
      $.each($elements.$startDateElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          isValidStartDate: true,
          messages: {
            isValidStartDate: Resources.getValue("validStartDateMsgTxt"),
          },
        });
        stamp($this);
      });

      // valid End Date
      $.each($elements.$endDateElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          isValidEndDate: true,
          messages: {
            isValidEndDate: Resources.getValue("validEndDateMsgTxt"),
          },
        });
        stamp($this);
      });

      // max value
      $.each($elements.$maxValueElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          maxValue: true,
          messages: {
            maxValue: Resources.getValue("incorrectRange"),
          },
        });
        stamp($this);
      });

      // valid Tax Number
      $.each($elements.$taxNoElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          isValidTaxNo: true,
          messages: {
            isValidTaxNo: Resources.getValue("notValidTaxNumber"),
          },
        });
        stamp($this);
      });

      // google captcha validation
      $.each($elements.$googleCaptchaElements, function (index, value) {
        var $this = $(value);
        $this.rules("add", {
          googleCaptchaIsValid: true,
          messages: {
            googleCaptchaIsValid: Resources.getValue("requiredMsgTxt"),
          },
        });
        stamp($this);
      });

      // react select validation
      $.each($elements.$reactSelectElements, function (index, value) {
        var elementId = value.getAttribute("id");
        var elementInput = value.querySelector("input");
        elementInput.setAttribute("name", elementId);
        elementInput.setAttribute("id", elementId);

        var $this = $(elementInput);
        $this.rules("add", {
          reactSelectIsValid: true,
          messages: {
            reactSelectIsValid: Resources.getValue("requiredMsgTxt"),
          },
        });
        stamp($(value));

        selectValidationListener($this);
      });
    };

    method.init = (function () {
      var $pluginSelector = $(kitbag.pluginSelector).filter(function () {
        var $this = $(this);
        return $this.data("validation-init") != true;
      });

      $.each($pluginSelector, function () {
        var $elements = {};
        $elements.$triggerEl = $(this);

        method.getFormRequirements($elements);
        method.listeners($elements);
        $elements.$triggerEl.data("validation-init", true);
      });
    })();
  },
};

export default Utils;
